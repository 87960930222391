/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ToDoStatusType,
    ToDoStatusTypeFromJSON,
    ToDoStatusTypeFromJSONTyped,
    ToDoStatusTypeToJSON,
    ToDoUserResponse,
    ToDoUserResponseFromJSON,
    ToDoUserResponseFromJSONTyped,
    ToDoUserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ToDoResponse
 */
export interface ToDoResponse {
    /**
     * UUID of this ToDo
     * @type {string}
     * @memberof ToDoResponse
     */
    id: string;
    /**
     * Short text describing what the assignee is to do.
     * @type {string}
     * @memberof ToDoResponse
     */
    title: string;
    /**
     * Longer text describing what the assignee is to do.
     * @type {string}
     * @memberof ToDoResponse
     */
    description?: string;
    /**
     * 
     * @type {ToDoStatusType}
     * @memberof ToDoResponse
     */
    status: ToDoStatusType;
    /**
     * 
     * @type {ToDoUserResponse}
     * @memberof ToDoResponse
     */
    assignedTo: ToDoUserResponse;
    /**
     * 
     * @type {ToDoUserResponse}
     * @memberof ToDoResponse
     */
    assignedBy: ToDoUserResponse;
    /**
     * Optional date this ToDo should be completed by the assignee
     * @type {Date}
     * @memberof ToDoResponse
     */
    shouldBeCompletedOn?: Date;
    /**
     * Optional UUID of the meeting that this ToDo was created in.
     * @type {string}
     * @memberof ToDoResponse
     */
    createdInMeeting?: string;
    /**
     * Date and time this ToDo was created
     * @type {Date}
     * @memberof ToDoResponse
     */
    createdOn: Date;
    /**
     * Date and time this ToDo was last updated
     * @type {Date}
     * @memberof ToDoResponse
     */
    updatedOn?: Date;
}

export function ToDoResponseFromJSON(json: any): ToDoResponse {
    return ToDoResponseFromJSONTyped(json, false);
}

export function ToDoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToDoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': ToDoStatusTypeFromJSON(json['status']),
        'assignedTo': ToDoUserResponseFromJSON(json['assignedTo']),
        'assignedBy': ToDoUserResponseFromJSON(json['assignedBy']),
        'shouldBeCompletedOn': !exists(json, 'shouldBeCompletedOn') ? undefined : (new Date(json['shouldBeCompletedOn'])),
        'createdInMeeting': !exists(json, 'createdInMeeting') ? undefined : json['createdInMeeting'],
        'createdOn': (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
    };
}

export function ToDoResponseToJSON(value?: ToDoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'status': ToDoStatusTypeToJSON(value.status),
        'assignedTo': ToDoUserResponseToJSON(value.assignedTo),
        'assignedBy': ToDoUserResponseToJSON(value.assignedBy),
        'shouldBeCompletedOn': value.shouldBeCompletedOn === undefined ? undefined : (value.shouldBeCompletedOn.toISOString().substr(0,10)),
        'createdInMeeting': value.createdInMeeting,
        'createdOn': (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
    };
}


