import React from "react";
import { useIntl } from "react-intl";
import { Grid, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import useStyles from "./Tip1.style";
import { messages } from "./Tip1.messages";
import TipImage from "../../../../Assets/tip-1.png";

const Tip1 = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid
          item
          alignContent="center"
          className={classes.imageContainer}
          xs={12}
          md="auto"
        >
          <img
            className={classes.image}
            src={TipImage}
            alt={intl.formatMessage(messages.titleLabel)}
          />
        </Grid>
        <Grid
          item
          alignContent="center"
          xs={12}
          md="auto"
          className={classes.descriptionContainer}
        >
          <div>
            <Typography variant="h5" className={classes.title}>
              {intl.formatMessage(messages.titleLabel)}
            </Typography>
            <Typography variant="subtitle1" className={classes.description}>
              <DoneIcon fontSize="large" className={classes.icon} />
              {intl.formatMessage(messages.descriptionItem1)}
            </Typography>
            <Typography variant="subtitle1" className={classes.description}>
              <DoneIcon fontSize="large" className={classes.icon} />
              {intl.formatMessage(messages.descriptionItem2)}
            </Typography>
            <Typography variant="subtitle1" className={classes.description}>
              <DoneIcon fontSize="large" className={classes.icon} />
              {intl.formatMessage(messages.descriptionItem3)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Tip1;
