import {
  CustomerAgreementResponse,
  CustomerUserAgreementType,
  UserAgreementConsentResponse,
  UserConsentStatusType,
} from "../generated/userconsent-api";

export const getCustomerAgreementWithType = (
  type: CustomerUserAgreementType,
  agreements: CustomerAgreementResponse[]
): CustomerAgreementResponse | undefined => {
  return agreements.find((agreement) => agreement.agreementType === type);
};

export const getUserAgreementWithType = (
  type: CustomerUserAgreementType,
  agreements: UserAgreementConsentResponse[]
): UserAgreementConsentResponse | undefined => {
  return agreements.find((agreement) => agreement.agreementType === type);
};

export const getNoneConsentedAgreements = (
  agreements: UserAgreementConsentResponse[]
): string[] => {
  const result = [] as string[];

  agreements
    .filter(
      (agreement) => agreement.status === UserConsentStatusType.NoConsentGiven
    )
    .forEach((agreement) => {
      if (agreement.shouldConsentTo) {
        result.push(agreement.shouldConsentTo);
      }
    });

  return result;
};
