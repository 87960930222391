import React, { useEffect, useState } from "react";
import { useSubscription } from "react-stomp-hooks";

import { Button, Paper, Typography } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useIntl } from "react-intl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CustomPagination from "../../Components/CustomPagination/Pagination";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { fileService } from "../../Providers/ServiceProvider/ServiceProvider";
import {
  PaginatedFileList,
  SortByType,
  SortOrderType,
} from "../../generated/file-api";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import FileList from "../../Components/FileList/FileList";
import { SocketDestination } from "../../WebSocket/WebSocketSession";
import {
  parseSignalMessage,
  SignalType,
} from "../../WebSocket/model/SignalMessage";
import { useStyles } from "./files.style";
import { messages } from "./files.messages";
import ShareFileDialog from "../../Components/Dialogs/ShareFileDialog/ShareFileDialog";

const Files = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [result, setResult] = useState<PaginatedFileList>();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(20);
  const setSpinner = useSpinner()[1];
  const [isFileListLoading, setIsFileListLoading] = useState<boolean>(false);
  const [isShareFileDialogOpen, setIsShareFileDialogOpen] = useState<boolean>(
    false
  );

  const isLoading = (): boolean => {
    return isFileListLoading;
  };

  const loadFiles = () => {
    if (customerId && authenticatedUser.user) {
      setIsFileListLoading(true);
      fileService()
        .listFiles({
          page,
          pageSize,
          customerId,
          sortBy: SortByType.UploadedOn,
          sortOrder: SortOrderType.Descending,
        })
        .then(setResult)
        .finally(() => setIsFileListLoading(false));
    }
  };

  const onFileShared = () => {
    setIsShareFileDialogOpen(false);
    loadFiles();
  };

  useSubscription(SocketDestination.SIGNAL_GLOBAL + customerId, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (signalMessage.type === SignalType.FILE) {
      loadFiles();
    }
  });

  useEffect(() => {
    loadFiles();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show nothing when loading
  if (isLoading()) {
    return null;
  }

  if (result && result.data && result.data.length < 1)
    return (
      <Paper className={classes.paperEmpty}>
        <ShareFileDialog
          isDialogOpen={isShareFileDialogOpen}
          onDialogClose={() => setIsShareFileDialogOpen(false)}
          onFileShared={onFileShared}
        />
        <InsertDriveFileIcon color="disabled" className={classes.icon} />
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.noFilesLabel}
        >
          {intl.formatMessage(messages.noFilesLabel)}
        </Typography>
        <Button
          size="small"
          color="primary"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={() => setIsShareFileDialogOpen(true)}
        >
          {intl.formatMessage(messages.shareFileBtnLabel)}
        </Button>
      </Paper>
    );

  return (
    <>
      <ShareFileDialog
        isDialogOpen={isShareFileDialogOpen}
        onDialogClose={() => setIsShareFileDialogOpen(false)}
        onFileShared={onFileShared}
      />
      <div className={classes.actionContainer}>
        <Button
          size="small"
          color="default"
          variant="text"
          startIcon={<CloudUploadIcon />}
          onClick={() => setIsShareFileDialogOpen(true)}
        >
          {intl.formatMessage(messages.shareFileBtnLabel)}
        </Button>
      </div>
      <FileList
        files={result?.data || []}
        onDeleted={loadFiles}
        onDownloaded={loadFiles}
      />
      <CustomPagination onChange={setPage} pagination={result?.pagination} />
    </>
  );
};

export default Files;
