import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import { messages } from "./CreateConsentAgreementButton.messages";
import CreateConsentAgreementDialog from "../../Dialogs/CreateConsentAgreementDialog/CreateConsentAgreementDialog";

interface Props {
  onCreated?: () => void;
  activeTab: number;
  customerId?: string;
}

const CreateConsentAgreementButton = (props: Props) => {
  const { onCreated, activeTab, customerId } = props;
  const intl = useIntl();

  const [
    isCreateConsentAgreementDialogOpen,
    setIsCreateConsentAgreementDialogOpen,
  ] = useState<boolean>(false);

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCreateConsentAgreementDialogOpen(true);
  };

  const handleOnCreated = () => {
    setIsCreateConsentAgreementDialogOpen(false);
    if (onCreated) {
      onCreated();
    }
  };

  return (
    <>
      <CreateConsentAgreementDialog
        isOpen={isCreateConsentAgreementDialogOpen}
        onClose={() => handleOnCreated()}
        activeTab={activeTab}
        customerId={customerId}
      />

      <Button
        size="medium"
        color="primary"
        variant="text"
        onClick={(e) => handleOpenDialog(e)}
        startIcon={<AddIcon />}
      >
        {intl.formatMessage(messages.buttonLabel)}
      </Button>
    </>
  );
};

export default CreateConsentAgreementButton;
