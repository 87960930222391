import React from "react";
import { List } from "@material-ui/core";
import { useStyles } from "./FileList.style";
import { FileMetadata } from "../../generated/file-api";
import FileListItem from "./Components/FileListItem/FileListItem";

interface Props {
  files: FileMetadata[];
  onDownloaded?: () => void;
  onDeleted?: () => void;
}

const FileList = (props: Props) => {
  const { files, onDownloaded, onDeleted } = props;
  const classes = useStyles();

  if (!files || files.length === 0) {
    return null;
  }

  return (
    <List className={classes.root}>
      {files.map(
        (file) =>
          file && (
            <FileListItem
              file={file}
              onDeleted={onDeleted}
              onDownloaded={onDownloaded}
            />
          )
      )}
    </List>
  );
};

export default FileList;
