import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { messages } from "./BackButton.messages";
import { RoutePath } from "../../Routes/RoutePath";

const BackButton = () => {
  const intl = useIntl();
  const history = useHistory();

  const redirectToLogin = (event: React.MouseEvent) => {
    event.stopPropagation();
    history.push({ pathname: RoutePath.PORTAL_CUSTOMERS });
  };

  return (
    <Button
      size="large"
      variant="text"
      startIcon={<ArrowBackIcon />}
      onClick={redirectToLogin}
    >
      {intl.formatMessage(messages.buttonLabel)}
    </Button>
  );
};

export default BackButton;
