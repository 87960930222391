/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User Consent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerUserAgreementType,
    CustomerUserAgreementTypeFromJSON,
    CustomerUserAgreementTypeFromJSONTyped,
    CustomerUserAgreementTypeToJSON,
    UserConsentStatusType,
    UserConsentStatusTypeFromJSON,
    UserConsentStatusTypeFromJSONTyped,
    UserConsentStatusTypeToJSON,
} from './';

/**
 * Response object containing the users consent status and which agreement that the user has consented to.
 * @export
 * @interface UserAgreementConsentResponse
 */
export interface UserAgreementConsentResponse {
    /**
     * 
     * @type {UserConsentStatusType}
     * @memberof UserAgreementConsentResponse
     */
    status: UserConsentStatusType;
    /**
     * Id of the user that has given consent.
     * @type {string}
     * @memberof UserAgreementConsentResponse
     */
    userId?: string;
    /**
     * 
     * @type {CustomerUserAgreementType}
     * @memberof UserAgreementConsentResponse
     */
    agreementType?: CustomerUserAgreementType;
    /**
     * Id of the user agreement that the user consented to.
     * @type {string}
     * @memberof UserAgreementConsentResponse
     */
    consentedTo?: string;
    /**
     * Date and time when the user gave their consent to the agreement.
     * @type {Date}
     * @memberof UserAgreementConsentResponse
     */
    consentGivenOn?: Date;
    /**
     * Id of the user agreement that the user should give their consent to.
     * @type {string}
     * @memberof UserAgreementConsentResponse
     */
    shouldConsentTo?: string;
}

export function UserAgreementConsentResponseFromJSON(json: any): UserAgreementConsentResponse {
    return UserAgreementConsentResponseFromJSONTyped(json, false);
}

export function UserAgreementConsentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAgreementConsentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': UserConsentStatusTypeFromJSON(json['status']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'agreementType': !exists(json, 'agreementType') ? undefined : CustomerUserAgreementTypeFromJSON(json['agreementType']),
        'consentedTo': !exists(json, 'consentedTo') ? undefined : json['consentedTo'],
        'consentGivenOn': !exists(json, 'consentGivenOn') ? undefined : (new Date(json['consentGivenOn'])),
        'shouldConsentTo': !exists(json, 'shouldConsentTo') ? undefined : json['shouldConsentTo'],
    };
}

export function UserAgreementConsentResponseToJSON(value?: UserAgreementConsentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': UserConsentStatusTypeToJSON(value.status),
        'userId': value.userId,
        'agreementType': CustomerUserAgreementTypeToJSON(value.agreementType),
        'consentedTo': value.consentedTo,
        'consentGivenOn': value.consentGivenOn === undefined ? undefined : (value.consentGivenOn.toISOString()),
        'shouldConsentTo': value.shouldConsentTo,
    };
}


