import React, { useState } from "react";
import { Grid, Paper, useTheme } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { useIntl } from "react-intl";
import { Divider } from "@mui/material";
import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import BankIdLogin from "./Components/BankIdLogin/BankIdLogin";
import { useStyles } from "./Login.style";
import { messages } from "./Login.messages";
import { RoutePath } from "../../Components/Routes/RoutePath";
import UsernameLogin from "./Components/UsernameLogin/UsernameLogin";
import WelcomeText from "./Components/WelcomeText/WelcomeText";
import CustomerLinkButton from "../../Components/Buttons/CustomerLinkButton/CustomerLinkButton";
import SithsLogin from "./Components/SithsLogin/SithsLogin";
import FeatureController from "../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import { getSessionStorageItem, Key } from "../../Utils/SessionStorage";
import { useCustomerIsAdminPortal } from "../../Providers/CustomerProvider/CustomerProvider";

const Login = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isSithsLoginSelected, setIsSithsLoginSelected] = useState(false);
  const [isBankIdLoginSelected, setIsBankIdLoginSelected] = useState(false);
  const [isUsernameLoginSelected, setIsUsernameLoginSelected] = useState(false);
  const isCustomerAdminPortal = useCustomerIsAdminPortal();
  const [error, setError] = useState<string>();
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  // if logged in we should redirect the user to dashboard view, if adminportal redirect to portal_customers view
  if (authenticatedUser.isLoggedIn) {
    return (
      <Redirect
        to={
          isCustomerAdminPortal
            ? RoutePath.PORTAL_CUSTOMERS
            : RoutePath.DASHBOARD
        }
      />
    );
  }

  const showBankIdLogin = (): boolean => {
    return !isUsernameLoginSelected && !isSithsLoginSelected;
  };

  const showSithsLogin = (): boolean => {
    if (isMobileWidth) {
      return false;
    }
    return !isUsernameLoginSelected && !isBankIdLoginSelected;
  };

  const showUsernameLogin = (): boolean => {
    return !isSithsLoginSelected && !isBankIdLoginSelected;
  };

  const showLinkButton = (): boolean => {
    return (
      !isSithsLoginSelected &&
      !isBankIdLoginSelected &&
      !isUsernameLoginSelected
    );
  };

  document.title = intl.formatMessage(messages.documentTitle);
  return (
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        <Paper className={classes.root}>
          <WelcomeText />
          {error && (
            <Alert severity="error" variant="standard">
              {error}
            </Alert>
          )}
          {getSessionStorageItem(Key.NONE_NOTIFIABLE_USER) && (
            <Alert severity="info" variant="standard">
              {intl.formatMessage(messages.noneNotifiableUserInfoAlert)}
            </Alert>
          )}
          {showBankIdLogin() && (
            <BankIdLogin
              onError={setError}
              onClick={() => setIsBankIdLoginSelected(true)}
              onCancel={() => setIsBankIdLoginSelected(false)}
            />
          )}
          {showUsernameLogin() && (
            <UsernameLogin
              onError={setError}
              onClick={() => setIsUsernameLoginSelected(true)}
              onCancel={() => setIsUsernameLoginSelected(false)}
            />
          )}
          {showLinkButton() && (
            <>
              <CustomerLinkButton />
              <FeatureController
                requiredFeature={CustomerFeatureType.SithsLogin}
              >
                {!isMobileWidth && (
                  <div className={classes.divider}>
                    <Divider>
                      {intl.formatMessage(messages.dividerTitle)}
                    </Divider>
                  </div>
                )}
              </FeatureController>
            </>
          )}
          {showSithsLogin() && (
            <SithsLogin
              onError={setError}
              onClick={() => setIsSithsLoginSelected(true)}
              onCancel={() => setIsSithsLoginSelected(false)}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
