/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Invite API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendInviteCodeRequest
 */
export interface SendInviteCodeRequest {
    /**
     * The invite ID
     * @type {string}
     * @memberof SendInviteCodeRequest
     */
    inviteId: string;
}

export function SendInviteCodeRequestFromJSON(json: any): SendInviteCodeRequest {
    return SendInviteCodeRequestFromJSONTyped(json, false);
}

export function SendInviteCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendInviteCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inviteId': json['inviteId'],
    };
}

export function SendInviteCodeRequestToJSON(value?: SendInviteCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inviteId': value.inviteId,
    };
}


