import React from "react";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import ConsentAgreementSettings from "../../../Components/ConsentAgreementSettings/ConsentAgreementSettings";

const ConsentAgreementSettingsPage = () => {
  const customerId = useCustomerId();
  return <ConsentAgreementSettings key="thisDomain" customerId={customerId} />;
};

export default ConsentAgreementSettingsPage;
