/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAuthorityType,
    UserAuthorityTypeFromJSON,
    UserAuthorityTypeFromJSONTyped,
    UserAuthorityTypeToJSON,
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthenticatedUser
 */
export interface AuthenticatedUser {
    /**
     * UUID of the authenticated user
     * @type {string}
     * @memberof AuthenticatedUser
     */
    userId: string;
    /**
     * UUID of the customer the user was authenticated against
     * @type {string}
     * @memberof AuthenticatedUser
     */
    customerId: string;
    /**
     * First name of the authenticated user
     * @type {string}
     * @memberof AuthenticatedUser
     */
    firstName?: string;
    /**
     * Last name of the authenticated user
     * @type {string}
     * @memberof AuthenticatedUser
     */
    lastName?: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof AuthenticatedUser
     */
    userRole: UserRoleType;
    /**
     * The users authorities
     * @type {Array<UserAuthorityType>}
     * @memberof AuthenticatedUser
     */
    userAuthorities?: Array<UserAuthorityType>;
    /**
     * Access token to use for authentication
     * @type {string}
     * @memberof AuthenticatedUser
     */
    token?: string;
}

export function AuthenticatedUserFromJSON(json: any): AuthenticatedUser {
    return AuthenticatedUserFromJSONTyped(json, false);
}

export function AuthenticatedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'customerId': json['customerId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'userRole': UserRoleTypeFromJSON(json['userRole']),
        'userAuthorities': !exists(json, 'userAuthorities') ? undefined : ((json['userAuthorities'] as Array<any>).map(UserAuthorityTypeFromJSON)),
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function AuthenticatedUserToJSON(value?: AuthenticatedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'customerId': value.customerId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'userRole': UserRoleTypeToJSON(value.userRole),
        'userAuthorities': value.userAuthorities === undefined ? undefined : ((value.userAuthorities as Array<any>).map(UserAuthorityTypeToJSON)),
        'token': value.token,
    };
}


