/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplyToMessageResponse
 */
export interface ReplyToMessageResponse {
    /**
     * The UUID of the created message reply
     * @type {string}
     * @memberof ReplyToMessageResponse
     */
    createdReplyMessage: string;
}

export function ReplyToMessageResponseFromJSON(json: any): ReplyToMessageResponse {
    return ReplyToMessageResponseFromJSONTyped(json, false);
}

export function ReplyToMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplyToMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdReplyMessage': json['createdReplyMessage'],
    };
}

export function ReplyToMessageResponseToJSON(value?: ReplyToMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdReplyMessage': value.createdReplyMessage,
    };
}


