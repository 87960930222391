export enum Variant {
  // Bookings
  PAST_BOOKINGS,
  ONGOING_BOOKING_REQUESTS,
  INCOMING_BOOKING_REQUESTS,
  UPCOMING_BOOKINGS,

  // Dashboard
  DASHBOARD_ONGOING_BOOKINGS,
  DASHBOARD_INCOMING_BOOKING_REQUESTS,
  DASHBOARD_UPCOMING_BOOKINGS,

  // Admin
  ADMIN_UPCOMING_BOOKINGS,
  ADMIN_NEWLY_ADDED,
}
