import React from "react";
import { Avatar, AvatarProps } from "@material-ui/core";
import { useStyles } from "./ProfilePicture.style";

export type Sizes = "large" | "medium" | "small";

interface Props {
  size?: Sizes;
  center?: boolean;
  firstName?: string;
  lastName?: string;
  avatarProps?: AvatarProps;
}

const ProfilePicture = (props: Props) => {
  const { size, center, firstName, lastName, avatarProps } = props;
  const classes = useStyles();
  // TODO: Fetch profile picture if it is set, current behaviour is to always show default avatar
  return (
    <Avatar
      {...avatarProps}
      alt={firstName && lastName && `${firstName} ${lastName}`}
      className={`${center && classes.center} ${size && classes[size]} ${
        avatarProps?.className
      }`}
      src="/static/images/avatar/1.jpg"
    />
  );
};

export default ProfilePicture;
