import * as Yup from "yup";

// text string is used as a key to fetch intl message
const MeetingValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().max(50, "titleTooLong").required("titleRequired"),
    duration: Yup.number()
      .min(1, "durationTooShort")
      .required("durationRequired"),
  });
};

export default MeetingValidationSchema;
