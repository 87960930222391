import { defineMessages } from "react-intl";

export const messages = defineMessages({
  caregiver: {
    id: "userfields.authorities.caregiver",
    defaultMessage: "Caregiver",
    description: "Label for checkbox for user settings caregiver",
  },
  admin: {
    id: "userfields.authorities.admin",
    defaultMessage: "Administrator",
    description: "Label for checkbox for user settings admin / receptionist",
  },
  systemAdmin: {
    id: "userfields.authorities.systemAdmin",
    defaultMessage: "System administrator",
    description: "Label for checkbox for user settings system admin",
  },
  bookMeetingTooltip: {
    id: "userfields.authorities.tooltip.bookMeeting",
    defaultMessage:
      "Book and participate in your own visits with patients and care staff",
    description: "book meeting label for tooltip",
  },
  bookMeetingOtherTooltip: {
    id: "userfields.authorities.tooltip.bookMeetingOther",
    defaultMessage: "Book a visit for healthcare staff",
    description: "book meeting for others tooltip",
  },
  addPatientWhenBookMeetingTooltip: {
    id: "userfields.authorities.tooltip.addPatientWhenBookMeeting",
    defaultMessage: "Add new patient when booking a visit",
    description: "add patient from book meeting view tooltip",
  },
  addPatientTooltip: {
    id: "userfields.authorities.tooltip.addPatient",
    defaultMessage: "Add new patient",
    description: "add patient tooltip",
  },
  addCaregiverTooltip: {
    id: "userfields.authorities.tooltip.addCaregiver",
    defaultMessage: "Add new healthcare staff",
    description: "add caregiver tooltip",
  },
  addAdminTooltip: {
    id: "userfields.authorities.tooltip.addAdmin",
    defaultMessage: "Add new administrator",
    description: "add admin tooltip",
  },
  addSystemAdminTooltip: {
    id: "userfields.authorities.tooltip.addSystemAdmin",
    defaultMessage: "Add new system administrator",
    description: "add system admin tooltip",
  },
  sendMessageTooltip: {
    id: "userfields.authorities.tooltip.sendMessage",
    defaultMessage:
      "Send messages [if the functionality is in your organization]",
    description: "send message tooltip",
  },
  customerSettingsTooltip: {
    id: "userfields.authorities.tooltip.customerSettings",
    defaultMessage: "Authority to change settings for the domain",
    description: "customer settings tooltip",
  },
  customerSettings: {
    id: "userfields.authorities.customerSettings",
    defaultMessage: "Customer Settings",
    description: "Label for checkbox for customer settings",
  },
  viewUpcomingBookingsTooltip: {
    id: "userfields.authorities.tooltip.viewUpcomingBookings",
    defaultMessage:
      "See upcoming visits for patients and healthcare professionals",
    description: "view upcoming bookings for other tooltip",
  },
});
