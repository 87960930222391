import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import {
  UserResponse,
  UserRoleType,
  UserStatusType,
} from "../../../../generated/user-api";
import { useStyles } from "./UserListItem.style";
import { messages } from "./UserListItem.messages";
import UserListItemText from "../UserListItemText/UserListItemText";
import UserListItemAvatar from "../UserListItemAvatar/UserListItemAvatar";
import CreateMeetingButton from "../../../Buttons/CreateMeetingButton/CreateMeetingButton";
import UpdateUserButton from "../../../Buttons/UpdateUserButton/UpdateUserButton";
import StatusChip from "../../../StatusChip/StatusChip";
import TakeCareSyncButton from "../../../Buttons/TakeCareSyncButton/TakeCareSyncButton";
import { Tab } from "../../../UserForm/ModifyUser/ModifyUserDialog";
import RoleChip from "../../../RoleChip/RoleChip";

interface Props {
  user: UserResponse;
  onUpdated?: (updatedUser: UserResponse) => void;
  noDivider?: boolean;
}

const UserListItem = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { user, onUpdated, noDivider } = props;
  const { email, workTitle, userStatus, mobilePhoneNumber, userRole } = user;

  return (
    <>
      <ListItem
        alignItems="flex-start"
        className={classes.root}
        divider={!noDivider}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <StatusChip status={userStatus} />
            <RoleChip authorities={user.userAuthorities || []} />
          </Grid>
          <Grid item xs={12}>
            <UserListItemAvatar user={user} />
          </Grid>
          {workTitle && (
            <Grid item sm={4} xs={6}>
              <UserListItemText
                primaryText={intl.formatMessage(messages.workTitle)}
                secondaryText={workTitle}
              />
            </Grid>
          )}
          <Grid item sm={4} xs={6}>
            <UserListItemText
              primaryText={intl.formatMessage(messages.phoneTitle)}
              secondaryText={mobilePhoneNumber}
            />
          </Grid>
          <Grid item sm={4} xs={6}>
            <UserListItemText
              primaryText={intl.formatMessage(messages.emailTitle)}
              secondaryText={email}
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            {userRole === UserRoleType.Client && (
              <TakeCareSyncButton userId={user.id} />
            )}
            <UpdateUserButton
              visibleTabs={[Tab.GENERAL, Tab.STATUS, Tab.PASSWORD]}
              userToUpdate={user}
              onUpdated={onUpdated}
              className={classes.button}
            />
            {userRole === UserRoleType.Client &&
              userStatus === UserStatusType.Active && (
                <CreateMeetingButton
                  color="secondary"
                  prePopulatedParticipants={[user]}
                  className={classes.button}
                />
              )}
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default UserListItem;
