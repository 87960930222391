import React, { useState } from "react";
import GroupIcon from "@material-ui/icons/Group";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import HelpIcon from "@material-ui/icons/Help";
import NoteIcon from "@material-ui/icons/Note";
import CloseIcon from "@material-ui/icons/Close";
import { Badge, Box, useMediaQuery, useTheme } from "@material-ui/core";
import ForumIcon from "@material-ui/icons/Forum";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useSubscription } from "react-stomp-hooks";
import { useIntl } from "react-intl";
import { useStyles } from "./Tabs.style";

import Tooltip from "../../../../Components/Tooltip/Tooltip";
import TabContent, { View } from "./Components/TabContent/TabContent";
import { Meeting } from "../../../../Models/Meeting";
import { SocketDestination } from "../../../../WebSocket/WebSocketSession";
import {
  parseSignalMessage,
  SignalType,
} from "../../../../WebSocket/model/SignalMessage";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { messages } from "./Tabs.messages";
import { hasFeatureEnabled } from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import { useCustomerFeatures } from "../../../../Providers/CustomerProvider/CustomerProvider";

export interface Props {
  meeting: Meeting;
  onNotesClick: (isExpanded: boolean) => void;
}

interface ContentProps {
  badgeNumber: number;
  icon: JSX.Element;
  tooltip: string;
}

const Tabs = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const customerFeatures = useCustomerFeatures();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [authenticatedUser] = useAuthenticatedUser();
  const [view, setView] = useState<View>(View.CLOSED);
  const [isPrivateNotesOpen, setIsPrivateNotesOpen] = useState(false);
  const [chatNotification, setChatNotification] = useState(0);
  const [fileNotification, setFileNotification] = useState(0);
  const [todoNotification, setTodoNotification] = useState(0);

  const { meeting, onNotesClick } = props;

  const clearNotifications = (notificationType: View) => {
    switch (notificationType) {
      case View.TODO:
        setTodoNotification(0);
        break;
      case View.FILE:
        setFileNotification(0);
        break;
      case View.CHAT:
        setChatNotification(0);
        break;
      default:
    }
  };
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: View
  ) => {
    if (!isSmallScreen && nextView === View.NOTE) {
      setIsPrivateNotesOpen(!isPrivateNotesOpen);
      onNotesClick(!isPrivateNotesOpen);
    } else if (nextView === null) {
      setView(View.CLOSED);
    } else {
      setView(nextView);
      clearNotifications(nextView);
    }
  };

  const handleNewNotification = (signalType: SignalType) => {
    switch (signalType) {
      case SignalType.TODO:
        if (view !== View.TODO) {
          setTodoNotification(todoNotification + 1);
        }
        break;
      case SignalType.FILE:
        if (view !== View.FILE) {
          setFileNotification(fileNotification + 1);
        }
        break;
      case SignalType.CHAT_MESSAGE:
        if (view !== View.CHAT) {
          setChatNotification(chatNotification + 1);
        }
        break;
      default:
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meeting.id, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (signalMessage.triggeredBy !== authenticatedUser.user?.id) {
      handleNewNotification(signalMessage.type);
    }
  });

  const ToggleButtonContent = (contentProps: ContentProps) => {
    const { badgeNumber, tooltip, icon } = contentProps;
    return (
      <Tooltip title={tooltip} placement="left">
        <div className={classes.icon}>
          <Badge
            color="error"
            badgeContent={badgeNumber}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {icon}
          </Badge>
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      <TabContent
        active={view}
        meeting={meeting}
        onClose={() => setView(View.CLOSED)}
      />
      <Box className={classes.root}>
        <ToggleButtonGroup
          className={classes.topTabs}
          orientation={isSmallScreen ? "horizontal" : "vertical"}
          value={view}
          onChange={handleChange}
          aria-label="vertical outlined primary button group"
          exclusive
        >
          <ToggleButton
            value={View.PARTICIPANT}
            aria-label={intl.formatMessage(messages.participantsTooltip)}
            className={classes.button}
          >
            <ToggleButtonContent
              icon={view === View.PARTICIPANT ? <CloseIcon /> : <GroupIcon />}
              tooltip={intl.formatMessage(messages.participantsTooltip)}
              badgeNumber={0}
            />
          </ToggleButton>
          <ToggleButton
            value={View.CHAT}
            aria-label={intl.formatMessage(messages.chatTooltip)}
            className={
              hasFeatureEnabled(
                CustomerFeatureType.InMeetingChat,
                customerFeatures
              )
                ? classes.button
                : classes.hidden
            }
          >
            <ToggleButtonContent
              icon={view === View.CHAT ? <CloseIcon /> : <ForumIcon />}
              tooltip={intl.formatMessage(messages.chatTooltip)}
              badgeNumber={chatNotification}
            />
          </ToggleButton>
          <ToggleButton
            value={View.TODO}
            aria-label={intl.formatMessage(messages.todoTooltip)}
            className={
              hasFeatureEnabled(
                CustomerFeatureType.InMeetingTodo,
                customerFeatures
              )
                ? classes.button
                : classes.hidden
            }
          >
            <ToggleButtonContent
              icon={
                view === View.TODO ? <CloseIcon /> : <AssignmentTurnedInIcon />
              }
              tooltip={intl.formatMessage(messages.todoTooltip)}
              badgeNumber={todoNotification}
            />
          </ToggleButton>
          <ToggleButton
            value={View.FILE}
            aria-label={intl.formatMessage(messages.fileTooltip)}
            className={
              hasFeatureEnabled(
                CustomerFeatureType.InMeetingFileShare,
                customerFeatures
              )
                ? classes.button
                : classes.hidden
            }
          >
            <ToggleButtonContent
              icon={view === View.FILE ? <CloseIcon /> : <AttachFileIcon />}
              tooltip={intl.formatMessage(messages.fileTooltip)}
              badgeNumber={fileNotification}
            />
          </ToggleButton>
          <ToggleButton
            value={View.NOTE}
            aria-label={intl.formatMessage(messages.privateNoteTooltip)}
            selected={isPrivateNotesOpen}
            className={
              hasFeatureEnabled(
                CustomerFeatureType.InMeetingNotes,
                customerFeatures
              )
                ? classes.button
                : classes.hidden
            }
          >
            <ToggleButtonContent
              icon={isPrivateNotesOpen ? <CloseIcon /> : <NoteIcon />}
              tooltip={intl.formatMessage(messages.privateNoteTooltip)}
              badgeNumber={0}
            />
          </ToggleButton>
        </ToggleButtonGroup>
        {!isSmallScreen && (
          <ToggleButtonGroup
            orientation="vertical"
            exclusive
            value={view}
            onChange={handleChange}
            aria-label="vertical outlined primary button group"
          >
            <ToggleButton
              value={View.TROUBLESHOOT}
              aria-label={intl.formatMessage(messages.troubleshootTooltip)}
              className={classes.helpButton}
            >
              <Tooltip
                title={intl.formatMessage(messages.troubleshootTooltip)}
                placement="left"
              >
                {view === View.PARTICIPANT ? (
                  <CloseIcon />
                ) : (
                  <HelpIcon className={classes.icon} />
                )}
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
    </>
  );
};

export default Tabs;
