import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: theme.spacing(1),
    zIndex: theme.zIndex.appBar,
  },
  paper: {
    position: "absolute",
    display: "flex",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  toolbarMobileContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
  toolbarButtons: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  toolbarButtonsDisabled: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    backgroundColor: theme.palette.grey[400],
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
    },
  },
  arrowButton: {
    borderRadius: "5em",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  infoButton: {
    position: "absolute",
    left: "0",
    margin: theme.spacing(1),
    borderRadius: "5em",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  groupButton: {
    margin: "8px",
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[400],
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
    },
  },
  activeButton: {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  activeButtonIcon: {
    color: "green",
  },
}));
