import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "&:hover": {
        backgroundColor: theme.palette.grey.A100,
      },
    },
    buttonGrid: {
      textAlign: "right",
      marginLeft: 10,
      marginBottom: 10,
    },
    marginBottom: {
      marginBottom: 10,
    },
    justifyRight: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
  })
);
