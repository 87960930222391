/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingParticipantType,
    MeetingParticipantTypeFromJSON,
    MeetingParticipantTypeFromJSONTyped,
    MeetingParticipantTypeToJSON,
    MeetingRoleType,
    MeetingRoleTypeFromJSON,
    MeetingRoleTypeFromJSONTyped,
    MeetingRoleTypeToJSON,
    ParticipantAttendance,
    ParticipantAttendanceFromJSON,
    ParticipantAttendanceFromJSONTyped,
    ParticipantAttendanceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingParticipantResponse
 */
export interface MeetingParticipantResponse {
    /**
     * UUID of user
     * @type {string}
     * @memberof MeetingParticipantResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipantResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipantResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipantResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipantResponse
     */
    workTitle?: string;
    /**
     * 
     * @type {MeetingParticipantType}
     * @memberof MeetingParticipantResponse
     */
    participantType: MeetingParticipantType;
    /**
     * 
     * @type {ParticipantAttendance}
     * @memberof MeetingParticipantResponse
     */
    participantAttendance: ParticipantAttendance;
    /**
     * 
     * @type {MeetingRoleType}
     * @memberof MeetingParticipantResponse
     */
    meetingRole: MeetingRoleType;
    /**
     * true if participant is added to meeting from external system
     * @type {boolean}
     * @memberof MeetingParticipantResponse
     */
    isExternallyAdded: boolean;
}

export function MeetingParticipantResponseFromJSON(json: any): MeetingParticipantResponse {
    return MeetingParticipantResponseFromJSONTyped(json, false);
}

export function MeetingParticipantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingParticipantResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
        'participantType': MeetingParticipantTypeFromJSON(json['participantType']),
        'participantAttendance': ParticipantAttendanceFromJSON(json['participantAttendance']),
        'meetingRole': MeetingRoleTypeFromJSON(json['meetingRole']),
        'isExternallyAdded': json['isExternallyAdded'],
    };
}

export function MeetingParticipantResponseToJSON(value?: MeetingParticipantResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'workTitle': value.workTitle,
        'participantType': MeetingParticipantTypeToJSON(value.participantType),
        'participantAttendance': ParticipantAttendanceToJSON(value.participantAttendance),
        'meetingRole': MeetingRoleTypeToJSON(value.meetingRole),
        'isExternallyAdded': value.isExternallyAdded,
    };
}


