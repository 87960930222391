import React from "react";

import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import ThemeSettings from "../../../Components/ThemeSettings/ThemeSettings";

const ThemeSettingsPage = () => {
  const customerId = useCustomerId();
  return <ThemeSettings key="thisDomain" customerId={customerId} />;
};

export default ThemeSettingsPage;
