import { defineMessages } from "react-intl";

export const messages = defineMessages({
  youAreScreenSharing: {
    id: "videoRoom.settings.screenSharing",
    defaultMessage: "You are screensharing",
    description: "Label that shows that the user has screensharing on",
  },
  spinnerLabel: {
    id: "videoRoom.spinner.label",
    defaultMessage: "Initiating visit",
    description: "spinner text",
  },
  alertDialogTitleHardwareUnavailable: {
    id: "dialog.videoRoom.hardwareUnavailable.alertDialog.title",
    defaultMessage: "Could not join meeting",
    description: "Title for alert dialog (hardwareUnavailable)",
  },
  alertDialogContentHardwareUnavailable: {
    id: "dialog.videoRoom.hardwareUnavailable.alertDialog.content",
    defaultMessage:
      "Your camera and microphone can not be found. Close all other applications and refresh the page, if this does not work it may be a good idea to restart your computer before trying again.",
    description: "Content for alert dialog (hardwareUnavailable)",
  },
  alertDialogSubmitLabelHardwareUnavailable: {
    id: "dialog.videoRoom.hardwareUnavailable.alertDialog.button.submit",
    defaultMessage: "Refresh page",
    description:
      "Button label for submit in alert dialog (hardwareUnavailable)",
  },
  alertDialogCancelLabelHardwareUnavailable: {
    id: "dialog.videoRoom.hardwareUnavailable.alertDialog.button.cancel",
    defaultMessage: "Guide",
    description:
      "Button label for cancel (open guide) in alert dialog (hardwareUnavailable)",
  },
  alertDialogTitleUserMediaAccessDenied: {
    id: "dialog.videoRoom.userMediaAccessDenied.alertDialog.title",
    defaultMessage: "Camera and microphone not granted",
    description: "Title for alert dialog (userMediaAccessDenied)",
  },
  alertDialogContentUserMediaAccessDenied: {
    id: "dialog.videoRoom.userMediaAccessDenied.alertDialog.content",
    defaultMessage:
      "Camara and microphone needs to be granted on this page to be able to join the meeting, when permission is granted refresh the page.",
    description: "Content for alert dialog (userMediaAccessDenied)",
  },
  alertDialogSubmitLabelUserMediaAccessDenied: {
    id: "dialog.videoRoom.userMediaAccessDenied.alertDialog.button.submit",
    defaultMessage: "Refresh page",
    description:
      "Button label for submit in alert dialog (userMediaAccessDenied)",
  },
  alertDialogCancelLabelUserMediaAccessDenied: {
    id: "dialog.videoRoom.userMediaAccessDenied.alertDialog.button.cancel",
    defaultMessage: "Guide",
    description:
      "Button label for cancel (open guide) in alert dialog (userMediaAccessDenied)",
  },

  alertDialogTitleVideoHardwareNotFound: {
    id: "dialog.videoRoom.videoHardwareNotFound.alertDialog.title",
    defaultMessage: "Camera could not be found",
    description: "Title for alert dialog (VideoHardwareNotFound)",
  },
  alertDialogContentVideoHardwareNotFound: {
    id: "dialog.videoRoom.videoHardwareNotFound.alertDialog.content",
    defaultMessage: "Camara could not be found!",
    description: "Content for alert dialog (VideoHardwareNotFound)",
  },
  alertDialogSubmitLabelVideoHardwareNotFound: {
    id: "dialog.videoRoom.videoHardwareNotFound.alertDialog.button.submit",
    defaultMessage: "Refresh page",
    description:
      "Button label for submit in alert dialog (VideoHardwareNotFound)",
  },
  alertDialogCancelLabelVideoHardwareNotFound: {
    id: "dialog.videoRoom.videoHardwareNotFound.alertDialog.button.cancel",
    defaultMessage: "Close",
    description:
      "Button label for cancel (redirect) in alert dialog (VideoHardwareNotFound)",
  },
  alertDialogTitleAudioHardwareNotFound: {
    id: "dialog.videoRoom.audioHardwareNotFound.alertDialog.title",
    defaultMessage: "Camera could not be found",
    description: "Title for alert dialog (AudioHardwareNotFound)",
  },
  alertDialogContentAudioHardwareNotFound: {
    id: "dialog.videoRoom.audioHardwareNotFound.alertDialog.content",
    defaultMessage: "Camara could not be found!",
    description: "Content for alert dialog (AudioHardwareNotFound)",
  },
  alertDialogSubmitLabelAudioHardwareNotFound: {
    id: "dialog.videoRoom.audioHardwareNotFound.alertDialog.button.submit",
    defaultMessage: "Refresh page",
    description:
      "Button label for submit in alert dialog (AudioHardwareNotFound)",
  },
  alertDialogCancelLabelAudioHardwareNotFound: {
    id: "dialog.videoRoom.audioHardwareNotFound.alertDialog.button.cancel",
    defaultMessage: "Close",
    description:
      "Button label for cancel (redirect) in alert dialog (AudioHardwareNotFound)",
  },
  alertBannerMacOSReactionsInfo: {
    id: "videoRoom.alert.macOsReactionsInfo.text",
    defaultMessage:
      "When using an Apple computer, 3D effects may appear in the video stream when you make various gestures. This is not part of CarePlatform but a functionality provided by Apple. Apple describes how this works and how to deactivate the function over {link}",
    description: "Banner for Mac OS reactions info",
  },
  alertBannerMacOSReactionsInfoLink: {
    id: "videoRoom.alert.macOsReactionsInfo.link",
    defaultMessage: "https://support.apple.com/en-gb/105117",
    description: "Link to apple support for Mac OS reactions",
  },
  alertBannerMacOSReactionsInfoLinkName: {
    id: "videoRoom.alert.macOsReactionsInfo.link.name",
    defaultMessage: "here",
    description: "Clickable link name to apple support for Mac OS reactions",
  },
  meetingHasEnded: {
    id: "session.error.meeting.meetingHasEnded.description",
    defaultMessage: "The meeting has ended",
    description: "Notify the patient when the caregiver ends the meeting",
  },
});
