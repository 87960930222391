import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import { useIntl } from "react-intl";
import format from "date-fns/format";
import { useSnackbar } from "notistack";
import {
  CreateCustomerUserAgreementRequest,
  CustomerUserAgreementType,
  UserAgreementRequest,
} from "../../../generated/customercontent-api";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import { customerContentService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { messages } from "./CreateConsentAgreementDialog.messages";
import { useStyles } from "./CreateConsentAgreementDialog.style";
import CardHeading from "../../CardHeading/CardHeading";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeTab: number;
  customerId?: string;
}

const CreateConsentAgreementDialog = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const { onClose, isOpen, activeTab, customerId } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [selectType, setSelectType] = useState<CustomerUserAgreementType>();
  const [textTitle, setTextTitle] = useState("");
  const [textContent, setTextContent] = useState("");
  const [validFromDate, setValidFromDate] = useState(new Date());

  const loadConsentAgreement = () => {
    if (customerId && selectType) {
      customerContentService()
        .getCustomerUserAgreementByType({
          customerId,
          agreementType: selectType,
        })
        .then((e) => {
          setTextTitle(e.title);
          setTextContent(e.contents);
        });
    }
  };

  const handleSaveButton = () => {
    if (
      customerId &&
      selectType &&
      validFromDate &&
      textTitle &&
      textContent.replace(/<(.|\n)*?>/g, "").trim().length > 0
    ) {
      customerContentService(true)
        .createCustomerUserAgreement({
          customerId,
          agreementType: selectType,
          userAgreementRequest: {
            title: textTitle,
            contents: textContent,
            validFrom: validFromDate,
          } as UserAgreementRequest,
        } as CreateCustomerUserAgreementRequest)
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.successOnCreateMessage), {
            variant: "success",
          });
          onClose();
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar(intl.formatMessage(messages.errorMissingFieldsMessage), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSelectType(
        activeTab === 0
          ? CustomerUserAgreementType.ConsentAgreement
          : CustomerUserAgreementType.PrivacyPolicy
      );
    }
  }, [isOpen, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectType) {
      loadConsentAgreement();
    }
  }, [selectType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      fullScreen={fullScreen}
      onClose={onClose}
    >
      <DialogCloseButton onClose={onClose} />
      <Grid container className={classes.container}>
        <Grid item xs={6} className={classes.gridSelect}>
          <TextField
            id="select"
            value={selectType}
            label={intl.formatMessage(messages.dialogSelectLabel)}
            variant="outlined"
            select
            className={classes.select}
            onChange={(e) =>
              setSelectType(e.target.value as CustomerUserAgreementType)
            }
          >
            <MenuItem value={CustomerUserAgreementType.ConsentAgreement}>
              {intl.formatMessage(messages.dialogSelectOptionConsent)}
            </MenuItem>
            <MenuItem value={CustomerUserAgreementType.PrivacyPolicy}>
              {intl.formatMessage(messages.dialogSelectOptionPolicy)}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} className={classes.gridDate}>
          <TextField
            id="date"
            label={intl.formatMessage(messages.validFromDateLabel)}
            type="date"
            variant="outlined"
            value={format(validFromDate, "yyyy-MM-dd")}
            onChange={(e) => setValidFromDate(new Date(e.target.value))}
            className={classes.date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridTextField}>
          <CardHeading noMarginBottom>Title</CardHeading>
          <TextField
            value={textTitle}
            type="string"
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            autoComplete="off"
            className={classes.textField}
            onChange={(e) => setTextTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridTextField}>
          <CardHeading noMarginBottom>Content</CardHeading>
          <ReactQuill
            theme="snow"
            modules={{ toolbar: true }}
            value={textContent}
            className={classes.textField}
            onChange={(e) => setTextContent(e)}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridButton}>
          <Button
            onClick={handleSaveButton}
            size="medium"
            color="primary"
            variant="contained"
          >
            {intl.formatMessage(messages.saveButtonLabel)}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CreateConsentAgreementDialog;
