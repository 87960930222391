/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateToDoRequest,
    CreateToDoRequestFromJSON,
    CreateToDoRequestToJSON,
    CreateToDoResponse,
    CreateToDoResponseFromJSON,
    CreateToDoResponseToJSON,
    PaginatedToDoResponseList,
    PaginatedToDoResponseListFromJSON,
    PaginatedToDoResponseListToJSON,
    ToDoResponse,
    ToDoResponseFromJSON,
    ToDoResponseToJSON,
    ToDoStatusType,
    ToDoStatusTypeFromJSON,
    ToDoStatusTypeToJSON,
    UpdateToDoRequest,
    UpdateToDoRequestFromJSON,
    UpdateToDoRequestToJSON,
} from '../models';

export interface CreateToDosRequest {
    customerId: string;
    createToDoRequest: CreateToDoRequest;
}

export interface DeleteToDoRequest {
    customerId: string;
    todoId: string;
}

export interface GetToDoRequest {
    customerId: string;
    todoId: string;
}

export interface ListToDosRequest {
    customerId: string;
    assignedBy?: string;
    assignedTo?: string;
    relatedTo?: string;
    meeting?: string;
    status?: ToDoStatusType;
    page?: number;
    pageSize?: number;
}

export interface UpdateToDoOperationRequest {
    customerId: string;
    todoId: string;
    updateToDoRequest: UpdateToDoRequest;
}

/**
 * 
 */
export class TodoApi extends runtime.BaseAPI {

    /**
     * Create ToDos assigned to one or more users. ToDo can have a connection to a meeting.
     * Create one of more new ToDos
     */
    async createToDosRaw(requestParameters: CreateToDosRequest): Promise<runtime.ApiResponse<CreateToDoResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createToDos.');
        }

        if (requestParameters.createToDoRequest === null || requestParameters.createToDoRequest === undefined) {
            throw new runtime.RequiredError('createToDoRequest','Required parameter requestParameters.createToDoRequest was null or undefined when calling createToDos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/todo/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateToDoRequestToJSON(requestParameters.createToDoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateToDoResponseFromJSON(jsonValue));
    }

    /**
     * Create ToDos assigned to one or more users. ToDo can have a connection to a meeting.
     * Create one of more new ToDos
     */
    async createToDos(requestParameters: CreateToDosRequest): Promise<CreateToDoResponse> {
        const response = await this.createToDosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Permanently delete a specific ToDo.
     * Delete a ToDo
     */
    async deleteToDoRaw(requestParameters: DeleteToDoRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteToDo.');
        }

        if (requestParameters.todoId === null || requestParameters.todoId === undefined) {
            throw new runtime.RequiredError('todoId','Required parameter requestParameters.todoId was null or undefined when calling deleteToDo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/todo/{customerId}/{todoId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"todoId"}}`, encodeURIComponent(String(requestParameters.todoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permanently delete a specific ToDo.
     * Delete a ToDo
     */
    async deleteToDo(requestParameters: DeleteToDoRequest): Promise<void> {
        await this.deleteToDoRaw(requestParameters);
    }

    /**
     * Get specific ToDo by id.
     * Get a ToDo
     */
    async getToDoRaw(requestParameters: GetToDoRequest): Promise<runtime.ApiResponse<ToDoResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getToDo.');
        }

        if (requestParameters.todoId === null || requestParameters.todoId === undefined) {
            throw new runtime.RequiredError('todoId','Required parameter requestParameters.todoId was null or undefined when calling getToDo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/todo/{customerId}/{todoId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"todoId"}}`, encodeURIComponent(String(requestParameters.todoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ToDoResponseFromJSON(jsonValue));
    }

    /**
     * Get specific ToDo by id.
     * Get a ToDo
     */
    async getToDo(requestParameters: GetToDoRequest): Promise<ToDoResponse> {
        const response = await this.getToDoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a list of ToDos matching search params.
     * List ToDos
     */
    async listToDosRaw(requestParameters: ListToDosRequest): Promise<runtime.ApiResponse<PaginatedToDoResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listToDos.');
        }

        const queryParameters: any = {};

        if (requestParameters.assignedBy !== undefined) {
            queryParameters['assignedBy'] = requestParameters.assignedBy;
        }

        if (requestParameters.assignedTo !== undefined) {
            queryParameters['assignedTo'] = requestParameters.assignedTo;
        }

        if (requestParameters.relatedTo !== undefined) {
            queryParameters['relatedTo'] = requestParameters.relatedTo;
        }

        if (requestParameters.meeting !== undefined) {
            queryParameters['meeting'] = requestParameters.meeting;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/todo/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedToDoResponseListFromJSON(jsonValue));
    }

    /**
     * Get a list of ToDos matching search params.
     * List ToDos
     */
    async listToDos(requestParameters: ListToDosRequest): Promise<PaginatedToDoResponseList> {
        const response = await this.listToDosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user to ba a participant of specified meeting (userId is used from the signed in one).
     * Update private notes for meeting belonging to a user
     */
    async updateToDoRaw(requestParameters: UpdateToDoOperationRequest): Promise<runtime.ApiResponse<ToDoResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateToDo.');
        }

        if (requestParameters.todoId === null || requestParameters.todoId === undefined) {
            throw new runtime.RequiredError('todoId','Required parameter requestParameters.todoId was null or undefined when calling updateToDo.');
        }

        if (requestParameters.updateToDoRequest === null || requestParameters.updateToDoRequest === undefined) {
            throw new runtime.RequiredError('updateToDoRequest','Required parameter requestParameters.updateToDoRequest was null or undefined when calling updateToDo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/todo/{customerId}/{todoId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"todoId"}}`, encodeURIComponent(String(requestParameters.todoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateToDoRequestToJSON(requestParameters.updateToDoRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ToDoResponseFromJSON(jsonValue));
    }

    /**
     * Requires user to ba a participant of specified meeting (userId is used from the signed in one).
     * Update private notes for meeting belonging to a user
     */
    async updateToDo(requestParameters: UpdateToDoOperationRequest): Promise<ToDoResponse> {
        const response = await this.updateToDoRaw(requestParameters);
        return await response.value();
    }

}
