import React from "react";
import {
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ListItem from "@material-ui/core/ListItem";
import { MessageAttachment } from "../../../../../../generated/message-api";
import FileActionButton from "../../../../../../Components/Buttons/FileActionButton/FileActionButton";

interface Props {
  attachment: MessageAttachment;
  createdBy: string;
  onDeleted?: () => void;
}

const DownloadableFile = (props: Props) => {
  const { attachment, createdBy, onDeleted } = props;
  return (
    <div style={{ width: "100%" }}>
      <ListItem>
        <ListItemIcon>
          <FilePresentIcon style={{ fontSize: "30" }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" noWrap>
            {attachment.name}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          {attachment.name !== "DELETED" && (
            <FileActionButton
              fileId={attachment.id}
              fileName={attachment.name}
              uploadedBy={createdBy}
              onDeleted={onDeleted}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};

export default DownloadableFile;
