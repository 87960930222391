import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { useStyles } from "./TroubleShootButton.style";
import TroubleshootSlider from "../Tabs/Components/TroubleshootSlider/TroubleshootSlider";

const TroubleShootButton = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.root}>
      {isOpen && <TroubleshootSlider onClose={() => setIsOpen(false)} />}
      <IconButton
        edge="end"
        aria-label="menu"
        onClick={() => setIsOpen(true)}
        color="inherit"
        className={classes.icon}
      >
        <HelpIcon />
      </IconButton>
    </div>
  );
};

export default TroubleShootButton;
