import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { messages } from "./FileActionButton.messages";
import Tooltip from "../../Tooltip/Tooltip";
import { fileService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { isAuthenticatedUser } from "../../../Utils/User";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import AlertDialog from "../../Dialogs/AlertDialog/AlertDialog";

interface Props {
  fileId: string;
  fileName: string;
  uploadedBy?: string;
  onDownloaded?: () => void;
  onDeleted?: () => void;
}

const FileActionButton = (props: Props) => {
  const intl = useIntl();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isDeleteDialogOpen, setIsDeleteDialogIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const { fileId, fileName, uploadedBy, onDownloaded, onDeleted } = props;

  const handleClose = () => {
    setAnchorEl(null);
    setIsDeleteDialogIsOpen(false);
  };

  const handleDelete = (): void => {
    if (customerId) {
      fileService()
        .deleteFile({
          customerId,
          fileId,
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.deleteSuccess), {
            variant: "success",
          })
        )
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          })
        )
        .finally(() => {
          if (onDeleted) {
            onDeleted();
          }
          handleClose();
        });
    }
  };

  const handleDownload = (blob: Blob): void => {
    const tempLink = document.createElement("a");
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = fileName;
    tempLink.click();
  };

  const handleDownloadClick = (download?: boolean): void => {
    // Trigger window.open() outside async method to be able to make it work for safari on iphone
    let windowRef: Window | null = null;
    if (!download) {
      windowRef = window.open();
    }

    if (customerId) {
      fileService()
        .downloadFileRaw({
          customerId,
          fileId,
        })
        .then((res) => Promise.resolve(res.raw.blob()))
        .then((blob) => {
          if (download) {
            handleDownload(blob);
          } else if (windowRef !== null) {
            // @ts-ignore
            windowRef.location = URL.createObjectURL(blob);
          }
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          })
        )
        .finally(() => {
          if (onDownloaded) {
            onDownloaded();
          }
          handleClose();
        });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={intl.formatMessage(messages.dialogTitle)}
        content={intl.formatMessage(messages.dialogContent)}
        onSubmit={handleDelete}
        onClose={handleClose}
        submitLabel={intl.formatMessage(messages.submitDelete)}
        cancelLabel={intl.formatMessage(messages.cancelDelete)}
      />
      <Tooltip title={intl.formatMessage(messages.buttonTooltip)}>
        <IconButton edge="end" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="file-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDownloadClick(true)}>
          {intl.formatMessage(messages.downloadLabel)}
        </MenuItem>
        <MenuItem onClick={() => handleDownloadClick(false)}>
          {intl.formatMessage(messages.openInNewTabLabel)}
        </MenuItem>
        {isAuthenticatedUser(uploadedBy, authenticatedUser.user) && (
          <MenuItem onClick={() => setIsDeleteDialogIsOpen(true)}>
            {intl.formatMessage(messages.deleteLabel)}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default FileActionButton;
