import React, { useState } from "react";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useIntl } from "react-intl";
import { useStyles } from "./MuteAudioButton.style";
import { messages } from "./MuteAudioButton.messages";
import useDevices from "../../hooks/useDevices";

interface Props {
  toggleAudio: () => void;
  hasAudio: boolean;
  getAudioSource: () => Promise<string | undefined>;
  cameraPublishing?: boolean;
  changeAudioSource: (device: any) => void;
}

export default function MuteAudioButton(props: Props) {
  const {
    toggleAudio,
    hasAudio,
    getAudioSource,
    cameraPublishing,
    changeAudioSource,
  } = props;
  const intl = useIntl();
  const classes = useStyles();

  const title = hasAudio
    ? intl.formatMessage(messages.disableMic)
    : intl.formatMessage(messages.enableMic);
  const deviceInfo: any = useDevices().deviceInfo; // eslint-disable-line
  const [devicesAvailable, setDevicesAvailable] = useState<any[]>([]); // eslint-disable-line
  const [options, setOptions] = useState<OT.Device[]>([]);
  const [open, setOpen] = useState(false);
  const anchorRef: any = React.useRef(null);// eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [audioDeviceId, setAudioDeviceId] = useState<string | undefined>("");

  React.useEffect(() => {
    setDevicesAvailable(deviceInfo.audioInputDevices);
    if (cameraPublishing && devicesAvailable) {
      getAudioSource().then((id) => setAudioDeviceId(id));

      const indexOfSelectedElement = devicesAvailable.indexOf(
        devicesAvailable.find((e) => e.deviceId === audioDeviceId)
      );

      setSelectedIndex(indexOfSelectedElement);
    }
  }, [
    cameraPublishing,
    getAudioSource,
    deviceInfo,
    audioDeviceId,
    devicesAvailable,
  ]);

  React.useEffect(() => {
    if (devicesAvailable) {
      const audioDevicesAvailable: OT.Device[] = devicesAvailable.map((e) => {
        return e.label;
      });
      setOptions(audioDevicesAvailable);
    }
  }, [devicesAvailable]);

  const handleChangeAudioSource = (event: any, index: number) => { // eslint-disable-line
    setSelectedIndex(index);
    setOpen(false);
    const audioDeviceIds = devicesAvailable.find(
      (device) => device.label === event.target.textContent
    ).deviceId;
    changeAudioSource(audioDeviceIds);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        disableElevation
        style={{
          margin: "8px",
          color: "white",
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        // color="white"
      >
        <Tooltip title={title} aria-label="add">
          <IconButton
            edge="start"
            color="primary"
            aria-label="mic"
            onClick={toggleAudio}
            className={`${classes.arrowButton} ${
              !hasAudio ? classes.disabledButton : ""
            }
        `}
          >
            {!hasAudio ? (
              <MicOffIcon fontSize="inherit" />
            ) : (
              <MicIcon fontSize="inherit" />
            )}
          </IconButton>
        </Tooltip>
        <IconButton
          color="secondary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.arrowButton}
          // color="primary"
        >
          <ArrowDropDownIcon />
        </IconButton>
      </ButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 101 }} // todo temporary fix for a bug in MP 0.1.5
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.deviceId}
                      selected={index === selectedIndex}
                      onClick={(event) => handleChangeAudioSource(event, index)}
                      className={classes.selected}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
