import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
import { Grid, ListItem, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import { messages } from "./AnonymizeFeatures.messages";
import { customerSettingsServiceToken } from "../../Providers/ServiceProvider/ServiceProvider";
import { useStyles } from "./AnonymizeFeatures.styles";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  CustomerFeature,
  CustomerFeatureResponse,
  CustomerFeatureType,
} from "../../generated/customersettings-api";
import { hasPermission } from "../PermissionController/PermissionController";
import { RoutePath } from "../Routes/RoutePath";
import { FeaturesConfig } from "./AnonymizeFeaturesConfig";
import InputText from "../../Views/CustomerSettings/InputText/InputText";

export interface Props {
  customerId?: string;
}

const AnonymizeFeatures = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const featureConfig = FeaturesConfig();
  const [authenticatedUser] = useAuthenticatedUser();
  const [enabledFeatures, setEnabledFeatures] = useState<CustomerFeature[]>();

  const loadSettings = () => {
    if (customerId) {
      customerSettingsServiceToken()
        .listCustomerFeatures({
          customerId,
        })
        .then((res: CustomerFeatureResponse) => {
          setEnabledFeatures(res.features);
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          });
        });
    }
  };

  const enableFeature = (
    feature: CustomerFeatureType,
    value: number
  ): CustomerFeature[] => {
    const selectedFeature = enabledFeatures?.find(
      (customerFeature) => customerFeature.feature === feature
    );

    if (enabledFeatures && !selectedFeature) {
      return [...enabledFeatures, { feature, value: value.toString() }];
    }
    return (
      enabledFeatures?.map((customerFeature) => {
        if (customerFeature.feature === feature) {
          return { feature, value: value.toString() };
        }
        return customerFeature;
      }) || [{ feature, value: value.toString() }]
    );
  };

  const disableFeature = (feature: CustomerFeatureType) => {
    return enabledFeatures
      ? enabledFeatures.filter(
          (customerFeature) => customerFeature.feature !== feature
        )
      : [];
  };

  const updateFeatures = (
    feature: CustomerFeatureType,
    enabled: boolean,
    value: number
  ) => {
    if (customerId) {
      customerSettingsServiceToken()
        .updateCustomerFeatures({
          customerId,
          customerFeature: enabled
            ? enableFeature(feature, value)
            : disableFeature(feature),
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          });
        })
        .finally(loadSettings);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.CustomerSettings],
    allowedRoles: [UserRoleType.Staff, UserRoleType.PlatformAdmin],
  });
  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }

  if (!enabledFeatures) {
    return null;
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {intl.formatMessage(messages.title)}
          </h3>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <List>
            {featureConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < featureConfig.length - 1}
              >
                <InputText
                  label={config.label}
                  featureType={config.feature}
                  initialValue={
                    enabledFeatures?.find(
                      (customerFeature) =>
                        customerFeature.feature === config.feature
                    )?.value || ""
                  }
                  onChanged={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AnonymizeFeatures;
