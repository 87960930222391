import React from "react";
import { SpeedDial, SpeedDialIcon } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import CallEndIcon from "@material-ui/icons/CallEnd";
import RoomWrapper from "@vonage/video-express/dist/mp/room";
import { useStyles } from "./EndCallButton.style";
import EndCallSpeedDialAction from "./Component/EndCallSpeedDialAction/EndCallSpeedDialAction";
import LeaveCallSpeedDialAction from "./Component/LeaveCallSpeedDialAction/LeaveCallSpeedDialAction";

interface Props {
  meetingId: string;
  room: RoomWrapper;
}

export default function EndCallButton(props: Props) {
  const { meetingId, room } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.container}>
      <SpeedDial
        ariaLabel="More options"
        icon={<SpeedDialIcon openIcon={<CloseIcon />} icon={<CallEndIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        FabProps={{ className: classes.speedDialFab }}
      >
        <EndCallSpeedDialAction
          open={open}
          delay={1}
          meetingId={meetingId}
          room={room}
        />
        <LeaveCallSpeedDialAction
          open={open}
          delay={1}
          room={room}
          meetingId={meetingId}
        />
      </SpeedDial>
    </div>
  );
}
