import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = (isSmallScreen: boolean) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
        maxHeight: isSmallScreen ? "90vh" : "40vh",
        overflow: "scroll",
      },
      rootFullHeight: {
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
        overflow: "hidden",
      },
      nothingToShowText: {
        marginBottom: theme.spacing(3),
      },
    });
  });
