import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateTodoDialog from "./Components/CreateTodoDialog/CreateTodoDialog";
import { Contact } from "../../../Models/Contact";
import { MeetingParticipant } from "../../../Models/Meeting";

interface Props {
  onCreated?: () => void;
  label: string;
  dialogTitle: string;
  meetingId?: string;
  participants?: MeetingParticipant[];
  contact?: Contact;
}

const CreateTodoButton = (props: Props) => {
  const {
    label,
    dialogTitle,
    onCreated,
    meetingId,
    contact,
    participants,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <>
      <CreateTodoDialog
        meetingId={meetingId}
        isOpen={isDialogOpen}
        onCreated={onCreated}
        dialogTitle={dialogTitle}
        contact={contact}
        participants={participants}
        onClose={() => setIsDialogOpen(false)}
      />
      <Button
        size="small"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
      >
        {label}
      </Button>
    </>
  );
};

export default CreateTodoButton;
