import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./AccountCompletion.tsx.style";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { RoutePath } from "../../Components/Routes/RoutePath";
import AccountSettingsForm from "../Account/Components/AccountSettingsForm/AccountSettingsForm";

const AccountCompletion = () => {
  const history = useHistory();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();

  if (!authenticatedUser.user) {
    return null;
  }

  return (
    <Grid container className={classes.root} spacing={2} justify="center">
      <Grid item xs={12} md={9}>
        <Paper className={classes.paper}>
          <AccountSettingsForm
            user={authenticatedUser.user}
            onUpdated={() => history.push(RoutePath.DASHBOARD)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AccountCompletion;
