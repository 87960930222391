import React from "react";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "../../CustomFooter.style";
import { useCustomerContent } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { UserRoleType } from "../../../../generated/user-api";
import { messages } from "./ContactDetails.messages";

const ContactDetails = () => {
  const intl = useIntl();
  const customerContent = useCustomerContent();
  const [authenticatedUser] = useAuthenticatedUser();
  const classes = useStyles();
  const {
    contactDetailsPhone,
    contactDetailsEmail,
    contactDetailsOpeningHours,
    technicalSupportEmail,
    technicalSupportPhone,
    technicalSupportOpeningHours,
  } = customerContent;

  if (!authenticatedUser.isLoggedIn) {
    return null;
  }

  if (authenticatedUser.user?.userRole === UserRoleType.Staff) {
    if (
      technicalSupportOpeningHours?.contents ||
      technicalSupportPhone?.contents ||
      technicalSupportEmail?.contents
    ) {
      return (
        <>
          <Typography className={classes.titleText} variant="overline">
            {intl.formatMessage(messages.techSupportTitle)}
          </Typography>
          {customerContent.technicalSupportPhone?.contents && (
            <Typography variant="body2" className={classes.text}>
              {intl.formatMessage(messages.phoneLabel, {
                phone: customerContent.technicalSupportPhone?.contents,
              })}
            </Typography>
          )}
          {customerContent.technicalSupportEmail?.contents && (
            <Typography variant="body2" className={classes.text}>
              {intl.formatMessage(messages.emailLabel, {
                email: customerContent.technicalSupportEmail?.contents,
              })}
            </Typography>
          )}
          {customerContent.technicalSupportOpeningHours?.contents && (
            <Typography variant="body2" className={classes.text}>
              {customerContent.technicalSupportOpeningHours?.contents}
            </Typography>
          )}
        </>
      );
    }
    return null;
  }

  if (
    contactDetailsOpeningHours?.contents ||
    contactDetailsPhone?.contents ||
    contactDetailsEmail?.contents
  ) {
    return (
      <>
        <Typography className={classes.titleText} variant="overline">
          {intl.formatMessage(messages.title)}
        </Typography>
        {customerContent.contactDetailsPhone?.contents && (
          <Typography variant="body2" className={classes.text}>
            {intl.formatMessage(messages.phoneLabel, {
              phone: customerContent.contactDetailsPhone?.contents,
            })}
          </Typography>
        )}
        {customerContent.contactDetailsEmail?.contents && (
          <Typography variant="body2" className={classes.text}>
            {intl.formatMessage(messages.emailLabel, {
              email: customerContent.contactDetailsEmail?.contents,
            })}
          </Typography>
        )}
        {customerContent.contactDetailsOpeningHours?.contents && (
          <Typography variant="body2" className={classes.text}>
            {customerContent.contactDetailsOpeningHours?.contents}
          </Typography>
        )}
      </>
    );
  }

  return null;
};

export default ContactDetails;
