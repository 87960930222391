import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 80,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    cardHeading: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
    basicLook: {
      padding: theme.spacing(0),
    },
  })
);
