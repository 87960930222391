/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Contact API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddContactRequest,
    AddContactRequestFromJSON,
    AddContactRequestToJSON,
    ContactResponse,
    ContactResponseFromJSON,
    ContactResponseToJSON,
    PaginatedContactResponseList,
    PaginatedContactResponseListFromJSON,
    PaginatedContactResponseListToJSON,
    SortByType,
    SortByTypeFromJSON,
    SortByTypeToJSON,
    SortOrderType,
    SortOrderTypeFromJSON,
    SortOrderTypeToJSON,
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeToJSON,
} from '../models';

export interface AddOrUpdateContactRequest {
    customerId: string;
    addContactRequest: AddContactRequest;
}

export interface DeleteContactByIdRequest {
    customerId: string;
    contactId: string;
}

export interface GetContactByIdRequest {
    customerId: string;
    contactId: string;
}

export interface ListContactsForUserRequest {
    customerId: string;
    userId: string;
    userStatus?: UserStatusType;
    page?: number;
    pageSize?: number;
    name?: string;
    sortBy?: SortByType;
    sortOrder?: SortOrderType;
}

/**
 * 
 */
export class ContactApi extends runtime.BaseAPI {

    /**
     * Create or update an interaction with a contact.
     * Create or update contact
     */
    async addOrUpdateContactRaw(requestParameters: AddOrUpdateContactRequest): Promise<runtime.ApiResponse<ContactResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling addOrUpdateContact.');
        }

        if (requestParameters.addContactRequest === null || requestParameters.addContactRequest === undefined) {
            throw new runtime.RequiredError('addContactRequest','Required parameter requestParameters.addContactRequest was null or undefined when calling addOrUpdateContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contact/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddContactRequestToJSON(requestParameters.addContactRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactResponseFromJSON(jsonValue));
    }

    /**
     * Create or update an interaction with a contact.
     * Create or update contact
     */
    async addOrUpdateContact(requestParameters: AddOrUpdateContactRequest): Promise<ContactResponse> {
        const response = await this.addOrUpdateContactRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a contact by id. The user must be the one owning the contact.
     * Delete a contact
     */
    async deleteContactByIdRaw(requestParameters: DeleteContactByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteContactById.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling deleteContactById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact/{customerId}/{contactId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a contact by id. The user must be the one owning the contact.
     * Delete a contact
     */
    async deleteContactById(requestParameters: DeleteContactByIdRequest): Promise<void> {
        await this.deleteContactByIdRaw(requestParameters);
    }

    /**
     * Get a contact by id. The user must be the one owning the contact.
     * Get contact
     */
    async getContactByIdRaw(requestParameters: GetContactByIdRequest): Promise<runtime.ApiResponse<ContactResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getContactById.');
        }

        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId','Required parameter requestParameters.contactId was null or undefined when calling getContactById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact/{customerId}/{contactId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"contactId"}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactResponseFromJSON(jsonValue));
    }

    /**
     * Get a contact by id. The user must be the one owning the contact.
     * Get contact
     */
    async getContactById(requestParameters: GetContactByIdRequest): Promise<ContactResponse> {
        const response = await this.getContactByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all contacts for a specific user. The user must be same as the requesting user.
     * List contacts
     */
    async listContactsForUserRaw(requestParameters: ListContactsForUserRequest): Promise<runtime.ApiResponse<PaginatedContactResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listContactsForUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listContactsForUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.userStatus !== undefined) {
            queryParameters['userStatus'] = requestParameters.userStatus;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contact/{customerId}/user/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedContactResponseListFromJSON(jsonValue));
    }

    /**
     * List all contacts for a specific user. The user must be same as the requesting user.
     * List contacts
     */
    async listContactsForUser(requestParameters: ListContactsForUserRequest): Promise<PaginatedContactResponseList> {
        const response = await this.listContactsForUserRaw(requestParameters);
        return await response.value();
    }

}
