import React from "react";
import linkifyHtml from "linkify-html";
import { Chip } from "@material-ui/core";
import ReactQuill from "react-quill";
import { useStyles } from "./ChatMessage.style";
import ProfilePicture from "../../../ProfilePicture/ProfilePicture";
import { ChatMessageResponse } from "../../../../generated/meetingchat-api";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { MeetingParticipantResponse } from "../../../../generated/meeting-api";
import { isAuthenticatedUser } from "../../../../Utils/User";

interface Props {
  chatMessage: ChatMessageResponse;
  participant?: MeetingParticipantResponse;
  lastMessageRef: React.RefObject<HTMLDivElement> | null;
}

const ChatMessage = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { chatMessage, participant, lastMessageRef } = props;

  return (
    <li
      key={chatMessage.id}
      className={
        isAuthenticatedUser(chatMessage.sentBy, authenticatedUser.user)
          ? classes.right
          : classes.left
      }
    >
      <div
        className={
          isAuthenticatedUser(chatMessage.sentBy, authenticatedUser.user)
            ? classes.chipReverse
            : classes.chip
        }
        ref={lastMessageRef}
      >
        <div
          className={
            isAuthenticatedUser(chatMessage.sentBy, authenticatedUser.user)
              ? classes.chipContainerSecondary
              : classes.chipContainerDefault
          }
        >
          <Chip
            className={classes.avatar}
            avatar={
              <ProfilePicture
                firstName={participant?.firstName}
                lastName={participant?.lastName}
              />
            }
            color={
              isAuthenticatedUser(chatMessage.sentBy, authenticatedUser.user)
                ? "secondary"
                : "default"
            }
          />
          <ReactQuill
            theme="bubble"
            readOnly
            modules={{ toolbar: false }}
            value={linkifyHtml(chatMessage.message.replaceAll("\n", "<br/>"))}
            className={classes.quillEditor}
          />
        </div>
      </div>
    </li>
  );
};

export default ChatMessage;
