import React from "react";
import { Paper, Typography, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./ProfileCard.style";
import { messages } from "./ProfileCard.messages";
import ProfilePicture from "../../../../Components/ProfilePicture/ProfilePicture";
import {
  UserAuthorityType,
  UserResponse,
} from "../../../../generated/user-api";
import PermissionController from "../../../../Components/PermissionController/PermissionController";
import CreateMeetingButton from "../../../../Components/Buttons/CreateMeetingButton/CreateMeetingButton";
import InviteButton from "../../../../Components/Buttons/InviteButton/InviteButton";

interface Props {
  user: UserResponse;
  onMeetingCreated: () => void;
}
const ProfileCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { user, onMeetingCreated } = props;
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  if (isMobileWidth) {
    return (
      <Paper className={classes.root}>
        <div className={classes.profileContainerPhoneView}>
          <ProfilePicture firstName={user.firstName} lastName={user.lastName} />
          <Typography variant="h6" className={classes.fullNamePhoneView}>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
        </div>
        <div className={classes.buttonContainerPhoneView}>
          <PermissionController
            atLeastOneOfAuthoritiesStaff={[UserAuthorityType.BookMeeting]}
          >
            <CreateMeetingButton
              color="secondary"
              className={classes.button}
              onCreated={onMeetingCreated}
              overrideLabel={intl.formatMessage(messages.inviteBtn)}
            />
            <InviteButton
              color="secondary"
              variant="outlined"
              className={classes.button}
            />
          </PermissionController>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.profileContainer}>
        <ProfilePicture size="large" center />
        <Typography variant="h5" className={classes.fullName}>
          {`${user.firstName} ${user.lastName}`}
        </Typography>
        <PermissionController
          atLeastOneOfAuthoritiesStaff={[UserAuthorityType.BookMeeting]}
        >
          <CreateMeetingButton
            color="secondary"
            className={classes.button}
            onCreated={onMeetingCreated}
            overrideLabel={intl.formatMessage(messages.inviteBtn)}
          />
          <InviteButton
            color="secondary"
            variant="outlined"
            className={classes.button}
          />
        </PermissionController>
      </div>
    </Paper>
  );
};

export default ProfileCard;
