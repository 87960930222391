/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Content API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentItemType,
    ContentItemTypeFromJSON,
    ContentItemTypeFromJSONTyped,
    ContentItemTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContentItemTypeRequest
 */
export interface ContentItemTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof ContentItemTypeRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentItemTypeRequest
     */
    contents?: string;
    /**
     * 
     * @type {ContentItemType}
     * @memberof ContentItemTypeRequest
     */
    type: ContentItemType;
}

export function ContentItemTypeRequestFromJSON(json: any): ContentItemTypeRequest {
    return ContentItemTypeRequestFromJSONTyped(json, false);
}

export function ContentItemTypeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentItemTypeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'contents': !exists(json, 'contents') ? undefined : json['contents'],
        'type': ContentItemTypeFromJSON(json['type']),
    };
}

export function ContentItemTypeRequestToJSON(value?: ContentItemTypeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'contents': value.contents,
        'type': ContentItemTypeToJSON(value.type),
    };
}


