import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.primary.contrastText,
      margin: 0,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    selected: {
      color: theme.palette.primary.contrastText,
      textDecoration: "underline",
      margin: 0,
    },
    button: {
      padding: 0,
    },
  })
);
