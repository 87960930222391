/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Template API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Kind of template.
 * @export
 * @enum {string}
 */
export enum MeetingTemplateKindType {
    Public = 'PUBLIC',
    Personal = 'PERSONAL'
}

export function MeetingTemplateKindTypeFromJSON(json: any): MeetingTemplateKindType {
    return MeetingTemplateKindTypeFromJSONTyped(json, false);
}

export function MeetingTemplateKindTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingTemplateKindType {
    return json as MeetingTemplateKindType;
}

export function MeetingTemplateKindTypeToJSON(value?: MeetingTemplateKindType | null): any {
    return value as any;
}

