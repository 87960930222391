import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      height: "8vh",
      justifyContent: "flex-start",
      padding: 0,
      display: "flex",
      alignItems: "center",
      paddingLeft: 20,
      width: "100vw",
    },
  })
);
