import React, { useState } from "react";
import { Button } from "@material-ui/core";
import CancelMeetingDialog from "./CancelMeetingDialog";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import {
  MeetingResponse,
  MeetingStatusType,
  SourceType,
} from "../../../generated/meeting-api";
import { Variant } from "../../BookingsList/Components/Variant/Variant";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  label: string;
  contextVariant: Variant;
  meeting: MeetingResponse;
  className?: string;
  onCanceledMeeting?: () => void;
}

const CancelMeetingButton = (props: Props) => {
  const {
    meeting,
    label,
    className,
    onCanceledMeeting,
    contextVariant,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [authenticatedUser] = useAuthenticatedUser();
  const isNotMeetingParticipant: boolean =
    meeting.participants?.filter((participant) => {
      return participant.userId === authenticatedUser.user?.id;
    }).length === 0;

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  if (meeting.source !== SourceType.Careplatform) {
    return null;
  }

  if (
    ((contextVariant === Variant.ADMIN_UPCOMING_BOOKINGS &&
      (!isNotMeetingParticipant ||
        authenticatedUser.user?.userAuthorities?.includes(
          UserAuthorityType.BookMeetingOther
        ))) ||
      contextVariant === Variant.UPCOMING_BOOKINGS ||
      contextVariant === Variant.ONGOING_BOOKING_REQUESTS) &&
    meeting.status === MeetingStatusType.Created
  ) {
    return (
      <PermissionController
        allowedRoles={[
          UserRoleType.Staff,
          UserRoleType.External,
          UserRoleType.PlatformAdmin,
        ]}
        atLeastOneOfAuthoritiesStaff={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
          UserAuthorityType.AdminSite,
        ]}
        atLeastOneOfAuthoritiesExternal={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
          UserAuthorityType.AdminSite,
        ]}
        atLeastOneOfAuthoritiesPlatformAdmin={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
          UserAuthorityType.AdminSite,
        ]}
      >
        <CancelMeetingDialog
          isOpen={isDialogOpen}
          meetingId={meeting.id}
          meetingTitle={meeting.title || ""}
          onClose={() => setIsDialogOpen(false)}
          onCanceledMeeting={onCanceledMeeting}
        />
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          className={className}
          onClick={handleOpenDialog}
        >
          {label}
        </Button>
      </PermissionController>
    );
  }

  return null;
};

export default CancelMeetingButton;
