import React, { useEffect, useState } from "react";
import { Typography, Chip } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./CalendarEventToolTip.style";
import {
  MeetingParticipantResponse,
  MeetingResponse,
  MeetingStatusType,
} from "../../../../generated/meeting-api";
import { messages } from "./CalendarEventToolTip.messages";
import {
  getFormattedDate,
  getFormattedTimespan,
  getNumberOfConfirmedParticipants,
  createSortedList,
} from "../../../../Utils/Meeting";
import { getMeetingStatusLabel } from "../../../meetingStatusChip/meetingStatusChip";
import ParticipantList from "../../../Tooltip/ParticipantList/ParticipantList";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  eventDetails: MeetingResponse;
}
const CalendarEventToolTip = (props: Props) => {
  const { eventDetails } = props;
  const statusType: MeetingStatusType = eventDetails.status;
  const [authenticatedUser] = useAuthenticatedUser();
  const [sortedList, setSortedList] = useState<MeetingParticipantResponse[]>();
  useEffect(() => {
    if (eventDetails.participants) {
      setSortedList(
        createSortedList(eventDetails.participants, authenticatedUser.user)
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.tooltipMinWidth}>
      <Typography variant="h6">{eventDetails.title}</Typography>
      <Chip
        size="small"
        className={classes[statusType]}
        label={getMeetingStatusLabel(eventDetails.status, intl)}
      />
      <div className={classes.dateTimeFormat}>
        <div>
          <p className={classes.titleSize}>
            {intl.formatMessage(messages.dateTitle)}
          </p>
          <Typography>
            {getFormattedDate(eventDetails.startDateTime)}
          </Typography>
          <Typography>
            {getFormattedTimespan(
              eventDetails.startDateTime,
              eventDetails.duration
            )}
          </Typography>
        </div>
      </div>
      <div>
        <p className={classes.titleSize}>
          {intl.formatMessage(messages.participantsTitle)}
          {`  ${getNumberOfConfirmedParticipants(
            eventDetails.participants
          )} ${intl.formatMessage(messages.statusOf)} ${
            eventDetails.participants?.length
          }`}
        </p>
      </div>
      <ParticipantList participants={sortedList} />
    </div>
  );
};

export default CalendarEventToolTip;
