import { UserResponse, UserRoleType } from "../generated/user-api";
import {
  MeetingParticipantRequest,
  MeetingParticipantResponse,
  MeetingParticipantType,
  MeetingRoleType,
} from "../generated/meeting-api";
import { Contact } from "../Models/Contact";
import { User } from "../Models/User";
import { MeetingParticipant } from "../Models/Meeting";

export const contactToUser = (contact: Contact): User => {
  return {
    id: contact.userId,
    personNumber: contact.personNumber,
    firstName: contact.firstName,
    lastName: contact.lastName,
    workTitle: contact.workTitle,
    email: contact.email,
    mobilePhoneNumber: contact.mobilePhoneNumber,
    userRole: contact.userRole,
  } as User;
};

export const userResponseToMeetingParticipantRequest = (
  userResponse: UserResponse
): MeetingParticipantRequest => {
  return {
    userId: userResponse.id,
    meetingRole:
      userResponse.userRole === UserRoleType.Staff
        ? MeetingRoleType.MeetingLeader
        : MeetingRoleType.Attendee,
  } as MeetingParticipantRequest;
};

export const meetingParticipantTypeToUserRoleType = (
  meetingParticipantType: MeetingParticipantType
): UserRoleType => {
  switch (meetingParticipantType) {
    case MeetingParticipantType.External:
      return UserRoleType.External;
    case MeetingParticipantType.Client:
      return UserRoleType.Client;
    case MeetingParticipantType.Staff:
      return UserRoleType.Staff;
    default:
      return UserRoleType.Client;
  }
};

export const userRoleToMeetingParticipantType = (
  userRole: UserRoleType
): MeetingParticipantType => {
  switch (userRole) {
    case UserRoleType.External:
      return MeetingParticipantType.External;
    case UserRoleType.Client:
      return MeetingParticipantType.Client;
    case UserRoleType.Staff:
      return MeetingParticipantType.Staff;
    default:
      return MeetingParticipantType.Client;
  }
};

export const meetingParticipantToUser = (
  meetingParticipant: MeetingParticipant
): User => {
  return {
    id: meetingParticipant.userId,
    personNumber: meetingParticipant.personNumber,
    firstName: meetingParticipant.firstName,
    lastName: meetingParticipant.lastName,
    email: meetingParticipant.email,
    workTitle: meetingParticipant.workTitle,
    userRole: meetingParticipantTypeToUserRoleType(
      meetingParticipant.participantType
    ),
  } as User;
};
export const meetingParticipantListToUserList = (
  meetingParticipantList?: MeetingParticipant[]
): User[] => {
  if (meetingParticipantList) {
    return meetingParticipantList.map(meetingParticipantToUser);
  }
  return [];
};

export const meetingParticipantResponseToContactUserResponse = (
  meetingParticipantResponse?: MeetingParticipantResponse
): Contact | undefined => {
  if (!meetingParticipantResponse) {
    return undefined;
  }

  return {
    userId: meetingParticipantResponse.userId,
    firstName: meetingParticipantResponse.firstName,
    lastName: meetingParticipantResponse.lastName,
    workTitle: meetingParticipantResponse.workTitle,
    userRole: meetingParticipantTypeToUserRoleType(
      meetingParticipantResponse.participantType
    ),
  } as Contact;
};
