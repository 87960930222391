import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "&:hover": {
        backgroundColor: theme.palette.grey.A100,
      },
    },
    buttonGrid: {
      textAlign: "end",
      alignSelf: "flex-end",
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);
