import { defineMessages } from "react-intl";

export const messages = defineMessages({
  searchInviteLabel: {
    id: "dialog.addOrCreateUser.field.searchQuery.invite.label",
    defaultMessage: "Personal identity number or email address",
    description: "label for personnumber or email field",
  },
  searchCreateLabel: {
    id: "dialog.addOrCreateUser.field.searchQuery.create.label",
    defaultMessage: "Personal identity number or email address",
    description: "label for personnumber or email field",
  },
  searchCreateOrInviteLabel: {
    id: "dialog.addOrCreateUser.field.searchQuery.createOrInvite.label",
    defaultMessage: "Personal identity number or email address",
    description: "label for personnumber or email field",
  },
});
