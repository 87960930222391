import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useIntl } from "react-intl";
import CreateOrUpdateCustomerDialog, {
  Variant,
} from "../../Dialogs/CreateOrUpdateCustomerDialog/CreateOrUpdateCustomerDialog";
import { messages } from "./CreateOrUpdateCustomerButton.messages";
import { CustomerResponse } from "../../../generated/customer-api";

interface Props {
  onClose: () => void;
  initialCustomerValues?: CustomerResponse;
  variant: Variant;
}

const CreateOrUpdateCustomerButton = (props: Props) => {
  const intl = useIntl();
  const { onClose, initialCustomerValues, variant } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    onClose();
  };
  return (
    <>
      <CreateOrUpdateCustomerDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        initialCustomerValues={initialCustomerValues}
        variant={variant}
      />
      <Button
        size="small"
        color="default"
        variant="text"
        startIcon={variant === Variant.UPDATE ? <EditIcon /> : <AddIcon />}
        onClick={() => setIsDialogOpen(true)}
      >
        {variant === Variant.UPDATE
          ? intl.formatMessage(messages.buttonUpdateLabel)
          : intl.formatMessage(messages.buttonCreateLabel)}
      </Button>
    </>
  );
};

export default CreateOrUpdateCustomerButton;
