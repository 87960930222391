/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Contact API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User role
 * @export
 * @enum {string}
 */
export enum UserRoleType {
    Client = 'CLIENT',
    Staff = 'STAFF',
    External = 'EXTERNAL'
}

export function UserRoleTypeFromJSON(json: any): UserRoleType {
    return UserRoleTypeFromJSONTyped(json, false);
}

export function UserRoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRoleType {
    return json as UserRoleType;
}

export function UserRoleTypeToJSON(value?: UserRoleType | null): any {
    return value as any;
}

