import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useIntl } from "react-intl";
import { MeetingResponse } from "../../../../generated/meeting-api";
import {
  getFormattedDate,
  getFormattedTimespan,
} from "../../../../Utils/Meeting";
import { useStyles } from "./BookingsListCard.style";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { messages } from "./BookingsListCard.messages";
import CustomPagination from "../../../../Components/CustomPagination/Pagination";
import { Pagination } from "../../../../generated/user-api";

interface Props {
  showSummary: boolean;
  selectedMeeting?: MeetingResponse;
  meetings?: MeetingResponse[];
  onItemClick: (meeting?: MeetingResponse) => void;
  pagination?: Pagination;
  onPaginationChange: (page: number) => void;
}

const BookingsListCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    showSummary,
    meetings,
    selectedMeeting,
    onItemClick,
    pagination,
    onPaginationChange,
  } = props;

  return (
    <Paper className={classes.paper}>
      {showSummary && (
        <>
          <CardHeading noMarginBottom>
            {intl.formatMessage(messages.summaryLabel).toUpperCase()}
          </CardHeading>
          <List component="nav" aria-label="Summary">
            <ListItem
              key="summary"
              button
              onClick={() => onItemClick()}
              selected={!selectedMeeting}
            >
              {!selectedMeeting && (
                <ListItemIcon>
                  <ArrowForwardIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={intl.formatMessage(messages.personOverviewLabel)}
              />
            </ListItem>
          </List>
        </>
      )}
      <CardHeading noMarginBottom>
        {intl.formatMessage(messages.bookingsLabel).toUpperCase()}
      </CardHeading>

      {meetings && (
        <>
          <List component="nav" aria-label="Bookings">
            {meetings?.map((meeting) => (
              <ListItem
                key={meeting.id}
                button
                onClick={() => onItemClick(meeting)}
                selected={meeting.id === selectedMeeting?.id}
              >
                {meeting.id === selectedMeeting?.id && (
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={meeting.title}
                  secondary={
                    meeting?.startDateTime &&
                    meeting?.duration &&
                    `${getFormattedDate(
                      meeting.startDateTime
                    )} (${getFormattedTimespan(
                      meeting.startDateTime,
                      meeting.duration
                    )})`
                  }
                />
              </ListItem>
            ))}
          </List>
          <CustomPagination
            onChange={onPaginationChange}
            pagination={pagination}
          />
        </>
      )}
    </Paper>
  );
};

export default BookingsListCard;
