import React from "react";
import { Chip } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { useStyles } from "./StatusChip.style";
import { messages } from "./StatusChip.messages";
import { UserStatusType } from "../../generated/user-api";
import { UserStatusType as MessageStatusType } from "../../generated/message-api";
import { UserStatusType as ContactStatusType } from "../../generated/contact-api";
import { CustomerStatusType } from "../../generated/customer-api";

interface Props {
  status:
    | UserStatusType
    | MessageStatusType
    | ContactStatusType
    | CustomerStatusType;
}

export const getUserStatusLabel = (
  userStatus:
    | UserStatusType
    | MessageStatusType
    | ContactStatusType
    | CustomerStatusType,
  intl: IntlShape
): string => {
  switch (userStatus) {
    case "ACTIVE":
      return intl.formatMessage(messages.statusActive);
    case "INACTIVE":
      return intl.formatMessage(messages.statusInactive);
    case "CLOSED":
      return intl.formatMessage(messages.statusClosed);
    default:
      return "unknown";
  }
};

const StatusChip = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { status } = props;

  return (
    <Chip
      size="small"
      className={classes[status]}
      label={getUserStatusLabel(status, intl)}
    />
  );
};

export default StatusChip;
