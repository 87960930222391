import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "session.troubleshoot.title",
    defaultMessage: "Troubleshooting",
    description: "Heading for toubleshooting card in meeting session view",
  },
  refreshTitle: {
    id: "session.troubleshoot.refresh.title",
    defaultMessage: "Refresh the page",
    description: "Heading for section refresh page in session view",
  },
  refreshDescription: {
    id: "session.troubleshoot.refresh.description",
    defaultMessage:
      "Sometimes, the easiest way to fix any problem you might encounter is to refresh the page. To do so, press F5 or click on this button:",
    description: "Description for section refresh page in session view",
  },
  stillProblemTitle: {
    id: "session.troubleshoot.stillProblem.title",
    defaultMessage:
      "If you still are experiencing problems with the camera and/or sound, you can try the following",
    description:
      "Heading for section still experiencing problem in session view",
  },
  leaveMeeting: {
    id: "session.troubleshoot.leaveMeeting",
    defaultMessage: "Leave and join the meeting.",
    description:
      "Bullet point for common fixes, leave and join meeting in session view",
  },
  connection: {
    id: "session.troubleshoot.connection",
    defaultMessage: "Make sure that you have good internet connection",
    description:
      "Bullet point for common fixes, check internet connection, in session view",
  },
  headphones: {
    id: "session.troubleshoot.headphones",
    defaultMessage:
      "In case of sound issues, try both with and without headphones",
    description:
      "Bullet point for common fixes, check headphones, in session view",
  },
  access: {
    id: "session.troubleshoot.access",
    defaultMessage: "Allow access to camera and sound in the browser",
    description:
      "Bullet point for common fixes, check allow access, in session view",
  },
  speakersTurnedOn: {
    id: "session.troubleshoot.speakersTurnedOn",
    defaultMessage: "Check that the speakers are turned on in the hardware",
    description:
      "Bullet point for common fixes, turn on speakers, in session view",
  },
  correctHardware: {
    id: "session.troubleshoot.correctHardware",
    defaultMessage:
      "Make sure that the correct camera, microphone and speakers are in use in case you have multiple alternatives or if the hardware is connected to another screen",
    description:
      "Bullet point for common fixes, check camera and speaker are correct, in session view",
  },
  closePrograms: {
    id: "session.troubleshoot.closePrograms",
    defaultMessage:
      "Close potential other programs using camera, microphone or speakers",
    "session.troubleshoot.requirements.title":
      "To take part in a meeting you need:",
    description:
      "Bullet point for common fixes, close other programs, in session view",
  },
  requirementsTitle: {
    id: "session.troubleshoot.requirements.title",
    defaultMessage: "To take part in a meeting you need:",
    description: "Heading for section requirements in session view",
  },
  requirementsComputer: {
    id: "session.troubleshoot.requirements.computer",
    defaultMessage:
      "A computer with camera and microphone as well as an updated version of Google Chrome or Apple Safari.",
    description: "Bullet point for requirements, computer, in session view",
  },
  requirementsApple: {
    id: "session.troubleshoot.requirements.appleDevice",
    defaultMessage:
      "Iphone or iPad with iOS 11 or later and an updated version of Apple Safari.",
    description: "Bullet point for requirements, apple device, in session view",
  },
  requirementsAndroid: {
    id: "session.troubleshoot.requirements.androidDevice",
    defaultMessage:
      "Android mobile phone or pad with an updated version of Google Chrome.",
    description:
      "Bullet point for requirements, andriod device, in session view",
  },
});
