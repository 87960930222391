import React from "react";
import { Grid, Switch, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useIntl } from "react-intl";
import {
  CustomerFeature,
  CustomerFeatureType,
} from "../../../generated/customersettings-api";
import { messages } from "./SwitchButton.messages";

export interface Props {
  label: string;
  featureType: CustomerFeatureType;
  enabledCustomerFeatures: CustomerFeature[];
  onSwitched: (featureType: CustomerFeatureType, enabled: boolean) => void;
}

const SwitchButton = (props: Props) => {
  const intl = useIntl();
  const { label, onSwitched, featureType, enabledCustomerFeatures } = props;

  return (
    <Grid container alignItems="center">
      {featureType === CustomerFeatureType.WebdocUpdateBookingButton &&
        !!enabledCustomerFeatures.find(
          (customerFeature) => customerFeature.feature === featureType
        ) && (
          <Grid item xs={12}>
            <Alert severity="info">
              {intl.formatMessage(messages.alertMessage)}
            </Alert>
          </Grid>
        )}
      <Grid item sm={8} xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Switch
          defaultChecked
          checked={
            !!enabledCustomerFeatures.find(
              (customerFeature) => customerFeature.feature === featureType
            )
          }
          onChange={(event, enabled) => onSwitched(featureType, enabled)}
        />
      </Grid>
    </Grid>
  );
};

export default SwitchButton;
