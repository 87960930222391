/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttendanceStatusType,
    AttendanceStatusTypeFromJSON,
    AttendanceStatusTypeFromJSONTyped,
    AttendanceStatusTypeToJSON,
} from './';

/**
 * Status of this participant
 * @export
 * @interface ParticipantAttendance
 */
export interface ParticipantAttendance {
    /**
     * 
     * @type {AttendanceStatusType}
     * @memberof ParticipantAttendance
     */
    status: AttendanceStatusType;
    /**
     * Date and time this particpants status was last updated
     * @type {Date}
     * @memberof ParticipantAttendance
     */
    updatedOn?: Date;
}

export function ParticipantAttendanceFromJSON(json: any): ParticipantAttendance {
    return ParticipantAttendanceFromJSONTyped(json, false);
}

export function ParticipantAttendanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantAttendance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': AttendanceStatusTypeFromJSON(json['status']),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
    };
}

export function ParticipantAttendanceToJSON(value?: ParticipantAttendance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': AttendanceStatusTypeToJSON(value.status),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
    };
}


