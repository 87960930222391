import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: theme.spacing(2),
    alignItems: "center",
    minWidth: 0, // makes sure truncation works fine
  },
}));
