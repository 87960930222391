import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmed: {
      color: theme.palette.success.main,
    },
    notConfirmed: {
      color: theme.palette.warning.main,
    },
    declined: {
      color: theme.palette.error.main,
    },
  })
);
