/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Customer features type
 * @export
 * @enum {string}
 */
export enum CustomerFeatureType {
    DebugView = 'DEBUG_VIEW',
    Messages = 'MESSAGES',
    OneWayMessage = 'ONE_WAY_MESSAGE',
    ExternalUsers = 'EXTERNAL_USERS',
    InMeetingChat = 'IN_MEETING_CHAT',
    InMeetingNotes = 'IN_MEETING_NOTES',
    InMeetingTodo = 'IN_MEETING_TODO',
    InMeetingFileShare = 'IN_MEETING_FILE_SHARE',
    InMeetingScreenShareClient = 'IN_MEETING_SCREEN_SHARE_CLIENT',
    InMeetingScreenShareStaff = 'IN_MEETING_SCREEN_SHARE_STAFF',
    InviteStaff = 'INVITE_STAFF',
    InviteClient = 'INVITE_CLIENT',
    InviteExternal = 'INVITE_EXTERNAL',
    UpdateExternalsViaContacts = 'UPDATE_EXTERNALS_VIA_CONTACTS',
    UpdateClientsViaContacts = 'UPDATE_CLIENTS_VIA_CONTACTS',
    UsesHsaId = 'USES_HSA_ID',
    TakeCareSync = 'TAKE_CARE_SYNC',
    UsesCustomDomain = 'USES_CUSTOM_DOMAIN',
    UsesMeetingConfirmation = 'USES_MEETING_CONFIRMATION',
    NotificationEmailClientMeetingInvited = 'NOTIFICATION_EMAIL_CLIENT_MEETING_INVITED',
    NotificationEmailClientMeetingRescheduled = 'NOTIFICATION_EMAIL_CLIENT_MEETING_RESCHEDULED',
    NotificationEmailClientMeetingCancelled = 'NOTIFICATION_EMAIL_CLIENT_MEETING_CANCELLED',
    NotificationEmailClientMeetingReminder = 'NOTIFICATION_EMAIL_CLIENT_MEETING_REMINDER',
    NotificationEmailClientMessageReceived = 'NOTIFICATION_EMAIL_CLIENT_MESSAGE_RECEIVED',
    NotificationEmailClientFileUploaded = 'NOTIFICATION_EMAIL_CLIENT_FILE_UPLOADED',
    NotificationEmailStaffMeetingInvited = 'NOTIFICATION_EMAIL_STAFF_MEETING_INVITED',
    NotificationEmailStaffMeetingRescheduled = 'NOTIFICATION_EMAIL_STAFF_MEETING_RESCHEDULED',
    NotificationEmailStaffMeetingCancelled = 'NOTIFICATION_EMAIL_STAFF_MEETING_CANCELLED',
    NotificationEmailStaffMeetingReminder = 'NOTIFICATION_EMAIL_STAFF_MEETING_REMINDER',
    NotificationEmailStaffMessageReceived = 'NOTIFICATION_EMAIL_STAFF_MESSAGE_RECEIVED',
    NotificationEmailStaffFileUploaded = 'NOTIFICATION_EMAIL_STAFF_FILE_UPLOADED',
    NotificationSmsClientMeetingInvited = 'NOTIFICATION_SMS_CLIENT_MEETING_INVITED',
    NotificationSmsClientMeetingRescheduled = 'NOTIFICATION_SMS_CLIENT_MEETING_RESCHEDULED',
    NotificationSmsClientMeetingCancelled = 'NOTIFICATION_SMS_CLIENT_MEETING_CANCELLED',
    NotificationSmsClientMeetingReminder = 'NOTIFICATION_SMS_CLIENT_MEETING_REMINDER',
    NotificationSmsClientMessageReceived = 'NOTIFICATION_SMS_CLIENT_MESSAGE_RECEIVED',
    NotificationSmsClientFileUploaded = 'NOTIFICATION_SMS_CLIENT_FILE_UPLOADED',
    NotificationSmsStaffMeetingInvited = 'NOTIFICATION_SMS_STAFF_MEETING_INVITED',
    NotificationSmsStaffMeetingRescheduled = 'NOTIFICATION_SMS_STAFF_MEETING_RESCHEDULED',
    NotificationSmsStaffMeetingCancelled = 'NOTIFICATION_SMS_STAFF_MEETING_CANCELLED',
    NotificationSmsStaffMeetingReminder = 'NOTIFICATION_SMS_STAFF_MEETING_REMINDER',
    NotificationSmsStaffMessageReceived = 'NOTIFICATION_SMS_STAFF_MESSAGE_RECEIVED',
    NotificationSmsStaffFileUploaded = 'NOTIFICATION_SMS_STAFF_FILE_UPLOADED',
    NotificationWebdoc = 'NOTIFICATION_WEBDOC',
    NotificationTakecare = 'NOTIFICATION_TAKECARE',
    NotificationCgmj4 = 'NOTIFICATION_CGMJ4',
    AnonymizeNoneActiveClient = 'ANONYMIZE_NONE_ACTIVE_CLIENT',
    AnonymizeExpiredInvites = 'ANONYMIZE_EXPIRED_INVITES',
    AnonymizeClosedUser = 'ANONYMIZE_CLOSED_USER',
    AnonymizeOldTodos = 'ANONYMIZE_OLD_TODOS',
    AnonymizeOldFiles = 'ANONYMIZE_OLD_FILES',
    AnonymizeOldNotes = 'ANONYMIZE_OLD_NOTES',
    AnonymizeOldMeetings = 'ANONYMIZE_OLD_MEETINGS',
    AnonymizeOldMessages = 'ANONYMIZE_OLD_MESSAGES',
    AnonymizeOldChatMessages = 'ANONYMIZE_OLD_CHAT_MESSAGES',
    AllowExternalGroupBookings = 'ALLOW_EXTERNAL_GROUP_BOOKINGS',
    SithsLogin = 'SITHS_LOGIN',
    UsernameLoginClient = 'USERNAME_LOGIN_CLIENT',
    UsernameLoginStaff = 'USERNAME_LOGIN_STAFF',
    UsernameLoginExternal = 'USERNAME_LOGIN_EXTERNAL',
    AdminBookingList = 'ADMIN_BOOKING_LIST',
    WebdocUpdateBookingButton = 'WEBDOC_UPDATE_BOOKING_BUTTON'
}

export function CustomerFeatureTypeFromJSON(json: any): CustomerFeatureType {
    return CustomerFeatureTypeFromJSONTyped(json, false);
}

export function CustomerFeatureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerFeatureType {
    return json as CustomerFeatureType;
}

export function CustomerFeatureTypeToJSON(value?: CustomerFeatureType | null): any {
    return value as any;
}

