import { useIntl } from "react-intl";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { rgbToHex } from "@mui/material";
import { Redirect } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { useSpinner } from "../Spinner/Spinner";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  CustomerThemeResponse,
  UpdateCustomerThemeRequest,
} from "../../generated/customersettings-api";
import { useStyles } from "./ThemeSettings.styles";
import { customerSettingsServiceToken } from "../../Providers/ServiceProvider/ServiceProvider";
import { messages } from "./ThemeSettings.messages";
import { hasPermission } from "../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { RoutePath } from "../Routes/RoutePath";
import ColorPicker from "../ColorPicker/ColorPicker";
import Image, { Type } from "./Componenets/Image/Image";
import {
  ACCENT_PANTONE_RHODAMINE_RED,
  PRIMARY_PANTONE_303,
} from "../Theme/Components/DefaultTheme/DefaultTheme";

interface Props {
  customerId?: string;
}

const ThemeSettings = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const theme = useTheme();
  const setSpinner = useSpinner()[1];
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();

  const [icon, setIcon] = useState<string>();
  const [logo, setLogo] = useState<string>();
  const [customerTheme, setCustomerTheme] = useState<CustomerThemeResponse>();
  const [primaryColor, setPrimaryColor] = useState(
    rgbToHex(theme.palette.primary.main)
  );
  const [secondaryColor, setSecondaryColor] = useState(
    rgbToHex(theme.palette.secondary.main)
  );
  const classes = useStyles();
  const defaultPrimaryColor = PRIMARY_PANTONE_303;
  const defaultSecondaryColor = ACCENT_PANTONE_RHODAMINE_RED;

  const loadSettings = () => {
    if (!customerId) return;
    customerSettingsServiceToken()
      .getCustomerTheme({
        customerId,
      })
      .then((res: CustomerThemeResponse) => {
        setCustomerTheme(res);
        setPrimaryColor(res.primaryColor || defaultPrimaryColor);
        setSecondaryColor(res.secondaryColor || defaultSecondaryColor);
        setLogo(res.logo);
        setIcon(res.favicon);
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.getReadData), {
          variant: "error",
        });
      });
  };

  const handleSaveChanges = () => {
    if (!customerId || !customerTheme?.id) return;
    setSpinner({ isLoading: true });
    const requestParameters: UpdateCustomerThemeRequest = {
      customerId,
      customerTheme: {
        id: customerTheme.id,
        primaryColor,
        secondaryColor,
        favicon: icon,
        logo,
      },
    };
    customerSettingsServiceToken()
      .updateCustomerTheme(requestParameters)
      .then(() => {
        enqueueSnackbar(intl.formatMessage(messages.successMessage), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.getReadData), {
          variant: "error",
        });
      });
    setSpinner({ isLoading: false });
  };

  useEffect(() => {
    loadSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.CustomerSettings],
    allowedRoles: [UserRoleType.Staff, UserRoleType.PlatformAdmin],
  });

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  if (!customerTheme) {
    return null;
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {intl.formatMessage(messages.customerThemeSettingsTab)}
          </h3>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.content}>
          <ColorPicker
            label={intl.formatMessage(messages.customerPrimaryColorLabel)}
            defaultColor={primaryColor}
            onChange={setPrimaryColor}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.content}>
          <ColorPicker
            label={intl.formatMessage(messages.customerSecondaryColorLabel)}
            defaultColor={secondaryColor}
            onChange={setSecondaryColor}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.content}>
          <Typography>{intl.formatMessage(messages.customerLogo)}</Typography>
          <Image
            type={Type.LOGO}
            currentSrc={logo}
            onUploaded={setLogo}
            maxSizeInKb={1024}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.content}>
          <Typography>{intl.formatMessage(messages.customerIcon)}</Typography>
          <Image
            type={Type.FAVICON}
            currentSrc={icon}
            onUploaded={setIcon}
            maxSizeInKb={100}
          />
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <div className={classes.buttonsContainer}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSaveChanges}
              className={classes.margin}
            >
              {intl.formatMessage(messages.applySettings)}
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => window.location.reload()}
              className={classes.margin}
            >
              {intl.formatMessage(messages.cancelChanges)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ThemeSettings;
