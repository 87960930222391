import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: 75,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      borderColor: "lightGray",
      borderRadius: 10,
      borderWidth: 0.5,
      borderStyle: "solid",
      padding: theme.spacing(1),
      cursor: "pointer",
    },
    icon: {
      height: 32,
      width: 32,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      borderColor: "lightGray",
      borderRadius: 10,
      borderWidth: 0.5,
      borderStyle: "solid",
      padding: theme.spacing(1),
      cursor: "pointer",
      objectFit: "contain",
    },
    hiddenInput: {
      display: "none",
    },
  })
);
