/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Invite API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteResponse
 */
export interface InviteResponse {
    /**
     * The UUID of this user
     * @type {string}
     * @memberof InviteResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof InviteResponse
     */
    email: string;
}

export function InviteResponseFromJSON(json: any): InviteResponse {
    return InviteResponseFromJSONTyped(json, false);
}

export function InviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'email': json['email'],
    };
}

export function InviteResponseToJSON(value?: InviteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'email': value.email,
    };
}


