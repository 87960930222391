/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Content API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of customer content item
 * @export
 * @enum {string}
 */
export enum ContentItemType {
    WelcomeText = 'WELCOME_TEXT',
    NoticeText = 'NOTICE_TEXT',
    LinkButton = 'LINK_BUTTON',
    FeedbackButton = 'FEEDBACK_BUTTON',
    TechnicalSupportPhone = 'TECHNICAL_SUPPORT_PHONE',
    TechnicalSupportEmail = 'TECHNICAL_SUPPORT_EMAIL',
    TechnicalSupportOpeningHours = 'TECHNICAL_SUPPORT_OPENING_HOURS',
    ContactDetailsPhone = 'CONTACT_DETAILS_PHONE',
    ContactDetailsEmail = 'CONTACT_DETAILS_EMAIL',
    ContactDetailsOpeningHours = 'CONTACT_DETAILS_OPENING_HOURS'
}

export function ContentItemTypeFromJSON(json: any): ContentItemType {
    return ContentItemTypeFromJSONTyped(json, false);
}

export function ContentItemTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentItemType {
    return json as ContentItemType;
}

export function ContentItemTypeToJSON(value?: ContentItemType | null): any {
    return value as any;
}

