import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";
import Participant from "@vonage/video-express/dist/internal/participant";
import RoomWrapper from "@vonage/video-express/dist/mp/room";
import EndCallButton from "../Components/EndCallButton/EndCallButton";

import { useStyles } from "./ToolBar.style";
import MuteAudioButton from "../Components/MuteAudioButton/MuteAudioButton";
import MuteVideoButton from "../Components/MuteVideoButton/MuteVideoButton";
import MuteAllButton from "../Components/MuteAllButton/MuteAllButton";
import SpeedDialButton from "../Components/SpeedDialButton/SpeedDialButton";

interface Props {
  meetingId: string;
  room: RoomWrapper;
  connected: boolean;
  cameraPublishing: boolean;
  isScreenSharing: boolean;
  startScreenSharing: () => void;
  stopScreenSharing: () => void;
  participants: Participant[];
}
export default function ToolBar(props: Props) {
  const {
    room,
    connected,
    cameraPublishing,
    isScreenSharing,
    startScreenSharing,
    stopScreenSharing,
    participants,
    meetingId,
  } = props;

  const theme = useTheme();
  const [hasAudio, setHasAudio] = useState(true);
  const [hasVideo, setHasVideo] = useState(true);
  const [areAllMuted, setAllMuted] = useState(false);
  const classes = useStyles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  const handleMuteAll = () => {
    if (!areAllMuted) {
      participants.map((participant: Participant) =>
        participant.camera?.disableAudio()
      );
      setAllMuted(true);
    } else {
      participants.map((participant: any) => participant.camera.enableAudio());
      setAllMuted(false);
    }
  };

  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };

  const toggleAudio = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
      }
    }
  };

  const getVideoSource: () => OT.Device | undefined = () => {
    if (room && room.camera) {
      return room.camera.getVideoDevice();
    }
    return undefined;
  };

  const changeVideoSource = (videoId: any) => {
    room.camera.setVideoDevice(videoId);
  };

  const changeAudioSource = (audioId: any) => {
    room.camera.setAudioDevice(audioId);
  };

  const getAudioSource = async (): Promise<string | undefined> => {
    if (room && room.camera) {
      const audioDevice = await room.camera.getAudioDevice();
      return audioDevice.deviceId;
    }
    return undefined;
  };

  useEffect(() => {
    if (connected) {
      const isAudioEnabled =
        room && room.camera && room.camera.isAudioEnabled();
      const isVideoEnabled =
        room && room.camera && room.camera.isVideoEnabled();
      setHasAudio(isAudioEnabled);
      setHasVideo(isVideoEnabled);
    }
  }, [connected, room]);

  return (
    <div
      className={
        isMobileWidth
          ? classes.toolbarMobileContainer
          : classes.toolbarContainer
      }
    >
      <MuteAudioButton
        toggleAudio={toggleAudio}
        hasAudio={hasAudio}
        changeAudioSource={changeAudioSource}
        getAudioSource={getAudioSource}
        cameraPublishing={cameraPublishing}
      />
      <MuteVideoButton
        toggleVideo={toggleVideo}
        hasVideo={hasVideo}
        getVideoSource={getVideoSource}
        cameraPublishing={cameraPublishing}
        changeVideoSource={changeVideoSource}
      />
      {!isMobileWidth && (
        <MuteAllButton
          handleMuteAll={handleMuteAll}
          areAllMuted={areAllMuted}
        />
      )}
      <SpeedDialButton
        room={room}
        onMuteAll={handleMuteAll}
        areAllMuted={areAllMuted}
        isScreenSharing={isScreenSharing}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
      />
      <EndCallButton meetingId={meetingId} room={room} />
    </div>
  );
}
