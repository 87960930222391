import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { messages } from "./ShareFileDialog.messages";
import { UserResponse, UserRoleType } from "../../../generated/user-api";
import { UserAuthorityType } from "../../../generated/authentication-api";
import { hasPermission } from "../../PermissionController/PermissionController";
import {
  PaginatedContactResponseList,
  SortByType,
  SortOrderType,
  UserStatusType,
} from "../../../generated/contact-api";
import ButtonLoadingWrapper from "../../Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";
import {
  contactService,
  userService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import { contactToUser } from "../../../Utils/ModelConverter";
import { User } from "../../../Models/User";
import ShareFileFields from "../../Fields/ShareFileFields/ShareFileFields";
import FileUploadButton from "../../Buttons/FileUploadButton/FileUploadButton";

interface Props {
  isDialogOpen?: boolean;
  onDialogClose?: () => void;
  onFileShared?: () => void;
}

const ShareFileDialog = (props: Props) => {
  const { isDialogOpen, onDialogClose, onFileShared } = props;
  const intl = useIntl();
  const theme = useTheme();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [staffContactList, setStaffContactList] = useState<User[]>();
  const [clientContactList, setClientContactList] = useState<User[]>();
  const [externalContactList, setExternalContactList] = useState<User[]>();
  const [staffList, setStaffList] = useState<UserResponse[]>();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filterByRole = (userRole: UserRoleType, users: User[]): User[] => {
    return users?.filter((user) => user.userRole === userRole) || [];
  };

  const initialValues = {
    participants: [] as User[],
    externalParticipants: [] as User[],
    staffParticipants: [] as User[],
  };

  const filterContacts = (
    contactResponseList: PaginatedContactResponseList,
    filter: UserRoleType[]
  ): User[] => {
    if (
      !customerId &&
      authenticatedUser?.user &&
      !hasPermission({
        currentUser: authenticatedUser.user,
        requiredAuthoritiesStaff: [UserAuthorityType.BookMeeting],
      })
    ) {
      // If user does not have auth bookMeeting, meaning it is booking for someone else, dont show contacts
      return [];
    }
    const users: User[] = [];
    contactResponseList.data?.forEach((contact) => {
      if (filter.includes(contact.userRole)) {
        users.push(contactToUser(contact));
      }
    });
    return users;
  };

  const loadContactList = () => {
    if (customerId && authenticatedUser.user?.id) {
      contactService()
        .listContactsForUserDecorated(authenticatedUser.user, {
          customerId,
          userStatus: UserStatusType.Active,
          userId: authenticatedUser.user.id,
          sortOrder: SortOrderType.Ascending,
          sortBy: SortByType.Popularity,
          pageSize: 1000, // maximum contacts loaded
        })
        .then((res) =>
          filterContacts(res, [
            UserRoleType.Client,
            UserRoleType.External,
            UserRoleType.Staff,
          ])
        )
        .then((users) => {
          setStaffContactList(filterByRole(UserRoleType.Staff, users));
          setClientContactList(filterByRole(UserRoleType.Client, users));
          setExternalContactList(filterByRole(UserRoleType.External, users));
        });
    }
  };

  const loadStaff = (): void => {
    if (customerId) {
      userService()
        .listUsersByCustomerAndSearchParams({
          pageSize: 1000,
          page: 1,
          customerId,
          userStatus: UserStatusType.Active,
          userRole: UserRoleType.Staff,
          userAuthorities: [UserAuthorityType.AttendMeeting],
        })
        .then((res) => {
          const filteredList = res.data?.filter(
            (user: UserResponse) => user.id !== authenticatedUser.user?.id
          );
          setStaffList(filteredList);
        });
    }
  };

  const onShareFile = () => {
    setIsLoading(true);
  };

  const onShareFileCompleted = () => {
    setIsLoading(false);
    if (onFileShared) {
      onFileShared();
    }
  };

  const onShareFileCanceled = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (isDialogOpen) {
      loadContactList();
      loadStaff();
    }
  }, [isDialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  });

  useEffect(() => {
    setSelectedUsers([
      ...formik.values.participants,
      ...formik.values.externalParticipants,
      ...formik.values.staffParticipants,
    ]);
  }, [formik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isDialogOpen || false}
      fullScreen={fullScreen}
      onClose={onDialogClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <ShareFileFields
            formik={formik}
            clientOptionList={clientContactList}
            externalOptionList={externalContactList}
            staffOptionList={staffList || staffContactList}
            disabledFields={[]}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isLoading}>
            <FileUploadButton
              isLoading={(loading) =>
                loading ? onShareFile() : onShareFileCompleted()
              }
              onCanceled={onShareFileCanceled}
              variant="contained"
              color="primary"
              onFileUploaded={onShareFileCompleted}
              accessGivenTo={selectedUsers.map((user) => user.id)}
              disabled={isLoading || selectedUsers.length < 1}
            />
          </ButtonLoadingWrapper>
          <Button color="primary" onClick={onDialogClose} disabled={isLoading}>
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ShareFileDialog;
