import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import { Container, Toolbar } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Logo from "./Components/Logo/Logo";
import { useStyles } from "./AppBar.style";
import Navigation, { NavConfig } from "./Components/Navigation/Navigation";
import { messages } from "./AppBar.messages";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../generated/user-api";
import Tabs, { TabConfig } from "../Tabs/Tabs";
import PermissionController, {
  hasPermission,
} from "../PermissionController/PermissionController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import {
  useCustomerIsAdminPortal,
  useCustomerName,
} from "../../Providers/CustomerProvider/CustomerProvider";
import { RoutePath } from "../Routes/RoutePath";
import {
  useCustomerCurrentBooking,
  useCustomerCurrentUpdate,
} from "../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { MeetingResponse } from "../../generated/meeting-api";

const AppBar = () => {
  const [authenticatedUser] = useAuthenticatedUser();
  const intl = useIntl();
  const customerName = useCustomerName();
  const isCustomerAdminPortal = useCustomerIsAdminPortal();
  const classes = useStyles();
  const location = useLocation();
  const isAdminPath = location.pathname.split("/")[1] === "admin";
  const setCustomerupdates = useCustomerCurrentUpdate()[1];
  const setCustomerCurrentBooking = useCustomerCurrentBooking()[1];

  const navAdminConfigs: NavConfig[] = [
    {
      title: intl.formatMessage(messages.adminExternalsTitle),
      to: RoutePath.ADMIN_EXTERNALS,
      allowedRoles: [UserRoleType.Staff],
      requiredFeature: CustomerFeatureType.ExternalUsers,
    },
    {
      title: intl.formatMessage(messages.adminPatientsTitle),
      to: RoutePath.ADMIN_PATIENTS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.adminBookingsTitle),
      to: RoutePath.ADMIN_BOOKINGS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.adminCaregiversTitle),
      to: RoutePath.ADMIN_CAREGIVERS,
      allowedRoles: [UserRoleType.Staff],
    },
    // {
    //   title: intl.formatMessage(messages.adminSettingsTitle),
    //   to: RoutePath.ADMIN_SETTINGS,
    //   allowedRoles: [UserRoleType.Staff],
    // },
  ];
  const navConfigs: NavConfig[] = [
    {
      title: intl.formatMessage(messages.dashboardTitle),
      to: RoutePath.DASHBOARD,
      allowedRoles: [
        UserRoleType.Client,
        UserRoleType.Staff,
        UserRoleType.External,
      ],
    },
    {
      title: intl.formatMessage(messages.clientsTitle),
      to: RoutePath.CLIENTS,
      showAsActiveFor: [RoutePath.BOOKING_HISTORY, RoutePath.CLIENTS],
      allowedRoles: [
        UserRoleType.Client,
        UserRoleType.Staff,
        UserRoleType.External,
      ],
    },
    {
      title: intl.formatMessage(messages.bookingsTitle),
      to: RoutePath.BOOKINGS,
      allowedRoles: [
        UserRoleType.Client,
        UserRoleType.Staff,
        UserRoleType.External,
      ],
    },
    {
      title: intl.formatMessage(messages.messagesTitle),
      to: RoutePath.MESSAGES,
      requiredFeature: CustomerFeatureType.Messages,
      allowedRoles: [
        UserRoleType.Client,
        UserRoleType.Staff,
        UserRoleType.External,
      ],
    },
    {
      title: intl.formatMessage(messages.filesTitle),
      to: RoutePath.FILES,
      requiredFeature: CustomerFeatureType.InMeetingFileShare,
      allowedRoles: [
        UserRoleType.Client,
        UserRoleType.Staff,
        UserRoleType.External,
      ],
    },
  ];
  const navSettingsConfigs: NavConfig[] = [
    {
      title: intl.formatMessage(messages.customerThemeSettingsTab),
      to: RoutePath.CUSTOMERS_THEME_SETTINGS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.customerFeaturesTab),
      to: RoutePath.CUSTOMER_FEATURE_SETTINGS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.customerEmailSettingsTab),
      to: RoutePath.CUSTOMER_EMAIL_SETTINGS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.customerAnonymizeSettingsTab),
      to: RoutePath.CUSTOMERS_ANONYMIZE_SETTINGS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.customerContentSettingsTab),
      to: RoutePath.CUSTOMERS_CONTENT_SETTINGS,
      allowedRoles: [UserRoleType.Staff],
    },
    {
      title: intl.formatMessage(messages.customerAgreementSettingsTab),
      to: RoutePath.CUSTOMERS_AGREEMENT_SETTINGS,
      allowedRoles: [UserRoleType.Staff],
    },
  ];
  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.myPagesTabTitle),
      redirectTo: RoutePath.DASHBOARD,
      component: (
        <Toolbar className={classes.toolbar}>
          <Logo />
          {authenticatedUser.isLoggedIn && authenticatedUser.isComplete && (
            <Navigation navConfigs={navConfigs} />
          )}
        </Toolbar>
      ),
    },
    {
      title: customerName || intl.formatMessage(messages.adminTabTitle),
      redirectTo: RoutePath.ADMIN_PATIENTS,
      allowedAuthorities: [UserAuthorityType.AdminSite],
      component: (
        <Toolbar className={classes.toolbar}>
          <Logo />
          {authenticatedUser.isLoggedIn && authenticatedUser.isComplete && (
            <Navigation navConfigs={navAdminConfigs} />
          )}
        </Toolbar>
      ),
    },
    {
      title: intl.formatMessage(messages.customerSettingsTabTitle),
      redirectTo: RoutePath.CUSTOMERS_THEME_SETTINGS,
      allowedAuthorities: [UserAuthorityType.CustomerSettings],
      component: (
        <Toolbar className={classes.toolbar}>
          <Logo />
          {authenticatedUser.isLoggedIn && authenticatedUser.isComplete && (
            <Navigation navConfigs={navSettingsConfigs} />
          )}
        </Toolbar>
      ),
    },
  ];

  const resetCurrentUpdates = () => {
    const arrayCopy: MeetingResponse[] = [];
    const arrayCopyUsers: UserResponse[] = [];
    setCustomerCurrentBooking({ MeetingResponse: arrayCopy });
    setCustomerupdates({ userResponse: arrayCopyUsers });
  };

  if (location.pathname === RoutePath.SESSION_VIDEO) {
    return null;
  }

  return (
    <MuiAppBar position="static" className={classes.appBar}>
      <PermissionController
        atLeastOneOfAuthoritiesStaff={[
          UserAuthorityType.AdminSite,
          UserAuthorityType.CustomerSettings,
        ]}
      >
        {authenticatedUser.isComplete && !isCustomerAdminPortal && (
          <div className={classes.extendedAppBar} />
        )}
      </PermissionController>
      <Container>
        <Tabs
          showTabs={
            !isCustomerAdminPortal &&
            authenticatedUser.user &&
            hasPermission({
              currentUser: authenticatedUser.user,
              atLeastOneOfAuthoritiesStaff: [
                UserAuthorityType.AdminSite,
                UserAuthorityType.CustomerSettings,
              ],
            })
          }
          defaultTab={isAdminPath ? 1 : 0}
          indicatorAtTop
          tabConfigs={tabConfig}
          className={classes.tabs}
          tabClassName={classes.tab}
          contentClassName={classes.tabsContent}
          onTabChange={resetCurrentUpdates}
        />
      </Container>
    </MuiAppBar>
  );
};

export default AppBar;
