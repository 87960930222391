import React from "react";
import {
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../generated/user-api";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface BaseProps {
  allowedRoles?: UserRoleType[];
  requiredAuthoritiesStaff?: UserAuthorityType[];
  requiredAuthoritiesClient?: UserAuthorityType[];
  requiredAuthoritiesExternal?: UserAuthorityType[];
  requiredAuthoritiesPlatformAdmin?: UserAuthorityType[];
  atLeastOneOfAuthoritiesStaff?: UserAuthorityType[];
  atLeastOneOfAuthoritiesClient?: UserAuthorityType[];
  atLeastOneOfAuthoritiesExternal?: UserAuthorityType[];
  atLeastOneOfAuthoritiesPlatformAdmin?: UserAuthorityType[];
}
interface Props extends BaseProps {
  children: React.ReactNode;
  overrideCurrentUserRole?: UserRoleType;
}

interface PropsAndCurrentUser extends BaseProps {
  currentUser?: UserResponse;
}

interface PermissionInput {
  currentUser?: UserResponse;
  allowedRoles?: UserRoleType[];
  requiredAuthorities?: UserAuthorityType[];
  atLeastOneOfAuthorities?: UserAuthorityType[];
}

export const hasAnyOfRoles = (
  userRole?: UserRoleType,
  allowedRoles?: UserRoleType[]
): boolean => {
  if (userRole) {
    return allowedRoles?.includes(userRole) || false;
  }
  return false;
};

const hasAuthority = (
  userAuthorities: UserAuthorityType[],
  requiredAuthorities: UserAuthorityType[]
): boolean => {
  let authorityRequirementFulfilled = true;
  requiredAuthorities.forEach((authority) => {
    if (!userAuthorities.includes(authority)) {
      authorityRequirementFulfilled = false;
    }
  });

  return authorityRequirementFulfilled;
};

const hasAtLeastOneOfAuthorities = (
  userAuthorities: UserAuthorityType[],
  oneOfAuthorities: UserAuthorityType[]
): boolean => {
  let authorityRequirementFulfilled = false;
  oneOfAuthorities.forEach((requiredAuthority) => {
    if (userAuthorities.includes(requiredAuthority)) {
      authorityRequirementFulfilled = true;
    }
  });
  return authorityRequirementFulfilled;
};

const hasPermissionForRole = ({
  currentUser,
  allowedRoles,
  requiredAuthorities,
  atLeastOneOfAuthorities,
}: PermissionInput): boolean => {
  // Deny if user has provided both required authority and atLeastOneOfAuthorities
  if (requiredAuthorities && atLeastOneOfAuthorities) {
    return false;
  }

  // Accept if user fulfills role requirements and no authority param has been provided
  if (
    !requiredAuthorities &&
    !atLeastOneOfAuthorities &&
    currentUser?.userRole &&
    allowedRoles &&
    hasAnyOfRoles(currentUser?.userRole, allowedRoles)
  ) {
    return true;
  }

  // Accept if user fulfills authority requirements and no restriction for roles has been provided
  if (
    !allowedRoles &&
    currentUser?.userAuthorities &&
    requiredAuthorities &&
    hasAuthority(currentUser?.userAuthorities, requiredAuthorities)
  ) {
    return true;
  }

  // Accept if user fulfills authority requirements and no restriction for roles has been provided
  if (
    !allowedRoles &&
    currentUser?.userAuthorities &&
    atLeastOneOfAuthorities &&
    hasAtLeastOneOfAuthorities(
      currentUser?.userAuthorities,
      atLeastOneOfAuthorities
    )
  ) {
    return true;
  }

  // Accept if user fulfills both authority and role requirements
  if (
    currentUser?.userRole &&
    allowedRoles &&
    hasAnyOfRoles(currentUser?.userRole, allowedRoles) &&
    currentUser?.userAuthorities &&
    ((requiredAuthorities &&
      hasAuthority(currentUser?.userAuthorities, requiredAuthorities)) ||
      (atLeastOneOfAuthorities &&
        hasAtLeastOneOfAuthorities(
          currentUser?.userAuthorities,
          atLeastOneOfAuthorities
        )))
  ) {
    return true;
  }

  // Deny as default
  return false;
};

export const hasPermission = (props: PropsAndCurrentUser): boolean => {
  const {
    currentUser,
    allowedRoles,
    requiredAuthoritiesStaff,
    requiredAuthoritiesClient,
    requiredAuthoritiesExternal,
    atLeastOneOfAuthoritiesStaff,
    atLeastOneOfAuthoritiesClient,
    atLeastOneOfAuthoritiesExternal,
    requiredAuthoritiesPlatformAdmin,
    atLeastOneOfAuthoritiesPlatformAdmin,
  } = props;
  switch (currentUser?.userRole) {
    case UserRoleType.Staff:
      return hasPermissionForRole({
        currentUser,
        allowedRoles,
        requiredAuthorities: requiredAuthoritiesStaff,
        atLeastOneOfAuthorities: atLeastOneOfAuthoritiesStaff,
      });
      break;
    case UserRoleType.Client:
      return hasPermissionForRole({
        currentUser,
        allowedRoles,
        requiredAuthorities: requiredAuthoritiesClient,
        atLeastOneOfAuthorities: atLeastOneOfAuthoritiesClient,
      });
      break;
    case UserRoleType.External:
      return hasPermissionForRole({
        currentUser,
        allowedRoles,
        requiredAuthorities: requiredAuthoritiesExternal,
        atLeastOneOfAuthorities: atLeastOneOfAuthoritiesExternal,
      });
      break;
    case UserRoleType.PlatformAdmin:
      return hasPermissionForRole({
        currentUser,
        allowedRoles,
        requiredAuthorities: requiredAuthoritiesPlatformAdmin,
        atLeastOneOfAuthorities: atLeastOneOfAuthoritiesPlatformAdmin,
      });
      break;
    default:
      return false;
  }
};

const PermissionController = (props: Props) => {
  const [authenticatedUser] = useAuthenticatedUser();
  // const [currentUser, setCurrentUser] = useState<UserResponse>();
  const {
    children,
    allowedRoles,
    overrideCurrentUserRole,
    requiredAuthoritiesStaff,
    requiredAuthoritiesClient,
    requiredAuthoritiesExternal,
    atLeastOneOfAuthoritiesStaff,
    atLeastOneOfAuthoritiesClient,
    atLeastOneOfAuthoritiesExternal,
    requiredAuthoritiesPlatformAdmin,
    atLeastOneOfAuthoritiesPlatformAdmin,
  } = props;
  const currentUser = {
    ...authenticatedUser.user,
    userRole: overrideCurrentUserRole || authenticatedUser.user?.userRole,
  } as UserResponse;

  if (
    hasPermission({
      currentUser,
      allowedRoles,
      requiredAuthoritiesStaff,
      requiredAuthoritiesClient,
      requiredAuthoritiesExternal,
      requiredAuthoritiesPlatformAdmin,
      atLeastOneOfAuthoritiesStaff,
      atLeastOneOfAuthoritiesClient,
      atLeastOneOfAuthoritiesExternal,
      atLeastOneOfAuthoritiesPlatformAdmin,
    })
  ) {
    return <>{children}</>;
  }
  return null;
};

export default PermissionController;
