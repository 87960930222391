import React from "react";
import { Paper, Typography, IconButton, Grid } from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import { useStyles } from "./CommonFixesCard.style";
import { messages } from "./CommonFixesCard.messages";

const CommonFixesCard = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container justify="center" alignItems="center">
        <Grid item md={4} xs={12}>
          <div className={classes.iconContainer}>
            <BuildIcon className={classes.icon} />
          </div>
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.bottomMargin}
          >
            {intl.formatMessage(messages.title)}
          </Typography>

          {/* Refresh information */}
          <Typography component="h2" variant="h6">
            {intl.formatMessage(messages.refreshTitle)}
          </Typography>
          <li className={classes.bottomMarginSmall}>
            {intl.formatMessage(messages.refreshDescription)}
            <IconButton onClick={() => window.location.reload()}>
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          </li>

          {/* Requirements information */}
          <Typography component="h2" variant="h6">
            {intl.formatMessage(messages.requirementsTitle)}
          </Typography>
          <li>{intl.formatMessage(messages.requirementsComputer)}</li>
          <li>{intl.formatMessage(messages.requirementsAppleDevices)}</li>
          <li className={classes.bottomMarginSmall}>
            {intl.formatMessage(messages.requirementsAndroidDevices)}
          </li>

          {/* Further troubleshooting */}
          <Typography component="h2" variant="h6">
            {intl.formatMessage(messages.furtherTroubleshootingTitle)}
          </Typography>
          <Typography component="p" variant="body2">
            {intl.formatMessage(messages.linkText)}
          </Typography>
          <Button
            variant="outlined"
            href="https://tokbox.com/developer/tools/precall/"
          >
            {intl.formatMessage(messages.buttonText)}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CommonFixesCard;
