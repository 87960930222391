import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      textAlign: "left",
      marginBottom: 10,
    },
    right: {
      textAlign: "right",
      marginBottom: 10,
    },
    avatar: {
      backgroundColor: "inherit",
      padding: 0,
      margin: 0,
      maxWidth: "32px",
      "& .MuiAvatar-root": {
        marginLeft: 18,
      },
    },
    chipContainerDefault: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 6,
      paddingLeft: theme.spacing(0.5),
      display: "flex",
      flexDirection: "row",
      maxWidth: "85%",
      alignItems: "center",
    },
    chipContainerSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 20,
      paddingRight: theme.spacing(0.5),
      display: "flex",
      flexDirection: "row-reverse",
      maxWidth: "85%",
      alignItems: "center",
    },
    quillEditor: {
      padding: 0,
      maxWidth: "87%",
    },
    timeFormat: {
      margin: 2,
      marginTop: 0,
      opacity: 0.6,
    },
    chip: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
    },
    chipReverse: {
      height: "100%",
      display: "flex",
      flexDirection: "row-reverse",
    },
  })
);
