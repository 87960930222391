import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./ParticipantList.style";
import { MeetingParticipant } from "../../../../Models/Meeting";
import ProfilePicture from "../../../ProfilePicture/ProfilePicture";
import { displayUser, formatPersonNumber } from "../../../../Utils/User";

interface Props {
  participants?: MeetingParticipant[];
}

const ParticipantList = (props: Props) => {
  const { participants } = props;
  const classes = useStyles();

  if (!participants) {
    return null;
  }

  return (
    <List aria-label="list of all participants">
      {participants.map((p) => (
        <ListItem button className={classes.listItem}>
          <ListItemAvatar>
            <ProfilePicture firstName={p.firstName} lastName={p.lastName} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                {displayUser(p, true)}
                <p style={{ padding: 0, margin: 0 }}>
                  <Typography variant="body2">
                    {p.personNumber
                      ? formatPersonNumber(p.personNumber)
                      : p.workTitle}
                  </Typography>
                </p>
              </span>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ParticipantList;
