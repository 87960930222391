import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateUserDialog from "./CreateUserDialog";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import PermissionController from "../../PermissionController/PermissionController";
import { User } from "../../../Models/User";

interface Props {
  onCreated?: ((user: User) => void) | (() => void);
  label: string;
  dialogTitle: string;
  userRole: UserRoleType;
  color?: "inherit" | "primary" | "secondary" | "default";
  className?: string;
  variant?: "text" | "outlined" | "contained";
  customerId: string;
}

const CreateUserButton = (props: Props) => {
  const {
    userRole,
    label,
    dialogTitle,
    onCreated,
    variant,
    color,
    className,
    customerId,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const CreateButton = () => (
    <Button
      size="small"
      color={color || "default"}
      className={className}
      variant={variant || "text"}
      startIcon={<AddIcon />}
      onClick={() => setIsDialogOpen(true)}
    >
      {label}
    </Button>
  );

  return (
    <>
      <CreateUserDialog
        isOpen={isDialogOpen}
        onCreated={onCreated}
        title={dialogTitle}
        userRole={userRole}
        onClose={() => setIsDialogOpen(false)}
        customerId={customerId}
      />

      {/* Authenticated user is PlatformAdmin  */}
      <PermissionController allowedRoles={[UserRoleType.PlatformAdmin]}>
        <PermissionController
          overrideCurrentUserRole={userRole}
          allowedRoles={[UserRoleType.Staff, UserRoleType.PlatformAdmin]}
          requiredAuthoritiesPlatformAdmin={[
            UserAuthorityType.CreateUserPlatformAdmin,
          ]}
        >
          <CreateButton />
        </PermissionController>
      </PermissionController>

      {/* Authenticated user is Staff  */}
      <PermissionController allowedRoles={[UserRoleType.Staff]}>
        <PermissionController
          overrideCurrentUserRole={userRole}
          requiredAuthoritiesStaff={[UserAuthorityType.CreateUserStaff]}
          requiredAuthoritiesClient={[UserAuthorityType.CreateUserClient]}
          requiredAuthoritiesExternal={[UserAuthorityType.CreateUserClient]}
          requiredAuthoritiesPlatformAdmin={[
            UserAuthorityType.CreateUserPlatformAdmin,
          ]}
        >
          <CreateButton />
        </PermissionController>
      </PermissionController>
    </>
  );
};

export default CreateUserButton;
