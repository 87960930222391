import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row-reverse",
    },

    chipContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "left",
      },
    },
    chip: {
      display: "flex",
      justifyContent: "center",
      minWidth: theme.spacing(26),
    },
    notYetValidFromChip: {
      justifyContent: "center",
      minWidth: theme.spacing(26),
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    },
  })
);
