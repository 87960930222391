import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    browserView: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    phoneView: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    navButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    navButtonActive: {
      width: "auto",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    navButtonPhone: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      borderRadius: 0,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    navButtonPhoneActive: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: 0,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    drawerPaper: {
      backgroundColor: theme.palette.common.white,
    },
    drawer: {
      width: theme.spacing(30),
      paddingTop: theme.spacing(2),
    },
    logoContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(5),
    },
  })
);
