/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './';

/**
 * A user object that can act as sender or recipient of a message.
 * @export
 * @interface MessageUserResponse
 */
export interface MessageUserResponse {
    /**
     * The UUID of this user
     * @type {string}
     * @memberof MessageUserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MessageUserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageUserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageUserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageUserResponse
     */
    workTitle?: string;
    /**
     * 
     * @type {UserStatusType}
     * @memberof MessageUserResponse
     */
    userStatus: UserStatusType;
    /**
     * 
     * @type {UserRoleType}
     * @memberof MessageUserResponse
     */
    userRole?: UserRoleType;
}

export function MessageUserResponseFromJSON(json: any): MessageUserResponse {
    return MessageUserResponseFromJSONTyped(json, false);
}

export function MessageUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
        'userStatus': UserStatusTypeFromJSON(json['userStatus']),
        'userRole': !exists(json, 'userRole') ? undefined : UserRoleTypeFromJSON(json['userRole']),
    };
}

export function MessageUserResponseToJSON(value?: MessageUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'workTitle': value.workTitle,
        'userStatus': UserStatusTypeToJSON(value.userStatus),
        'userRole': UserRoleTypeToJSON(value.userRole),
    };
}


