import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { messages } from "./MeetingTemplates.messages";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { MeetingTemplateResponse } from "../../generated/meetingtemplate-api";
import { useStyles } from "./MeetingTemplates.style";
import { useSpinner } from "../../Components/Spinner/Spinner";
import MeetingTemplateList from "../../Components/MeetingTemplateList/MeetingTemplateList";
import CreateMeetingTemplateButton from "../../Components/Buttons/CreateMeetingTemplateButton/CreateMeetingTemplateButton";
import PermissionController, {
  hasPermission,
} from "../../Components/PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { meetingTemplateService } from "../../Providers/ServiceProvider/ServiceProvider";

const MeetingTemplates = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const setSpinner = useSpinner()[1];

  const [meetingTemplateResponse, setMeetingTemplateResponse] = useState<
    MeetingTemplateResponse[]
  >();

  const [
    isMeetingTemplatesRequestsLoading,
    setisMeetingTemplatesRequestsLoading,
  ] = useState<boolean>(false);

  const isLoading = (): boolean => {
    return isMeetingTemplatesRequestsLoading;
  };

  const loadMeetingTemplatesRequests = () => {
    if (customerId && authenticatedUser.user?.id) {
      setisMeetingTemplatesRequestsLoading(true);
      meetingTemplateService()
        .listMeetingTemplates({
          customerId,
        })
        .then((res) => setMeetingTemplateResponse(res))
        .finally(() => setisMeetingTemplatesRequestsLoading(false));
    }
  };
  const hasStaffPermission = hasPermission({
    currentUser: authenticatedUser.user,
    allowedRoles: [UserRoleType.Staff],
  });
  useEffect(() => {
    loadMeetingTemplatesRequests();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show nothing when loading
  if (isLoading()) {
    return null;
  }
  if (!hasStaffPermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        className={classes.MeetingTemplates}
      >
        {intl.formatMessage(messages.meetingTemplatesTitle)}
      </Typography>
      <div className={classes.actionContainer}>
        <PermissionController
          allowedRoles={[UserRoleType.Staff]}
          atLeastOneOfAuthoritiesStaff={[
            UserAuthorityType.BookMeeting,
            UserAuthorityType.AdminSite,
          ]}
        >
          <CreateMeetingTemplateButton
            onCreated={loadMeetingTemplatesRequests}
            color="default"
            variant="text"
            overrideLabel={intl.formatMessage(messages.createNewtemplate)}
          />
        </PermissionController>
      </div>
      <MeetingTemplateList
        MeetingTemplates={meetingTemplateResponse}
        reloadTemplates={loadMeetingTemplatesRequests}
      />
    </>
  );
};

export default MeetingTemplates;
