import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import HistoryIcon from "@material-ui/icons/History";
import {
  MeetingResponse,
  MeetingStatusType,
} from "../../../generated/meeting-api";
import { messages } from "./BookingHistoryButton.messages";
import { RoutePath } from "../../Routes/RoutePath";
import { Variant } from "../../BookingsList/Components/Variant/Variant";

interface Props {
  userId?: string;
  contactId?: string;
  meeting?: MeetingResponse;
  contextVariant?: Variant;
  className?: string;
}

const BookingHistoryButton = (props: Props) => {
  const { meeting, className, userId, contextVariant, contactId } = props;
  const intl = useIntl();
  const history = useHistory();

  const redirectToBookingHistory = (event: React.MouseEvent) => {
    event.stopPropagation();
    history.push({
      pathname: RoutePath.BOOKING_HISTORY,
      search: meeting
        ? `?meetingId=${meeting.id}`
        : `?userId=${userId}&contactId=${contactId}`,
    });
  };

  // Should not be visible in admin
  if (contextVariant && contextVariant === Variant.ADMIN_UPCOMING_BOOKINGS) {
    return null;
  }

  // Should not be visible if meeting not completed
  if (!userId && meeting) {
    if (meeting.status !== MeetingStatusType.Completed) {
      return null;
    }
  }

  return (
    <Button
      size="small"
      color="secondary"
      variant="contained"
      className={className}
      startIcon={<HistoryIcon />}
      onClick={redirectToBookingHistory}
    >
      {intl.formatMessage(messages.buttonLabel)}
    </Button>
  );
};

export default BookingHistoryButton;
