import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customerThemeSettingsTab: {
    id: "settings.themeTab",
    defaultMessage: "Theme Settings",
    description: "label for customer theme settings",
  },
  customerPrimaryColorLabel: {
    id: "admin.customerThemeSettings.primaryColor",
    defaultMessage: "Primary Color",
    description: "label for primary color",
  },
  customerSecondaryColorLabel: {
    id: "admin.customerThemeSettings.secondaryColor",
    defaultMessage: "Secondary Color",
    description: "label for secondary color",
  },
  applySettings: {
    id: "admin.customerThemeSettings.applyChanges",
    defaultMessage: "Apply Changes",
    description: "Button for apply changes",
  },
  cancelChanges: {
    id: "admin.customerThemeSettings.cancelChanges",
    defaultMessage: "Cancel Changes",
    description: "Button for cancelling changes",
  },
  getReadData: {
    id: "save.settings.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occurred for saving features",
  },
  successMessage: {
    id: "save.settings.successsave",
    defaultMessage: "Changes applied. Refresh page is required",
    description: "Snackbar displayed when saving features success",
  },
  customerLogo: {
    id: "admin.customerThemeSettings.logo",
    defaultMessage: "Customer logo",
    description: "Label for customer logo feature",
  },
  customerIcon: {
    id: "admin.customerThemeSettings.icon",
    defaultMessage: "Customer icon (32x32)",
    description: "Label for customer icon feature",
  },
});
