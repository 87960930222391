import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useSubscription } from "react-stomp-hooks";
import { useStyles } from "./TodoSlider.style";
import { messages } from "./TodoSlider.messages";
import { Meeting } from "../../../../../../Models/Meeting";
import { PaginatedToDoResponseList } from "../../../../../../generated/todo-api";

import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { todoService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";
import FeatureController from "../../../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../../generated/customersettings-api";
import {
  parseSignalMessage,
  SignalType,
} from "../../../../../../WebSocket/model/SignalMessage";
import { SocketDestination } from "../../../../../../WebSocket/WebSocketSession";
import CreateTodoButton from "../../../../../../Components/Buttons/CreateTodoButton/CreateTodoButton";
import TodoList from "../../../../../../Components/TodoCard/Components/TodoList/TodoList";
import DialogCloseButton from "../../../../../../Components/Buttons/DialogCloseButton/DialogCloseButton";

interface Props {
  meeting: Meeting;
  onLoaded?: () => void;
  onLoading?: () => void;
  onClose: () => void;
}

const TodoSlider = (props: Props) => {
  const { meeting, onLoaded, onLoading, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [todos, setTodos] = useState<PaginatedToDoResponseList>();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();

  const loadTodos = () => {
    if (customerId && authenticatedUser.user) {
      if (onLoading) {
        onLoading();
      }
      todoService()
        .listToDos({
          meeting: meeting.id,
          pageSize: 30, // max 30 todos can be shown
          customerId,
        })
        .then((res) => setTodos(res))
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(onLoaded);
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meeting.id, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.TODO &&
      signalMessage.triggeredBy !== authenticatedUser.user?.id
    ) {
      loadTodos();
    }
  });

  useEffect(() => {
    loadTodos();
  }, [meeting.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!todos) {
    return null;
  }

  if (isSmallScreen) {
    return (
      <FeatureController requiredFeature={CustomerFeatureType.InMeetingTodo}>
        <Dialog open fullScreen className={classes.dialogRoot} scroll="paper">
          {onClose && <DialogCloseButton onClose={onClose} />}
          <DialogTitle>
            <Typography
              className={classes.cardHeading}
              component="h2"
              variant="subtitle2"
            >
              {intl.formatMessage(messages.title).toUpperCase()}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="subtitle2">
              <Box fontStyle="italic">
                {intl.formatMessage(messages.description)}
              </Box>
            </Typography>
            <Divider />
            <TodoList
              todos={todos}
              onDeleted={loadTodos}
              onUpdated={loadTodos}
            />
          </DialogContent>
          <DialogActions>
            <div className={classes.inputContainer}>
              <CreateTodoButton
                meetingId={meeting.id}
                onCreated={loadTodos}
                participants={meeting.participants}
                dialogTitle={intl.formatMessage(messages.createTodoDialogTitle)}
                label={intl.formatMessage(messages.createTodoButtonLabel)}
              />
            </div>
          </DialogActions>
        </Dialog>
      </FeatureController>
    );
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingTodo}>
      <Container className={classes.root}>
        <Typography
          className={classes.cardHeading}
          component="h2"
          variant="subtitle2"
        >
          {intl.formatMessage(messages.title).toUpperCase()}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          <Box fontStyle="italic">
            {intl.formatMessage(messages.description)}
          </Box>
        </Typography>
        <Divider />
        <div className={classes.contentContainer}>
          <TodoList todos={todos} onDeleted={loadTodos} onUpdated={loadTodos} />
        </div>
        <div className={classes.inputContainer}>
          <CreateTodoButton
            meetingId={meeting.id}
            onCreated={loadTodos}
            participants={meeting.participants}
            dialogTitle={intl.formatMessage(messages.createTodoDialogTitle)}
            label={intl.formatMessage(messages.createTodoButtonLabel)}
          />
        </div>
      </Container>
    </FeatureController>
  );
};

export default TodoSlider;
