import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DisplayMessagesCard from "../../../Views/Messages/Components/DisplayMessagesCard/DisplayMessagesCard";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import { useStyles } from "./DisplayMessageDialog.styles";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onMessageCreated?: () => void;
  threadId?: string;
  numberOfUnreadMessages?: number;
}

const DisplayMessageDialog = (props: Props) => {
  const {
    isOpen,
    onClose,
    onMessageCreated,
    threadId,
    numberOfUnreadMessages,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [subject, setSubject] = useState<string>();
  const classes = useStyles();

  useEffect(() => {
    if (!isOpen) {
      setSubject(undefined);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen || false}
      fullScreen={fullScreen}
      onClose={onClose}
    >
      <DialogTitle className={classes.dialogTitle}>{subject}</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent style={{ padding: 0 }}>
        <DisplayMessagesCard
          onThreadLoaded={setSubject}
          hideHeading
          threadId={threadId}
          onMessageCreated={onMessageCreated}
          numberOfUnreadMessages={numberOfUnreadMessages}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DisplayMessageDialog;
