/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageResponse,
    MessageResponseFromJSON,
    MessageResponseFromJSONTyped,
    MessageResponseToJSON,
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedMessageResponseList
 */
export interface PaginatedMessageResponseList {
    /**
     * 
     * @type {Pagination}
     * @memberof PaginatedMessageResponseList
     */
    pagination: Pagination;
    /**
     * 
     * @type {Array<MessageResponse>}
     * @memberof PaginatedMessageResponseList
     */
    data?: Array<MessageResponse>;
}

export function PaginatedMessageResponseListFromJSON(json: any): PaginatedMessageResponseList {
    return PaginatedMessageResponseListFromJSONTyped(json, false);
}

export function PaginatedMessageResponseListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMessageResponseList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(MessageResponseFromJSON)),
    };
}

export function PaginatedMessageResponseListToJSON(value?: PaginatedMessageResponseList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(MessageResponseToJSON)),
    };
}


