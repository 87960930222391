import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import AddOrCreateUserDialog from "../../Dialogs/AddOrCreateUserDilog/AddOrCreateUserDialog";
import { User } from "../../../Models/User";
import { Variant } from "../../Dialogs/AddOrCreateUserDilog/Components/SearchField/SearchField";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { hasFeatureEnabled } from "../../FeatureController/FeatureController";
import { useCustomerFeatures } from "../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  onCreated: ((user: User) => void) | (() => void);
  label: string;
  dialogTitle: string;
  userRole: UserRoleType;
}

const AddOrCreateUserButton = (props: Props) => {
  const { label, dialogTitle, onCreated, userRole } = props;
  const customerFeatures = useCustomerFeatures();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const getRequiredFeature = () => {
    switch (userRole) {
      case UserRoleType.External:
        return CustomerFeatureType.InviteExternal;
      case UserRoleType.Client:
        return CustomerFeatureType.InviteClient;
      case UserRoleType.Staff:
        return CustomerFeatureType.InviteStaff;
      default:
        return CustomerFeatureType.InviteStaff;
    }
  };

  return (
    <>
      <AddOrCreateUserDialog
        isOpen={isDialogOpen}
        onCreated={onCreated}
        title={dialogTitle}
        userRole={userRole}
        variant={
          hasFeatureEnabled(getRequiredFeature(), customerFeatures)
            ? Variant.INVITE_OR_CREATE
            : Variant.CREATE
        }
        onClose={() => setIsDialogOpen(false)}
      />
      <PermissionController
        allowedRoles={[UserRoleType.Staff]}
        requiredAuthoritiesStaff={[UserAuthorityType.CreateUserClient]}
      >
        <Button
          size="small"
          color="default"
          variant="text"
          startIcon={<AddIcon />}
          onClick={() => setIsDialogOpen(true)}
        >
          {label}
        </Button>
      </PermissionController>
    </>
  );
};

export default AddOrCreateUserButton;
