import React from "react";
import { FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { messages } from "./ShareFileFields.messages";
import UserSelectField from "../UserSelectField/UserSelectField";
import { User } from "../../../Models/User";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { UserRoleType } from "../../../generated/user-api";
import PermissionController from "../../PermissionController/PermissionController";

export interface MeetingFormikValues {
  participants: User[];
  externalParticipants: User[];
  staffParticipants: User[];
}

interface Props {
  formik: FormikProps<MeetingFormikValues>;
  disabledFields?: string[];
  clientOptionList?: User[];
  externalOptionList?: User[];
  staffOptionList?: User[];
}

const ShareFileFields = (props: Props) => {
  const intl = useIntl();
  const {
    formik,
    disabledFields,
    clientOptionList,
    externalOptionList,
    staffOptionList,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserSelectField
          id="staffParticipants"
          formik={formik}
          disabledFields={disabledFields}
          userOptionList={staffOptionList}
          label={intl.formatMessage(messages.staffParticipantsLabel)}
        />
      </Grid>
      <PermissionController
        allowedRoles={[UserRoleType.Staff, UserRoleType.PlatformAdmin]}
      >
        <Grid item xs={12}>
          <UserSelectField
            id="participants"
            role={UserRoleType.Client}
            label={intl.formatMessage(messages.participantsLabel)}
            formik={formik}
            userOptionList={clientOptionList}
            disabledFields={disabledFields}
          />
        </Grid>
        <FeatureController requiredFeature={CustomerFeatureType.ExternalUsers}>
          <Grid item xs={12}>
            <UserSelectField
              id="externalParticipants"
              role={UserRoleType.External}
              label={intl.formatMessage(messages.externalParticipantsLabel)}
              formik={formik}
              userOptionList={externalOptionList}
              disabledFields={disabledFields}
            />
          </Grid>
        </FeatureController>
      </PermissionController>
    </Grid>
  );
};

export default ShareFileFields;
