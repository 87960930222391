import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleLabel: {
    id: "previewVideoCard.title.label",
    defaultMessage: "Video",
    description: "Title label",
  },
  content: {
    id: "previewVideoCard.content.text",
    defaultMessage:
      "Make sure that you can see yourself in the video on this page.",
    description: "Content text",
  },
  errorContent: {
    id: "previewVideoCard.content.error.text",
    defaultMessage: "Camera permission needs to be granted",
    description: "Error content text",
  },
  errorNoDeviceFound: {
    id: "previewVideoCard.content.error.noDevice.text",
    defaultMessage: "No camera device could be found.",
    description: "Error content text when no camera device found",
  },
  videoSourceLabel: {
    id: "previewVideoCard.field.videoSource.label",
    defaultMessage: "Video Source",
    description: "Label for video source field",
  },
  showHowButtonLabel: {
    id: "previewVideoCard.button.showHow.label",
    defaultMessage: "Show how",
    description: "Label show how button",
  },
});
