import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  AttendanceStatusType,
  MeetingResponse,
  MeetingStatusType,
} from "../../../generated/meeting-api";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { messages } from "./ConnectToMeetingButton.messages";
import { UserRoleType } from "../../../generated/user-api";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import {
  getAttendanceStatusByUserId,
  isMeetingLeader,
} from "../../../Utils/Meeting";
import { Variant } from "../../BookingsList/Components/Variant/Variant";
import { RoutePath } from "../../Routes/RoutePath";
import { meetingService } from "../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  meeting: MeetingResponse;
  contextVariant: Variant;
  className?: string;
  overrideLabel?: {
    id?: string | number;
    description?: string;
    defaultMessage?: string;
  };
}

const ConnectToMeetingButton = (props: Props) => {
  const { meeting, className, overrideLabel, contextVariant } = props;
  const [label, setLabel] = useState<string>();
  const intl = useIntl();
  const history = useHistory();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const { enqueueSnackbar } = useSnackbar();
  const attendanceStatus = getAttendanceStatusByUserId(
    authenticatedUser.user?.id,
    meeting.participants
  );

  const defineButtonLabel = () => {
    if (overrideLabel) {
      setLabel(intl.formatMessage(overrideLabel));
    } else if (
      isMeetingLeader(meeting, authenticatedUser.user) &&
      meeting.status === MeetingStatusType.Created
    ) {
      setLabel(intl.formatMessage(messages.startMeetingButton));
    } else {
      setLabel(intl.formatMessage(messages.connectMeetingButton));
    }
  };

  useEffect(() => {
    defineButtonLabel();
  }, [intl.locale]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    defineButtonLabel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToMeetingPage = () => {
    history.push({
      pathname: RoutePath.SESSION_VIDEO,
      search: `?meetingId=${meeting.id}`,
    });
  };

  const startMeeting = () => {
    if (customerId && meeting.id && authenticatedUser.user) {
      meetingService()
        .startMeetingSession({
          customerId,
          meetingId: meeting.id,
          meetingStartRequest: {
            userId: authenticatedUser.user?.id,
          },
        })
        .then(() => redirectToMeetingPage())
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          });
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        });
    }
  };

  const handleConnectToMeeting = () => {
    if (
      authenticatedUser.user?.userRole === UserRoleType.Staff &&
      meeting.status !== MeetingStatusType.InProgress
    ) {
      startMeeting();
    } else {
      redirectToMeetingPage();
    }
  };

  if (
    meeting.status !== MeetingStatusType.InProgress &&
    !isMeetingLeader(meeting, authenticatedUser?.user)
  ) {
    return null;
  }

  if (
    contextVariant === Variant.PAST_BOOKINGS &&
    attendanceStatus !== AttendanceStatusType.Confirmed
  ) {
    return null;
  }

  if (
    contextVariant !== Variant.ADMIN_UPCOMING_BOOKINGS &&
    contextVariant !== Variant.INCOMING_BOOKING_REQUESTS &&
    contextVariant !== Variant.DASHBOARD_INCOMING_BOOKING_REQUESTS &&
    (meeting.status === MeetingStatusType.Created ||
      meeting.status === MeetingStatusType.InProgress)
  ) {
    return (
      <Button
        size="small"
        color="primary"
        className={className}
        variant="contained"
        onClick={handleConnectToMeeting}
      >
        {label}
      </Button>
    );
  }

  return null;
};

export default ConnectToMeetingButton;
