import React, { Dispatch, SetStateAction, useContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

const CurrentPathContext = React.createContext<
  [string | undefined, Dispatch<SetStateAction<string | undefined>>]
>([undefined, () => {}]);

export const useCurrentPath = () => {
  return useContext(CurrentPathContext);
};

const CurrentPathProvider = (props: Props) => {
  const { children } = props;

  return (
    <CurrentPathContext.Provider value={useState<string>()}>
      {children}
    </CurrentPathContext.Provider>
  );
};

export default CurrentPathProvider;
