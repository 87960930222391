import * as Yup from "yup";
import subDays from "date-fns/subDays";

// text string is used as a key to fetch intl message
const TodoValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().max(255, "titleTooLong").required("titleRequired"),
    description: Yup.string().max(32000, "descriptionTooLong"),
    assignedTo: Yup.array().min(1, "participantsRequired"),
    shouldBeCompletedOn: Yup.date().min(
      subDays(new Date(), 1),
      "shouldBeCompletedOnDateInPast"
    ),
  });
};

export default TodoValidationSchema;
