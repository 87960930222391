import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useIntl } from "react-intl";
import { messages } from "./SafariDesktopGuide.messages";
import SafariDesktop1 from "../../../../../Assets/safari-desktop-1.png";
import SafariDesktop2 from "../../../../../Assets/safari-desktop-2.png";
import { useStyles } from "./SafariDesktopGuide.style";

const SafariDesktopGuide = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle)}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          <ol>
            <li>{intl.formatMessage(messages.step1)}</li>
            <li>{intl.formatMessage(messages.step2)}</li>
            <li>{intl.formatMessage(messages.step3)}</li>
            <img
              className={classes.image}
              src={SafariDesktop1}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step4)}</li>
            <img
              style={{ maxWidth: 500 }}
              className={classes.image}
              src={SafariDesktop2}
              alt={intl.formatMessage(messages.imgAltText)}
            />
          </ol>
        </Typography>
      </DialogContent>
    </>
  );
};

export default SafariDesktopGuide;
