import { defineMessages } from "react-intl";

export const messages = defineMessages({
  nextMeeting: {
    id: "contactlist.item.nextmeeting",
    defaultMessage: "Next meeting",
    description: "title for work title in list item",
  },
  phoneTitle: {
    id: "contactlist.item.phonetitle",
    defaultMessage: "Phone",
    description: "title for phone in list item",
  },
  emailTitle: {
    id: "contactlist.item.emailtitle",
    defaultMessage: "Email",
    description: "title for email in list item",
  },
});
