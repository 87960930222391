import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperEmpty: {
      minHeight: "80vh",
      textAlign: "center",
      paddingTop: "30vh",
    },
    noFilesLabel: {
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: 100,
    },
    actionContainer: {
      marginTop: theme.spacing(1),
      width: "100%",
      textAlign: "right",
    },
  })
);
