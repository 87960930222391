/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A user object that can act as assignee or assigner
 * @export
 * @interface ToDoUserResponse
 */
export interface ToDoUserResponse {
    /**
     * The UUID of this user
     * @type {string}
     * @memberof ToDoUserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ToDoUserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ToDoUserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ToDoUserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ToDoUserResponse
     */
    workTitle?: string;
}

export function ToDoUserResponseFromJSON(json: any): ToDoUserResponse {
    return ToDoUserResponseFromJSONTyped(json, false);
}

export function ToDoUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToDoUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
    };
}

export function ToDoUserResponseToJSON(value?: ToDoUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'workTitle': value.workTitle,
    };
}


