import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useSubscription } from "react-stomp-hooks";
import { useStyles } from "./SharedFilesSlider.style";
import { messages } from "./SharedFilesSlider.messages";
import { Meeting } from "../../../../../../Models/Meeting";
import {
  PaginatedFileList,
  SortByType,
  SortOrderType,
} from "../../../../../../generated/file-api";
import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { fileService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";
import { SocketDestination } from "../../../../../../WebSocket/WebSocketSession";
import {
  parseSignalMessage,
  SignalType,
} from "../../../../../../WebSocket/model/SignalMessage";
import FeatureController from "../../../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../../generated/customersettings-api";
import CustomPagination from "../../../../../../Components/CustomPagination/Pagination";
import FileUploadButton from "../../../../../../Components/Buttons/FileUploadButton/FileUploadButton";
import FileList from "../../../../../../Components/SharedFilesCard/Components/FileList/FileList";
import DialogCloseButton from "../../../../../../Components/Buttons/DialogCloseButton/DialogCloseButton";

interface Props {
  meeting?: Meeting;
  onLoaded?: () => void;
  onLoading?: () => void;
  onClose: () => void;
}

const SharedFilesSlider = (props: Props) => {
  const { meeting, onLoaded, onLoading, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [files, setFiles] = useState<PaginatedFileList>();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);

  const loadFiles = () => {
    if (customerId && authenticatedUser.user) {
      if (onLoading) {
        onLoading();
      }
      fileService()
        .listFiles({
          page,
          pageSize,
          customerId,
          meetingId: meeting?.id,
          sortBy: SortByType.UploadedOn,
          sortOrder: SortOrderType.Descending,
        })
        .then(setFiles)
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(onLoaded);
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meeting?.id, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.FILE &&
      signalMessage.triggeredBy !== authenticatedUser.user?.id
    ) {
      loadFiles();
    }
  });

  useEffect(() => {
    loadFiles();
  }, [meeting?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadFiles();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!files) {
    return null;
  }

  if (isSmallScreen) {
    return (
      <FeatureController
        requiredFeature={CustomerFeatureType.InMeetingFileShare}
      >
        <Dialog open fullScreen className={classes.dialogRoot} scroll="paper">
          {onClose && <DialogCloseButton onClose={onClose} />}
          <DialogTitle>
            <Typography
              className={classes.cardHeading}
              component="h2"
              variant="subtitle2"
            >
              {intl.formatMessage(messages.title).toUpperCase()}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="subtitle2">
              <Box fontStyle="italic">
                {intl.formatMessage(messages.description)}
              </Box>
            </Typography>
            <Divider />
            <FileList files={files.data || []} onDeleted={loadFiles} />
            <CustomPagination
              size="small"
              onChange={setPage}
              pagination={files.pagination}
            />
          </DialogContent>
          <DialogActions>
            <div className={classes.inputContainer}>
              <FileUploadButton
                meetingId={meeting?.id}
                onFileUploaded={loadFiles}
              />
            </div>
          </DialogActions>
        </Dialog>
      </FeatureController>
    );
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingFileShare}>
      <Container className={classes.root}>
        <Typography
          className={classes.cardHeading}
          component="h2"
          variant="subtitle2"
        >
          {intl.formatMessage(messages.title).toUpperCase()}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          <Box fontStyle="italic">
            {intl.formatMessage(messages.description)}
          </Box>
        </Typography>
        <Divider />
        <div className={classes.contentContainer}>
          <FileList files={files.data || []} onDeleted={loadFiles} />
          <CustomPagination
            size="small"
            onChange={setPage}
            pagination={files.pagination}
          />
        </div>
        <div className={classes.inputContainer}>
          <FileUploadButton
            meetingId={meeting?.id}
            onFileUploaded={loadFiles}
          />
        </div>
      </Container>
    </FeatureController>
  );
};

export default SharedFilesSlider;
