import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    textContainer: {
      margin: 5,
    },
    margin: {
      margin: 10,
    },
    buttonGrid: {
      textAlign: "center",
    },
  })
);
