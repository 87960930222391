import { List } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { RoutePath } from "../Routes/RoutePath";
import { PaginatedCustomerList } from "../../generated/customer-api";
import CustomerListItem from "./Components/CustomerListItem/CustomerListItem";

export interface Props {
  list: PaginatedCustomerList;
}

export default function CustomerList(props: Props) {
  const { list } = props;
  const history = useHistory();

  const handleItemClick = (customerId: string) => {
    history.push({
      pathname: `${RoutePath.PORTAL_CUSTOMER_SETTINGS}/${customerId}`,
      state: { customerId },
    });
  };

  if (!list) {
    return null;
  }

  return (
    <List>
      {list.data?.map((customer) => (
        <CustomerListItem
          key={customer.id}
          customer={customer}
          onItemClick={handleItemClick}
        />
      ))}
    </List>
  );
}
