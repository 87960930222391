import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dashboardview.fileCard.title",
    defaultMessage: "FILES",
    description: "heading for file card",
  },
  error: {
    id: "dashboardview.fileCard.error",
    defaultMessage: "Something went wrong",
    description: "Error message when fails to load files",
  },
});
