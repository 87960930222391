import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React from "react";
import { FormikProps } from "formik";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./CustomerFields.messages";
import { Market } from "../../../generated/customer-api";
import { useStyles } from "./CustomerFields.styles";
import { messages as errorMessages } from "./Validation/Validation.messages";
import TextField from "../TextField/TextField";
import SelectField, { Item } from "../SelectField/SelectField";

const getErrorMessage = (intl: IntlShape, error: string | undefined) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  disabledFields?: string[] | undefined;
  onIsCustomDomain: (e: boolean) => void;
  isCustomDomain: boolean;
}

export default function CustomerFields(props: Props) {
  const { formik, disabledFields, isCustomDomain, onIsCustomDomain } = props;
  const classes = useStyles();
  const intl = useIntl();
  const { errors } = formik;
  const selectItems = [
    {
      label: intl.formatMessage(messages.customerDialogMarketSwedenLabel),
      value: Market.Sweden,
    },
    {
      label: intl.formatMessage(messages.customerDialogMarketFinlandLabel),
      value: Market.Finland,
    },
  ] as Item[];

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12}>
        <TextField
          id="customerName"
          formik={formik}
          label={intl.formatMessage(messages.customerDialogNameFieldLabel)}
          disabledFields={disabledFields}
          errorMsg={getErrorMessage(intl, errors.customerName?.toString())}
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="domain"
          formik={formik}
          label={intl.formatMessage(messages.customerDialogDomainFieldLabel)}
          disabledFields={disabledFields}
          errorMsg={getErrorMessage(intl, errors.domain?.toString())}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              id="isCustomDomain"
              value={isCustomDomain}
              size="medium"
              onChange={(event, checked) => onIsCustomDomain(checked)}
            />
          }
          label={intl.formatMessage(messages.customerDialogCustomDomainLabel)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="organizationNumber"
          label={intl.formatMessage(messages.customerDialogOrgNumberFieldLabel)}
          formik={formik}
          disabledFields={disabledFields}
          errorMsg={getErrorMessage(
            intl,
            errors.organizationNumber?.toString()
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          id="market"
          label={intl.formatMessage(messages.customerDialogMarketFieldLabel)}
          formik={formik}
          items={selectItems}
          disabledFields={disabledFields}
        />
      </Grid>
    </Grid>
  );
}
