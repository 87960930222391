/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Contact API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddContactRequest
 */
export interface AddContactRequest {
    /**
     * Id of the user to add a contact to.
     * @type {string}
     * @memberof AddContactRequest
     */
    userId: string;
    /**
     * Id of the user to be added as contact.
     * @type {string}
     * @memberof AddContactRequest
     */
    contactId: string;
}

export function AddContactRequestFromJSON(json: any): AddContactRequest {
    return AddContactRequestFromJSONTyped(json, false);
}

export function AddContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddContactRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'contactId': json['contactId'],
    };
}

export function AddContactRequestToJSON(value?: AddContactRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'contactId': value.contactId,
    };
}


