import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleLabel: {
    id: "todofields.title.label",
    defaultMessage: "Title",
    description: "Label for textfield title in todo",
  },
  descriptionLabel: {
    id: "todofields.description.label",
    defaultMessage: "Description",
    description: "Label for textfield description in todo",
  },
  assignedToLabel: {
    id: "todofields.assignedTo.label",
    defaultMessage: "Assigned to",
    description: "Label for textfield assigned to in todo",
  },
  shouldBeCompletedOnLabel: {
    id: "todofields.shouldBeCompletedOn.label",
    defaultMessage: "Should be completed on",
    description: "Label for textfield should be completed on in todo",
  },
});
