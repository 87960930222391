import React, { ChangeEvent, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useIntl } from "react-intl";
import List from "@material-ui/core/List";
import {
  Button,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@material-ui/core/Avatar";
import { Home } from "@material-ui/icons";
import DialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { messages } from "./TakeCareSyncDialog.messages";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import {
  customerSettingsServiceToken,
  syncService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import {
  CustomerUnit,
  SourceType,
} from "../../../generated/customersettings-api";

interface Props {
  userId: string;
  onSynced?: () => void;
  isOpen: boolean;
  onClose?: () => void;
}

const TakeCareSyncDialog = (props: Props) => {
  const { userId, isOpen, onClose, onSynced } = props;
  const intl = useIntl();
  const theme = useTheme();
  const [units, setUnits] = useState<CustomerUnit[] | undefined>([]);
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const snackbarAction = (snackbarId: string) => (
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  const loadCustomerUnits = () => {
    customerSettingsServiceToken()
      .listUnits({ sourceType: SourceType.Takecare })
      .then((res) => setUnits(res.units))
      .catch(() =>
        enqueueSnackbar(intl.formatMessage(messages.generalError), {
          variant: "error",
        })
      );
  };

  const handleSync = () => {
    syncService()
      .syncBookings({
        syncRequest: {
          userId,
          units: selectedUnits?.map((selectedUnit) => selectedUnit) || [],
        },
      })
      .then((res) => {
        if (onClose) {
          onClose();
        }
        if (onSynced) {
          onSynced();
        }
        if (res.created < 1 && res.canceled < 1) {
          enqueueSnackbar(intl.formatMessage(messages.info), {
            variant: "info",
            persist: true,
            action: snackbarAction,
          });
        } else if (res.created === 0 && res.canceled > 0) {
          enqueueSnackbar(
            intl.formatMessage(messages.successOnlyCanceled, {
              canceled: res.canceled,
            }),
            {
              variant: "success",
              persist: true,
              action: snackbarAction,
            }
          );
        } else if (res.created > 0 && res.canceled === 0) {
          enqueueSnackbar(
            intl.formatMessage(messages.successOnlyCreated, {
              created: res.created,
            }),
            {
              variant: "success",
              persist: true,
              action: snackbarAction,
            }
          );
        } else if (res.created > 0 && res.canceled > 0) {
          enqueueSnackbar(
            intl.formatMessage(messages.successCreatedAndCanceled, {
              created: res.created,
              canceled: res.canceled,
            }),
            {
              variant: "success",
              persist: true,
              action: snackbarAction,
            }
          );
        }
      })
      .catch(() =>
        enqueueSnackbar(intl.formatMessage(messages.generalError), {
          variant: "error",
          persist: true,
          action: snackbarAction,
        })
      );
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedUnits([...selectedUnits, event.target.value]);
    } else {
      setSelectedUnits(selectedUnits.filter((u) => u !== event.target.value));
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadCustomerUnits();
    }
    return () => setSelectedUnits([]);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <DialogCloseButton onClose={onClose} />
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.title)}
      </DialogTitle>
      <List>
        {units?.map((u) => (
          <ListItem key={u.hsaId} button>
            <ListItemAvatar>
              <Avatar>
                <Home />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={u.name} secondary={u.hsaId} />
            <ListItemSecondaryAction>
              <Checkbox value={u.hsaId} onChange={handleChange} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={selectedUnits.length < 1}
          onClick={handleSync}
        >
          {intl.formatMessage(messages.submitButtonLabel)}
        </Button>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(messages.cancelButtonLabel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TakeCareSyncDialog;
