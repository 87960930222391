import { defineMessages } from "react-intl";

export const messages = defineMessages({
  participantsTooltip: {
    id: "sessionVideo.tab.participants.tooltip",
    defaultMessage: "Participants",
    description: "Tooltip for sessionVideo tab Participants",
  },
  chatTooltip: {
    id: "sessionVideo.tab.chat.tooltip",
    defaultMessage: "Chat",
    description: "Tooltip for sessionVideo tab Chat",
  },
  todoTooltip: {
    id: "sessionVideo.tab.chat.todo",
    defaultMessage: "Todo",
    description: "Tooltip for sessionVideo tab Todo",
  },
  fileTooltip: {
    id: "sessionVideo.tab.chat.file",
    defaultMessage: "File",
    description: "Tooltip for sessionVideo tab File",
  },
  privateNoteTooltip: {
    id: "sessionVideo.tab.chat.privateNote",
    defaultMessage: "Private notes",
    description: "Tooltip for sessionVideo tab Private Note",
  },
  troubleshootTooltip: {
    id: "sessionVideo.tab.chat.troubleshoot",
    defaultMessage: "Troubleshoot",
    description: "Tooltip for sessionVideo tab Troubleshoot",
  },
});
