import { createMuiTheme } from "@material-ui/core";
import { CustomerThemeResponse } from "../../../../generated/customersettings-api";

// Primary Colors
export const PRIMARY_PANTONE_303 = "#00354E";

// Accent Colors
export const ACCENT_PANTONE_RHODAMINE_RED = "#E5005B";

// Background color
const BACKGROUND = "#f2f2f2";

const HOVER_GRAY = "#b2b2b2ff";
const HOVER_LIGHT_GRAY = "#f5f5f5ff";

// Default theme goes here
export const defaultTheme = (customTheme?: CustomerThemeResponse) =>
  createMuiTheme({
    overrides: {
      MuiButton: {
        outlinedPrimary: {
          color: "black",
          "&:hover": {
            backgroundColor: HOVER_GRAY,
          },
        },
        outlinedSecondary: {
          color: "black",
          "&:hover": {
            backgroundColor: HOVER_GRAY,
          },
        },
        textPrimary: {
          color: "black",
          "&:hover": {
            backgroundColor: HOVER_LIGHT_GRAY,
          },
        },
        textSecondary: {
          color: "black",
          "&:hover": {
            backgroundColor: HOVER_LIGHT_GRAY,
          },
        },
        root: {
          borderRadius: 50,
        },
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    palette: {
      background: {
        default: BACKGROUND,
      },
      primary: {
        main: customTheme?.primaryColor || PRIMARY_PANTONE_303,
      },
      secondary: {
        main: customTheme?.secondaryColor || ACCENT_PANTONE_RHODAMINE_RED,
      },
    },
  });
