import { defineMessages } from "react-intl";

export const messages: { [key: string]: any } = defineMessages({
  dialogSelectOptionConsent: {
    id: "dialog.consentTitle.create",
    defaultMessage: "Consent agreement",
    description: "Select option for consent agreement dialog",
  },
  dialogSelectOptionPolicy: {
    id: "dialog.policyTitle.create",
    defaultMessage: "Privacy policy",
    description: "Select option for privacy policy dialog",
  },
  dialogTitleField: {
    id: "dialog.titleField.create",
    defaultMessage: "Title",
    description: "Label for title field",
  },
  errorMissingFieldsMessage: {
    id: "dialog.createConsentAgreement.errorMissingFields",
    defaultMessage: "Fields unfilled",
    description:
      "Error message if trying to creating new agreement/policy without all fields filled",
  },
  successOnCreateMessage: {
    id: "dialog.createConsentAgreement.successOnCreateMessage",
    defaultMessage: "Success",
    description: "Success message if creating new agreement/policy was created",
  },
  saveButtonLabel: {
    id: "dialog.createConsentAgreement.saveButtonLabel",
    defaultMessage: "Save",
    description: "save message on button for creating new agreement/policy",
  },
  dialogSelectLabel: {
    id: "dialog.createConsentAgreement.selectLabel",
    defaultMessage: "Agreement type",
    description:
      "agreement type label on select for creating new agreement/policy",
  },
  generalError: {
    id: "dialog.createConsentAgreement.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when it fails to create a policy or consent",
  },
  validFromDateLabel: {
    id: "dialog.createConsentAgreement.validFromDateLabel",
    defaultMessage: "Valid from",
    description: "Label for valid from for date field",
  },
});
