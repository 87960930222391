import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, ListItem, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import SwitchButtonNotification from "../../Views/CustomerSettings/SwitchButton/SwitchButtonNotification";
import { messages } from "./NotificationFeatures.messages";
import { customerSettingsServiceToken } from "../../Providers/ServiceProvider/ServiceProvider";
import { useStyles } from "./NotificationFeatures.styles";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  CustomerFeature,
  CustomerFeatureResponse,
  CustomerFeatureType,
} from "../../generated/customersettings-api";
import { hasPermission } from "../PermissionController/PermissionController";
import { RoutePath } from "../Routes/RoutePath";
import {
  FeaturesCaregiverConfig,
  FeaturesExternalConfig,
  FeaturesPatientsAndExternalConfig,
} from "./NotificationFeaturesConfig";
import SwitchButton from "../../Views/CustomerSettings/SwitchButton/SwitchButton";

export interface Props {
  customerId?: string;
}

const NotificationFeatures = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const featuresPatientsAndExternalConfig = FeaturesPatientsAndExternalConfig();
  const featuresCaregiverConfig = FeaturesCaregiverConfig();
  const featuresExternalConfig = FeaturesExternalConfig();
  const [authenticatedUser] = useAuthenticatedUser();
  const [enabledFeatures, setEnabledFeatures] = useState<CustomerFeature[]>([]);

  const loadSettings = () => {
    if (customerId) {
      customerSettingsServiceToken()
        .listCustomerFeatures({
          customerId,
        })
        .then((res: CustomerFeatureResponse) => {
          setEnabledFeatures(res.features);
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.getReadData), {
            variant: "error",
          });
        });
    }
  };

  const enableFeature = (feature: CustomerFeatureType) => {
    return [...enabledFeatures, { feature }];
  };

  const disableFeature = (feature: CustomerFeatureType) => {
    return enabledFeatures.filter(
      (customerFeature) => customerFeature.feature !== feature
    );
  };

  const updateFeatures = (feature: CustomerFeatureType, enabled: boolean) => {
    if (customerId) {
      customerSettingsServiceToken()
        .updateCustomerFeatures({
          customerId,
          customerFeature: enabled
            ? enableFeature(feature)
            : disableFeature(feature),
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.getReadData), {
            variant: "error",
          });
        })
        .finally(loadSettings);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.CustomerSettings],
    allowedRoles: [UserRoleType.Staff, UserRoleType.PlatformAdmin],
  });

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {intl.formatMessage(messages.customerNotificationSettingsTab)}
          </h3>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.notificationExternalCategory)}
          </h3>
          <List>
            {featuresExternalConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < featuresExternalConfig.length - 1}
              >
                <SwitchButton
                  label={config.label}
                  featureType={config.feature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(
              messages.notificationPatientsAndExternalCategory
            )}
          </h3>
          <List>
            {featuresPatientsAndExternalConfig.map((config, index) => (
              <ListItem
                key={`${config.smsFeature}-${config.emailFeature}`}
                divider={index < featuresPatientsAndExternalConfig.length - 1}
              >
                <SwitchButtonNotification
                  label={config.label}
                  emailLabel={intl.formatMessage(messages.customerEmailHeader)}
                  smsLabel={intl.formatMessage(messages.customerSmsHeader)}
                  emailFeatureType={config.emailFeature}
                  smsFeatureType={config.smsFeature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.notificationCaregiverCategory)}
          </h3>
          <List>
            {featuresCaregiverConfig.map((config, index) => (
              <ListItem
                key={`${config.smsFeature}-${config.emailFeature}`}
                divider={index < featuresPatientsAndExternalConfig.length - 1}
              >
                <SwitchButtonNotification
                  label={config.label}
                  emailLabel={intl.formatMessage(messages.customerEmailHeader)}
                  smsLabel={intl.formatMessage(messages.customerSmsHeader)}
                  emailFeatureType={config.emailFeature}
                  smsFeatureType={config.smsFeature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NotificationFeatures;
