import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "dialog.changeCustomerStatus.title",
    defaultMessage: "Change customer status",
    description: "Title for status dialog",
  },
  dialogInfoText: {
    id: "dialog.changeCustomerStatus.infoText",
    defaultMessage:
      "You are about to change the status of the customer. \nBank-id verification required",
    description: "Info text for status dialog",
  },
  dialogSelectOptionActive: {
    id: "dialog.changeCustomerStatus.select.active",
    defaultMessage: "Active",
    description: "Select option for change status customer active",
  },
  dialogSelectOptionInactive: {
    id: "dialog.changeCustomerStatus.select.inactive",
    defaultMessage: "Inactive",
    description: "Select option for change status customer inactive",
  },
  dialogSelectOptionClosed: {
    id: "dialog.changeCustomerStatus.select.closed",
    defaultMessage: "Closed",
    description: "Select option for change status customer closed",
  },
  dialogSelectLabel: {
    id: "dialog.changeCustomerStatus.selectLabel",
    defaultMessage: "Status",
    description: "label for select dialog customer status",
  },
  submitButtonLabel: {
    id: "dialog.changeCustomerStatus.submitButton.label",
    defaultMessage: "Update Status",
    description: "Submit button for update status",
  },
  cancelButtonLabel: {
    id: "dialog.changeCustomerStatus.cancelButton.label",
    defaultMessage: "Cancel",
    description: "cancel button label",
  },
  generalError: {
    id: "dialog.changeCustomerStatus.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to update status",
  },
});
