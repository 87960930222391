import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dialog.inviteUser.title",
    defaultMessage: "Select user type",
    description: "dialog title",
  },
  cancelButtonLabel: {
    id: "dialog.inviteUser.button.cancel",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  createButtonLabel: {
    id: "dialog.inviteUser.button.create",
    defaultMessage: "Create",
    description: "label for create button",
  },
  inviteButtonLabel: {
    id: "dialog.inviteUser.button.invite",
    defaultMessage: "Invite",
    description: "label for invite button",
  },
  itemStaffLabel: {
    id: "dialog.inviteUser.item.staff.label",
    defaultMessage: "Caregiver",
    description: "dialog title for invite staff",
  },
  itemClientLabel: {
    id: "dialog.inviteUser.item.client.label",
    defaultMessage: "Patient",
    description: "dialog title for invite client",
  },
  itemExternalLabel: {
    id: "dialog.inviteUser.item.external.label",
    defaultMessage: "External user",
    description: "dialog title for invite external user",
  },
  inviteStaffDialogTitle: {
    id: "dialog.inviteUser.staff.inviteDialog.title",
    defaultMessage: "Invite new caregiver",
    description: "dialog title for invite staff",
  },
  inviteClientDialogTitle: {
    id: "dialog.inviteUser.client.inviteDialog.title",
    defaultMessage: "Invite new patient",
    description: "dialog title for invite client",
  },
  inviteExternalDialogTitle: {
    id: "dialog.inviteUser.external.inviteDialog.title",
    defaultMessage: "Invite new external user",
    description: "dialog title for invite external user",
  },
  createStaffDialogTitle: {
    id: "dialog.inviteUser.staff.createDialog.title",
    defaultMessage: "Create new caregiver",
    description: "dialog title for create staff",
  },
  createClientDialogTitle: {
    id: "dialog.inviteUser.client.createDialog.title",
    defaultMessage: "Create new patient",
    description: "dialog title for create client",
  },
  createExternalDialogTitle: {
    id: "dialog.inviteUser.external.createDialog.title",
    defaultMessage: "Create new external user",
    description: "dialog title for create external user",
  },
});
