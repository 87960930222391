import React, { useEffect } from "react";
import { FormikProps } from "formik";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./UserFields.messages";
import { messages as errorMessages } from "../Validation/Validation.messages";
import CardHeading from "../../../CardHeading/CardHeading";
import TextField from "../../../Fields/TextField/TextField";
import { UserRoleType } from "../../../../generated/user-api";
import FeatureController from "../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import LockedTextField from "../../../Fields/LockedTextField/LockedTextField";
import { hasAnyOfRoles } from "../../../PermissionController/PermissionController";

interface Props {
  userRole: UserRoleType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  disabledFields?: string[] | undefined;
  useHsaIdLock?: boolean;
  loadHsaId?: () => void;
  usePersonNumberLock?: boolean;
  loadPersonNumber?: () => void;
  duplicateHsaIdError?: boolean;
}

const getErrorMessage = (intl: IntlShape, error: string | undefined) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const UserFields = (props: Props) => {
  const {
    formik,
    userRole,
    disabledFields,
    useHsaIdLock,
    loadHsaId,
    duplicateHsaIdError,
    usePersonNumberLock,
    loadPersonNumber,
  } = props;
  const intl = useIntl();
  const { errors } = formik;

  const formatPhoneNumber = async () => {
    const phoneNumber = formik.values.mobilePhoneNumber;
    let strippedPhoneNumber: string = phoneNumber?.replace(/-|\s/g, "");
    if (strippedPhoneNumber && strippedPhoneNumber[0] === "0") {
      strippedPhoneNumber = `+46${strippedPhoneNumber.substring(1)}`;
    }
    await formik.setFieldValue("mobilePhoneNumber", strippedPhoneNumber);
    formik.setFieldTouched("mobilePhoneNumber", true);
  };

  useEffect(() => {
    if (duplicateHsaIdError) {
      formik.setFieldError("hsaId", "duplicateHsaId");
    } else {
      formik.setFieldError("hsaId", undefined);
    }
  }, [duplicateHsaIdError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={2}>
      <CardHeading>
        {intl.formatMessage(messages.personalSectionTitle).toUpperCase()}
      </CardHeading>
      <Grid item xs={12}>
        <LockedTextField
          id="personNumber"
          lockedValue="************"
          placeholder="YYYYMMDDNNNN"
          loadLockedData={loadPersonNumber}
          label={intl.formatMessage(messages.personNumberLabel)}
          formik={formik}
          errorMsg={getErrorMessage(intl, errors.personNumber?.toString())}
          disableLock={!usePersonNumberLock}
          disabledFields={disabledFields}
        />
      </Grid>

      {userRole === UserRoleType.Staff && (
        <FeatureController requiredFeature={CustomerFeatureType.UsesHsaId}>
          <Grid item xs={12}>
            <LockedTextField
              id="hsaId"
              lockedValue="*********"
              placeholder="SEXXXXXXXXXX-XXXX"
              loadLockedData={loadHsaId}
              label={intl.formatMessage(messages.hsaIdLabel)}
              formik={formik}
              errorMsg={
                duplicateHsaIdError
                  ? getErrorMessage(intl, errors.hsaId?.toString())
                  : undefined
              }
              disableLock={!useHsaIdLock}
              disabledFields={disabledFields}
            />
          </Grid>
        </FeatureController>
      )}

      <Grid item xs={6}>
        <TextField
          id="firstName"
          formik={formik}
          onBlur={formatPhoneNumber}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.firstNameLabel)}
          errorMsg={getErrorMessage(intl, errors.firstName?.toString())}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="lastName"
          formik={formik}
          onBlur={formatPhoneNumber}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.lastNameLabel)}
          errorMsg={getErrorMessage(intl, errors.lastName?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="mobilePhoneNumber"
          inputMode="tel"
          formik={formik}
          onBlur={formatPhoneNumber}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.phoneLabel)}
          errorMsg={getErrorMessage(intl, errors.mobilePhoneNumber?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          inputMode="email"
          formik={formik}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.emailLabel)}
          errorMsg={getErrorMessage(intl, errors.email?.toString())}
        />
      </Grid>
      {/* If the displayed user has role Staff or External, show this */}
      {hasAnyOfRoles(userRole, [UserRoleType.Staff, UserRoleType.External]) && (
        <>
          <CardHeading>
            {intl.formatMessage(messages.caregiverSectionTitle).toUpperCase()}
          </CardHeading>
          <Grid item xs={12}>
            <TextField
              id="workTitle"
              formik={formik}
              disabledFields={disabledFields}
              label={intl.formatMessage(messages.workTitleLabel)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UserFields;
