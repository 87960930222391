/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerThemeRequest
 */
export interface CustomerThemeRequest {
    /**
     * UUID of theme
     * @type {string}
     * @memberof CustomerThemeRequest
     */
    id: string;
    /**
     * Theme primary color
     * @type {string}
     * @memberof CustomerThemeRequest
     */
    primaryColor?: string;
    /**
     * Theme secondary color
     * @type {string}
     * @memberof CustomerThemeRequest
     */
    secondaryColor?: string;
    /**
     * Base64-encoded contents of logo
     * @type {string}
     * @memberof CustomerThemeRequest
     */
    logo?: string;
    /**
     * Base64-encoded contents of favicon
     * @type {string}
     * @memberof CustomerThemeRequest
     */
    favicon?: string;
}

export function CustomerThemeRequestFromJSON(json: any): CustomerThemeRequest {
    return CustomerThemeRequestFromJSONTyped(json, false);
}

export function CustomerThemeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerThemeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'primaryColor': !exists(json, 'primaryColor') ? undefined : json['primaryColor'],
        'secondaryColor': !exists(json, 'secondaryColor') ? undefined : json['secondaryColor'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'favicon': !exists(json, 'favicon') ? undefined : json['favicon'],
    };
}

export function CustomerThemeRequestToJSON(value?: CustomerThemeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'primaryColor': value.primaryColor,
        'secondaryColor': value.secondaryColor,
        'logo': value.logo,
        'favicon': value.favicon,
    };
}


