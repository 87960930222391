import React from "react";
import { Tooltip, useTheme, useMediaQuery } from "@material-ui/core";
import format from "date-fns/format";
import CalendarEventInListMode from "./CalendarEventInListMode/CalendarEventInListMode";
import CalendarEventToolTip from "./CalendarEventToolTip/CalendarEventToolTip";
import { getFormattedTimespan } from "../../../Utils/Meeting";
import { Meeting } from "../../../Models/Meeting";

interface Props {
  eventDetails: Meeting;
  eventOnClick: () => void;
  eventInfo: any;
  reloadBookings: () => void;
  calendarApi: any;
}

const CalendarEvent = (props: Props) => {
  const {
    eventDetails,
    eventOnClick,
    eventInfo,
    reloadBookings,
    calendarApi,
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const event = () => {
    const currentView = calendarApi.currentDataManager.state.currentViewType;
    if (currentView === "listWeek") {
      return (
        <CalendarEventInListMode
          eventDetails={eventDetails}
          eventOnClick={eventOnClick}
          eventInfo={eventInfo}
          reloadBookings={reloadBookings}
          calendarApi={calendarApi}
        />
      );
    }
    return (
      <Tooltip
        title={<CalendarEventToolTip eventDetails={eventDetails} />}
        placement="bottom-start"
      >
        <div
          style={
            currentView === "dayGridMonth"
              ? {
                  padding: 2,
                  borderRadius: 5,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingBottom: 15,
                }
              : {}
          }
        >
          {!isSmallScreen && currentView !== "timeGridWeek" && (
            <span className="fc-list-event-dot" style={{ marginRight: 2 }} />
          )}
          {currentView === "dayGridMonth" &&
            (!isExtraSmallScreen ? (
              <b>
                {getFormattedTimespan(
                  eventInfo.event.extendedProps.eventDetails.startDateTime,
                  eventInfo.event.extendedProps.eventDetails.duration
                )}
              </b>
            ) : (
              format(
                eventInfo.event.extendedProps.eventDetails.startDateTime,
                "HH:mm"
              ).toString()
            ))}
          {currentView === "timeGridDay" && (
            <b style={{ marginRight: 10 }}>
              {`${getFormattedTimespan(
                eventInfo.event.extendedProps.eventDetails.startDateTime,
                eventInfo.event.extendedProps.eventDetails.duration
              )}       `}
              {eventInfo.event.title}
            </b>
          )}
          {currentView !== "timeGridDay" && (
            <div
              style={
                currentView === "dayGridMonth"
                  ? {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      position: "absolute",
                      left: !isSmallScreen ? 15 : 0,
                      right: 0,
                      top: 20,
                    }
                  : {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }
              }
            >
              {currentView === "timeGridWeek" &&
                `${getFormattedTimespan(
                  eventInfo.event.extendedProps.eventDetails.startDateTime,
                  eventInfo.event.extendedProps.eventDetails.duration
                )}   `}
              {eventInfo.event.title}
            </div>
          )}
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        eventOnClick();
      }}
      aria-hidden="true"
    >
      {event()}
    </div>
  );
};

export default CalendarEvent;
