import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarButtons: {
      height: 50,
      width: 50,
      margin: theme.spacing(1),
      borderRadius: "5em",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    toolbarButtonsDisabled: {
      height: 50,
      width: 50,
      margin: theme.spacing(1),
      borderRadius: "5em",
      backgroundColor: theme.palette.grey[400],
      "&:hover": {
        backgroundColor: theme.palette.grey[600],
      },
    },
  })
);
