import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./ButtonLoadingWrapper.style";

interface Props {
  children: JSX.Element;
  isLoading: boolean;
}

const ButtonLoadingWrapper = (props: Props) => {
  const { children, isLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {children}
      {isLoading && (
        <CircularProgress
          color="secondary"
          size={25}
          className={classes.buttonProgress}
        />
      )}
    </div>
  );
};

export default ButtonLoadingWrapper;
