/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Response object for when ToDos are created.
 * @export
 * @interface CreateToDoResponse
 */
export interface CreateToDoResponse {
    /**
     * Ids of the ToDos created
     * @type {Array<string>}
     * @memberof CreateToDoResponse
     */
    ids: Array<string>;
}

export function CreateToDoResponseFromJSON(json: any): CreateToDoResponse {
    return CreateToDoResponseFromJSONTyped(json, false);
}

export function CreateToDoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateToDoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
    };
}

export function CreateToDoResponseToJSON(value?: CreateToDoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
    };
}


