/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessageResponse
 */
export interface ChatMessageResponse {
    /**
     * The UUID of this chat message.
     * @type {string}
     * @memberof ChatMessageResponse
     */
    id: string;
    /**
     * The contents of this chat message.
     * @type {string}
     * @memberof ChatMessageResponse
     */
    message: string;
    /**
     * The UUID of the user that sent this chat message.
     * @type {string}
     * @memberof ChatMessageResponse
     */
    sentBy: string;
    /**
     * Date and time this chat message was sent on.
     * @type {Date}
     * @memberof ChatMessageResponse
     */
    sentOn: Date;
}

export function ChatMessageResponseFromJSON(json: any): ChatMessageResponse {
    return ChatMessageResponseFromJSONTyped(json, false);
}

export function ChatMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'message': json['message'],
        'sentBy': json['sentBy'],
        'sentOn': (new Date(json['sentOn'])),
    };
}

export function ChatMessageResponseToJSON(value?: ChatMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'sentBy': value.sentBy,
        'sentOn': (value.sentOn.toISOString()),
    };
}


