/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Content API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerUserAgreementType,
    CustomerUserAgreementTypeFromJSON,
    CustomerUserAgreementTypeFromJSONTyped,
    CustomerUserAgreementTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAgreementResponse
 */
export interface UserAgreementResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAgreementResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgreementResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgreementResponse
     */
    contents: string;
    /**
     * 
     * @type {Date}
     * @memberof UserAgreementResponse
     */
    validFrom: Date;
    /**
     * 
     * @type {CustomerUserAgreementType}
     * @memberof UserAgreementResponse
     */
    type?: CustomerUserAgreementType;
}

export function UserAgreementResponseFromJSON(json: any): UserAgreementResponse {
    return UserAgreementResponseFromJSONTyped(json, false);
}

export function UserAgreementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAgreementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'contents': json['contents'],
        'validFrom': (new Date(json['validFrom'])),
        'type': !exists(json, 'type') ? undefined : CustomerUserAgreementTypeFromJSON(json['type']),
    };
}

export function UserAgreementResponseToJSON(value?: UserAgreementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'contents': value.contents,
        'validFrom': (value.validFrom.toISOString().substr(0,10)),
        'type': CustomerUserAgreementTypeToJSON(value.type),
    };
}


