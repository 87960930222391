import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createButton: {
    id: "portalCustomer.userSettings.button.create.label",
    defaultMessage: "Create",
    description: "Label for create caregiver button",
  },
  createDialogTitle: {
    id: "portalCustomer.userSettings.dialog.create.title",
    defaultMessage: "Create caregiver",
    description: "Title for create new caregiver dialog",
  },
  searchbarPlaceholder: {
    id: "portalCustomer.userSettings.searchbar.placeholder",
    defaultMessage: "Name",
    description: "placeholder for searchbar",
  },
});
