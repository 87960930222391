/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains session id and keys to allow access to a video meeting session.
 * @export
 * @interface MeetingSessionInfo
 */
export interface MeetingSessionInfo {
    /**
     * The id of the video session set up by the video provider.
     * @type {string}
     * @memberof MeetingSessionInfo
     */
    sessionId: string;
    /**
     * Unique user token to allow requested user access to the session of the video provider.
     * @type {string}
     * @memberof MeetingSessionInfo
     */
    userToken: string;
    /**
     * API key to allow client to communicate with video provider.
     * @type {string}
     * @memberof MeetingSessionInfo
     */
    apiKey: string;
    /**
     * EU proxy url to be used by client to be able to send all trafic using EU Proxy.
     * @type {string}
     * @memberof MeetingSessionInfo
     */
    euProxyUrl?: string;
}

export function MeetingSessionInfoFromJSON(json: any): MeetingSessionInfo {
    return MeetingSessionInfoFromJSONTyped(json, false);
}

export function MeetingSessionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingSessionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'userToken': json['userToken'],
        'apiKey': json['apiKey'],
        'euProxyUrl': !exists(json, 'euProxyUrl') ? undefined : json['euProxyUrl'],
    };
}

export function MeetingSessionInfoToJSON(value?: MeetingSessionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'userToken': value.userToken,
        'apiKey': value.apiKey,
        'euProxyUrl': value.euProxyUrl,
    };
}


