import { defineMessages } from "react-intl";

export const messages = defineMessages({
  error: {
    id: "consentDialog.button.accept.error",
    defaultMessage: "Something went wrong",
    description: "Text to be shown when fails to approve consents",
  },
  success: {
    id: "consentDialog.button.accept.success",
    defaultMessage: "Consent approved",
    description: "Text to be shown when successfully approved consents",
  },
  acceptLabel: {
    id: "consentDialog.button.accept.label",
    defaultMessage: "Agree",
    description: "Button label for accept button",
  },
  declineLabel: {
    id: "consentDialog.button.decline.label",
    defaultMessage: "Don't agree",
    description: "Button label for decline button",
  },
  Title: {
    id: "consentDialog.title",
    defaultMessage: "Terms and Condition",
    description: "Dialog title",
  },
  privacyPolicyLabel: {
    id: "consentDialog.stepper.privacyPolicy.label",
    defaultMessage: "Privacy Policy",
    description: "Label for privacy policy",
  },
  consentAgreementLabel: {
    id: "consentDialog.stepper.consentAgreement.label",
    defaultMessage: "Consent",
    description: "label for consent step",
  },
});
