import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    calendar: {
      zIndex: 150,
    },
    datePickerContainer: {
      "& > *": {
        width: "100%",
      },
    },
  })
);
