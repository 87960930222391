import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      textAlign: "left",
      justifyContent: "flex-start",
    },
    right: {
      textAlign: "right",
      justifyContent: "flex-end",
    },
    chip: {
      maxWidth: "90%",
      height: "100%",
      marginBottom: theme.spacing(1),
    },
    label: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      textAlign: "left",
      whiteSpace: "normal",
      overflow: "visible",
    },
  })
);
