import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import DialogTitle from "@material-ui/core/DialogTitle";
import { messages } from "./AllowMediaGuideDialog.messages";
import Tabs, { TabConfig } from "../../Tabs/Tabs";
import ChromeDesktopGuide from "./Components/ChromeDesktopGuide/ChromeDesktopGuide";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import SafariDesktopGuide from "./Components/SafariDesktopGuide/SafariDesktopGuide";
import ChromePhoneGuide from "./Components/ChromePhoneGuide/ChromePhoneGuide";
import SafariPhoneGuide from "./Components/SafariPhoneGuide/SafariPhoneGuide";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AllowMediaGuideDialog = (props: Props) => {
  const { isOpen, onClose } = props;
  const intl = useIntl();
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.chromeDesktopTabTitle),
      component: <ChromeDesktopGuide />,
    },
    {
      title: intl.formatMessage(messages.safariDesktopTabTitle),
      component: <SafariDesktopGuide />,
    },
    {
      title: intl.formatMessage(messages.chromePhoneTabTitle),
      component: <ChromePhoneGuide />,
    },
    {
      title: intl.formatMessage(messages.safariPhoneTabTitle),
      component: <SafariPhoneGuide />,
    },
  ];

  const tabConfigMobileView: TabConfig[] = [
    {
      title: intl.formatMessage(messages.chromePhoneTabTitle),
      component: <ChromePhoneGuide />,
    },
    {
      title: intl.formatMessage(messages.safariPhoneTabTitle),
      component: <SafariPhoneGuide />,
    },
  ];

  return (
    <Dialog
      fullScreen={isMobileWidth}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle />
      <DialogCloseButton onClose={onClose} />
      <Tabs
        showTabs
        tabConfigs={isMobileWidth ? tabConfigMobileView : tabConfig}
      />
    </Dialog>
  );
};

export default AllowMediaGuideDialog;
