import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import { messages } from "./ModifyUser.messages";
import Tabs, { TabConfig } from "../../Tabs/Tabs";
import General from "./Components/General/General";
import Status from "./Components/Status/Status";
import { displayUser } from "../../../Utils/User";
import { User } from "../../../Models/User";
import { UserRoleType } from "../../../generated/user-api";
import { hasFeatureEnabled } from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../../Providers/CustomerProvider/CustomerProvider";
import Password from "./Components/Password/Password";
import { userService } from "../../../Providers/ServiceProvider/ServiceProvider";

export enum Tab {
  GENERAL,
  STATUS,
  PASSWORD,
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onUpdated?: (user: User) => void;
  userId: string;
  visibleTabs: Tab[];
  submitLabel?: string;
}

const ModifyUserDialog = (props: Props) => {
  const {
    isOpen,
    userId,
    onClose,
    onUpdated,
    visibleTabs,
    submitLabel,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const [user, setUser] = useState<User>();
  const customerId = useCustomerId();
  const customerFeatures = useCustomerFeatures();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const tabConfig: TabConfig[] = [];

  useEffect(() => {
    if (customerId && isOpen) {
      userService()
        .getUserById({
          customerId,
          userId,
        })
        .then(setUser);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return null;
  }

  const showGeneralTab = (): boolean => {
    return !!visibleTabs.includes(Tab.GENERAL);
  };

  const showPasswordTab = (): boolean => {
    if (visibleTabs.includes(Tab.PASSWORD)) {
      switch (user.userRole) {
        case UserRoleType.Staff:
          return hasFeatureEnabled(
            CustomerFeatureType.UsernameLoginStaff,
            customerFeatures
          );
        case UserRoleType.Client:
          return hasFeatureEnabled(
            CustomerFeatureType.UsernameLoginClient,
            customerFeatures
          );
        case UserRoleType.External:
          return hasFeatureEnabled(
            CustomerFeatureType.UsernameLoginExternal,
            customerFeatures
          );
        default:
          return false;
      }
    }
    return false;
  };

  const showStatusTab = (): boolean => {
    return !!visibleTabs.includes(Tab.STATUS);
  };

  if (showGeneralTab())
    tabConfig.push({
      title: intl.formatMessage(messages.generalTabTitle),
      component: (
        <General
          user={user}
          onClose={onClose}
          onUpdated={onUpdated}
          submitLabel={submitLabel}
        />
      ),
    });

  if (showStatusTab())
    tabConfig.push({
      title: intl.formatMessage(messages.statusTabTitle),
      component: <Status user={user} onClose={onClose} onUpdated={onUpdated} />,
    });

  if (showPasswordTab())
    tabConfig.push({
      title: intl.formatMessage(messages.passwordTabTitle),
      component: <Password user={user} onClose={onClose} />,
    });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle, {
          name: displayUser(user, true),
        })}
      </DialogTitle>
      <Tabs showTabs tabConfigs={tabConfig} />
    </Dialog>
  );
};

export default ModifyUserDialog;
