export const emptyStringToUndefined = (value?: string): string | undefined =>
  value?.trim().length === 0 ? undefined : value;

export const formatHSAID = (value?: string): string | undefined => {
  if (value !== undefined) {
    const updatedValue = value.replace(/ /g, "");
    if (updatedValue.startsWith("SE")) {
      return updatedValue;
    }
    return `SE${updatedValue}`;
  }
  return undefined;
};
