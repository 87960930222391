import React from "react";
import { Paper } from "@material-ui/core";
import { useStyles } from "./TabContent.style";
import ChatSlider from "../ChatSlider/ChatSlider";
import TodoSlider from "../TodoSlider/TodoSlider";
import { Meeting } from "../../../../../../Models/Meeting";
import SharedFilesSlider from "../SharedFilesSlider/SharedFilesSlider";
import ParticipantSlider from "../ParticipantSlider/ParticipantSlider";
import TroubleshootSlider from "../TroubleshootSlider/TroubleshootSlider";
import PrivateNotesSlider from "../../../PrivateNotesSlider/PrivateNotesSlider";

export enum View {
  CLOSED,
  CHAT,
  TODO,
  FILE,
  NOTE,
  PARTICIPANT,
  TROUBLESHOOT,
}

export interface Props {
  meeting: Meeting;
  active: View;
  onClose: () => void;
}

const TabContent = (props: Props) => {
  const { active, meeting, onClose } = props;
  const classes = useStyles();

  return (
    <>
      {active !== View.CLOSED && (
        <Paper className={classes.root}>
          {active === View.CHAT && (
            <ChatSlider meeting={meeting} onClose={onClose} />
          )}
          {active === View.TODO && (
            <TodoSlider meeting={meeting} onClose={onClose} />
          )}
          {active === View.FILE && (
            <SharedFilesSlider meeting={meeting} onClose={onClose} />
          )}
          {active === View.TROUBLESHOOT && (
            <TroubleshootSlider onClose={onClose} />
          )}
          {active === View.PARTICIPANT && (
            <ParticipantSlider meeting={meeting} onClose={onClose} />
          )}
          {active === View.NOTE && (
            <PrivateNotesSlider
              meetingId={meeting.id}
              isExpanded
              onClose={onClose}
            />
          )}
        </Paper>
      )}
    </>
  );
};

export default TabContent;
