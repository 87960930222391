import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  groupButton: {
    margin: "8px",
    color: "white",
  },
  arrowButton: {
    borderRadius: "5em",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[400],
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
    },
  },
  selected: {
    color: theme.palette.primary.dark,
    backgroundColor: "white",
  },
}));
