export enum SignalAction {
  NEW = "NEW",
  MODIFIED = "MODIFIED",
  DELETED = "DELETED",
  ENDED = "ENDED",
  STARTED = "STARTED",
}

export enum SignalType {
  TODO = "TODO",
  CHAT_MESSAGE = "CHAT_MESSAGE",
  FILE = "FILE",
  MEETING_SESSION = "MEETING_SESSION",
  MEETING = "MEETING",
  MESSAGE = "MESSAGE",
}

export interface SignalMessage {
  affectedIds: Array<string>;
  triggeredBy: string;
  type: SignalType;
  action: SignalAction;
}

export const parseSignalMessage = (body: string): SignalMessage => {
  return JSON.parse(body);
};
