import { defineMessages } from "react-intl";

export const messages = defineMessages({
  searchbarPlaceholder: {
    id: "admin.externals.searchbar.placeholder",
    defaultMessage: "Name or full e-mail",
    description: "place holder for searchbar",
  },
  createButton: {
    id: "admin.externals.button.create.label",
    defaultMessage: "Create",
    description: "Label for create external button",
  },
  inviteButton: {
    id: "admin.externals.button.invite.label",
    defaultMessage: "Invite",
    description: "Label for invite external button",
  },
  createDialogTitle: {
    id: "admin.externals.dialog.create.title",
    defaultMessage: "Create external user",
    description: "Titel for create new external dialog",
  },
  inviteDialogTitle: {
    id: "admin.externals.dialog.invite.title",
    defaultMessage: "Invite external user",
    description: "Titel for create new external dialog",
  },
  newlyAddedOrUpdatedLabel: {
    id: "admin.externals.newlyAddedOrUpdated.label",
    defaultMessage: "Newly added/updated",
    description: "Label for newly added/updated users",
  },
});
