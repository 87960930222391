import { useEffect } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useCustomerIsAdminPortal } from "../../Providers/CustomerProvider/CustomerProvider";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { messages } from "./Sign.messages";
import { customerService } from "../../Providers/ServiceProvider/ServiceProvider";
import {
  CustomerStatusRequest,
  UpdateCustomerStatusRequest,
} from "../../generated/customer-api";

const Sign = () => {
  const [grandidsession] = useQueryParam("grandidsession", StringParam);
  const [customerId] = useQueryParam("customerId", StringParam);
  const [status] = useQueryParam("status", StringParam);
  const [action] = useQueryParam("action", StringParam);
  const history = useHistory();
  const isAdminPortal = useCustomerIsAdminPortal();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const handleCustomerStatusChange = () => {
    customerService()
      .updateCustomerStatus({
        id: customerId,
        body: {
          status,
          sessionId: grandidsession,
        } as CustomerStatusRequest,
      } as UpdateCustomerStatusRequest)
      .then(() => {
        enqueueSnackbar(intl.formatMessage(messages.successUpdate), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage(messages.generalError), {
          variant: "error",
        });
      })
      .finally(() => {
        history.push({
          pathname: `${RoutePath.PORTAL_CUSTOMER_SETTINGS}/${customerId}`,
          state: { customerId },
        });
      });
  };

  useEffect(() => {
    if (action === "CUSTOMER_STATUS_UPDATE" && isAdminPortal) {
      handleCustomerStatusChange();
    } else {
      history.push(RoutePath.DASHBOARD);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Sign;
