import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { messages } from "./Clients.messages";
import { SortByType, SortOrderType } from "../../generated/contact-api";
import CustomPagination from "../../Components/CustomPagination/Pagination";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { contactService } from "../../Providers/ServiceProvider/ServiceProvider";
import ContactList from "../../Components/ContactList/ContactList";
import { PaginatedContactList } from "../../Models/Contact";

const Clients = () => {
  const intl = useIntl();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [result, setResult] = useState<PaginatedContactList>();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>();
  const setSpinner = useSpinner()[1];
  const [isContactListLoading, setIsContactListLoading] = useState<boolean>(
    false
  );

  const isLoading = (): boolean => {
    return isContactListLoading;
  };

  const loadContactList = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsContactListLoading(true);
      contactService()
        .listContactsForUserDecorated(authenticatedUser.user, {
          customerId,
          sortBy: SortByType.LastName,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          name: query !== "" ? query : undefined,
          pageSize,
          page,
        })
        .then((res) => setResult(res))
        .finally(() => setIsContactListLoading(false));
    }
  };

  useEffect(() => {
    loadContactList();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page !== 1) {
      // Reset pagination when search is triggered
      setPage(1);
    } else {
      loadContactList();
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show nothing when loading
  if (isLoading()) {
    return null;
  }

  return (
    <>
      <SearchBar
        minQueryLength={3}
        placeholder={intl.formatMessage(messages.searchbarPlaceholder)}
        onSubmit={(newQuery) => setQuery(newQuery)}
      />
      <ContactList
        contactList={result?.data}
        reloadContacts={() => loadContactList()}
      />
      <CustomPagination onChange={setPage} pagination={result?.pagination} />
    </>
  );
};
export default Clients;
