import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useStyles } from "./LanguageSwitch.style";

interface Props {
  label: string;
  language: "en" | "sv";
  changeLocaleFunction: (newLocale: string) => void;
}

const LanguageSwitch = (props: Props) => {
  const { changeLocaleFunction, label, language } = props;
  const classes = useStyles();

  const handleClick = () => {
    localStorage.setItem("lang", language);
    changeLocaleFunction(language);
    document.documentElement.lang = language;
  };

  return (
    <div>
      <Button className={classes.button}>
        <Typography className={classes.text} onClick={handleClick}>
          {label}
        </Typography>
      </Button>
    </div>
  );
};

export default LanguageSwitch;
