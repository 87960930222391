/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageThreadResponse,
    MessageThreadResponseFromJSON,
    MessageThreadResponseFromJSONTyped,
    MessageThreadResponseToJSON,
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedMessageThreadResponseList
 */
export interface PaginatedMessageThreadResponseList {
    /**
     * 
     * @type {Pagination}
     * @memberof PaginatedMessageThreadResponseList
     */
    pagination: Pagination;
    /**
     * 
     * @type {Array<MessageThreadResponse>}
     * @memberof PaginatedMessageThreadResponseList
     */
    data?: Array<MessageThreadResponse>;
}

export function PaginatedMessageThreadResponseListFromJSON(json: any): PaginatedMessageThreadResponseList {
    return PaginatedMessageThreadResponseListFromJSONTyped(json, false);
}

export function PaginatedMessageThreadResponseListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMessageThreadResponseList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(MessageThreadResponseFromJSON)),
    };
}

export function PaginatedMessageThreadResponseListToJSON(value?: PaginatedMessageThreadResponseList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(MessageThreadResponseToJSON)),
    };
}


