/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateMessageRequest,
    CreateMessageRequestFromJSON,
    CreateMessageRequestToJSON,
    CreateMessageResponse,
    CreateMessageResponseFromJSON,
    CreateMessageResponseToJSON,
    MarkMessagesAsReadRequest,
    MarkMessagesAsReadRequestFromJSON,
    MarkMessagesAsReadRequestToJSON,
    MessageResponse,
    MessageResponseFromJSON,
    MessageResponseToJSON,
    PaginatedMessageResponseList,
    PaginatedMessageResponseListFromJSON,
    PaginatedMessageResponseListToJSON,
    PaginatedMessageThreadResponseList,
    PaginatedMessageThreadResponseListFromJSON,
    PaginatedMessageThreadResponseListToJSON,
    ReplyToMessageRequest,
    ReplyToMessageRequestFromJSON,
    ReplyToMessageRequestToJSON,
    ReplyToMessageResponse,
    ReplyToMessageResponseFromJSON,
    ReplyToMessageResponseToJSON,
    SortByType,
    SortByTypeFromJSON,
    SortByTypeToJSON,
    SortOrderType,
    SortOrderTypeFromJSON,
    SortOrderTypeToJSON,
    UnreadMessagesResponse,
    UnreadMessagesResponseFromJSON,
    UnreadMessagesResponseToJSON,
} from '../models';

export interface CountUnreadMessagesForCurrentUserRequest {
    customerId: string;
    threadId?: string;
}

export interface CreateMessageOperationRequest {
    customerId: string;
    createMessageRequest: CreateMessageRequest;
}

export interface DeleteMessageRequest {
    customerId: string;
    messageId: string;
}

export interface GetMessageRequest {
    customerId: string;
    messageId: string;
}

export interface ListMessageThreadsRequest {
    customerId: string;
    page?: number;
    pageSize?: number;
    sortOrder?: SortOrderType;
    sortBy?: SortByType;
    query?: string;
    onlyUnreadMessages?: boolean;
}

export interface ListMessagesRequest {
    customerId: string;
    threadId: string;
    page?: number;
    pageSize?: number;
    sortOrder?: SortOrderType;
    sortBy?: SortByType;
}

export interface MarkMessagesAsReadOperationRequest {
    customerId: string;
    markMessagesAsReadRequest: MarkMessagesAsReadRequest;
}

export interface ReplyToMessageOperationRequest {
    customerId: string;
    messageId: string;
    createMessageRequest: ReplyToMessageRequest;
}

/**
 * 
 */
export class MessageApi extends runtime.BaseAPI {

    /**
     * Get the number of unread messages for the current user.
     * Get number of unread messages
     */
    async countUnreadMessagesForCurrentUserRaw(requestParameters: CountUnreadMessagesForCurrentUserRequest): Promise<runtime.ApiResponse<UnreadMessagesResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling countUnreadMessagesForCurrentUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.threadId !== undefined) {
            queryParameters['threadId'] = requestParameters.threadId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/{customerId}/unread`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnreadMessagesResponseFromJSON(jsonValue));
    }

    /**
     * Get the number of unread messages for the current user.
     * Get number of unread messages
     */
    async countUnreadMessagesForCurrentUser(requestParameters: CountUnreadMessagesForCurrentUserRequest): Promise<UnreadMessagesResponse> {
        const response = await this.countUnreadMessagesForCurrentUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new message to one or more recipients with the current user as sender.
     * Create new message
     */
    async createMessageRaw(requestParameters: CreateMessageOperationRequest): Promise<runtime.ApiResponse<CreateMessageResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createMessage.');
        }

        if (requestParameters.createMessageRequest === null || requestParameters.createMessageRequest === undefined) {
            throw new runtime.RequiredError('createMessageRequest','Required parameter requestParameters.createMessageRequest was null or undefined when calling createMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMessageRequestToJSON(requestParameters.createMessageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMessageResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new message to one or more recipients with the current user as sender.
     * Create new message
     */
    async createMessage(requestParameters: CreateMessageOperationRequest): Promise<CreateMessageResponse> {
        const response = await this.createMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Permanently delete a specific message. Only the recipient can delete a message.
     * Delete a message
     */
    async deleteMessageRaw(requestParameters: DeleteMessageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteMessage.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling deleteMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/{customerId}/{messageId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permanently delete a specific message. Only the recipient can delete a message.
     * Delete a message
     */
    async deleteMessage(requestParameters: DeleteMessageRequest): Promise<void> {
        await this.deleteMessageRaw(requestParameters);
    }

    /**
     * Get specific message by id.
     * Get a message
     */
    async getMessageRaw(requestParameters: GetMessageRequest): Promise<runtime.ApiResponse<MessageResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMessage.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling getMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/{customerId}/{messageId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageResponseFromJSON(jsonValue));
    }

    /**
     * Get specific message by id.
     * Get a message
     */
    async getMessage(requestParameters: GetMessageRequest): Promise<MessageResponse> {
        const response = await this.getMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a paginated list of message threads belonging to the current user.
     * List message threads
     */
    async listMessageThreadsRaw(requestParameters: ListMessageThreadsRequest): Promise<runtime.ApiResponse<PaginatedMessageThreadResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listMessageThreads.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.onlyUnreadMessages !== undefined) {
            queryParameters['onlyUnreadMessages'] = requestParameters.onlyUnreadMessages;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMessageThreadResponseListFromJSON(jsonValue));
    }

    /**
     * Get a paginated list of message threads belonging to the current user.
     * List message threads
     */
    async listMessageThreads(requestParameters: ListMessageThreadsRequest): Promise<PaginatedMessageThreadResponseList> {
        const response = await this.listMessageThreadsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a paginated list of messages belonging to the current user.
     * List messages in thread
     */
    async listMessagesRaw(requestParameters: ListMessagesRequest): Promise<runtime.ApiResponse<PaginatedMessageResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listMessages.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling listMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/{customerId}/thread/{threadId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"threadId"}}`, encodeURIComponent(String(requestParameters.threadId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMessageResponseListFromJSON(jsonValue));
    }

    /**
     * Get a paginated list of messages belonging to the current user.
     * List messages in thread
     */
    async listMessages(requestParameters: ListMessagesRequest): Promise<PaginatedMessageResponseList> {
        const response = await this.listMessagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Marks one or more messages as read
     * Mark messages as read
     */
    async markMessagesAsReadRaw(requestParameters: MarkMessagesAsReadOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling markMessagesAsRead.');
        }

        if (requestParameters.markMessagesAsReadRequest === null || requestParameters.markMessagesAsReadRequest === undefined) {
            throw new runtime.RequiredError('markMessagesAsReadRequest','Required parameter requestParameters.markMessagesAsReadRequest was null or undefined when calling markMessagesAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MarkMessagesAsReadRequestToJSON(requestParameters.markMessagesAsReadRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks one or more messages as read
     * Mark messages as read
     */
    async markMessagesAsRead(requestParameters: MarkMessagesAsReadOperationRequest): Promise<void> {
        await this.markMessagesAsReadRaw(requestParameters);
    }

    /**
     * Post a reply to this message
     * Reply to message
     */
    async replyToMessageRaw(requestParameters: ReplyToMessageOperationRequest): Promise<runtime.ApiResponse<ReplyToMessageResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling replyToMessage.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling replyToMessage.');
        }

        if (requestParameters.createMessageRequest === null || requestParameters.createMessageRequest === undefined) {
            throw new runtime.RequiredError('createMessageRequest','Required parameter requestParameters.createMessageRequest was null or undefined when calling replyToMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message/{customerId}/{messageId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReplyToMessageRequestToJSON(requestParameters.createMessageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReplyToMessageResponseFromJSON(jsonValue));
    }

    /**
     * Post a reply to this message
     * Reply to message
     */
    async replyToMessage(requestParameters: ReplyToMessageOperationRequest): Promise<ReplyToMessageResponse> {
        const response = await this.replyToMessageRaw(requestParameters);
        return await response.value();
    }

}
