import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import {
  UserResponse,
  UserStatusType,
} from "../../../../../generated/user-api";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../../../../Providers/CustomerProvider/CustomerProvider";
import UserStatus from "../../../../UserStatus/UserStatus";
import CardHeading from "../../../../CardHeading/CardHeading";
import { messages as dialogMessages } from "../../ModifyUser.messages";
import { messages } from "./Status.messages";
import { displayUser } from "../../../../../Utils/User";
import { useCustomerCurrentUpdate } from "../../../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { userService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import FeatureController from "../../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../../generated/customersettings-api";

interface Props {
  user: UserResponse;
  onClose: () => void;
  onUpdated?: (user: UserResponse) => void;
}

const Status = (props: Props) => {
  const { user, onClose, onUpdated } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useCustomerId();
  const customerFeatures = useCustomerFeatures();
  const [userStatus, setUserStatus] = useState<UserStatusType>(
    // @ts-ignore
    user.userStatus
  );

  const [customerupdates, setCustomerupdates] = useCustomerCurrentUpdate();
  const handleSubmit = () => {
    if (customerId && user) {
      userService()
        .updateUserStatus({
          customerId,
          userId: user.id,
          body: { newStatus: userStatus },
        })
        .then((res) => {
          if (onUpdated) {
            onUpdated(res);
          }
          const arrayCopy: UserResponse[] = customerupdates.userResponse.filter(
            (element: UserResponse) => {
              return element.id !== user.id;
            }
          );
          user.userStatus = userStatus;
          arrayCopy.push(user);
          setCustomerupdates({ userResponse: arrayCopy });
          enqueueSnackbar(
            intl.formatMessage(dialogMessages.success, {
              name: displayUser(res, true),
            }),
            { variant: "success" }
          );
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(dialogMessages.generalError), {
            variant: "error",
          })
        )
        .finally(onClose);
    }
    onClose();
  };

  return (
    <form>
      <DialogContent>
        <FeatureController
          requiredFeature={CustomerFeatureType.AnonymizeClosedUser}
        >
          {userStatus === UserStatusType.Closed && (
            <Alert severity="warning">
              {intl.formatMessage(messages.warningMessage, {
                numberOfDays:
                  customerFeatures.find(
                    (cf) =>
                      cf.feature === CustomerFeatureType.AnonymizeClosedUser
                  )?.value || "0",
              })}
            </Alert>
          )}
        </FeatureController>
        <CardHeading>
          {intl.formatMessage(messages.statusGroupLabel).toUpperCase()}
        </CardHeading>
        <UserStatus user={user} onChange={setUserStatus} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {intl.formatMessage(dialogMessages.submitButtonLabel)}
        </Button>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(dialogMessages.cancelButtonLabel)}
        </Button>
      </DialogActions>
    </form>
  );
};

export default Status;
