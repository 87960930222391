import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Paper } from "@material-ui/core";
import { ImageList } from "@mui/material";
import { messages } from "./Video.messages";
import { useStyles } from "./Video.style";
import VideoFilterUploadButton from "../../../../Components/Buttons/VideoFilterUploadButton/VideoFilterUploadButton";
import { videoFilterService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { PaginatedFilterList } from "../../../../generated/video-api";
import CustomPagination from "../../../../Components/CustomPagination/Pagination";
import BackgroundImageItem from "../BackgroundImageItem/BackgroundImageItem";

const Video = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [page, setPage] = useState<number>(1);
  const [paginatedFilter, setPaginatedFilter] = useState<PaginatedFilterList>();

  const loadVideoFilterList = () => {
    if (customerId) {
      videoFilterService()
        .listfilter({
          customerId,
          page,
          pageSize: 5,
        })
        .then((res) => {
          setPaginatedFilter(res);
        });
    }
  };

  useEffect(() => {
    loadVideoFilterList();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper className={classes.margin}>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {intl.formatMessage(messages.title)}
          </h3>
          <VideoFilterUploadButton
            variant="text"
            onFileUploaded={() => loadVideoFilterList()}
          />
        </Grid>
        <Grid item xs={12}>
          {paginatedFilter && paginatedFilter.data && (
            <>
              <ImageList variant="standard" cols={5} gap={8}>
                {paginatedFilter.data.map((file) => (
                  <BackgroundImageItem file={file} />
                ))}
              </ImageList>
              <CustomPagination
                onChange={setPage}
                pagination={paginatedFilter?.pagination}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Video;
