import { defineMessages } from "react-intl";

export const messages = defineMessages({
  generalError: {
    id: "verification.error.general",
    defaultMessage: "Failed to verify",
    description: "error description text",
  },
  duplicateUserError: {
    id: "verification.error.duplicateUser",
    defaultMessage: "Duplicate user",
    description: "error description text",
  },
});
