import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useCustomerContent } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useStyles } from "./CustomerLink.style";

const CustomerLinkButton = () => {
  const classes = useStyles();
  const customerContent = useCustomerContent();

  if (
    customerContent.linkButton?.title &&
    customerContent.linkButton?.contents
  ) {
    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.linkButton}
            href={customerContent.linkButton.contents}
            target="_blank"
          >
            {customerContent.linkButton.title}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default CustomerLinkButton;
