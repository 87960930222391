import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: 0,
      paddingRight: 0,
      maxWidth: "md",
    },
    tabs: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      height: 33,
      minHeight: 33,
    },
    tab: {
      padding: 0,
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(-1),
      minWidth: 0,
    },
    tabsContent: {
      backgroundColor: theme.palette.common.white,
    },
    appBar: {
      backgroundColor: theme.palette.common.white,
    },
    extendedAppBar: {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      height: 35,
      position: "absolute",
    },
  })
);
