import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  personNumberRequired: {
    id: "validation.personNumberRequired",
    defaultMessage: "Personal identity number is required",
    description:
      "Text to display when user attempts to enter empty person number",
  },
  personNumberInvalid: {
    id: "validation.personNumberInvalid",
    defaultMessage: "Invalid personal identity number",
    description: "Text to display when user enters invalid person number",
  },
  firstNameRequired: {
    id: "validation.firstNameRequired",
    defaultMessage: "First name is required",
    description: "Text to display when user attempts to enter empty first name",
  },
  firstNameTooLong: {
    id: "validation.firstNameTooLong",
    defaultMessage: "First name can not be longer than 50 characters",
    description:
      "Text to display when user attempts to enter a first name that is longer than 50 characters",
  },
  lastNameRequired: {
    id: "validation.lasttNameRequired",
    defaultMessage: "Last name is required",
    description: "Text to display when user attempts to enter empty last name",
  },
  lastNameTooLong: {
    id: "validation.lastNameTooLong",
    defaultMessage: "Last name can not be longer than 50 characters",
    description:
      "Text to display when user attempts to enter a last name that is longer than 50 characters",
  },
  phoneNumberRequired: {
    id: "validation.phoneNumberRequired",
    defaultMessage:
      "Phone number is required and used to send reminders for your meetings",
    description:
      "Text to display when user attempts to enter empty phone number",
  },
  phoneNumberInvalid: {
    id: "validation.phoneNumberInvalid",
    defaultMessage: "Invalid format",
    description:
      "Text to display when user enters phone number in invalid format",
  },
  emailInvalid: {
    id: "validation.emailInvalid",
    defaultMessage: "Invalid format",
    description: "Text to display when user enters email in invalid format",
  },
  duplicateHsaId: {
    id: "validation.duplicateHsaId",
    defaultMessage: "HSA_ID already exists",
    description: "Text to display when user enters existing HSA_ID",
  },
});
