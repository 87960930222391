/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
    DynamicKeyValue,
    DynamicKeyValueFromJSON,
    DynamicKeyValueFromJSONTyped,
    DynamicKeyValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface SignAuthenticationRequest
 */
export interface SignAuthenticationRequest {
    /**
     * 
     * @type {Action}
     * @memberof SignAuthenticationRequest
     */
    action?: Action;
    /**
     * List of dynamic key values
     * @type {Array<DynamicKeyValue>}
     * @memberof SignAuthenticationRequest
     */
    dynamicKeyValues: Array<DynamicKeyValue>;
}

export function SignAuthenticationRequestFromJSON(json: any): SignAuthenticationRequest {
    return SignAuthenticationRequestFromJSONTyped(json, false);
}

export function SignAuthenticationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignAuthenticationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : ActionFromJSON(json['action']),
        'dynamicKeyValues': ((json['dynamicKeyValues'] as Array<any>).map(DynamicKeyValueFromJSON)),
    };
}

export function SignAuthenticationRequestToJSON(value?: SignAuthenticationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': ActionToJSON(value.action),
        'dynamicKeyValues': ((value.dynamicKeyValues as Array<any>).map(DynamicKeyValueToJSON)),
    };
}


