import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useIntl } from "react-intl";
import { messages } from "./CreateMeetingTemplateButton.messages";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import CreateMeetingTemplateDialog from "../../Dialogs/CreatingMeetingTemplateDialog/CreateMeetingTemplateDialog";

interface Props {
  color?: "inherit" | "primary" | "secondary" | "default";
  overrideLabel?: string;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  onCreated?: () => void;
}

const CreateMeetingTemplateButton = (props: Props) => {
  const { className, onCreated, variant, overrideLabel, color } = props;
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleOnCreated = () => {
    setIsDialogOpen(false);
    if (onCreated) {
      onCreated();
    }
  };

  return (
    <PermissionController
      allowedRoles={[UserRoleType.Staff]}
      atLeastOneOfAuthoritiesStaff={[
        UserAuthorityType.BookMeeting,
        UserAuthorityType.AdminSite,
      ]}
    >
      <CreateMeetingTemplateDialog
        onMeetingCreated={handleOnCreated}
        isDialogOpen={isDialogOpen}
        onDialogClose={() => setIsDialogOpen(false)}
      />
      <Button
        size="small"
        color={color || "primary"}
        className={className}
        startIcon={<AddIcon />}
        variant={variant || "contained"}
        onClick={handleOpenDialog}
      >
        {overrideLabel || intl.formatMessage(messages.buttonLabel)}
      </Button>
    </PermissionController>
  );
};

export default CreateMeetingTemplateButton;
