/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DynamicKeyValue
 */
export interface DynamicKeyValue {
    /**
     * key
     * @type {string}
     * @memberof DynamicKeyValue
     */
    key: string;
    /**
     * value
     * @type {string}
     * @memberof DynamicKeyValue
     */
    value: string;
}

export function DynamicKeyValueFromJSON(json: any): DynamicKeyValue {
    return DynamicKeyValueFromJSONTyped(json, false);
}

export function DynamicKeyValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): DynamicKeyValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function DynamicKeyValueToJSON(value?: DynamicKeyValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}


