import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useSubscription } from "react-stomp-hooks";
import { useStyles } from "./ChatSlider.style";
import { messages } from "./ChatSlider.messages";
import { CustomerFeatureType } from "../../../../../../generated/customersettings-api";
import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import { ChatMessageResponse } from "../../../../../../generated/meetingchat-api";
import { meetingChatService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";
import {
  parseSignalMessage,
  SignalType,
} from "../../../../../../WebSocket/model/SignalMessage";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { SocketDestination } from "../../../../../../WebSocket/WebSocketSession";
import FeatureController from "../../../../../../Components/FeatureController/FeatureController";
import SendChatMessage from "../../../../../../Components/ChatCard/Components/SendChatMessage/SendChatMessage";
import DisplayChat from "../../../../../../Components/ChatCard/Components/DisplayChat/DisplayChat";
import { Meeting } from "../../../../../../Models/Meeting";
import DialogCloseButton from "../../../../../../Components/Buttons/DialogCloseButton/DialogCloseButton";

interface Props {
  meeting: Meeting;
  onLoaded?: () => void;
  onLoading?: () => void;
  onClose: () => void;
}

const ChatSlider = (props: Props) => {
  const { meeting, onLoaded, onLoading, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [chatMessages, setChatMessages] = useState<ChatMessageResponse[]>([]);
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const { enqueueSnackbar } = useSnackbar();
  const [counterNewMessage, setCounterNewMessage] = useState<number>(0);
  const increaseNewMessagesCounter: () => void = () => {
    setCounterNewMessage(counterNewMessage + 1);
  };

  const loadChat = () => {
    if (customerId && meeting.id) {
      if (onLoading) {
        onLoading();
      }
      meetingChatService()
        .listChatMessages({
          customerId,
          meetingId: meeting.id,
        })
        .then((res) => {
          return res;
        })
        .then(setChatMessages)
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          })
        )
        .finally(() => {
          increaseNewMessagesCounter();
          if (onLoaded) onLoaded();
        });
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meeting.id, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.CHAT_MESSAGE &&
      signalMessage.triggeredBy !== authenticatedUser.user?.id
    ) {
      loadChat();
    }
  });

  useEffect(() => {
    loadChat();
  }, [meeting.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isSmallScreen) {
    return (
      <FeatureController requiredFeature={CustomerFeatureType.InMeetingChat}>
        <Dialog open fullScreen className={classes.dialogRoot} scroll="paper">
          {onClose && <DialogCloseButton onClose={onClose} />}
          <DialogTitle>
            <Typography
              className={classes.cardHeading}
              component="h2"
              variant="subtitle2"
            >
              {intl.formatMessage(messages.title).toUpperCase()}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="subtitle2">
              <Box fontStyle="italic">
                {intl.formatMessage(messages.description)}
              </Box>
            </Typography>
            <Divider />
            <DisplayChat
              fullHeight
              chatMessages={chatMessages}
              participants={meeting.participants}
              counterNewMessage={counterNewMessage}
            />
          </DialogContent>
          <DialogActions>
            <div className={classes.inputContainerSx}>
              <SendChatMessage
                meetingId={meeting.id}
                onMessageSent={loadChat}
              />
            </div>
          </DialogActions>
        </Dialog>
      </FeatureController>
    );
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingChat}>
      <Container className={classes.root}>
        <Typography
          className={classes.cardHeading}
          component="h2"
          variant="subtitle2"
        >
          {intl.formatMessage(messages.title).toUpperCase()}
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          <Box fontStyle="italic">
            {intl.formatMessage(messages.description)}
          </Box>
        </Typography>
        <Divider />
        <div className={classes.contentContainer}>
          <DisplayChat
            fullHeight
            chatMessages={chatMessages}
            participants={meeting.participants}
            counterNewMessage={counterNewMessage}
          />
        </div>
        <div className={classes.inputContainer}>
          <SendChatMessage meetingId={meeting.id} onMessageSent={loadChat} />
        </div>
      </Container>
    </FeatureController>
  );
};

export default ChatSlider;
