/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform file management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterUser
 */
export interface FilterUser {
    /**
     * The UUID of this user.
     * @type {string}
     * @memberof FilterUser
     */
    id: string;
    /**
     * First name of this user.
     * @type {string}
     * @memberof FilterUser
     */
    firstName: string;
    /**
     * Last name of this user.
     * @type {string}
     * @memberof FilterUser
     */
    lastName: string;
}

export function FilterUserFromJSON(json: any): FilterUser {
    return FilterUserFromJSONTyped(json, false);
}

export function FilterUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}

export function FilterUserToJSON(value?: FilterUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


