import { defineMessages } from "react-intl";

export const messages = defineMessages({
  meetingNotFoundErrorTitle: {
    id: "session.error.meeting.notfound.title",
    defaultMessage: "Meeting not found!",
    description: "Error title when meeting not found",
  },
  meetingNotFoundErrorDescription: {
    id: "session.error.meeting.notfound.description",
    defaultMessage: "The meeting you are looking for could not be found.",
    description: "Error description when meeting not found",
  },
});
