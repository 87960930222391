import React from "react";
import { IconButton } from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Tooltip from "@material-ui/core/Tooltip";
import { useIntl } from "react-intl";
import { useStyles } from "./MuteAllButton.style";
import { messages } from "./MuteAllButton.messages";

interface Props {
  handleMuteAll: () => void;
  areAllMuted: boolean;
}

export default function MuteAllButton(props: Props) {
  const { handleMuteAll, areAllMuted } = props;
  const intl = useIntl();
  const classes = useStyles();
  const title = areAllMuted
    ? intl.formatMessage(messages.unmuteTooltip)
    : intl.formatMessage(messages.muteTooltip);
  return (
    <Tooltip title={title} aria-label="add">
      <IconButton
        edge="start"
        aria-label="videoCamera"
        onClick={handleMuteAll}
        className={
          areAllMuted ? classes.toolbarButtonsDisabled : classes.toolbarButtons
        }
      >
        {areAllMuted ? (
          <VolumeOffIcon fontSize="inherit" />
        ) : (
          <VolumeUpIcon fontSize="inherit" />
        )}
      </IconButton>
    </Tooltip>
  );
}
