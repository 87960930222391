import {
  Configuration as UserConfig,
  UserApi,
  UserRoleType,
} from "../../generated/user-api";
import { getSessionStorageItem, Key } from "../../Utils/SessionStorage";
import { User } from "../../Models/User";
import { getUserIdsFromContacts } from "../../Utils/UserId";
import {
  decorateContactWithPersonNumber,
  decoratePaginatedContactListWithPersonNumber,
} from "../../Utils/Decorator";
import {
  ContactApi,
  GetContactByIdRequest,
  ListContactsForUserRequest,
} from "../../generated/contact-api";
import { Contact, PaginatedContactList } from "../../Models/Contact";

const BASE_PATH = process.env.REACT_APP_BACKEND_BASE_URL;
const TOKEN_PREFIX = "Bearer "; // needs to be a space in the end

const userService = (): UserApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const userConfig = new UserConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new UserApi(userConfig);
};

export class CustomContactApi extends ContactApi {
  async listContactsForUserDecorated(
    authenticatedUser: User,
    requestParameters: ListContactsForUserRequest
  ): Promise<PaginatedContactList> {
    const originalResponse = await this.listContactsForUserRaw(
      requestParameters
    );
    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        const clientIds = getUserIdsFromContacts(UserRoleType.Client, res.data);
        return userService()
          .listPersonNumbers({
            listPersonNumberRequest: { userIds: Array.from(clientIds) },
          })
          .then((pIds) =>
            decoratePaginatedContactListWithPersonNumber(res, pIds)
          );
      }
      return res;
    });
  }

  async getContactByIdDecorated(
    authenticatedUser: User,
    requestParameters: GetContactByIdRequest
  ): Promise<Contact> {
    const originalResponse = await this.getContactByIdRaw(requestParameters);

    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        return userService()
          .listPersonNumbers({
            listPersonNumberRequest: { userIds: [res.userId] },
          })
          .then((pIds) => decorateContactWithPersonNumber(res, pIds));
      }
      return res;
    });
  }
}
