/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageAttachment,
    MessageAttachmentFromJSON,
    MessageAttachmentFromJSONTyped,
    MessageAttachmentToJSON,
    MessageStatusType,
    MessageStatusTypeFromJSON,
    MessageStatusTypeFromJSONTyped,
    MessageStatusTypeToJSON,
    MessageUserResponse,
    MessageUserResponseFromJSON,
    MessageUserResponseFromJSONTyped,
    MessageUserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * UUID of this message
     * @type {string}
     * @memberof MessageResponse
     */
    id: string;
    /**
     * UUID of the message that this message is a child of.
     * @type {string}
     * @memberof MessageResponse
     */
    threadId: string;
    /**
     * The subject of this message
     * @type {string}
     * @memberof MessageResponse
     */
    subject: string;
    /**
     * The contentes of this message
     * @type {string}
     * @memberof MessageResponse
     */
    contents: string;
    /**
     * 
     * @type {MessageAttachment}
     * @memberof MessageResponse
     */
    attachment?: MessageAttachment;
    /**
     * 
     * @type {MessageUserResponse}
     * @memberof MessageResponse
     */
    recipient: MessageUserResponse;
    /**
     * 
     * @type {MessageUserResponse}
     * @memberof MessageResponse
     */
    sender: MessageUserResponse;
    /**
     * 
     * @type {MessageStatusType}
     * @memberof MessageResponse
     */
    status: MessageStatusType;
    /**
     * Date and time this message was sent
     * @type {Date}
     * @memberof MessageResponse
     */
    sentOn: Date;
    /**
     * Date and time this message was read by the recipient
     * @type {Date}
     * @memberof MessageResponse
     */
    readOn?: Date;
}

export function MessageResponseFromJSON(json: any): MessageResponse {
    return MessageResponseFromJSONTyped(json, false);
}

export function MessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'threadId': json['threadId'],
        'subject': json['subject'],
        'contents': json['contents'],
        'attachment': !exists(json, 'attachment') ? undefined : MessageAttachmentFromJSON(json['attachment']),
        'recipient': MessageUserResponseFromJSON(json['recipient']),
        'sender': MessageUserResponseFromJSON(json['sender']),
        'status': MessageStatusTypeFromJSON(json['status']),
        'sentOn': (new Date(json['sentOn'])),
        'readOn': !exists(json, 'readOn') ? undefined : (new Date(json['readOn'])),
    };
}

export function MessageResponseToJSON(value?: MessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'threadId': value.threadId,
        'subject': value.subject,
        'contents': value.contents,
        'attachment': MessageAttachmentToJSON(value.attachment),
        'recipient': MessageUserResponseToJSON(value.recipient),
        'sender': MessageUserResponseToJSON(value.sender),
        'status': MessageStatusTypeToJSON(value.status),
        'sentOn': (value.sentOn.toISOString()),
        'readOn': value.readOn === undefined ? undefined : (value.readOn.toISOString()),
    };
}


