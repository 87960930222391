import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useIntl } from "react-intl";
import {
  Button,
  List,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ListItemButton } from "@mui/material";
import { messages } from "./InviteUserDialog.messages";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import { UserRoleType } from "../../../generated/user-api";
import { User } from "../../../Models/User";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { useStyles } from "./InviteUserDialog.styles";
import CreateUserButton from "../../Buttons/CreateUserButton/CreateUserButton";
import InviteUserButton from "../../Buttons/InviteUserButton/InviteUserButton";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  onInvited?: (user: User) => void;
  isOpen: boolean;
  onClose?: () => void;
}

const InviteUserDialog = (props: Props) => {
  const { onInvited, isOpen, onClose } = props;
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const customerId = useCustomerId();

  const handleOnCreated = (user: User) => {
    if (onInvited) {
      onInvited(user);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <DialogCloseButton onClose={onClose} />
        <DialogTitle id="form-dialog-title">
          {intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <List>
            <FeatureController
              requiredFeature={CustomerFeatureType.InviteStaff}
            >
              <ListItemButton>
                <ListItemText
                  primary={intl.formatMessage(messages.itemStaffLabel)}
                />
                <ListItemSecondaryAction>
                  {customerId && (
                    <CreateUserButton
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onCreated={handleOnCreated}
                      customerId={customerId}
                      userRole={UserRoleType.Staff}
                      label={intl.formatMessage(messages.createButtonLabel)}
                      dialogTitle={intl.formatMessage(
                        messages.createStaffDialogTitle
                      )}
                    />
                  )}
                  <InviteUserButton
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    onCreated={handleOnCreated}
                    userRole={UserRoleType.Staff}
                    label={intl.formatMessage(messages.inviteButtonLabel)}
                    dialogTitle={intl.formatMessage(
                      messages.inviteStaffDialogTitle
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            </FeatureController>
            <FeatureController
              requiredFeature={CustomerFeatureType.InviteClient}
            >
              <ListItemButton>
                <ListItemText
                  primary={intl.formatMessage(messages.itemClientLabel)}
                />
                <ListItemSecondaryAction>
                  {customerId && (
                    <CreateUserButton
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onCreated={handleOnCreated}
                      userRole={UserRoleType.Client}
                      customerId={customerId}
                      label={intl.formatMessage(messages.createButtonLabel)}
                      dialogTitle={intl.formatMessage(
                        messages.createClientDialogTitle
                      )}
                    />
                  )}

                  <InviteUserButton
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    onCreated={handleOnCreated}
                    userRole={UserRoleType.Client}
                    label={intl.formatMessage(messages.inviteButtonLabel)}
                    dialogTitle={intl.formatMessage(
                      messages.inviteClientDialogTitle
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            </FeatureController>
            <FeatureController
              requiredFeature={CustomerFeatureType.InviteExternal}
            >
              <ListItemButton>
                <ListItemText
                  primary={intl.formatMessage(messages.itemExternalLabel)}
                />
                <ListItemSecondaryAction>
                  {customerId && (
                    <CreateUserButton
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      onCreated={handleOnCreated}
                      customerId={customerId}
                      userRole={UserRoleType.External}
                      label={intl.formatMessage(messages.createButtonLabel)}
                      dialogTitle={intl.formatMessage(
                        messages.createExternalDialogTitle
                      )}
                    />
                  )}
                  <InviteUserButton
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    onCreated={handleOnCreated}
                    userRole={UserRoleType.External}
                    label={intl.formatMessage(messages.inviteButtonLabel)}
                    dialogTitle={intl.formatMessage(
                      messages.inviteExternalDialogTitle
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
            </FeatureController>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteUserDialog;
