import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./Spinner.style";

interface Props {
  children: React.ReactNode;
}

interface ContextType {
  isLoading: boolean;
  text?: string;
}

const initialState: ContextType = {
  isLoading: false,
};

const Context = React.createContext<
  [ContextType, Dispatch<SetStateAction<ContextType>>]
>([initialState, () => {}]);

export const useSpinner = () => {
  return useContext(Context);
};

const Spinner = (props: Props) => {
  const { children } = props;
  const [state, setState] = useState(initialState);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerTimeout, setSpinnerTimeout] = useState<NodeJS.Timeout>();
  const classes = useStyles();

  const clearSpinnerTimeout = (timeoutId?: NodeJS.Timeout): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setShowSpinner(false);
  };

  const createTimeout = (): NodeJS.Timeout => {
    clearSpinnerTimeout(spinnerTimeout);
    return setTimeout(() => {
      setShowSpinner(true);
    }, 1000);
  };

  useEffect(() => {
    if (state.isLoading) {
      setSpinnerTimeout(createTimeout);
    } else {
      clearSpinnerTimeout(spinnerTimeout);
    }

    // if page page refresh clear timout
    return () => clearSpinnerTimeout(spinnerTimeout);
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Context.Provider value={[state, setState]}>
      <Backdrop
        className={classes.backdrop}
        open={showSpinner && state.isLoading}
      >
        <Grid container className={classes.gridContainer} spacing={2}>
          <Grid item xs={12}>
            <CircularProgress size={50} color="secondary" />
          </Grid>
          {state.text && (
            <Grid item xs={12}>
              <Typography component="h1" variant="h6" color="secondary">
                {state.text}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Backdrop>
      {children}
    </Context.Provider>
  );
};

export default Spinner;
