/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChatMessageResponse,
    ChatMessageResponseFromJSON,
    ChatMessageResponseToJSON,
    CreateChatMessageRequest,
    CreateChatMessageRequestFromJSON,
    CreateChatMessageRequestToJSON,
    CreateChatMessageResponse,
    CreateChatMessageResponseFromJSON,
    CreateChatMessageResponseToJSON,
} from '../models';

export interface CreateChatMessageOperationRequest {
    customerId: string;
    meetingId: string;
    createChatMessageRequest: CreateChatMessageRequest;
}

export interface DeleteChatMessageRequest {
    customerId: string;
    meetingId: string;
    chatMessageId: string;
}

export interface GetChatMessageRequest {
    customerId: string;
    meetingId: string;
    chatMessageId: string;
}

export interface ListChatMessagesRequest {
    customerId: string;
    meetingId: string;
}

/**
 * 
 */
export class MeetingchatApi extends runtime.BaseAPI {

    /**
     * Requires user to ba a participant of specified meeting. Creates a chat message with the signed in userId as sender.
     * Create a new chat message
     */
    async createChatMessageRaw(requestParameters: CreateChatMessageOperationRequest): Promise<runtime.ApiResponse<CreateChatMessageResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createChatMessage.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling createChatMessage.');
        }

        if (requestParameters.createChatMessageRequest === null || requestParameters.createChatMessageRequest === undefined) {
            throw new runtime.RequiredError('createChatMessageRequest','Required parameter requestParameters.createChatMessageRequest was null or undefined when calling createChatMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meetingchat/{customerId}/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChatMessageRequestToJSON(requestParameters.createChatMessageRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateChatMessageResponseFromJSON(jsonValue));
    }

    /**
     * Requires user to ba a participant of specified meeting. Creates a chat message with the signed in userId as sender.
     * Create a new chat message
     */
    async createChatMessage(requestParameters: CreateChatMessageOperationRequest): Promise<CreateChatMessageResponse> {
        const response = await this.createChatMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Permanently delete a specific chat message. Only the creator of a chat message should be able to delete it.
     * Delete a chat message
     */
    async deleteChatMessageRaw(requestParameters: DeleteChatMessageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteChatMessage.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling deleteChatMessage.');
        }

        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId','Required parameter requestParameters.chatMessageId was null or undefined when calling deleteChatMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meetingchat/{customerId}/{meetingId}/message/{chatMessageId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))).replace(`{${"chatMessageId"}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Permanently delete a specific chat message. Only the creator of a chat message should be able to delete it.
     * Delete a chat message
     */
    async deleteChatMessage(requestParameters: DeleteChatMessageRequest): Promise<void> {
        await this.deleteChatMessageRaw(requestParameters);
    }

    /**
     * Get specific chat message by id.
     * Get a chat message
     */
    async getChatMessageRaw(requestParameters: GetChatMessageRequest): Promise<runtime.ApiResponse<ChatMessageResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getChatMessage.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling getChatMessage.');
        }

        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId','Required parameter requestParameters.chatMessageId was null or undefined when calling getChatMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meetingchat/{customerId}/{meetingId}/message/{chatMessageId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))).replace(`{${"chatMessageId"}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageResponseFromJSON(jsonValue));
    }

    /**
     * Get specific chat message by id.
     * Get a chat message
     */
    async getChatMessage(requestParameters: GetChatMessageRequest): Promise<ChatMessageResponse> {
        const response = await this.getChatMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a list of chat messages belonging to a meeting
     * List chat messages
     */
    async listChatMessagesRaw(requestParameters: ListChatMessagesRequest): Promise<runtime.ApiResponse<Array<ChatMessageResponse>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listChatMessages.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling listChatMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meetingchat/{customerId}/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatMessageResponseFromJSON));
    }

    /**
     * Get a list of chat messages belonging to a meeting
     * List chat messages
     */
    async listChatMessages(requestParameters: ListChatMessagesRequest): Promise<Array<ChatMessageResponse>> {
        const response = await this.listChatMessagesRaw(requestParameters);
        return await response.value();
    }

}
