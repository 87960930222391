import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { alpha } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    rootNewlyAdded: {
      width: "100%",
      backgroundColor: alpha(theme.palette.secondary.light, 0.2),
    },
    noResultText: {
      width: "100%",
      minHeight: 200,
      textAlign: "center",
      paddingTop: 70,
    },
  })
);
