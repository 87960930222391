import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(0),
      width: "100%",
      textAlign: "right",
    },
    customerNameContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    customerName: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
    div: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(1),
    },
    searchBarDiv: {
      backgroundColor: theme.palette.background.default,
      paddingBottom: theme.spacing(0.1),
    },
  })
);
