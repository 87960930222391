import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0,
    },
    leftPaper: {
      paddingTop: 20,
      paddingBottom: 20,
      padding: "auto",
      textAlign: "center",
    },
    middlePaper: {
      padding: 10,
    },
    focusAreas: {
      margin: 5,
    },
  })
);
