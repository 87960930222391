import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(1),
      width: "100%",
      textAlign: "right",
    },
  })
);
