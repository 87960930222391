import React, { useEffect, useRef, useState } from "react";
import { ImageListItem, ImageListItemBar } from "@mui/material";
import ImageIcon from "@material-ui/icons/Image";
import { useStyles } from "./BackgroundImageItem.style";
import { videoFilterService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { FileMetadata } from "../../../../generated/video-api";
import ButtonLoadingWrapper from "../../../../Components/Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";

interface Props {
  file: FileMetadata;
}

const BackgroundImageItem = (props: Props) => {
  const classes = useStyles();
  const customerId = useCustomerId();
  const { file } = props;
  const [isLoaded, setIsLoaded] = useState<boolean>();
  const imgRef = useRef<HTMLImageElement>(null);

  const loadImage = () => {
    if (customerId) {
      setIsLoaded(false);
      videoFilterService()
        .downloadFilterRaw({
          customerId,
          fileId: file.fileId,
        })
        .then((res) => Promise.resolve(res.raw.blob()))
        .then((blob) => {
          setIsLoaded(true);
          if (imgRef !== null) {
            // @ts-ignore
            imgRef.current.src = URL.createObjectURL(blob);
          }
        });
    }
  };

  useEffect(() => {
    loadImage();
  }, [file.fileId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ImageListItem component="div" className={classes.item}>
      {isLoaded ? (
        <img
          className={classes.img}
          ref={imgRef}
          width={248}
          alt={file.fileName}
          loading="lazy"
        />
      ) : (
        <ButtonLoadingWrapper isLoading>
          <ImageIcon className={classes.imgPlaceholder} />
        </ButtonLoadingWrapper>
      )}
      <ImageListItemBar
        sx={{
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.4) 80%, rgba(0,0,0,0) 100%)",
        }}
        className={classes.imgBar}
        title={file.fileName}
        position="top"
      />
    </ImageListItem>
  );
};

export default BackgroundImageItem;
