import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "deletemeetingtemplatedialog.title",
    defaultMessage: "Delete template",
    description: "Title delete template dialog",
  },
  content: {
    id: "deletemeetingtemplatedialog.description",
    defaultMessage: "Are you sure you want to delete this booking template?",
    description:
      "Description in the delete booking template dialog to verify action",
  },
  cancelButtonLabel: {
    id: "deletemeetingtemplatedialog.button.cancel.label",
    defaultMessage: "Go back",
    description: "label for closing dialog",
  },
  submitButtonLabel: {
    id: "deletemeetingtemplatedialog.button.submit.label",
    defaultMessage: "Delete template",
    description: "label for verify delete of booking template",
  },
  success: {
    id: "deletemeetingtemplatedialog.success",
    defaultMessage: "Booking template was deleted",
    description: "Snackbar displayed when meeting is succesfully deleted",
  },
  generalError: {
    id: "deletemeetingtemplatedialog.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occured for deleting booking template",
  },
});
