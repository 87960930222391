import React from "react";
import { Paper } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./IncomingBookingsCard.style";
import { messages } from "./IncomingBookingsCard.messages";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { PaginatedMeetingResponseList } from "../../../../generated/meeting-api";
import BookingsListItemSimple from "../../../../Components/BookingsList/Components/BookingsListItemSimple/BookingsListItemSimple";
import { Variant } from "../../../../Components/BookingsList/Components/Variant/Variant";

interface Props {
  onReload?: () => void;
  bookings?: PaginatedMeetingResponseList;
}

const IncomingBookingsCard = (props: Props) => {
  const { onReload, bookings } = props;
  const intl = useIntl();
  const classes = useStyles();

  if (!bookings?.data || bookings?.data.length === 0) {
    return null;
  }

  const mapIncomingBookings = () => {
    return bookings?.data?.map((meeting) => (
      <BookingsListItemSimple
        meeting={meeting}
        variant={Variant.DASHBOARD_INCOMING_BOOKING_REQUESTS}
        reloadBookings={onReload}
      />
    ));
  };

  return (
    <Paper className={classes.root}>
      <CardHeading>
        {intl.formatMessage(messages.title).toUpperCase()}
      </CardHeading>
      {mapIncomingBookings()}
    </Paper>
  );
};

export default IncomingBookingsCard;
