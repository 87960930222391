import { defineMessages } from "react-intl";

export const messages = defineMessages({
  participantsLabel: {
    id: "shareFileFields.participants.label",
    defaultMessage: "Participants",
    description: "Label for field participants",
  },
  externalParticipantsLabel: {
    id: "shareFileFields.externalParticipants.label",
    defaultMessage: "Externals",
    description: "Label for field externals",
  },
  staffParticipantsLabel: {
    id: "shareFileFields.staffParticipants.label",
    defaultMessage: "Caregiver",
    description: "Label for field staff participants",
  },
});
