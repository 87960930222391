import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customerSettingsTab: {
    id: "settings.featureTab",
    defaultMessage: "Features Settings",
    description: "label for customer features settings",
  },
  functionsSettingsCategory: {
    id: "settings.featureTab.functionsCategory",
    defaultMessage: "Functions",
    description: "label for customer functions settings",
  },
  notificationSettingsCategory: {
    id: "settings.featureTab.notificationCategory",
    defaultMessage: "Notifications",
    description: "label for customer notification settings",
  },
  meetingRoomSettingsCategory: {
    id: "settings.featureTab.meetingRoomCategory",
    defaultMessage: "In meeting room",
    description: "label for customer meeting room settings",
  },
  userManagementSettingsCategory: {
    id: "settings.featureTab.userManagementCategory",
    defaultMessage: "User management",
    description: "label for customer user management settings",
  },
  loginSettingsCategory: {
    id: "settings.featureTab.loginCategory",
    defaultMessage: "Login",
    description: "label for customer login settings",
  },
  miscellaneousSettingsCategory: {
    id: "settings.featureTab.miscellaneousCategory",
    defaultMessage: "Miscellaneous",
    description: "label for customer miscellaneous settings",
  },
  getReadData: {
    id: "save.settings.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occurred for saving features",
  },
});
