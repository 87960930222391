import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      display: "flex",
      "&:hover": {
        backgroundColor: theme.palette.grey.A100,
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);
