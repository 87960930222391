import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pinContainer: {
      position: "relative",
      fontFamily: "monospace",
      fontSize: "2.5rem",
      height: "2ch",
      display: "flex",
      alignItems: "center",
      width: "11.5ch",
      cursor: "text",
    },
    pinInput: {
      fontFamily: "inherit",
      fontSize: "inherit",
      border: "none",
      outline: "none",
      padding: 0,
      letterSpacing: "1ch",
      color: "gray",
      paddingLeft: ".25ch",
      height: "1.5ch",
      marginBottom: ".05ch",
      background: "transparent",
      width: "13ch",
      caretColor: "transparent",
    },

    cell: {
      position: "absolute",
      top: 0,
      bottom: 0,
      outline: "2px solid lightgray",
      width: "1.5ch",
      zIndex: 1,
      borderRadius: 5,
    },
    activeCell: {
      position: "absolute",
      top: 0,
      bottom: 0,
      borderColor: theme.palette.primary.main,
      outline: "2px solid",
      width: "1.5ch",
      zIndex: 1,
      borderRadius: 5,
    },

    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
