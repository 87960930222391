import React from "react";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./MeetingTemplateList.style";
import { messages } from "./MeetingTemplateList.messages";
import { MeetingTemplateResponse } from "../../generated/meetingtemplate-api";
import MeetingTemplatesItem from "./Components/MeetingTemplateListItem/MeetingTemplateListItem";

interface Props {
  MeetingTemplates?: MeetingTemplateResponse[];
  reloadTemplates?: () => void;
}

const MeetingTemplateList = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { MeetingTemplates, reloadTemplates } = props;

  if (!MeetingTemplates || MeetingTemplates.length === 0) {
    return (
      <Typography
        className={classes.noResultText}
        variant="h4"
        color="textSecondary"
      >
        {intl.formatMessage(messages.noResultsText)}
      </Typography>
    );
  }

  return (
    <List className={classes.root}>
      {MeetingTemplates.map(
        (template) =>
          template && (
            <MeetingTemplatesItem
              key={template.id}
              meetingTemplate={template}
              reloadTemplates={reloadTemplates}
            />
          )
      )}
    </List>
  );
};

export default MeetingTemplateList;
