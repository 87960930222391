import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitleCreate: {
    id: "dialog.createOrUpdateCustomer.create.title",
    defaultMessage: "Create customer",
    description: "Title for create dialog",
  },
  dialogTitleUpdate: {
    id: "dialog.createOrUpdateCustomer.update.title",
    defaultMessage: "Update customer",
    description: "Title for update dialog",
  },
  cancelButtonLabel: {
    id: "dialog.createOrUpdateCustomer.button.cancel",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  submitButtonCreateLabel: {
    id: "dialog.createOrUpdateCustomer.button.submit.create",
    defaultMessage: "Create",
    description: "label for submit button when creating",
  },
  submitButtonUpdateLabel: {
    id: "dialog.createOrUpdateCustomer.button.submit.update",
    defaultMessage: "Update",
    description: "label for submit button when updating",
  },
  successCreate: {
    id: "dialog.createOrUpdateCustomer.successCreate",
    defaultMessage: "Customer was created",
    description: "Success message when customer has been created",
  },
  successUpdate: {
    id: "dialog.createOrUpdateCustomer.successUpdate",
    defaultMessage: "Customer was Updated",
    description: "Success message when customer has been created",
  },
  generalError: {
    id: "dialog.createOrUpdateCustomer.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to create a customer",
  },
});
