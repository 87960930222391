import React from "react";
import Box from "@material-ui/core/Box";

interface Props {
  component: React.ReactNode;
  index: number | string;
  value: number | string;
  className?: string;
}

const TabPanel = (props: Props) => {
  const { component, value, index, className, ...other } = props;

  return (
    <div
      className={className}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{component}</Box>}
    </div>
  );
};

export default TabPanel;
