import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "footer.contactDetails.title",
    defaultMessage: "Contact details",
    description: "title for contact details",
  },
  techSupportTitle: {
    id: "footer.contactDetails.title.techSupport",
    defaultMessage: "Tech support",
    description: "title for tech support",
  },
  phoneLabel: {
    id: "footer.contactDetails.phone.label",
    defaultMessage: "Phone: {phone}",
    description: "phone label",
  },
  emailLabel: {
    id: "footer.contactDetails.email.label",
    defaultMessage: "Email: {email}",
    description: "email label",
  },
});
