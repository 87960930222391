/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User Consent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of customer user agreement
 * @export
 * @enum {string}
 */
export enum CustomerUserAgreementType {
    PrivacyPolicy = 'PRIVACY_POLICY',
    ConsentAgreement = 'CONSENT_AGREEMENT'
}

export function CustomerUserAgreementTypeFromJSON(json: any): CustomerUserAgreementType {
    return CustomerUserAgreementTypeFromJSONTyped(json, false);
}

export function CustomerUserAgreementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerUserAgreementType {
    return json as CustomerUserAgreementType;
}

export function CustomerUserAgreementTypeToJSON(value?: CustomerUserAgreementType | null): any {
    return value as any;
}

