import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { useIntl } from "react-intl";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import { MeetingResponse } from "../../../generated/meeting-api";
import { meetingService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import OngoingMeetingCard from "../../../Views/Dashboard/Components/OngoingMeetingCard/OngoingMeetingCard";
import { messages } from "./ConnectToMeetingDialog.messages";

interface Props {
  open: boolean;
  meetingId: string;
  showCloseCross?: boolean;
  onClose?: () => void;
}

const ConnectToMeetingDialog = (props: Props) => {
  const intl = useIntl();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [meeting, setMeeting] = useState<MeetingResponse>();
  const { open, showCloseCross, onClose, meetingId } = props;

  const fetchMeetingSessionDetails = (): void => {
    if (customerId && authenticatedUser.user && meetingId) {
      meetingService()
        .getMeetingSessionDecorated(authenticatedUser.user, {
          customerId,
          meetingId,
          userId: authenticatedUser.user.id,
        })
        .then((res) => setMeeting(res.meeting));
    }
  };

  useEffect(() => {
    fetchMeetingSessionDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!meeting) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      {showCloseCross && onClose && <DialogCloseButton onClose={onClose} />}
      <DialogTitle>{intl.formatMessage(messages.dialogTitle)}</DialogTitle>
      <OngoingMeetingCard bookings={[meeting]} />
      <DialogActions>
        {onClose && (
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConnectToMeetingDialog;
