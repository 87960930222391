import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  useCustomerId,
  useCustomerIsAdminPortal,
} from "../../Providers/CustomerProvider/CustomerProvider";
import {
  AuthenticatedUser,
  AuthType,
} from "../../generated/authentication-api";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { messages } from "./Authentication.messages";
import { RoutePath } from "../../Components/Routes/RoutePath";
import {
  authenticationService,
  userService,
} from "../../Providers/ServiceProvider/ServiceProvider";
import UserSelection from "./Components/UserSelection/UserSelection";
import { UserResponse } from "../../generated/user-api";

const Authentication = () => {
  const [grandidsession] = useQueryParam("grandidsession", StringParam);
  const [authType] = useQueryParam("type", StringParam);
  const history = useHistory();
  const customerId = useCustomerId();
  const isAdminPortal = useCustomerIsAdminPortal();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<AuthenticatedUser[] | undefined>();
  const [authenticatedUser, setAuthenticatedUser] = useAuthenticatedUser();

  const isUserComplete = (user?: UserResponse): boolean => {
    return !!(user?.firstName && user?.lastName);
  };

  const makeRequestForAuthenticatedUsers = (): void => {
    if (customerId && grandidsession && authType) {
      authenticationService()
        .checkIdpAuthenticationSession({
          customerId,
          sessionId: grandidsession,
          authType: authType as AuthType,
        })
        .then(({ authenticatedUsers }) => {
          setUsers(authenticatedUsers);
        })
        .catch(() => {
          history.push(RoutePath.LOGIN);
          enqueueSnackbar(
            authType === AuthType.Siths
              ? intl.formatMessage(messages.userDoesNotExistSithsError)
              : intl.formatMessage(messages.userDoesNotExistError),
            {
              variant: "error",
            }
          );
        });
    } else {
      history.push(RoutePath.LOGIN);
    }
  };

  const initializeAuthenticatedUser = (user: AuthenticatedUser) => {
    if (user.customerId) {
      userService(user.token)
        .getUserById({ customerId: user.customerId, userId: user.userId })
        .then((userRs) => {
          setAuthenticatedUser({
            ...user,
            token: user.token,
            isComplete: isUserComplete(userRs),
            user: userRs,
          });
        });
    }
  };

  useEffect(() => {
    // If users have not been set, make api call
    if (!users) {
      makeRequestForAuthenticatedUsers();
    }
  }, [grandidsession, users]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenticatedUser.isLoggedIn && authenticatedUser.isComplete) {
      if (isAdminPortal) {
        history.push(RoutePath.PORTAL_CUSTOMERS);
      } else {
        history.push(RoutePath.DASHBOARD);
      }
    } else if (authenticatedUser.isLoggedIn && !authenticatedUser.isComplete) {
      history.push(RoutePath.ACCOUNT_COMPLETION);
    }
  }, [authenticatedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!users) {
    return null;
  }

  return (
    <UserSelection users={users} onUserSelect={initializeAuthenticatedUser} />
  );
};

export default Authentication;
