/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Chat API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateChatMessageResponse
 */
export interface CreateChatMessageResponse {
    /**
     * The UUID of the newly created chat message.
     * @type {string}
     * @memberof CreateChatMessageResponse
     */
    id: string;
}

export function CreateChatMessageResponseFromJSON(json: any): CreateChatMessageResponse {
    return CreateChatMessageResponseFromJSONTyped(json, false);
}

export function CreateChatMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateChatMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function CreateChatMessageResponseToJSON(value?: CreateChatMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}


