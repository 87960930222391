import React from "react";
import {
  CustomerFeature,
  CustomerFeatureType,
} from "../../generated/customersettings-api";
import { useCustomerFeatures } from "../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  children: React.ReactNode;
  requiredFeature?: CustomerFeatureType;
  requiredFeatures?: CustomerFeatureType[];
  requiredAnyOfFeatures?: CustomerFeatureType[];
}

export const hasFeatureEnabled = (
  requiredFeature: CustomerFeatureType,
  customerFeatures: CustomerFeature[]
): boolean => {
  const customerFeatureTypes = customerFeatures.map(
    (customerFeature) => customerFeature.feature
  );
  return customerFeatureTypes.includes(requiredFeature);
};

export const hasFeaturesEnabled = (
  requiredFeatures: CustomerFeatureType[],
  customerFeatures: CustomerFeature[]
): boolean => {
  return requiredFeatures.every((requiredFeature) =>
    hasFeatureEnabled(requiredFeature, customerFeatures)
  );
};

export const hasAnyOfFeaturesEnabled = (
  requiredFeatures: CustomerFeatureType[],
  customerFeatures: CustomerFeature[]
): boolean => {
  return (
    requiredFeatures.filter((requiredFeature) =>
      hasFeatureEnabled(requiredFeature, customerFeatures)
    ).length > 0
  );
};

export const checkConditions = (
  customerFeatures: CustomerFeature[],
  requiredFeature?: CustomerFeatureType,
  requiredFeatures?: CustomerFeatureType[],
  requiredAnyOfFeatures?: CustomerFeatureType[]
): boolean => {
  let isRequiredFeatureConditionMet = false;
  let isRequiredFeaturesConditionMet = false;
  let isRequiredAnyOfFeaturesConditionMet = false;

  if (
    !requiredFeature ||
    hasFeatureEnabled(requiredFeature, customerFeatures)
  ) {
    isRequiredFeatureConditionMet = true;
  }

  if (
    !requiredFeatures ||
    requiredFeatures.length === 0 ||
    hasFeaturesEnabled(requiredFeatures, customerFeatures)
  ) {
    isRequiredFeaturesConditionMet = true;
  }

  if (
    !requiredAnyOfFeatures ||
    requiredAnyOfFeatures.length === 0 ||
    hasAnyOfFeaturesEnabled(requiredAnyOfFeatures, customerFeatures)
  ) {
    isRequiredAnyOfFeaturesConditionMet = true;
  }

  return (
    isRequiredFeatureConditionMet &&
    isRequiredFeaturesConditionMet &&
    isRequiredAnyOfFeaturesConditionMet
  );
};

const FeatureController = (props: Props) => {
  const customerFeatures = useCustomerFeatures();
  const {
    requiredFeature,
    requiredFeatures,
    requiredAnyOfFeatures,
    children,
  } = props;

  if (!requiredFeature && !requiredFeatures && !requiredAnyOfFeatures) {
    return <>{children}</>;
  }

  if (
    checkConditions(
      customerFeatures,
      requiredFeature,
      requiredFeatures,
      requiredAnyOfFeatures
    )
  ) {
    return <>{children}</>;
  }
  return null;
};

export default FeatureController;
