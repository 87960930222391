import React from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import {
  CustomerFeature,
  CustomerFeatureType,
} from "../../../generated/customersettings-api";

export interface Props {
  label: string;
  emailLabel: string;
  smsLabel: string;
  emailFeatureType: CustomerFeatureType;
  smsFeatureType: CustomerFeatureType;
  enabledCustomerFeatures: CustomerFeature[];
  onSwitched: (featureType: CustomerFeatureType, enabled: boolean) => void;
}

const SwitchButtonNotification = (props: Props) => {
  const {
    label,
    smsLabel,
    emailLabel,
    emailFeatureType,
    smsFeatureType,
    enabledCustomerFeatures,
    onSwitched,
  } = props;

  return (
    <Grid container alignItems="center">
      <Grid item sm={8} xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item sm={2} xs={6}>
        <FormControlLabel
          label={emailLabel}
          labelPlacement="start"
          control={
            <Switch
              defaultChecked
              checked={
                !!enabledCustomerFeatures.find(
                  (customerFeature) =>
                    customerFeature.feature === emailFeatureType
                )
              }
              onChange={(event, enabled) =>
                onSwitched(emailFeatureType, enabled)
              }
            />
          }
        />
      </Grid>
      <Grid item sm={2} xs={6}>
        <FormControlLabel
          label={smsLabel}
          labelPlacement="start"
          control={
            <Switch
              defaultChecked
              checked={
                !!enabledCustomerFeatures.find(
                  (customerFeature) =>
                    customerFeature.feature === smsFeatureType
                )
              }
              onChange={(event, enabled) => onSwitched(smsFeatureType, enabled)}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default SwitchButtonNotification;
