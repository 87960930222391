import React from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { UserAuthorityType } from "../../generated/user-api";
import { hasPermission } from "../../Components/PermissionController/PermissionController";
import { messages } from "./AdminSettings.messages";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { RoutePath } from "../../Components/Routes/RoutePath";
import Tabs, { TabConfig } from "../../Components/Tabs/Tabs";
import Video from "./Component/Video/Video";

const AdminSettings = () => {
  const intl = useIntl();
  const [authenticatedUser] = useAuthenticatedUser();

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.AdminSite],
  });

  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.videoTabTitle),
      component: Video(),
    },
  ];

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  return (
    <>
      <Tabs defaultTab={0} showTabs tabConfigs={tabConfig} />
    </>
  );
};

export default AdminSettings;
