import { UserResponse } from "../generated/user-api";
import {
  MessageResponse,
  MessageThreadResponse,
  MessageUserResponse,
} from "../generated/message-api";

export const getMessageCounterPart = (
  message?: MessageResponse,
  currentUser?: UserResponse
): MessageUserResponse | undefined => {
  if (!message || !currentUser) {
    return undefined;
  }
  return message.sender.id === currentUser.id
    ? message.recipient
    : message.sender;
};

export const getThreadCounterPart = (
  thread?: MessageThreadResponse,
  currentUserId?: string
): MessageUserResponse | undefined => {
  if (!thread || !currentUserId) {
    return undefined;
  }
  return thread.sender.id === currentUserId ? thread.recipient : thread.sender;
};
