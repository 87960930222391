import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./BookingsCard.style";
import { messages } from "./BookingsCard.messages";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { PaginatedMeetingResponseList } from "../../../../generated/meeting-api";
import BookingsListItemSimple from "../../../../Components/BookingsList/Components/BookingsListItemSimple/BookingsListItemSimple";
import { Variant } from "../../../../Components/BookingsList/Components/Variant/Variant";

interface Props {
  bookings?: PaginatedMeetingResponseList;
  onReload?: () => void;
}

const BookingsCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { bookings, onReload } = props;

  const mapUpcomingBookings = () => {
    if (!bookings?.data || bookings?.data.length === 0) {
      return (
        <Typography
          className={classes.nothingToShowText}
          component="h1"
          variant="body2"
        >
          {intl.formatMessage(messages.nothingToShow)}
        </Typography>
      );
    }

    return bookings?.data?.map((meeting) => (
      <BookingsListItemSimple
        meeting={meeting}
        variant={Variant.DASHBOARD_UPCOMING_BOOKINGS}
        reloadBookings={onReload}
      />
    ));
  };

  return (
    <Paper className={classes.root}>
      <CardHeading>
        {intl.formatMessage(messages.title).toUpperCase()}
      </CardHeading>
      {mapUpcomingBookings()}
    </Paper>
  );
};

export default BookingsCard;
