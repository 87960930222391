import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  fabContainer: {
    display: "flex",
    margin: theme.spacing(0.5),
  },
  container: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  fab: {
    display: "flex",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 1,
    alignSelf: "flex-start",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  arrowButton: {
    borderRadius: "5em",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[400],
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
    },
  },
  selected: {
    color: theme.palette.primary.dark,
    backgroundColor: "white",
  },
}));
