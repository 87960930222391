import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 80,
      marginTop: theme.spacing(1),
    },
    list: {
      padding: 0,
    },
  })
);
