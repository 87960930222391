import { Chip, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { getFormattedDate } from "../../../../Utils/Meeting";
import { UserAgreementResponse } from "../../../../generated/customercontent-api";
import { useStyles } from "./CurrentConsentAgreement.styles";
import { messages } from "./CurrentConsentAgreement.messages";

export interface Props {
  current: UserAgreementResponse;
}

export default function CurrentConsentAgreement(props: Props) {
  const { current } = props;
  const classes = useStyles();
  const intl = useIntl();

  const getValidFromLabel = (validFrom: Date) => {
    return `${intl.formatMessage(
      messages.privacyPolicyValidFromLabel
    )}: ${getFormattedDate(validFrom)}`;
  };

  if (!current) {
    return null;
  }

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} md={4}>
        <Chip
          size="small"
          className={classes.currentAgreementChip}
          label={getValidFromLabel(current.validFrom)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography component="h6" className={classes.typography}>
          {current.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReactQuill
          theme="bubble"
          readOnly
          modules={{ toolbar: false }}
          value={current.contents}
        />
      </Grid>
    </Grid>
  );
}
