import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { alpha } from "@mui/material";

export const useStyles = (isPortalAdmin: boolean) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      customerListItemLabel: {
        marginBottom: theme.spacing(0),
        color: isPortalAdmin
          ? theme.palette.grey.A400
          : theme.palette.grey.A200,
        textAlign: "center",
      },
      customerListItemValue: {
        marginTop: theme.spacing(0),
        color: theme.palette.text.primary,
        textAlign: "center",
      },
      customerListItemName: {
        marginBottom: theme.spacing(0),
      },
      customerListItemDomain: {
        marginTop: theme.spacing(0),
        color: theme.palette.success.dark,
      },
      customerListGridContainer: {
        display: "flex",
      },
      listItem: {
        cursor: "pointer",
        backgroundColor: isPortalAdmin
          ? alpha(theme.palette.secondary.light, 0.2)
          : undefined,
        "&:hover": {
          backgroundColor: isPortalAdmin
            ? alpha(theme.palette.secondary.dark, 0.2)
            : undefined,
        },
      },
      statusGrid: {
        alignContent: "center",
        display: "grid",
        justifyContent: "center",
      },
    });
  });
