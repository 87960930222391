import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { FormikProps, useFormik } from "formik";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  UpdateUserRequest,
  UserResponse,
  UserRoleType,
} from "../../../../../generated/user-api";
import UserFields from "../../../Components/UserFields/UserFields";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { useCustomerCurrentUpdate } from "../../../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { messages } from "../../ModifyUser.messages";
import AuthorityFields from "../../../Components/AuthorityFields/AuthorityFields";
import { displayUser } from "../../../../../Utils/User";
import { userService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import UpdateUserValidationSchema from "../../../Components/Validation/UpdateUserValidationSchema";
import { User } from "../../../../../Models/User";
import {
  emptyStringToUndefined,
  formatHSAID,
} from "../../../../../Utils/Format";

const mapUserObject = (user: UserResponse) => {
  const userObject: UpdateUserRequest = {
    firstName: emptyStringToUndefined(user.firstName),
    lastName: emptyStringToUndefined(user.lastName),
    workTitle: emptyStringToUndefined(user.workTitle),
    email: emptyStringToUndefined(user.email),
    mobilePhoneNumber: emptyStringToUndefined(user.mobilePhoneNumber),
    additionalInformation: emptyStringToUndefined(user.additionalInformation),
    userAuthorities: user.userAuthorities,
  };

  return userObject;
};

interface Props {
  onClose: () => void;
  onUpdated?: (user: UserResponse) => void;
  user: UserResponse;
  submitLabel?: string;
}

const General = (props: Props) => {
  const { user, onClose, onUpdated, submitLabel } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useCustomerId();
  const [customerupdates, setCustomerupdates] = useCustomerCurrentUpdate();
  const [personNumber, setPersonNumber] = useState<string>();
  const [hsaId, setHsaId] = useState<string>();
  const [duplicateHsaIdError, setDuplicateHsaIdError] = useState(false);

  const loadPersonNumber = () => {
    if (user && customerId) {
      userService()
        .getPersonNumber({ userId: user.id })
        .then((res) => setPersonNumber(res.personNumber));
    }
  };

  const loadUserHsaId = () => {
    if (user) {
      userService()
        .getHsaId({ userId: user.id })
        .then((res) => setHsaId(res.hsaId ? res.hsaId : ""));
    }
  };

  const initialValues: User = {
    ...user,
    hsaId,
    personNumber,
  };

  const updateUser = (formik: FormikProps<User>, values: User) => {
    if (customerId && user) {
      userService()
        .updateUser({
          customerId,
          userId: user.id,
          body: mapUserObject(values),
        })
        .then((res) => {
          if (onUpdated) {
            onUpdated(res);
          }
          const arrayCopy: UserResponse[] = customerupdates.userResponse.filter(
            (element: UserResponse) => {
              return element.id !== user.id;
            }
          );
          arrayCopy.push(res);
          setCustomerupdates({ userResponse: arrayCopy });
          enqueueSnackbar(
            intl.formatMessage(messages.success, {
              name: displayUser(res, true),
            }),
            { variant: "success" }
          );
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        })
        .finally(onClose);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UpdateUserValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (customerId && user) {
        if (values.hsaId !== undefined && hsaId !== values.hsaId) {
          userService()
            .updateHsaId({
              userId: user.id,
              updateUserHsaIdRequest: {
                hsaId: formatHSAID(emptyStringToUndefined(values.hsaId)),
              },
            })
            .then((result) => setHsaId(result.hsaId || ""))
            .then(() => updateUser(formik, values))
            .catch((error) => {
              if (error.status === 409) {
                setDuplicateHsaIdError(true);
              }
            });
        } else {
          updateUser(formik, values);
        }
      }
    },
  });

  useEffect(() => {
    if (!formik.errors.hsaId) {
      setDuplicateHsaIdError(false);
    }
  }, [formik.errors.hsaId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <UserFields
          formik={formik}
          useHsaIdLock
          loadHsaId={loadUserHsaId}
          duplicateHsaIdError={duplicateHsaIdError}
          usePersonNumberLock
          loadPersonNumber={loadPersonNumber}
          userRole={user.userRole}
          disabledFields={["personNumber"]}
        />
        <AuthorityFields formik={formik} userRole={user.userRole} user={user} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={
            formik.values.userAuthorities?.length === 0 &&
            user.userRole !== UserRoleType.PlatformAdmin
          }
        >
          {submitLabel || intl.formatMessage(messages.submitButtonLabel)}
        </Button>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(messages.cancelButtonLabel)}
        </Button>
      </DialogActions>
    </form>
  );
};

export default General;
