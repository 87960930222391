import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useSnackbar } from "notistack";
import {
  MeetingParticipantType,
  MeetingResponse,
  SourceType,
} from "../../../generated/meeting-api";
import { messages } from "./ModifyMeetingDialog.messages";
import { UserRoleType } from "../../../generated/user-api";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useCustomerCurrentBooking } from "../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import {
  createParticipantList,
  getParticipantsByType,
} from "../../../Utils/Meeting";
import { UserAuthorityType } from "../../../generated/authentication-api";
import MeetingValidationSchema from "../../Fields/MeetingFields/Components/Validation/MeetingValidationSchema";
import MeetingFields, {
  MeetingFormikValues,
} from "../../Fields/MeetingFields/MeetingFields";
import {
  PaginatedContactResponseList,
  SortByType,
  SortOrderType,
} from "../../../generated/contact-api";
import { hasPermission } from "../../PermissionController/PermissionController";
import {
  contactToUser,
  meetingParticipantListToUserList,
} from "../../../Utils/ModelConverter";
import {
  contactService,
  meetingService,
  userService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import { Meeting } from "../../../Models/Meeting";
import { User } from "../../../Models/User";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  meeting: Meeting;
  onUpdated?: () => void;
}

const ModifyMeetingDialog = (props: Props) => {
  const { isOpen, onClose, meeting, onUpdated } = props;
  const intl = useIntl();
  const theme = useTheme();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [clientContactList, setClientContactList] = useState<User[]>();
  const [staffFixedList, setStaffFixedList] = useState<User[]>();
  const [clientFixedList, setClientFixedList] = useState<User[]>();
  const [externalFixedList, setExternalFixedList] = useState<User[]>();
  const [externalContactList, setExternalContactList] = useState<User[]>();
  const [staffList, setStaffList] = useState<User[]>();
  const [customerupdates, setCustomerupdates] = useCustomerCurrentBooking();

  const filterByRole = (userRole: UserRoleType, users?: User[]): User[] => {
    return users?.filter((user) => user.userRole === userRole) || [];
  };

  const initialValues = {
    title: meeting.title || "",
    startDateTime: meeting.startDateTime || new Date(),
    duration: meeting.duration || 30,
    participants: [],
    externalParticipants: [],
    staffParticipants: [],
  } as MeetingFormikValues;

  const filterContacts = (
    contactResponseList: PaginatedContactResponseList,
    filter: UserRoleType[]
  ): User[] => {
    if (
      !customerId &&
      authenticatedUser?.user &&
      !hasPermission({
        currentUser: authenticatedUser.user,
        requiredAuthoritiesStaff: [UserAuthorityType.BookMeeting],
      })
    ) {
      // If user does not have auth bookMeeting, meaning it is booking for someone else, dont show contacts
      return [];
    }
    const users: User[] = [];
    contactResponseList.data?.forEach((contact) => {
      if (filter.includes(contact.userRole)) {
        users.push(contactToUser(contact));
      }
    });
    return users;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: MeetingValidationSchema,
    onSubmit: (values) => {
      if (customerId && authenticatedUser?.user) {
        meetingService()
          .updateMeeting({
            meetingId: meeting.id,
            customerId,
            meetingRequest: {
              title: values.title,
              duration: values.duration,
              startDateTime: values.startDateTime,
              participants: createParticipantList([
                ...values.participants,
                ...values.staffParticipants,
                ...values.externalParticipants,
              ]),
            },
          })
          .then((res) => {
            enqueueSnackbar(intl.formatMessage(messages.success), {
              variant: "success",
            });
            const arrayCopy: MeetingResponse[] = customerupdates.MeetingResponse.filter(
              (element: MeetingResponse) => {
                return element.id !== meeting.id;
              }
            );
            arrayCopy.push(res);
            setCustomerupdates({ MeetingResponse: arrayCopy });
          })
          .then(onUpdated)
          .catch(() =>
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            })
          )
          .finally(onClose);
      }
    },
  });

  const loadContactList = (): void => {
    const clientParticipants = getParticipantsByType(
      MeetingParticipantType.Client,
      meeting.participants
    );
    const externalParticipants = getParticipantsByType(
      MeetingParticipantType.External,
      meeting.participants
    );

    setClientFixedList(
      meetingParticipantListToUserList(
        clientParticipants?.filter((p) => p.isExternallyAdded)
      )
    );

    setExternalFixedList(
      meetingParticipantListToUserList(
        externalParticipants?.filter((p) => p.isExternallyAdded)
      )
    );

    formik.setFieldValue(
      "participants",
      meetingParticipantListToUserList(clientParticipants)
    );
    formik.setFieldValue(
      "externalParticipants",
      meetingParticipantListToUserList(externalParticipants)
    );

    if (customerId && authenticatedUser.user?.id) {
      contactService()
        .listContactsForUserDecorated(authenticatedUser.user, {
          customerId,
          userId: authenticatedUser.user.id,
          sortOrder: SortOrderType.Ascending,
          sortBy: SortByType.Popularity,
          pageSize: 1000, // maximum contacts loaded
        })
        .then((res) =>
          filterContacts(res, [UserRoleType.Client, UserRoleType.External])
        )
        .then((users) => {
          setClientContactList(filterByRole(UserRoleType.Client, users));
          setExternalContactList(filterByRole(UserRoleType.External, users));
        });
    }
  };

  const loadStaff = (): void => {
    if (customerId) {
      if (customerId) {
        userService()
          .listUsersByCustomerAndSearchParams({
            pageSize: 1000,
            page: 1,
            customerId,
            userRole: UserRoleType.Staff,
            userAuthorities: [UserAuthorityType.AttendMeeting],
          })
          .then((res) => {
            // sets pre filled value
            const staffParticipants = getParticipantsByType(
              MeetingParticipantType.Staff,
              meeting.participants
            );

            setStaffFixedList(
              meetingParticipantListToUserList(
                staffParticipants?.filter((p) => p.isExternallyAdded)
              )
            );

            formik.setFieldValue(
              "staffParticipants",
              meetingParticipantListToUserList(staffParticipants)
            );

            // sets optionList
            setStaffList(res.data);
          });
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      loadContactList();
      loadStaff();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle)}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MeetingFields
            formik={formik}
            staffFixedList={staffFixedList}
            clientFixedList={clientFixedList}
            externalFixedList={externalFixedList}
            staffOptionList={staffList}
            clientOptionList={clientContactList}
            externalOptionList={externalContactList}
            meetingToBeUpdated={meeting.id}
            disabledFields={
              meeting.source !== SourceType.Careplatform
                ? ["title", "startDateTime", "duration"]
                : undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary">
            {intl.formatMessage(messages.submitButtonLabel)}
          </Button>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModifyMeetingDialog;
