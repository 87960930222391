import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    participantContainer: {
      display: "flex",
    },
    divider: {
      marginRight: theme.spacing(2),
    },
    fadeContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      minHeight: 160,
      [theme.breakpoints.down("md")]: {
        minHeight: 310,
      },
      [theme.breakpoints.up("md")]: {
        minHeight: 160,
      },
    },
  })
);
