/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Customer unit type
 * @export
 * @enum {string}
 */
export enum SourceType {
    Webdoc = 'WEBDOC',
    Takecare = 'TAKECARE',
    Cgmj4 = 'CGMJ4'
}

export function SourceTypeFromJSON(json: any): SourceType {
    return SourceTypeFromJSONTyped(json, false);
}

export function SourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceType {
    return json as SourceType;
}

export function SourceTypeToJSON(value?: SourceType | null): any {
    return value as any;
}

