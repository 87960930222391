import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { messages } from "./GoToDashboardButton.messages";
import { RoutePath } from "../../Routes/RoutePath";
import { Key, setSessionStorageItem } from "../../../Utils/SessionStorage";

interface Props {
  className?: string;
  userId?: string | null;
}

const GoToDashboardButton = (props: Props) => {
  const { className, userId } = props;
  const intl = useIntl();
  const history = useHistory();

  const redirectToDashboard = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (userId !== undefined && userId !== null) {
      setSessionStorageItem(Key.NONE_NOTIFIABLE_USER, userId).then(() => {
        history.push({
          pathname: RoutePath.DASHBOARD,
          search: `userId=${userId}`,
        });
      });
    } else {
      history.push({ pathname: RoutePath.DASHBOARD });
    }
  };

  return (
    <Button
      size="small"
      color="secondary"
      variant="contained"
      className={className}
      startIcon={<LoginIcon />}
      onClick={redirectToDashboard}
    >
      {intl.formatMessage(messages.buttonLabel)}
    </Button>
  );
};

export default GoToDashboardButton;
