import { Configuration as UserConfig } from "../../generated/user-api";
import {
  AuthenticationApi,
  Configuration as AuthenticationConfig,
} from "../../generated/authentication-api";
import {
  Configuration as CustomerConfig,
  CustomerApi,
} from "../../generated/customer-api";
import { Configuration as MeetingConfig } from "../../generated/meeting-api";
import { Configuration as ContactConfig } from "../../generated/contact-api";
import {
  Configuration as NotesConfig,
  NotesApi,
} from "../../generated/notes-api";
import { Configuration as FileConfig, FileApi } from "../../generated/file-api";
import { Configuration as TodoConfig, TodoApi } from "../../generated/todo-api";
import {
  Configuration as UserConsentConfig,
  UserConsentApi,
} from "../../generated/userconsent-api";
import {
  Configuration as CustomerSettingsConfig,
  CustomerSettingsApi,
} from "../../generated/customersettings-api";
import {
  Configuration as MeetingChatConfig,
  MeetingchatApi,
} from "../../generated/meetingchat-api";
import {
  Configuration as CustomerContentConfig,
  CustomerContentApi,
} from "../../generated/customercontent-api";
import { Configuration as MessageConfig } from "../../generated/message-api";
import {
  Configuration as MeetingTemplateConfig,
  MeetingtemplateApi,
} from "../../generated/meetingtemplate-api";
import { Configuration as SyncConfig, SyncApi } from "../../generated/sync-api";
import { getSessionStorageItem, Key } from "../../Utils/SessionStorage";
import { CustomMessageApi } from "./CustomMessageApi";
import { CustomMeetingApi } from "./CustomMeetingApi";
import { CustomContactApi } from "./CustomContactApi";
import {
  Configuration as InviteConfig,
  InviteApi,
} from "../../generated/invite-api";
import {
  Configuration as VideoFilterConfig,
  FilterApi as VideoFilterApi,
} from "../../generated/video-api";
import { CustomUserApi } from "./CustomUserApi";
import {
  Configuration as NotificationConfig,
  NotificationApi,
} from "../../generated/notification-api";

const BASE_PATH = process.env.REACT_APP_BACKEND_BASE_URL;
const TOKEN_PREFIX = "Bearer "; // needs to be a space in the end

export const messageService = (): CustomMessageApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const messageConfig = new MessageConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new CustomMessageApi(messageConfig);
};

export const customerContentService = (
  useToken?: boolean
): CustomerContentApi => {
  let customerContentConfig;

  if (useToken) {
    const token = getSessionStorageItem(Key.JWT_TOKEN);
    customerContentConfig = new CustomerContentConfig({
      basePath: BASE_PATH,
      headers: { Authorization: `${TOKEN_PREFIX}${token}` },
    });
  } else {
    customerContentConfig = new CustomerContentConfig({
      basePath: BASE_PATH,
    });
  }
  return new CustomerContentApi(customerContentConfig);
};

export const todoService = (): TodoApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const todoConfig = new TodoConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new TodoApi(todoConfig);
};

export const meetingChatService = (): MeetingchatApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const meetingChatConfig = new MeetingChatConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new MeetingchatApi(meetingChatConfig);
};

export const customerSettingsService = (): CustomerSettingsApi => {
  const customerSettingsConfig = new CustomerSettingsConfig({
    basePath: BASE_PATH,
  });

  return new CustomerSettingsApi(customerSettingsConfig);
};
export const customerSettingsServiceToken = (): CustomerSettingsApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const customerSettingsConfig = new CustomerSettingsConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });
  return new CustomerSettingsApi(customerSettingsConfig);
};

export const userConsentService = (overrideToken?: string): UserConsentApi => {
  const token = overrideToken || getSessionStorageItem(Key.JWT_TOKEN);
  const userConsentConfig = new UserConsentConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new UserConsentApi(userConsentConfig);
};

export const fileService = (): FileApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const fileConfig = new FileConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new FileApi(fileConfig);
};

export const notesService = (): NotesApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const notesConfig = new NotesConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new NotesApi(notesConfig);
};

export const contactService = (): CustomContactApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const contactConfig = new ContactConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new CustomContactApi(contactConfig);
};

export const meetingService = (): CustomMeetingApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const meetingConfig = new MeetingConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new CustomMeetingApi(meetingConfig);
};

export const meetingTemplateService = (): MeetingtemplateApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const meetingTemplateConfig = new MeetingTemplateConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new MeetingtemplateApi(meetingTemplateConfig);
};

export const userService = (overrideToken?: string): CustomUserApi => {
  const token = overrideToken || getSessionStorageItem(Key.JWT_TOKEN);
  const userConfig = new UserConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new CustomUserApi(userConfig);
};

export const customerService = (): CustomerApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const customerConfig = new CustomerConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new CustomerApi(customerConfig);
};

export const authenticationService = (): AuthenticationApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  let authenticationConfig: AuthenticationConfig;
  if (token) {
    authenticationConfig = new AuthenticationConfig({
      basePath: BASE_PATH,
      headers: { Authorization: `${TOKEN_PREFIX}${token}` },
    });
  } else {
    authenticationConfig = new AuthenticationConfig({
      basePath: BASE_PATH,
    });
  }

  return new AuthenticationApi(authenticationConfig);
};

export const syncService = (): SyncApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const syncConfig = new SyncConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new SyncApi(syncConfig);
};

export const inviteService = (useToken: boolean): InviteApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const inviteConfig = new InviteConfig({
    basePath: BASE_PATH,
    headers: useToken ? { Authorization: `${TOKEN_PREFIX}${token}` } : {},
  });

  return new InviteApi(inviteConfig);
};

export const notificationService = (): NotificationApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const notificationConfig = new NotificationConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new NotificationApi(notificationConfig);
};

export const videoFilterService = (): VideoFilterApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const videoFilterConfig = new VideoFilterConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new VideoFilterApi(videoFilterConfig);
};
