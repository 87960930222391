/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarkMessagesAsReadRequest
 */
export interface MarkMessagesAsReadRequest {
    /**
     * true if all reply messages should be marked as read otherwise false.
     * @type {boolean}
     * @memberof MarkMessagesAsReadRequest
     */
    includeAllReplyMessages?: boolean;
    /**
     * Array with UUIDs of the messages to be marked as read.
     * @type {Array<string>}
     * @memberof MarkMessagesAsReadRequest
     */
    messageIds: Array<string>;
}

export function MarkMessagesAsReadRequestFromJSON(json: any): MarkMessagesAsReadRequest {
    return MarkMessagesAsReadRequestFromJSONTyped(json, false);
}

export function MarkMessagesAsReadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkMessagesAsReadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeAllReplyMessages': !exists(json, 'includeAllReplyMessages') ? undefined : json['includeAllReplyMessages'],
        'messageIds': json['messageIds'],
    };
}

export function MarkMessagesAsReadRequestToJSON(value?: MarkMessagesAsReadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeAllReplyMessages': value.includeAllReplyMessages,
        'messageIds': value.messageIds,
    };
}


