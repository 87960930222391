import React, { useState, useEffect } from "react";
import { differenceInMinutes } from "date-fns";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useStyles } from "./Timer.style";

const formatTimeLeft = (minutes: number) => {
  return `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
};

interface Props {
  expectedEndDateTime: Date;
}

const Timer = (props: Props) => {
  const classes = useStyles();
  const { expectedEndDateTime } = props;
  const [counter, setCounter] = useState(
    differenceInMinutes(expectedEndDateTime, new Date())
  );

  useEffect(() => {
    if (counter > 0) {
      // Will run every minute
      setTimeout(() => setCounter(counter - 1), 60000);
    }
  }, [counter]);

  return (
    <div className={classes.root}>
      <AccessTimeIcon fontSize="small" className={classes.icon} />
      {formatTimeLeft(counter)}
    </div>
  );
};

export default Timer;
