/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingParticipantRequest,
    MeetingParticipantRequestFromJSON,
    MeetingParticipantRequestFromJSONTyped,
    MeetingParticipantRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingRequest
 */
export interface MeetingRequest {
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof MeetingRequest
     */
    startDateTime: Date;
    /**
     * Meeting lenght in minutes
     * @type {number}
     * @memberof MeetingRequest
     */
    duration: number;
    /**
     * The users that are to participate in this meeting. At least one participant is required and at least one of the participants must have role MEETING_LEADER.
     * @type {Array<MeetingParticipantRequest>}
     * @memberof MeetingRequest
     */
    participants?: Array<MeetingParticipantRequest>;
}

export function MeetingRequestFromJSON(json: any): MeetingRequest {
    return MeetingRequestFromJSONTyped(json, false);
}

export function MeetingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'startDateTime': (new Date(json['startDateTime'])),
        'duration': json['duration'],
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(MeetingParticipantRequestFromJSON)),
    };
}

export function MeetingRequestToJSON(value?: MeetingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'startDateTime': (value.startDateTime.toISOString()),
        'duration': value.duration,
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(MeetingParticipantRequestToJSON)),
    };
}


