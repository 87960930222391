import React, { useEffect, useState } from "react";
import ConsentDialog from "../ConsentDialogs/ConsentDialog";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { UserAgreementConsentResponse } from "../../generated/userconsent-api";
import { getNoneConsentedAgreements } from "../../Utils/Agreements";
import { userConsentService } from "../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  children: React.ReactNode;
}

const ConsentController = (props: Props) => {
  const { children } = props;
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [userAgreementConsents, setUserAgreementConsents] = useState<
    UserAgreementConsentResponse[]
  >([]);

  const hasConsented = (): boolean => {
    const noneConsentedAgreements = getNoneConsentedAgreements(
      userAgreementConsents
    );

    return noneConsentedAgreements.length === 0;
  };

  const loadUserAgreementConsent = () => {
    if (customerId && authenticatedUser.user?.id && authenticatedUser.token) {
      userConsentService(authenticatedUser.token)
        .checkUserConsentOnCustomer({
          userId: authenticatedUser.user?.id,
          customerId,
        })
        .then(setUserAgreementConsents);
    }
  };

  const showConsentDialog = (): boolean => {
    return !!(authenticatedUser?.isLoggedIn && !hasConsented());
  };

  useEffect(() => {
    loadUserAgreementConsent();
  }, [authenticatedUser.user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {getNoneConsentedAgreements(userAgreementConsents).length > 0 && (
        <ConsentDialog
          open={showConsentDialog()}
          agreementIds={getNoneConsentedAgreements(userAgreementConsents)}
          onAccepted={loadUserAgreementConsent}
        />
      )}
      {children}
    </>
  );
};

export default ConsentController;
