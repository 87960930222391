import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  titleRequired: {
    id: "validation.meetingTitleRequired",
    defaultMessage: "Meeting title is required",
    description:
      "Text to display when user attempts to enter empty title for meeting",
  },
  titleTooLong: {
    id: "validation.meetingTitleTooLong",
    defaultMessage: "Meeting title can not be longer than 50 characters",
    description:
      "Text to display when user attempts to enter a meeting title that is longer than 50 characters",
  },
  startTimeRequired: {
    id: "validation.meetingStartTimeRequired",
    defaultMessage: "Meeting start time is required",
    description:
      "Text to display when user does not enter start time for meeting",
  },
  startTimeTooLow: {
    id: "validation.meetingStartTimeTooLow",
    defaultMessage: "Meeting start time must be in the future",
    description:
      "Text to display when user enters a start time earlier than current time",
  },
  durationRequired: {
    id: "validation.meetingDurationRequired",
    defaultMessage: "Duration for meeting is required",
    description:
      "Text to display when user does not enter duration for meeting",
  },
  durationTooShort: {
    id: "validation.meetingDurationTooShort",
    defaultMessage: "Meeting time can not be shorter than 1 minute",
    description:
      "Text to display when user attempts to enter a meeting title that is too short",
  },
});
