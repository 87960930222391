/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User Status
 * @export
 * @enum {string}
 */
export enum MeetingRoleType {
    MeetingLeader = 'MEETING_LEADER',
    Attendee = 'ATTENDEE'
}

export function MeetingRoleTypeFromJSON(json: any): MeetingRoleType {
    return MeetingRoleTypeFromJSONTyped(json, false);
}

export function MeetingRoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingRoleType {
    return json as MeetingRoleType;
}

export function MeetingRoleTypeToJSON(value?: MeetingRoleType | null): any {
    return value as any;
}

