import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./MeetingTitle.style";

interface Props {
  title?: string;
}

const MeetingTitle = (props: Props) => {
  const classes = useStyles();
  const { title } = props;

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" noWrap>
        <b>{title}</b>
      </Typography>
    </div>
  );
};

export default MeetingTitle;
