import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import {
  TextField,
  Grid,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  useTheme,
} from "@material-ui/core";
import { useIntl, IntlShape } from "react-intl";
import { messages } from "./MeetingTemplateFields.messages";
import { messages as errorMessages } from "./Components/Validation/ValidationTemplate.messages";
import { useStyles } from "./MeetingTemplateFields.style";
import CardHeading from "../../CardHeading/CardHeading";
import { MeetingTemplateKindType } from "../../../generated/meetingtemplate-api";
import { UserAuthorityType } from "../../../generated/user-api";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

export interface MeetingTemplateFormikValues {
  title: string;
  duration: number;
  templateType: MeetingTemplateKindType;
}

interface Props {
  formik: FormikProps<MeetingTemplateFormikValues>;
  disabledFields?: string[];
  errorType: string;
  typeDefaultValue?: MeetingTemplateKindType;
  isNewTemplate?: boolean;
}

const getErrorMessage = (intl: IntlShape, error?: string) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledTextInput = ({ label, touched, error, ...props }: any) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <TextField
      fullWidth
      label={label}
      InputLabelProps={{
        style: { color: theme.palette.text.secondary },
      }}
      variant="outlined"
      error={!!(error && touched)}
      autoComplete="off"
      helperText={
        touched && error ? getErrorMessage(intl, error.toString()) : null
      }
      {...props}
    />
  );
};

const MeetingTemplateFields = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    formik,
    disabledFields,
    errorType,
    typeDefaultValue,
    isNewTemplate,
  } = props;
  const { errors, touched } = formik;
  const [templateType, setTemplateType] = useState<
    MeetingTemplateKindType | undefined
  >(typeDefaultValue);
  const [authenticatedUser] = useAuthenticatedUser();
  useEffect(() => {
    setTemplateType(typeDefaultValue);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTemplate = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selected = event.target.value as MeetingTemplateKindType;
    setTemplateType(selected);
    const copy = formik.values;
    copy.templateType = selected;
    formik.setValues(copy);
  };

  return (
    <Grid container spacing={2}>
      {isNewTemplate && (
        <Grid item xs={12}>
          <CardHeading>
            {intl.formatMessage(messages.kindTypesHeader).toUpperCase()}
          </CardHeading>
          <FormControl variant="outlined" fullWidth>
            <Select
              id="templatesselect"
              value={templateType}
              onChange={handleChangeTemplate}
              error={errorType !== ""}
              disabled={
                disabledFields?.includes("templatesselect") ||
                !authenticatedUser.user?.userAuthorities?.includes(
                  UserAuthorityType.AdminSite
                )
              }
            >
              {(Object.keys(MeetingTemplateKindType) as Array<
                keyof typeof MeetingTemplateKindType
              >).map((key) => {
                const type: MeetingTemplateKindType =
                  MeetingTemplateKindType[key];
                return (
                  <MenuItem value={type}>
                    {intl.formatMessage(
                      MeetingTemplateKindType[key] ===
                        MeetingTemplateKindType.Personal
                        ? messages.personalTypeLabel
                        : messages.publicTypeLabel
                    )}
                  </MenuItem>
                );
              })}
            </Select>
            {errorType !== "" && (
              <FormHelperText className={classes.redColor}>
                {errorType}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <StyledTextInput
          id="title"
          name="title"
          inputMode="text"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={errors.title}
          touched={touched.title}
          label={intl.formatMessage(messages.titleLabel)}
          disabled={disabledFields?.includes("title")}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextInput
          id="duration"
          type="number"
          name="duration"
          value={formik.values.duration}
          onChange={formik.handleChange}
          error={errors.duration}
          touched={touched.duration}
          label={intl.formatMessage(messages.durationLabel)}
          disabled={disabledFields?.includes("duration")}
        />
      </Grid>
    </Grid>
  );
};

export default MeetingTemplateFields;
