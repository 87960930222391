import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  date: {
    width: 200,
    margin: theme.spacing(4),
  },
  select: {
    width: 200,
    margin: theme.spacing(4),
    textAlign: "left",
  },
  gridButton: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  gridDate: {
    textAlign: "left",
    height: 80,
    minWidth: 250,
    marginBottom: theme.spacing(1),
  },
  gridSelect: {
    textAlign: "right",
    height: 80,
    minWidth: 250,
    marginBottom: theme.spacing(1),
  },
  gridTextField: {
    marginLeft: theme.spacing(3.5),
    marginRight: theme.spacing(3.5),
  },
  titleField: {
    textAlign: "center",
  },
  textField: {
    marginTop: theme.spacing(0),
  },
}));
