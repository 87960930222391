/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    ToDoResponse,
    ToDoResponseFromJSON,
    ToDoResponseFromJSONTyped,
    ToDoResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedToDoResponseList
 */
export interface PaginatedToDoResponseList {
    /**
     * 
     * @type {Pagination}
     * @memberof PaginatedToDoResponseList
     */
    pagination: Pagination;
    /**
     * 
     * @type {Array<ToDoResponse>}
     * @memberof PaginatedToDoResponseList
     */
    data?: Array<ToDoResponse>;
}

export function PaginatedToDoResponseListFromJSON(json: any): PaginatedToDoResponseList {
    return PaginatedToDoResponseListFromJSONTyped(json, false);
}

export function PaginatedToDoResponseListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedToDoResponseList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(ToDoResponseFromJSON)),
    };
}

export function PaginatedToDoResponseListToJSON(value?: PaginatedToDoResponseList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(ToDoResponseToJSON)),
    };
}


