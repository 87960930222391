import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alertMessage: {
    id: "switchButton.alert",
    defaultMessage:
      "Link needs to be added to DB (WEBDOC_UPDATE_BOOKING_BUTTON)",
    description: "alert message",
  },
});
