/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Template API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A request object to update a meeting template.
 * @export
 * @interface UpdateMeetingTemplateRequest
 */
export interface UpdateMeetingTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMeetingTemplateRequest
     */
    title?: string;
    /**
     * Meeting lenght in minutes
     * @type {number}
     * @memberof UpdateMeetingTemplateRequest
     */
    duration?: number;
}

export function UpdateMeetingTemplateRequestFromJSON(json: any): UpdateMeetingTemplateRequest {
    return UpdateMeetingTemplateRequestFromJSONTyped(json, false);
}

export function UpdateMeetingTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeetingTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function UpdateMeetingTemplateRequestToJSON(value?: UpdateMeetingTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'duration': value.duration,
    };
}


