import React from "react";
import { ListItem } from "@material-ui/core";
import { useStyles } from "./Message.style";
import { MessageResponse } from "../../../../../../generated/message-api";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { isAuthenticatedUser } from "../../../../../../Utils/User";
import MessageChip from "../MessageChip/MessageChip";

interface Props {
  ref?: React.RefObject<HTMLDivElement>;
  messageResponse?: MessageResponse;
  distinctDate?: boolean;
  onDeleted?: () => void;
}

const Message = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { messageResponse, ref, distinctDate, onDeleted } = props;
  if (!messageResponse) {
    return null;
  }

  return (
    <ListItem
      key={messageResponse.id}
      className={
        isAuthenticatedUser(messageResponse.sender.id, authenticatedUser.user)
          ? classes.right
          : classes.left
      }
    >
      <div ref={ref} style={{ width: "100%" }}>
        <MessageChip
          onDeleted={onDeleted}
          messageResponse={messageResponse}
          distinctDate={distinctDate}
        />
      </div>
    </ListItem>
  );
};

export default Message;
