import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      padding: theme.spacing(2),
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      fontSize: 100,
    },
    bottomMargin: {
      marginBottom: theme.spacing(2),
    },
    bottomMarginSmall: {
      marginBottom: theme.spacing(1),
    },
  })
);
