import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "modifyuserdialog.title",
    defaultMessage: "Modify user {name}",
    description: "Title for dialog Modify user",
  },
  success: {
    id: "modifyuserdialog.success",
    defaultMessage: "{name} was updated",
    description: "Success message when other user has been updated",
  },
  duplicateHsaIdError: {
    id: "modifyuserdialog.error.duplicateHsaId",
    defaultMessage: "HSA_ID already exists",
    description:
      "Text shown in snack bar when user updates his/her HSA_ID fails due to duplicate error",
  },
  generalError: {
    id: "modifyuserdialog.error.general",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when it fails to update a user due to general error",
  },
  cancelButtonLabel: {
    id: "modifyuserdialog.button.cancel.label",
    defaultMessage: "Cancel",
    description: "label for cancel button in Modify user dialog",
  },
  submitButtonLabel: {
    id: "modifyuserdialog.button.submit.label",
    defaultMessage: "Update",
    description: "label for submit button in Modify user dialog",
  },
  generalTabTitle: {
    id: "modifyuserdialog.general.tab.title",
    defaultMessage: "General",
    description: "tab title for general in Modify user dialog",
  },
  statusTabTitle: {
    id: "modifyuserdialog.status.tab.title",
    defaultMessage: "Status",
    description: "tab title for status in Modify user dialog",
  },
  passwordTabTitle: {
    id: "modifyuserdialog.password.tab.title",
    defaultMessage: "Password",
    description: "tab title for password in Modify user dialog",
  },
});
