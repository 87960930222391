import { defineMessages } from "react-intl";

export const messages = defineMessages({
  unmuteTooltip: {
    id: "video.toolbar.unmute.tooltip",
    defaultMessage: "Unmute",
    description: "Tooltip label for button unmuting participants",
  },
  muteTooltip: {
    id: "video.toolbar.mute.tooltip",
    defaultMessage: "Mute",
    description: "Tooltip label for button muting participants",
  },
});
