import React from "react";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import NotificationFeatures from "../../../Components/NotificationFeatures/NotificationFeatures";

const NotificationFeaturesPage = () => {
  const customerId = useCustomerId();
  return <NotificationFeatures key="thisDomain" customerId={customerId} />;
};

export default NotificationFeaturesPage;
