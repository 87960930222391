import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      paddingTop: theme.spacing(0.5),
    },
  })
);
