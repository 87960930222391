import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  fabContainer: {
    display: "flex",
    margin: theme.spacing(0.5),
  },
  container: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  fab: {
    display: "flex",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 1,
    alignSelf: "flex-start",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
}));
