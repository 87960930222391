import { defineMessages } from "react-intl";

export const messages = defineMessages({
  incomingBookingRequestsTitle: {
    id: "bookingsview.bookings.incoming.title",
    defaultMessage: "Incoming booking requests",
    description: "title incoming bookings section",
  },
  upcomingBookingsTitle: {
    id: "bookingsview.bookings.upcoming.title",
    defaultMessage: "Upcoming visits",
    description: "title upcoming bookings section",
  },
  viewCalendarTitle: {
    id: "bookingsview.bookings.viewCalendar.title",
    defaultMessage: "View on Calendar",
    description: "View all events on calendar",
  },
  ongoingBookingsRequestTitle: {
    id: "bookingsview.bookings.ongoing.title",
    defaultMessage: "My ongoing booking requests",
    description: "title ongoing bookings section",
  },
  pastBookingsTitle: {
    id: "bookingsview.bookings.past.title",
    defaultMessage: "All previous bookings",
    description: "title past bookings section",
  },
  bookMeetingButton: {
    id: "bookingsview.bookings.createButton",
    defaultMessage: "Schedule a meeting",
    description: "label for button to create new meeting",
  },
});
