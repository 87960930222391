import { useHistory } from "react-router-dom";
import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@material-ui/core";
import DefaultLogo from "../../../../Assets/Careplatform_logo.png";
import { useStyles } from "./Logo.style";
import { messages } from "../../AppBar.messages";
import {
  useCustomerIsAdminPortal,
  useCustomerTheme,
} from "../../../../Providers/CustomerProvider/CustomerProvider";
import { RoutePath } from "../../../Routes/RoutePath";

const Logo = () => {
  const intl = useIntl();
  const customerTheme = useCustomerTheme();
  const classes = useStyles();
  const base64Logo = customerTheme?.logo;
  const history = useHistory();
  const isCustomerAdminPortal = useCustomerIsAdminPortal();

  const navigateToDashboard = () => {
    if (isCustomerAdminPortal) {
      history.push(RoutePath.PORTAL_CUSTOMERS);
    } else {
      history.push(RoutePath.DASHBOARD);
    }
  };
  return (
    <div className={classes.container}>
      <Button onClick={navigateToDashboard} className={classes.button}>
        {base64Logo ? (
          <img
            className={classes.logo}
            src={`data:;base64,${base64Logo}`}
            alt={intl.formatMessage(messages.logoAltText)}
          />
        ) : (
          <img
            className={classes.logo}
            src={DefaultLogo}
            alt={intl.formatMessage(messages.logoAltText)}
          />
        )}
      </Button>
    </div>
  );
};

export default Logo;
