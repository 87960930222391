import { defineMessages } from "react-intl";

export const messages = defineMessages({
  endCall: {
    id: "video.toolbar.endCall.label",
    defaultMessage: "End call for all",
    description: "Tooltip label for end call",
  },
  generalError: {
    id: "video.toolbar.endCall.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to end a meeting",
  },
  dialogTitle: {
    id: "dialog.endMeeting.title",
    defaultMessage: "Do you want to end this meeting?",
    description: "Dialog title",
  },
  dialogContent: {
    id: "dialog.endMeeting.content",
    defaultMessage:
      "This meeting session will be destroyed for all participants",
    description: "Dialog content",
  },
  dialogSubmitButton: {
    id: "dialog.endMeeting.button.submit",
    defaultMessage: "End meeting",
    description: "Dialog submit button",
  },
  dialogCancelButton: {
    id: "dialog.endMeeting.button.cancel",
    defaultMessage: "Cancel",
    description: "Dialog cancel button",
  },
});
