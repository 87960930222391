/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingResponse,
    MeetingResponseFromJSON,
    MeetingResponseFromJSONTyped,
    MeetingResponseToJSON,
    MeetingSessionInfo,
    MeetingSessionInfoFromJSON,
    MeetingSessionInfoFromJSONTyped,
    MeetingSessionInfoToJSON,
} from './';

/**
 * Response object for a meeting session, sessionInfo will be null if the meeting has not been started.
 * @export
 * @interface MeetingSessionResponse
 */
export interface MeetingSessionResponse {
    /**
     * 
     * @type {MeetingResponse}
     * @memberof MeetingSessionResponse
     */
    meeting: MeetingResponse;
    /**
     * 
     * @type {MeetingSessionInfo}
     * @memberof MeetingSessionResponse
     */
    sessionInfo?: MeetingSessionInfo;
}

export function MeetingSessionResponseFromJSON(json: any): MeetingSessionResponse {
    return MeetingSessionResponseFromJSONTyped(json, false);
}

export function MeetingSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingSessionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meeting': MeetingResponseFromJSON(json['meeting']),
        'sessionInfo': !exists(json, 'sessionInfo') ? undefined : MeetingSessionInfoFromJSON(json['sessionInfo']),
    };
}

export function MeetingSessionResponseToJSON(value?: MeetingSessionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meeting': MeetingResponseToJSON(value.meeting),
        'sessionInfo': MeetingSessionInfoToJSON(value.sessionInfo),
    };
}


