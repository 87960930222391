import React from "react";
import { TextField as MuiTextField, useTheme } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import { FormikProps } from "formik";

interface Props {
  id: string;
  label: string;
  errorMsg?: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
  autocompleteParams: AutocompleteRenderInputParams;
}

const RenderInput = (props: Props) => {
  const {
    autocompleteParams,
    label,
    formik,
    id,
    errorMsg,
    disabledFields,
  } = props;
  const { errors, touched } = formik;
  const theme = useTheme();

  return (
    <MuiTextField
      {...autocompleteParams}
      fullWidth
      label={label}
      InputLabelProps={{
        style: { color: theme.palette.text.secondary },
      }}
      variant="outlined"
      error={!!(errors[id] && touched[id])}
      helperText={touched[id] && errors[id] ? errorMsg : null}
      disabled={disabledFields?.includes(id)}
    />
  );
};

export default RenderInput;
