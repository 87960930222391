import { defineMessages } from "react-intl";

export const messages = defineMessages({
  statusActive: {
    id: "statusChip.status.active",
    defaultMessage: "Active",
    description: "Label for active status",
  },
  statusInactive: {
    id: "statusChip.status.inactive",
    defaultMessage: "Inactive",
    description: "Label for inactive status",
  },
  statusClosed: {
    id: "statusChip.status.closed",
    defaultMessage: "Closed",
    description: "Label for closed status",
  },
});
