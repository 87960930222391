import { useState, useRef } from "react";
import * as VideoExpress from "@vonage/video-express";
import { Device, PreviewPublisher } from "@vonage/video-express";
import useDevices from "./useDevices";
import { ErrorType } from "./Error";

let currentAudioIndex = 0;
let currentVideoIndex = 0;

export interface PreviewPublisherHook {
  publisherCreated: boolean;
  publisher: PreviewPublisher;
  audioLogLevel: number;
  createPublisher: (previewContainer: string | HTMLElement) => void;
  destroyPublisher: () => void;
  publisherErrors: ErrorType[];
  deviceInfo: {
    audioInputDevices: Device[];
    videoInputDevices: Device[];
    audioOutputDevices: Device[];
  };
}

export default function usePreviewPublisher() {
  const { deviceInfo, getDevices } = useDevices();
  const previewPublisher: any = useRef(null);
  const [publisherErrors, setPublisherErrors] = useState<ErrorType[]>([]);
  const [audioLogLevel, setAudioLogLevel] = useState(0);
  const [publisherCreated, setPreviewMediaCreated] = useState(false);

  const calculateAudioLevel = (audioLevel: number) => {
    // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    const currentLogLevel = Math.log(audioLevel) / Math.LN10 / 1.5 + 1;
    setAudioLogLevel(Math.min(Math.max(currentLogLevel, 0), 1) * 100);
  };

  const setupListeners = (ref: any) => {
    if (ref.current) {
      ref.current.on("audioLevelUpdated", (audioLevel: number) => {
        calculateAudioLevel(audioLevel);
      });
    }
  };

  const joinPreview = (
    previewContainer: string | HTMLElement,
    audioInputDevices: Device[],
    videoInputDevices: Device[]
  ) => {
    if (audioInputDevices.length < 1 && videoInputDevices.length < 1) {
      setPublisherErrors([
        ...publisherErrors,
        ErrorType.AUDIO_HARDWARE_NOT_FOUND,
        ErrorType.VIDEO_HARDWARE_NOT_FOUND,
      ]);
    } else if (videoInputDevices.length < 1) {
      setPublisherErrors([
        ...publisherErrors,
        ErrorType.VIDEO_HARDWARE_NOT_FOUND,
      ]);
    } else if (audioInputDevices.length < 1) {
      setPublisherErrors([
        ...publisherErrors,
        ErrorType.AUDIO_HARDWARE_NOT_FOUND,
      ]);
    }

    if (audioInputDevices.length > 0 || videoInputDevices.length > 0) {
      previewPublisher.current
        .previewMedia({
          targetElement: previewContainer,
          publisherProperties: {
            videoSource: videoInputDevices[currentVideoIndex]?.deviceId,
            audioSource: audioInputDevices[currentAudioIndex]?.deviceId,
          },
        })
        .then(() => {
          setupListeners(previewPublisher);
          setPreviewMediaCreated(previewPublisher);
        })
        .catch((e: Error) => {
          if (e.name === "OT_USER_MEDIA_ACCESS_DENIED") {
            setPublisherErrors([
              ...publisherErrors,
              ErrorType.OT_USER_MEDIA_ACCESS_DENIED,
            ]);
          }
          if (e.name === "OT_HARDWARE_UNAVAILABLE") {
            if (currentAudioIndex < audioInputDevices.length - 1) {
              currentAudioIndex += 1;
              joinPreview(
                previewContainer,
                audioInputDevices,
                videoInputDevices
              );
            } else if (currentVideoIndex < videoInputDevices.length - 1) {
              currentAudioIndex = 0;
              currentVideoIndex += 1;
              joinPreview(
                previewContainer,
                audioInputDevices,
                videoInputDevices
              );
            } else {
              setPublisherErrors([
                ...publisherErrors,
                ErrorType.OT_HARDWARE_UNAVAILABLE,
              ]);
            }
          }
        });
    }
  };

  const createPublisher = (previewContainer: string | HTMLElement) => {
    getDevices();
    previewPublisher.current = new VideoExpress.PreviewPublisher(
      previewContainer
    );

    VideoExpress.getDevices().then((devices) => {
      const audioInputDevices =
        devices?.filter((d) => d.kind.toLowerCase() === "audioinput") || [];
      const videoInputDevices =
        devices?.filter((d) => d.kind.toLowerCase() === "videoinput") || [];
      joinPreview(previewContainer, audioInputDevices, videoInputDevices);
    });

    previewPublisher.current
      .previewMedia({
        targetElement: previewContainer,
      })
      .then(() => {
        setupListeners(previewPublisher);
        setPreviewMediaCreated(previewPublisher);
      })
      .catch((e: Error) => {
        if (e.name === "OT_USER_MEDIA_ACCESS_DENIED") {
          setPublisherErrors([
            ...publisherErrors,
            ErrorType.OT_USER_MEDIA_ACCESS_DENIED,
          ]);
        }
      });
  };

  const destroyPublisher = () => {
    previewPublisher.current.destroy();
  };

  return {
    publisher: previewPublisher.current,
    createPublisher,
    destroyPublisher,
    audioLogLevel,
    publisherCreated,
    deviceInfo,
    publisherErrors,
  } as PreviewPublisherHook;
}
