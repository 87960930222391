import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  select: {
    width: 200,
    margin: theme.spacing(4),
    textAlign: "left",
  },
  infoText: {
    color: theme.palette.error.main,
  },
}));
