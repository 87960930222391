import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitleClient: {
    id: "dialog.addOrCreateUser.client.title.label",
    defaultMessage: "Create new patient",
    description: "Title for dialog",
  },
  dialogTitleStaff: {
    id: "dialog.addOrCreateUser.staff.title.label",
    defaultMessage: "Create new caregiver",
    description: "Title for dialog",
  },
  dialogTitleExternal: {
    id: "dialog.addOrCreateUser.external.title.label",
    defaultMessage: "Create new external user",
    description: "Title for dialog",
  },
  cancelButtonLabel: {
    id: "dialog.addOrCreateUser.button.cancel.label",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  submitButtonLabel: {
    id: "dialog.addOrCreateUser.button.submit.label",
    defaultMessage: "Create",
    description: "label for submit button",
  },
});
