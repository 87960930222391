import React from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoOutline from "@material-ui/icons/InfoOutlined";
import { NumberParam, useQueryParam, StringParam } from "use-query-params";
import { Alert } from "@mui/material";
import { messages } from "./Sync.messages";
import { useStyles } from "./Sync.style";
import GoToLoginButton from "../../Components/Buttons/GoToLoginButton/GoToLoginButton";
import GoToDashboardButton from "../../Components/Buttons/GoToDashboardButton/GoToDashboardButton";

interface Variant {
  title: string;
  icon: JSX.Element;
}

interface Variants {
  successOnlyCreated: Variant;
  successOnlyCanceled: Variant;
  successCreatedAndCanceled: Variant;
  info: Variant;
  error: Variant;
}

const Sync = () => {
  const intl = useIntl();
  const [createdBookings] = useQueryParam("created", NumberParam);
  const [canceledBookings] = useQueryParam("canceled", NumberParam);
  const [notified] = useQueryParam("notified", StringParam);
  const [userId] = useQueryParam("userId", StringParam);
  const classes = useStyles();

  const variants: Variants = {
    successCreatedAndCanceled: {
      title: intl.formatMessage(messages.titleSuccess),
      icon: <CheckCircleOutlineIcon className={classes.iconSuccess} />,
    },
    successOnlyCreated: {
      title: intl.formatMessage(messages.titleSuccess),
      icon: <CheckCircleOutlineIcon className={classes.iconSuccess} />,
    },
    successOnlyCanceled: {
      title: intl.formatMessage(messages.titleSuccess),
      icon: <CheckCircleOutlineIcon className={classes.iconSuccess} />,
    },
    info: {
      title: intl.formatMessage(messages.titleInfo),
      icon: <InfoOutline className={classes.iconInfo} />,
    },
    error: {
      title: intl.formatMessage(messages.titleError),
      icon: <HighlightOffIcon className={classes.iconError} />,
    },
  };

  const isZeroUpdates = () => {
    return createdBookings === 0 && canceledBookings === 0;
  };
  const getVariant = (): Variant => {
    if (isZeroUpdates()) {
      return variants.info;
    }
    if (
      createdBookings !== null &&
      createdBookings !== undefined &&
      canceledBookings !== null &&
      canceledBookings !== undefined
    ) {
      if (createdBookings > 0 && canceledBookings > 0) {
        return variants.successCreatedAndCanceled;
      }
      if (createdBookings > 0 && canceledBookings === 0) {
        return variants.successOnlyCreated;
      }
      if (createdBookings === 0 && canceledBookings > 0) {
        return variants.successOnlyCanceled;
      }
    }
    return variants.error;
  };

  return (
    <Paper className={classes.root}>
      {getVariant().icon}
      <Typography align="center" variant="h3" component="h1">
        {getVariant().title}
      </Typography>
      {isZeroUpdates() && (
        <Container className={classes.infoDescriptionContainer}>
          <Typography align="center" variant="body2" component="p">
            {intl.formatMessage(messages.descriptionInfo)}
          </Typography>
        </Container>
      )}
      {notified === "false" && !isZeroUpdates() && (
        <Container maxWidth="sm">
          <Alert
            severity="warning"
            variant="standard"
            className={classes.canceledContainer}
          >
            {intl.formatMessage(messages.userNotNotifiable)}
            <br />
            <GoToDashboardButton
              className={classes.dashboardButton}
              userId={userId}
            />
          </Alert>
        </Container>
      )}
      {createdBookings !== null &&
        createdBookings !== undefined &&
        createdBookings > 0 && (
          <Typography
            align="center"
            variant="subtitle1"
            className={classes.createdContainer}
          >
            {intl.formatMessage(messages.descriptionSuccessCreated, {
              numberOfCreatedBookings: createdBookings,
            })}
          </Typography>
        )}
      {canceledBookings !== null &&
        canceledBookings !== undefined &&
        canceledBookings > 0 && (
          <Typography
            align="center"
            variant="subtitle1"
            className={classes.canceledContainer}
          >
            {intl.formatMessage(messages.descriptionSuccessCanceled, {
              numberOfCanceledBookings: canceledBookings,
            })}
          </Typography>
        )}
      <GoToLoginButton className={classes.button} />
    </Paper>
  );
};

export default Sync;
