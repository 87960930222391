import { defineMessages } from "react-intl";

export const messages = defineMessages({
  startScreenSharing: {
    id: "video.toolbar.mutecamera.stopScreenSharing.tooltip",
    defaultMessage: "Stop Screensharing",
    description: "Tooltip label for stop screensharing",
  },
  stopScreenSharing: {
    id: "video.toolbar.mutecamera.startScreenSharing.tooltip",
    defaultMessage: "Start Screensharing",
    description: "Tooltip label for start screensharing",
  },
});
