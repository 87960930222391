/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerUnit,
    CustomerUnitFromJSON,
    CustomerUnitFromJSONTyped,
    CustomerUnitToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerUnitResponse
 */
export interface CustomerUnitResponse {
    /**
     * UUID of customer
     * @type {string}
     * @memberof CustomerUnitResponse
     */
    customerId: string;
    /**
     * List of customer units
     * @type {Array<CustomerUnit>}
     * @memberof CustomerUnitResponse
     */
    units?: Array<CustomerUnit>;
}

export function CustomerUnitResponseFromJSON(json: any): CustomerUnitResponse {
    return CustomerUnitResponseFromJSONTyped(json, false);
}

export function CustomerUnitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerUnitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'units': !exists(json, 'units') ? undefined : ((json['units'] as Array<any>).map(CustomerUnitFromJSON)),
    };
}

export function CustomerUnitResponseToJSON(value?: CustomerUnitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'units': value.units === undefined ? undefined : ((value.units as Array<any>).map(CustomerUnitToJSON)),
    };
}


