import React from "react";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { UserResponse } from "../../generated/user-api";
import { useStyles } from "./UserList.style";
import UserListItem from "./Components/UserListItem/UserListItem";
import { messages } from "./UserList.messages";
import { Variant } from "./Components/Variant/Variant";

interface Props {
  userList?: UserResponse[];
  variant?: Variant;

  onUpdated?: (updatedUser: UserResponse) => void;
}

const UserList = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { userList, onUpdated, variant } = props;

  if (!userList || userList.length === 0) {
    return (
      <Typography
        className={classes.noResultText}
        variant="h4"
        color="textSecondary"
      >
        {intl.formatMessage(messages.noResultsText)}
      </Typography>
    );
  }

  return (
    <List
      className={
        variant === Variant.ADMIN_NEWLY_ADDED
          ? classes.rootNewlyAdded
          : classes.root
      }
    >
      {userList.map(
        (user, index) =>
          user && (
            <UserListItem
              key={user.id}
              user={user}
              onUpdated={onUpdated}
              noDivider={userList?.length === index + 1}
            />
          )
      )}
    </List>
  );
};

export default UserList;
