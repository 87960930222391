import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    large: {
      width: 200,
      height: 200,
    },
    medium: {
      width: 100,
      height: 100,
    },
    small: {
      width: 70,
      height: 70,
    },
    center: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);
