import { defineMessages } from "react-intl";

export const messages = defineMessages({
  successUpdate: {
    id: "sign.changeCustomerStatus.success",
    defaultMessage: "Status was updated",
    description: "Success message when status has been updated",
  },
  generalError: {
    id: "sign.changeCustomerStatus.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to update status",
  },
});
