import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    chipContainer: {
      marginBottom: theme.spacing(2),
    },
    rightSpace: {
      marginRight: theme.spacing(1),
    },
  })
);
