import { defineMessages } from "react-intl";

export const messages = defineMessages({
  error: {
    id: "todoList.error",
    defaultMessage: "Something went wrong",
    description: "Error message when something fails",
  },
  deleteSuccess: {
    id: "todoList.delete.success",
    defaultMessage: "Todo successfully deleted",
    description: "Success message when delete todo",
  },
  deleteTooltip: {
    id: "todoList.tooltip.delete",
    defaultMessage: "Delete",
    description: "Tooltip for delete button",
  },
  openTooltip: {
    id: "todoList.tooltip.open",
    defaultMessage: "Open",
    description: "Tooltip for open todo",
  },
});
