/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Sync API
 * An API to use to trigger sync
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SyncRequest,
    SyncRequestFromJSON,
    SyncRequestToJSON,
    SyncResponse,
    SyncResponseFromJSON,
    SyncResponseToJSON,
} from '../models';

export interface SyncBookingsRequest {
    syncRequest: SyncRequest;
}

/**
 * 
 */
export class SyncApi extends runtime.BaseAPI {

    /**
     * Synchronize Takecare bookings and redirect to customer page
     * Synchronize Takecare bookings and redirect to customer page
     */
    async redirectedSyncRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Synchronize Takecare bookings and redirect to customer page
     * Synchronize Takecare bookings and redirect to customer page
     */
    async redirectedSync(): Promise<void> {
        await this.redirectedSyncRaw();
    }

    /**
     * Sync bookings
     * sync bookings.
     */
    async syncBookingsRaw(requestParameters: SyncBookingsRequest): Promise<runtime.ApiResponse<SyncResponse>> {
        if (requestParameters.syncRequest === null || requestParameters.syncRequest === undefined) {
            throw new runtime.RequiredError('syncRequest','Required parameter requestParameters.syncRequest was null or undefined when calling syncBookings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sync/bookings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncRequestToJSON(requestParameters.syncRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncResponseFromJSON(jsonValue));
    }

    /**
     * Sync bookings
     * sync bookings.
     */
    async syncBookings(requestParameters: SyncBookingsRequest): Promise<SyncResponse> {
        const response = await this.syncBookingsRaw(requestParameters);
        return await response.value();
    }

}
