import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useIntl } from "react-intl";
import { messages } from "./SearchResult.messages";
import { User } from "../../../../../Models/User";
import { displayUser } from "../../../../../Utils/User";
import { Query } from "../SearchField/SearchField";

interface Props {
  query?: Query;
  users?: User[];
  onCreateNew: () => void;
  onSelect: (user: User) => void;
  onInvite: () => void;
  isLoading: boolean;
}

const SearchResult = (props: Props) => {
  const { isLoading, query, users, onSelect, onCreateNew, onInvite } = props;
  const intl = useIntl();

  if (users && users.length === 0 && query?.personNumber && !isLoading) {
    return (
      <>
        <Typography>
          {intl.formatMessage(messages.noMatchPersonNumber)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onCreateNew}
        >
          {intl.formatMessage(messages.createUserButton)}
        </Button>
      </>
    );
  }

  if (users && users.length === 0 && query?.email && !isLoading) {
    return (
      <>
        <Typography>{intl.formatMessage(messages.noMatchEmail)}</Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onInvite}
        >
          {intl.formatMessage(messages.inviteUserButton)}
        </Button>
      </>
    );
  }

  return (
    <>
      <List>
        {users?.map((user: User) => (
          <ListItem button onClick={() => onSelect(user)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={displayUser(user)} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SearchResult;
