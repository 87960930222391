import { defineMessages } from "react-intl";

export const messages = defineMessages({
  submitButton: {
    id: "accountview.form.submitbutton",
    defaultMessage: "Update information",
    description: "Button text to submit form to update user information",
  },
  generalError: {
    id: "accountview.form.error.general",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when user updates his/her personal information and fails due to general error",
  },
  success: {
    id: "accountview.form.success",
    defaultMessage: "Information updated",
    description:
      "Text shown in snack bar when user succesfully updates his/her personal information",
  },
  beforeLeave: {
    id: "accountview.alert.beforeLeave",
    defaultMessage: "You have unsaved changes, are you sure you want to leave?",
    description:
      "Text shown in alert when user navigates from account view with unsaved changes",
  },
});
