/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Notes API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotesResponse
 */
export interface NotesResponse {
    /**
     * UUID of notes
     * @type {string}
     * @memberof NotesResponse
     */
    id: string;
    /**
     * Notes
     * @type {string}
     * @memberof NotesResponse
     */
    notes: string;
    /**
     * Date and time the notes was created
     * @type {Date}
     * @memberof NotesResponse
     */
    createdOn: Date;
    /**
     * Date and time the notes was last updated
     * @type {Date}
     * @memberof NotesResponse
     */
    updatedOn: Date;
}

export function NotesResponseFromJSON(json: any): NotesResponse {
    return NotesResponseFromJSONTyped(json, false);
}

export function NotesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'notes': json['notes'],
        'createdOn': (new Date(json['createdOn'])),
        'updatedOn': (new Date(json['updatedOn'])),
    };
}

export function NotesResponseToJSON(value?: NotesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'notes': value.notes,
        'createdOn': (value.createdOn.toISOString()),
        'updatedOn': (value.updatedOn.toISOString()),
    };
}


