import { useState, useEffect, useCallback } from "react";
import * as VideoExpress from "@vonage/video-express";

export default function useDevices() {
  const audioInputDevices: VideoExpress.Device[] = [];
  const videoInputDevices: VideoExpress.Device[] = [];
  const audioOutputDevices: VideoExpress.Device[] = [];

  const [deviceInfo, setDeviceInfo] = useState({
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices,
  });

  const getDevices = useCallback(async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      // console.warn("enumerateDevices() not supported.");
      return;
    }
    try {
      const devices: VideoExpress.Device[] | undefined = await VideoExpress.getDevices(); // eslint-disable-line
      if (devices) {
        const audioInputDevices: VideoExpress.Device[] = devices.filter( // eslint-disable-line
          (d) => d.kind.toLowerCase() === "audioinput"
        );
        const audioOutputDevices: VideoExpress.Device[] = devices.filter( // eslint-disable-line
          (d) => d.kind.toLowerCase() === "audiooutput"
        );
        const videoInputDevices: VideoExpress.Device[] = devices.filter( // eslint-disable-line
          (d) => d.kind.toLowerCase() === "videoinput"
        );
        setDeviceInfo({
          audioInputDevices,
          videoInputDevices,
          audioOutputDevices,
        });
      }
      // });
    } catch (err) {
      // console.log("[loadDevices] - ", err);
    }
  }, []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, [getDevices]);

  return { deviceInfo, getDevices };
}
