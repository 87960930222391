import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  cancelButtonLabel: {
    id: "dialog.shareFile.button.cancel.label",
    defaultMessage: "Cancel",
    description: "Cancel label for close dialog button",
  },
});
