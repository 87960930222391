/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAuthorityType,
    UserAuthorityTypeFromJSON,
    UserAuthorityTypeFromJSONTyped,
    UserAuthorityTypeToJSON,
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * Personnummer.
     * @type {string}
     * @memberof CreateUserRequest
     */
    personNumber?: string;
    /**
     * HSA-ID.
     * @type {string}
     * @memberof CreateUserRequest
     */
    hsaId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    workTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    mobilePhoneNumber?: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof CreateUserRequest
     */
    userRole: UserRoleType;
    /**
     * 
     * @type {Array<UserAuthorityType>}
     * @memberof CreateUserRequest
     */
    userAuthorities?: Array<UserAuthorityType>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    additionalInformation?: string;
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
    return CreateUserRequestFromJSONTyped(json, false);
}

export function CreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personNumber': !exists(json, 'personNumber') ? undefined : json['personNumber'],
        'hsaId': !exists(json, 'hsaId') ? undefined : json['hsaId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'userRole': UserRoleTypeFromJSON(json['userRole']),
        'userAuthorities': !exists(json, 'userAuthorities') ? undefined : ((json['userAuthorities'] as Array<any>).map(UserAuthorityTypeFromJSON)),
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
    };
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personNumber': value.personNumber,
        'hsaId': value.hsaId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'workTitle': value.workTitle,
        'email': value.email,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'userRole': UserRoleTypeToJSON(value.userRole),
        'userAuthorities': value.userAuthorities === undefined ? undefined : ((value.userAuthorities as Array<any>).map(UserAuthorityTypeToJSON)),
        'additionalInformation': value.additionalInformation,
    };
}


