/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Content API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentItemResponse,
    ContentItemResponseFromJSON,
    ContentItemResponseToJSON,
    ContentItemType,
    ContentItemTypeFromJSON,
    ContentItemTypeToJSON,
    ContentItemTypeRequest,
    ContentItemTypeRequestFromJSON,
    ContentItemTypeRequestToJSON,
    ContentItemTypeResponse,
    ContentItemTypeResponseFromJSON,
    ContentItemTypeResponseToJSON,
    CustomerUserAgreementType,
    CustomerUserAgreementTypeFromJSON,
    CustomerUserAgreementTypeToJSON,
    PaginatedAgreementList,
    PaginatedAgreementListFromJSON,
    PaginatedAgreementListToJSON,
    UserAgreementRequest,
    UserAgreementRequestFromJSON,
    UserAgreementRequestToJSON,
    UserAgreementResponse,
    UserAgreementResponseFromJSON,
    UserAgreementResponseToJSON,
} from '../models';

export interface CreateCustomerUserAgreementRequest {
    customerId: string;
    agreementType: CustomerUserAgreementType;
    userAgreementRequest: UserAgreementRequest;
}

export interface DeleteCustomerContentItemRequest {
    customerId: string;
    body: string;
}

export interface GetAllCustomerContentItemsRequest {
    customerId: string;
}

export interface GetCustomerContentItemByTypeRequest {
    customerId: string;
    contentItemType: ContentItemType;
}

export interface GetCustomerUserAgreementByTypeRequest {
    customerId: string;
    agreementType: CustomerUserAgreementType;
}

export interface GetCustomerUserAgreementsByTypeRequest {
    customerId: string;
    agreementType: CustomerUserAgreementType;
    page?: number;
    pageSize?: number;
}

export interface UpdateCustomerContentItemRequest {
    customerId: string;
    contentItemTypeRequest: ContentItemTypeRequest;
}

/**
 * 
 */
export class CustomerContentApi extends runtime.BaseAPI {

    /**
     * Create new customer agreement for customer
     * Create new customer agreement
     */
    async createCustomerUserAgreementRaw(requestParameters: CreateCustomerUserAgreementRequest): Promise<runtime.ApiResponse<UserAgreementResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createCustomerUserAgreement.');
        }

        if (requestParameters.agreementType === null || requestParameters.agreementType === undefined) {
            throw new runtime.RequiredError('agreementType','Required parameter requestParameters.agreementType was null or undefined when calling createCustomerUserAgreement.');
        }

        if (requestParameters.userAgreementRequest === null || requestParameters.userAgreementRequest === undefined) {
            throw new runtime.RequiredError('userAgreementRequest','Required parameter requestParameters.userAgreementRequest was null or undefined when calling createCustomerUserAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/content/{customerId}/agreement/{agreementType}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"agreementType"}}`, encodeURIComponent(String(requestParameters.agreementType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAgreementRequestToJSON(requestParameters.userAgreementRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAgreementResponseFromJSON(jsonValue));
    }

    /**
     * Create new customer agreement for customer
     * Create new customer agreement
     */
    async createCustomerUserAgreement(requestParameters: CreateCustomerUserAgreementRequest): Promise<UserAgreementResponse> {
        const response = await this.createCustomerUserAgreementRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete customer content item based on id.
     * Delete customer content item
     */
    async deleteCustomerContentItemRaw(requestParameters: DeleteCustomerContentItemRequest): Promise<runtime.ApiResponse<Array<ContentItemTypeResponse>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteCustomerContentItem.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling deleteCustomerContentItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/content/{customerId}/items`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContentItemTypeResponseFromJSON));
    }

    /**
     * Delete customer content item based on id.
     * Delete customer content item
     */
    async deleteCustomerContentItem(requestParameters: DeleteCustomerContentItemRequest): Promise<Array<ContentItemTypeResponse>> {
        const response = await this.deleteCustomerContentItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all items of content based on customer id. If no content is configured, a default item with the same type is returned.
     * Get all customer content items
     */
    async getAllCustomerContentItemsRaw(requestParameters: GetAllCustomerContentItemsRequest): Promise<runtime.ApiResponse<Array<ContentItemTypeResponse>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAllCustomerContentItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/content/{customerId}/items`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContentItemTypeResponseFromJSON));
    }

    /**
     * Get all items of content based on customer id. If no content is configured, a default item with the same type is returned.
     * Get all customer content items
     */
    async getAllCustomerContentItems(requestParameters: GetAllCustomerContentItemsRequest): Promise<Array<ContentItemTypeResponse>> {
        const response = await this.getAllCustomerContentItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get an item of content based on customer id and content type. If no content is configured, a default item with the same type is returned.
     * Get customer content
     */
    async getCustomerContentItemByTypeRaw(requestParameters: GetCustomerContentItemByTypeRequest): Promise<runtime.ApiResponse<ContentItemResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerContentItemByType.');
        }

        if (requestParameters.contentItemType === null || requestParameters.contentItemType === undefined) {
            throw new runtime.RequiredError('contentItemType','Required parameter requestParameters.contentItemType was null or undefined when calling getCustomerContentItemByType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/content/{customerId}/item/{contentItemType}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"contentItemType"}}`, encodeURIComponent(String(requestParameters.contentItemType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentItemResponseFromJSON(jsonValue));
    }

    /**
     * Get an item of content based on customer id and content type. If no content is configured, a default item with the same type is returned.
     * Get customer content
     */
    async getCustomerContentItemByType(requestParameters: GetCustomerContentItemByTypeRequest): Promise<ContentItemResponse> {
        const response = await this.getCustomerContentItemByTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the latest user agreement from a customer
     * Get customer content
     */
    async getCustomerUserAgreementByTypeRaw(requestParameters: GetCustomerUserAgreementByTypeRequest): Promise<runtime.ApiResponse<UserAgreementResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerUserAgreementByType.');
        }

        if (requestParameters.agreementType === null || requestParameters.agreementType === undefined) {
            throw new runtime.RequiredError('agreementType','Required parameter requestParameters.agreementType was null or undefined when calling getCustomerUserAgreementByType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/content/{customerId}/agreement/{agreementType}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"agreementType"}}`, encodeURIComponent(String(requestParameters.agreementType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAgreementResponseFromJSON(jsonValue));
    }

    /**
     * Get the latest user agreement from a customer
     * Get customer content
     */
    async getCustomerUserAgreementByType(requestParameters: GetCustomerUserAgreementByTypeRequest): Promise<UserAgreementResponse> {
        const response = await this.getCustomerUserAgreementByTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all customer agreements by type
     * Get all customer agreements by type
     */
    async getCustomerUserAgreementsByTypeRaw(requestParameters: GetCustomerUserAgreementsByTypeRequest): Promise<runtime.ApiResponse<PaginatedAgreementList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerUserAgreementsByType.');
        }

        if (requestParameters.agreementType === null || requestParameters.agreementType === undefined) {
            throw new runtime.RequiredError('agreementType','Required parameter requestParameters.agreementType was null or undefined when calling getCustomerUserAgreementsByType.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/content/{customerId}/agreements/{agreementType}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"agreementType"}}`, encodeURIComponent(String(requestParameters.agreementType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAgreementListFromJSON(jsonValue));
    }

    /**
     * Get all customer agreements by type
     * Get all customer agreements by type
     */
    async getCustomerUserAgreementsByType(requestParameters: GetCustomerUserAgreementsByTypeRequest): Promise<PaginatedAgreementList> {
        const response = await this.getCustomerUserAgreementsByTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update customer content item based on customerId and contentItemType.
     * Update customer content item
     */
    async updateCustomerContentItemRaw(requestParameters: UpdateCustomerContentItemRequest): Promise<runtime.ApiResponse<ContentItemTypeResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomerContentItem.');
        }

        if (requestParameters.contentItemTypeRequest === null || requestParameters.contentItemTypeRequest === undefined) {
            throw new runtime.RequiredError('contentItemTypeRequest','Required parameter requestParameters.contentItemTypeRequest was null or undefined when calling updateCustomerContentItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/content/{customerId}/items`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentItemTypeRequestToJSON(requestParameters.contentItemTypeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentItemTypeResponseFromJSON(jsonValue));
    }

    /**
     * Update customer content item based on customerId and contentItemType.
     * Update customer content item
     */
    async updateCustomerContentItem(requestParameters: UpdateCustomerContentItemRequest): Promise<ContentItemTypeResponse> {
        const response = await this.updateCustomerContentItemRaw(requestParameters);
        return await response.value();
    }

}
