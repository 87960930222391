/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthType,
    AuthTypeFromJSON,
    AuthTypeToJSON,
    AuthenticatedUser,
    AuthenticatedUserFromJSON,
    AuthenticatedUserToJSON,
    AuthenticationResponse,
    AuthenticationResponseFromJSON,
    AuthenticationResponseToJSON,
    AuthenticationStartResponse,
    AuthenticationStartResponseFromJSON,
    AuthenticationStartResponseToJSON,
    GenerateCodeRequest,
    GenerateCodeRequestFromJSON,
    GenerateCodeRequestToJSON,
    ServiceUserAuthenticationRequest,
    ServiceUserAuthenticationRequestFromJSON,
    ServiceUserAuthenticationRequestToJSON,
    ServiceUserAuthenticationResponse,
    ServiceUserAuthenticationResponseFromJSON,
    ServiceUserAuthenticationResponseToJSON,
    SignAuthenticationRequest,
    SignAuthenticationRequestFromJSON,
    SignAuthenticationRequestToJSON,
    UserAuthenticationRequest,
    UserAuthenticationRequestFromJSON,
    UserAuthenticationRequestToJSON,
    UserAuthenticationResponse,
    UserAuthenticationResponseFromJSON,
    UserAuthenticationResponseToJSON,
    VerifyCodeRequest,
    VerifyCodeRequestFromJSON,
    VerifyCodeRequestToJSON,
    VerifyCodeResponse,
    VerifyCodeResponseFromJSON,
    VerifyCodeResponseToJSON,
} from '../models';

export interface AuthenticateServiceUserRequest {
    customerId: string;
    serviceUserAuthenticationRequest: ServiceUserAuthenticationRequest;
}

export interface AuthenticateUserRequest {
    customerId: string;
    userAuthenticationRequest: UserAuthenticationRequest;
}

export interface CheckIdpAuthenticationSessionRequest {
    customerId: string;
    sessionId: string;
    authType: AuthType;
}

export interface GenerateCodeOperationRequest {
    customerId: string;
    generateCodeRequest: GenerateCodeRequest;
}

export interface RefreshTokenRequest {
    customerId: string;
}

export interface StartIdpAuthenticationSessionRequest {
    customerId: string;
    authType: AuthType;
    personNumber?: string;
    hsaId?: string;
}

export interface StartSignIdpAuthenticationSessionRequest {
    customerId: string;
    personNumber?: string;
    signAuthenticationRequest?: SignAuthenticationRequest;
}

export interface VerifyCodeOperationRequest {
    customerId: string;
    verifyCodeRequest: VerifyCodeRequest;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Authenticate service user for machine to machine calls.
     * Authenticate service user for M2M calls
     */
    async authenticateServiceUserRaw(requestParameters: AuthenticateServiceUserRequest): Promise<runtime.ApiResponse<ServiceUserAuthenticationResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling authenticateServiceUser.');
        }

        if (requestParameters.serviceUserAuthenticationRequest === null || requestParameters.serviceUserAuthenticationRequest === undefined) {
            throw new runtime.RequiredError('serviceUserAuthenticationRequest','Required parameter requestParameters.serviceUserAuthenticationRequest was null or undefined when calling authenticateServiceUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate/{customerId}/serviceUser`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceUserAuthenticationRequestToJSON(requestParameters.serviceUserAuthenticationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceUserAuthenticationResponseFromJSON(jsonValue));
    }

    /**
     * Authenticate service user for machine to machine calls.
     * Authenticate service user for M2M calls
     */
    async authenticateServiceUser(requestParameters: AuthenticateServiceUserRequest): Promise<ServiceUserAuthenticationResponse> {
        const response = await this.authenticateServiceUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Authenticate user using person number and password.
     * Authenticate user using person number and password
     */
    async authenticateUserRaw(requestParameters: AuthenticateUserRequest): Promise<runtime.ApiResponse<UserAuthenticationResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling authenticateUser.');
        }

        if (requestParameters.userAuthenticationRequest === null || requestParameters.userAuthenticationRequest === undefined) {
            throw new runtime.RequiredError('userAuthenticationRequest','Required parameter requestParameters.userAuthenticationRequest was null or undefined when calling authenticateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate/{customerId}/user`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAuthenticationRequestToJSON(requestParameters.userAuthenticationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthenticationResponseFromJSON(jsonValue));
    }

    /**
     * Authenticate user using person number and password.
     * Authenticate user using person number and password
     */
    async authenticateUser(requestParameters: AuthenticateUserRequest): Promise<UserAuthenticationResponse> {
        const response = await this.authenticateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Contacts the IdP using the provided sessionId to check the result of the authentication session.
     * Check status of an IdP authentication session.
     */
    async checkIdpAuthenticationSessionRaw(requestParameters: CheckIdpAuthenticationSessionRequest): Promise<runtime.ApiResponse<AuthenticationResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling checkIdpAuthenticationSession.');
        }

        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling checkIdpAuthenticationSession.');
        }

        if (requestParameters.authType === null || requestParameters.authType === undefined) {
            throw new runtime.RequiredError('authType','Required parameter requestParameters.authType was null or undefined when calling checkIdpAuthenticationSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.sessionId !== undefined) {
            queryParameters['sessionId'] = requestParameters.sessionId;
        }

        if (requestParameters.authType !== undefined) {
            queryParameters['authType'] = requestParameters.authType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/authenticate/{customerId}/idp`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseFromJSON(jsonValue));
    }

    /**
     * Contacts the IdP using the provided sessionId to check the result of the authentication session.
     * Check status of an IdP authentication session.
     */
    async checkIdpAuthenticationSession(requestParameters: CheckIdpAuthenticationSessionRequest): Promise<AuthenticationResponse> {
        const response = await this.checkIdpAuthenticationSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send generated code to user via SMS
     * Send generated code to user
     */
    async generateCodeRaw(requestParameters: GenerateCodeOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling generateCode.');
        }

        if (requestParameters.generateCodeRequest === null || requestParameters.generateCodeRequest === undefined) {
            throw new runtime.RequiredError('generateCodeRequest','Required parameter requestParameters.generateCodeRequest was null or undefined when calling generateCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate/{customerId}/code`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateCodeRequestToJSON(requestParameters.generateCodeRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send generated code to user via SMS
     * Send generated code to user
     */
    async generateCode(requestParameters: GenerateCodeOperationRequest): Promise<void> {
        await this.generateCodeRaw(requestParameters);
    }

    /**
     * Gets a new JWT with with updated expired time.
     * Refreches JWT.
     */
    async refreshTokenRaw(requestParameters: RefreshTokenRequest): Promise<runtime.ApiResponse<AuthenticatedUser>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling refreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/authenticate/refreshToken/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticatedUserFromJSON(jsonValue));
    }

    /**
     * Gets a new JWT with with updated expired time.
     * Refreches JWT.
     */
    async refreshToken(requestParameters: RefreshTokenRequest): Promise<AuthenticatedUser> {
        const response = await this.refreshTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Start an authentication session with an external Identity Provider to authenticate an user.
     * Authenticate user using IdP
     */
    async startIdpAuthenticationSessionRaw(requestParameters: StartIdpAuthenticationSessionRequest): Promise<runtime.ApiResponse<AuthenticationStartResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling startIdpAuthenticationSession.');
        }

        if (requestParameters.authType === null || requestParameters.authType === undefined) {
            throw new runtime.RequiredError('authType','Required parameter requestParameters.authType was null or undefined when calling startIdpAuthenticationSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.authType !== undefined) {
            queryParameters['authType'] = requestParameters.authType;
        }

        if (requestParameters.personNumber !== undefined) {
            queryParameters['personNumber'] = requestParameters.personNumber;
        }

        if (requestParameters.hsaId !== undefined) {
            queryParameters['hsaId'] = requestParameters.hsaId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/authenticate/{customerId}/idp`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationStartResponseFromJSON(jsonValue));
    }

    /**
     * Start an authentication session with an external Identity Provider to authenticate an user.
     * Authenticate user using IdP
     */
    async startIdpAuthenticationSession(requestParameters: StartIdpAuthenticationSessionRequest): Promise<AuthenticationStartResponse> {
        const response = await this.startIdpAuthenticationSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Start an sign session with an external Identity Provider to sign.
     * Sign using IdP
     */
    async startSignIdpAuthenticationSessionRaw(requestParameters: StartSignIdpAuthenticationSessionRequest): Promise<runtime.ApiResponse<AuthenticationStartResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling startSignIdpAuthenticationSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.personNumber !== undefined) {
            queryParameters['personNumber'] = requestParameters.personNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate/sign/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignAuthenticationRequestToJSON(requestParameters.signAuthenticationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationStartResponseFromJSON(jsonValue));
    }

    /**
     * Start an sign session with an external Identity Provider to sign.
     * Sign using IdP
     */
    async startSignIdpAuthenticationSession(requestParameters: StartSignIdpAuthenticationSessionRequest): Promise<AuthenticationStartResponse> {
        const response = await this.startSignIdpAuthenticationSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Verify code sent by SMS.
     * Verify code
     */
    async verifyCodeRaw(requestParameters: VerifyCodeOperationRequest): Promise<runtime.ApiResponse<VerifyCodeResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling verifyCode.');
        }

        if (requestParameters.verifyCodeRequest === null || requestParameters.verifyCodeRequest === undefined) {
            throw new runtime.RequiredError('verifyCodeRequest','Required parameter requestParameters.verifyCodeRequest was null or undefined when calling verifyCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authenticate/{customerId}/code/verify`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyCodeRequestToJSON(requestParameters.verifyCodeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyCodeResponseFromJSON(jsonValue));
    }

    /**
     * Verify code sent by SMS.
     * Verify code
     */
    async verifyCode(requestParameters: VerifyCodeOperationRequest): Promise<VerifyCodeResponse> {
        const response = await this.verifyCodeRaw(requestParameters);
        return await response.value();
    }

}
