import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGrid: {
      textAlign: "end",
      alignSelf: "flex-end",
    },
    button: {
      marginLeft: theme.spacing(1),
    },
    meetingDot: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
      borderWidth: 5,
      borderRadius: 5,
      marginRight: 3,
    },
    tooltipMinWidth: {
      minWidth: 600,
      color: theme.palette.primary.contrastText,
    },
  })
);
