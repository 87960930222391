import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "anonymizeFeatures.title",
    defaultMessage: "GDPR Settings",
    description: "label for customer features settings",
  },
  error: {
    id: "anonymizeFeatures.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occured for saving settings",
  },
});
