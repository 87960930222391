import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bankIdButton: {
    id: "login.bankId.button.label",
    defaultMessage: "Login - BankID",
    description: "Label for button to login with BankID",
  },
  submitButtonLabel: {
    id: "login.bankId.button.submit.label",
    defaultMessage: "Login",
    description: "Submit label for login",
  },
  cancelButtonLabel: {
    id: "login.bankId.button.cancel.label",
    defaultMessage: "Cancel",
    description: "Cancel label for login",
  },
  personNumber: {
    id: "login.bankId.input.personNumber.label",
    defaultMessage: "Personal identity number",
    description: "Label for textfield personal identity number in login form",
  },
  generalError: {
    id: "login.bankId.error.general",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when login failed due to general error",
  },
});
