import { defineMessages } from "react-intl";

export const messages = defineMessages({
  error: {
    id: "fileListItem.error.general",
    defaultMessage: "Something went wrong",
    description: "General error message",
  },
  lastOpenedLabel: {
    id: "fileListItem.lastOpened.label",
    defaultMessage: "Last opened",
    description: "Last opened label",
  },
  uploadedByLabel: {
    id: "fileListItem.uploadedBy.label",
    defaultMessage: "Uploaded by",
    description: "Uploaded by label",
  },
  sharedWithLabel: {
    id: "fileListItem.sharedWith.label",
    defaultMessage: "Shared with",
    description: "Shared With label",
  },
});
