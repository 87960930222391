export enum Key {
  USER_ID = "user-id",
  JWT_TOKEN = "jwt-token",
  NONE_NOTIFIABLE_USER = "none-notifiable-user",
}

export const getSessionStorageItem = (key: Key): string | undefined => {
  const value = sessionStorage.getItem(key);
  return value == null ? undefined : value;
};

export const setSessionStorageItem = (
  key: Key,
  value: string
): Promise<void> => {
  return Promise.resolve().then(() => sessionStorage.setItem(key, value));
};

export const removeSessionStorageItem = (key: Key): void => {
  sessionStorage.removeItem(key);
};
