import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "notes.title",
    defaultMessage: "Private notes",
    description: "Heading for notes card in meeting session view",
  },
  helperText: {
    id: "notes.helperText",
    defaultMessage: "Seen only by you",
    description: "Helper text for notes to indicate visibility",
  },
  content: {
    id: "notes.description",
    defaultMessage:
      "Note that this is not a journal or part of a journal. If the case is covered by medical record requirements, you must follow the regular procedures.",
    description: "Descriptive text shown in notes card",
  },
  autoSaveStatusNotSavedLabel: {
    id: "notes.chip.autoSave.status.notSaved",
    defaultMessage: "Typing...",
    description: "Label for chip when all changes not saved",
  },
  autoSaveStatusSavedLabel: {
    id: "notes.chip.autoSave.status.saved",
    defaultMessage: "Saved",
    description: "Label for chip when all changes saved",
  },
  error: {
    id: "notes.chip.autoSave.error",
    defaultMessage: "Could not save notes",
    description: "Text to be shown when fails to save notes",
  },
});
