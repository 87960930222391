import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useSubscription } from "react-stomp-hooks";
import { useStyles } from "./TodoCard.style";
import { messages } from "./TodoCard.messages";
import FeatureController from "../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import CreateTodoButton from "../Buttons/CreateTodoButton/CreateTodoButton";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { PaginatedToDoResponseList } from "../../generated/todo-api";
import TodoList from "./Components/TodoList/TodoList";
import { todoService } from "../../Providers/ServiceProvider/ServiceProvider";
import {
  parseSignalMessage,
  SignalType,
} from "../../WebSocket/model/SignalMessage";
import { SocketDestination } from "../../WebSocket/WebSocketSession";
import { Contact } from "../../Models/Contact";
import { MeetingParticipant } from "../../Models/Meeting";

interface Props {
  variant: "inMeeting" | "meetingOverview" | "personOverview";
  readOnly?: boolean;
  meetingId?: string;
  contact?: Contact;
  participants?: MeetingParticipant[];
  onLoaded?: () => void;
  onLoading?: () => void;
}

const TodoCard = (props: Props) => {
  const {
    meetingId,
    participants,
    contact,
    onLoaded,
    onLoading,
    readOnly,
    variant,
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [todos, setTodos] = useState<PaginatedToDoResponseList>();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();

  const loadTodos = () => {
    if (customerId && authenticatedUser.user) {
      if (onLoading) {
        onLoading();
      }
      todoService()
        .listToDos({
          meeting: meetingId,
          relatedTo: contact?.userId,
          pageSize: 30, // max 30 todos can be shown
          customerId,
        })
        .then((res) => setTodos(res))
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(onLoaded);
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meetingId, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.TODO &&
      signalMessage.triggeredBy !== authenticatedUser.user?.id
    ) {
      loadTodos();
    }
  });

  useEffect(() => {
    loadTodos();
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!todos) {
    return null;
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingTodo}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="todo-content"
          id="todo-header"
        >
          <Typography
            className={classes.cardHeading}
            component="h2"
            variant="subtitle2"
          >
            {intl.formatMessage(messages.title).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container disableGutters>
            <Typography gutterBottom variant="subtitle2">
              <Box fontStyle="italic">
                {variant === "inMeeting" &&
                  intl.formatMessage(messages.inMeetingDescription)}
                {variant === "personOverview" &&
                  intl.formatMessage(messages.personOverviewDescription, {
                    name: `${contact?.firstName} ${contact?.lastName}`,
                  })}
                {variant === "meetingOverview" &&
                  intl.formatMessage(messages.meetingOverviewDescription)}
              </Box>
            </Typography>
            <TodoList
              todos={todos}
              onDeleted={loadTodos}
              onUpdated={loadTodos}
            />
            <Box component="div" className={classes.buttonEnd}>
              {!readOnly && (
                <CreateTodoButton
                  meetingId={meetingId}
                  onCreated={loadTodos}
                  contact={contact}
                  participants={participants}
                  dialogTitle={intl.formatMessage(
                    messages.createTodoDialogTitle
                  )}
                  label={intl.formatMessage(messages.createTodoButtonLabel)}
                />
              )}
            </Box>
          </Container>
        </AccordionDetails>
      </Accordion>
    </FeatureController>
  );
};

export default TodoCard;
