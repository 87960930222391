import React from "react";
import { FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./TodoFields.messages";
import { messages as errorMessages } from "./Components/Validation/Validation.messages";
import TextField from "../../../../Fields/TextField/TextField";
import UserSelectField from "../../../../Fields/UserSelectField/UserSelectField";
import DateField, { Type } from "../../../../Fields/DateField/DateField";
import { User } from "../../../../../Models/User";

export interface TodoFormikValues {
  title: string;
  description: string;
  assignedTo: User[];
  shouldBeCompletedOn?: Date;
}

interface Props {
  formik: FormikProps<TodoFormikValues>;
  disabledFields?: string[];
  userOptionList?: User[];
}

const getErrorMessage = (intl: IntlShape, error?: string) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const TodoFields = (props: Props) => {
  const intl = useIntl();
  const { formik, disabledFields, userOptionList } = props;
  const { errors } = formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DateField
          id="shouldBeCompletedOn"
          formik={formik}
          type={Type.DATE}
          label={intl.formatMessage(messages.shouldBeCompletedOnLabel)}
          errorMsg={getErrorMessage(
            intl,
            errors.shouldBeCompletedOn?.toString()
          )}
          disabledFields={disabledFields}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="title"
          formik={formik}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.titleLabel)}
          errorMsg={getErrorMessage(intl, errors.title?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
          formik={formik}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.descriptionLabel)}
          errorMsg={getErrorMessage(intl, errors.description?.toString())}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <UserSelectField
          id="assignedTo"
          label={intl.formatMessage(messages.assignedToLabel)}
          formik={formik}
          errorMsg={getErrorMessage(intl, errors.assignedTo?.toString())}
          disabledFields={disabledFields}
          userOptionList={userOptionList}
        />
      </Grid>
    </Grid>
  );
};

export default TodoFields;
