import {
  ListUsersByCustomerAndSearchParamsRequest,
  PaginatedUserListResponse,
  UserApi,
  UserRoleType,
} from "../../generated/user-api";
import { User } from "../../Models/User";
import { getUserIdsFromUsers } from "../../Utils/UserId";
import { decoratePaginatedUserListWithPersonNumber } from "../../Utils/Decorator";

export class CustomUserApi extends UserApi {
  async listUsersByCustomerAndSearchParamsDecorated(
    authenticatedUser: User,
    requestParameters: ListUsersByCustomerAndSearchParamsRequest
  ): Promise<PaginatedUserListResponse> {
    const originalResponse = await this.listUsersByCustomerAndSearchParamsRaw(
      requestParameters
    );
    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        const clientIds = getUserIdsFromUsers(UserRoleType.Client, res.data);
        return this.listPersonNumbers({
          listPersonNumberRequest: { userIds: Array.from(clientIds) },
        }).then((pIds) => decoratePaginatedUserListWithPersonNumber(res, pIds));
      }
      return res;
    });
  }
}
