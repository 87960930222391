import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Button from "@material-ui/core/Button";
import { PreviewPublisherHook } from "../../../SessionVideo/hooks/usePreviewPublisher";
import { messages } from "./PreviewVideoCard.messages";
import useStyles from "./PreviewVideoCard.style";
import { ErrorType } from "../../../SessionVideo/hooks/Error";
import AllowMediaGuideDialog from "../../../../Components/Dialogs/AllowMediaGuideDialog/AllowMediaGuideDialog";

interface Props {
  preview: PreviewPublisherHook;
}

const PreviewVideoCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [videoDevice, setVideoDevice] = useState("");
  const { preview } = props;
  const [showGuide, setShowGuide] = useState(false);

  const roomContainer: any = useRef();

  const handleVideoSource = React.useCallback(
    (e) => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      preview.publisher.setVideoDevice(videoDeviceId);
    },
    [preview.publisher]
  );

  useEffect(() => {
    if (preview.deviceInfo && preview.deviceInfo.videoInputDevices.length > 0) {
      if (preview.publisherCreated && preview.deviceInfo) {
        // @ts-ignore
        setVideoDevice(preview.publisher.getVideoDevice().deviceId);
      }
    }
  }, [preview.deviceInfo, preview.publisher, preview.publisherCreated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (roomContainer.current) {
      preview.createPublisher(roomContainer.current);
    }

    return () => {
      preview.destroyPublisher();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    preview.publisherErrors.includes(ErrorType.OT_USER_MEDIA_ACCESS_DENIED) ||
    preview.publisherErrors.includes(ErrorType.VIDEO_HARDWARE_NOT_FOUND)
  ) {
    return (
      <Paper className={classes.paper}>
        <AllowMediaGuideDialog
          isOpen={showGuide}
          onClose={() => setShowGuide(false)}
        />
        <Grid container justify="center" alignItems="center">
          <Grid item md={4} xs={12}>
            <div className={classes.iconContainer}>
              <ReportProblemIcon className={classes.icon} />
            </div>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography variant="h5" color="error">
              {intl.formatMessage(messages.titleLabel)}
            </Typography>
            <Typography variant="body1">
              {preview.publisherErrors.includes(
                ErrorType.OT_USER_MEDIA_ACCESS_DENIED
              )
                ? intl.formatMessage(messages.errorContent)
                : intl.formatMessage(messages.errorNoDeviceFound)}
            </Typography>
            {preview.publisherErrors.includes(
              ErrorType.OT_USER_MEDIA_ACCESS_DENIED
            ) && (
              <Button variant="outlined" onClick={() => setShowGuide(true)}>
                {intl.formatMessage(messages.showHowButtonLabel)}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Grid container justify="center" alignItems="center">
        <Grid item md={4} xs={12}>
          <div
            id="video-container"
            className={classes.videoPreview}
            ref={roomContainer}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography variant="h5">
            {intl.formatMessage(messages.titleLabel)}
          </Typography>
          <Typography variant="body1">
            {intl.formatMessage(messages.content)}
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="videoSource">
              {intl.formatMessage(messages.videoSourceLabel)}
            </InputLabel>
            {preview.deviceInfo.videoInputDevices && (
              <Select
                labelId="videoSource"
                value={videoDevice}
                onChange={handleVideoSource}
              >
                {preview.deviceInfo.videoInputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PreviewVideoCard;
