import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    inputText: {
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 13,
      marginBottom: 8,
    },
    chatGrid: {
      paddingTop: 30,
      paddingBottom: 10,
      marginTop: 10,
      borderTopColor: "lightgray",
      borderTopStyle: "solid",
      borderTopWidth: 0.5,
    },
    textPadding: {
      paddingRight: 10,
    },
    buttonEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  })
);
