/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Indicates if a message has been marked as read.
 * @export
 * @enum {string}
 */
export enum MessageStatusType {
    MarkedAsRead = 'MARKED_AS_READ',
    Unread = 'UNREAD'
}

export function MessageStatusTypeFromJSON(json: any): MessageStatusType {
    return MessageStatusTypeFromJSONTyped(json, false);
}

export function MessageStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageStatusType {
    return json as MessageStatusType;
}

export function MessageStatusTypeToJSON(value?: MessageStatusType | null): any {
    return value as any;
}

