import { useCallback, useRef, useState } from "react";
import * as VideoExpress from "@vonage/video-express";
import { Device } from "@vonage/video-express";
import Participant from "@vonage/video-express/dist/internal/participant";
import { PublisherProperties } from "opentok-react/types/opentok";
import { ErrorType } from "./Error";

let currentAudioIndex = 0;
let currentVideoIndex = 0;

export default function useRoom(sessionEUProxy: string | null) {
  const roomRef: any = useRef(null);
  const [camera, setCamera] = useState(null);
  const [screen, setScreen] = useState(null);
  const [localParticipant, setLocalParticipant] = useState<any>(null);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [connected, setConnected] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [networkStatus, setNetworkStatus] = useState<string | null>(null);
  const [publisherIsSpeaking] = useState(false);
  const [cameraPublishing, setCameraPublishing] = useState(false);
  const participantsArray: Participant[] = [];

  const addParticipants = (participant: Participant) => {
    participantsArray.push(participant);
    setParticipants([...participantsArray]);
  };

  const removeParticipants = (participant: Participant) => {
    const indexToBeRemoved = participantsArray.indexOf(participant);
    participantsArray.splice(indexToBeRemoved, 1);
    setParticipants([...participantsArray]);
  };

  const addLocalParticipant = (room: any) => {
    if (room) {
      setLocalParticipant({
        id: room.participantId,
        name: room.participantName,
      });
    }
  };
  const startRoomListeners = () => {
    if (roomRef.current) {
      roomRef.current.on("connected", () => {});

      roomRef.current.on("disconnected", () => {
        setNetworkStatus("disconnected");
      });

      roomRef.current.camera.on("created", () => {
        setCameraPublishing(true);
      });

      roomRef.current.on("activeSpeakerChanged", () => {});

      roomRef.current.on("reconnected", () => {
        setNetworkStatus("reconnected");
      });

      roomRef.current.on("reconnecting", () => {
        setNetworkStatus("reconnecting");
      });

      roomRef.current.on("participantJoined", (participant: Participant) => {
        addParticipants(participant);
      });

      roomRef.current.on("participantLeft", (participant: Participant) => {
        removeParticipants(participant);
      });
    }
  };

  const joinCall = (
    publisherProperties: PublisherProperties,
    audioInputDevices: Device[],
    videoInputDevices: Device[]
  ) => {
    if (audioInputDevices.length < 1 && videoInputDevices.length < 1) {
      setErrors([
        ...errors,
        ErrorType.AUDIO_HARDWARE_NOT_FOUND,
        ErrorType.VIDEO_HARDWARE_NOT_FOUND,
      ]);
    } else if (videoInputDevices.length < 1) {
      setErrors([...errors, ErrorType.VIDEO_HARDWARE_NOT_FOUND]);
    } else if (audioInputDevices.length < 1) {
      setErrors([...errors, ErrorType.AUDIO_HARDWARE_NOT_FOUND]);
    }

    roomRef.current
      .join({
        publisherProperties: {
          ...publisherProperties,
          videoSource:
            videoInputDevices[currentVideoIndex]?.deviceId || undefined,
          audioSource:
            audioInputDevices[currentAudioIndex]?.deviceId || undefined,
        } as PublisherProperties,
      })
      .then(() => {
        setConnected(true);
        setCamera(roomRef.current.camera);
        setScreen(roomRef.current.screen);
        addLocalParticipant({ room: roomRef.current });
      })
      .catch((e: Error) => {
        if (e.name === "OT_USER_MEDIA_ACCESS_DENIED") {
          setErrors([...errors, ErrorType.OT_USER_MEDIA_ACCESS_DENIED]);
        }
        if (e.name === "OT_HARDWARE_UNAVAILABLE") {
          if (currentAudioIndex < audioInputDevices.length - 1) {
            currentAudioIndex += 1;
            joinCall(publisherProperties, audioInputDevices, videoInputDevices);
          } else if (currentVideoIndex < videoInputDevices.length - 1) {
            currentAudioIndex = 0;
            currentVideoIndex += 1;
            joinCall(publisherProperties, audioInputDevices, videoInputDevices);
          } else {
            setErrors([...errors, ErrorType.OT_HARDWARE_UNAVAILABLE]);
          }
        }
        if (audioInputDevices.length < 1 || videoInputDevices.length < 1) {
          setErrors([...errors, ErrorType.OT_HARDWARE_UNAVAILABLE]);
        }
      });
  };

  const createCall = useCallback(
    ({ apikey, sessionId, token }, userName, publisherOptions) => {
      if (!apikey || !sessionId || !token) {
        throw new Error("Check your credentials");
      }

      if (!sessionEUProxy) {
        throw new Error("No Proxy URL available");
      }
      VideoExpress.setProxyUrl(`${sessionEUProxy}`).then(() => {
        roomRef.current = new VideoExpress.Room({
          apiKey: apikey,
          sessionId,
          token,
          roomContainer: "roomContainer",
          maxVideoParticipantsOnScreen: 25,
          participantName: userName,
          managedLayoutOptions: {
            layoutMode: "grid",
            cameraPublisherContainer: "publisherContainer",
          },
        });

        startRoomListeners();

        const finalPublisherOptions = Object.assign({}, publisherOptions, { // eslint-disable-line
          style: {
            buttonDisplayMode: "off",
            nameDisplayMode: "auto",
            audioLevelDisplayMode: "off",
          },
          name: userName,
          showControls: true,
        });

        VideoExpress.getDevices().then((devices) => {
          const audioInputDevices =
            devices?.filter((d) => d.kind.toLowerCase() === "audioinput") || [];
          const videoInputDevices =
            devices?.filter((d) => d.kind.toLowerCase() === "videoinput") || [];
          joinCall(finalPublisherOptions, audioInputDevices, videoInputDevices);
        });
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    createCall,
    connected,
    camera,
    screen,
    room: roomRef.current,
    participants,
    networkStatus,
    publisherIsSpeaking,
    cameraPublishing,
    localParticipant,
    errors,
  };
}
