import React from "react";
import { StompSessionProvider } from "react-stomp-hooks";
import { getSessionStorageItem, Key } from "../Utils/SessionStorage";

interface Props {
  children: React.ReactNode;
}

interface StompHeaders {
  [key: string]: string;
}

export enum SocketDestination {
  SIGNAL_GLOBAL = "/user/topic/global.", // /user/topic/global.{CUSTOMER_ID}
  SIGNAL_MEETING = "/user/topic/meeting.", // /user/topic/meeting.{MEETING_ID}
}

const WebSocketSession = (props: Props) => {
  const { children } = props;
  const TOKEN_PREFIX = "Bearer "; // needs to be a space in the end
  const TOKEN = getSessionStorageItem(Key.JWT_TOKEN);
  const BASE_PATH = process.env.REACT_APP_BACKEND_BASE_URL;
  const SOCKET_CONNECT_PATH = `${BASE_PATH}/websocket`;

  const headers: StompHeaders = {};
  headers.Authorization = TOKEN_PREFIX + TOKEN;

  if (!TOKEN) {
    return <>{children}</>;
  }

  return (
    <StompSessionProvider
      url={SOCKET_CONNECT_PATH}
      connectHeaders={headers}
      reconnectDelay={3000}
    >
      {children}
    </StompSessionProvider>
  );
};

export default WebSocketSession;
