/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplyToMessageRequest
 */
export interface ReplyToMessageRequest {
    /**
     * The contentes of this reply message
     * @type {string}
     * @memberof ReplyToMessageRequest
     */
    contents: string;
}

export function ReplyToMessageRequestFromJSON(json: any): ReplyToMessageRequest {
    return ReplyToMessageRequestFromJSONTyped(json, false);
}

export function ReplyToMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplyToMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contents': json['contents'],
    };
}

export function ReplyToMessageRequestToJSON(value?: ReplyToMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contents': value.contents,
    };
}


