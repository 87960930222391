import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "@mui/material";
import { useAuthenticatedUser } from "../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useStyles } from "../../PortalCustomerSettings.style";
import { PaginatedUserList } from "../../../../../Models/User";
import { useSpinner } from "../../../../../Components/Spinner/Spinner";
import { userService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import {
  SortByType,
  SortOrderType,
  UserRoleType,
} from "../../../../../generated/user-api";
import { hasPermission } from "../../../../../Components/PermissionController/PermissionController";
import { RoutePath } from "../../../../../Components/Routes/RoutePath";
import CreateUserButton from "../../../../../Components/Buttons/CreateUserButton/CreateUserButton";
import { messages } from "./PortalCustomerUserSettings.messages";
import UserList from "../../../../../Components/UserList/UserList";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import CustomPagination from "../../../../../Components/CustomPagination/Pagination";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";

export interface Props {
  customerId: string;
}
const PortalCustomerUserSettings = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const thisCustomerId = useCustomerId();
  const [result, setResult] = useState<PaginatedUserList>();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>();
  const setSpinner = useSpinner()[1];
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoadingComplete, setFirstLoadingComplete] = useState(false);

  const loadUserList = () => {
    if (customerId) {
      setIsLoading(true);
      userService()
        .listUsersByCustomerAndSearchParams({
          name: query !== "" ? query : undefined,
          pageSize,
          page,
          customerId,
          sortBy: SortByType.LastName,
          sortOrder: SortOrderType.Ascending,
          userRole: UserRoleType.PlatformAdmin,
        })
        .then((res) => {
          setResult(res);
          setFirstLoadingComplete(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    allowedRoles: [UserRoleType.PlatformAdmin],
  });

  useEffect(() => {
    loadUserList();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page !== 1) {
      // Reset pagination when search is triggered
      setPage(1);
    } else {
      loadUserList();
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading });
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  return (
    <>
      <div className={classes.searchBarDiv}>
        {firstLoadingComplete && (
          <div className={classes.actionContainer}>
            <CreateUserButton
              onCreated={loadUserList}
              userRole={
                thisCustomerId === customerId
                  ? UserRoleType.PlatformAdmin
                  : UserRoleType.Staff
              }
              label={intl.formatMessage(messages.createButton)}
              dialogTitle={intl.formatMessage(messages.createDialogTitle)}
              customerId={customerId}
            />
          </div>
        )}
        {firstLoadingComplete && (
          <SearchBar
            placeholder={intl.formatMessage(messages.searchbarPlaceholder)}
            onSubmit={(newQuery) => setQuery(newQuery)}
          />
        )}
      </div>
      {!isLoading && (
        <>
          <UserList userList={result?.data} onUpdated={loadUserList} />
          <Divider />
          <CustomPagination
            onChange={setPage}
            pagination={result?.pagination}
          />
        </>
      )}
    </>
  );
};

export default PortalCustomerUserSettings;
