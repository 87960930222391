import { useIntl } from "react-intl";
import { messages } from "../../Views/CustomerSettings/CustomerSettings.messages";
import { CustomerFeatureType } from "../../generated/customersettings-api";

interface NotificationFeaturesConfig {
  label: string;
  smsFeature: CustomerFeatureType;
  emailFeature: CustomerFeatureType;
}
interface CustomerFeaturesConfig {
  label: string;
  feature: CustomerFeatureType;
}

export const FeaturesPatientsAndExternalConfig = (): NotificationFeaturesConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.notificationClientMeetingInvited),
      emailFeature: CustomerFeatureType.NotificationEmailClientMeetingInvited,
      smsFeature: CustomerFeatureType.NotificationSmsClientMeetingInvited,
    },
    {
      label: intl.formatMessage(messages.notificationClientMeetingRescheduled),
      emailFeature:
        CustomerFeatureType.NotificationEmailClientMeetingRescheduled,
      smsFeature: CustomerFeatureType.NotificationSmsClientMeetingRescheduled,
    },
    {
      label: intl.formatMessage(messages.notificationClientMeetingCancelled),
      emailFeature: CustomerFeatureType.NotificationEmailClientMeetingCancelled,
      smsFeature: CustomerFeatureType.NotificationSmsClientMeetingCancelled,
    },
    {
      label: intl.formatMessage(messages.notificationClientMeetingReminder),
      emailFeature: CustomerFeatureType.NotificationEmailClientMeetingReminder,
      smsFeature: CustomerFeatureType.NotificationSmsClientMeetingReminder,
    },
    {
      label: intl.formatMessage(messages.notificationClientMessageReceived),
      emailFeature: CustomerFeatureType.NotificationEmailClientMessageReceived,
      smsFeature: CustomerFeatureType.NotificationSmsClientMessageReceived,
    },
    {
      label: intl.formatMessage(messages.notificationClientUploadedFile),
      emailFeature: CustomerFeatureType.NotificationEmailClientFileUploaded,
      smsFeature: CustomerFeatureType.NotificationSmsClientFileUploaded,
    },
  ];
};

export const FeaturesCaregiverConfig = (): NotificationFeaturesConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.notificationStaffMeetingInvited),
      emailFeature: CustomerFeatureType.NotificationEmailStaffMeetingInvited,
      smsFeature: CustomerFeatureType.NotificationSmsStaffMeetingInvited,
    },
    {
      label: intl.formatMessage(messages.notificationStaffMeetingRescheduled),
      emailFeature:
        CustomerFeatureType.NotificationEmailStaffMeetingRescheduled,
      smsFeature: CustomerFeatureType.NotificationSmsStaffMeetingRescheduled,
    },
    {
      label: intl.formatMessage(messages.notificationStaffMeetingCancelled),
      emailFeature: CustomerFeatureType.NotificationEmailStaffMeetingCancelled,
      smsFeature: CustomerFeatureType.NotificationSmsStaffMeetingCancelled,
    },
    {
      label: intl.formatMessage(messages.notificationStaffMeetingReminder),
      emailFeature: CustomerFeatureType.NotificationEmailStaffMeetingReminder,
      smsFeature: CustomerFeatureType.NotificationSmsStaffMeetingReminder,
    },
    {
      label: intl.formatMessage(messages.notificationStaffMessageReceived),
      emailFeature: CustomerFeatureType.NotificationEmailStaffMessageReceived,
      smsFeature: CustomerFeatureType.NotificationSmsStaffMessageReceived,
    },
    {
      label: intl.formatMessage(messages.notificationStaffUploadedFile),
      emailFeature: CustomerFeatureType.NotificationEmailStaffFileUploaded,
      smsFeature: CustomerFeatureType.NotificationSmsStaffFileUploaded,
    },
  ];
};

export const FeaturesExternalConfig = (): CustomerFeaturesConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.notificationWebdoc),
      feature: CustomerFeatureType.NotificationWebdoc,
    },
    {
      label: intl.formatMessage(messages.notificationTakecare),
      feature: CustomerFeatureType.NotificationTakecare,
    },
    {
      label: intl.formatMessage(messages.notificationCgmj4),
      feature: CustomerFeatureType.NotificationCgmj4,
    },
  ];
};
