/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HsaIdResponse
 */
export interface HsaIdResponse {
    /**
     * The UUID of this user
     * @type {string}
     * @memberof HsaIdResponse
     */
    id: string;
    /**
     * The HSA-ID of this user
     * @type {string}
     * @memberof HsaIdResponse
     */
    hsaId?: string;
}

export function HsaIdResponseFromJSON(json: any): HsaIdResponse {
    return HsaIdResponseFromJSONTyped(json, false);
}

export function HsaIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HsaIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hsaId': !exists(json, 'hsaId') ? undefined : json['hsaId'],
    };
}

export function HsaIdResponseToJSON(value?: HsaIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hsaId': value.hsaId,
    };
}


