import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "todo.title",
    defaultMessage: "Todo list",
    description: "Heading for todo card in meeting session view",
  },
  description: {
    id: "todo.description.inMeeting",
    defaultMessage:
      "The information you create is visible to all participants.",
    description: "Descriptive text shown in todo card",
  },
  createTodoButtonLabel: {
    id: "todo.button.addTodo",
    defaultMessage: "Add",
    description: "Label for button create new todo",
  },
  createTodoDialogTitle: {
    id: "todo.dialog.title",
    defaultMessage: "Create a todo",
    description: "Title for dialog to create new todo",
  },
  error: {
    id: "todo.error",
    defaultMessage: "Something went wrong",
    description: "Error message when fails to load todos",
  },
});
