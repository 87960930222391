import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bookMeetingButton: {
    id: "admin.bookingsview.booking.createButton",
    defaultMessage: "Schedule a meeting",
    description: "label for button to create new meeting",
  },
  searchbarPlaceholder: {
    id: "admin.bookingsview.searchbar.placeholder",
    defaultMessage: "Personal identity number",
    description: "placeholder for searchbar",
  },
  newlyAddedOrUpdatedLabel: {
    id: "admin.bookingsview.newlyAddedOrUpdated.label",
    defaultMessage: "Newly added/updated",
    description: "Label for newly added/updated bookings",
  },
});
