/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyCodeRequest
 */
export interface VerifyCodeRequest {
    /**
     * The session ID
     * @type {string}
     * @memberof VerifyCodeRequest
     */
    sessionId: string;
    /**
     * The user ID
     * @type {string}
     * @memberof VerifyCodeRequest
     */
    userId: string;
    /**
     * Code to verify
     * @type {string}
     * @memberof VerifyCodeRequest
     */
    code: string;
}

export function VerifyCodeRequestFromJSON(json: any): VerifyCodeRequest {
    return VerifyCodeRequestFromJSONTyped(json, false);
}

export function VerifyCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'userId': json['userId'],
        'code': json['code'],
    };
}

export function VerifyCodeRequestToJSON(value?: VerifyCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'userId': value.userId,
        'code': value.code,
    };
}


