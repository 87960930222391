import { defineMessages } from "react-intl";

export const messages = defineMessages({
  revokeLabel: {
    id: "privacyPolicySettings.button.revoke.label",
    defaultMessage: "Revoke consent",
    description: "Label for revoke consent button",
  },
  consentGivenOnLabel: {
    id: "privacyPolicySettings.chip.consentGivenOn.label",
    defaultMessage: "Consent given on",
    description: "Label for chip consent given on",
  },
});
