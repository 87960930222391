import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { messages } from "./UpdateMeetingButton.messages";
import { MeetingStatusType } from "../../../generated/meeting-api";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import { Variant } from "../../BookingsList/Components/Variant/Variant";
import ModifyMeetingDialog from "../../Dialogs/ModifyMeetingDialog/ModifyMeetingDialog";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { Meeting } from "../../../Models/Meeting";

interface Props {
  contextVariant: Variant;
  meeting: Meeting;
  className?: string;
  onChanged?: () => void;
}

const UpdateMeetingButton = (props: Props) => {
  const { meeting, className, onChanged, contextVariant } = props;
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [authenticatedUser] = useAuthenticatedUser();

  const isNotMeetingParticipant: boolean =
    meeting.participants?.filter((participant) => {
      return participant.userId === authenticatedUser.user?.id;
    }).length === 0;

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  if (
    ((contextVariant === Variant.ADMIN_UPCOMING_BOOKINGS &&
      (!isNotMeetingParticipant ||
        authenticatedUser.user?.userAuthorities?.includes(
          UserAuthorityType.BookMeetingOther
        ))) ||
      contextVariant === Variant.UPCOMING_BOOKINGS ||
      contextVariant === Variant.ONGOING_BOOKING_REQUESTS) &&
    meeting.status === MeetingStatusType.Created
  ) {
    return (
      <PermissionController
        allowedRoles={[
          UserRoleType.Staff,
          UserRoleType.External,
          UserRoleType.PlatformAdmin,
        ]}
        atLeastOneOfAuthoritiesStaff={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
          UserAuthorityType.AdminSite,
        ]}
        atLeastOneOfAuthoritiesExternal={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
          UserAuthorityType.AdminSite,
        ]}
        atLeastOneOfAuthoritiesPlatformAdmin={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
          UserAuthorityType.AdminSite,
        ]}
      >
        <ModifyMeetingDialog
          isOpen={isDialogOpen}
          meeting={meeting}
          onClose={() => setIsDialogOpen(false)}
          onUpdated={onChanged}
        />
        <Button
          size="small"
          color="primary"
          className={className}
          variant="outlined"
          onClick={handleOpenDialog}
        >
          {intl.formatMessage(messages.buttonLabel)}
        </Button>
      </PermissionController>
    );
  }

  return null;
};

export default UpdateMeetingButton;
