import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);
