import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    profileContainer: {
      paddingTop: theme.spacing(2),
      padding: "auto",
      textAlign: "center",
    },
    profileContainerPhoneView: {
      display: "flex",
      justifyContent: "center",
    },
    buttonContainerPhoneView: {
      textAlign: "center",
    },
    fullName: {
      marginTop: theme.spacing(3),
    },
    fullNamePhoneView: {
      marginTop: 5,
      marginLeft: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
      width: 180,
    },
  })
);
