import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  fabContainer: {
    display: "flex",
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(8),
  },
  container: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  fab: {
    display: "flex",
    borderColor: theme.palette.info.main,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    borderStyle: "solid",
    borderWidth: 1,
    alignSelf: "flex-end",
    "&:hover": {
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.info.dark,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
}));
