import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import MuiDrawer from "@material-ui/core/Drawer";
import { useIntl } from "react-intl";
import { useStyles } from "./Navigation.style";
import { messages } from "../../AppBar.messages";
import ProfilePicture from "../../../ProfilePicture/ProfilePicture";
import {
  useAuthenticatedUser,
  useLogout,
} from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../../../generated/user-api";
import PermissionController from "../../../PermissionController/PermissionController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import FeatureController from "../../../FeatureController/FeatureController";
import Logo from "../Logo/Logo";
import { RoutePath } from "../../../Routes/RoutePath";
import {
  useCustomerCurrentBooking,
  useCustomerCurrentUpdate,
} from "../../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { MeetingResponse } from "../../../../generated/meeting-api";
import { useCustomerIsAdminPortal } from "../../../../Providers/CustomerProvider/CustomerProvider";

export interface NavConfig {
  title: string;
  to: string;
  showAsActiveFor?: string[];
  requiredFeature?: CustomerFeatureType;
  allowedRoles: UserRoleType[];
}

interface Props {
  navConfigs: NavConfig[];
}

const Navigation = (props: Props) => {
  const { navConfigs } = props;
  const intl = useIntl();
  const history = useHistory();
  const logout = useLogout();
  const classes = useStyles();
  const location = useLocation();
  const isCustomerAdminPortal = useCustomerIsAdminPortal();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setCustomerupdates = useCustomerCurrentUpdate()[1];
  const setCustomerCurrentBooking = useCustomerCurrentBooking()[1];
  const resetRecentList = () => {
    const arrayCopy: MeetingResponse[] = [];
    const arrayCopyUsers: UserResponse[] = [];
    setCustomerCurrentBooking({ MeetingResponse: arrayCopy });
    setCustomerupdates({ userResponse: arrayCopyUsers });
  };
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsMenuOpen(open);
  };

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (id: string) => {
    switch (id) {
      case "profile":
        history.push(RoutePath.ACCOUNT);
        break;
      case "meetingTemplates":
        history.push(RoutePath.MEETING_TEMPLATES);
        break;
      case "adminPortal":
        history.push(RoutePath.PORTAL_CUSTOMERS);
        break;
      case "theme":
        history.push(RoutePath.CUSTOMERS_THEME_SETTINGS);
        break;
      case "logout":
        logout();
        break;
      default:
    }
    handleClose();
  };

  const buildNavButtons = (isPhone: boolean) => {
    if (isCustomerAdminPortal) {
      return null;
    }
    return navConfigs.map((config) => {
      const isActive = config.showAsActiveFor
        ? config.showAsActiveFor.includes(location.pathname)
        : config.to === location.pathname;
      const phoneClass = isActive
        ? classes.navButtonPhoneActive
        : classes.navButtonPhone;
      const desktopClass = isActive
        ? classes.navButtonActive
        : classes.navButton;

      return (
        <PermissionController
          key={config.to}
          allowedRoles={config.allowedRoles}
        >
          <FeatureController requiredFeature={config.requiredFeature}>
            <Button
              to={config.to}
              component={NavLink}
              variant="contained"
              className={isPhone ? phoneClass : desktopClass}
              disableElevation={!isActive}
              onClick={resetRecentList}
            >
              {config.title}
            </Button>
          </FeatureController>
        </PermissionController>
      );
    });
  };

  return (
    <>
      {/* Navigation for bigger devices */}
      <div className={classes.browserView}>{buildNavButtons(false)}</div>

      <IconButton
        edge="end"
        aria-label={intl.formatMessage(messages.accountTitle)}
        onClick={handleProfileMenu}
        color="inherit"
      >
        <ProfilePicture
          center
          firstName={authenticatedUser.user?.firstName}
          lastName={authenticatedUser.user?.lastName}
        />
      </IconButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("profile")}>
          {intl.formatMessage(messages.profileMenuLabel)}
        </MenuItem>
        <PermissionController
          allowedRoles={[UserRoleType.Staff]}
          atLeastOneOfAuthoritiesStaff={[
            UserAuthorityType.BookMeeting,
            UserAuthorityType.AdminSite,
          ]}
        >
          {!isCustomerAdminPortal && (
            <MenuItem onClick={() => handleMenuItemClick("meetingTemplates")}>
              {intl.formatMessage(messages.meetingTemplateMenuLabel)}
            </MenuItem>
          )}
        </PermissionController>
        <MenuItem onClick={() => handleMenuItemClick("logout")}>
          {intl.formatMessage(messages.logoutMenuLabel)}
        </MenuItem>
      </Menu>

      {/* Navigation for smaller devices */}
      {!isCustomerAdminPortal && (
        <div className={classes.phoneView}>
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            color="inherit"
          >
            <MenuIcon color="action" />
          </IconButton>
        </div>
      )}

      <MuiDrawer
        classes={{ paper: classes.drawerPaper }}
        PaperProps={{ color: "primary" }}
        anchor="left"
        open={isMenuOpen}
        onClose={toggleDrawer(false)}
      >
        <div
          className={classes.drawer}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className={classes.logoContainer}>
            <Logo />
          </div>
          {buildNavButtons(true)}
        </div>
      </MuiDrawer>
    </>
  );
};

export default Navigation;
