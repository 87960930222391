import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customerDialogNameFieldLabel: {
    id: "customerfields.name.label",
    defaultMessage: "Name",
    description: "Customer field name label",
  },
  customerDialogDomainFieldLabel: {
    id: "customerfields.domain.label",
    defaultMessage: "Domain",
    description: "Customer field domain label",
  },
  customerDialogOrgNumberFieldLabel: {
    id: "customerfields.orgnumber.label",
    defaultMessage: "Organization number",
    description: "Customer field organization number",
  },
  customerDialogMarketFieldLabel: {
    id: "customerfields.market.label",
    defaultMessage: "Market",
    description: "Customer field market label",
  },
  customerDialogCustomDomainLabel: {
    id: "customerfields.customDomain.label",
    defaultMessage: "Custom domain",
    description: "Customer field custom domain checkbox label",
  },
  customerDialogMarketSwedenLabel: {
    id: "customerfields.select.sweden.label",
    defaultMessage: "Sweden",
    description: "Customer field Sweden select label",
  },
  customerDialogMarketFinlandLabel: {
    id: "customerfields.select.finland.label",
    defaultMessage: "Finland",
    description: "Customer field Finland select label",
  },
});
