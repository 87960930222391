import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    active: {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    listItemTextActive: {
      color: theme.palette.primary.contrastText,
    },
    listItemTextUnread: {
      fontWeight: "bold",
    },
  })
);
