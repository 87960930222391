import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      maxHeight: "100vh",
    },
    dialogRoot: {
      maxHeight: "100vh",
    },
    contentContainer: {
      flex: 1,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      overflow: "auto",
    },
    cardHeading: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
    basicLook: {
      padding: theme.spacing(0),
    },
  })
);
