import React, { useEffect, useState } from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import ProfilePicture, { Sizes } from "../ProfilePicture/ProfilePicture";
import { useStyles } from "./BadgeAvatar.style";
import { MeetingParticipantResponse } from "../../generated/meeting-api";
import ParticipantList from "../Tooltip/ParticipantList/ParticipantList";
import Tooltip from "../Tooltip/Tooltip";
import { createSortedList } from "../../Utils/Meeting";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  size: Sizes;
  center?: boolean;
  firstName?: string;
  lastName?: string;
  participantsForTooltip?: MeetingParticipantResponse[];
  badgeLabel?: number;
}

const BadgeAvatar = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const [sortedList, setSortedList] = useState<MeetingParticipantResponse[]>();
  const {
    size,
    center,
    lastName,
    firstName,
    badgeLabel,
    participantsForTooltip,
  } = props;

  useEffect(() => {
    if (participantsForTooltip) {
      setSortedList(
        createSortedList(participantsForTooltip, authenticatedUser.user)
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tooltip
      title={<ParticipantList participants={sortedList} />}
      placement="bottom"
      hideTooltip={badgeLabel === 0}
    >
      <div className={`${center && classes.center} ${classes.root}`}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={
            badgeLabel ? (
              <Avatar className={classes[size]}>{`+${badgeLabel}`}</Avatar>
            ) : null
          }
        >
          <ProfilePicture
            size={size}
            firstName={firstName}
            lastName={lastName}
          />
        </Badge>
      </div>
    </Tooltip>
  );
};

export default BadgeAvatar;
