import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
    listItem: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    browserView: {
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  })
);
