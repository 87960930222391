import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { messages } from "./VerificationCodeField.messages";
import { useStyles } from "./VerificationCodeField.style";

interface PinProps {
  cellNumber: number;
}

interface Props {
  onSubmit: (code: string) => void;
}

const VerificationCodeField = (props: Props) => {
  const { onSubmit } = props;
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState<string>("");
  const [activeCell, setActiveCell] = useState<number>();
  const intl = useIntl();
  const classes = useStyles();

  const clearCode = () => {
    setCode("");
  };

  const isDisabled = () => {
    return code.trim().length < 6;
  };

  const handleSubmit = () => {
    onSubmit(code);
    clearCode();
    inputRef.current?.focus();
  };

  const handleOnCellClick = () => {
    if (code.length >= 6) {
      setActiveCell(5);
    } else {
      setActiveCell(code.length);
    }
    inputRef.current?.focus();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCode(event.currentTarget.value);
  };

  const handleFocusSwitch = () => {
    if (code.length < 6) {
      setActiveCell(code.length);
    }
  };

  const PinCell = (pinProps: PinProps) => {
    return (
      <div
        className={
          activeCell === pinProps.cellNumber ? classes.activeCell : classes.cell
        }
        style={{ left: `${pinProps.cellNumber * 2}ch` }}
      />
    );
  };

  useEffect(() => {
    handleFocusSwitch();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item>
        <div className={classes.container}>
          <ClickAwayListener onClickAway={() => setActiveCell(6)}>
            <div
              className={classes.pinContainer}
              onClickCapture={handleOnCellClick}
            >
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                ref={inputRef}
                type="text"
                name="box-pin-code"
                className={classes.pinInput}
                inputMode="numeric"
                autoComplete="off"
                maxLength={6}
                value={code}
                onChange={handleChange}
                onFocus={handleFocusSwitch}
              />
              <PinCell cellNumber={0} />
              <PinCell cellNumber={1} />
              <PinCell cellNumber={2} />
              <PinCell cellNumber={3} />
              <PinCell cellNumber={4} />
              <PinCell cellNumber={5} />
            </div>
          </ClickAwayListener>
        </div>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          ref={submitBtnRef}
          variant="contained"
          onClick={handleSubmit}
          disabled={isDisabled()}
        >
          {intl.formatMessage(messages.submitButton)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default VerificationCodeField;
