import React from "react";
import { Typography } from "@material-ui/core";
import { useCustomerContent } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useStyles } from "./WelcomeText.style";

const WelcomeText = () => {
  const classes = useStyles();
  const customerContent = useCustomerContent();

  return (
    <>
      {customerContent.welcomeText?.title && (
        <Typography variant="h5">
          <div className={classes.titleText}>
            {customerContent.welcomeText?.title}
          </div>
        </Typography>
      )}
      {customerContent.welcomeText?.contents && (
        <Typography variant="subtitle1">
          <div className={classes.customerMessage}>
            {customerContent.welcomeText?.contents}
          </div>
        </Typography>
      )}
      {customerContent.noticeText?.contents && (
        <Typography variant="subtitle1">
          <div className={classes.customerMessage}>
            {customerContent.noticeText?.contents}
          </div>
        </Typography>
      )}
    </>
  );
};

export default WelcomeText;
