import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    CLOSED: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    },
    INACTIVE: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
    },
    ACTIVE: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.success.main,
    },
  })
);
