import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleLabel: {
    id: "browserSupportCard.title.label",
    defaultMessage: "Browser compatibility",
    description: "Title label",
  },
  supportedBrowser: {
    id: "browserSupportCard.supportedBrowser.text",
    defaultMessage: "{browserName} is supported ",
    description: "Content text",
  },
  notSupportedBrowser: {
    id: "browserSupportCard.notSupportedBrowser.text",
    defaultMessage:
      "{browserName} is not supported, try to install another browser by following one of the buttons below",
    description: "Content text",
  },
  chromeDownloadLabel: {
    id: "browserSupportCard.download.chrome.label",
    defaultMessage: "Chrome",
    description: "Chrome download button label",
  },
  firefoxDownloadLabel: {
    id: "browserSupportCard.download.firefox.label",
    defaultMessage: "Firefox",
    description: "Firefox download button label",
  },
});
