import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deleteTemplateButton: {
    id: "meetingtemplateitem.item.button.delete",
    defaultMessage: "Delete",
    description: "Delete button label",
  },
  title: {
    id: "meetingtemplateitem.item.title.label",
    defaultMessage: "Title",
    description: "Label for title label",
  },
  meetingType: {
    id: "meetingtemplateitem.item.meetingtype.label",
    defaultMessage: "Template Type",
    description: "Label for template type label",
  },
  meetingDuration: {
    id: "meetingtemplateitem.item.duration.label",
    defaultMessage: "Duration",
    description: "Label for meeting duration label",
  },
  personalTypeLabel: {
    id: "kindmeetingtemplatekind.personal",
    defaultMessage: "PERSONAL",
    description: "Label for personal Type Label",
  },
  publicTypeLabel: {
    id: "kindmeetingtemplatekind.public",
    defaultMessage: "PUBLIC",
    description: "Label for public Type Label",
  },
});
