import { useEffect, useState } from "react";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import {
  MessageResponse,
  MessageUserResponse,
  SortOrderType,
} from "../../../../../../generated/message-api";
import { getMessageCounterPart } from "../../../../../../Utils/Message";
import { messageService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";

export default function useLoadMessages(
  numberOfUnreadMessages: number,
  newReply: number,
  threadId?: string,
  page?: number
) {
  const [loading, setLoading] = useState(true);
  const [counterPart, setCounterPart] = useState<MessageUserResponse>();
  const [messageList, setMessageList] = useState<MessageResponse[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [authenticatedUser] = useAuthenticatedUser();
  const customerId = useCustomerId();

  const markMessagesAsRead = () => {
    if (customerId && threadId) {
      messageService().markMessagesAsRead({
        customerId,
        markMessagesAsReadRequest: {
          includeAllReplyMessages: true,
          messageIds: [threadId],
        },
      });
    }
  };

  // Used to load older messages
  const appendMessages = (messages: MessageResponse[]) => {
    setHasMore(messages.length > 0);
    if (messageList?.length > 0) {
      const index = messages.findIndex(
        (msg) => msg.id === messageList[messageList.length - 1].id
      );
      if (index >= 0) {
        setMessageList([...messageList, ...messages.splice(index + 1)]);
      } else {
        setMessageList([...messageList, ...messages]);
      }
    } else {
      setMessageList(messages);
      markMessagesAsRead();
    }
  };

  // Used to load new messages
  const prependMessages = (messages: MessageResponse[]) => {
    if (messageList?.length > 0) {
      const index = messages.findIndex((msg) => msg.id === messageList[0].id);
      const newMessages = messages.splice(0, index);
      setMessageList([...newMessages, ...messageList]);
      markMessagesAsRead();
    }
  };

  // Handles the call to backend to retrieve messages
  const makeCall = (pageNumber: number, loadNewMessages: boolean) => {
    if (customerId && threadId && page) {
      setLoading(true);
      messageService()
        .listMessages({
          customerId,
          threadId,
          sortOrder: SortOrderType.Descending,
          page: loadNewMessages ? 1 : pageNumber,
          pageSize: 20,
        })
        .then((res) => {
          if (res.data) {
            if (loadNewMessages) {
              prependMessages(res.data);
            } else {
              appendMessages(res.data);
            }
            setLoading(false);
          }
        });
    }
  };

  // When messageList is loaded update counterpart
  useEffect(() => {
    if (messageList.length > 0) {
      setCounterPart(
        getMessageCounterPart(messageList[0], authenticatedUser.user)
      );
    }
  }, [messageList]); // eslint-disable-line react-hooks/exhaustive-deps

  // When new threadId clear all states
  useEffect(() => {
    setMessageList([]);
    setCounterPart(undefined);
    setHasMore(false);
    setLoading(true);
    return () => setMessageList([]);
  }, [threadId]); // eslint-disable-line react-hooks/exhaustive-deps

  // When first call is made we make sure state is updated before calling backend
  useEffect(() => {
    if (messageList.length === 0) {
      makeCall(1, false);
    }
  }, [messageList]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handles trigger of call when page is changed
  useEffect(() => {
    if (page && page !== 1) {
      makeCall(page, false);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (numberOfUnreadMessages > 0) {
      makeCall(1, true);
    }
  }, [numberOfUnreadMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (newReply > 0) {
      makeCall(1, true);
    }
  }, [newReply]); // eslint-disable-line react-hooks/exhaustive-deps

  return { loading, messageList, hasMore, counterPart };
}
