/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingRoleType,
    MeetingRoleTypeFromJSON,
    MeetingRoleTypeFromJSONTyped,
    MeetingRoleTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingParticipantRequest
 */
export interface MeetingParticipantRequest {
    /**
     * UUID of user
     * @type {string}
     * @memberof MeetingParticipantRequest
     */
    userId: string;
    /**
     * 
     * @type {MeetingRoleType}
     * @memberof MeetingParticipantRequest
     */
    meetingRole: MeetingRoleType;
}

export function MeetingParticipantRequestFromJSON(json: any): MeetingParticipantRequest {
    return MeetingParticipantRequestFromJSONTyped(json, false);
}

export function MeetingParticipantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingParticipantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'meetingRole': MeetingRoleTypeFromJSON(json['meetingRole']),
    };
}

export function MeetingParticipantRequestToJSON(value?: MeetingParticipantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'meetingRole': MeetingRoleTypeToJSON(value.meetingRole),
    };
}


