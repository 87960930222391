import { defineMessages } from "react-intl";

export const messages = defineMessages({
  searchbarPlaceholder: {
    id: "admin.clients.searchbar.placeholder",
    defaultMessage: "Personal number",
    description: "place holder for searchbar",
  },
  createButton: {
    id: "admin.clients.button.create.label",
    defaultMessage: "Create",
    description: "Label for create patient button",
  },
  inviteButton: {
    id: "admin.clients.button.invite.label",
    defaultMessage: "Invite",
    description: "Label for create patient button",
  },
  createDialogTitle: {
    id: "admin.clients.dialog.create.title",
    defaultMessage: "Create patient",
    description: "Titel for create new patient dialog",
  },
  inviteDialogTitle: {
    id: "admin.clients.dialog.invite.title",
    defaultMessage: "Invite patient",
    description: "Titel for invite new patient dialog",
  },
  newlyAddedOrUpdatedLabel: {
    id: "admin.clients.newlyAddedOrUpdated.label",
    defaultMessage: "Newly added/updated",
    description: "Label for newly added/updated users",
  },
});
