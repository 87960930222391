import React, { useState, useEffect } from "react";
import {
  MeetingResponse,
  MeetingStatusType,
} from "../../../generated/meeting-api";
import { UserAuthorityType } from "../../../generated/user-api";
import { Variant } from "../../BookingsList/Components/Variant/Variant";
import ModifyMeetingDialog from "../../Dialogs/ModifyMeetingDialog/ModifyMeetingDialog";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  contextVariant: Variant;
  meeting: MeetingResponse;
  onChanged?: () => void;
  switchDialog: boolean;
}

const ChangeMeetingDialog = (props: Props) => {
  const { meeting, onChanged, contextVariant, switchDialog } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [authenticatedUser] = useAuthenticatedUser();
  const isNotMeetingParticipant: boolean =
    meeting.participants?.filter((participant) => {
      return participant.userId === authenticatedUser.user?.id;
    }).length === 0;
  useEffect(() => {
    setIsDialogOpen(true);
  }, [switchDialog]);
  if (
    ((contextVariant === Variant.ADMIN_UPCOMING_BOOKINGS &&
      (!isNotMeetingParticipant ||
        authenticatedUser.user?.userAuthorities?.includes(
          UserAuthorityType.BookMeetingOther
        ))) ||
      contextVariant === Variant.UPCOMING_BOOKINGS ||
      contextVariant === Variant.ONGOING_BOOKING_REQUESTS) &&
    meeting.status === MeetingStatusType.Created
  ) {
    return (
      <ModifyMeetingDialog
        isOpen={isDialogOpen}
        meeting={meeting}
        onClose={() => setIsDialogOpen(false)}
        onUpdated={onChanged}
      />
    );
  }

  return null;
};

export default ChangeMeetingDialog;
