import { PersonNumberResponse } from "../generated/user-api";
import { Contact, PaginatedContactList } from "../Models/Contact";
import { PaginatedMessageThreadList } from "../Models/Message";
import {
  Meeting,
  MeetingSession,
  PaginatedMeetingList,
} from "../Models/Meeting";
import { PaginatedUserList, User } from "../Models/User";

export const decoratePaginatedContactListWithPersonNumber = (
  paginatedContacts: PaginatedContactList,
  pIds: PersonNumberResponse[]
): PaginatedContactList => {
  paginatedContacts.data?.forEach((contact) => {
    // eslint-disable-next-line no-param-reassign
    contact.personNumber = pIds.find(
      (pId) => pId.id === contact.userId
    )?.personNumber;
  });

  return paginatedContacts;
};

export const decorateContactWithPersonNumber = (
  contact: Contact,
  pIds: PersonNumberResponse[]
): Contact => {
  // eslint-disable-next-line no-param-reassign
  contact.personNumber = pIds.find(
    (pId) => pId.id === contact.userId
  )?.personNumber;

  return contact;
};

export const decorateUserListWithPersonNumber = (
  users: User[],
  pIds: PersonNumberResponse[]
): User[] => {
  users.forEach((user) => {
    // eslint-disable-next-line no-param-reassign
    user.personNumber = pIds.find((pId) => pId.id === user.id)?.personNumber;
  });

  return users;
};

export const decorateUserWithPersonNumber = (
  user: User,
  pId?: string
): User => {
  // eslint-disable-next-line no-param-reassign
  user.personNumber = pId;
  return user;
};

export const decoratePaginatedUserListWithPersonNumber = (
  paginatedUsers: PaginatedUserList,
  pIds: PersonNumberResponse[]
): PaginatedUserList => {
  paginatedUsers.data?.forEach((user) => {
    // eslint-disable-next-line no-param-reassign
    user.personNumber = pIds.find((pId) => pId.id === user.id)?.personNumber;
  });

  return paginatedUsers;
};

export const decoratePaginatedMessageThreadListWithPersonNumber = (
  paginatedMessageThreads: PaginatedMessageThreadList,
  pIds: PersonNumberResponse[]
): PaginatedMessageThreadList => {
  paginatedMessageThreads.data?.forEach((thread) => {
    // eslint-disable-next-line no-param-reassign
    thread.sender.personNumber = pIds.find(
      (pId) => pId.id === thread.sender.id
    )?.personNumber;

    // eslint-disable-next-line no-param-reassign
    thread.recipient.personNumber = pIds.find(
      (pId) => pId.id === thread.recipient.id
    )?.personNumber;
  });

  return paginatedMessageThreads;
};

export const decoratePaginatedMeetingListWithPersonNumber = (
  paginatedMeetingList: PaginatedMeetingList,
  pIds: PersonNumberResponse[]
): PaginatedMeetingList => {
  paginatedMeetingList.data?.forEach((meeting) => {
    meeting.participants?.forEach((p) => {
      // eslint-disable-next-line no-param-reassign
      p.personNumber = pIds.find((pId) => pId.id === p.userId)?.personNumber;
    });
  });

  return paginatedMeetingList;
};

export const decorateMeetingWithPersonNumber = (
  meeting: Meeting,
  pIds: PersonNumberResponse[]
): Meeting => {
  meeting.participants?.forEach((p) => {
    // eslint-disable-next-line no-param-reassign
    p.personNumber = pIds.find((pId) => pId.id === p.userId)?.personNumber;
  });

  return meeting;
};

export const decorateMeetingSessionWithPersonNumber = (
  meetingSession: MeetingSession,
  pIds: PersonNumberResponse[]
): MeetingSession => {
  meetingSession.meeting.participants?.forEach((participant) => {
    // eslint-disable-next-line no-param-reassign
    participant.personNumber = pIds.find(
      (pId) => pId.id === participant.userId
    )?.personNumber;
  });

  return meetingSession;
};
