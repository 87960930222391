import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonTooltip: {
    id: "button.fileAction.tooltip",
    defaultMessage: "More",
    description: "tooltip for file action button",
  },
  generalError: {
    id: "button.fileAction.error.general",
    defaultMessage: "Something went wrong",
    description: "General error",
  },
  deleteSuccess: {
    id: "button.fileAction.delete.success",
    defaultMessage: "File successfully deleted",
    description: "Success message when delete file",
  },
  downloadLabel: {
    id: "button.fileAction.download.label",
    defaultMessage: "Download",
    description: "option label",
  },
  deleteLabel: {
    id: "button.fileAction.delete.label",
    defaultMessage: "Delete",
    description: "option label",
  },
  openInNewTabLabel: {
    id: "button.fileAction.openInNewTab.label",
    defaultMessage: "Open in new tab",
    description: "option label",
  },
  dialogTitle: {
    id: "button.fileAction.delete.dialog.title",
    defaultMessage: "Delete",
    description: "submit delete label",
  },
  dialogContent: {
    id: "button.fileAction.delete.dialog.content",
    defaultMessage: "Are you sure you want to delete this file?",
    description: "submit delete label",
  },
  submitDelete: {
    id: "button.fileAction.delete.dialog.submit.label",
    defaultMessage: "Continue",
    description: "submit delete label",
  },
  cancelDelete: {
    id: "button.fileAction.delete.dialog.cancel.label",
    defaultMessage: "Cancel",
    description: "cancel delete label",
  },
});
