/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of customer status
 * @export
 * @enum {string}
 */
export enum CustomerStatusType {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Closed = 'CLOSED'
}

export function CustomerStatusTypeFromJSON(json: any): CustomerStatusType {
    return CustomerStatusTypeFromJSONTyped(json, false);
}

export function CustomerStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerStatusType {
    return json as CustomerStatusType;
}

export function CustomerStatusTypeToJSON(value?: CustomerStatusType | null): any {
    return value as any;
}

