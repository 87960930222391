import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sithsButton: {
    id: "login.siths.button.label",
    defaultMessage: "Login - SITHS",
    description: "Label for button to login with SITHS",
  },
  submitButtonLabel: {
    id: "login.siths.button.submit.label",
    defaultMessage: "Login",
    description: "Submit label for login",
  },
  cancelButtonLabel: {
    id: "login.siths.button.cancel.label",
    defaultMessage: "Cancel",
    description: "Cancel label for login",
  },
  hsaId: {
    id: "login.siths.input.hsaId.label",
    defaultMessage: "HsaId",
    description: "Label for textfield hsaId in login form",
  },
  generalError: {
    id: "login.siths.error.general",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when login failed due to general error",
  },
});
