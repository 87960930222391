import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "center",
    },
    buttonContainer: {
      marginTop: theme.spacing(1),
    },
    loginUsernameButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      justifyContent: "center",
      marginTop: theme.spacing(1),
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  })
);
