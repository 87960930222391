import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleLabel: {
    id: "waitingLobby.tip1.title.label",
    defaultMessage: "Förberedelser",
    description: "Title label",
  },
  descriptionItem1: {
    id: "waitingLobby.tip1.description.item.1.label",
    defaultMessage: "Bra och stabil internetuppkoppling",
    description: "description item 1",
  },
  descriptionItem2: {
    id: "waitingLobby.tip1.description.item.2.label",
    defaultMessage: "Ostörd miljö, gärna framför ljuskälla",
    description: "description item 2",
  },
  descriptionItem3: {
    id: "waitingLobby.tip1.description.item.3.label",
    defaultMessage: "Sätt kamera i nivå med dina ögon",
    description: "description item 3",
  },
});
