import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginLeft: theme.spacing(1),
      cursor: "pointer",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: theme.palette.grey["400"],
      },
    },
  })
);
