import React from "react";
import { Menu } from "@material-ui/core";
import { useIntl } from "react-intl";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "../../Tooltip/Tooltip";
import { useStyles } from "./ContactActionButton.style";
import { messages } from "./ContactActionButton.messages";
import DeleteAction from "./Components/DeleteAction/DeleteAction";
import { Contact } from "../../../Models/Contact";

interface Props {
  contact: Contact;
  onChanged?: () => void;
}

const ContactActionButton = (props: Props) => {
  const { contact, onChanged } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChanged = () => {
    if (onChanged) {
      onChanged();
    }
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title={intl.formatMessage(messages.tooltip)}>
        <MoreVertIcon
          className={classes.icon}
          fontSize="default"
          onClick={handleClick}
        />
      </Tooltip>
      <Menu
        id="file-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <DeleteAction
          contactId={contact.contactId}
          onDeleted={handleOnChanged}
          onClosed={handleClose}
        />
      </Menu>
    </>
  );
};

export default ContactActionButton;
