import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";

interface Props {
  headerText?: string;
  primaryText?: string;
  secondaryText?: string;
  display?: "initial" | "block" | "inline";
}

const BookingsListItemText = (props: Props) => {
  const { primaryText, secondaryText, headerText, display } = props;

  return (
    <>
      <Typography
        style={{ fontWeight: "bold" }}
        variant="caption"
        color="textSecondary"
        display={display}
      >
        {headerText}
      </Typography>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        primaryTypographyProps={{
          style: { marginInlineEnd: 10 },
          display,
          variant: "body2",
          color: "textPrimary",
        }}
        secondaryTypographyProps={{
          display,
          variant: "body2",
          color: "textSecondary",
        }}
      />
    </>
  );
};

export default BookingsListItemText;
