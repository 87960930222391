import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "80vh",
      textAlign: "center",
      paddingTop: "30vh",
    },
  })
);
