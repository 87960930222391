import { defineMessages } from "react-intl";

export const messages = defineMessages({
  chatPlaceholder: {
    id: "chat.textfield",
    defaultMessage: "Write a new message",
    description: "Placeholder for textfield to send new chat",
  },
  sendChat: {
    id: "chat.sendButton",
    defaultMessage: "Send",
    description: "Label for button to send chat message",
  },
  generalError: {
    id: "chat.error.could.not.be.sent",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to create chat message",
  },
});
