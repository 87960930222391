import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleLabel: {
    id: "previewAudioCard.title.label",
    defaultMessage: "Audio",
    description: "Title label in preview room",
  },
  content: {
    id: "previewAudioCard.content.text",
    defaultMessage:
      "Try to talk into your microphone. When you do, the dark area located in the top right of your video screen should become green and react with the volume of your voice.",
    description: "Content text",
  },
  errorContent: {
    id: "previewAudioCard.content.error.text",
    defaultMessage: "Microphone permission needs to be granted",
    description: "Error content text",
  },
  errorNoDeviceFound: {
    id: "previewAudioCard.content.error.noDevice.text",
    defaultMessage: "No audio device could be found.",
    description: "Error content text when no audio device found",
  },
  audioSourceLabel: {
    id: "previewAudioCard.field.audioSource.label",
    defaultMessage: "Audio Source",
    description: "Label for audio source field",
  },
  showHowButtonLabel: {
    id: "previewAudioCard.button.showHow.label",
    defaultMessage: "Show how",
    description: "Label show how button",
  },
});
