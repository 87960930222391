import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(5),
      textAlign: "center",
    },
    linkButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,
      marginLeft: theme.spacing(3),
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    boldText: {
      fontWeight: "bold",
    },
  })
);
