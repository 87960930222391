import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    noResultText: {
      width: "100%",
      minHeight: 200,
      textAlign: "center",
      paddingTop: 70,
    },
  })
);
