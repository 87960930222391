/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Template API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateMeetingTemplateRequest,
    CreateMeetingTemplateRequestFromJSON,
    CreateMeetingTemplateRequestToJSON,
    MeetingTemplateKindType,
    MeetingTemplateKindTypeFromJSON,
    MeetingTemplateKindTypeToJSON,
    MeetingTemplateResponse,
    MeetingTemplateResponseFromJSON,
    MeetingTemplateResponseToJSON,
    UpdateMeetingTemplateRequest,
    UpdateMeetingTemplateRequestFromJSON,
    UpdateMeetingTemplateRequestToJSON,
} from '../models';

export interface CreateMeetingTemplateOperationRequest {
    customerId: string;
    createMeetingTemplateRequest: CreateMeetingTemplateRequest;
}

export interface DeleteMeetingTemplateRequest {
    customerId: string;
    templateId: string;
}

export interface GetMeetingTemplateRequest {
    customerId: string;
    templateId: string;
}

export interface ListMeetingTemplatesRequest {
    customerId: string;
    templateKind?: MeetingTemplateKindType;
}

export interface UpdateMeetingTemplateOperationRequest {
    customerId: string;
    templateId: string;
    updateMeetingTemplateRequest: UpdateMeetingTemplateRequest;
}

/**
 * 
 */
export class MeetingtemplateApi extends runtime.BaseAPI {

    /**
     * Create a new meeting template
     * Create meeting template
     */
    async createMeetingTemplateRaw(requestParameters: CreateMeetingTemplateOperationRequest): Promise<runtime.ApiResponse<MeetingTemplateResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createMeetingTemplate.');
        }

        if (requestParameters.createMeetingTemplateRequest === null || requestParameters.createMeetingTemplateRequest === undefined) {
            throw new runtime.RequiredError('createMeetingTemplateRequest','Required parameter requestParameters.createMeetingTemplateRequest was null or undefined when calling createMeetingTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meetingtemplate/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMeetingTemplateRequestToJSON(requestParameters.createMeetingTemplateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Create a new meeting template
     * Create meeting template
     */
    async createMeetingTemplate(requestParameters: CreateMeetingTemplateOperationRequest): Promise<MeetingTemplateResponse> {
        const response = await this.createMeetingTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a meeting template. The user must be the one owning the template.
     * Delete meeting template
     */
    async deleteMeetingTemplateRaw(requestParameters: DeleteMeetingTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteMeetingTemplate.');
        }

        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling deleteMeetingTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meetingtemplate/{customerId}/{templateId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a meeting template. The user must be the one owning the template.
     * Delete meeting template
     */
    async deleteMeetingTemplate(requestParameters: DeleteMeetingTemplateRequest): Promise<void> {
        await this.deleteMeetingTemplateRaw(requestParameters);
    }

    /**
     * Get a meeting template by id.
     * Get meeting template
     */
    async getMeetingTemplateRaw(requestParameters: GetMeetingTemplateRequest): Promise<runtime.ApiResponse<MeetingTemplateResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMeetingTemplate.');
        }

        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling getMeetingTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meetingtemplate/{customerId}/{templateId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Get a meeting template by id.
     * Get meeting template
     */
    async getMeetingTemplate(requestParameters: GetMeetingTemplateRequest): Promise<MeetingTemplateResponse> {
        const response = await this.getMeetingTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * List meeting templates belonging to a customer.
     * List meeting templates
     */
    async listMeetingTemplatesRaw(requestParameters: ListMeetingTemplatesRequest): Promise<runtime.ApiResponse<Array<MeetingTemplateResponse>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listMeetingTemplates.');
        }

        const queryParameters: any = {};

        if (requestParameters.templateKind !== undefined) {
            queryParameters['templateKind'] = requestParameters.templateKind;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meetingtemplate/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingTemplateResponseFromJSON));
    }

    /**
     * List meeting templates belonging to a customer.
     * List meeting templates
     */
    async listMeetingTemplates(requestParameters: ListMeetingTemplatesRequest): Promise<Array<MeetingTemplateResponse>> {
        const response = await this.listMeetingTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an existing meeting template. User must be STAFF and in case of a personal template, the user must be the one owning the template.
     * Update meeting template
     */
    async updateMeetingTemplateRaw(requestParameters: UpdateMeetingTemplateOperationRequest): Promise<runtime.ApiResponse<MeetingTemplateResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateMeetingTemplate.');
        }

        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling updateMeetingTemplate.');
        }

        if (requestParameters.updateMeetingTemplateRequest === null || requestParameters.updateMeetingTemplateRequest === undefined) {
            throw new runtime.RequiredError('updateMeetingTemplateRequest','Required parameter requestParameters.updateMeetingTemplateRequest was null or undefined when calling updateMeetingTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meetingtemplate/{customerId}/{templateId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeetingTemplateRequestToJSON(requestParameters.updateMeetingTemplateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Update an existing meeting template. User must be STAFF and in case of a personal template, the user must be the one owning the template.
     * Update meeting template
     */
    async updateMeetingTemplate(requestParameters: UpdateMeetingTemplateOperationRequest): Promise<MeetingTemplateResponse> {
        const response = await this.updateMeetingTemplateRaw(requestParameters);
        return await response.value();
    }

}
