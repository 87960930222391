import React from "react";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./BookingsList.style";
import { messages } from "./BookingsList.messages";
import { MeetingResponse } from "../../generated/meeting-api";
import { Variant } from "./Components/Variant/Variant";
import BookingsListItem from "./Components/BookingsListItem/BookingsListItem";

interface Props {
  variant: Variant;
  bookingsList?: MeetingResponse[];
  reloadBookings?: () => void;
}

const BookingsList = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { bookingsList, variant, reloadBookings } = props;

  if (!bookingsList || bookingsList.length === 0) {
    return (
      <Typography
        className={classes.noResultText}
        variant="h4"
        color="textSecondary"
      >
        {intl.formatMessage(messages.noResultsText)}
      </Typography>
    );
  }

  return (
    <>
      <List
        className={
          variant === Variant.ADMIN_NEWLY_ADDED
            ? classes.rootNewlyAdded
            : classes.root
        }
      >
        {bookingsList.map(
          (booking, index) =>
            booking && (
              <BookingsListItem
                key={booking.id}
                variant={variant}
                meeting={booking}
                reloadBookings={reloadBookings}
                noDivider={bookingsList?.length === index + 1}
              />
            )
        )}
      </List>
    </>
  );
};

export default BookingsList;
