import React, { useState } from "react";
import { Button } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useIntl } from "react-intl";
import CreateMessageDialog from "../../Dialogs/CreateMessageDialog/CreateMessageDialog";
import { messages } from "./CreateMessageButton.messages";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import { User } from "../../../Models/User";
import { hasFeatureEnabled } from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { useCustomerFeatures } from "../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  className?: string;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  onCreated?: () => void;
  label?: string;
  recipients: User[];
}

const CreateMessageButton = (props: Props) => {
  const intl = useIntl();
  const customerFeatures = useCustomerFeatures();
  const { label, onCreated, recipients, variant, size, className } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const ButtonModule = () => (
    <>
      <CreateMessageDialog
        prePopulatedRecipients={recipients}
        isOpen={isDialogOpen}
        onCreated={onCreated}
        onClose={() => setIsDialogOpen(false)}
      />
      <Button
        className={className}
        size={size || "small"}
        color="primary"
        variant={variant || "contained"}
        startIcon={<SendIcon />}
        onClick={() => setIsDialogOpen(true)}
      >
        {label || intl.formatMessage(messages.label)}
      </Button>
    </>
  );

  return (
    <PermissionController
      requiredAuthoritiesStaff={[UserAuthorityType.SendMessage]}
      requiredAuthoritiesClient={[UserAuthorityType.SendMessage]}
      requiredAuthoritiesExternal={[UserAuthorityType.SendMessage]}
    >
      {hasFeatureEnabled(
        CustomerFeatureType.OneWayMessage,
        customerFeatures
      ) ? (
        <PermissionController allowedRoles={[UserRoleType.Staff]}>
          <ButtonModule />
        </PermissionController>
      ) : (
        <ButtonModule />
      )}
    </PermissionController>
  );
};

export default CreateMessageButton;
