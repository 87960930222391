import { defineMessages } from "react-intl";

export const messages = defineMessages({
  chromeDesktopTabTitle: {
    id: "dialog.allowMediaGuide.tab.chrome.desktop.title",
    defaultMessage: "Chrome desktop",
    description: "tab title for chrome desktop guide",
  },
  safariDesktopTabTitle: {
    id: "dialog.allowMediaGuide.tab.safari.desktop.title",
    defaultMessage: "Safari desktop",
    description: "tab title for safari desktop guide",
  },
  chromePhoneTabTitle: {
    id: "dialog.allowMediaGuide.tab.chrome.phone.title",
    defaultMessage: "Chrome phone",
    description: "tab title for chrome phone guide",
  },
  safariPhoneTabTitle: {
    id: "dialog.allowMediaGuide.tab.safari.phone.title",
    defaultMessage: "Safari phone",
    description: "tab title for safari phone guide",
  },
});
