import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      margin: 0,
      padding: 0,
    },
  })
);
