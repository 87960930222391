import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import {
  SortByType,
  SortOrderType,
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../generated/user-api";
import { hasPermission } from "../../Components/PermissionController/PermissionController";
import UserList from "../../Components/UserList/UserList";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { useCustomerCurrentUpdate } from "../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import CustomPagination from "../../Components/CustomPagination/Pagination";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { messages } from "./AdminPatients.messages";
import { useStyles } from "./AdminPatients.style";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import CreateUserButton from "../../Components/Buttons/CreateUserButton/CreateUserButton";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { userService } from "../../Providers/ServiceProvider/ServiceProvider";
import { PaginatedUserList } from "../../Models/User";
import InviteUserButton from "../../Components/Buttons/InviteUserButton/InviteUserButton";
import { Variant } from "../../Components/UserList/Components/Variant/Variant";

const AdminPatients = () => {
  const intl = useIntl();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const classes = useStyles();
  const [result, setResult] = useState<PaginatedUserList>();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>();
  const setSpinner = useSpinner()[1];
  const [isUserListLoading, setIsUserListLoading] = useState<boolean>(false);
  const [customerupdates] = useCustomerCurrentUpdate();
  const isLoading = (): boolean => {
    return isUserListLoading;
  };

  const loadUserList = () => {
    if (customerId && query && query.length > 9) {
      const formatedQuery = query.replace("-", "").replace(" ", "");
      setIsUserListLoading(true);
      userService()
        .listUsersByCustomerAndSearchParams({
          personNumber: formatedQuery,
          pageSize,
          page,
          customerId,
          userRole: UserRoleType.Client,
          sortBy: SortByType.LastName,
          sortOrder: SortOrderType.Ascending,
        })
        .then((res) => setResult(res))
        .finally(() => setIsUserListLoading(false));
    }
  };
  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.AdminSite],
  });

  useEffect(() => {
    loadUserList();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page !== 1) {
      // Reset pagination when search is triggered
      setPage(1);
    } else {
      loadUserList();
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  return (
    <>
      <div className={classes.actionContainer}>
        {customerId && (
          <CreateUserButton
            onCreated={loadUserList}
            userRole={UserRoleType.Client}
            label={intl.formatMessage(messages.createButton)}
            dialogTitle={intl.formatMessage(messages.createDialogTitle)}
            customerId={customerId}
          />
        )}
        <InviteUserButton
          onCreated={loadUserList}
          userRole={UserRoleType.Client}
          label={intl.formatMessage(messages.inviteButton)}
          dialogTitle={intl.formatMessage(messages.inviteDialogTitle)}
        />
      </div>
      {customerupdates &&
        customerupdates.userResponse.filter((element: UserResponse) => {
          return element.userRole === UserRoleType.Client;
        })?.length > 0 && (
          <div className={classes.newlyAddedContainer}>
            <UserList
              variant={Variant.ADMIN_NEWLY_ADDED}
              userList={customerupdates.userResponse.filter(
                (element: UserResponse) => {
                  return element.userRole === UserRoleType.Client;
                }
              )}
              onUpdated={loadUserList}
            />
          </div>
        )}
      <SearchBar
        minQueryLength={10}
        placeholder={intl.formatMessage(messages.searchbarPlaceholder)}
        onSubmit={(newQuery) => setQuery(newQuery)}
      />
      <UserList userList={result?.data} onUpdated={loadUserList} />
      <CustomPagination onChange={setPage} pagination={result?.pagination} />
    </>
  );
};

export default AdminPatients;
