/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerThemeRequest,
    CustomerThemeRequestFromJSON,
    CustomerThemeRequestFromJSONTyped,
    CustomerThemeRequestToJSON,
    CustomerThemeResponseAllOf,
    CustomerThemeResponseAllOfFromJSON,
    CustomerThemeResponseAllOfFromJSONTyped,
    CustomerThemeResponseAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerThemeResponse
 */
export interface CustomerThemeResponse {
    /**
     * UUID of theme
     * @type {string}
     * @memberof CustomerThemeResponse
     */
    id: string;
    /**
     * Theme primary color
     * @type {string}
     * @memberof CustomerThemeResponse
     */
    primaryColor?: string;
    /**
     * Theme secondary color
     * @type {string}
     * @memberof CustomerThemeResponse
     */
    secondaryColor?: string;
    /**
     * Base64-encoded contents of logo
     * @type {string}
     * @memberof CustomerThemeResponse
     */
    logo?: string;
    /**
     * Base64-encoded contents of favicon
     * @type {string}
     * @memberof CustomerThemeResponse
     */
    favicon?: string;
    /**
     * Date and time this theme was created
     * @type {Date}
     * @memberof CustomerThemeResponse
     */
    createdOn: Date;
    /**
     * Date and time this theme was last updated
     * @type {Date}
     * @memberof CustomerThemeResponse
     */
    updatedOn?: Date;
}

export function CustomerThemeResponseFromJSON(json: any): CustomerThemeResponse {
    return CustomerThemeResponseFromJSONTyped(json, false);
}

export function CustomerThemeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerThemeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'primaryColor': !exists(json, 'primaryColor') ? undefined : json['primaryColor'],
        'secondaryColor': !exists(json, 'secondaryColor') ? undefined : json['secondaryColor'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'favicon': !exists(json, 'favicon') ? undefined : json['favicon'],
        'createdOn': (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
    };
}

export function CustomerThemeResponseToJSON(value?: CustomerThemeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'primaryColor': value.primaryColor,
        'secondaryColor': value.secondaryColor,
        'logo': value.logo,
        'favicon': value.favicon,
        'createdOn': (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
    };
}


