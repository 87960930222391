import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      position: "relative",
    },
    dialogRoot: {
      maxHeight: "100vh",
    },
    cardHeading: {
      marginLeft: "auto",
      marginRight: "auto",
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
    cardHeadingSx: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
    buttonEnd: {
      paddingTop: theme.spacing(1),
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginBottom: theme.spacing(1),
    },
    icon: {
      margin: theme.spacing(0, 0.5),
    },
    accordionSummary: {
      backgroundColor: theme.palette.primary.dark,
      maxHeight: theme.spacing(3),
      minHeight: theme.spacing(3),
    },
    req: {
      height: 80,
      overflowY: "scroll",
    },
  })
);
