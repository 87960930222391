/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User Status
 * @export
 * @enum {string}
 */
export enum UserStatusType {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Closed = 'CLOSED'
}

export function UserStatusTypeFromJSON(json: any): UserStatusType {
    return UserStatusTypeFromJSONTyped(json, false);
}

export function UserStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusType {
    return json as UserStatusType;
}

export function UserStatusTypeToJSON(value?: UserStatusType | null): any {
    return value as any;
}

