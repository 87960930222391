import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Divider, Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useIntl } from "react-intl";
import { messages } from "./ChromePhoneGuide.messages";
import ChromePhone1 from "../../../../../Assets/chrome-phone-1.jpg";
import ChromePhone2 from "../../../../../Assets/chrome-phone-2.jpg";
import ChromePhone3 from "../../../../../Assets/chrome-phone-3.jpg";
import ChromePhone4 from "../../../../../Assets/chrome-phone-4.jpg";
import ChromePhoneIos1 from "../../../../../Assets/chrome-phone-apple-1.png";
import ChromePhoneIos2 from "../../../../../Assets/chrome-phone-apple-2.png";
import ChromePhoneIos3 from "../../../../../Assets/chrome-phone-apple-3.png";
import { useStyles } from "./ChromePhoneGuide.style";

const ChromePhoneGuide = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle)}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">
          {intl.formatMessage(messages.iosTitle)}
        </Typography>
        <Typography variant="h6">
          <ol>
            <li>{intl.formatMessage(messages.step1Ios)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhoneIos1}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step2Ios)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhoneIos2}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step3Ios)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhoneIos3}
              alt={intl.formatMessage(messages.imgAltText)}
            />
          </ol>
        </Typography>
        <Divider />
        <Typography variant="h4">
          {intl.formatMessage(messages.androidTitle)}
        </Typography>
        <Typography variant="h6">
          <ol>
            <li>{intl.formatMessage(messages.step1)}</li>
            <li>{intl.formatMessage(messages.step2)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhone1}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step3)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhone2}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step4)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhone3}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step5)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={ChromePhone4}
              alt={intl.formatMessage(messages.imgAltText)}
            />
          </ol>
        </Typography>
      </DialogContent>
    </>
  );
};

export default ChromePhoneGuide;
