import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createButton: {
    id: "messagesview.button.create.label",
    defaultMessage: "Create new message",
    description: "button label for create message",
  },
  searchLabel: {
    id: "messagesview.searchbar.placeholder",
    defaultMessage: "Search",
    description: "Searchbar placeholder",
  },
});
