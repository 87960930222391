import { defineMessages } from "react-intl";

export const messages = defineMessages({
  meetingTemplatesTitle: {
    id: "meetingtemplates.title.label",
    defaultMessage: "Booking Templates",
    description: "Title of booking templates",
  },
  createNewtemplate: {
    id: "meetingtemplate.create.label",
    defaultMessage: "Create Booking Template",
    description: "Button to create a booking template",
  },
});
