import React, { useState } from "react";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useIntl } from "react-intl";
import {
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../../generated/user-api";
import PermissionController from "../../PermissionController/PermissionController";
import { messages } from "./UpdateUserButton.messages";
import ModifyUserDialog, {
  Tab,
} from "../../UserForm/ModifyUser/ModifyUserDialog";

interface Props {
  className?: string;
  onUpdated?: (user: UserResponse) => void;
  userToUpdate: UserResponse;
  visibleTabs: Tab[];
}

const UpdateUserButton = (props: Props) => {
  const intl = useIntl();
  const { onUpdated, userToUpdate, className, visibleTabs } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const content = () => {
    return (
      <>
        <ModifyUserDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          userId={userToUpdate.id}
          onUpdated={onUpdated}
          visibleTabs={visibleTabs}
        />
        <Button
          className={className}
          size="small"
          color="primary"
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => setIsDialogOpen(true)}
        >
          {intl.formatMessage(messages.buttonLabel)}
        </Button>
      </>
    );
  };
  return (
    <>
      {/* Authenticated user is PlatformAdmin  */}
      <PermissionController allowedRoles={[UserRoleType.PlatformAdmin]}>
        <PermissionController
          overrideCurrentUserRole={userToUpdate.userRole}
          allowedRoles={[UserRoleType.Staff, UserRoleType.PlatformAdmin]}
          requiredAuthoritiesPlatformAdmin={[
            UserAuthorityType.CreateUserPlatformAdmin,
          ]}
        >
          {content()}
        </PermissionController>
      </PermissionController>

      {/* Authenticated user is Staff  */}
      <PermissionController allowedRoles={[UserRoleType.Staff]}>
        <PermissionController
          overrideCurrentUserRole={userToUpdate.userRole}
          requiredAuthoritiesClient={[UserAuthorityType.CreateUserClient]}
          requiredAuthoritiesExternal={[UserAuthorityType.CreateUserClient]}
          requiredAuthoritiesStaff={[UserAuthorityType.CreateUserStaff]}
        >
          {content()}
        </PermissionController>
      </PermissionController>
    </>
  );
};

export default UpdateUserButton;
