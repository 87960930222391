import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "@material-ui/lab";
import { FormikProps } from "formik";
import { useIntl } from "react-intl";
import { messages } from "./ParticipantWarning.messages";
import { useStyles } from "./ParticipantWarning.style";

interface Props {
  formik: FormikProps<any>;
}

const ParticipantWarning = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik } = props;
  const [
    isMinimumRequiredParticipants,
    setIsMinimumRequiredParticipants,
  ] = useState(true);

  const checkParticipants = (): void => {
    const totalParticipants = [
      ...formik.values.staffParticipants,
      ...formik.values.externalParticipants,
      ...formik.values.participants,
    ];

    setIsMinimumRequiredParticipants(
      totalParticipants.length >= 2 &&
        formik.values.staffParticipants.length >= 1
    );

    // setIsMinimumRequiredParticipants(!isMinimumRequiredParticipants);
  };

  useEffect(() => {
    checkParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.staffParticipants,
    formik.values.externalParticipants,
    formik.values.participants,
  ]);

  useEffect(() => {
    if (formik.errors.participants) {
      formik.setFieldError("externalParticipants", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors]);

  if (
    isMinimumRequiredParticipants ||
    (!formik.errors.participants && !formik.errors.staffParticipants)
  ) {
    return null;
  }

  return (
    <>
      <Alert severity="error" className={classes.container}>
        {intl.formatMessage(messages.warningMessage)}
      </Alert>
    </>
  );
};

export default ParticipantWarning;
