import { defineMessages } from "react-intl";

export const messages = defineMessages({
  submitButton: {
    id: "dashboardview.dialog.updateAndNotifyUser.submit.label",
    defaultMessage: "Save and notify user",
    description: "submit button label",
  },
  success: {
    id: "dashboardview.dialog.updateAndNotifyUser.success.message",
    defaultMessage: "Triggered notifications for {numberOfBookings} bookings",
    description: "success message",
  },
});
