import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import { Lock, LockOpen } from "@material-ui/icons";
import { Typography } from "@mui/material";

interface Props {
  disabled?: boolean;
  lockedValue: string;
  loadLockedData?: () => Promise<string>;
  stopPropagation?: boolean;
}

const LockedTypography = (props: Props) => {
  const [value, setValue] = useState<string>();
  const [isLocked, setIsLocked] = useState<boolean>(true);

  const { lockedValue, loadLockedData, disabled, stopPropagation } = props;

  const handleLockedTypography = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLocked(!isLocked);
    if (stopPropagation) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    if (!isLocked && loadLockedData) {
      loadLockedData().then(setValue);
    }
  }, [isLocked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={handleLockedTypography}
    >
      {isLocked ? <Lock fontSize="inherit" /> : <LockOpen fontSize="inherit" />}
      <Typography variant="body2" color="textSecondary">
        {isLocked ? lockedValue : value}
      </Typography>
    </IconButton>
  );
};

export default LockedTypography;
