/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationStartResponse
 */
export interface AuthenticationStartResponse {
    /**
     * Session id generated by external IDP
     * @type {string}
     * @memberof AuthenticationStartResponse
     */
    sessionId: string;
    /**
     * An URL to the IDP to redirect to using the sessionId as parameter
     * @type {string}
     * @memberof AuthenticationStartResponse
     */
    redirectUrl: string;
}

export function AuthenticationStartResponseFromJSON(json: any): AuthenticationStartResponse {
    return AuthenticationStartResponseFromJSONTyped(json, false);
}

export function AuthenticationStartResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationStartResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'redirectUrl': json['redirectUrl'],
    };
}

export function AuthenticationStartResponseToJSON(value?: AuthenticationStartResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'redirectUrl': value.redirectUrl,
    };
}


