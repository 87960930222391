/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of customer market
 * @export
 * @enum {string}
 */
export enum Market {
    Sweden = 'SWEDEN',
    Finland = 'FINLAND'
}

export function MarketFromJSON(json: any): Market {
    return MarketFromJSONTyped(json, false);
}

export function MarketFromJSONTyped(json: any, ignoreDiscriminator: boolean): Market {
    return json as Market;
}

export function MarketToJSON(value?: Market | null): any {
    return value as any;
}

