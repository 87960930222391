import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      textAlign: "center",
    },
    iconNewMessage: {
      marginRight: theme.spacing(1),
      fontSize: 20,
      textAlign: "center",
    },
  })
);
