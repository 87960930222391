/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform file management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileMetadata,
    FileMetadataFromJSON,
    FileMetadataToJSON,
    PaginatedFileList,
    PaginatedFileListFromJSON,
    PaginatedFileListToJSON,
    SortByType,
    SortByTypeFromJSON,
    SortByTypeToJSON,
    SortOrderType,
    SortOrderTypeFromJSON,
    SortOrderTypeToJSON,
} from '../models';

export interface DeleteFileRequest {
    customerId: string;
    fileId: string;
}

export interface DownloadFileRequest {
    customerId: string;
    fileId: string;
}

export interface ListFilesRequest {
    customerId: string;
    sortBy?: SortByType;
    sortOrder?: SortOrderType;
    page?: number;
    pageSize?: number;
    meetingId?: string;
    userId?: string;
    onlyUnopenedFiles?: boolean;
}

export interface UploadFileRequest {
    customerId: string;
    accessGivenTo: Array<string>;
    file: Blob;
}

export interface UploadMeetingFileRequest {
    customerId: string;
    meetingId: string;
    file: Blob;
}

export interface UploadMessageFileRequest {
    customerId: string;
    threadId: string;
    file: Blob;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Delete a specific file.
     * Delete a file
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/{customerId}/{fileId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific file.
     * Delete a file
     */
    async deleteFile(requestParameters: DeleteFileRequest): Promise<void> {
        await this.deleteFileRaw(requestParameters);
    }

    /**
     * Download a specific file.
     * Download a file
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling downloadFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/{customerId}/{fileId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download a specific file.
     * Download a file
     */
    async downloadFile(requestParameters: DownloadFileRequest): Promise<Blob> {
        const response = await this.downloadFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * List files shared with you.
     * List files shared with you
     */
    async listFilesRaw(requestParameters: ListFilesRequest): Promise<runtime.ApiResponse<PaginatedFileList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.meetingId !== undefined) {
            queryParameters['meetingId'] = requestParameters.meetingId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.onlyUnopenedFiles !== undefined) {
            queryParameters['onlyUnopenedFiles'] = requestParameters.onlyUnopenedFiles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFileListFromJSON(jsonValue));
    }

    /**
     * List files shared with you.
     * List files shared with you
     */
    async listFiles(requestParameters: ListFilesRequest): Promise<PaginatedFileList> {
        const response = await this.listFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Operation to be used when any user uploads a file.
     * Upload a file and its metadata.
     */
    async uploadFileRaw(requestParameters: UploadFileRequest): Promise<runtime.ApiResponse<FileMetadata>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling uploadFile.');
        }

        if (requestParameters.accessGivenTo === null || requestParameters.accessGivenTo === undefined) {
            throw new runtime.RequiredError('accessGivenTo','Required parameter requestParameters.accessGivenTo was null or undefined when calling uploadFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.accessGivenTo) {
            queryParameters['accessGivenTo'] = requestParameters.accessGivenTo.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/file/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileMetadataFromJSON(jsonValue));
    }

    /**
     * Operation to be used when any user uploads a file.
     * Upload a file and its metadata.
     */
    async uploadFile(requestParameters: UploadFileRequest): Promise<FileMetadata> {
        const response = await this.uploadFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Operation to be used when any user uploads a file to a meeting session.
     * Upload a file and its metadata.
     */
    async uploadMeetingFileRaw(requestParameters: UploadMeetingFileRequest): Promise<runtime.ApiResponse<FileMetadata>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling uploadMeetingFile.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling uploadMeetingFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadMeetingFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/file/{customerId}/meeting/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileMetadataFromJSON(jsonValue));
    }

    /**
     * Operation to be used when any user uploads a file to a meeting session.
     * Upload a file and its metadata.
     */
    async uploadMeetingFile(requestParameters: UploadMeetingFileRequest): Promise<FileMetadata> {
        const response = await this.uploadMeetingFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Operation to be used when any user uploads a file to a message thread.
     * Upload a file and its metadata.
     */
    async uploadMessageFileRaw(requestParameters: UploadMessageFileRequest): Promise<runtime.ApiResponse<FileMetadata>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling uploadMessageFile.');
        }

        if (requestParameters.threadId === null || requestParameters.threadId === undefined) {
            throw new runtime.RequiredError('threadId','Required parameter requestParameters.threadId was null or undefined when calling uploadMessageFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadMessageFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/file/{customerId}/message/{threadId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"threadId"}}`, encodeURIComponent(String(requestParameters.threadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileMetadataFromJSON(jsonValue));
    }

    /**
     * Operation to be used when any user uploads a file to a message thread.
     * Upload a file and its metadata.
     */
    async uploadMessageFile(requestParameters: UploadMessageFileRequest): Promise<FileMetadata> {
        const response = await this.uploadMessageFileRaw(requestParameters);
        return await response.value();
    }

}
