import React from "react";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import { useIntl } from "react-intl";
import { SpeedDialAction } from "@material-ui/lab";
import { useStyles } from "./MuteAllSpeedDialAction.style";
import { messages } from "./MuteAllSpeedDialAction.messages";

interface Props {
  open: boolean;
  delay: number;
  onMuteAll: () => void;
  areAllMuted: boolean;
}

export default function MuteAllSpeedDialAction(props: Props) {
  const { onMuteAll, areAllMuted, open, delay } = props;
  const intl = useIntl();
  const classes = useStyles();
  const title = areAllMuted
    ? intl.formatMessage(messages.unmuteTooltip)
    : intl.formatMessage(messages.muteTooltip);
  return (
    <SpeedDialAction
      key="mute-all"
      icon={
        areAllMuted ? (
          <div className={classes.container}>
            <VolumeOffIcon className={classes.icon} />
            {title}
          </div>
        ) : (
          <div className={classes.container}>
            <VolumeUpIcon className={classes.icon} />
            {title}
          </div>
        )
      }
      onClick={onMuteAll}
      open={open}
      delay={delay}
      classes={{ fab: classes.fab }}
      FabProps={{ variant: "extended" }}
      TooltipClasses={{ tooltip: classes.hidden }}
      className={classes.fabContainer}
    />
  );
}
