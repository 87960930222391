import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Tabs.style";
import TabPanel from "./Components/TabPanel/TabPanel";
import { UserAuthorityType } from "../../generated/user-api";
import { hasPermission } from "../PermissionController/PermissionController";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

export interface TabConfig {
  title: string;
  component: JSX.Element;
  redirectTo?: string;
  allowedAuthorities?: UserAuthorityType[];
}

export interface Props {
  showTabs?: boolean;
  indicatorAtTop?: boolean;
  centered?: boolean;
  tabConfigs: TabConfig[];
  className?: string;
  tabClassName?: string;
  contentClassName?: string;
  defaultTab?: number;
  onTabChange?: (activeIndex: number) => void;
  scrollable?: boolean;
}

const Tabs = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [authenticatedUser] = useAuthenticatedUser();
  const {
    showTabs,
    defaultTab,
    tabConfigs,
    className,
    contentClassName,
    centered,
    indicatorAtTop,
    tabClassName,
    scrollable = false,
    onTabChange,
  } = props;
  const [currentTab, setCurrentTab] = React.useState(defaultTab || 0);

  const allowedTabs = (): TabConfig[] => {
    return tabConfigs.filter(
      (tabConfig) =>
        tabConfig.allowedAuthorities === undefined ||
        hasPermission({
          currentUser: authenticatedUser.user,
          atLeastOneOfAuthoritiesStaff: [...tabConfig.allowedAuthorities],
          atLeastOneOfAuthoritiesClient: [...tabConfig.allowedAuthorities],
          atLeastOneOfAuthoritiesExternal: [...tabConfig.allowedAuthorities],
          atLeastOneOfAuthoritiesPlatformAdmin: [
            ...tabConfig.allowedAuthorities,
          ],
        })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setCurrentTab(newValue);
    const redirectPath = allowedTabs()[newValue].redirectTo;
    if (redirectPath) {
      history.push(redirectPath);
    }
    if (onTabChange) {
      onTabChange(newValue);
    }
  };
  return (
    <div className={classes.root}>
      {showTabs && authenticatedUser.isComplete && (
        <Paper>
          <MuiTabs
            classes={{
              indicator: indicatorAtTop
                ? classes.indicatorAtTop
                : classes.indicatorAtBottom,
            }}
            TabIndicatorProps={{}}
            centered={centered}
            value={currentTab}
            onChange={handleChange}
            aria-label="tabs"
            className={className}
            variant={scrollable ? "scrollable" : "standard"}
          >
            {allowedTabs().map((config) => (
              <MuiTab
                key={config.title}
                label={config.title}
                className={tabClassName}
              />
            ))}
          </MuiTabs>
        </Paper>
      )}
      {allowedTabs().map((config, index) => (
        <TabPanel
          key={config.title}
          value={currentTab}
          index={index}
          className={contentClassName}
          component={config.component}
        />
      ))}
    </div>
  );
};

export default Tabs;
