import { defineMessages } from "react-intl";

export const messages = defineMessages({
  testVideo: {
    id: "dashboardview.quickmenucard.list.testvideo",
    defaultMessage: "Test Video",
    description: "list item text for testing your video functionality",
  },
  quickMenuTitle: {
    id: "dashboardview.quickmenucard.title",
    defaultMessage: "QUICK MENU",
    description: "title for the section of the quick menu",
  },
  CreateMeetingTemplate: {
    id: "dashboardview.quickmenucard.list.CreateMeetingTemplate",
    defaultMessage: "Create Booking Template",
    description: "Create a new Booking template",
  },
  userGuide: {
    id: "dashboardview.quickmenucard.list.userguide",
    defaultMessage: "User Guide",
    description: "User guide for users",
  },
});
