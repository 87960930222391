import React from "react";
import { useIntl } from "react-intl";
import { Grid, Paper, Typography } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import {
  browserName,
  browserVersion,
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isOpera,
  isSafari,
} from "react-device-detect";
import Button from "@material-ui/core/Button";
import useStyles from "./BrowserSupportCard.style";
import { messages } from "./BrowserSupportCard.messages";

const isSupportedBrowser = () => {
  if (isChrome && Number(browserVersion) >= 31) return true;
  if (isFirefox && Number(browserVersion) >= 34) return true;
  if (isSafari && Number(browserVersion) >= 11) return true;
  if (isAndroid) return true;
  if (isOpera) return true;
  return isEdge;
};

const BrowserSupportCard = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container justify="center" alignItems="center">
        <Grid item md={4} xs={12}>
          <div className={classes.iconContainer}>
            {isSupportedBrowser() ? (
              <LanguageIcon className={classes.icon} />
            ) : (
              <ReportProblemIcon className={classes.icon} />
            )}
          </div>
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography
            variant="h5"
            color={!isSupportedBrowser() ? "error" : undefined}
          >
            {intl.formatMessage(messages.titleLabel)}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {isSupportedBrowser()
              ? intl.formatMessage(messages.supportedBrowser, { browserName })
              : intl.formatMessage(messages.notSupportedBrowser, {
                  browserName: `${browserName}`,
                })}
          </Typography>
          {!isSupportedBrowser() && (
            <>
              <Button
                variant="outlined"
                className={classes.button}
                href="http://www.chrome.com"
              >
                {intl.formatMessage(messages.chromeDownloadLabel)}
              </Button>
              <Button variant="outlined" href="http://www.firefox.com">
                {intl.formatMessage(messages.firefoxDownloadLabel)}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BrowserSupportCard;
