import { defineMessages } from "react-intl";

export const messages = defineMessages({
  actionLabel: {
    id: "menu.action.deleteContact.label",
    defaultMessage: "Delete contact",
    description: "delete contact label",
  },
  alertTitle: {
    id: "menu.action.deleteContact.alert.title",
    defaultMessage: "Delete contact",
    description: "delete contact alert title",
  },
  alertContent: {
    id: "menu.action.deleteContact.alert.content",
    defaultMessage: "Are you sure you want to delete this contact?",
    description: "delete contact alert title",
  },
  alertSubmitLabel: {
    id: "menu.action.deleteContact.alert.submit.label",
    defaultMessage: "Delete",
    description: "delete contact alert submit label",
  },
  alertCancelLabel: {
    id: "menu.action.deleteContact.alert.cancel.label",
    defaultMessage: "Cancel",
    description: "delete contact alert cancel label",
  },
  success: {
    id: "menu.action.deleteContact.success",
    defaultMessage: "Contact successfully deleted",
    description: "Text shown in snack bar when contact successfully deleted",
  },
  generalError: {
    id: "menu.action.deleteContact.error.general",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when general error appears",
  },
});
