import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 10,
      paddingTop: 30,
      paddingBottom: 30,
    },
    divider: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(4),
    },
  })
);
