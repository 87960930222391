/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAuthenticationRequest
 */
export interface UserAuthenticationRequest {
    /**
     * Username
     * @type {string}
     * @memberof UserAuthenticationRequest
     */
    username: string;
    /**
     * Users password.
     * @type {string}
     * @memberof UserAuthenticationRequest
     */
    password: string;
}

export function UserAuthenticationRequestFromJSON(json: any): UserAuthenticationRequest {
    return UserAuthenticationRequestFromJSONTyped(json, false);
}

export function UserAuthenticationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthenticationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
    };
}

export function UserAuthenticationRequestToJSON(value?: UserAuthenticationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
    };
}


