/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttendanceStatusRequest,
    AttendanceStatusRequestFromJSON,
    AttendanceStatusRequestToJSON,
    AttendanceStatusType,
    AttendanceStatusTypeFromJSON,
    AttendanceStatusTypeToJSON,
    AvailableResponse,
    AvailableResponseFromJSON,
    AvailableResponseToJSON,
    CheckMeetingStatusRequest,
    CheckMeetingStatusRequestFromJSON,
    CheckMeetingStatusRequestToJSON,
    CheckMeetingStatusResponse,
    CheckMeetingStatusResponseFromJSON,
    CheckMeetingStatusResponseToJSON,
    MeetingHistoryType,
    MeetingHistoryTypeFromJSON,
    MeetingHistoryTypeToJSON,
    MeetingParticipantResponse,
    MeetingParticipantResponseFromJSON,
    MeetingParticipantResponseToJSON,
    MeetingRequest,
    MeetingRequestFromJSON,
    MeetingRequestToJSON,
    MeetingResponse,
    MeetingResponseFromJSON,
    MeetingResponseToJSON,
    MeetingSessionEndRequest,
    MeetingSessionEndRequestFromJSON,
    MeetingSessionEndRequestToJSON,
    MeetingSessionResponse,
    MeetingSessionResponseFromJSON,
    MeetingSessionResponseToJSON,
    MeetingSessionStartRequest,
    MeetingSessionStartRequestFromJSON,
    MeetingSessionStartRequestToJSON,
    MeetingStatusType,
    MeetingStatusTypeFromJSON,
    MeetingStatusTypeToJSON,
    PaginatedMeetingResponseList,
    PaginatedMeetingResponseListFromJSON,
    PaginatedMeetingResponseListToJSON,
    SortOrderType,
    SortOrderTypeFromJSON,
    SortOrderTypeToJSON,
    UnConfirmedMeetingAttendanceResponse,
    UnConfirmedMeetingAttendanceResponseFromJSON,
    UnConfirmedMeetingAttendanceResponseToJSON,
    UpdateMeetingStatusRequest,
    UpdateMeetingStatusRequestFromJSON,
    UpdateMeetingStatusRequestToJSON,
} from '../models';

export interface CheckIfUserIsAvailableRequest {
    customerId: string;
    userId: string;
    from: Date;
    duration: number;
    excludedMeetingId?: string;
}

export interface CheckStatusOnMeetingsRequest {
    customerId: string;
    checkMeetingStatusRequest: CheckMeetingStatusRequest;
}

export interface CreateMeetingRequest {
    customerId: string;
    meetingRequest: MeetingRequest;
}

export interface EndMeetingSessionRequest {
    customerId: string;
    meetingId: string;
    meetingEndRequest: MeetingSessionEndRequest;
}

export interface GetMeetingByIdRequest {
    customerId: string;
    meetingId: string;
}

export interface GetMeetingSessionRequest {
    customerId: string;
    meetingId: string;
    userId: string;
}

export interface GetMeetingsForCustomerRequest {
    customerId: string;
    createdBy?: string;
    history?: MeetingHistoryType;
    statuses?: Array<MeetingStatusType>;
    sortOrder?: SortOrderType;
    page?: number;
    pageSize?: number;
    personNumber?: string;
}

export interface GetMeetingsForUserRequest {
    customerId: string;
    userId: string;
    createdBy?: string;
    participantId?: string;
    history?: MeetingHistoryType;
    statuses?: Array<MeetingStatusType>;
    attendanceStatuses?: Array<AttendanceStatusType>;
    sortOrder?: SortOrderType;
    page?: number;
    pageSize?: number;
    fromDateTime?: Date;
    toDateTime?: Date;
}

export interface GetMeetingsWithUnconfirmedAttendanceRequest {
    customerId: string;
}

export interface StartMeetingSessionRequest {
    customerId: string;
    meetingId: string;
    meetingStartRequest: MeetingSessionStartRequest;
}

export interface UpdateAttendanceStatusRequest {
    customerId: string;
    meetingId: string;
    attendanceStatusRequest: AttendanceStatusRequest;
}

export interface UpdateMeetingRequest {
    customerId: string;
    meetingId: string;
    meetingRequest: MeetingRequest;
}

export interface UpdateMeetingStatusOperationRequest {
    customerId: string;
    meetingId: string;
    updateMeetingStatusRequest: UpdateMeetingStatusRequest;
}

/**
 * 
 */
export class MeetingApi extends runtime.BaseAPI {

    /**
     * Check if user is available during a specific time.
     * Check if user is available during a specific time
     */
    async checkIfUserIsAvailableRaw(requestParameters: CheckIfUserIsAvailableRequest): Promise<runtime.ApiResponse<AvailableResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling checkIfUserIsAvailable.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling checkIfUserIsAvailable.');
        }

        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling checkIfUserIsAvailable.');
        }

        if (requestParameters.duration === null || requestParameters.duration === undefined) {
            throw new runtime.RequiredError('duration','Required parameter requestParameters.duration was null or undefined when calling checkIfUserIsAvailable.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.duration !== undefined) {
            queryParameters['duration'] = requestParameters.duration;
        }

        if (requestParameters.excludedMeetingId !== undefined) {
            queryParameters['excludedMeetingId'] = requestParameters.excludedMeetingId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meeting/{customerId}/user/{userId}/available`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableResponseFromJSON(jsonValue));
    }

    /**
     * Check if user is available during a specific time.
     * Check if user is available during a specific time
     */
    async checkIfUserIsAvailable(requestParameters: CheckIfUserIsAvailableRequest): Promise<AvailableResponse> {
        const response = await this.checkIfUserIsAvailableRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check the status of the meetings in the supplied list of meeting ids.
     * Check status on meetings
     */
    async checkStatusOnMeetingsRaw(requestParameters: CheckStatusOnMeetingsRequest): Promise<runtime.ApiResponse<Array<CheckMeetingStatusResponse>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling checkStatusOnMeetings.');
        }

        if (requestParameters.checkMeetingStatusRequest === null || requestParameters.checkMeetingStatusRequest === undefined) {
            throw new runtime.RequiredError('checkMeetingStatusRequest','Required parameter requestParameters.checkMeetingStatusRequest was null or undefined when calling checkStatusOnMeetings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}/check`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckMeetingStatusRequestToJSON(requestParameters.checkMeetingStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CheckMeetingStatusResponseFromJSON));
    }

    /**
     * Check the status of the meetings in the supplied list of meeting ids.
     * Check status on meetings
     */
    async checkStatusOnMeetings(requestParameters: CheckStatusOnMeetingsRequest): Promise<Array<CheckMeetingStatusResponse>> {
        const response = await this.checkStatusOnMeetingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authorities BOOK_MEETING or BOOK_MEETING_OTHER.
     * Create a new meeting
     */
    async createMeetingRaw(requestParameters: CreateMeetingRequest): Promise<runtime.ApiResponse<MeetingResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createMeeting.');
        }

        if (requestParameters.meetingRequest === null || requestParameters.meetingRequest === undefined) {
            throw new runtime.RequiredError('meetingRequest','Required parameter requestParameters.meetingRequest was null or undefined when calling createMeeting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingRequestToJSON(requestParameters.meetingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authorities BOOK_MEETING or BOOK_MEETING_OTHER.
     * Create a new meeting
     */
    async createMeeting(requestParameters: CreateMeetingRequest): Promise<MeetingResponse> {
        const response = await this.createMeetingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authority ATTEND_MEETING.
     * End this meeting
     */
    async endMeetingSessionRaw(requestParameters: EndMeetingSessionRequest): Promise<runtime.ApiResponse<MeetingResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling endMeetingSession.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling endMeetingSession.');
        }

        if (requestParameters.meetingEndRequest === null || requestParameters.meetingEndRequest === undefined) {
            throw new runtime.RequiredError('meetingEndRequest','Required parameter requestParameters.meetingEndRequest was null or undefined when calling endMeetingSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}/session/{meetingId}/end`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingSessionEndRequestToJSON(requestParameters.meetingEndRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authority ATTEND_MEETING.
     * End this meeting
     */
    async endMeetingSession(requestParameters: EndMeetingSessionRequest): Promise<MeetingResponse> {
        const response = await this.endMeetingSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with roles STAFF or CLIENT and authority ATTEND_MEETING. If CLIENT the user needs to be a participant of the meeting to access.
     * Get a meeting by id
     */
    async getMeetingByIdRaw(requestParameters: GetMeetingByIdRequest): Promise<runtime.ApiResponse<MeetingResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMeetingById.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling getMeetingById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meeting/{customerId}/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with roles STAFF or CLIENT and authority ATTEND_MEETING. If CLIENT the user needs to be a participant of the meeting to access.
     * Get a meeting by id
     */
    async getMeetingById(requestParameters: GetMeetingByIdRequest): Promise<MeetingResponse> {
        const response = await this.getMeetingByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role CLIENT, STAFF and authorities ATTEND_MEETING.
     * Get a meeting and session info
     */
    async getMeetingSessionRaw(requestParameters: GetMeetingSessionRequest): Promise<runtime.ApiResponse<MeetingSessionResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMeetingSession.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling getMeetingSession.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getMeetingSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meeting/{customerId}/session/{meetingId}/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingSessionResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role CLIENT, STAFF and authorities ATTEND_MEETING.
     * Get a meeting and session info
     */
    async getMeetingSession(requestParameters: GetMeetingSessionRequest): Promise<MeetingSessionResponse> {
        const response = await this.getMeetingSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authority ADMIN_SITE.
     * Get a list of meetings belonging to a customer
     */
    async getMeetingsForCustomerRaw(requestParameters: GetMeetingsForCustomerRequest): Promise<runtime.ApiResponse<PaginatedMeetingResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMeetingsForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.createdBy !== undefined) {
            queryParameters['createdBy'] = requestParameters.createdBy;
        }

        if (requestParameters.history !== undefined) {
            queryParameters['history'] = requestParameters.history;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.personNumber !== undefined) {
            queryParameters['personNumber'] = requestParameters.personNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meeting/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingResponseListFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authority ADMIN_SITE.
     * Get a list of meetings belonging to a customer
     */
    async getMeetingsForCustomer(requestParameters: GetMeetingsForCustomerRequest): Promise<PaginatedMeetingResponseList> {
        const response = await this.getMeetingsForCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with roles STAFF or CLIENT.
     * Get a list of meetings belonging to a user
     */
    async getMeetingsForUserRaw(requestParameters: GetMeetingsForUserRequest): Promise<runtime.ApiResponse<PaginatedMeetingResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMeetingsForUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getMeetingsForUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.createdBy !== undefined) {
            queryParameters['createdBy'] = requestParameters.createdBy;
        }

        if (requestParameters.participantId !== undefined) {
            queryParameters['participantId'] = requestParameters.participantId;
        }

        if (requestParameters.history !== undefined) {
            queryParameters['history'] = requestParameters.history;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.attendanceStatuses) {
            queryParameters['attendanceStatuses'] = requestParameters.attendanceStatuses;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.fromDateTime !== undefined) {
            queryParameters['fromDateTime'] = (requestParameters.fromDateTime as any).toISOString();
        }

        if (requestParameters.toDateTime !== undefined) {
            queryParameters['toDateTime'] = (requestParameters.toDateTime as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meeting/{customerId}/user/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingResponseListFromJSON(jsonValue));
    }

    /**
     * Requires user with roles STAFF or CLIENT.
     * Get a list of meetings belonging to a user
     */
    async getMeetingsForUser(requestParameters: GetMeetingsForUserRequest): Promise<PaginatedMeetingResponseList> {
        const response = await this.getMeetingsForUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of meetings where the current users attendance status is not confirmed. Requires user with role CLIENT, STAFF and authorities ATTEND_MEETING.
     * Get not confirmed meetings
     */
    async getMeetingsWithUnconfirmedAttendanceRaw(requestParameters: GetMeetingsWithUnconfirmedAttendanceRequest): Promise<runtime.ApiResponse<UnConfirmedMeetingAttendanceResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getMeetingsWithUnconfirmedAttendance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/meeting/{customerId}/attendance`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnConfirmedMeetingAttendanceResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of meetings where the current users attendance status is not confirmed. Requires user with role CLIENT, STAFF and authorities ATTEND_MEETING.
     * Get not confirmed meetings
     */
    async getMeetingsWithUnconfirmedAttendance(requestParameters: GetMeetingsWithUnconfirmedAttendanceRequest): Promise<UnConfirmedMeetingAttendanceResponse> {
        const response = await this.getMeetingsWithUnconfirmedAttendanceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authority ATTEND_MEETING.
     * Start this meeting
     */
    async startMeetingSessionRaw(requestParameters: StartMeetingSessionRequest): Promise<runtime.ApiResponse<MeetingSessionResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling startMeetingSession.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling startMeetingSession.');
        }

        if (requestParameters.meetingStartRequest === null || requestParameters.meetingStartRequest === undefined) {
            throw new runtime.RequiredError('meetingStartRequest','Required parameter requestParameters.meetingStartRequest was null or undefined when calling startMeetingSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}/session/{meetingId}/start`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingSessionStartRequestToJSON(requestParameters.meetingStartRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingSessionResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authority ATTEND_MEETING.
     * Start this meeting
     */
    async startMeetingSession(requestParameters: StartMeetingSessionRequest): Promise<MeetingSessionResponse> {
        const response = await this.startMeetingSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Uppdates attendance status for signed in user for specified meetingId.
     * Update attendance status for meeting
     */
    async updateAttendanceStatusRaw(requestParameters: UpdateAttendanceStatusRequest): Promise<runtime.ApiResponse<MeetingParticipantResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateAttendanceStatus.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling updateAttendanceStatus.');
        }

        if (requestParameters.attendanceStatusRequest === null || requestParameters.attendanceStatusRequest === undefined) {
            throw new runtime.RequiredError('attendanceStatusRequest','Required parameter requestParameters.attendanceStatusRequest was null or undefined when calling updateAttendanceStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}/attendance/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttendanceStatusRequestToJSON(requestParameters.attendanceStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantResponseFromJSON(jsonValue));
    }

    /**
     * Uppdates attendance status for signed in user for specified meetingId.
     * Update attendance status for meeting
     */
    async updateAttendanceStatus(requestParameters: UpdateAttendanceStatusRequest): Promise<MeetingParticipantResponse> {
        const response = await this.updateAttendanceStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authorities BOOK_MEETING or BOOK_MEETING_OTHER.
     * Update a meeting
     */
    async updateMeetingRaw(requestParameters: UpdateMeetingRequest): Promise<runtime.ApiResponse<MeetingResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateMeeting.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling updateMeeting.');
        }

        if (requestParameters.meetingRequest === null || requestParameters.meetingRequest === undefined) {
            throw new runtime.RequiredError('meetingRequest','Required parameter requestParameters.meetingRequest was null or undefined when calling updateMeeting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingRequestToJSON(requestParameters.meetingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authorities BOOK_MEETING or BOOK_MEETING_OTHER.
     * Update a meeting
     */
    async updateMeeting(requestParameters: UpdateMeetingRequest): Promise<MeetingResponse> {
        const response = await this.updateMeetingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authorities BOOK_MEETING or BOOK_MEETING_OTHER.
     * Update the status on a meeting
     */
    async updateMeetingStatusRaw(requestParameters: UpdateMeetingStatusOperationRequest): Promise<runtime.ApiResponse<MeetingResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateMeetingStatus.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling updateMeetingStatus.');
        }

        if (requestParameters.updateMeetingStatusRequest === null || requestParameters.updateMeetingStatusRequest === undefined) {
            throw new runtime.RequiredError('updateMeetingStatusRequest','Required parameter requestParameters.updateMeetingStatusRequest was null or undefined when calling updateMeetingStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting/{customerId}/status/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeetingStatusRequestToJSON(requestParameters.updateMeetingStatusRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authorities BOOK_MEETING or BOOK_MEETING_OTHER.
     * Update the status on a meeting
     */
    async updateMeetingStatus(requestParameters: UpdateMeetingStatusOperationRequest): Promise<MeetingResponse> {
        const response = await this.updateMeetingStatusRaw(requestParameters);
        return await response.value();
    }

}
