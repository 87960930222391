import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    margin: "auto",
    padding: theme.spacing(2),
  },
  videoPreview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: 100,
  },
}));
