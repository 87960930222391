import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "row",
      height: theme.spacing(6),
    },
  },
  topTabs: {
    flex: 1,
  },
  hidden: {
    display: "none",
  },
  button: {
    borderRadius: 0,
    width: "100%",
    padding: 0,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  helpButton: {
    borderRadius: 0,
    width: "100%",
    padding: 0,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
    width: theme.spacing(6),
    height: theme.spacing(6),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  contentContainer: {
    width: theme.spacing(40),
    borderRadius: 0,
  },
}));
