/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerStatusType,
    CustomerStatusTypeFromJSON,
    CustomerStatusTypeFromJSONTyped,
    CustomerStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerStatusRequest
 */
export interface CustomerStatusRequest {
    /**
     * User sign session
     * @type {string}
     * @memberof CustomerStatusRequest
     */
    sessionId: string;
    /**
     * 
     * @type {CustomerStatusType}
     * @memberof CustomerStatusRequest
     */
    status: CustomerStatusType;
}

export function CustomerStatusRequestFromJSON(json: any): CustomerStatusRequest {
    return CustomerStatusRequestFromJSONTyped(json, false);
}

export function CustomerStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'status': CustomerStatusTypeFromJSON(json['status']),
    };
}

export function CustomerStatusRequestToJSON(value?: CustomerStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'status': CustomerStatusTypeToJSON(value.status),
    };
}


