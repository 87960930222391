import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { messages } from "./ChangeMeetingTemplateButton.messages";
import { MeetingTemplateResponse } from "../../../generated/meetingtemplate-api";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import ModifyMeetingTemplateDialog from "../../Dialogs/ModifyMeetingTemplateDialog/ModifyMeetingTemplateDialog";

interface Props {
  meetingTemplate: MeetingTemplateResponse;
  className?: string;
  onChanged?: () => void;
  disabled?: boolean;
}

const ChangeMeetingButton = (props: Props) => {
  const { meetingTemplate, className, onChanged, disabled } = props;
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  return (
    <PermissionController
      allowedRoles={[UserRoleType.Staff]}
      atLeastOneOfAuthoritiesStaff={[
        UserAuthorityType.BookMeeting,
        UserAuthorityType.AdminSite,
      ]}
    >
      <ModifyMeetingTemplateDialog
        isOpen={isDialogOpen}
        meetingTemplate={meetingTemplate}
        onClose={() => setIsDialogOpen(false)}
        onUpdated={onChanged}
      />
      <Button
        size="small"
        color="primary"
        className={className}
        variant="outlined"
        onClick={handleOpenDialog}
        disabled={disabled}
      >
        {intl.formatMessage(messages.buttonLabel)}
      </Button>
    </PermissionController>
  );
};

export default ChangeMeetingButton;
