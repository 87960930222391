import React from "react";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import AnonymizeFeatures from "../../../Components/AnonymizeFeatures/AnonymizeFeatures";

const AnonymizeFeaturesPage = () => {
  const customerId = useCustomerId();
  return <AnonymizeFeatures key="thisDomain" customerId={customerId} />;
};

export default AnonymizeFeaturesPage;
