/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform file management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileMetadata,
    FileMetadataFromJSON,
    FileMetadataFromJSONTyped,
    FileMetadataToJSON,
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedFileList
 */
export interface PaginatedFileList {
    /**
     * 
     * @type {Pagination}
     * @memberof PaginatedFileList
     */
    pagination: Pagination;
    /**
     * 
     * @type {Array<FileMetadata>}
     * @memberof PaginatedFileList
     */
    data?: Array<FileMetadata>;
}

export function PaginatedFileListFromJSON(json: any): PaginatedFileList {
    return PaginatedFileListFromJSONTyped(json, false);
}

export function PaginatedFileListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedFileList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(FileMetadataFromJSON)),
    };
}

export function PaginatedFileListToJSON(value?: PaginatedFileList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(FileMetadataToJSON)),
    };
}


