import { defineMessages } from "react-intl";

export const messages = defineMessages({
  disableMic: {
    id: "video.toolbar.muteaudio.disable.tooltip",
    defaultMessage: "Unmute Participants",
    description: "Tooltip label for button unmuting participants",
  },
  enableMic: {
    id: "video.toolbar.muteaudio.enable.tooltip",
    defaultMessage: "Mute Participants",
    description: "Tooltip label for button muting participants",
  },
});
