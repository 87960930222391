import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "dialog.allowMediaGuide.safari.desktop.title",
    defaultMessage: "Safari (Allow Camera and Microphone on desktop)",
    description: "Title for dialog safari",
  },
  imgAltText: {
    id: "dialog.allowMediaGuide.safari.desktop.image.alt.text",
    defaultMessage: "Chrome (Allow Camera and Microphone Guide on desktop)",
    description: "Image text",
  },
  step1: {
    id: "dialog.allowMediaGuide.safari.desktop.step.1.text",
    defaultMessage: "Start a meeting.",
    description: "Step 1",
  },
  step2: {
    id: "dialog.allowMediaGuide.safari.desktop.step.2.text",
    defaultMessage:
      "Click the button (mark 1, see image below) “Safari > Settings for {DOMAIN}” in the toolbar at the top.",
    description: "Step 2",
  },
  step3: {
    id: "dialog.allowMediaGuide.safari.desktop.step.3.text",
    defaultMessage:
      "Change the settings as marked in the highlighted section (mark 2, see image below) to allow camera/microphone on this page.",
    description: "Step 3",
  },
  step4: {
    id: "dialog.allowMediaGuide.safari.desktop.step.4.text",
    defaultMessage:
      "Click the button (mark 3, see image below) to refresh the page and apply the settings.",
    description: "Step 4",
  },
});
