import { defineMessages } from "react-intl";

export const messages = defineMessages({
  startMeetingButton: {
    id: "button.connectToMeeting.start.label",
    defaultMessage: "Start meeting",
    description: "Button label to start meeting as a meeting leader",
  },
  connectMeetingButton: {
    id: "button.connectToMeeting.connect.label",
    defaultMessage: "Join",
    description: "Button label to join meeting",
  },
  generalError: {
    id: "button.connectToMeeting.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when fails to start meeting session",
  },
  success: {
    id: "button.connectToMeeting.success",
    defaultMessage: "Successfully started meeting",
    description:
      "Text shown in snack bar when successfully created meeting session",
  },
});
