import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeading: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      color: theme.palette.grey["600"],
    },
    cardHeadingNoMargin: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
    "fc-list-event-graphic": {
      backgroundColor: "red",
    },
    button: {
      marginLeft: 5,
      padding: 5,
      marginTop: 5,
    },
    buttonToday: {
      marginLeft: 20,
      marginRight: 5,
      padding: 5,
    },
    headerButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap-reverse",
    },
    headerButtonsXs: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap-reverse",
      margin: 3,
    },
  })
);
