import React, { useContext, useEffect, useState } from "react";
import {
  CustomerFeature,
  CustomerSettingsResponse,
  CustomerThemeResponse,
} from "../../generated/customersettings-api";
import {
  ContentItemType,
  ContentItemTypeResponse,
} from "../../generated/customercontent-api";
import {
  customerContentService,
  customerSettingsService,
} from "../ServiceProvider/ServiceProvider";

interface Props {
  children: React.ReactNode;
}

interface ContentItems {
  welcomeText?: ContentItemTypeResponse;
  noticeText?: ContentItemTypeResponse;
  linkButton?: ContentItemTypeResponse;
  feedbackButton?: ContentItemTypeResponse;
  contactDetailsEmail?: ContentItemTypeResponse;
  contactDetailsPhone?: ContentItemTypeResponse;
  contactDetailsOpeningHours?: ContentItemTypeResponse;
  technicalSupportEmail?: ContentItemTypeResponse;
  technicalSupportPhone?: ContentItemTypeResponse;
  technicalSupportOpeningHours?: ContentItemTypeResponse;
}

const CustomerIdContext = React.createContext<string | undefined>(undefined);
const CustomerNameContext = React.createContext<string | undefined>(undefined);
const CustomerContentContext = React.createContext<ContentItems>({});
const CustomerFeaturesContext = React.createContext<Array<CustomerFeature>>([]);
const CustomerIsAdminPortalContext = React.createContext<boolean | undefined>(
  undefined
);
const CustomerThemeContext = React.createContext<
  CustomerThemeResponse | undefined
>(undefined);

export const useCustomerId = () => {
  return useContext(CustomerIdContext);
};

export const useCustomerTheme = () => {
  return useContext(CustomerThemeContext);
};

export const useCustomerFeatures = () => {
  return useContext(CustomerFeaturesContext);
};

export const useCustomerName = () => {
  return useContext(CustomerNameContext);
};

export const useCustomerContent = () => {
  return useContext(CustomerContentContext);
};

export const useCustomerIsAdminPortal = () => {
  return useContext(CustomerIsAdminPortalContext);
};

const CustomerProvider = (props: Props) => {
  const { children } = props;
  const [
    customerSettings,
    setCustomerSettings,
  ] = useState<CustomerSettingsResponse>();
  const [customerContent, setCustomerContent] = useState<
    Array<ContentItemTypeResponse>
  >([]);

  const mapCustomerContent = (
    contentItems: Array<ContentItemTypeResponse>
  ): ContentItems => {
    const items = {} as ContentItems;
    contentItems.forEach((item) => {
      switch (item.type) {
        case ContentItemType.FeedbackButton:
          items.feedbackButton = item;
          break;
        case ContentItemType.LinkButton:
          items.linkButton = item;
          break;
        case ContentItemType.NoticeText:
          items.noticeText = item;
          break;
        case ContentItemType.WelcomeText:
          items.welcomeText = item;
          break;
        case ContentItemType.TechnicalSupportEmail:
          items.technicalSupportEmail = item;
          break;
        case ContentItemType.TechnicalSupportPhone:
          items.technicalSupportPhone = item;
          break;
        case ContentItemType.TechnicalSupportOpeningHours:
          items.technicalSupportOpeningHours = item;
          break;
        case ContentItemType.ContactDetailsEmail:
          items.contactDetailsEmail = item;
          break;
        case ContentItemType.ContactDetailsPhone:
          items.contactDetailsPhone = item;
          break;
        case ContentItemType.ContactDetailsOpeningHours:
          items.contactDetailsOpeningHours = item;
          break;
        default:
      }
    });
    return items;
  };

  useEffect(() => {
    customerSettingsService()
      .getCustomerSettingsByHostname({
        customerSettingsByHostnameRequest: {
          hostname: window.location.hostname,
        },
      })
      .then((value) => setCustomerSettings(value));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerSettings?.id) {
      customerContentService()
        .getAllCustomerContentItems({
          customerId: customerSettings.id,
        })
        .then(setCustomerContent);
    }
  }, [customerSettings?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!customerSettings?.id) {
    return null;
  }
  return (
    <CustomerIdContext.Provider value={customerSettings.id}>
      <CustomerIsAdminPortalContext.Provider
        value={customerSettings.isAdminPortal}
      >
        <CustomerContentContext.Provider
          value={mapCustomerContent(customerContent)}
        >
          <CustomerNameContext.Provider value={customerSettings.customerName}>
            <CustomerThemeContext.Provider value={customerSettings.theme}>
              <CustomerFeaturesContext.Provider
                value={customerSettings.features || []}
              >
                {children}
              </CustomerFeaturesContext.Provider>
            </CustomerThemeContext.Provider>
          </CustomerNameContext.Provider>
        </CustomerContentContext.Provider>
      </CustomerIsAdminPortalContext.Provider>
    </CustomerIdContext.Provider>
  );
};

export default CustomerProvider;
