/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ToDoStatusType,
    ToDoStatusTypeFromJSON,
    ToDoStatusTypeFromJSONTyped,
    ToDoStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateToDoRequest
 */
export interface UpdateToDoRequest {
    /**
     * Short text describing what the assignee is to do.
     * @type {string}
     * @memberof UpdateToDoRequest
     */
    title?: string;
    /**
     * Longer text describing what the assignee is to do.
     * @type {string}
     * @memberof UpdateToDoRequest
     */
    description?: string;
    /**
     * 
     * @type {ToDoStatusType}
     * @memberof UpdateToDoRequest
     */
    status?: ToDoStatusType;
    /**
     * Date this ToDo should be completed by the assignee.
     * @type {Date}
     * @memberof UpdateToDoRequest
     */
    shouldBeCompletedOn?: Date;
}

export function UpdateToDoRequestFromJSON(json: any): UpdateToDoRequest {
    return UpdateToDoRequestFromJSONTyped(json, false);
}

export function UpdateToDoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateToDoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : ToDoStatusTypeFromJSON(json['status']),
        'shouldBeCompletedOn': !exists(json, 'shouldBeCompletedOn') ? undefined : (new Date(json['shouldBeCompletedOn'])),
    };
}

export function UpdateToDoRequestToJSON(value?: UpdateToDoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'status': ToDoStatusTypeToJSON(value.status),
        'shouldBeCompletedOn': value.shouldBeCompletedOn === undefined ? undefined : (value.shouldBeCompletedOn.toISOString().substr(0,10)),
    };
}


