import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  ButtonGroup,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useStyles } from "./CalendarComponent.style";
import CalendarEvent from "./CalendarItem/CalendarEvent";
import {
  MeetingResponse,
  MeetingStatusType,
  PaginatedMeetingResponseList,
  SortOrderType,
} from "../../generated/meeting-api";
import "./Calendar.css";
import { messages } from "./CalendarComponent.messages";
import { meetingService } from "../../Providers/ServiceProvider/ServiceProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import ChangeMeetingDialog from "./ChangeMeetingDialog/ChangeMeetingDialog";
import { Variant } from "../BookingsList/Components/Variant/Variant";
import { getCurrentTimeZone } from "../../Utils/Meeting";
import { UserRoleType } from "../../generated/user-api";

interface Props {
  noMarginBottom?: boolean;
  createNewMeeting: (date: Date) => void;
  refreshData: boolean;
}

interface EventItem {
  title?: string;
  start: string;
  end: string;
  eventDetails: MeetingResponse;
}

interface CalendarRange {
  fromDate: Date;
  toDate: Date;
}

interface CalendarButton {
  title?: string;
  color?: "primary" | "secondary";
  className?: any;
  action: () => void;
  icon?: JSX.Element;
}

const CalendarComponent = (props: Props) => {
  const intl = useIntl();
  const { noMarginBottom, createNewMeeting, refreshData } = props;
  const classes = useStyles();
  const ref: React.LegacyRef<FullCalendar> | undefined = React.createRef();
  const [calendarEvents, setcalendarEvents] = useState<any>([]);
  const [authenticatedUser] = useAuthenticatedUser();
  const [
    currentCalendarRange,
    setCurrentCalendarRange,
  ] = useState<CalendarRange | null>(null);
  const [isListVisible] = useState<boolean>(false);
  const theme = useTheme();
  const customerId = useCustomerId();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [
    bookingList,
    setBookingList,
  ] = useState<PaginatedMeetingResponseList>();
  const [switchDialog, setSwitchDialog] = useState<boolean>(false);
  const [lastMeetingClicked, setLastMeetingClicked] = useState<any>(false);

  const loadFromToBookings = () => {
    if (currentCalendarRange === null) return;
    if (customerId && authenticatedUser.user?.id) {
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: 1,
          pageSize: 200,
          customerId,
          sortOrder: SortOrderType.Descending,
          userId: authenticatedUser.user.id,
          fromDateTime: currentCalendarRange.fromDate,
          toDateTime: currentCalendarRange.toDate,
        })
        .then((res) => setBookingList(res));
    }
  };

  const processBookingLists = () => {
    let newArray: EventItem[] = [];
    bookingList?.data?.forEach((record: MeetingResponse) => {
      const newEvent: EventItem = {
        title: record.title,
        start: getCurrentTimeZone(record.startDateTime)
          .toISOString()
          .split(".")[0],
        end: getCurrentTimeZone(record.expectedEndDateTime)
          .toISOString()
          .split(".")[0],
        eventDetails: record,
      };
      newArray = [...newArray, newEvent];
    });
    setcalendarEvents(newArray);
  };

  useEffect(() => {
    loadFromToBookings();
  }, [currentCalendarRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadFromToBookings();
  }, [refreshData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    processBookingLists();
  }, [bookingList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const calendarView = ref?.current?.getApi()?.view;
    if (calendarView) {
      setCurrentCalendarRange({
        fromDate: calendarView.activeStart,
        toDate: calendarView.activeEnd,
      });
    }
  }, [ref?.current?.getApi()?.view.activeStart]); // eslint-disable-line

  useEffect(() => {
    const calendarApi = ref?.current?.getApi();
    calendarApi?.setOption("height", "70vh");
  }, [ref?.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const setStartEndDate = () => {
    const calendarView = ref?.current?.getApi()?.view;
    if (calendarView) {
      setCurrentCalendarRange({
        fromDate: calendarView.activeStart,
        toDate: calendarView.activeEnd,
      });
    }
  };
  const renderEventContent = (eventInfo: any) => {
    return (
      <CalendarEvent
        eventDetails={eventInfo.event.extendedProps.eventDetails}
        eventOnClick={() => {
          const calendarView: any = ref?.current?.getApi()?.view;
          if (calendarView?.type === "listWeek") return;
          if (
            eventInfo?.event?.extendedProps?.eventDetails?.status !==
            MeetingStatusType.Created
          )
            return;
          if (authenticatedUser.user?.userRole !== UserRoleType.Staff) return;
          setLastMeetingClicked(eventInfo.event.extendedProps.eventDetails);
          setSwitchDialog(!switchDialog);
        }}
        eventInfo={eventInfo}
        reloadBookings={loadFromToBookings}
        calendarApi={ref?.current?.getApi()}
      />
    );
  };
  const RenderHeaderButton = (calendarButtonProps: CalendarButton) => {
    const { color, className, title, action, icon } = calendarButtonProps;
    return (
      <Button
        color={color || "primary"}
        className={className || classes.button}
        variant="contained"
        onClick={action}
        startIcon={icon}
      >
        {title && title}
      </Button>
    );
  };
  const handleDateClick = (info: any) => {
    createNewMeeting(info.dateStr);
  };
  const changeView = (viewType: string) => {
    ref?.current?.getApi()?.changeView(viewType);
    setStartEndDate();
  };
  return (
    <Typography
      className={
        noMarginBottom ? classes.cardHeadingNoMargin : classes.cardHeading
      }
      component="h1"
      variant="subtitle2"
    >
      <div
        className={
          !isSmallScreen ? classes.headerButtons : classes.headerButtonsXs
        }
      >
        <div
          className={
            !isSmallScreen ? classes.headerButtons : classes.headerButtonsXs
          }
        >
          <ButtonGroup color="primary">
            <RenderHeaderButton
              icon={<ChevronLeft />}
              action={() => {
                ref?.current?.getApi()?.prev();
                setStartEndDate();
              }}
            />
            <RenderHeaderButton
              icon={<ChevronRight />}
              action={() => {
                ref?.current?.getApi()?.next();
                setStartEndDate();
              }}
            />
            <RenderHeaderButton
              title={intl.formatMessage(messages.today)}
              action={() => {
                ref?.current?.getApi()?.today();
                setStartEndDate();
              }}
              color="secondary"
            />
          </ButtonGroup>
        </div>
        <ButtonGroup color="primary">
          {isListVisible && (
            <RenderHeaderButton
              title={intl.formatMessage(messages.list)}
              action={() => changeView("listWeek")}
            />
          )}
          <RenderHeaderButton
            title={intl.formatMessage(messages.month)}
            action={() => changeView("dayGridMonth")}
          />
          <RenderHeaderButton
            title={intl.formatMessage(messages.week)}
            action={() => changeView("timeGridWeek")}
          />
          <RenderHeaderButton
            title={intl.formatMessage(messages.day)}
            action={() => changeView("timeGridDay")}
          />
        </ButtonGroup>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "",
          center: "title",
          right: "",
        }}
        timeZone="UTC"
        ref={ref}
        locale={intl.locale}
        events={calendarEvents}
        dateClick={(info: any) => handleDateClick(info)}
        eventContent={renderEventContent}
        selectable
        firstDay={1}
        buttonText={{
          today: intl.formatMessage(messages.today),
          month: intl.formatMessage(messages.month),
          week: intl.formatMessage(messages.week),
          day: intl.formatMessage(messages.day),
          list: intl.formatMessage(messages.list),
        }}
      />
      <ChangeMeetingDialog
        contextVariant={Variant.ADMIN_UPCOMING_BOOKINGS}
        meeting={lastMeetingClicked}
        onChanged={loadFromToBookings}
        switchDialog={switchDialog}
      />
    </Typography>
  );
};

export default CalendarComponent;
