import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Bookmark } from "@material-ui/icons";
import { Grid, GridSize, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./MeetingTemplateListItem.style";
import {
  MeetingTemplateKindType,
  MeetingTemplateResponse,
} from "../../../../generated/meetingtemplate-api";
import MeetingTemplateListItemText from "../MeetingTemplateListItemText/MeetingTemplateListItemText";
import ChangeMeetingTemplateButton from "../../../Buttons/ChangeMeetingTemplateButton/ChangeMeetingTemplateButton";
import DeleteMeetingTemplateButton from "../../../Buttons/DeleteMeetingTemplateDialog/DeleteMeetingTemplateButton";
import { messages } from "./MeetingTemplateListItem.messages";
import PermissionController from "../../../PermissionController/PermissionController";
import {
  UserAuthorityType,
  UserRoleType,
} from "../../../../generated/user-api";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface GridConfig {
  md: GridSize;
  sm: GridSize;
  xs: GridSize;
}

interface GridConfigList {
  type: GridConfig;
  duration: GridConfig;
  meetingTitle: GridConfig;
  icon: GridConfig;
  buttons: GridConfig;
}
interface Props {
  meetingTemplate: MeetingTemplateResponse;
  reloadTemplates?: () => void;
}

const MeetingTemplateListItem = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { meetingTemplate, reloadTemplates } = props;
  const [authenticatedUser] = useAuthenticatedUser();
  const gridConfig: GridConfigList = {
    duration: {
      md: 3,
      sm: 3,
      xs: 6,
    },
    type: { md: 4, sm: 4, xs: 6 },
    meetingTitle: { md: 10, sm: 10, xs: 10 },
    buttons: { md: 5, sm: 5, xs: 12 },
    icon: { md: 1, sm: 1, xs: 2 },
  };
  const getActionButtons = (isPublicTemplate: boolean) => {
    if (!isPublicTemplate) {
      return true;
    }
    return authenticatedUser.user?.userAuthorities?.includes(
      UserAuthorityType.AdminSite
    );
  };
  return (
    <>
      <ListItem alignItems="flex-start" className={classes.root} divider>
        <Grid container>
          {meetingTemplate.title && (
            <Grid item {...gridConfig.meetingTitle}>
              <Typography color="textPrimary" variant="h6">
                <Bookmark color="disabled" className={classes.icon} />
                {meetingTemplate.title}
              </Typography>
            </Grid>
          )}
          {meetingTemplate.type && (
            <Grid item {...gridConfig.type}>
              <MeetingTemplateListItemText
                primaryText={intl.formatMessage(messages.meetingType)}
                secondaryText={intl.formatMessage(
                  meetingTemplate.type === MeetingTemplateKindType.Personal
                    ? messages.personalTypeLabel
                    : messages.publicTypeLabel
                )}
              />
            </Grid>
          )}
          {meetingTemplate.duration && (
            <Grid item {...gridConfig.duration}>
              <MeetingTemplateListItemText
                primaryText={intl.formatMessage(messages.meetingDuration)}
                secondaryText={meetingTemplate.duration.toLocaleString()}
              />
            </Grid>
          )}

          <Grid item {...gridConfig.buttons} className={classes.buttonGrid}>
            <PermissionController
              allowedRoles={[UserRoleType.Staff]}
              atLeastOneOfAuthoritiesStaff={[
                UserAuthorityType.BookMeeting,
                UserAuthorityType.AdminSite,
              ]}
            >
              <ChangeMeetingTemplateButton
                onChanged={reloadTemplates}
                className={classes.button}
                meetingTemplate={meetingTemplate}
                disabled={
                  !getActionButtons(
                    meetingTemplate.type === MeetingTemplateKindType.Public
                  )
                }
              />
              <DeleteMeetingTemplateButton
                onDeleteTemplate={reloadTemplates}
                className={classes.button}
                label={intl.formatMessage(messages.deleteTemplateButton)}
                meetingTemplate={meetingTemplate}
                meetingTitle={meetingTemplate.title || ""}
                disabled={
                  !getActionButtons(
                    meetingTemplate.type === MeetingTemplateKindType.Public
                  )
                }
              />
            </PermissionController>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default MeetingTemplateListItem;
