import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    boldText: {
      fontWeight: "bold",
    },
    participantContainer: {
      float: "left",
      marginRight: theme.spacing(2),
    },
    buttonGrid: {
      textAlign: "right",
    },
    button: {
      marginBottom: theme.spacing(1),
    },
  })
);
