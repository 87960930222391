import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
    },
    paperEmpty: {
      minHeight: "80vh",
      textAlign: "center",
      paddingTop: "30vh",
    },
    ul: {
      display: "flex",
      flexDirection: "column-reverse",
      height: "57vh",
      overflow: "scroll",
      [theme.breakpoints.down(815)]: {
        height: "70vh",
      },
    },
    ulsm: {
      height: "90vh",
      overflow: "scroll",
      marginBottom: "35vh",
    },
    noMessagesLabel: {
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: 100,
    },
    replyMessageContainer: {
      maxWidth: "90vh",
      backgroundColor: "white",
      maxHeight: "25vh",
    },
  })
);
