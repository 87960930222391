import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useStyles } from "../../../Views/Dashboard/Components/QuickMenuCard/QuickMenuCard.style";
import CreateMeetingTemplateDialog from "../../Dialogs/CreatingMeetingTemplateDialog/CreateMeetingTemplateDialog";

interface Props {
  title: string;
  icon: JSX.Element;
}

const CreateMeetingQuickCardTemplateButton = (props: Props) => {
  const { title, icon } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleOnCreated = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <CreateMeetingTemplateDialog
        onMeetingCreated={handleOnCreated}
        isDialogOpen={isDialogOpen}
        onDialogClose={() => setIsDialogOpen(false)}
      />
      <ListItem
        button
        divider
        className={classes.hover}
        onClick={() => setIsDialogOpen(true)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ variant: "button" }}
        />
      </ListItem>
    </>
  );
};

export default CreateMeetingQuickCardTemplateButton;
