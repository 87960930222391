/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthenticatedUser,
    AuthenticatedUserFromJSON,
    AuthenticatedUserFromJSONTyped,
    AuthenticatedUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAuthenticationResponse
 */
export interface UserAuthenticationResponse {
    /**
     * The session id used during authentication
     * @type {string}
     * @memberof UserAuthenticationResponse
     */
    sessionId?: string;
    /**
     * The users that was identified by the supplied authentication details (token will always be null)
     * @type {Array<AuthenticatedUser>}
     * @memberof UserAuthenticationResponse
     */
    authenticatedUsers?: Array<AuthenticatedUser>;
}

export function UserAuthenticationResponseFromJSON(json: any): UserAuthenticationResponse {
    return UserAuthenticationResponseFromJSONTyped(json, false);
}

export function UserAuthenticationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthenticationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'authenticatedUsers': !exists(json, 'authenticatedUsers') ? undefined : ((json['authenticatedUsers'] as Array<any>).map(AuthenticatedUserFromJSON)),
    };
}

export function UserAuthenticationResponseToJSON(value?: UserAuthenticationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'authenticatedUsers': value.authenticatedUsers === undefined ? undefined : ((value.authenticatedUsers as Array<any>).map(AuthenticatedUserToJSON)),
    };
}


