/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Invite API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User authority
 * @export
 * @enum {string}
 */
export enum UserAuthorityType {
    AttendMeeting = 'ATTEND_MEETING',
    BookMeeting = 'BOOK_MEETING',
    BookMeetingOther = 'BOOK_MEETING_OTHER',
    SendMessage = 'SEND_MESSAGE',
    CreateUserStaff = 'CREATE_USER_STAFF',
    CreateUserClient = 'CREATE_USER_CLIENT',
    AdminSite = 'ADMIN_SITE',
    AppointAdminSite = 'APPOINT_ADMIN_SITE',
    CustomerSettings = 'CUSTOMER_SETTINGS'
}

export function UserAuthorityTypeFromJSON(json: any): UserAuthorityType {
    return UserAuthorityTypeFromJSONTyped(json, false);
}

export function UserAuthorityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthorityType {
    return json as UserAuthorityType;
}

export function UserAuthorityTypeToJSON(value?: UserAuthorityType | null): any {
    return value as any;
}

