import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    center: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);
