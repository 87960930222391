import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormikProps, useFormik } from "formik";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { messages } from "./CreateTodoDialog.messages";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import TodoFields, { TodoFormikValues } from "../TodoFields/TodoFields";
import TodoValidationSchema from "../TodoFields/Components/Validation/TodoValidationSchema";
import {
  contactToUser,
  meetingParticipantListToUserList,
} from "../../../../../Utils/ModelConverter";
import ButtonLoadingWrapper from "../../../ButtonLoadingWrapper/ButtonLoadingWrapper";
import { todoService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import { Contact } from "../../../../../Models/Contact";
import { MeetingParticipant } from "../../../../../Models/Meeting";
import { User } from "../../../../../Models/User";
import { getUsersIds } from "../../../../../Utils/User";

interface Props {
  meetingId?: string;
  contact?: Contact;
  participants?: MeetingParticipant[];
  onCreated?: () => void;
  isOpen: boolean;
  dialogTitle: string;
  onClose?: () => void;
}

const CreateTodoDialog = (props: Props) => {
  const {
    isOpen,
    onClose,
    dialogTitle,
    onCreated,
    meetingId,
    participants,
    contact,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isCreatingTodoLoading, setIsCreatingTodoLoading] = useState<boolean>(
    false
  );

  const createTodo = (
    formik: FormikProps<TodoFormikValues>,
    title: string,
    description: string,
    assignedTo: string[],
    shouldBeCompletedOn?: Date
  ) => {
    if (customerId && authenticatedUser.user) {
      setIsCreatingTodoLoading(true);
      todoService()
        .createToDos({
          customerId,
          createToDoRequest: {
            title,
            assignedTo,
            description,
            shouldBeCompletedOn,
            createdInMeeting: meetingId,
            assignedBy: authenticatedUser.user.id,
          },
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          })
        )
        .then(() => formik.resetForm())
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(() => {
          setIsCreatingTodoLoading(false);
          if (onCreated) {
            onCreated();
          }
          if (onClose) {
            onClose();
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      assignedTo: contact ? [contactToUser(contact)] : ([] as User[]),
      shouldBeCompletedOn: undefined,
    } as TodoFormikValues,
    validationSchema: TodoValidationSchema,
    onSubmit: (values) => {
      createTodo(
        formik,
        values.title,
        values.description,
        getUsersIds(values.assignedTo),
        values.shouldBeCompletedOn
      );
    },
  });

  const handleClose = () => {
    formik.resetForm();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TodoFields
            formik={formik}
            userOptionList={meetingParticipantListToUserList(participants)}
            disabledFields={contact && ["assignedTo"]}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isCreatingTodoLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isCreatingTodoLoading}
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button variant="outlined" onClick={handleClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTodoDialog;
