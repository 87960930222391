import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useCustomerCurrentBooking } from "../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { messages } from "./CreateMeetingDialog.messages";
import { UserResponse, UserRoleType } from "../../../generated/user-api";
import { MeetingTemplateResponse } from "../../../generated/meetingtemplate-api";
import { useStyles } from "./CreateMeetingDialog.style";
import { UserAuthorityType } from "../../../generated/authentication-api";
import CardHeading from "../../CardHeading/CardHeading";
import { hasPermission } from "../../PermissionController/PermissionController";
import {
  PaginatedContactResponseList,
  SortByType,
  SortOrderType,
  UserStatusType,
} from "../../../generated/contact-api";
import { MeetingResponse } from "../../../generated/meeting-api";
import MeetingFields from "../../Fields/MeetingFields/MeetingFields";
import MeetingValidationSchema from "../../Fields/MeetingFields/Components/Validation/MeetingValidationSchema";
import { createParticipantList } from "../../../Utils/Meeting";
import ButtonLoadingWrapper from "../../Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";
import {
  contactService,
  meetingService,
  meetingTemplateService,
  userService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import { contactToUser } from "../../../Utils/ModelConverter";
import { User } from "../../../Models/User";

interface Props {
  isDialogOpen?: boolean;
  onDialogClose?: () => void;
  onMeetingCreated?: () => void;
  prePopulatedParticipants?: UserResponse[];
  isAdmin?: boolean;
  defaultDate?: Date;
}

const CreateMeetingDialog = (props: Props) => {
  const {
    isDialogOpen,
    onDialogClose,
    prePopulatedParticipants,
    onMeetingCreated,
    isAdmin,
    defaultDate,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [clientContactList, setClientContactList] = useState<User[]>();
  const [externalContactList, setExternalContactList] = useState<User[]>();
  const [templateList, setTemplateList] = useState<MeetingTemplateResponse[]>(
    []
  );
  const [staffList, setStaffList] = useState<UserResponse[]>();
  const [bookingOffer, setBookingOffer] = useState<number>();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [
    isCreatingMeetingLoading,
    setIsCreatingMeetingLoading,
  ] = useState<boolean>(false);
  const [customerupdates, setCustomerupdates] = useCustomerCurrentBooking();

  const setUpInitialStaffParticipants = () => {
    // If user has authority attend meeting, she should be added to the staff field
    if (
      hasPermission({
        currentUser: authenticatedUser.user,
        requiredAuthoritiesStaff: [UserAuthorityType.AttendMeeting],
        requiredAuthoritiesClient: [UserAuthorityType.AttendMeeting],
        requiredAuthoritiesExternal: [UserAuthorityType.AttendMeeting],
      }) &&
      !isAdmin
    ) {
      return [authenticatedUser.user];
    }
    return [];
  };

  const setUpMeetingLeader = () => {
    // If user has authority bookMeetingOther, send undefined as value for meetingleaders
    if (
      hasPermission({
        currentUser: authenticatedUser.user,
        requiredAuthoritiesStaff: [UserAuthorityType.BookMeetingOther],
      })
    ) {
      return undefined;
    }
    return authenticatedUser.user;
  };

  const filterByRole = (userRole: UserRoleType, users?: User[]): User[] => {
    return users?.filter((user) => user.userRole === userRole) || [];
  };

  const initialValues = {
    title: "",
    startDateTime: new Date(),
    duration: 30,
    participants: filterByRole(UserRoleType.Client, prePopulatedParticipants),
    externalParticipants: filterByRole(
      UserRoleType.External,
      prePopulatedParticipants
    ),
    staffParticipants: setUpInitialStaffParticipants() as UserResponse[],
  };

  const filterContacts = (
    contactResponseList: PaginatedContactResponseList,
    filter: UserRoleType[]
  ): User[] => {
    if (
      !customerId &&
      authenticatedUser?.user &&
      !hasPermission({
        currentUser: authenticatedUser.user,
        requiredAuthoritiesStaff: [UserAuthorityType.BookMeeting],
      })
    ) {
      // If user does not have auth bookMeeting, meaning it is booking for someone else, dont show contacts
      return [];
    }
    const users: User[] = [];
    contactResponseList.data?.forEach((contact) => {
      if (filter.includes(contact.userRole)) {
        users.push(contactToUser(contact));
      }
    });
    return users;
  };

  const loadContactList = () => {
    if (customerId && authenticatedUser.user?.id) {
      contactService()
        .listContactsForUserDecorated(authenticatedUser.user, {
          customerId,
          userStatus: UserStatusType.Active,
          userId: authenticatedUser.user.id,
          sortOrder: SortOrderType.Ascending,
          sortBy: SortByType.Popularity,
          pageSize: 1000, // maximum contacts loaded
        })
        .then((res) =>
          filterContacts(res, [UserRoleType.Client, UserRoleType.External])
        )
        .then((users) => {
          setClientContactList(filterByRole(UserRoleType.Client, users));
          setExternalContactList(filterByRole(UserRoleType.External, users));
        });
    }
  };

  const loadStaff = (): void => {
    if (customerId) {
      userService()
        .listUsersByCustomerAndSearchParams({
          pageSize: 1000,
          page: 1,
          customerId,
          userStatus: UserStatusType.Active,
          userRole: UserRoleType.Staff,
          userAuthorities: [UserAuthorityType.AttendMeeting],
        })
        .then((res) => {
          const filteredList = isAdmin
            ? res.data
            : res.data?.filter(
                (user: UserResponse) => user.id !== authenticatedUser.user?.id
              );
          setStaffList(filteredList);
        });
    }
  };

  const LoadMeetingTemplates = () => {
    if (customerId && authenticatedUser.user?.id) {
      meetingTemplateService()
        .listMeetingTemplates({ customerId })
        .then((res) => setTemplateList(res));
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      loadContactList();
      loadStaff();
      LoadMeetingTemplates();
    }
  }, [isDialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues,
    validationSchema: MeetingValidationSchema,
    onSubmit: (values) => {
      if (customerId && authenticatedUser?.user) {
        setIsCreatingMeetingLoading(true);
        meetingService()
          .createMeeting({
            customerId,
            meetingRequest: {
              title: values.title,
              duration: values.duration,
              startDateTime: values.startDateTime,
              participants: createParticipantList([
                ...values.participants,
                ...values.externalParticipants,
                ...values.staffParticipants,
              ]),
            },
          })
          .then((res) => {
            const arrayCopy: MeetingResponse[] =
              customerupdates.MeetingResponse;
            arrayCopy.push(res);
            setCustomerupdates({ MeetingResponse: arrayCopy });
          })
          .then(() => {
            formik.resetForm();
            enqueueSnackbar(intl.formatMessage(messages.success), {
              variant: "success",
            });
          })
          .then(onMeetingCreated)
          .then(loadContactList)
          .catch(() => {
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            });
          })
          .finally(() => setIsCreatingMeetingLoading(false));
      }
    },
  });
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as number;
    setBookingOffer(selected);
    formik.resetForm({
      values: { ...formik.values, ...templateList[selected] },
    });
  };

  const TemplateSelector = () => (
    <>
      <CardHeading>
        {intl.formatMessage(messages.bookingOfferHeader).toUpperCase()}
      </CardHeading>
      <FormControl variant="outlined" fullWidth>
        <Select
          id="visit-templates-select"
          value={bookingOffer}
          onChange={handleChange}
          defaultValue=""
        >
          {templateList.map((record: MeetingTemplateResponse, index) => {
            return <MenuItem value={index}>{record.title}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <Divider className={classes.divider} />
    </>
  );

  useEffect(() => {
    if (defaultDate) {
      const copy = formik.values;
      copy.startDateTime = new Date(defaultDate);
      formik.setValues(copy);
    }
  }, [defaultDate]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isDialogOpen || false}
      fullScreen={fullScreen}
      onClose={onDialogClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TemplateSelector />
          <MeetingFields
            formik={formik}
            clientOptionList={clientContactList}
            externalOptionList={externalContactList}
            staffOptionList={staffList}
            meetingLeader={setUpMeetingLeader()}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isCreatingMeetingLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isCreatingMeetingLoading}
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button
            color="primary"
            onClick={onDialogClose}
            disabled={isCreatingMeetingLoading}
          >
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateMeetingDialog;
