import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flexGrow: 1,
    },
    logo: {
      height: 28,
    },
    button: {
      "&:hover": {
        background: "none",
        cursor: "pointer",
      },
    },
  })
);
