import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DeleteMeetingTemplateDialog from "./DeleteMeetingTemplateDialog";
import PermissionController from "../../PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import { MeetingTemplateResponse } from "../../../generated/meetingtemplate-api";

interface Props {
  label: string;
  meetingTemplate: MeetingTemplateResponse;
  meetingTitle: string;
  className?: string;
  onDeleteTemplate?: () => void;
  disabled?: boolean;
}

const DeleteMeetingTemplateButton = (props: Props) => {
  const {
    meetingTemplate,
    meetingTitle,
    label,
    className,
    onDeleteTemplate,
    disabled,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  return (
    <PermissionController
      allowedRoles={[UserRoleType.Staff]}
      atLeastOneOfAuthoritiesStaff={[
        UserAuthorityType.BookMeeting,
        UserAuthorityType.AdminSite,
      ]}
    >
      <DeleteMeetingTemplateDialog
        isOpen={isDialogOpen}
        meetingId={meetingTemplate.id}
        meetingTitle={meetingTitle}
        onClose={() => setIsDialogOpen(false)}
        onDeleteTemplate={onDeleteTemplate}
      />
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        className={className}
        onClick={handleOpenDialog}
        disabled={disabled}
      >
        {label}
      </Button>
    </PermissionController>
  );
};

export default DeleteMeetingTemplateButton;
