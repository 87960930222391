import { defineMessages } from "react-intl";

export const messages = defineMessages({
  statusGroupLabel: {
    id: "modifyuserdialog.status.group.label",
    defaultMessage: "User status",
    description: "label user status group",
  },
  warningMessage: {
    id: "modifyuserdialog.status.closed.warning.label",
    defaultMessage:
      "This user will be permanently deleted in {numberOfDays} days.",
    description: "Warning Label when status changed to  closed",
  },
});
