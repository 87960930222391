import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { CustomRouteProps } from "../Routes.config";
import { useCurrentPath } from "../../../Providers/CurrentPathProvider/CurrentPathProvider";

const PublicRoute = (route: CustomRouteProps) => {
  const location = useLocation();
  const setPath = useCurrentPath()[1];
  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Route {...route} />;
};

export default PublicRoute;
