import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { messages } from "./UpdateWebdocMeetingButton.messages";
import PermissionController from "../../PermissionController/PermissionController";
import { UserRoleType } from "../../../generated/user-api";
import { Meeting } from "../../../Models/Meeting";
import { useCustomerFeatures } from "../../../Providers/CustomerProvider/CustomerProvider";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { getParticipantByUserId } from "../../../Utils/Meeting";

interface Props {
  meeting: Meeting;
  className?: string;
}

const UpdateWebdocMeetingButton = (props: Props) => {
  const { meeting, className } = props;
  const intl = useIntl();
  const customerFeatures = useCustomerFeatures();
  const [authenticatedUser] = useAuthenticatedUser();

  const isAuthenticatedUserExternallyAdded = getParticipantByUserId(
    authenticatedUser.user?.id,
    meeting.participants
  )?.isExternallyAdded;

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const updateLink = customerFeatures.find(
      (feature) =>
        feature.feature === CustomerFeatureType.WebdocUpdateBookingButton
    )?.value;

    if (updateLink) {
      window.open(updateLink, "_blank");
    }
  };

  if (!isAuthenticatedUserExternallyAdded) {
    return null;
  }

  return (
    <PermissionController allowedRoles={[UserRoleType.Client]}>
      <FeatureController
        requiredFeature={CustomerFeatureType.WebdocUpdateBookingButton}
      >
        <Button
          size="small"
          color="primary"
          className={className}
          variant="outlined"
          onClick={handleOnClick}
          startIcon={<OpenInNewIcon />}
        >
          {intl.formatMessage(messages.buttonLabel)}
        </Button>
      </FeatureController>
    </PermissionController>
  );
};

export default UpdateWebdocMeetingButton;
