import React, { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  useTheme,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { Lock, LockOpen } from "@material-ui/icons";

interface Props {
  id: string;
  label: string;
  lockedValue: string;
  placeholder?: string;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  errorMsg?: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
  inputMode?: "text" | "tel" | "email";
  disableLock?: boolean;
  loadLockedData?: () => void;
  autoComplete?: "on" | "off";
}

const LockedTextField = (props: Props) => {
  const [isLocked, setIsLocked] = useState<boolean>(true);
  const theme = useTheme();

  const {
    id,
    label,
    formik,
    onBlur,
    errorMsg,
    disabledFields,
    inputMode,
    disableLock,
    lockedValue,
    loadLockedData,
    placeholder,
    autoComplete,
  } = props;
  const { errors, touched } = formik;

  const handleLockedField = () => {
    setIsLocked(!isLocked);
  };

  useEffect(() => {
    if (!isLocked && loadLockedData) {
      loadLockedData();
    }
  }, [isLocked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MuiTextField
      id={id}
      name={id}
      label={label}
      InputLabelProps={{
        style: { color: theme.palette.text.secondary },
      }}
      placeholder={placeholder}
      autoComplete={autoComplete || "off"}
      value={!disableLock && isLocked ? lockedValue : formik.values[id]}
      onChange={formik.handleChange}
      onBlur={onBlur}
      error={!!(errors[id] && touched[id])}
      helperText={touched[id] && errors[id] ? errorMsg : null}
      variant="outlined"
      fullWidth
      inputMode={inputMode || "text"}
      disabled={(!disableLock && isLocked) || disabledFields?.includes(id)}
      InputProps={{
        endAdornment: !disableLock ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle locked field"
              onClick={handleLockedField}
              edge="end"
            >
              {isLocked ? <Lock /> : <LockOpen />}
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

export default LockedTextField;
