/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Contact API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContactResponse
 */
export interface ContactResponse {
    /**
     * The UUID of this contact.
     * @type {string}
     * @memberof ContactResponse
     */
    contactId: string;
    /**
     * The user id of this contact.
     * @type {string}
     * @memberof ContactResponse
     */
    userId: string;
    /**
     * First name of this contact.
     * @type {string}
     * @memberof ContactResponse
     */
    firstName?: string;
    /**
     * Last name of this contact.
     * @type {string}
     * @memberof ContactResponse
     */
    lastName?: string;
    /**
     * Title of this contact. (Null if contact has role CLIENT)
     * @type {string}
     * @memberof ContactResponse
     */
    workTitle?: string;
    /**
     * E-mail address of this contact. (Null if contact has role STAFF)
     * @type {string}
     * @memberof ContactResponse
     */
    email?: string;
    /**
     * Mobile phone number of this contact. (Null if contact has role STAFF)
     * @type {string}
     * @memberof ContactResponse
     */
    mobilePhoneNumber?: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof ContactResponse
     */
    userRole: UserRoleType;
    /**
     * 
     * @type {UserStatusType}
     * @memberof ContactResponse
     */
    userStatus: UserStatusType;
    /**
     * Number of interactions with this contact.
     * @type {number}
     * @memberof ContactResponse
     */
    interactionCount: number;
    /**
     * Date and time of last interaction with this contact.
     * @type {Date}
     * @memberof ContactResponse
     */
    lastContactOn: Date;
    /**
     * Date and time this contact was created
     * @type {Date}
     * @memberof ContactResponse
     */
    createdOn: Date;
}

export function ContactResponseFromJSON(json: any): ContactResponse {
    return ContactResponseFromJSONTyped(json, false);
}

export function ContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactId': json['contactId'],
        'userId': json['userId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'workTitle': !exists(json, 'workTitle') ? undefined : json['workTitle'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'userRole': UserRoleTypeFromJSON(json['userRole']),
        'userStatus': UserStatusTypeFromJSON(json['userStatus']),
        'interactionCount': json['interactionCount'],
        'lastContactOn': (new Date(json['lastContactOn'])),
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function ContactResponseToJSON(value?: ContactResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactId': value.contactId,
        'userId': value.userId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'workTitle': value.workTitle,
        'email': value.email,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'userRole': UserRoleTypeToJSON(value.userRole),
        'userStatus': UserStatusTypeToJSON(value.userStatus),
        'interactionCount': value.interactionCount,
        'lastContactOn': (value.lastContactOn.toISOString()),
        'createdOn': (value.createdOn.toISOString()),
    };
}


