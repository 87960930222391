import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "cancelmeetingdialog.title",
    defaultMessage: "Cancel meeting",
    description: "Title cancel meeting dialog",
  },
  content: {
    id: "cancelmeetingdialog.description",
    defaultMessage: "Are you sure you want to cancel this meeting?",
    description: "Description in the cancel meeting dialog to verify action",
  },
  cancelButtonLabel: {
    id: "cancelmeetingdialog.button.cancel.label",
    defaultMessage: "Go back",
    description: "label for closing dialog",
  },
  submitButtonLabel: {
    id: "cancelmeetingdialog.button.submit.label",
    defaultMessage: "Cancel meeting",
    description: "label for verify cancel of meeting",
  },
  success: {
    id: "cancelmeetingdialog.success",
    defaultMessage: "Meeting was cancelled",
    description: "Snackbar displayed when meeting is succesfully cancelled",
  },
  generalError: {
    id: "cancelmeetingdialog.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occured for cancelling meeting",
  },
});
