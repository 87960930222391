import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { UserAgreementResponse } from "../../../../generated/customercontent-api";
import { useStyles } from "./ConsentAgreementListItem.styles";
import { messages } from "./ConsentAgreementListItem.messages";
import { getFormattedDate } from "../../../../Utils/Meeting";

export interface Props {
  userAgreement: UserAgreementResponse;
}

const ConsentAgreementListItem = (props: Props) => {
  const { userAgreement } = props;
  const classes = useStyles();
  const intl = useIntl();

  const getValidFromLabel = (validFrom: Date) => {
    return `${intl.formatMessage(
      messages.privacyPolicyValidFromLabel
    )}: ${getFormattedDate(validFrom)}`;
  };

  return (
    <>
      <Accordion key={userAgreement.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={4} className={classes.chipContainer}>
              <Chip
                size="small"
                className={
                  userAgreement.validFrom.getTime() <= Date.now()
                    ? classes.chip
                    : classes.notYetValidFromChip
                }
                label={getValidFromLabel(userAgreement.validFrom)}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography component="h6">{userAgreement.title}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" component="div">
            <ReactQuill
              theme="bubble"
              readOnly
              modules={{ toolbar: false }}
              value={userAgreement.contents}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ConsentAgreementListItem;
