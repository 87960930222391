/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUserRequest,
    CreateUserRequestFromJSON,
    CreateUserRequestToJSON,
    GeneratePasswordRequest,
    GeneratePasswordRequestFromJSON,
    GeneratePasswordRequestToJSON,
    HsaIdResponse,
    HsaIdResponseFromJSON,
    HsaIdResponseToJSON,
    ListPersonNumberRequest,
    ListPersonNumberRequestFromJSON,
    ListPersonNumberRequestToJSON,
    PaginatedUserListResponse,
    PaginatedUserListResponseFromJSON,
    PaginatedUserListResponseToJSON,
    PersonNumberResponse,
    PersonNumberResponseFromJSON,
    PersonNumberResponseToJSON,
    SortByType,
    SortByTypeFromJSON,
    SortByTypeToJSON,
    SortOrderType,
    SortOrderTypeFromJSON,
    SortOrderTypeToJSON,
    UpdatePasswordRequest,
    UpdatePasswordRequestFromJSON,
    UpdatePasswordRequestToJSON,
    UpdateUserHsaIdRequest,
    UpdateUserHsaIdRequestFromJSON,
    UpdateUserHsaIdRequestToJSON,
    UpdateUserRequest,
    UpdateUserRequestFromJSON,
    UpdateUserRequestToJSON,
    UpdateUserStatusRequest,
    UpdateUserStatusRequestFromJSON,
    UpdateUserStatusRequestToJSON,
    UserAuthorityType,
    UserAuthorityTypeFromJSON,
    UserAuthorityTypeToJSON,
    UserResponse,
    UserResponseFromJSON,
    UserResponseToJSON,
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeToJSON,
    UserStatusType,
    UserStatusTypeFromJSON,
    UserStatusTypeToJSON,
    VerifyPersonNumberResponse,
    VerifyPersonNumberResponseFromJSON,
    VerifyPersonNumberResponseToJSON,
    VerifyPersonNumberStartResponse,
    VerifyPersonNumberStartResponseFromJSON,
    VerifyPersonNumberStartResponseToJSON,
} from '../models';

export interface ActivatePasswordRequest {
    customerId: string;
    userId: string;
}

export interface CheckVerifyPersonNumberIdpSessionRequest {
    sessionId: string;
}

export interface CreateUserOperationRequest {
    customerId: string;
    body: CreateUserRequest;
}

export interface DeactivatePasswordRequest {
    customerId: string;
    userId: string;
}

export interface DeleteUserRequest {
    customerId: string;
    userId: string;
}

export interface GeneratePasswordOperationRequest {
    customerId: string;
    generatePasswordRequest: GeneratePasswordRequest;
}

export interface GetHsaIdRequest {
    userId: string;
}

export interface GetPersonNumberRequest {
    userId: string;
}

export interface GetUserByIdRequest {
    customerId: string;
    userId: string;
}

export interface ListPersonNumbersRequest {
    listPersonNumberRequest: ListPersonNumberRequest;
}

export interface ListUsersByCustomerAndSearchParamsRequest {
    customerId: string;
    userRole: UserRoleType;
    userStatus?: UserStatusType;
    personNumber?: string;
    name?: string;
    email?: string;
    userAuthorities?: Array<UserAuthorityType>;
    sortBy?: SortByType;
    sortOrder?: SortOrderType;
    page?: number;
    pageSize?: number;
}

export interface StartVerifyPersonNumberIdpSessionRequest {
    personNumber?: string;
}

export interface UpdateHsaIdRequest {
    userId: string;
    updateUserHsaIdRequest: UpdateUserHsaIdRequest;
}

export interface UpdatePasswordOperationRequest {
    customerId: string;
    updatePasswordRequest: UpdatePasswordRequest;
}

export interface UpdateUserOperationRequest {
    customerId: string;
    userId: string;
    body: UpdateUserRequest;
}

export interface UpdateUserStatusOperationRequest {
    customerId: string;
    userId: string;
    body: UpdateUserStatusRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Activate user for password login
     * Activate user for password login
     */
    async activatePasswordRaw(requestParameters: ActivatePasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling activatePassword.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling activatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{customerId}/password/activate/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate user for password login
     * Activate user for password login
     */
    async activatePassword(requestParameters: ActivatePasswordRequest): Promise<void> {
        await this.activatePasswordRaw(requestParameters);
    }

    /**
     * Contacts the IdP using the provided sessionId to check the result of the authentication session.
     * Check status of person number verification of IdP session.
     */
    async checkVerifyPersonNumberIdpSessionRaw(requestParameters: CheckVerifyPersonNumberIdpSessionRequest): Promise<runtime.ApiResponse<VerifyPersonNumberResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling checkVerifyPersonNumberIdpSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.sessionId !== undefined) {
            queryParameters['sessionId'] = requestParameters.sessionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/personnumber/verify`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyPersonNumberResponseFromJSON(jsonValue));
    }

    /**
     * Contacts the IdP using the provided sessionId to check the result of the authentication session.
     * Check status of person number verification of IdP session.
     */
    async checkVerifyPersonNumberIdpSession(requestParameters: CheckVerifyPersonNumberIdpSessionRequest): Promise<VerifyPersonNumberResponse> {
        const response = await this.checkVerifyPersonNumberIdpSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF and authorities CREATE_USER_CLIENT or CREATE_USER_STAFF.
     * Create user
     */
    async createUserRaw(requestParameters: CreateUserOperationRequest): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createUser.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF and authorities CREATE_USER_CLIENT or CREATE_USER_STAFF.
     * Create user
     */
    async createUser(requestParameters: CreateUserOperationRequest): Promise<UserResponse> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deactivate user for password login
     * Deactivate user for password login
     */
    async deactivatePasswordRaw(requestParameters: DeactivatePasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deactivatePassword.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deactivatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{customerId}/password/deactivate/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate user for password login
     * Deactivate user for password login
     */
    async deactivatePassword(requestParameters: DeactivatePasswordRequest): Promise<void> {
        await this.deactivatePasswordRaw(requestParameters);
    }

    /**
     * NOT IMPLEMENTED.
     * Delete user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling deleteUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{customerId}/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * NOT IMPLEMENTED.
     * Delete user
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * Generate new password for user and send it to registered phone number
     * Generate new password for user
     */
    async generatePasswordRaw(requestParameters: GeneratePasswordOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling generatePassword.');
        }

        if (requestParameters.generatePasswordRequest === null || requestParameters.generatePasswordRequest === undefined) {
            throw new runtime.RequiredError('generatePasswordRequest','Required parameter requestParameters.generatePasswordRequest was null or undefined when calling generatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/{customerId}/password`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratePasswordRequestToJSON(requestParameters.generatePasswordRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Generate new password for user and send it to registered phone number
     * Generate new password for user
     */
    async generatePassword(requestParameters: GeneratePasswordOperationRequest): Promise<void> {
        await this.generatePasswordRaw(requestParameters);
    }

    /**
     * Users can always access themselves.
     * Get a HSA-ID by userId
     */
    async getHsaIdRaw(requestParameters: GetHsaIdRequest): Promise<runtime.ApiResponse<HsaIdResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getHsaId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/hsaid/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HsaIdResponseFromJSON(jsonValue));
    }

    /**
     * Users can always access themselves.
     * Get a HSA-ID by userId
     */
    async getHsaId(requestParameters: GetHsaIdRequest): Promise<HsaIdResponse> {
        const response = await this.getHsaIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Users can always access themselves.
     * Get a person number by userId
     */
    async getPersonNumberRaw(requestParameters: GetPersonNumberRequest): Promise<runtime.ApiResponse<PersonNumberResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getPersonNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/personnumber/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonNumberResponseFromJSON(jsonValue));
    }

    /**
     * Users can always access themselves.
     * Get a person number by userId
     */
    async getPersonNumber(requestParameters: GetPersonNumberRequest): Promise<PersonNumberResponse> {
        const response = await this.getPersonNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * Users with role CLIENT or EXTERNAL can only access themselves.
     * Get a user by id
     */
    async getUserByIdRaw(requestParameters: GetUserByIdRequest): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getUserById.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{customerId}/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Users with role CLIENT or EXTERNAL can only access themselves.
     * Get a user by id
     */
    async getUserById(requestParameters: GetUserByIdRequest): Promise<UserResponse> {
        const response = await this.getUserByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * This makes it possible for a staff to access their contacts (only clients) person number.
     * List person numbers by userIds
     */
    async listPersonNumbersRaw(requestParameters: ListPersonNumbersRequest): Promise<runtime.ApiResponse<Array<PersonNumberResponse>>> {
        if (requestParameters.listPersonNumberRequest === null || requestParameters.listPersonNumberRequest === undefined) {
            throw new runtime.RequiredError('listPersonNumberRequest','Required parameter requestParameters.listPersonNumberRequest was null or undefined when calling listPersonNumbers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/personnumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListPersonNumberRequestToJSON(requestParameters.listPersonNumberRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonNumberResponseFromJSON));
    }

    /**
     * This makes it possible for a staff to access their contacts (only clients) person number.
     * List person numbers by userIds
     */
    async listPersonNumbers(requestParameters: ListPersonNumbersRequest): Promise<Array<PersonNumberResponse>> {
        const response = await this.listPersonNumbersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF.
     * List users by customer and search parameters
     */
    async listUsersByCustomerAndSearchParamsRaw(requestParameters: ListUsersByCustomerAndSearchParamsRequest): Promise<runtime.ApiResponse<PaginatedUserListResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listUsersByCustomerAndSearchParams.');
        }

        if (requestParameters.userRole === null || requestParameters.userRole === undefined) {
            throw new runtime.RequiredError('userRole','Required parameter requestParameters.userRole was null or undefined when calling listUsersByCustomerAndSearchParams.');
        }

        const queryParameters: any = {};

        if (requestParameters.userRole !== undefined) {
            queryParameters['userRole'] = requestParameters.userRole;
        }

        if (requestParameters.userStatus !== undefined) {
            queryParameters['userStatus'] = requestParameters.userStatus;
        }

        if (requestParameters.personNumber !== undefined) {
            queryParameters['personNumber'] = requestParameters.personNumber;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.userAuthorities) {
            queryParameters['userAuthorities'] = requestParameters.userAuthorities;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedUserListResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF.
     * List users by customer and search parameters
     */
    async listUsersByCustomerAndSearchParams(requestParameters: ListUsersByCustomerAndSearchParamsRequest): Promise<PaginatedUserListResponse> {
        const response = await this.listUsersByCustomerAndSearchParamsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Start an verify session with an external Identity Provider to verify a users person number.
     * Verify user person number using IdP
     */
    async startVerifyPersonNumberIdpSessionRaw(requestParameters: StartVerifyPersonNumberIdpSessionRequest): Promise<runtime.ApiResponse<VerifyPersonNumberStartResponse>> {
        const queryParameters: any = {};

        if (requestParameters.personNumber !== undefined) {
            queryParameters['personNumber'] = requestParameters.personNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/personnumber/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyPersonNumberStartResponseFromJSON(jsonValue));
    }

    /**
     * Start an verify session with an external Identity Provider to verify a users person number.
     * Verify user person number using IdP
     */
    async startVerifyPersonNumberIdpSession(requestParameters: StartVerifyPersonNumberIdpSessionRequest): Promise<VerifyPersonNumberStartResponse> {
        const response = await this.startVerifyPersonNumberIdpSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update HSA-ID for a specific user.
     * Update HSA-ID by userId
     */
    async updateHsaIdRaw(requestParameters: UpdateHsaIdRequest): Promise<runtime.ApiResponse<HsaIdResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateHsaId.');
        }

        if (requestParameters.updateUserHsaIdRequest === null || requestParameters.updateUserHsaIdRequest === undefined) {
            throw new runtime.RequiredError('updateUserHsaIdRequest','Required parameter requestParameters.updateUserHsaIdRequest was null or undefined when calling updateHsaId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/hsaid/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserHsaIdRequestToJSON(requestParameters.updateUserHsaIdRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HsaIdResponseFromJSON(jsonValue));
    }

    /**
     * Update HSA-ID for a specific user.
     * Update HSA-ID by userId
     */
    async updateHsaId(requestParameters: UpdateHsaIdRequest): Promise<HsaIdResponse> {
        const response = await this.updateHsaIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update password for authenticated user
     * Update password for user
     */
    async updatePasswordRaw(requestParameters: UpdatePasswordOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updatePassword.');
        }

        if (requestParameters.updatePasswordRequest === null || requestParameters.updatePasswordRequest === undefined) {
            throw new runtime.RequiredError('updatePasswordRequest','Required parameter requestParameters.updatePasswordRequest was null or undefined when calling updatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/{customerId}/password`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordRequestToJSON(requestParameters.updatePasswordRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update password for authenticated user
     * Update password for user
     */
    async updatePassword(requestParameters: UpdatePasswordOperationRequest): Promise<void> {
        await this.updatePasswordRaw(requestParameters);
    }

    /**
     * Requires user with role STAFF or CLIENT. Users with CLIENT can only update their own data.
     * Updated user
     */
    async updateUserRaw(requestParameters: UpdateUserOperationRequest): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/{customerId}/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF or CLIENT. Users with CLIENT can only update their own data.
     * Updated user
     */
    async updateUser(requestParameters: UpdateUserOperationRequest): Promise<UserResponse> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user with role STAFF or CLIENT. Users with CLIENT can only change their own status.
     * Update status of user
     */
    async updateUserStatusRaw(requestParameters: UpdateUserStatusOperationRequest): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateUserStatus.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserStatus.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateUserStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/status/{customerId}/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserStatusRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * Requires user with role STAFF or CLIENT. Users with CLIENT can only change their own status.
     * Update status of user
     */
    async updateUserStatus(requestParameters: UpdateUserStatusOperationRequest): Promise<UserResponse> {
        const response = await this.updateUserStatusRaw(requestParameters);
        return await response.value();
    }

}
