import React from "react";
import { Button, FilledInput } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import { messages } from "./SendChatMessage.messages";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useStyles } from "./SendChatMessage.style";
import { meetingChatService } from "../../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  meetingId: string;
  onMessageSent?: (message: string) => void;
}

const SendChatMessage = (props: Props) => {
  const intl = useIntl();
  const { meetingId, onMessageSent } = props;
  const initialValues = { chatMessage: "" };
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const sendMessage = (message: string) => {
    if (customerId) {
      meetingChatService()
        .createChatMessage({
          customerId,
          meetingId,
          createChatMessageRequest: { message },
        })
        .then(() => onMessageSent && onMessageSent(message))
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          })
        );
    }
  };

  // TODO add validation maximum 8000 characters
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (values?.chatMessage && values.chatMessage !== "") {
        sendMessage(values.chatMessage);
        formik.setFieldValue("chatMessage", "");
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <FilledInput
        id="chatMessage"
        name="chatMessage"
        inputProps={{ style: { fontSize: 13 } }}
        placeholder={intl.formatMessage(messages.chatPlaceholder)}
        value={formik.values.chatMessage}
        onChange={formik.handleChange}
        className={classes.inputText}
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.ctrlKey !== true) {
            formik.submitForm();
            e.preventDefault();
          }
          if (e.key === "Enter" && e.ctrlKey === true) {
            formik.setValues({ chatMessage: `${formik.values.chatMessage}\n` });
          }
        }}
        fullWidth
        multiline
        rowsMax={5}
        autoComplete="off"
      />
      <Box component="div" className={classes.buttonEnd}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
        >
          {intl.formatMessage(messages.sendChat)}
        </Button>
      </Box>
    </form>
  );
};

export default SendChatMessage;
