import React, { useEffect, useState } from "react";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import BlurOffIcon from "@material-ui/icons/BlurOff";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { useIntl } from "react-intl";
import RoomWrapper from "@vonage/video-express/dist/mp/room";
import { SpeedDialAction } from "@material-ui/lab";
import { useStyles } from "./BackgroundSpedDialAction.style";
import { messages } from "./BackgroundSpedDialAction.messages";
import { videoFilterService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import {
  FileMetadata,
  PaginatedFilterList,
} from "../../../../../../generated/video-api";
import MenuItemContent from "./Component/MenuItemContent/MenuItemContent";

interface Props {
  open: boolean;
  delay: number;
  room: RoomWrapper;
}

export default function BackgroundSpedDialAction(props: Props) {
  const { open, delay, room } = props;
  const intl = useIntl();
  const classes = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef: any = React.useRef(null);// eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const customerId = useCustomerId();
  const [paginatedFilter, setPaginatedFilter] = useState<PaginatedFilterList>();
  const [hasFilter, setHasFilter] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  const title = hasFilter
    ? intl.formatMessage(messages.disableFilter)
    : intl.formatMessage(messages.enableFilter);

  const blobToDataURL = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
      reader.onabort = () => reject(new Error("Read aborted"));
      reader.readAsDataURL(blob);
    });
  };

  const loadVideoFilterList = () => {
    if (customerId) {
      videoFilterService()
        .listfilter({
          customerId,
          page: 1,
          pageSize: 10,
        })
        .then((res) => {
          setPaginatedFilter(res);
        });
    }
  };

  const loadImage = (custId: string, fileId: string) => {
    return videoFilterService()
      .downloadFilterRaw({
        customerId: custId,
        fileId,
      })
      .then((res) => Promise.resolve(res.raw.blob()))
      .then(blobToDataURL);
  };

  const toggleFilter = () => {
    if (hasFilter) {
      room.camera.clearVideoFilter().then(() => setHasFilter(false));
    } else if (selectedImage) {
      room.camera
        .setVideoFilter({
          type: "backgroundReplacement",
          backgroundImgUrl: selectedImage,
        })
        .then(() => setHasFilter(true));
    } else {
      room.camera
        .setVideoFilter({
          type: "backgroundBlur",
          blurStrength: "high",
        })
        .then(() => setHasFilter(true));
    }
  };

  useEffect(() => {
    loadVideoFilterList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (file: FileMetadata, index: number) => {
    setSelectedIndex(index);
    setIsMenuOpen(false);
    if (customerId) {
      loadImage(customerId, file.fileId).then((dataUrl) => {
        setSelectedImage(dataUrl);
        room.camera
          .setVideoFilter({
            type: "backgroundReplacement",
            backgroundImgUrl: dataUrl,
          })
          .then(() => setHasFilter(true));
      });
    }
  };

  const handleSelectBlur = () => {
    setSelectedIndex(undefined);
    setSelectedImage(undefined);
    setIsMenuOpen(false);
    toggleFilter();
  };

  const handleToggle = () => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  };

  // is used for all roles right now,in future this single button should be configured to be used
  // for all roles without staffs, this is to be able to use video background for staffs.
  return (
    <SpeedDialAction
      key="background-filter"
      icon={
        hasFilter ? (
          <div className={classes.container}>
            <BlurOffIcon className={classes.icon} />
            {title}
          </div>
        ) : (
          <div className={classes.container}>
            <BlurOnIcon className={classes.icon} />
            {title}
          </div>
        )
      }
      onClick={toggleFilter}
      open={open}
      delay={delay}
      classes={{ fab: classes.fab }}
      FabProps={{
        variant: "extended",
      }}
      TooltipClasses={{ tooltip: classes.hidden }}
      className={classes.fabContainer}
    />
  );

  // this button should be used by staffs to enable video backgrounds
  return (
    <>
      <ButtonGroup
        disableElevation
        style={{
          margin: "8px",
          color: "white",
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Tooltip title={title} aria-label="add">
          <IconButton
            edge="start"
            color="primary"
            aria-label="mic"
            onClick={toggleFilter}
            className={`${classes.arrowButton} ${
              !hasFilter ? classes.disabledButton : ""
            }
        `}
          >
            {!hasFilter ? (
              <BlurOffIcon fontSize="inherit" />
            ) : (
              <BlurOnIcon fontSize="inherit" />
            )}
          </IconButton>
        </Tooltip>
        <IconButton
          color="secondary"
          size="small"
          aria-controls={isMenuOpen ? "split-button-menu" : undefined}
          aria-expanded={isMenuOpen ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.arrowButton}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </ButtonGroup>

      <Paper>
        <Menu
          id="split-button-menu"
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          <MenuItem
            key="none"
            selected={selectedIndex === undefined}
            onClick={handleSelectBlur}
            className={classes.selected}
          >
            <ListItemIcon>
              <BlurOnIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Background blur" />
          </MenuItem>
          {paginatedFilter?.data?.map((file, index) => (
            <MenuItem
              key={file.fileId}
              selected={index === selectedIndex}
              onClick={() => handleSelect(file, index)}
              className={classes.selected}
            >
              <MenuItemContent file={file} />
            </MenuItem>
          ))}
        </Menu>
      </Paper>
    </>
  );
}
