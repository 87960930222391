import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
import { Grid, ListItem, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import SwitchButton from "../../Views/CustomerSettings/SwitchButton/SwitchButton";
import { messages } from "./CustomerFeatures.messages";
import { customerSettingsServiceToken } from "../../Providers/ServiceProvider/ServiceProvider";
import { useStyles } from "./CustomerFeatures.styles";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  CustomerFeature,
  CustomerFeatureResponse,
  CustomerFeatureType,
} from "../../generated/customersettings-api";
import { hasPermission } from "../PermissionController/PermissionController";
import { RoutePath } from "../Routes/RoutePath";
import {
  FunctionsConfig,
  LoginConfig,
  MeetingRoomConfig,
  MiscellaneousConfig,
  UserManagementConfig,
} from "./CustomerFeaturesConfig";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";

interface Props {
  customerId?: string;
}

const CustomerFeatureScreen = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const functionsFeatureConfig = FunctionsConfig();
  const meetingRoomFeatureConfig = MeetingRoomConfig();
  const userManagementConfig = UserManagementConfig();
  const loginFeatureConfig = LoginConfig();
  const miscellaneousFeatureConfig = MiscellaneousConfig();
  const [authenticatedUser] = useAuthenticatedUser();
  const [enabledFeatures, setEnabledFeatures] = useState<CustomerFeature[]>([]);

  const loadSettings = () => {
    if (customerId) {
      customerSettingsServiceToken()
        .listCustomerFeatures({
          customerId,
        })
        .then((res: CustomerFeatureResponse) => {
          setEnabledFeatures(res.features);
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.getReadData), {
            variant: "error",
          });
        });
    }
  };

  const enableFeature = (feature: CustomerFeatureType) => {
    return [...enabledFeatures, { feature }];
  };

  const disableFeature = (feature: CustomerFeatureType) => {
    return enabledFeatures.filter(
      (customerFeature) => customerFeature.feature !== feature
    );
  };

  const updateFeatures = (feature: CustomerFeatureType, enabled: boolean) => {
    if (customerId) {
      customerSettingsServiceToken()
        .updateCustomerFeatures({
          customerId,
          customerFeature: enabled
            ? enableFeature(feature)
            : disableFeature(feature),
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.getReadData), {
            variant: "error",
          });
        })
        .finally(loadSettings);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.CustomerSettings],
    allowedRoles: [UserRoleType.Staff, UserRoleType.PlatformAdmin],
  });

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {intl.formatMessage(messages.customerSettingsTab)}
          </h3>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.functionsSettingsCategory)}
          </h3>
          <List>
            {functionsFeatureConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < functionsFeatureConfig.length - 1}
              >
                <SwitchButton
                  label={config.label}
                  featureType={config.feature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.meetingRoomSettingsCategory)}
          </h3>
          <List>
            {meetingRoomFeatureConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < meetingRoomFeatureConfig.length - 1}
              >
                <SwitchButton
                  label={config.label}
                  featureType={config.feature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.userManagementSettingsCategory)}
          </h3>
          <List>
            {userManagementConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < userManagementConfig.length - 1}
              >
                <SwitchButton
                  label={config.label}
                  featureType={config.feature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.loginSettingsCategory)}
          </h3>
          <List>
            {loginFeatureConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < loginFeatureConfig.length - 1}
              >
                <SwitchButton
                  label={config.label}
                  featureType={config.feature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.miscellaneousSettingsCategory)}
          </h3>
          <List>
            {miscellaneousFeatureConfig.map((config, index) => (
              <ListItem
                key={config.feature}
                divider={index < miscellaneousFeatureConfig.length - 1}
              >
                <SwitchButton
                  label={config.label}
                  featureType={config.feature}
                  enabledCustomerFeatures={enabledFeatures}
                  onSwitched={updateFeatures}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustomerFeatureScreen;
