/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User Consent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerAgreementResponse,
    CustomerAgreementResponseFromJSON,
    CustomerAgreementResponseToJSON,
    UserAgreementConsentRequest,
    UserAgreementConsentRequestFromJSON,
    UserAgreementConsentRequestToJSON,
    UserAgreementConsentResponse,
    UserAgreementConsentResponseFromJSON,
    UserAgreementConsentResponseToJSON,
} from '../models';

export interface CheckUserConsentOnCustomerRequest {
    customerId: string;
    userId: string;
}

export interface GetCustomerUserAgreementRequest {
    customerId: string;
    agreementId: string;
}

export interface GiveUserConsentToCustomerAgreementRequest {
    customerId: string;
    userAgreementConsentRequest: UserAgreementConsentRequest;
}

export interface RevokeUserConsentOnCustomerRequest {
    customerId: string;
    userId: string;
}

/**
 * 
 */
export class UserConsentApi extends runtime.BaseAPI {

    /**
     * Check if the user has given consent to the latest agreements for the customer.
     * Check user consent
     */
    async checkUserConsentOnCustomerRaw(requestParameters: CheckUserConsentOnCustomerRequest): Promise<runtime.ApiResponse<Array<UserAgreementConsentResponse>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling checkUserConsentOnCustomer.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling checkUserConsentOnCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consent/{customerId}/user/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserAgreementConsentResponseFromJSON));
    }

    /**
     * Check if the user has given consent to the latest agreements for the customer.
     * Check user consent
     */
    async checkUserConsentOnCustomer(requestParameters: CheckUserConsentOnCustomerRequest): Promise<Array<UserAgreementConsentResponse>> {
        const response = await this.checkUserConsentOnCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a customer user agreement by id.
     * Get customer agreement
     */
    async getCustomerUserAgreementRaw(requestParameters: GetCustomerUserAgreementRequest): Promise<runtime.ApiResponse<CustomerAgreementResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerUserAgreement.');
        }

        if (requestParameters.agreementId === null || requestParameters.agreementId === undefined) {
            throw new runtime.RequiredError('agreementId','Required parameter requestParameters.agreementId was null or undefined when calling getCustomerUserAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consent/{customerId}/agreement/{agreementId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"agreementId"}}`, encodeURIComponent(String(requestParameters.agreementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAgreementResponseFromJSON(jsonValue));
    }

    /**
     * Returns a customer user agreement by id.
     * Get customer agreement
     */
    async getCustomerUserAgreement(requestParameters: GetCustomerUserAgreementRequest): Promise<CustomerAgreementResponse> {
        const response = await this.getCustomerUserAgreementRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create or update consent to a customer user agreement.
     * Create or update consent
     */
    async giveUserConsentToCustomerAgreementRaw(requestParameters: GiveUserConsentToCustomerAgreementRequest): Promise<runtime.ApiResponse<UserAgreementConsentResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling giveUserConsentToCustomerAgreement.');
        }

        if (requestParameters.userAgreementConsentRequest === null || requestParameters.userAgreementConsentRequest === undefined) {
            throw new runtime.RequiredError('userAgreementConsentRequest','Required parameter requestParameters.userAgreementConsentRequest was null or undefined when calling giveUserConsentToCustomerAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/consent/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAgreementConsentRequestToJSON(requestParameters.userAgreementConsentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAgreementConsentResponseFromJSON(jsonValue));
    }

    /**
     * Create or update consent to a customer user agreement.
     * Create or update consent
     */
    async giveUserConsentToCustomerAgreement(requestParameters: GiveUserConsentToCustomerAgreementRequest): Promise<UserAgreementConsentResponse> {
        const response = await this.giveUserConsentToCustomerAgreementRaw(requestParameters);
        return await response.value();
    }

    /**
     * Revokes the users consent to ALL agreements from this customer.
     * Revoke user consent
     */
    async revokeUserConsentOnCustomerRaw(requestParameters: RevokeUserConsentOnCustomerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling revokeUserConsentOnCustomer.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling revokeUserConsentOnCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consent/{customerId}/user/{userId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revokes the users consent to ALL agreements from this customer.
     * Revoke user consent
     */
    async revokeUserConsentOnCustomer(requestParameters: RevokeUserConsentOnCustomerRequest): Promise<void> {
        await this.revokeUserConsentOnCustomerRaw(requestParameters);
    }

}
