/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform file management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The person sharing the file will become the owner of the file.
 * @export
 * @interface FileUserResponse
 */
export interface FileUserResponse {
    /**
     * The UUID of this user.
     * @type {string}
     * @memberof FileUserResponse
     */
    id: string;
    /**
     * First name of this user.
     * @type {string}
     * @memberof FileUserResponse
     */
    firstName: string;
    /**
     * Last name of this user.
     * @type {string}
     * @memberof FileUserResponse
     */
    lastName: string;
}

export function FileUserResponseFromJSON(json: any): FileUserResponse {
    return FileUserResponseFromJSONTyped(json, false);
}

export function FileUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
    };
}

export function FileUserResponseToJSON(value?: FileUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


