import { defineMessages } from "react-intl";

export const messages = defineMessages({
  summaryLabel: {
    id: "bookingsListCard.summary.title",
    defaultMessage: "Summary",
    description: "label for summary",
  },
  personOverviewLabel: {
    id: "bookingsListCard.summary.list.item.personOverview.label",
    defaultMessage: "Show person overview",
    description: "item label for person overview",
  },
  bookingsLabel: {
    id: "bookingsListCard.bookings.title",
    defaultMessage: "Past bookings",
    description: "label for bookings card",
  },
});
