import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    margin: "auto",
    padding: theme.spacing(2),
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progress: {
    position: "absolute",
    top: -10,
    left: -10,
    zIndex: 2,
  },
  staticProgress: {
    position: "absolute",
    top: -10,
    left: -10,
    color: theme.palette.grey.A100,
    zIndex: 1,
  },
  icon: {
    fontSize: 100,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));
