import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noFilesLabel: {
    id: "files.noFiles.label",
    defaultMessage: "No files",
    description: "Text to be shown when no messages",
  },
  shareFileBtnLabel: {
    id: "files.button.shareFile.label",
    defaultMessage: "Share file",
    description: "Button label",
  },
});
