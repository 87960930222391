import React, { useEffect, useState } from "react";
import { useSubscription } from "react-stomp-hooks";
import { useIntl } from "react-intl";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { StringParam, useQueryParam } from "use-query-params";
import { Fade } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { SocketDestination } from "../../WebSocket/WebSocketSession";
import {
  parseSignalMessage,
  SignalAction,
  SignalType,
} from "../../WebSocket/model/SignalMessage";
import { meetingService } from "../../Providers/ServiceProvider/ServiceProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { MeetingSession } from "../../Models/Meeting";
import { redirectToErrorPage } from "../ErrorPage/ErrorPage";
import { messages } from "./WaitingLobby.messages";
import ParticipantList from "../../Components/ParticipantCard/Components/ParticipantList/ParticipantList";
import { getFormattedDateTimeSpan } from "../../Utils/Meeting";
import { useStyles } from "./WaitingLobby.style";
import Tip1 from "./Components/Tip1/Tip1";
import BackButton from "../../Components/Buttons/BackButton/BackButton";
import ConnectToMeetingDialog from "../../Components/Dialogs/ConnectToMeetingDialog/ConnectToMeetingDialog";
import { RoutePath } from "../../Components/Routes/RoutePath";

const WaitingLobby = () => {
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const isPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const [meetingId] = useQueryParam("meetingId", StringParam);
  const [startedMeetingId, setStartedMeetingId] = useState<string>();
  const [session, setSession] = useState<MeetingSession>();
  const [showTip, setShowTip] = useState<boolean>(true);
  const [activeTip, setActiveTip] = useState<number>(0);
  const tips = [<Tip1 />];
  const tipsTransition = 1000;
  const tipsTransitionDelay = 5000;
  const [counter, setCounter] = useState<number>(1);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  const [direction, setDirection] = useState<"right" | "left">("right");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleError = (status: number) => {
    switch (status) {
      case 400:
        redirectToErrorPage({
          errorCode: 400,
          title: intl.formatMessage(messages.meetingNotFoundErrorTitle),
          description: intl.formatMessage(
            messages.meetingNotFoundErrorDescription
          ),
        });
        break;
      case 404:
        redirectToErrorPage({
          errorCode: 404,
          title: intl.formatMessage(messages.meetingNotFoundErrorTitle),
          description: intl.formatMessage(
            messages.meetingNotFoundErrorDescription
          ),
        });
        break;
      default:
        redirectToErrorPage({});
    }
  };

  const fetchMeetingSessionDetails = (): void => {
    if (customerId && authenticatedUser.user && meetingId) {
      meetingService()
        .getMeetingSessionDecorated(authenticatedUser.user, {
          customerId,
          meetingId,
          userId: authenticatedUser.user.id,
        })
        .then((res) => setSession(res))
        .catch((reason) => handleError(reason.status));
    }
  };

  useEffect(() => {
    fetchMeetingSessionDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tips.length !== 1) {
      if (counter % 2) {
        setTimeout(() => {
          setShowTip(false);
          setCounter(counter + 1);
        }, tipsTransitionDelay);
      } else {
        setTimeout(() => {
          setShowTip(true);
          setCounter(counter + 1);
          if (activeTip === tips.length - 1) {
            setActiveTip(0);
          } else {
            setActiveTip(activeTip + 1);
          }
        }, tipsTransition);
      }
    }
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEntered = () => {
    setDirection("left");
    setTimeout(() => {
      setShowLoadingText(false);
    }, 3000);
  };

  const onExited = () => {
    setDirection("right");
    setShowLoadingText(true);
  };

  const redirectToMeetingPage = () => {
    history.push({
      pathname: RoutePath.SESSION_VIDEO,
      search: `?meetingId=${meetingId}`,
    });
  };

  useSubscription(SocketDestination.SIGNAL_GLOBAL + customerId, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.MEETING &&
      signalMessage.action === SignalAction.STARTED
    ) {
      if (meetingId) {
        if (signalMessage.affectedIds.includes(meetingId)) {
          redirectToMeetingPage();
        } else {
          setStartedMeetingId(signalMessage.affectedIds[0]);
          setIsOpen(true);
        }
      }
    }
  });

  return (
    <>
      {startedMeetingId && (
        <ConnectToMeetingDialog
          open={isOpen}
          showCloseCross
          onClose={() => setIsOpen(false)}
          meetingId={startedMeetingId}
        />
      )}
      <BackButton />
      <Grid container />
      <Grid item>
        <Card>
          <Slide
            direction={direction}
            onEntered={onEntered}
            onExited={onExited}
            in={showLoadingText}
            timeout={{ enter: 1000, exit: 500, appear: 3000 }}
          >
            <CardHeader title={intl.formatMessage(messages.title)} />
          </Slide>
          <LinearProgress color="secondary" />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography gutterBottom variant="h5" component="h2">
                  {session?.meeting.title}
                </Typography>
                {session?.meeting && (
                  <Typography>
                    {getFormattedDateTimeSpan(
                      session?.meeting.startDateTime,
                      session?.meeting.duration
                    )}
                  </Typography>
                )}
                <div className={classes.fadeContainer}>
                  <Fade in={showTip} timeout={tipsTransition}>
                    <div>{tips[activeTip]}</div>
                  </Fade>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={classes.participantContainer}
              >
                <Divider
                  orientation={isPhone ? "horizontal" : "vertical"}
                  className={classes.divider}
                />
                <div>
                  <Typography>
                    {intl.formatMessage(messages.participantTitle)}
                  </Typography>
                  <ParticipantList
                    participants={session?.meeting.participants}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid />
    </>
  );
};

export default WaitingLobby;
