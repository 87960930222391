/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform User Consent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerUserAgreementType,
    CustomerUserAgreementTypeFromJSON,
    CustomerUserAgreementTypeFromJSONTyped,
    CustomerUserAgreementTypeToJSON,
} from './';

/**
 * Object containing an agreement that a user has to consent to.
 * @export
 * @interface CustomerAgreementResponse
 */
export interface CustomerAgreementResponse {
    /**
     * The UUID of this customer agreement item.
     * @type {string}
     * @memberof CustomerAgreementResponse
     */
    id: string;
    /**
     * 
     * @type {CustomerUserAgreementType}
     * @memberof CustomerAgreementResponse
     */
    agreementType: CustomerUserAgreementType;
    /**
     * The title of this agreement.
     * @type {string}
     * @memberof CustomerAgreementResponse
     */
    agreementTitle: string;
    /**
     * Long text with the agreement that the user has to consent to.
     * @type {string}
     * @memberof CustomerAgreementResponse
     */
    agreementText: string;
    /**
     * Date from when this agreement is in effect.
     * @type {Date}
     * @memberof CustomerAgreementResponse
     */
    validFrom: Date;
}

export function CustomerAgreementResponseFromJSON(json: any): CustomerAgreementResponse {
    return CustomerAgreementResponseFromJSONTyped(json, false);
}

export function CustomerAgreementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAgreementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'agreementType': CustomerUserAgreementTypeFromJSON(json['agreementType']),
        'agreementTitle': json['agreementTitle'],
        'agreementText': json['agreementText'],
        'validFrom': (new Date(json['validFrom'])),
    };
}

export function CustomerAgreementResponseToJSON(value?: CustomerAgreementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'agreementType': CustomerUserAgreementTypeToJSON(value.agreementType),
        'agreementTitle': value.agreementTitle,
        'agreementText': value.agreementText,
        'validFrom': (value.validFrom.toISOString().substr(0,10)),
    };
}


