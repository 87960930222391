import { useIntl } from "react-intl";
import { messages } from "../../Views/CustomerSettings/CustomerSettings.messages";
import { CustomerFeatureType } from "../../generated/customersettings-api";

interface AnonymizeFeaturesConfig {
  label: string;
  feature: CustomerFeatureType;
}

export const FeaturesConfig = (): AnonymizeFeaturesConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.anonymizeNoneActiveClient),
      feature: CustomerFeatureType.AnonymizeNoneActiveClient,
    },
    {
      label: intl.formatMessage(messages.anonymizeClosedUser),
      feature: CustomerFeatureType.AnonymizeClosedUser,
    },
    {
      label: intl.formatMessage(messages.anonymizeOldTodos),
      feature: CustomerFeatureType.AnonymizeOldTodos,
    },
    {
      label: intl.formatMessage(messages.anonymizeOldFiles),
      feature: CustomerFeatureType.AnonymizeOldFiles,
    },
    {
      label: intl.formatMessage(messages.anonymizeOldNotes),
      feature: CustomerFeatureType.AnonymizeOldNotes,
    },
    {
      label: intl.formatMessage(messages.anonymizeOldMeetings),
      feature: CustomerFeatureType.AnonymizeOldMeetings,
    },
    {
      label: intl.formatMessage(messages.anonymizeOldMessages),
      feature: CustomerFeatureType.AnonymizeOldMessages,
    },
    {
      label: intl.formatMessage(messages.anonymizeOldChatMessages),
      feature: CustomerFeatureType.AnonymizeOldChatMessages,
    },
    {
      label: intl.formatMessage(messages.anonymizeExpiredInvites),
      feature: CustomerFeatureType.AnonymizeExpiredInvites,
    },
  ];
};
