import React from "react";
import ScreenShare from "@material-ui/icons/ScreenShare";
import StopScreenShare from "@material-ui/icons/StopScreenShare";
import { useIntl } from "react-intl";
import { SpeedDialAction } from "@material-ui/lab";
import { useStyles } from "./ScreenSharingSpeedDialAction.styles";
import { messages } from "./ScreenSharingSpeedDialAction.messages";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { UserRoleType } from "../../../../../../generated/user-api";
import { CustomerFeatureType } from "../../../../../../generated/customersettings-api";
import FeatureController from "../../../../../../Components/FeatureController/FeatureController";

interface Props {
  open: boolean;
  delay: number;
  isScreenSharing: boolean;
  startScreenSharing: () => void;
  stopScreenSharing: () => void;
}
export default function ScreenSharingSpeedDialAction(props: Props) {
  const {
    open,
    delay,
    isScreenSharing,
    startScreenSharing,
    stopScreenSharing,
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();

  const handleScreenSharing = () => {
    return isScreenSharing ? stopScreenSharing() : startScreenSharing();
  };

  const title = !isScreenSharing
    ? intl.formatMessage(messages.stopScreenSharing)
    : intl.formatMessage(messages.startScreenSharing);

  const requiredFeature =
    authenticatedUser.user?.userRole === UserRoleType.Staff
      ? CustomerFeatureType.InMeetingScreenShareStaff
      : CustomerFeatureType.InMeetingScreenShareClient;

  return (
    <FeatureController requiredFeature={requiredFeature}>
      <SpeedDialAction
        key="screen-sharing"
        icon={
          isScreenSharing ? (
            <div className={classes.container}>
              <StopScreenShare className={classes.icon} />
              {title}
            </div>
          ) : (
            <div className={classes.container}>
              <ScreenShare className={classes.icon} />
              {title}
            </div>
          )
        }
        onClick={handleScreenSharing}
        open={open}
        delay={delay}
        FabProps={{
          variant: "extended",
        }}
        classes={{ fab: classes.fab }}
        TooltipClasses={{ tooltip: classes.hidden }}
        className={classes.fabContainer}
      />
    </FeatureController>
  );
}
