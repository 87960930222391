/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Template API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingTemplateKindType,
    MeetingTemplateKindTypeFromJSON,
    MeetingTemplateKindTypeFromJSONTyped,
    MeetingTemplateKindTypeToJSON,
} from './';

/**
 * A request object to create a new meeting template.
 * @export
 * @interface CreateMeetingTemplateRequest
 */
export interface CreateMeetingTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMeetingTemplateRequest
     */
    title?: string;
    /**
     * Meeting lenght in minutes
     * @type {number}
     * @memberof CreateMeetingTemplateRequest
     */
    duration?: number;
    /**
     * 
     * @type {MeetingTemplateKindType}
     * @memberof CreateMeetingTemplateRequest
     */
    type?: MeetingTemplateKindType;
}

export function CreateMeetingTemplateRequestFromJSON(json: any): CreateMeetingTemplateRequest {
    return CreateMeetingTemplateRequestFromJSONTyped(json, false);
}

export function CreateMeetingTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMeetingTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'type': !exists(json, 'type') ? undefined : MeetingTemplateKindTypeFromJSON(json['type']),
    };
}

export function CreateMeetingTemplateRequestToJSON(value?: CreateMeetingTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'duration': value.duration,
        'type': MeetingTemplateKindTypeToJSON(value.type),
    };
}


