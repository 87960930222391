import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dashboardview.quickmenucard.letestvisit.title",
    defaultMessage: "LATEST VISIT",
    description: "title for the section of the latest meeting",
  },
  durationUnit: {
    id: "dashboardview.quickmenucard.letestvisit.duration.label",
    defaultMessage: "minutes",
    description: "label for duration unit ",
  },
  noVisitsYetLabel: {
    id: "dashboardview.quickmenucard.letestvisit.novisitsyet.label",
    defaultMessage: "No visits yet",
    description: "label when no latest visits available ",
  },
});
