import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import { useFormik } from "formik";
import {
  CustomerResponse,
  CustomerStatusType,
} from "../../../generated/customer-api";
import { messages } from "./ChangeCustomerStatusDialog.messages";
import ButtonLoadingWrapper from "../../Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";
import {
  authenticationService,
  userService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import { useStyles } from "./ChangeCustomerStatusDialog.style";
import {
  Action,
  AuthenticationStartResponse,
} from "../../../generated/authentication-api";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { emptyStringToUndefined } from "../../../Utils/Format";
import SelectField, { Item } from "../../Fields/SelectField/SelectField";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  customer?: CustomerResponse;
}

const ChangeCustomerStatusDialog = (props: Props) => {
  const { isOpen, onClose, customer } = props;
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const customerId = useCustomerId();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [isLoading, setIsLoading] = useState(false);
  const [personNumber, setPersonNumber] = useState<string>();
  const initialValues = {
    status: customer?.status || CustomerStatusType.Active,
  };
  const selectItems = [
    {
      label: intl.formatMessage(messages.dialogSelectOptionActive),
      value: CustomerStatusType.Active,
    },
    {
      label: intl.formatMessage(messages.dialogSelectOptionInactive),
      value: CustomerStatusType.Inactive,
    },
    {
      label: intl.formatMessage(messages.dialogSelectOptionClosed),
      value: CustomerStatusType.Closed,
    },
  ] as Item[];

  const loadUserPersonNumber = () => {
    if (authenticatedUser.user?.id) {
      userService()
        .getPersonNumber({ userId: authenticatedUser.user?.id })
        .then((res) =>
          setPersonNumber(res.personNumber ? res.personNumber : "")
        );
    }
  };

  useEffect(() => {
    loadUserPersonNumber();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (status: CustomerStatusType) => {
    if (customerId && customer?.id) {
      setIsLoading(true);
      authenticationService()
        .startSignIdpAuthenticationSession({
          customerId,
          personNumber: emptyStringToUndefined(personNumber),
          signAuthenticationRequest: {
            action: Action.CustomerStatusUpdate,
            dynamicKeyValues: [
              { key: "customerId", value: customer?.id },
              { key: "status", value: status },
            ],
          },
        })
        .then((authResponse: AuthenticationStartResponse) => {
          window.location.href = authResponse.redirectUrl;
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          })
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleSubmit(values.status),
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle)}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography component="pre" className={classes.infoText}>
            {intl.formatMessage(messages.dialogInfoText)}
          </Typography>
          <SelectField
            id="status"
            label={intl.formatMessage(messages.dialogSelectLabel)}
            formik={formik}
            items={selectItems}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading || formik.values.status === customer?.status}
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeCustomerStatusDialog;
