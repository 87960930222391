import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "sharedFiles.title",
    defaultMessage: "Shared files",
    description: "Heading for shared files card in meeting session view",
  },
  inMeetingDescription: {
    id: "sharedFiles.description.inMeeting",
    defaultMessage:
      'The documents you upload are visible to all participants. On mobile / tablet: When a participant clicks on "Share document", the video can be temporarily turned off.\n\nNote that if you have blocked the popup, you may have trouble opening the documents. This can be changed in the browser settings.',
    description: "Descriptive text shown in shared files card",
  },
  personOverviewDescription: {
    id: "sharedFiles.description.personOverview",
    defaultMessage:
      "All documents you and {name} previously shared are displayed here. New documents you share here are only available for the two of you.\n\nPlease note that if you have blocked the popup, you may have trouble accessing the documents. This may change in your browser settings.",
    description: "Descriptive text shown in shared files card",
  },
  meetingOverviewDescription: {
    id: "sharedFiles.description.meetingOverview",
    defaultMessage:
      'The documents that you write here are visible both to you and your patient/caregiver. If the Patient or Caregiver clicks on "Share Document" in mobile or tablet, the video might stop sending temporarily because the camera is used for document sharing. Documents are often opened in a seperate tab, so if you open a document from a phone or tablet the video is likely to stop sending until you are back on the tab with the video meeting.',
    description: "Descriptive text shown in shared files card",
  },
  error: {
    id: "sharedFiles.error",
    defaultMessage: "Something went wrong",
    description: "Error message when fails to load files",
  },
});
