import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./BookingsListItemSimple.style";
import BookingsListItemText from "../BookingsListItemText/BookingsListItemText";
import {
  MeetingResponse,
  MeetingStatusType,
} from "../../../../generated/meeting-api";
import {
  getFormattedDate,
  getFormattedTimespan,
  getParticipantsAfterRemoval,
} from "../../../../Utils/Meeting";
import { Variant } from "../Variant/Variant";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import BadgeAvatar from "../../../BadgeAvatar/BadgeAvatar";
import ConnectToMeetingButton from "../../../Buttons/ConnectToMeetingButton/ConnectToMeetingButton";
import AcceptMeetingButton from "../../../Buttons/AcceptMeetingButton/AcceptMeetingButton";
import AvatarGroup from "../../../AvatarGroup/AvatarGroup";
import { UserRoleType } from "../../../../generated/user-api";
import GoToWaitingLobbyButton from "../../../Buttons/GoToWaitingLobbyButton/GoToWaitingLobbyButton";

interface Props {
  variant: Variant;
  meeting: MeetingResponse;
  reloadBookings?: () => void;
}

const BookingsListItemSimple = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { variant, meeting, reloadBookings } = props;
  const participants = getParticipantsAfterRemoval(meeting.participants || [], [
    authenticatedUser.user?.id || "",
  ]);

  const CustomAvatar = () => {
    if (participants.length > 1) {
      return <AvatarGroup participants={participants} max={1} center />;
    }

    return (
      <BadgeAvatar
        size="small"
        lastName={participants[0]?.lastName}
        firstName={participants[0]?.firstName}
        participantsForTooltip={participants}
      />
    );
  };
  return (
    <>
      <ListItem alignItems="flex-start" className={classes.root} divider>
        <Grid container>
          <Grid item md={2} sm={2} xs={4}>
            <CustomAvatar />
          </Grid>
          {meeting.startDateTime && (
            <Grid item md={7} sm={7} xs={8}>
              <Typography style={{ fontWeight: "bold" }} variant="h6">
                {meeting.title}
              </Typography>
              <BookingsListItemText
                display="inline"
                primaryText={getFormattedDate(meeting.startDateTime)}
                secondaryText={getFormattedTimespan(
                  meeting.startDateTime,
                  meeting.duration
                )}
              />
            </Grid>
          )}
          <Grid item md={3} sm={3} xs={12} className={classes.buttonGrid}>
            {meeting.status === MeetingStatusType.Created &&
              variant === Variant.DASHBOARD_UPCOMING_BOOKINGS &&
              authenticatedUser.user?.userRole !== UserRoleType.Staff && (
                <GoToWaitingLobbyButton meetingId={meeting.id} />
              )}
            <AcceptMeetingButton
              meeting={meeting}
              contextVariant={variant}
              className={classes.button}
              onAccepted={reloadBookings}
            />
            <ConnectToMeetingButton
              contextVariant={variant}
              className={classes.button}
              meeting={meeting}
            />
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default BookingsListItemSimple;
