import { defineMessages } from "react-intl";

export const messages = defineMessages({
  error: {
    id: "button.videoFilterUpload.error",
    defaultMessage: "Something went wrong",
    description: "Text to be shown when fails to upload video filter",
  },
  fileTooLargeError: {
    id: "button.videoFilterUpload.error.fileTooLarge",
    defaultMessage: "File too large",
    description: "Text to be shown when video filter too large to be uploaded",
  },
  success: {
    id: "button.videoFilterUpload.success",
    defaultMessage: "Successfully uploaded video filter",
    description: "Text to be shown when successfully uploaded video filter",
  },
  label: {
    id: "button.videoFilterUpload.label",
    defaultMessage: "Add",
    description: "Button label for upload video filter",
  },
});
