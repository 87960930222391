import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "sharedFiles.title",
    defaultMessage: "Shared files",
    description: "Heading for shared files card in meeting session view",
  },
  description: {
    id: "sharedFiles.description.inMeeting",
    defaultMessage:
      'The documents you upload are visible to all participants. On mobile / tablet: When a participant clicks on "Share document", the video can be temporarily turned off.\n\nNote that if you have blocked the popup, you may have trouble opening the documents. This can be changed in the browser settings.',
    description: "Descriptive text shown in shared files card",
  },
  error: {
    id: "sharedFiles.error",
    defaultMessage: "Something went wrong",
    description: "Error message when fails to load files",
  },
});
