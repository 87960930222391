import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  dialogTitle: {
    id: "dialog.modifyMeetingTemplate.title",
    defaultMessage: "Update booking template",
    description: "titel for update booking template dialog ",
  },
  submitButtonLabel: {
    id: "dialog.modifyMeetingTemplate.button.submit",
    defaultMessage: "Update booking template",
    description: "Submit label for updating booking template",
  },
  cancelButtonLabel: {
    id: "dialog.modifyMeetingTemplate.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel label for cancel update of booking template",
  },
  success: {
    id: "dialog.modifyMeetingTemplate.success",
    defaultMessage: "Booking template was updated",
    description: "Success message when booking template has been updated",
  },
  generalError: {
    id: "dialog.modifyMeetingTemplate.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when it fails to update a booking template",
  },
});
