import { defineMessages } from "react-intl";

export const messages = defineMessages({
  acceptButtonLabel: {
    id: "button.acceptMeetingButton.accept.label",
    defaultMessage: "Accept",
    description: "accept button label",
  },
  acceptAndConnectButtonLabel: {
    id: "button.acceptMeetingButton.acceptAndConnect.label",
    defaultMessage: "Accept and join",
    description: "accept and join button label",
  },
  generalError: {
    id: "button.acceptMeetingButton.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when fails to confirm meeting",
  },
  success: {
    id: "button.acceptMeetingButton.success",
    defaultMessage: "Successfully confirmed",
    description: "Text shown in snack bar when successfully confirm meeting",
  },
});
