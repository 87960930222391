import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 50,
    margin: theme.spacing(1),
  },
  speedDialFab: {
    height: 50,
    width: 50,
  },
}));
