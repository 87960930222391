import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: "80vh",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    iconSuccess: {
      fontSize: 100,
      width: "100%",
      color: theme.palette.success.main,
    },
    iconInfo: {
      fontSize: 100,
      width: "100%",
      color: theme.palette.info.main,
    },
    iconError: {
      fontSize: 100,
      width: "100%",
      color: theme.palette.error.main,
    },
    button: {
      marginTop: theme.spacing(2),
    },
    dashboardButton: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(-4),
    },
    createdContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    canceledContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    infoDescriptionContainer: {
      maxWidth: 650,
    },
  })
);
