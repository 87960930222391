import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeading: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      color: theme.palette.grey["600"],
    },
    cardHeadingNoMargin: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
  })
);
