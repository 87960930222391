import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import NewMessageIcon from "@material-ui/icons/Forum";
import { messages } from "./NewMessagesAvailableButton.messages";
import { useStyles } from "./NewMessagesAvailableButton.style";

interface Props {
  show?: boolean;
  onClick?: () => void;
}

const NewMessagesAvailableButton = (props: Props) => {
  const { show, onClick } = props;
  const intl = useIntl();
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return (
    <div className={classes.buttonContainer}>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={onClick}
      >
        <NewMessageIcon className={classes.iconNewMessage} />
        {intl.formatMessage(messages.newMessagesAvailableButtonLabel)}
      </Button>
    </div>
  );
};

export default NewMessagesAvailableButton;
