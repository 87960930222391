import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { messages } from "./CreateMeetingButton.messages";
import PermissionController from "../../PermissionController/PermissionController";
import {
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../../generated/user-api";
import CreateMeetingDialog from "../../Dialogs/CreateMeetingDialog/CreateMeetingDialog";

interface Props {
  color?: "inherit" | "primary" | "secondary" | "default";
  overrideLabel?: string;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  onCreated?: () => void;
  prePopulatedParticipants?: UserResponse[];
  isAdmin?: boolean | undefined;
}

const CreateMeetingButton = (props: Props) => {
  const {
    className,
    onCreated,
    prePopulatedParticipants,
    variant,
    overrideLabel,
    color,
    isAdmin,
  } = props;
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleOnCreated = () => {
    setIsDialogOpen(false);
    if (onCreated) {
      onCreated();
    }
  };

  return (
    <PermissionController
      allowedRoles={[UserRoleType.Staff]}
      atLeastOneOfAuthoritiesStaff={[
        UserAuthorityType.BookMeeting,
        UserAuthorityType.BookMeetingOther,
      ]}
    >
      <CreateMeetingDialog
        onMeetingCreated={handleOnCreated}
        isDialogOpen={isDialogOpen}
        onDialogClose={() => setIsDialogOpen(false)}
        prePopulatedParticipants={prePopulatedParticipants}
        isAdmin={isAdmin}
      />
      <Button
        size="small"
        color={color || "primary"}
        className={className}
        variant={variant || "contained"}
        startIcon={<CalendarTodayIcon />}
        onClick={handleOpenDialog}
      >
        {overrideLabel || intl.formatMessage(messages.buttonLabel)}
      </Button>
    </PermissionController>
  );
};

export default CreateMeetingButton;
