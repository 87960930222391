import React from "react";
import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { useStyles } from "./MessageList.style";
import ProfilePicture from "../../../../../../Components/ProfilePicture/ProfilePicture";
import { messages } from "../../../../../../Components/SharedFilesCard/Components/FileList/FileList.messages";
import { getThreadCounterPart } from "../../../../../../Utils/Message";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { displayUser } from "../../../../../../Utils/User";
import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import { messageService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";
import { MessageThreadResponse } from "../../../../../../generated/message-api";

interface Props {
  selectedId?: string;
  onSelected?: (messageId: string) => void;
  messageThreads?: MessageThreadResponse[];
}

const MessageList = (props: Props) => {
  const { messageThreads, onSelected, selectedId } = props;
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const customerId = useCustomerId();

  const markMessagesAsRead = (
    messageId: string,
    hasUnreadMessages: boolean
  ) => {
    if (customerId && hasUnreadMessages) {
      messageService()
        .markMessagesAsRead({
          customerId,
          markMessagesAsReadRequest: {
            includeAllReplyMessages: true,
            messageIds: [messageId],
          },
        })
        .finally(() => {
          if (onSelected) {
            onSelected(messageId);
          }
        });
    } else if (onSelected) {
      onSelected(messageId);
    }
  };

  const handleOnSelected = (messageId: string, hasUnreadMessages: boolean) => {
    markMessagesAsRead(messageId, hasUnreadMessages);
  };

  const mapThreads = messageThreads?.map((thread) => {
    const messageCounterPart = getThreadCounterPart(
      thread,
      authenticatedUser.user?.id
    );
    return (
      <div key={thread.id}>
        <ListItem
          button
          onClick={() =>
            handleOnSelected(thread.id, thread.nbrOfUnreadMessages > 0)
          }
          className={selectedId === thread.id ? classes.active : classes.root}
        >
          <Badge
            badgeContent={thread.nbrOfUnreadMessages}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ListItemAvatar>
              <ProfilePicture
                firstName={messageCounterPart?.firstName}
                lastName={messageCounterPart?.lastName}
              />
            </ListItemAvatar>
          </Badge>
          <ListItemText
            primary={thread.subject}
            secondary={displayUser(messageCounterPart)}
            primaryTypographyProps={{
              className:
                thread.nbrOfUnreadMessages !== 0
                  ? classes.listItemTextUnread
                  : undefined,
            }}
            secondaryTypographyProps={{
              className:
                selectedId === thread.id
                  ? classes.listItemTextActive
                  : undefined,
            }}
          />
        </ListItem>
        <Divider />
      </div>
    );
  });

  if (!messages) {
    return null;
  }
  return <List>{mapThreads}</List>;
};

export default MessageList;
