import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import { StringParam, useQueryParam } from "use-query-params";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { SortByType, SortOrderType } from "../../generated/message-api";
import CreateMessageButton from "../../Components/Buttons/CreateMessageButton/CreateMessageButton";
import MessageListCard from "./Components/MessageListCard/MessageListCard";
import DisplayMessagesCard from "./Components/DisplayMessagesCard/DisplayMessagesCard";
import { messages } from "./Messages.messages";
import DisplayMessageDialog from "../../Components/Dialogs/DisplayMessageDialog/DisplayMessageDialog";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { useStyles } from "./Messages.style";
import { messageService } from "../../Providers/ServiceProvider/ServiceProvider";
import { PaginatedMessageThreadList } from "../../Models/Message";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

const POLLER_INTERVAL = 5000;
let unreadMessagesCounter: number | undefined;

export const redirectToMessage = (parentMsgId: string) => {
  window.location.replace(
    `${RoutePath.MESSAGES}?selectedThreadId=${parentMsgId}`
  );
};

const Messages = () => {
  const intl = useIntl();
  const theme = useTheme();
  const [authenticatedUser] = useAuthenticatedUser();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const customerId = useCustomerId();
  const setSpinner = useSpinner()[1];
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>();
  const [
    unreadMessagesInSelectedThread,
    setUnreadMessagesInSelectedThread,
  ] = useState<number>(0);
  const [
    paginatedMessageThreads,
    setPaginatedMessageThreads,
  ] = useState<PaginatedMessageThreadList>();
  const [selectedThreadId, setSelectedThreadId] = useQueryParam(
    "selectedThreadId",
    StringParam
  );
  const classes = useStyles();
  const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(false);
  const [firstLoadingComplete, setFirstLoadingComplete] = useState<boolean>(
    false
  );

  const hasMessages = () => {
    return (
      (paginatedMessageThreads?.data &&
        paginatedMessageThreads.data?.length > 0) ||
      query
    );
  };

  const loadMessages = () => {
    if (customerId && authenticatedUser.user) {
      setIsMessagesLoading(true);
      messageService()
        .listMessageThreadsDecorated(authenticatedUser.user, {
          customerId,
          page,
          query: query || undefined,
          pageSize: 10,
          sortBy: SortByType.SentDate,
          sortOrder: SortOrderType.Descending,
        })
        .then((res) => {
          setPaginatedMessageThreads(res);
          setFirstLoadingComplete(true);
        })
        .finally(() => setIsMessagesLoading(false));
    }
  };

  const checkIfAnyNewMessages = async (): Promise<number | undefined> => {
    if (customerId) {
      const value = await Promise.resolve(
        messageService().countUnreadMessagesForCurrentUser({
          customerId,
        })
      );

      return value.nbrOfMessages;
    }
    return undefined;
  };

  const handleSearch = (searchQuery: string): void => {
    if (searchQuery.trim().length === 0) {
      setQuery(undefined);
    } else {
      setQuery(searchQuery);
    }
    setSelectedThreadId(undefined);
  };

  const handleMsgCreated = (): void => {
    setSelectedThreadId(undefined);
    loadMessages();
  };

  useEffect(() => {
    if (customerId && selectedThreadId) {
      messageService()
        .countUnreadMessagesForCurrentUser({
          customerId,
          threadId: selectedThreadId,
        })
        .then((res) => setUnreadMessagesInSelectedThread(res.nbrOfMessages));
    }
  }, [paginatedMessageThreads, selectedThreadId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadMessages();
  }, [page, selectedThreadId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page !== 1) {
      // Reset pagination when search is triggered
      setPage(1);
    } else {
      loadMessages();
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(() => {
      checkIfAnyNewMessages().then((res) => {
        if (res !== unreadMessagesCounter) {
          loadMessages();
          unreadMessagesCounter = res;
        }
      });
    }, POLLER_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isMessagesLoading });
  }, [isMessagesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isSmallScreen && (
        <DisplayMessageDialog
          threadId={selectedThreadId !== null ? selectedThreadId : undefined}
          isOpen={selectedThreadId !== undefined && selectedThreadId !== null}
          onClose={() => setSelectedThreadId(undefined)}
          numberOfUnreadMessages={unreadMessagesCounter}
        />
      )}
      {hasMessages() && (
        <CreateMessageButton
          variant="text"
          label={intl.formatMessage(messages.createButton)}
          recipients={[]}
          onCreated={handleMsgCreated}
        />
      )}
      <Grid container spacing={2}>
        {hasMessages() && (
          <Grid item md={4} xs={12}>
            <MessageListCard
              onSearch={handleSearch}
              onSelected={setSelectedThreadId}
              onPaginationChange={setPage}
              paginatedMessageThreadResponseList={paginatedMessageThreads}
              selectedId={
                selectedThreadId !== null ? selectedThreadId : undefined
              }
            />
          </Grid>
        )}
        {(!isSmallScreen || (isSmallScreen && !hasMessages())) && (
          <Grid
            item
            xs={12}
            md={hasMessages() ? 8 : 12}
            className={
              isMessagesLoading && !firstLoadingComplete ? classes.hidegrid : ""
            }
          >
            <DisplayMessagesCard
              onMessageCreated={handleMsgCreated}
              threadId={
                selectedThreadId !== null ? selectedThreadId : undefined
              }
              noMessages={
                paginatedMessageThreads?.data === undefined ||
                (paginatedMessageThreads?.data.length < 1 && !query)
              }
              numberOfUnreadMessages={unreadMessagesInSelectedThread}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Messages;
