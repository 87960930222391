import { defineMessages } from "react-intl";

export const messages = defineMessages({
  disableCamera: {
    id: "video.toolbar.mutecamera.disable.tooltip",
    defaultMessage: "Disable Camera",
    description: "Tooltip label for button unmuting participants",
  },
  enableCamera: {
    id: "video.toolbar.mutecamera.enable.tooltip",
    defaultMessage: "Enable Camera",
    description: "Tooltip label for button muting participants",
  },
});
