import React from "react";
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { Meeting } from "../../../../../../Models/Meeting";
import { useStyles } from "./ParticipantSlider.style";
import ParticipantList from "../../../../../../Components/ParticipantCard/Components/ParticipantList/ParticipantList";
import { messages } from "./ParticipantSlider.messages";
import DialogCloseButton from "../../../../../../Components/Buttons/DialogCloseButton/DialogCloseButton";

interface Props {
  meeting: Meeting;
  onClose: () => void;
}

const ParticipantSlider = (props: Props) => {
  const { meeting, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  if (isSmallScreen) {
    return (
      <Dialog open fullScreen className={classes.dialogRoot} scroll="paper">
        {onClose && <DialogCloseButton onClose={onClose} />}
        <DialogTitle>
          <Typography
            className={classes.cardHeading}
            component="h2"
            variant="subtitle2"
          >
            {intl.formatMessage(messages.title).toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <ParticipantList participants={meeting.participants} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Container className={classes.root}>
      <Typography
        className={classes.cardHeading}
        component="h2"
        variant="subtitle2"
      >
        {intl.formatMessage(messages.title).toUpperCase()}
      </Typography>
      <Divider />
      <div className={classes.contentContainer}>
        <ParticipantList participants={meeting.participants} />
      </div>
    </Container>
  );
};

export default ParticipantSlider;
