/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of attendance for a participant
 * @export
 * @enum {string}
 */
export enum AttendanceStatusType {
    NotConfirmed = 'NOT_CONFIRMED',
    Confirmed = 'CONFIRMED',
    Declined = 'DECLINED'
}

export function AttendanceStatusTypeFromJSON(json: any): AttendanceStatusType {
    return AttendanceStatusTypeFromJSONTyped(json, false);
}

export function AttendanceStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendanceStatusType {
    return json as AttendanceStatusType;
}

export function AttendanceStatusTypeToJSON(value?: AttendanceStatusType | null): any {
    return value as any;
}

