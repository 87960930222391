import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { userService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { messages } from "./VerifyPersonNumberDialog.messages";
import { useLogout } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  open: boolean;
}

const VerifyPersonNumberDialog = (props: Props) => {
  const { open } = props;
  const intl = useIntl();
  const logout = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = { personNumber: undefined };

  const startIdpSession = (personNumber?: string) => {
    userService()
      .startVerifyPersonNumberIdpSession({
        personNumber,
      })
      .then((res) => {
        window.location.href = res.redirectUrl;
      })
      .catch(() => {
        logout();
        enqueueSnackbar(intl.formatMessage(messages.generalError), {
          variant: "error",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => startIdpSession(values.personNumber),
  });

  useEffect(() => {
    if (!process.env.REACT_APP_TEST_AUTHENTICATION && open) {
      startIdpSession();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!process.env.REACT_APP_TEST_AUTHENTICATION) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{intl.formatMessage(messages.title)}</DialogTitle>
        <DialogContent>
          {intl.formatMessage(messages.description)}
        </DialogContent>
        <TextField
          id="personNumber"
          placeholder="YYYYMMDDNNNN"
          name="personNumber"
          label={intl.formatMessage(messages.textFieldLabel)}
          value={formik.values.personNumber}
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
        />
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            {intl.formatMessage(messages.submitButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VerifyPersonNumberDialog;
