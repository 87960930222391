import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      width: 120,
      marginLeft: theme.spacing(2),
      cursor: "pointer",
    },
    colorPicker: {
      marginTop: theme.spacing(1),
      position: "absolute",
    },
    colorPreview: {
      width: 35,
      minHeight: 35,
      borderColor: "lightGray",
      borderRadius: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
    },
    colorContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      Width: 300,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
  })
);
