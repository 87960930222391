import React, { useEffect } from "react";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Alert } from "@material-ui/lab";
import { messages } from "./UserSelection.messages";
import { useStyles } from "./UserSelection.style";
import { AuthenticatedUser } from "../../../../generated/authentication-api";
import { Key, setSessionStorageItem } from "../../../../Utils/SessionStorage";
import ProfilePicture from "../../../../Components/ProfilePicture/ProfilePicture";
import { displayAuthenticatedUser } from "../../../../Utils/User";
import CardHeading from "../../../../Components/CardHeading/CardHeading";

interface Props {
  fullWidth?: boolean;
  users: AuthenticatedUser[];
  onUserSelect: (user: AuthenticatedUser) => void;
}

const UserSelection = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { users, onUserSelect, fullWidth } = props;

  const handleUserSelect = (autheticatedUser: AuthenticatedUser) => {
    if (autheticatedUser.token) {
      setSessionStorageItem(Key.JWT_TOKEN, autheticatedUser.token).then(() =>
        onUserSelect(autheticatedUser)
      );
    } else {
      onUserSelect(autheticatedUser);
    }
  };

  useEffect(() => {
    if (users.length === 1) {
      handleUserSelect(users[0]);
    }
  }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

  if (users.length === 1) {
    return null;
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={fullWidth ? 12 : 8}>
        <Container>
          <Grid container justify="center" spacing={2}>
            <Grid item lg={fullWidth ? 12 : 8} xs={12}>
              <Alert
                severity="info"
                variant="standard"
                className={classes.alert}
              >
                {intl.formatMessage(messages.heading)}
              </Alert>
            </Grid>
            {users &&
              users.map((user: AuthenticatedUser) => (
                <Grid key={user.userId} item lg={fullWidth ? 12 : 8} xs={12}>
                  <Paper
                    className={classes.paper}
                    onClick={() => handleUserSelect(user)}
                  >
                    <Grid container>
                      <Grid item>
                        <ProfilePicture
                          size="small"
                          avatarProps={{ variant: "rounded" }}
                        />
                      </Grid>
                      <Grid item>
                        <CardHeading noMarginBottom>
                          {intl
                            .formatMessage(messages[user.userRole.toString()])
                            .toUpperCase()}
                        </CardHeading>
                        <Typography
                          className={classes.leftMargin}
                          align="left"
                          variant="subtitle1"
                        >
                          {displayAuthenticatedUser(user)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default UserSelection;
