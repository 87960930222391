/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMessageRequest
 */
export interface CreateMessageRequest {
    /**
     * The subject of this message
     * @type {string}
     * @memberof CreateMessageRequest
     */
    subject: string;
    /**
     * The contentes of this message
     * @type {string}
     * @memberof CreateMessageRequest
     */
    contents: string;
    /**
     * List of UUID of users that this message will be sent to.
     * @type {Array<string>}
     * @memberof CreateMessageRequest
     */
    recipients: Array<string>;
}

export function CreateMessageRequestFromJSON(json: any): CreateMessageRequest {
    return CreateMessageRequestFromJSONTyped(json, false);
}

export function CreateMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'contents': json['contents'],
        'recipients': json['recipients'],
    };
}

export function CreateMessageRequestToJSON(value?: CreateMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'contents': value.contents,
        'recipients': value.recipients,
    };
}


