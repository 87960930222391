/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Sign action
 * @export
 * @enum {string}
 */
export enum Action {
    CustomerStatusUpdate = 'CUSTOMER_STATUS_UPDATE'
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    return json as Action;
}

export function ActionToJSON(value?: Action | null): any {
    return value as any;
}

