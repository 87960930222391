import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dialog.verifyPersonNumber.title",
    defaultMessage: "Verify",
    description: "dialog title",
  },
  description: {
    id: "dialog.verifyPersonNumber.description",
    defaultMessage:
      "This is only used in test purpose, in production this dialog will not be available",
    description: "dialog title",
  },
  submitButtonLabel: {
    id: "dialog.verifyPersonNumber.button.submit",
    defaultMessage: "Verify person number",
    description: "label for submit button",
  },
  textFieldLabel: {
    id: "dialog.verifyPersonNumber.field.personNumber.label",
    defaultMessage: "Person number",
    description: "Label for text filed",
  },
  generalError: {
    id: "dialog.verifyPersonNumber.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when general error appears",
  },
});
