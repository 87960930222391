/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform file management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileUserResponse,
    FileUserResponseFromJSON,
    FileUserResponseFromJSONTyped,
    FileUserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FileMetadata
 */
export interface FileMetadata {
    /**
     * The name of the file
     * @type {string}
     * @memberof FileMetadata
     */
    fileName: string;
    /**
     * The file id.
     * @type {string}
     * @memberof FileMetadata
     */
    fileId: string;
    /**
     * The size of the file in bytes.
     * @type {number}
     * @memberof FileMetadata
     */
    fileSize: number;
    /**
     * The content type of the file.
     * @type {string}
     * @memberof FileMetadata
     */
    contentType: string;
    /**
     * Date and time when the file was uploaded.
     * @type {Date}
     * @memberof FileMetadata
     */
    uploadedOn: Date;
    /**
     * 
     * @type {FileUserResponse}
     * @memberof FileMetadata
     */
    uploadedBy: FileUserResponse;
    /**
     * Date and time when the file was last opened, null if not opened.
     * @type {Date}
     * @memberof FileMetadata
     */
    lastOpenedAt?: Date;
    /**
     * 
     * @type {Array<FileUserResponse>}
     * @memberof FileMetadata
     */
    userAccessList: Array<FileUserResponse>;
}

export function FileMetadataFromJSON(json: any): FileMetadata {
    return FileMetadataFromJSONTyped(json, false);
}

export function FileMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': json['fileName'],
        'fileId': json['fileId'],
        'fileSize': json['fileSize'],
        'contentType': json['contentType'],
        'uploadedOn': (new Date(json['uploadedOn'])),
        'uploadedBy': FileUserResponseFromJSON(json['uploadedBy']),
        'lastOpenedAt': !exists(json, 'lastOpenedAt') ? undefined : (new Date(json['lastOpenedAt'])),
        'userAccessList': ((json['userAccessList'] as Array<any>).map(FileUserResponseFromJSON)),
    };
}

export function FileMetadataToJSON(value?: FileMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'fileId': value.fileId,
        'fileSize': value.fileSize,
        'contentType': value.contentType,
        'uploadedOn': (value.uploadedOn.toISOString()),
        'uploadedBy': FileUserResponseToJSON(value.uploadedBy),
        'lastOpenedAt': value.lastOpenedAt === undefined ? undefined : (value.lastOpenedAt.toISOString()),
        'userAccessList': ((value.userAccessList as Array<any>).map(FileUserResponseToJSON)),
    };
}


