import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(1),
      width: "100%",
      textAlign: "right",
    },
    accordionIncomingBookings: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      marginBottom: theme.spacing(3),
    },
    accordionOngoingBookings: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(3),
    },
    accordionDetails: {
      padding: theme.spacing(0.3),
    },
    heading: {
      fontWeight: "bold",
      paddingLeft: theme.spacing(1),
    },
    bottomMargin: {
      marginTop: theme.spacing(0.2),
      padding: theme.spacing(2),
    },
    hidden: {
      display: "none",
    },
  })
);
