import { defineMessages } from "react-intl";

export const messages = defineMessages({
  submitButtonLabel: {
    id: "todo.dialog.create.button.submit.label",
    defaultMessage: "Create",
    description: "label for submit button",
  },
  cancelButtonLabel: {
    id: "todo.dialog.create.button.cancel.label",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  success: {
    id: "todo.dialog.create.form.success",
    defaultMessage: "Todo was created",
    description: "Success message when todo has been created",
  },
  error: {
    id: "todo.dialog.create.form.error",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to create a todo",
  },
});
