import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alertDialogTitle: {
    id: "dialog.testVideo.hardwareUnavailable.alertDialog.title",
    defaultMessage: "Could not join meeting",
    description: "Title for alert dialog (hardwareUnavailable)",
  },
  alertDialogContent: {
    id: "dialog.testVideo.hardwareUnavailable.alertDialog.content",
    defaultMessage:
      "Your camera/microphone seems to be used by another application. Close all other applicatopns and refresh the page, if this does not work it may be a good idea to restart your computer before trying again.",
    description: "Content for alert dialog (hardwareUnavailable)",
  },
  alertDialogSubmitLabel: {
    id: "dialog.testVideo.hardwareUnavailable.alertDialog.button.submit",
    defaultMessage: "Refresh page",
    description:
      "Button label for submit in alert dialog (hardwareUnavailable)",
  },
  alertDialogCancelLabel: {
    id: "dialog.testVideo.hardwareUnavailable.alertDialog.button.cancel",
    defaultMessage: "Cancel",
    description:
      "Button label for cancel in alert dialog (hardwareUnavailable)",
  },
});
