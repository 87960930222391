import React from "react";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { FormikProps } from "formik";
import { messages } from "./UsernameLoginFields.messages";
import TextField from "../TextField/TextField";
import { messages as errorMessages } from "../MeetingFields/Components/Validation/Validation.messages";

export interface FormikValues {
  username: string;
  password: string;
}

interface Props {
  formik: FormikProps<FormikValues>;
  disabledFields?: string[];
}

const getErrorMessage = (intl: IntlShape, error?: any) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const UsernameLoginFields = (props: Props) => {
  const intl = useIntl();
  const { formik, disabledFields } = props;
  const { errors } = formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="username"
          formik={formik}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.usernameLabel)}
          placeholder={intl.formatMessage(messages.usernamePlaceholder)}
          errorMsg={getErrorMessage(intl, errors.username?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password"
          formik={formik}
          inputMode="password"
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.passwordLabel)}
          errorMsg={getErrorMessage(intl, errors.password?.toString())}
        />
      </Grid>
    </Grid>
  );
};

export default UsernameLoginFields;
