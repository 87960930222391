/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceUserAuthenticationRequest
 */
export interface ServiceUserAuthenticationRequest {
    /**
     * ClientId to authenticate
     * @type {string}
     * @memberof ServiceUserAuthenticationRequest
     */
    clientId: string;
    /**
     * Client secret, a secret known from both sides.
     * @type {string}
     * @memberof ServiceUserAuthenticationRequest
     */
    clientSecret: string;
}

export function ServiceUserAuthenticationRequestFromJSON(json: any): ServiceUserAuthenticationRequest {
    return ServiceUserAuthenticationRequestFromJSONTyped(json, false);
}

export function ServiceUserAuthenticationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceUserAuthenticationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'clientSecret': json['clientSecret'],
    };
}

export function ServiceUserAuthenticationRequestToJSON(value?: ServiceUserAuthenticationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
    };
}


