import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { Link, Redirect } from "react-router-dom";
import { useIntl } from "react-intl";
import { useStyles } from "./ErrorPage.style";
import { messages } from "./ErrorPage.messages";
import { RoutePath } from "../../Components/Routes/RoutePath";

interface ErrorProps {
  // eslint-disable-next-line react/no-unused-prop-types
  title?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  errorCode?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  description?: string;
}

const buildQueryString = (queryParams: ErrorProps): string => {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(queryParams).length; i += 1) {
    if (Object.values(queryParams)[i] !== "") {
      keyValuePairs.push(
        `${Object.keys(queryParams)[i]}=${encodeURIComponent(
          Object.values(queryParams)[i]
        )}`
      );
    }
  }
  return keyValuePairs.join("&");
};

export const RedirectToErrorPage = (props: ErrorProps) => {
  return <Redirect to={`${RoutePath.ERROR_PAGE}?${buildQueryString(props)}`} />;
};

export const redirectToErrorPage = (props: ErrorProps) => {
  window.location.replace(`${RoutePath.ERROR_PAGE}?${buildQueryString(props)}`);
};

const ErrorPage = () => {
  const intl = useIntl();
  const [
    title = intl.formatMessage(messages.notFountErrorTitle),
  ] = useQueryParam("title", StringParam);
  const [
    errorCode = intl.formatMessage(messages.notFountErrorCode),
  ] = useQueryParam("errorCode", NumberParam);
  const [
    description = intl.formatMessage(messages.notFountErrorDescription),
  ] = useQueryParam("description", StringParam);
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h1">
          {errorCode}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="h3">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Button
          to={RoutePath.DASHBOARD}
          component={Link}
          variant="contained"
          color="primary"
        >
          <Typography align="center" variant="h6">
            {intl.formatMessage(messages.buttonLabel)}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
