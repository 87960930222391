import { defineMessages } from "react-intl";

export const messages = defineMessages({
  searchbarPlaceholder: {
    id: "admin.caregivers.searchbar.placeholder",
    defaultMessage: "Name",
    description: "place holder for searchbar",
  },
  createButton: {
    id: "admin.caregivers.button.create.label",
    defaultMessage: "Create",
    description: "Label for create caregiver button",
  },
  inviteButton: {
    id: "admin.caregivers.button.invite.label",
    defaultMessage: "Invite",
    description: "Label for invite caregiver button",
  },
  createDialogTitle: {
    id: "admin.caregivers.dialog.create.title",
    defaultMessage: "Create caregiver",
    description: "Titel for create new caregiver dialog",
  },
  inviteDialogTitle: {
    id: "admin.caregivers.dialog.create.title",
    defaultMessage: "Invite caregiver",
    description: "Titel for create new caregiver dialog",
  },
  newlyAddedOrUpdatedLabel: {
    id: "admin.caregivers.newlyAddedOrUpdated.label",
    defaultMessage: "Newly added/updated",
    description: "Label for newly added/updated users",
  },
});
