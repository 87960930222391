import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "inviteVerify.title.label",
    defaultMessage: "Verify by enter code",
    description: "title",
  },
  description: {
    id: "inviteVerify.description.label",
    defaultMessage: "A temporary code has been sent to you via email.",
    description: "description",
  },
  error: {
    id: "inviteVerify.error.description.label",
    defaultMessage: "Failed to verify invite",
    description: "error description text",
  },
});
