import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useSubscription } from "react-stomp-hooks";
import { useStyles } from "./ChatCard.style";
import { messages } from "./ChatCard.messages";
import SendChatMessage from "./Components/SendChatMessage/SendChatMessage";
import DisplayChat from "./Components/DisplayChat/DisplayChat";
import FeatureController from "../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { ChatMessageResponse } from "../../generated/meetingchat-api";
import { MeetingParticipantResponse } from "../../generated/meeting-api";
import { meetingChatService } from "../../Providers/ServiceProvider/ServiceProvider";
import {
  parseSignalMessage,
  SignalType,
} from "../../WebSocket/model/SignalMessage";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { SocketDestination } from "../../WebSocket/WebSocketSession";

interface Props {
  readOnly?: boolean;
  meetingId: string;
  participants?: MeetingParticipantResponse[];
  basicLook?: boolean;
  onLoaded?: () => void;
  onLoading?: () => void;
}

const ChatCard = (props: Props) => {
  const {
    readOnly,
    meetingId,
    participants,
    basicLook,
    onLoaded,
    onLoading,
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [chatMessages, setChatMessages] = useState<ChatMessageResponse[]>([]);
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const { enqueueSnackbar } = useSnackbar();
  const [counterNewMessage, setCounterNewMessage] = useState<number>(0);
  const increaseNewMessagesCounter: () => void = () => {
    setCounterNewMessage(counterNewMessage + 1);
  };

  const loadChat = () => {
    if (customerId && meetingId) {
      if (onLoading) {
        onLoading();
      }
      meetingChatService()
        .listChatMessages({
          customerId,
          meetingId,
        })
        .then((res) => {
          return res;
        })
        .then(setChatMessages)
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          })
        )
        .finally(() => {
          increaseNewMessagesCounter();
          if (onLoaded) onLoaded();
        });
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meetingId, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.CHAT_MESSAGE &&
      signalMessage.triggeredBy !== authenticatedUser.user?.id
    ) {
      loadChat();
    }
  });

  useEffect(() => {
    loadChat();
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingChat}>
      <Accordion defaultExpanded elevation={basicLook ? 0 : 1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="chat-content"
          id="chat-header"
          className={basicLook ? classes.basicLook : undefined}
        >
          <Typography
            className={classes.cardHeading}
            component="h2"
            variant="subtitle2"
          >
            {intl.formatMessage(messages.title).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={basicLook ? classes.basicLook : undefined}>
          <Container disableGutters>
            <DisplayChat
              chatMessages={chatMessages}
              participants={participants}
              counterNewMessage={counterNewMessage}
            />
            {!readOnly && (
              <SendChatMessage meetingId={meetingId} onMessageSent={loadChat} />
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    </FeatureController>
  );
};

export default ChatCard;
