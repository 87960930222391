import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(1),
      width: "100%",
      textAlign: "right",
    },
  })
);
