import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./ContactListItem.style";
import { messages } from "./ContactListItem.messages";
import ContactListItemText from "../UserListItemText/ContactListItemText";
import ContactListItemAvatar from "../ContactListItemAvatar/ContactListItemAvatar";
import {
  UserResponse,
  UserRoleType,
  UserStatusType,
} from "../../../../generated/user-api";
import FeatureController from "../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import BookingHistoryButton from "../../../Buttons/BookingHistoryButton/BookingHistoryButton";
import CreateMeetingButton from "../../../Buttons/CreateMeetingButton/CreateMeetingButton";
import CreateMessageButton from "../../../Buttons/CreateMessageButton/CreateMessageButton";
import { contactToUser } from "../../../../Utils/ModelConverter";
import StatusChip from "../../../StatusChip/StatusChip";
import TakeCareSyncButton from "../../../Buttons/TakeCareSyncButton/TakeCareSyncButton";
import { Contact } from "../../../../Models/Contact";
import ContactActionButton from "../../../Buttons/ContactActionButton/ContactActionButton";
import UpdateUserButton from "../../../Buttons/UpdateUserButton/UpdateUserButton";
import PermissionController from "../../../PermissionController/PermissionController";
import { Tab } from "../../../UserForm/ModifyUser/ModifyUserDialog";

interface Props {
  contact: Contact;
  reloadContacts?: () => void;
}

const ContactListItem = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { contact, reloadContacts } = props;
  const participants: UserResponse[] = [
    {
      id: contact.userId,
      email: contact.email,
      firstName: contact.firstName,
      lastName: contact.lastName,
      mobilePhoneNumber: contact.mobilePhoneNumber || "",
      userRole: contact.userRole,
      userStatus: UserStatusType.Active,
      hasHsaId: false, // We dont know if user has HSA_ID
      hasPersonNumber: false, // We dont know if user has PersonNumber
      hasPassword: false, // We dont know if user has Password
      hasActivatedPassword: false, // We dont know if user has activated password
    },
  ];

  return (
    <>
      <ListItem alignItems="flex-start" className={classes.root} divider>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <ContactActionButton contact={contact} onChanged={reloadContacts} />
            <StatusChip status={contact.userStatus} />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.marginBottom}>
            <ContactListItemAvatar contact={contact} />
          </Grid>
          <Grid item sm={4} xs={6}>
            {contact.mobilePhoneNumber && (
              <ContactListItemText
                primaryText={intl.formatMessage(messages.phoneTitle)}
                secondaryText={contact.mobilePhoneNumber}
              />
            )}
          </Grid>
          <Grid item sm={4} xs={6}>
            {contact.email && (
              <ContactListItemText
                primaryText={intl.formatMessage(messages.emailTitle)}
                secondaryText={contact.email}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.justifyRight}>
            {contact.userRole === UserRoleType.Client && (
              <TakeCareSyncButton
                userId={contact.userId}
                className={classes.buttonGrid}
              />
            )}
            <BookingHistoryButton
              className={classes.buttonGrid}
              userId={contact.userId}
              contactId={contact.contactId}
            />
            {contact.userStatus === UserStatusType.Active && (
              <CreateMeetingButton
                className={classes.buttonGrid}
                prePopulatedParticipants={participants}
              />
            )}
            <FeatureController requiredFeature={CustomerFeatureType.Messages}>
              {contact.userStatus === UserStatusType.Active && (
                <CreateMessageButton
                  className={classes.buttonGrid}
                  variant="outlined"
                  recipients={[contactToUser(contact)]}
                />
              )}
            </FeatureController>
            <PermissionController allowedRoles={[UserRoleType.Staff]}>
              {contact.userRole !== UserRoleType.Staff && (
                <FeatureController
                  requiredFeature={
                    contact.userRole === UserRoleType.Client
                      ? CustomerFeatureType.UpdateClientsViaContacts
                      : CustomerFeatureType.UpdateExternalsViaContacts
                  }
                >
                  <UpdateUserButton
                    visibleTabs={[Tab.GENERAL, Tab.PASSWORD]}
                    userToUpdate={contactToUser(contact)}
                    onUpdated={reloadContacts}
                    className={classes.buttonGrid}
                  />
                </FeatureController>
              )}
            </PermissionController>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default ContactListItem;
