/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerUnit
 */
export interface CustomerUnit {
    /**
     * HSA-ID
     * @type {string}
     * @memberof CustomerUnit
     */
    hsaId: string;
    /**
     * Name of the unit
     * @type {string}
     * @memberof CustomerUnit
     */
    name: string;
}

export function CustomerUnitFromJSON(json: any): CustomerUnit {
    return CustomerUnitFromJSONTyped(json, false);
}

export function CustomerUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hsaId': json['hsaId'],
        'name': json['name'],
    };
}

export function CustomerUnitToJSON(value?: CustomerUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hsaId': value.hsaId,
        'name': value.name,
    };
}


