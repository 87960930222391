/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageUserResponse,
    MessageUserResponseFromJSON,
    MessageUserResponseFromJSONTyped,
    MessageUserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface MessageThreadResponse
 */
export interface MessageThreadResponse {
    /**
     * UUID of this message
     * @type {string}
     * @memberof MessageThreadResponse
     */
    id: string;
    /**
     * The subject of thread
     * @type {string}
     * @memberof MessageThreadResponse
     */
    subject: string;
    /**
     * 
     * @type {MessageUserResponse}
     * @memberof MessageThreadResponse
     */
    recipient: MessageUserResponse;
    /**
     * 
     * @type {MessageUserResponse}
     * @memberof MessageThreadResponse
     */
    sender: MessageUserResponse;
    /**
     * Number of replies in thread.
     * @type {number}
     * @memberof MessageThreadResponse
     */
    nbrOfReplyMessages: number;
    /**
     * Number of unread messages in thread.
     * @type {number}
     * @memberof MessageThreadResponse
     */
    nbrOfUnreadMessages: number;
    /**
     * Date and time the last message in thread was sent
     * @type {Date}
     * @memberof MessageThreadResponse
     */
    latestMessageSentOn?: Date;
    /**
     * Date and time this message was sent
     * @type {Date}
     * @memberof MessageThreadResponse
     */
    createdOn: Date;
}

export function MessageThreadResponseFromJSON(json: any): MessageThreadResponse {
    return MessageThreadResponseFromJSONTyped(json, false);
}

export function MessageThreadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageThreadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subject': json['subject'],
        'recipient': MessageUserResponseFromJSON(json['recipient']),
        'sender': MessageUserResponseFromJSON(json['sender']),
        'nbrOfReplyMessages': json['nbrOfReplyMessages'],
        'nbrOfUnreadMessages': json['nbrOfUnreadMessages'],
        'latestMessageSentOn': !exists(json, 'latestMessageSentOn') ? undefined : (new Date(json['latestMessageSentOn'])),
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function MessageThreadResponseToJSON(value?: MessageThreadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'recipient': MessageUserResponseToJSON(value.recipient),
        'sender': MessageUserResponseToJSON(value.sender),
        'nbrOfReplyMessages': value.nbrOfReplyMessages,
        'nbrOfUnreadMessages': value.nbrOfUnreadMessages,
        'latestMessageSentOn': value.latestMessageSentOn === undefined ? undefined : (value.latestMessageSentOn.toISOString()),
        'createdOn': (value.createdOn.toISOString()),
    };
}


