import { defineMessages } from "react-intl";

export const messages = defineMessages({
  adminPortalProfileSettingsTab: {
    id: "portalCustomerSettings.tab.profile.label",
    defaultMessage: "Profile",
    description: "label for admin portal profile settings",
  },
  adminPortalThemeSettingsTab: {
    id: "portalCustomerSettings.tab.theme.label",
    defaultMessage: "Theme",
    description: "label for admin portal theme settings",
  },
  adminPortalUserSettingsTab: {
    id: "portalCustomerSettings.tab.users.label",
    defaultMessage: "Users",
    description: "label for admin portal user settings",
  },
  adminPortalCustomerFeaturesTab: {
    id: "portalCustomerSettings.tab.features.label",
    defaultMessage: "Features",
    description: "label for admin portal customer feature settings",
  },
  adminPortalNotificationFeaturesTab: {
    id: "portalCustomerSettings.tab.notifications.label",
    defaultMessage: "Notifications",
    description: "label for admin portal notification feature settings",
  },
  adminPortalAnonymizeFeaturesTab: {
    id: "portalCustomerSettings.tab.anonymize.label",
    defaultMessage: "GDPR",
    description: "label for admin portal Anonymize feature settings",
  },
  adminPortalContentSettingsTab: {
    id: "portalCustomerSettings.tab.content.label",
    defaultMessage: "Content",
    description: "label for admin portal content feature settings",
  },
  adminPortalConsentAgreementSettingsTab: {
    id: "portalCustomerSettings.tab.consentAgreement.label",
    defaultMessage: "Agreement & policy",
    description: "label for admin portal Agreement and policy settings",
  },
});
