import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      marginTop: "auto",
      backgroundColor: theme.palette.primary.main,
    },
    content: {
      maxWidth: "md",
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
    },
    gridItem: {
      textAlign: "center",
      paddingTop: theme.spacing(2),
    },
    versionText: {
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    titleText: {
      fontWeight: "bolder",
      color: theme.palette.primary.contrastText,
    },
    text: {
      color: theme.palette.primary.contrastText,
    },
    buttonText: {
      color: theme.palette.primary.contrastText,
      margin: 0,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    button: {
      padding: 0,
    },
  })
);
