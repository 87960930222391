import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noMessages: {
    id: "displayMessagesCard.noMessages",
    defaultMessage: "Nothing here! Say hello!",
    description: "Text to be shown when no messages",
  },
  noMessageSelected: {
    id: "displayMessagesCard.noMessageSelected",
    defaultMessage: "No message selected",
    description: "Text to be shown when no message selected",
  },
  createButton: {
    id: "displayMessagesCard.button.create",
    defaultMessage: "Create new message",
    description: "Button label for create new message",
  },
  oneWayMessageWarning: {
    id: "displayMessagesCard.warning.oneWayMessage",
    defaultMessage: "These messages cannot be replied to",
    description: "Alert warning",
  },
  oneWayMessageWarningStaff: {
    id: "displayMessagesCard.warning.oneWayMessage.staff",
    defaultMessage: "The recipient cannot reply to this messages",
    description: "Alert warning",
  },
});
