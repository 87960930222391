import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  dialogTitle: {
    id: "dialog.modifyMeeting.title",
    defaultMessage: "Update Meeting",
    description: "titel for update meeting dialog ",
  },
  submitButtonLabel: {
    id: "dialog.modifyMeeting.button.submit",
    defaultMessage: "Update Meeting",
    description: "Submit label for updating meeting",
  },
  cancelButtonLabel: {
    id: "dialog.modifyMeeting.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel label for cancel update of meeting",
  },
  success: {
    id: "dialog.modifyMeeting.success",
    defaultMessage: "Meeting was updated",
    description: "Success message when meeting has been updated",
  },
  generalError: {
    id: "dialog.modifyMeeting.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to update a meeting",
  },
  createUserDialogTitle: {
    id: "dialog.modifyMeeting.createUser.dialog.title",
    defaultMessage: "Create patient",
    description: "Title for create user dialog",
  },
  createUserButtonLabel: {
    id: "dialog.modifyMeeting.createUser.dialog.button.label",
    defaultMessage: "Add new patient",
    description: "Button label for creat new patien",
  },
});
