import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "notes.title",
    defaultMessage: "Private notes",
    description: "Heading for notes card in meeting session view",
  },
  helperText: {
    id: "notes.helperText",
    defaultMessage: "Seen only by you",
    description: "Helper text for notes to indicate visibility",
  },
  content: {
    id: "notes.description",
    defaultMessage:
      "Note that this is not a journal or part of a journal. If the case is covered by medical record requirements, you must follow the regular procedures.",
    description: "Descriptive text shown in notes card",
  },
  saveButton: {
    id: "notes.button.save",
    defaultMessage: "Save",
    description: "Label for button to save note",
  },
  success: {
    id: "notes.button.save.success",
    defaultMessage: "Successgully saved notes",
    description: "Text to be shown when successfully saves notes",
  },
  error: {
    id: "notes.button.save.error",
    defaultMessage: "Could not save notes",
    description: "Text to be shown when fails to save notes",
  },
});
