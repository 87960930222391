import { Contact } from "../Models/Contact";
import { MessageThread } from "../Models/Message";
import { getThreadCounterPart } from "./Message";
import { Meeting, MeetingParticipant } from "../Models/Meeting";
import { MeetingParticipantType } from "../generated/meeting-api";
import { UserRoleType } from "../generated/user-api";
import { User } from "../Models/User";

export const getUserIdsFromContacts = (
  userRole: UserRoleType,
  contactList?: Contact[]
): Set<string> => {
  const result = new Set<string>();
  contactList
    ?.filter((contact) => contact.userRole === userRole)
    .forEach((u) => result.add(u.userId));
  return result;
};

export const getUserIdsFromUsers = (
  userRole: UserRoleType,
  userList?: User[]
): Set<string> => {
  const result = new Set<string>();
  userList
    ?.filter((user) => user.userRole === userRole)
    .forEach((user) => result.add(user.id));
  return result;
};

export const getUserIdsFromMessages = (
  authenticatedUserId?: string,
  threads?: MessageThread[]
): Set<string> => {
  const result = new Set<string>();
  const userIds =
    threads
      ?.map((thread) => getThreadCounterPart(thread, authenticatedUserId))
      .map((user) => user?.id) || [];

  userIds.forEach((id) => {
    if (id !== undefined) {
      result.add(id);
    }
  });

  return result;
};

export const getUserIdsFromParticipants = (
  participantType: MeetingParticipantType,
  participants?: MeetingParticipant[]
): Set<string> => {
  const result = new Set<string>();
  participants
    ?.filter((p) => p.participantType === participantType)
    .forEach((p) => result.add(p.userId));
  return result;
};

export const getUserIdsFromMeetings = (
  participantType: MeetingParticipantType,
  meetings?: Meeting[]
): Set<string> => {
  const result = new Set<string>();

  meetings?.forEach((meeting) => {
    getUserIdsFromParticipants(
      participantType,
      meeting.participants
    ).forEach((userId) => result.add(userId));
  });
  return result;
};
