import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  Box,
  withStyles,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useStyles } from "./PrivateNotesSlider.style";
import { messages } from "./PrivateNotesSlider.messages";
import FeatureController from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";
import PrivateNotesEditor from "../../../../Components/PrivateNotesCard/Components/PrivateNotesEditor/PrivateNotesEditor";
import DialogCloseButton from "../../../../Components/Buttons/DialogCloseButton/DialogCloseButton";
import { notesService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { NotesResponse } from "../../../../generated/notes-api";

interface Props {
  meetingId: string;
  onLoaded?: () => void;
  onLoading?: () => void;
  isExpanded: boolean;
  onClose: () => void;
}

const PrivateNotesSlider = (props: Props) => {
  const { meetingId, onLoaded, onLoading, isExpanded, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useCustomerId();
  const [notesId, setNotesId] = useState<string>();
  const [notes, setNotes] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const StyledAccordionSummary = withStyles({
    root: {
      minHeight: theme.spacing(3),
      maxHeight: theme.spacing(3),
      "&.Mui-expanded": {
        minHeight: theme.spacing(3),
        maxHeight: theme.spacing(3),
      },
    },
  })(AccordionSummary);

  const doNothing = () => {};

  const createNote = () => {
    if (customerId) {
      notesService()
        .createMeetingNotes({
          customerId,
          meetingId,
          notesRequest: { notes },
        })
        .then((res) => {
          setNotesId(res.id);
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        );
    }
  };

  useEffect(() => {
    if (customerId) {
      if (onLoading) {
        onLoading();
      }
      notesService()
        .listNotesByMeetingForUser({
          customerId,
          meetingId,
        })
        .then((res) => {
          const note: NotesResponse | undefined = res.data?.pop();
          if (note === undefined) {
            createNote();
          } else {
            setNotes(note?.notes || "");
            setNotesId(note?.id);
          }
        })
        .finally(onLoaded);
    }
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isSmallScreen) {
    return (
      <FeatureController requiredFeature={CustomerFeatureType.InMeetingChat}>
        <Dialog open fullScreen className={classes.dialogRoot} scroll="paper">
          {onClose && <DialogCloseButton onClose={onClose} />}
          <DialogTitle>
            <Typography
              className={classes.cardHeadingSx}
              component="h2"
              variant="subtitle2"
            >
              {intl.formatMessage(messages.title).toUpperCase()}
              <LockIcon fontSize="inherit" className={classes.icon} />
              {intl.formatMessage(messages.helperText)}
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Container>
              <Typography gutterBottom variant="subtitle2">
                <Box fontStyle="italic">
                  {intl.formatMessage(messages.content)}
                </Box>
              </Typography>
              {notesId && (
                <PrivateNotesEditor
                  fullHeight
                  meetingId={meetingId}
                  notesId={notesId}
                  initialValue={notes}
                />
              )}
            </Container>
          </DialogActions>
        </Dialog>
      </FeatureController>
    );
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingNotes}>
      <Box className={classes.root}>
        <Accordion
          hidden={!isExpanded}
          elevation={1}
          square
          expanded={isExpanded}
          onChange={() => doNothing()}
        >
          <StyledAccordionSummary
            aria-controls="private-notes"
            id="notes-header"
            className={classes.accordionSummary}
          >
            <Typography
              className={classes.cardHeading}
              component="h2"
              variant="subtitle2"
            >
              {intl.formatMessage(messages.title).toUpperCase()}
              <LockIcon fontSize="inherit" className={classes.icon} />
              {intl.formatMessage(messages.helperText)}
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Container disableGutters>
              <Typography gutterBottom variant="subtitle2">
                <Box fontStyle="italic">
                  {intl.formatMessage(messages.content)}
                </Box>
              </Typography>
              {notesId && (
                <PrivateNotesEditor
                  meetingId={meetingId}
                  notesId={notesId}
                  initialValue={notes}
                />
              )}
            </Container>
          </AccordionDetails>
        </Accordion>
      </Box>
    </FeatureController>
  );
};

export default PrivateNotesSlider;
