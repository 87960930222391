import React from "react";
import { Chip } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { useStyles } from "./meetingStatusChip.style";
import { messages } from "./meetingStatusChip.messages";
import { MeetingStatusType } from "../../generated/meeting-api";

interface Props {
  status: MeetingStatusType;
}

export const getMeetingStatusLabel = (
  status: MeetingStatusType,
  intl: IntlShape
): string => {
  switch (status) {
    case MeetingStatusType.Created:
      return intl.formatMessage(messages.createdStatus).toUpperCase();
    case MeetingStatusType.InProgress:
      return intl.formatMessage(messages.inProgressStatus).toUpperCase();
    case MeetingStatusType.Completed:
      return intl.formatMessage(messages.completedStatus).toUpperCase();
    case MeetingStatusType.Cancelled:
      return intl.formatMessage(messages.cancelledStatus).toUpperCase();
    default:
      return intl.formatMessage(messages.unknownStatus).toUpperCase();
  }
};

const MeetingStatusChip = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { status } = props;

  return (
    <Chip
      size="small"
      className={classes[status]}
      label={getMeetingStatusLabel(status, intl)}
    />
  );
};

export default MeetingStatusChip;
