import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Divider, Grid, ListItem, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import { useSnackbar } from "notistack";
import { messages } from "./ContentSettings.messages";
import { useStyles } from "./ContentSettings.styles";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { hasPermission } from "../PermissionController/PermissionController";
import { RoutePath } from "../Routes/RoutePath";
import {
  FeaturesContactDetailsConfig,
  FeaturesTechSupportConfig,
  FeaturesTextAndButtonsConfig,
} from "./ContentSettingsConfig";
import ContentText from "./ContentText/ContentText";
import { customerContentService } from "../../Providers/ServiceProvider/ServiceProvider";
import {
  ContentItemTypeRequest,
  ContentItemTypeResponse,
} from "../../generated/customercontent-api";

export interface Props {
  customerId?: string;
}

const ContentSettings = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const featureTextAndButtonsConfig = FeaturesTextAndButtonsConfig();
  const featureTechSupportConfig = FeaturesTechSupportConfig();
  const featureContactDetailsConfig = FeaturesContactDetailsConfig();
  const [authenticatedUser] = useAuthenticatedUser();
  const [contentItemType, setContentItemType] = useState<
    ContentItemTypeResponse[]
  >();

  const loadContents = () => {
    if (customerId) {
      customerContentService()
        .getAllCustomerContentItems({
          customerId,
        })
        .then((res: Array<ContentItemTypeResponse>) => {
          setContentItemType(res);
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.getReadData), {
            variant: "error",
          });
        });
    }
  };

  const updateContent = (contentItemTypeRequest: ContentItemTypeRequest) => {
    if (customerId) {
      customerContentService(true)
        .updateCustomerContentItem({
          customerId,
          contentItemTypeRequest,
        })
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.successMessage), {
            variant: "success",
          });
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.getReadData), {
            variant: "error",
          });
        })
        .finally(loadContents);
    }
  };

  useEffect(() => {
    loadContents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.CustomerSettings],
    allowedRoles: [UserRoleType.Staff, UserRoleType.PlatformAdmin],
  });
  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }

  if (!contentItemType) {
    return null;
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {intl.formatMessage(messages.customerSettingsTab)}
          </h3>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.customerContentTextAndButtonsCategory)}
          </h3>
          <Divider className={classes.sectionLine} light />
          <List>
            {featureTextAndButtonsConfig.map((config, index) => (
              <ListItem
                key={config.type}
                divider={index < featureTextAndButtonsConfig.length - 1}
              >
                <ContentText
                  label={config.label}
                  itemType={config.type}
                  hasTitle={config.hasTitle}
                  contentItemTypeResponse={contentItemType.find(
                    (customerContent) => customerContent.type === config.type
                  )}
                  onChanged={updateContent}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <Divider className={classes.sectionLine} light />
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.customerContentContactCategory)}
          </h3>
          <Divider className={classes.sectionLine} light />
          <List>
            {featureContactDetailsConfig.map((config, index) => (
              <ListItem
                key={config.type}
                divider={index < featureContactDetailsConfig.length - 1}
              >
                <ContentText
                  label={config.label}
                  itemType={config.type}
                  hasTitle={config.hasTitle}
                  contentItemTypeResponse={contentItemType?.find(
                    (customerContent) => customerContent.type === config.type
                  )}
                  onChanged={updateContent}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <Divider className={classes.sectionLine} light />
          <h3 className={classes.subTitle}>
            {intl.formatMessage(messages.customerContentTechSupportCategory)}
          </h3>
          <Divider className={classes.sectionLine} light />
          <List>
            {featureTechSupportConfig.map((config, index) => (
              <ListItem
                key={config.type}
                divider={index < featureTechSupportConfig.length - 1}
              >
                <ContentText
                  label={config.label}
                  itemType={config.type}
                  hasTitle={config.hasTitle}
                  contentItemTypeResponse={contentItemType?.find(
                    (customerContent) => customerContent.type === config.type
                  )}
                  onChanged={updateContent}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ContentSettings;
