import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonCreateLabel: {
    id: "button.createOrUpdateCustomer.create.label",
    defaultMessage: "Create",
    description: "Label for create customer button",
  },
  buttonUpdateLabel: {
    id: "button.createOrUpdateCustomer.update.label",
    defaultMessage: "Update",
    description: "Label for update customer button",
  },
});
