import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  bookingOfferHeader: {
    id: "dialog.scheduleMeeting.bookingOffer",
    defaultMessage: "Booking templates",
    description: "Heading for section booking offer in create meeting",
  },
  submitButtonLabel: {
    id: "dialog.scheduleMeeting.button.submit",
    defaultMessage: "Schedule meeting",
    description: "Submit label for creating new meeting",
  },
  cancelButtonLabel: {
    id: "dialog.scheduleMeeting.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel label for close dialog button",
  },
  success: {
    id: "dialog.scheduleMeeting.success",
    defaultMessage: "Meeting was created",
    description: "Success message when meeting has been created",
  },
  generalError: {
    id: "dialog.scheduleMeeting.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to create a meeting",
  },
});
