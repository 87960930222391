import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { useStyles } from "./PortalCustomers.style";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useSpinner } from "../../../Components/Spinner/Spinner";
import { customerService } from "../../../Providers/ServiceProvider/ServiceProvider";
import CustomPagination from "../../../Components/CustomPagination/Pagination";
import {
  GetCustomersListRequest,
  PaginatedCustomerList,
} from "../../../generated/customer-api";
import { RoutePath } from "../../../Components/Routes/RoutePath";
import CreateOrUpdateCustomerButton from "../../../Components/Buttons/CreateOrUpdateCustomerButton/CreateOrUpdateCustomerButton";
import { UserRoleType } from "../../../generated/user-api";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { hasPermission } from "../../../Components/PermissionController/PermissionController";
import CustomerList from "../../../Components/CustomerList/CustomerList";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import { messages } from "./PortalCustomers.messages";
import { Variant } from "../../../Components/Dialogs/CreateOrUpdateCustomerDialog/CreateOrUpdateCustomerDialog";

const PortalCustomers = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [result, setResult] = useState<PaginatedCustomerList>();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>();
  const setSpinner = useSpinner()[1];
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadCustomerList = () => {
    if (customerId) {
      setIsLoading(true);
      customerService()
        .getCustomersList({
          search: query !== "" ? query : undefined,
          page,
          pageSize,
        } as GetCustomersListRequest)
        .then((res) => {
          setResult(res);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleDialogClose = () => {
    loadCustomerList();
  };

  useEffect(() => {
    loadCustomerList();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      loadCustomerList();
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading });
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    allowedRoles: [UserRoleType.PlatformAdmin],
  });

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }

  if (!result) {
    return null;
  }

  return (
    <>
      <div className={classes.actionContainer}>
        <CreateOrUpdateCustomerButton
          onClose={handleDialogClose}
          variant={Variant.CREATE}
        />
      </div>
      <SearchBar
        placeholder={intl.formatMessage(messages.searchbarPlaceholder)}
        onSubmit={(newQuery) => setQuery(newQuery)}
      />
      <Paper>
        {!isLoading && (
          <>
            <CustomerList list={result} />
            <CustomPagination
              onChange={setPage}
              pagination={result?.pagination}
            />
          </>
        )}
      </Paper>
    </>
  );
};

export default PortalCustomers;
