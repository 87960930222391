/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingResponse,
    MeetingResponseFromJSON,
    MeetingResponseFromJSONTyped,
    MeetingResponseToJSON,
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedMeetingResponseList
 */
export interface PaginatedMeetingResponseList {
    /**
     * 
     * @type {Pagination}
     * @memberof PaginatedMeetingResponseList
     */
    pagination: Pagination;
    /**
     * 
     * @type {Array<MeetingResponse>}
     * @memberof PaginatedMeetingResponseList
     */
    data?: Array<MeetingResponse>;
}

export function PaginatedMeetingResponseListFromJSON(json: any): PaginatedMeetingResponseList {
    return PaginatedMeetingResponseListFromJSONTyped(json, false);
}

export function PaginatedMeetingResponseListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMeetingResponseList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(MeetingResponseFromJSON)),
    };
}

export function PaginatedMeetingResponseListToJSON(value?: PaginatedMeetingResponseList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(MeetingResponseToJSON)),
    };
}


