import React, { useState } from "react";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { displayUser, isAuthenticatedUser } from "../../../../Utils/User";
import { messages } from "./TodoList.messages";
import Tooltip from "../../../Tooltip/Tooltip";
import {
  PaginatedToDoResponseList,
  ToDoResponse,
  ToDoStatusType,
} from "../../../../generated/todo-api";
import { useStyles } from "./TodoList.style";
import ViewTodoDialog from "../ViewTodoDialog/ViewTodoDialog";
import { todoService } from "../../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  todos?: PaginatedToDoResponseList;
  onDeleted?: () => void;
  onUpdated?: () => void;
}

const TodoList = (props: Props) => {
  const { todos, onDeleted, onUpdated } = props;
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedTodo, setSelectedTodo] = useState<ToDoResponse>();

  const handleDialogOpen = (todo: ToDoResponse): void => {
    setSelectedTodo(todo);
    setIsDialogOpen(true);
  };

  const handleDelete = (todoId: string): void => {
    if (customerId) {
      todoService()
        .deleteToDo({
          todoId,
          customerId,
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.deleteSuccess), {
            variant: "success",
          })
        )
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(onDeleted);
    }
  };

  const handleToggle = (
    event: React.MouseEvent,
    todoId: string,
    currentStatus: ToDoStatusType
  ) => {
    event.stopPropagation();
    if (customerId) {
      todoService()
        .updateToDo({
          todoId,
          customerId,
          updateToDoRequest: {
            status:
              currentStatus === ToDoStatusType.Completed
                ? ToDoStatusType.NotCompleted
                : ToDoStatusType.Completed,
          },
        })
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(onUpdated);
    }
  };

  const mapTodos = todos?.data?.map((todo) => {
    const isCheckboxDisabled =
      !isAuthenticatedUser(todo.assignedTo.id, authenticatedUser.user) &&
      !isAuthenticatedUser(todo.assignedBy.id, authenticatedUser.user);

    const isDeleteDisabled = !isAuthenticatedUser(
      todo.assignedBy.id,
      authenticatedUser.user
    );

    return (
      <Tooltip title={intl.formatMessage(messages.openTooltip)}>
        <ListItem
          button
          onClick={() => handleDialogOpen(todo)}
          className={classes.root}
        >
          <ListItemIcon>
            <Checkbox
              onClick={(e) => handleToggle(e, todo.id, todo.status)}
              edge="start"
              checked={todo.status === ToDoStatusType.Completed}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": todo.id }}
              disabled={isCheckboxDisabled}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">{todo.title}</Typography>}
            secondary={
              <Typography variant="body2">
                {displayUser(todo.assignedTo, true)}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            {!isDeleteDisabled && (
              <Tooltip title={intl.formatMessage(messages.deleteTooltip)}>
                <IconButton onClick={() => handleDelete(todo.id)}>
                  <HighlightOffIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </Tooltip>
    );
  });

  if (!todos?.data) {
    return null;
  }
  return (
    <>
      {selectedTodo && (
        <ViewTodoDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          todo={selectedTodo}
        />
      )}
      <List>{mapTodos}</List>
    </>
  );
};

export default TodoList;
