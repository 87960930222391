import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useIntl } from "react-intl";
import { messages } from "./SafariPhoneGuide.messages";
import SafariPhone1 from "../../../../../Assets/safari-phone-1.png";
import SafariPhone2 from "../../../../../Assets/safari-phone-2.png";
import SafariPhone3 from "../../../../../Assets/safari-phone-3.jpg";
import { useStyles } from "./SafariPhoneGuide.style";

const SafariPhoneGuide = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.dialogTitle)}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          <ol>
            <li>{intl.formatMessage(messages.step1)}</li>
            <li>{intl.formatMessage(messages.step2)}</li>
            <li>{intl.formatMessage(messages.step3)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={SafariPhone1}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step4)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={SafariPhone2}
              alt={intl.formatMessage(messages.imgAltText)}
            />
            <li>{intl.formatMessage(messages.step5)}</li>
            <img
              style={{ maxWidth: 300 }}
              className={classes.image}
              src={SafariPhone3}
              alt={intl.formatMessage(messages.imgAltText)}
            />
          </ol>
        </Typography>
      </DialogContent>
    </>
  );
};

export default SafariPhoneGuide;
