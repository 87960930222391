import { defineMessages } from "react-intl";

export const messages = defineMessages({
  workTitle: {
    id: "userlist.item.worktitle",
    defaultMessage: "Work title",
    description: "title for work title in list item",
  },
  phoneTitle: {
    id: "userlist.item.phonetitle",
    defaultMessage: "Phone",
    description: "title for phone in list item",
  },
  emailTitle: {
    id: "userlist.item.emailtitle",
    defaultMessage: "Email",
    description: "title for email in list item",
  },
});
