import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipContainerDefault: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 6,
      display: "flex",
      flexDirection: "row",
      maxWidth: "85%",
    },
    chipContainerSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 20,
      paddingLeft: 5,
      display: "flex",
      flexDirection: "row-reverse",
      maxWidth: "85%",
    },
    timeFormat: {
      margin: 15,
      marginTop: 3,
      fontSize: 6,
      opacity: 0.5,
    },
    avatar: {
      padding: 0,
      margin: 0,
      backgroundColor: "inherit",
    },
    left: {
      textAlign: "left",
    },
    right: {
      textAlign: "right",
    },
    chip: {
      height: "100%",
      marginBottom: theme.spacing(0),
      display: "flex",
      flexDirection: "row",
    },
    chipReverse: {
      height: "100%",
      marginBottom: theme.spacing(0),
      display: "flex",
      flexDirection: "row-reverse",
    },
    dateFormat: {
      marginTop: 15,
      marginBottom: 15,
    },
    dateContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    divSpliter: {
      width: "20%",
      height: 1,
      backgroundColor: "gray",
      opacity: 0.2,
    },
    checkyes: {
      width: 15,
      height: 15,
      marginTop: 5,
      marginLeft: 5,
    },
    checksecond: {
      width: 15,
      height: 15,
      marginTop: 5,
      marginLeft: -9,
      marginRight: 10,
    },
  })
);
