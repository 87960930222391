import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./Components/DefaultTheme/DefaultTheme";
import { useCustomerTheme } from "../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  children: React.ReactNode;
}

const getFaviconEl = (): HTMLElement | undefined => {
  const favicon = document.getElementById("favicon");
  return favicon !== null ? favicon : undefined;
};

const ThemeProviders = (props: Props) => {
  const { children } = props;
  const customerTheme = useCustomerTheme();

  const updateFavIcon = () => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if (customerTheme?.favicon) {
      favicon?.setAttribute("href", `data:;base64,${customerTheme.favicon}`);
    }
  };

  updateFavIcon();
  return (
    <MuiThemeProvider theme={defaultTheme(customerTheme)}>
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProviders;
