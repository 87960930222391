import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dashboardview.bookingscard.title",
    defaultMessage: "BOOKINGS",
    description: "heading for bookings card",
  },
  nothingToShow: {
    id: "dashboardview.bookingscard.nothingToShow",
    defaultMessage: "No new bookings",
    description: "text to be shown when no new bookings",
  },
});
