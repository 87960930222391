import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    updateCustomerButton: {
      textAlign: "right",
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    statusButtonDiv: {
      display: "flex",
      justifyContent: "right",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
  })
);
