import React, { useState } from "react";
import { FormikProps } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilterOptionsState } from "@material-ui/lab";
import { useIntl } from "react-intl";
import { formatName, formatPersonNumber } from "../../../Utils/User";
import { User } from "../../../Models/User";
import { validPersonNumber } from "../../../Utils/RegExp";
import RenderOption, { Option } from "./Components/RenderOption/RenderOption";
import { UserResponse, UserRoleType } from "../../../generated/user-api";
import CreateUserDialog from "../../Buttons/CreateUserButton/CreateUserDialog";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import RenderInput from "./Components/RenderInput/RenderInput";
import RenderTags from "./Components/RenderTags/RenderTags";
import { messages } from "./UserSelectField.messages";

export interface NotAvailableUser {
  id: string;
  name: string;
}

interface Props {
  id: string;
  role?: UserRoleType;
  label: string;
  errorMsg?: string;
  formik: FormikProps<any>;
  disabledFields?: string[];
  size?: "small" | "medium";
  meetingLeader?: User;
  userOptionList?: User[];
  userFixedList?: User[];
}

const UserSelectField = (props: Props) => {
  const {
    id,
    role,
    size,
    label,
    formik,
    errorMsg,
    disabledFields,
    meetingLeader,
    userFixedList,
    userOptionList,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [userToBeCreated, setUserToBeCreated] = useState<string>();
  const customerId = useCustomerId();
  const intl = useIntl();

  const addIfNotExist = (user: User, users: User[]): User[] => {
    if (!users.find((u) => u.id === user.id)) {
      users.push(user);
    }

    return users;
  };

  const handleChange = async (value: User[]) => {
    let newValue = value;
    userFixedList?.forEach((user) => {
      newValue = addIfNotExist(user, newValue);
    });

    // If user attempts to clear staff field, always add meetingleader (this is only used when creating meeting)
    if (
      meetingLeader &&
      id === "staffParticipants" &&
      (!value || value?.length === 0)
    ) {
      newValue = addIfNotExist(meetingLeader, newValue);
    }
    await formik.setFieldValue(id, newValue);
    formik.setFieldTouched(id, true);
  };

  const formatLabel = (user: User): string => {
    const nameLabel =
      formatName(user.firstName, user.lastName) === "" && user.email
        ? user.email
        : formatName(user.firstName, user.lastName);

    const personNumberLabel = `${nameLabel} (${formatPersonNumber(
      user.personNumber
    )})`;

    return user.personNumber ? personNumberLabel : nameLabel;
  };

  const handleOnCreateUser = (personNumber: string) => {
    setUserToBeCreated(personNumber);
    setIsOpen(true);
  };

  const filterOptions = (
    options: Option[],
    state: FilterOptionsState<Option>
  ) => {
    const selectedOptions: User[] = formik.values[id];
    const selectedOptionIds: string[] = formik.values[id].map(
      (user: User) => user.id
    );
    const input: string = state.inputValue.toLowerCase().replaceAll("-", "");
    const compactInput: string = input.length > 10 ? input.substring(2) : input;

    const result = options.filter(
      (option) =>
        !selectedOptionIds.includes(option.id) &&
        formatLabel(option).toLowerCase().replaceAll("-", "").includes(input)
    );

    if (result.length > 0) {
      return result;
    }
    if (role && validPersonNumber.test(input)) {
      options.filter(
        (option) =>
          !selectedOptionIds.includes(option.id) &&
          formatLabel(option).toLowerCase().replaceAll("-", "").includes(input)
      );

      if (
        !selectedOptions
          .map((option) =>
            option.personNumber?.replaceAll("-", "").substring(2)
          )
          .includes(compactInput)
      )
        return [
          { id: input, personNumber: input, isNewUser: true },
        ] as Option[];
    }
    return [];
  };

  const addCreatedUserAsClientParticipant = (user: UserResponse) => {
    formik.setFieldValue(id, [...formik.values[id], user], true);
  };

  const getDialogTitle = () => {
    switch (role) {
      case UserRoleType.Client:
        return intl.formatMessage(messages.dialogTitleClient);
      case UserRoleType.External:
        return intl.formatMessage(messages.dialogTitleExternal);
      case UserRoleType.Staff:
        return intl.formatMessage(messages.dialogTitleStaff);
      default:
        return "Unknown";
    }
  };

  return (
    <>
      {customerId && role && (
        <CreateUserDialog
          title={getDialogTitle()}
          customerId={customerId}
          isOpen={isOpen}
          userRole={role}
          onClose={() => setIsOpen(false)}
          preFilledPersonNumber={userToBeCreated}
          onCreated={addCreatedUserAsClientParticipant}
        />
      )}
      <Autocomplete
        id={id}
        disabled={disabledFields?.includes(id)}
        multiple
        filterSelectedOptions
        value={formik.values[id]}
        options={userOptionList || []}
        onChange={(event, value) => {
          handleChange(value);
        }}
        getOptionLabel={(option: Option) => formatLabel(option)}
        filterOptions={filterOptions}
        renderOption={(option) => (
          <RenderOption
            option={option}
            role={role}
            onCreateUser={handleOnCreateUser}
          />
        )}
        renderTags={(tagValue, getTagProps) => (
          <RenderTags
            size={size}
            formik={formik}
            tagValue={tagValue}
            tagProps={getTagProps}
            userFixedList={userFixedList}
            meetingLeader={meetingLeader}
            disabledFields={disabledFields}
          />
        )}
        renderInput={(params) => (
          <RenderInput
            id={id}
            label={label}
            formik={formik}
            errorMsg={errorMsg}
            autocompleteParams={params}
            disabledFields={disabledFields}
          />
        )}
      />
    </>
  );
};

export default UserSelectField;
