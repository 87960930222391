import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      paddingLeft: theme.spacing(2),
    },
    subTitle: {
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    sectionLine: {},
  })
);
