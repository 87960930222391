import { defineMessages } from "react-intl";

export const messages = defineMessages({
  buttonLabel: {
    id: "errorpageview.button.label",
    defaultMessage: "Back to dashboard",
    description: "label for button to get back to dashboard",
  },
  notFountErrorCode: {
    id: "errorpageview.error.notfound.code",
    defaultMessage: "404",
    description: "Error code when page not found",
  },
  notFountErrorTitle: {
    id: "errorpageview.error.notfound.title",
    defaultMessage: "Not found!",
    description: "Error title when page not found",
  },
  notFountErrorDescription: {
    id: "errorpageview.error.notfound.description",
    defaultMessage: "The page you are trying to reach cannot be found",
    description: "Error description when page not found",
  },
});
