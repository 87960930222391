import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleSuccess: {
    id: "sync.success.title.label",
    defaultMessage: "Success",
    description: "Title for successfully synchronised bookings",
  },
  descriptionSuccessCreated: {
    id: "sync.success.description.created.label",
    defaultMessage: "New bookings: {numberOfCreatedBookings}",
    description: "Number of bookings synchronized",
  },
  descriptionSuccessCanceled: {
    id: "sync.success.description.canceled.label",
    defaultMessage: "Canceled bookings: {numberOfCanceledBookings}",
    description: "Number of bookings synchronized",
  },
  titleInfo: {
    id: "sync.info.title.label",
    defaultMessage: "No bookings to sync",
    description: "Title when no bookings to sync",
  },
  descriptionInfo: {
    id: "sync.info.description.label",
    defaultMessage:
      "This could be due to two things. Either all bookings for this patient are already synced to CarePlatform, or there are no future bookings for patients in TakeCare.",
    description: "Description text when no bookings ready to sync",
  },
  titleError: {
    id: "sync.error.title.label",
    defaultMessage: "Error",
    description: "Title when failed to synchronised bookings",
  },
  descriptionError: {
    id: "sync.error.description.label",
    defaultMessage: "Failed to synchronize bookings",
    description: "error description text",
  },
  userNotNotifiable: {
    id: "sync.warning.notNotifiable.description.label",
    defaultMessage:
      "Client does not have any contact information, no notification will be sent.{br}To add contact information and send an invitation to the patient, click here:",
    description: "Notification warning description text",
  },
});
