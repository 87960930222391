import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { RoutePath } from "../RoutePath";
import { useCurrentPath } from "../../../Providers/CurrentPathProvider/CurrentPathProvider";
import { CustomRouteProps, RouteType } from "../Routes.config";
import { useCustomerIsAdminPortal } from "../../../Providers/CustomerProvider/CustomerProvider";

const PrivateRoute = (route: CustomRouteProps) => {
  const history = useHistory();
  const location = useLocation();
  const [authenticatedUser] = useAuthenticatedUser();
  const setPath = useCurrentPath()[1];
  const customerIsAdminPortal = useCustomerIsAdminPortal();

  useEffect(() => {
    setPath(location.pathname);
    if (authenticatedUser.isLoggedIn && !authenticatedUser.isComplete) {
      history.push(RoutePath.ACCOUNT_COMPLETION);
    }
  }, [authenticatedUser, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (authenticatedUser.isLoggedIn) {
    if (route.routeType === RouteType.PRIVATE) {
      return <Route {...route} />;
    }
    if (route.routeType === RouteType.PRIVATE_ADMIN && !customerIsAdminPortal) {
      return <Redirect to={RoutePath.DASHBOARD} />;
    }
    if (
      route.routeType === RouteType.PRIVATE_CUSTOMER &&
      customerIsAdminPortal
    ) {
      return <Redirect to={RoutePath.PORTAL_CUSTOMERS} />;
    }
    return <Route {...route} />;
  }

  if (authenticatedUser.isLoggedIn === false) {
    return <Redirect to={RoutePath.LOGIN} />;
  }

  // Needs to be here in case of page refresh on a private route to be able to stay on it instead
  // of redirect to login --> dashboard
  return null;
};

export default PrivateRoute;
