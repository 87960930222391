/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The status of a meeting
 * @export
 * @enum {string}
 */
export enum MeetingStatusType {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED'
}

export function MeetingStatusTypeFromJSON(json: any): MeetingStatusType {
    return MeetingStatusTypeFromJSONTyped(json, false);
}

export function MeetingStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingStatusType {
    return json as MeetingStatusType;
}

export function MeetingStatusTypeToJSON(value?: MeetingStatusType | null): any {
    return value as any;
}

