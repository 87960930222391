import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipMinWidth: {
      color: theme.palette.primary.contrastText,
      minWidth: 270,
    },
    dateTimeFormat: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    titleSize: {
      fontSize: 20,
    },
    IN_PROGRESS: {
      width: 120,
      color: theme.palette.info.main,
      backgroundColor: theme.palette.grey.A700,
    },
    COMPLETED: {
      width: 100,
      color: theme.palette.grey.A700,
      backgroundColor: theme.palette.grey.A100,
    },
    CANCELLED: {
      width: 100,
      color: theme.palette.error.main,
      backgroundColor: theme.palette.grey.A700,
    },
    CREATED: {
      width: 100,
      color: theme.palette.success.main,
      backgroundColor: theme.palette.grey.A700,
    },
  })
);
