import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import AlertDialog from "../../../../Dialogs/AlertDialog/AlertDialog";
import { messages } from "./DeleteAction.messages";
import { contactService } from "../../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  contactId: string;
  onDeleted?: () => void;
  onClosed?: () => void;
}

const DeleteAction = (props: Props) => {
  const { contactId, onDeleted, onClosed } = props;
  const customerId = useCustomerId();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleteDialogOpen, setIsDeleteDialogIsOpen] = useState<boolean>(
    false
  );

  const handleClose = () => {
    setIsDeleteDialogIsOpen(false);
    if (onClosed) {
      onClosed();
    }
  };

  const handleDelete = (): void => {
    if (customerId) {
      contactService()
        .deleteContactById({ customerId, contactId })
        .then(() => {
          handleClose();
          if (onDeleted) {
            onDeleted();
          }
        })
        .then(() => enqueueSnackbar(intl.formatMessage(messages.success)))
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.generalError))
        );
    }
  };

  return (
    <>
      <AlertDialog
        open={isDeleteDialogOpen}
        title={intl.formatMessage(messages.alertTitle)}
        content={intl.formatMessage(messages.alertContent)}
        onSubmit={handleDelete}
        onClose={handleClose}
        submitLabel={intl.formatMessage(messages.alertSubmitLabel)}
        cancelLabel={intl.formatMessage(messages.alertCancelLabel)}
      />
      <MenuItem onClick={() => setIsDeleteDialogIsOpen(true)}>
        {intl.formatMessage(messages.actionLabel)}
      </MenuItem>
    </>
  );
};

export default DeleteAction;
