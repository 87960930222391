import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customDomainLabel: {
    id: "customerList.customDomain.label",
    defaultMessage: "Custom domain",
    description: "label for custom domain in customer list",
  },
  orgNumberLabel: {
    id: "customerList.orgNumber.label",
    defaultMessage: "Org. number",
    description: "Label for organization number in customer list",
  },
  marketLabel: {
    id: "customerList.market.label",
    defaultMessage: "Market",
    description: "Label for market in customer list",
  },
  createdLabel: {
    id: "customerList.created.label",
    defaultMessage: "Created",
    description: "Label for created in customer list",
  },
  customDomainFalseLabel: {
    id: "customerList.customDomain.false.label",
    defaultMessage: "No",
    description: "Label for custom domain false in customer list",
  },
  customDomainTrueLabel: {
    id: "customerList.customDomain.true.label",
    defaultMessage: "Yes",
    description: "Label for custom domain true in customer list",
  },
});
