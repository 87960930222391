/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceUserAuthenticationResponse
 */
export interface ServiceUserAuthenticationResponse {
    /**
     * Name of the service user
     * @type {string}
     * @memberof ServiceUserAuthenticationResponse
     */
    name: string;
    /**
     * The JWT to be used in M2M calls.
     * @type {string}
     * @memberof ServiceUserAuthenticationResponse
     */
    token: string;
}

export function ServiceUserAuthenticationResponseFromJSON(json: any): ServiceUserAuthenticationResponse {
    return ServiceUserAuthenticationResponseFromJSONTyped(json, false);
}

export function ServiceUserAuthenticationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceUserAuthenticationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'token': json['token'],
    };
}

export function ServiceUserAuthenticationResponseToJSON(value?: ServiceUserAuthenticationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'token': value.token,
    };
}


