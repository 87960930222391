import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useIntl } from "react-intl";
import {
  UserAuthorityType,
  UserResponse,
  UserStatusType,
} from "../../generated/user-api";
import { getUserStatusLabel } from "../StatusChip/StatusChip";
import { hasPermission } from "../PermissionController/PermissionController";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  user: UserResponse;
  onChange: (status: UserStatusType) => void;
}

const UserStatus = (props: Props) => {
  const intl = useIntl();
  const [authenticatedUser] = useAuthenticatedUser();
  const { user, onChange } = props;
  // @ts-ignore
  const [status, setStatus] = useState<UserStatusType>(user.userStatus);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    // @ts-ignore
    const newStatus: UserStatusType = event.target.value;
    setStatus(newStatus);
    onChange(newStatus);
  };

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel id="user-status-label">Status</InputLabel>
        <Select
          labelId="user-status-label"
          id="user-status-select"
          value={status}
          // @ts-ignore
          onChange={handleChange}
          label="Status"
          style={{ width: 200 }}
        >
          <MenuItem value={UserStatusType.Active}>
            {getUserStatusLabel(UserStatusType.Active, intl)}
          </MenuItem>
          <MenuItem value={UserStatusType.Inactive}>
            {getUserStatusLabel(UserStatusType.Inactive, intl)}
          </MenuItem>
          {hasPermission({
            currentUser: authenticatedUser.user,
            requiredAuthoritiesStaff: [UserAuthorityType.AdminSite],
          }) && (
            <MenuItem value={UserStatusType.Closed}>
              {getUserStatusLabel(UserStatusType.Closed, intl)}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};

export default UserStatus;
