import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customerNotificationSettingsTab: {
    id: "settings.notificationTab",
    defaultMessage: "Notifications Settings",
    description: "label for customer notification settings",
  },
  notificationPatientsAndExternalCategory: {
    id: "settings.notificationTab.patientsCategory",
    defaultMessage: "Patients and external",
    description: "label for patients and external notification settings",
  },
  notificationCaregiverCategory: {
    id: "settings.notificationTab.caregiverCategory",
    defaultMessage: "Caregiver",
    description: "label for caregiver notification settings",
  },
  notificationExternalCategory: {
    id: "settings.notificationTab.ExternalCategory",
    defaultMessage: "External services",
    description: "label for external services notification settings",
  },
  getReadData: {
    id: "save.settings.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occured for saving features",
  },
  customerEmailHeader: {
    id: "settings.emailHeaderSwitchTab",
    defaultMessage: "Email",
    description: "label for header email switch column",
  },
  customerSmsHeader: {
    id: "settings.SmsHeaderSwitchTab",
    defaultMessage: "SMS",
    description: "label for header SMS switch column",
  },
});
