/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of a ToDo
 * @export
 * @enum {string}
 */
export enum ToDoStatusType {
    NotCompleted = 'NOT_COMPLETED',
    Completed = 'COMPLETED'
}

export function ToDoStatusTypeFromJSON(json: any): ToDoStatusType {
    return ToDoStatusTypeFromJSONTyped(json, false);
}

export function ToDoStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToDoStatusType {
    return json as ToDoStatusType;
}

export function ToDoStatusTypeToJSON(value?: ToDoStatusType | null): any {
    return value as any;
}

