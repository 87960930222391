/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingStatusType,
    MeetingStatusTypeFromJSON,
    MeetingStatusTypeFromJSONTyped,
    MeetingStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateMeetingStatusRequest
 */
export interface UpdateMeetingStatusRequest {
    /**
     * 
     * @type {MeetingStatusType}
     * @memberof UpdateMeetingStatusRequest
     */
    newStatus: MeetingStatusType;
}

export function UpdateMeetingStatusRequestFromJSON(json: any): UpdateMeetingStatusRequest {
    return UpdateMeetingStatusRequestFromJSONTyped(json, false);
}

export function UpdateMeetingStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeetingStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newStatus': MeetingStatusTypeFromJSON(json['newStatus']),
    };
}

export function UpdateMeetingStatusRequestToJSON(value?: UpdateMeetingStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newStatus': MeetingStatusTypeToJSON(value.newStatus),
    };
}


