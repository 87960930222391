import React from "react";
import {
  Container,
  Typography,
  IconButton,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import BuildIcon from "@material-ui/icons/Build";
import { useIntl } from "react-intl";
import { useStyles } from "./TroubleshootSlider.style";
import { messages } from "./TroubleshootSlider.messages";
import DialogCloseButton from "../../../../../../Components/Buttons/DialogCloseButton/DialogCloseButton";

interface Props {
  onClose: () => void;
}

const DisplayListItem = ({ children }: { children: React.ReactNode }) => (
  <ListItem dense>• {children}</ListItem>
);

const TroubleshootSlider = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { onClose } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  if (isSmallScreen) {
    return (
      <Dialog open fullScreen className={classes.dialogRoot} scroll="paper">
        {onClose && <DialogCloseButton onClose={onClose} />}
        <DialogTitle>
          <Typography
            className={classes.cardHeading}
            component="h2"
            variant="subtitle2"
          >
            {intl.formatMessage(messages.title).toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.contentContainer}>
            {/* Refresh information */}
            <Typography component="h3" variant="body1">
              <BuildIcon className={classes.icon} fontSize="inherit" />
              {intl.formatMessage(messages.refreshTitle)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {intl.formatMessage(messages.refreshDescription)}
              <IconButton
                size="small"
                color="default"
                onClick={() => window.location.reload()}
              >
                <RefreshIcon />
              </IconButton>
            </Typography>
            {/* Still problem - bullet points */}
            <Typography
              component="h3"
              variant="body1"
              className={classes.sectionHeader}
            >
              <BuildIcon className={classes.icon} fontSize="inherit" />
              {intl.formatMessage(messages.stillProblemTitle)}
            </Typography>
            <DisplayListItem>
              {intl.formatMessage(messages.leaveMeeting)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.connection)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.headphones)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.access)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.speakersTurnedOn)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.correctHardware)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.closePrograms)}
            </DisplayListItem>
            {/* Requirements - bullet points */}
            <Typography
              component="h3"
              variant="body1"
              className={classes.sectionHeader}
            >
              <BuildIcon className={classes.icon} fontSize="inherit" />
              {intl.formatMessage(messages.requirementsTitle)}
            </Typography>
            <DisplayListItem>
              {intl.formatMessage(messages.requirementsComputer)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.requirementsApple)}
            </DisplayListItem>
            <DisplayListItem>
              {intl.formatMessage(messages.requirementsAndroid)}
            </DisplayListItem>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Container className={classes.root}>
      <Typography
        className={classes.cardHeading}
        component="h2"
        variant="subtitle2"
      >
        {intl.formatMessage(messages.title).toUpperCase()}
      </Typography>
      <Divider />
      <div className={classes.contentContainer}>
        {/* Refresh information */}
        <Typography component="h3" variant="body1">
          <BuildIcon className={classes.icon} fontSize="inherit" />
          {intl.formatMessage(messages.refreshTitle)}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {intl.formatMessage(messages.refreshDescription)}
          <IconButton
            size="small"
            color="default"
            onClick={() => window.location.reload()}
          >
            <RefreshIcon />
          </IconButton>
        </Typography>
        {/* Still problem - bullet points */}
        <Typography
          component="h3"
          variant="body1"
          className={classes.sectionHeader}
        >
          <BuildIcon className={classes.icon} fontSize="inherit" />
          {intl.formatMessage(messages.stillProblemTitle)}
        </Typography>
        <DisplayListItem>
          {intl.formatMessage(messages.leaveMeeting)}
        </DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.connection)}
        </DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.headphones)}
        </DisplayListItem>
        <DisplayListItem>{intl.formatMessage(messages.access)}</DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.speakersTurnedOn)}
        </DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.correctHardware)}
        </DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.closePrograms)}
        </DisplayListItem>
        {/* Requirements - bullet points */}
        <Typography
          component="h3"
          variant="body1"
          className={classes.sectionHeader}
        >
          <BuildIcon className={classes.icon} fontSize="inherit" />
          {intl.formatMessage(messages.requirementsTitle)}
        </Typography>
        <DisplayListItem>
          {intl.formatMessage(messages.requirementsComputer)}
        </DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.requirementsApple)}
        </DisplayListItem>
        <DisplayListItem>
          {intl.formatMessage(messages.requirementsAndroid)}
        </DisplayListItem>
      </div>
    </Container>
  );
};

export default TroubleshootSlider;
