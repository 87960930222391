import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeading: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
    savedOn: {
      marginRight: theme.spacing(1),
    },
    buttonEnd: {
      paddingTop: theme.spacing(1),
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    chipNotSaved: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
    icon: {
      margin: theme.spacing(0, 0.5),
    },
    basicLook: {
      padding: theme.spacing(0),
    },
    req: {
      height: 80,
      overflowY: "scroll",
    },
    reqFullHeight: {
      height: "100%",
      overflowY: "scroll",
    },
  })
);
