import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./IntputText.styles";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { messages } from "./InputText.messages";

export interface Props {
  label: string;
  featureType: CustomerFeatureType;
  initialValue?: string;
  onChanged: (
    featureType: CustomerFeatureType,
    enabled: boolean,
    value: number
  ) => void;
}

const InputText = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { label, featureType, initialValue, onChanged } = props;
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    const enabled = e.target.value > 0;
    onChanged(featureType, enabled, e.target.value);
  };

  return (
    <Grid container alignItems="center">
      <Grid item sm={6} xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          value={value}
          type="number"
          onChange={handleChange}
          variant="outlined"
          label={intl.formatMessage(messages.numberOfDays)}
          autoComplete="off"
          className={classes.actionContainer}
        />
      </Grid>
    </Grid>
  );
};

export default InputText;
