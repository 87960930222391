import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useIntl } from "react-intl";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import PermissionController from "../../PermissionController/PermissionController";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { messages } from "./InviteButton.messages";
import InviteUserDialog from "../../Dialogs/InviteUserDialog/InviteUserDialog";
import { User } from "../../../Models/User";
import { contactService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  color?: "inherit" | "primary" | "secondary" | "default";
  className?: string;
  variant?: "text" | "outlined" | "contained";
}

const InviteButton = (props: Props) => {
  const intl = useIntl();
  const { color, className, variant } = props;
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOnInvited = (user: User) => {
    if (customerId && authenticatedUser.user?.id) {
      contactService()
        .addOrUpdateContact({
          customerId,
          addContactRequest: {
            contactId: user.id,
            userId: authenticatedUser.user?.id,
          },
        })
        .then(() => setIsDialogOpen(false));
    }
  };

  return (
    <FeatureController
      requiredAnyOfFeatures={[
        CustomerFeatureType.InviteStaff,
        CustomerFeatureType.InviteClient,
        CustomerFeatureType.InviteExternal,
      ]}
    >
      <PermissionController
        allowedRoles={[UserRoleType.Staff]}
        atLeastOneOfAuthoritiesStaff={[
          UserAuthorityType.CreateUserClient,
          UserAuthorityType.CreateUserStaff,
        ]}
      >
        <InviteUserDialog
          isOpen={isDialogOpen}
          onInvited={handleOnInvited}
          onClose={() => setIsDialogOpen(false)}
        />
        <Button
          size="small"
          color={color || "primary"}
          className={className}
          variant={variant || "contained"}
          startIcon={<PersonAddIcon />}
          onClick={() => setIsDialogOpen(true)}
        >
          {intl.formatMessage(messages.buttonLabel)}
        </Button>
      </PermissionController>
    </FeatureController>
  );
};

export default InviteButton;
