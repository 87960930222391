import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import { useIntl } from "react-intl";
import { enUS, sv } from "date-fns/locale";
import formatDistance from "date-fns/formatDistance";

import {
  AttendanceStatusType,
  MeetingParticipantRequest,
  MeetingParticipantResponse,
  MeetingParticipantType,
  MeetingResponse,
  MeetingRoleType,
} from "../generated/meeting-api";
import { UserResponse, UserRoleType } from "../generated/user-api";
import { userResponseToMeetingParticipantRequest } from "./ModelConverter";
import { MeetingParticipant } from "../Models/Meeting";

export const getParticipantByUserId = (
  userId?: string,
  participants?: MeetingParticipantResponse[]
): MeetingParticipantResponse | undefined => {
  return participants?.find((p) => p.userId === userId);
};

export const getStopDateTime = (
  startDateTime: Date,
  duration: number
): Date => {
  return addMinutes(startDateTime, duration);
};

export const getFormattedTimespan = (
  startDateTime: Date,
  duration?: number
): string => {
  const formattedStartTime = format(startDateTime, "HH:mm").toString();
  if (duration) {
    const formattedStopTime = format(
      getStopDateTime(startDateTime, duration),
      "HH:mm"
    ).toString();

    return `${formattedStartTime} - ${formattedStopTime}`;
  }
  return formattedStartTime;
};

export const getFormattedDate = (dateTime: Date): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  const currentLocal = intl.locale === "sv" ? sv : enUS;
  return format(dateTime, "dd LLLL yyyy", {
    locale: currentLocal,
  }).toString();
};

export const getFormattedDateTimeSpan = (
  startDateTime: Date,
  duration: number
): string => {
  return `${getFormattedDate(startDateTime)} ${getFormattedTimespan(
    startDateTime,
    duration
  )}`;
};

export const getFormattedDateTime = (dateTime: Date): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  const currentLocal = intl.locale === "sv" ? sv : enUS;
  return format(dateTime, "dd LLLL yyyy HH:mm", {
    locale: currentLocal,
  }).toString();
};

export const getCurrentTimeZone = (dateTime: Date): Date => {
  const offset = new Date().getTimezoneOffset();
  const defaultMeetingDate = new Date(dateTime);
  defaultMeetingDate.setMinutes(new Date(dateTime).getMinutes() - offset);
  return defaultMeetingDate;
};

export const getParticipantsAfterRemoval = (
  participants: MeetingParticipantResponse[],
  participantsIdsToRemove: string[]
): MeetingParticipantResponse[] => {
  return participants.filter(
    (p) => !participantsIdsToRemove.includes(p.userId || "")
  );
};

export const getMeetingLeader = (
  participants?: MeetingParticipantResponse[]
): MeetingParticipantResponse | undefined => {
  return participants?.find(
    (p) =>
      p.meetingRole === MeetingRoleType.MeetingLeader &&
      p.participantType === MeetingParticipantType.Staff
  );
};

export const getClient = (
  participants?: MeetingParticipantResponse[]
): MeetingParticipantResponse | undefined => {
  return participants?.find(
    (p) =>
      p.meetingRole === MeetingRoleType.Attendee &&
      p.participantType === MeetingParticipantType.Client
  );
};

export const getParticipantsFromUserList = (
  participantsIdList: string[],
  userList: UserResponse[]
): UserResponse[] => {
  return userList.filter((user) => participantsIdList.includes(user.id));
};

export const getParticipantsId = (
  participants?: MeetingParticipant[]
): string[] => {
  if (!participants) {
    return [];
  }
  return participants.map((p) => p.userId);
};

export const getParticipantsIdByType = (
  participantType: MeetingParticipantType,
  participants?: MeetingParticipant[]
): string[] => {
  if (!participants) {
    return [];
  }
  return participants
    .filter((p) => p.participantType === participantType)
    .map((p) => p.userId);
};

export const getMeetingIds = (meetings?: MeetingResponse[]): string[] => {
  if (!meetings) {
    return [];
  }
  return meetings.map((meeting) => meeting.id);
};

export const getStaffParticipants = (
  participants?: MeetingParticipantResponse[]
): MeetingParticipantResponse[] | undefined => {
  if (!participants) {
    return [];
  }
  return participants?.filter(
    (p) => p.participantType === MeetingParticipantType.Staff
  );
};

export const getClientParticipants = (
  participants?: MeetingParticipant[]
): MeetingParticipant[] | undefined => {
  return participants?.filter(
    (p) =>
      p.meetingRole === MeetingRoleType.Attendee &&
      p.participantType === MeetingParticipantType.Client
  );
};

export const getParticipantsByType = (
  meetingParticipantType: MeetingParticipantType,
  participants?: MeetingParticipant[]
): MeetingParticipant[] | undefined => {
  return participants?.filter(
    (p) => p.participantType === meetingParticipantType
  );
};

export const isMeetingLeader = (
  meeting?: MeetingResponse,
  authenticatedUser?: UserResponse
): boolean => {
  if (!authenticatedUser) {
    return false;
  }
  const participant = getParticipantByUserId(
    authenticatedUser?.id,
    meeting?.participants
  );
  return participant?.meetingRole === MeetingRoleType.MeetingLeader;
};

export const getNumberOfConfirmedParticipants = (
  participants?: MeetingParticipantResponse[]
): number => {
  let numberOfConfirmedParticipants = 0;
  if (participants) {
    participants.forEach((p) => {
      if (p.participantAttendance?.status === AttendanceStatusType.Confirmed) {
        numberOfConfirmedParticipants += 1;
      }
    });
  }
  return numberOfConfirmedParticipants;
};

export const getAttendanceStatusByUserId = (
  userId?: string,
  participants?: MeetingParticipantResponse[]
): AttendanceStatusType | undefined => {
  return getParticipantByUserId(userId, participants)?.participantAttendance
    .status;
};

export const calculateTimeSinceDate = (DateTime: Date, locale: string) => {
  return formatDistance(DateTime, new Date(), {
    addSuffix: true,
    locale: locale === "sv" ? sv : enUS,
  });
};

export const isParticipant = (
  userId?: string,
  participants?: MeetingParticipantResponse[]
): boolean => {
  return getParticipantByUserId(userId, participants) !== undefined;
};

export const createSortedList = (
  participants: MeetingParticipantResponse[],
  authenticatedUser?: UserResponse
): MeetingParticipantResponse[] => {
  const staffs = participants?.filter(
    (p) => p.participantType === MeetingParticipantType.Staff
  );
  const clients = participants?.filter(
    (p) => p.participantType === MeetingParticipantType.Client
  );
  const externals = participants?.filter(
    (p) => p.participantType === MeetingParticipantType.External
  );

  const result = [] as MeetingParticipantResponse[];
  switch (authenticatedUser?.userRole) {
    case UserRoleType.Client:
      return result.concat([
        ...(staffs || []),
        ...(externals || []),
        ...(clients || []),
      ]);
    case UserRoleType.Staff:
      return result.concat([
        ...(clients || []),
        ...(externals || []),
        ...(staffs || []),
      ]);
    default:
      return result.concat([
        ...(staffs || []),
        ...(clients || []),
        ...(externals || []),
      ]);
  }
};

export const createParticipantList = (
  userResponses: UserResponse[]
): MeetingParticipantRequest[] => {
  return userResponses.map((user) =>
    userResponseToMeetingParticipantRequest(user)
  );
};

export const getMeetingCounterParts = (
  meeting?: MeetingResponse,
  currentUser?: UserResponse
): MeetingParticipantResponse[] | undefined => {
  if (!meeting || !currentUser || !meeting.participants) {
    return undefined;
  }
  return meeting.participants.filter((p) => p.userId !== currentUser.id);
};
