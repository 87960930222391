/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform ToDo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateToDoRequest
 */
export interface CreateToDoRequest {
    /**
     * Short text describing what the assignee is to do.
     * @type {string}
     * @memberof CreateToDoRequest
     */
    title: string;
    /**
     * Longer text describing what the assignee is to do.
     * @type {string}
     * @memberof CreateToDoRequest
     */
    description?: string;
    /**
     * List of UUID of users that this ToDo will be assigned to
     * @type {Array<string>}
     * @memberof CreateToDoRequest
     */
    assignedTo: Array<string>;
    /**
     * The UUID of the user that created or assigned this ToDo.
     * @type {string}
     * @memberof CreateToDoRequest
     */
    assignedBy: string;
    /**
     * Optional UUID of the meeting that this ToDo was created in.
     * @type {string}
     * @memberof CreateToDoRequest
     */
    createdInMeeting?: string;
    /**
     * Optional date this ToDo should be completed by the assignee.
     * @type {Date}
     * @memberof CreateToDoRequest
     */
    shouldBeCompletedOn?: Date;
}

export function CreateToDoRequestFromJSON(json: any): CreateToDoRequest {
    return CreateToDoRequestFromJSONTyped(json, false);
}

export function CreateToDoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateToDoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'assignedTo': json['assignedTo'],
        'assignedBy': json['assignedBy'],
        'createdInMeeting': !exists(json, 'createdInMeeting') ? undefined : json['createdInMeeting'],
        'shouldBeCompletedOn': !exists(json, 'shouldBeCompletedOn') ? undefined : (new Date(json['shouldBeCompletedOn'])),
    };
}

export function CreateToDoRequestToJSON(value?: CreateToDoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'assignedTo': value.assignedTo,
        'assignedBy': value.assignedBy,
        'createdInMeeting': value.createdInMeeting,
        'shouldBeCompletedOn': value.shouldBeCompletedOn === undefined ? undefined : (value.shouldBeCompletedOn.toISOString().substr(0,10)),
    };
}


