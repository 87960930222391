/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerRequest,
    CustomerRequestFromJSON,
    CustomerRequestToJSON,
    CustomerResponse,
    CustomerResponseFromJSON,
    CustomerResponseToJSON,
    CustomerStatusRequest,
    CustomerStatusRequestFromJSON,
    CustomerStatusRequestToJSON,
    PaginatedCustomerList,
    PaginatedCustomerListFromJSON,
    PaginatedCustomerListToJSON,
} from '../models';

export interface CreateCustomerRequest {
    body: CustomerRequest;
}

export interface GetCustomerByIdRequest {
    id: string;
}

export interface GetCustomersListRequest {
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface UpdateCustomerRequest {
    id: string;
    body: CustomerRequest;
}

export interface UpdateCustomerStatusRequest {
    id: string;
    body: CustomerStatusRequest;
}

/**
 * 
 */
export class CustomerApi extends runtime.BaseAPI {

    /**
     * This can only be done by an admin user.
     * Create a new customer
     */
    async createCustomerRaw(requestParameters: CreateCustomerRequest): Promise<runtime.ApiResponse<CustomerResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerResponseFromJSON(jsonValue));
    }

    /**
     * This can only be done by an admin user.
     * Create a new customer
     */
    async createCustomer(requestParameters: CreateCustomerRequest): Promise<CustomerResponse> {
        const response = await this.createCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get customer by id
     */
    async getCustomerByIdRaw(requestParameters: GetCustomerByIdRequest): Promise<runtime.ApiResponse<CustomerResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomerById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerResponseFromJSON(jsonValue));
    }

    /**
     * Get customer by id
     */
    async getCustomerById(requestParameters: GetCustomerByIdRequest): Promise<CustomerResponse> {
        const response = await this.getCustomerByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all customers
     * Get all customers
     */
    async getCustomersListRaw(requestParameters: GetCustomersListRequest): Promise<runtime.ApiResponse<PaginatedCustomerList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customer/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCustomerListFromJSON(jsonValue));
    }

    /**
     * Get all customers
     * Get all customers
     */
    async getCustomersList(requestParameters: GetCustomersListRequest): Promise<PaginatedCustomerList> {
        const response = await this.getCustomersListRaw(requestParameters);
        return await response.value();
    }

    /**
     * This can only be done by the logged in user or by an administrator.
     * Update a customer
     */
    async updateCustomerRaw(requestParameters: UpdateCustomerRequest): Promise<runtime.ApiResponse<CustomerResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomer.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerResponseFromJSON(jsonValue));
    }

    /**
     * This can only be done by the logged in user or by an administrator.
     * Update a customer
     */
    async updateCustomer(requestParameters: UpdateCustomerRequest): Promise<CustomerResponse> {
        const response = await this.updateCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * This can only be done by the logged in platform admin.
     * Update a customer status
     */
    async updateCustomerStatusRaw(requestParameters: UpdateCustomerStatusRequest): Promise<runtime.ApiResponse<CustomerResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCustomerStatus.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateCustomerStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customer/status/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerStatusRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerResponseFromJSON(jsonValue));
    }

    /**
     * This can only be done by the logged in platform admin.
     * Update a customer status
     */
    async updateCustomerStatus(requestParameters: UpdateCustomerStatusRequest): Promise<CustomerResponse> {
        const response = await this.updateCustomerStatusRaw(requestParameters);
        return await response.value();
    }

}
