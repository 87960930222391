import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 600,
    },
    list: {
      marginBottom: theme.spacing(5),
    },
    hover: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "& $icon": {
          color: theme.palette.secondary.contrastText,
        },
      },
    },
    icon: {},
    profileContainer: {
      paddingTop: 20,
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
    },
    avatarText: {
      marginTop: theme.spacing(1),
    },
  })
);
