import { defineMessages } from "react-intl";

export const messages = defineMessages({
  disableFilter: {
    id: "video.toolbar.backgroundFilter.disable.tooltip",
    defaultMessage: "Disable filter",
    description: "Tooltip label for button filter",
  },
  enableFilter: {
    id: "video.toolbar.backgroundFilter.enable.tooltip",
    defaultMessage: "Enable filter",
    description: "Tooltip label for button filter",
  },
});
