import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { minHeight: 80, marginTop: theme.spacing(1) },
    nothingToShowText: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  })
);
