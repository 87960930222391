import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { useCustomerIsAdminPortal } from "../../../Providers/CustomerProvider/CustomerProvider";
import { RoutePath } from "../../../Components/Routes/RoutePath";
import ThemeSettings from "../../../Components/ThemeSettings/ThemeSettings";
import Tabs, { TabConfig } from "../../../Components/Tabs/Tabs";
import { messages } from "./PortalCustomerSettings.messages";
import { customerService } from "../../../Providers/ServiceProvider/ServiceProvider";
import {
  CustomerResponse,
  GetCustomerByIdRequest,
} from "../../../generated/customer-api";
import { useStyles } from "./PortalCustomerSettings.style";
import PortalCustomerUserSettings from "./Components/PortalCustomerUserSettings/PortalCustomerUserSettings";
import PortalCustomerProfile from "./Components/PortalCustomerProfile/PortalCustomerProfile";

import CustomerFeatures from "../../../Components/CustomerFeatures/CustomerFeatures";
import NotificationFeatures from "../../../Components/NotificationFeatures/NotificationFeatures";
import AnonymizeFeatures from "../../../Components/AnonymizeFeatures/AnonymizeFeatures";
import ContentSettings from "../../../Components/ContentSettings/ContentSettings";
import ConsentAgreementSettings from "../../../Components/ConsentAgreementSettings/ConsentAgreementSettings";
import BackButton from "../../../Components/Buttons/BackButton/BackButton";

const PortalCustomerSettings = () => {
  const location = useLocation<{ customerId?: string }>();
  const intl = useIntl();
  const classes = useStyles();
  const isCustomerAdminPortal = useCustomerIsAdminPortal();
  const [customerResponse, setCustomerResponse] = useState<CustomerResponse>();
  const customerId = location.state?.customerId;

  const loadCustomer = () => {
    customerService()
      .getCustomerById({ id: customerId } as GetCustomerByIdRequest)
      .then((res) => {
        setCustomerResponse(res);
      });
  };

  useEffect(() => {
    loadCustomer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isCustomerAdminPortal) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  if (!customerId || !customerResponse) {
    return null;
  }

  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.adminPortalProfileSettingsTab),
      component: (
        <PortalCustomerProfile
          key={customerId}
          customerResponse={customerResponse}
          loadCustomer={loadCustomer}
        />
      ),
    },
    {
      title: intl.formatMessage(messages.adminPortalUserSettingsTab),
      component: (
        <PortalCustomerUserSettings key={customerId} customerId={customerId} />
      ),
    },
    {
      title: intl.formatMessage(messages.adminPortalThemeSettingsTab),
      component: <ThemeSettings key={customerId} customerId={customerId} />,
    },
    {
      title: intl.formatMessage(messages.adminPortalCustomerFeaturesTab),
      component: <CustomerFeatures key={customerId} customerId={customerId} />,
    },
    {
      title: intl.formatMessage(messages.adminPortalNotificationFeaturesTab),
      component: (
        <NotificationFeatures key={customerId} customerId={customerId} />
      ),
    },
    {
      title: intl.formatMessage(messages.adminPortalAnonymizeFeaturesTab),
      component: <AnonymizeFeatures key={customerId} customerId={customerId} />,
    },
    {
      title: intl.formatMessage(messages.adminPortalContentSettingsTab),
      component: <ContentSettings key={customerId} customerId={customerId} />,
    },
    {
      title: intl.formatMessage(
        messages.adminPortalConsentAgreementSettingsTab
      ),
      component: (
        <ConsentAgreementSettings key={customerId} customerId={customerId} />
      ),
    },
  ];
  return (
    <>
      <BackButton />
      <div className={classes.customerNameContainer}>
        <SettingsIcon />
        <Typography variant="h6" className={classes.customerName}>
          {customerResponse?.customerName}
        </Typography>
      </div>
      <Tabs defaultTab={0} showTabs tabConfigs={tabConfig} scrollable />
    </>
  );
};

export default PortalCustomerSettings;
