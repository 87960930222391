import { defineMessages } from "react-intl";

export const messages = defineMessages({
  endCall: {
    id: "video.toolbar.leaveCall.label",
    defaultMessage: "Leave call",
    description: "Tooltip label for leave call",
  },
  dialogTitle: {
    id: "dialog.leaveMeeting.title",
    defaultMessage: "Do you want to leave this meeting?",
    description: "Dialog title",
  },
  dialogSubmitButton: {
    id: "dialog.leaveMeeting.button.submit",
    defaultMessage: "Leave meeting",
    description: "Dialog submit button",
  },
  dialogCancelButton: {
    id: "dialog.leaveMeeting.button.cancel",
    defaultMessage: "Cancel",
    description: "Dialog cancel button",
  },
});
