import React from "react";
import MuiTooltip, { TooltipProps } from "@material-ui/core/Tooltip";

interface Props extends TooltipProps {
  hideTooltip?: boolean;
}

const Tooltip = (props: Props) => {
  const { hideTooltip, children } = props;
  return hideTooltip ? <>{children}</> : <MuiTooltip {...props} />;
};

export default Tooltip;
