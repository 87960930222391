import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicatorAtTop: {
    top: 0,
  },
  indicatorAtBottom: {
    bottom: 0,
  },
}));
