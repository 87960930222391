import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(45),
    minWidth: theme.spacing(45),
    borderRadius: 0,
    borderLeftWidth: theme.spacing(0.2),
    borderLeftColor: theme.palette.primary.dark,
    borderLeftStyle: "solid",
  },
}));
