/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request from an user to start a meeting. User needs to have meeting role MEETING_LEADER in order to start the meeting.
 * @export
 * @interface MeetingSessionStartRequest
 */
export interface MeetingSessionStartRequest {
    /**
     * UUID of user that wants to start the meeting.
     * @type {string}
     * @memberof MeetingSessionStartRequest
     */
    userId: string;
}

export function MeetingSessionStartRequestFromJSON(json: any): MeetingSessionStartRequest {
    return MeetingSessionStartRequestFromJSONTyped(json, false);
}

export function MeetingSessionStartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingSessionStartRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
    };
}

export function MeetingSessionStartRequestToJSON(value?: MeetingSessionStartRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
    };
}


