import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Sync } from "@material-ui/icons";
import { messages } from "./TakeCareSyncButton.messages";
import PermissionController from "../../PermissionController/PermissionController";
import TakeCareSyncDialog from "../../Dialogs/TakeCareSyncDialog/TakeCareSyncDialog";
import { UserRoleType } from "../../../generated/authentication-api";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { useStyles } from "./TakeCareSyncButton.style";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  userId: string;
  className?: string;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  onSynced?: () => void;
  label?: string;
}

const TakeCareSyncButton = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { userId, label, onSynced, variant, size, className } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  if (!authenticatedUser.user?.hasHsaId) {
    return null;
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.TakeCareSync}>
      <PermissionController allowedRoles={[UserRoleType.Staff]}>
        <TakeCareSyncDialog
          userId={userId}
          isOpen={isDialogOpen}
          onSynced={onSynced}
          onClose={() => setIsDialogOpen(false)}
        />
        <Button
          className={`${classes.button} ${className}`}
          size={size || "small"}
          variant={variant || "contained"}
          startIcon={<Sync />}
          onClick={() => setIsDialogOpen(true)}
        >
          {label || intl.formatMessage(messages.label)}
        </Button>
      </PermissionController>
    </FeatureController>
  );
};

export default TakeCareSyncButton;
