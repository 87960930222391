import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
import {
  CustomerUserAgreementType,
  PaginatedAgreementList,
  UserAgreementResponse,
} from "../../generated/customercontent-api";
import { messages } from "./ConsentAgreementSettings.messages";
import { useStyles } from "./ConsentAgreementSettings.styles";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { hasPermission } from "../PermissionController/PermissionController";
import { RoutePath } from "../Routes/RoutePath";
import Tabs, { TabConfig } from "../Tabs/Tabs";
import CreateConsentAgreementButton from "../Buttons/CreateConsentAgreementButton/CreateConsentAgreementButton";
import { customerContentService } from "../../Providers/ServiceProvider/ServiceProvider";
import { useSpinner } from "../Spinner/Spinner";
import ConsentAgreementTabComponent from "./components/ConsentAgreementTabComponent/ConsentAgreementTabComponent";

export interface Props {
  customerId?: string;
}

const ConsentAgreementSettings = (props: Props) => {
  const { customerId } = props;
  const intl = useIntl();
  const setSpinner = useSpinner()[1];
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [policyPage, setPolicyPage] = useState<number>(1);
  const [agreementPage, setAgreementPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPolicies, setIsLoadingPolicies] = useState<boolean>(false);
  const [isLoadingCurrentPolicy, setIsLoadingCurrentPolicy] = useState<boolean>(
    false
  );
  const [isLoadingAgreements, setIsLoadingAgreements] = useState<boolean>(
    false
  );
  const [
    isLoadingCurrentAgreement,
    setIsLoadingCurrentAgreement,
  ] = useState<boolean>(false);
  const [
    policiesListResult,
    setPoliciesListResult,
  ] = useState<PaginatedAgreementList>();
  const [
    agreementListResult,
    setAgreementListResult,
  ] = useState<PaginatedAgreementList>();
  const [
    currentPolicyResult,
    setCurrentPolicyResult,
  ] = useState<UserAgreementResponse>();
  const [
    currentAgreementResult,
    setCurrentAgreementResult,
  ] = useState<UserAgreementResponse>();

  const loadPolicies = () => {
    if (customerId) {
      setIsLoadingPolicies(true);
      customerContentService()
        .getCustomerUserAgreementsByType({
          customerId,
          agreementType: CustomerUserAgreementType.PrivacyPolicy,
          page: policyPage,
          pageSize,
        })
        .then(setPoliciesListResult)
        .finally(() => setIsLoadingPolicies(false));
    }
  };

  const loadAgreements = () => {
    if (customerId) {
      setIsLoadingAgreements(true);
      customerContentService()
        .getCustomerUserAgreementsByType({
          customerId,
          agreementType: CustomerUserAgreementType.ConsentAgreement,
          page: agreementPage,
          pageSize,
        })
        .then(setAgreementListResult)
        .finally(() => setIsLoadingAgreements(false));
    }
  };

  const loadCurrentPolicy = () => {
    if (customerId) {
      setIsLoadingCurrentPolicy(true);
      customerContentService()
        .getCustomerUserAgreementByType({
          customerId,
          agreementType: CustomerUserAgreementType.PrivacyPolicy,
        })
        .then(setCurrentPolicyResult)
        .finally(() => setIsLoadingCurrentPolicy(false));
    }
  };

  const loadCurrentAgreement = () => {
    if (customerId) {
      setIsLoadingCurrentAgreement(true);
      customerContentService()
        .getCustomerUserAgreementByType({
          customerId,
          agreementType: CustomerUserAgreementType.ConsentAgreement,
        })
        .then(setCurrentAgreementResult)
        .finally(() => setIsLoadingCurrentAgreement(false));
    }
  };

  const loadAllAgreements = () => {
    loadPolicies();
    loadAgreements();
    loadCurrentPolicy();
    loadCurrentAgreement();
  };

  useEffect(() => {
    if (
      isLoadingAgreements ||
      isLoadingCurrentAgreement ||
      isLoadingPolicies ||
      isLoadingCurrentPolicy
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isLoadingAgreements,
    isLoadingCurrentAgreement,
    isLoadingPolicies,
    isLoadingCurrentPolicy,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadCurrentPolicy();
    loadCurrentAgreement();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadPolicies();
  }, [policyPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadAgreements();
  }, [agreementPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading });
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.consentTabLabel),
      component: (
        <ConsentAgreementTabComponent
          list={agreementListResult}
          current={currentAgreementResult}
          page={agreementPage}
          onPaginationChange={setAgreementPage}
        />
      ),
    },
    {
      title: intl.formatMessage(messages.privacyPolicyTabLabel),
      component: (
        <ConsentAgreementTabComponent
          list={policiesListResult}
          current={currentPolicyResult}
          page={policyPage}
          onPaginationChange={setPolicyPage}
        />
      ),
    },
  ];

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.CustomerSettings],
    allowedRoles: [UserRoleType.Staff, UserRoleType.PlatformAdmin],
  });

  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  return (
    <Paper>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={9}>
          <div className={classes.actionContainer}>
            <CreateConsentAgreementButton
              onCreated={loadAllAgreements}
              activeTab={activeTab}
              customerId={customerId}
            />
          </div>
          <Tabs
            tabConfigs={tabConfig}
            showTabs
            onTabChange={(e) => setActiveTab(e)}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConsentAgreementSettings;
