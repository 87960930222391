import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bookingDetailsExperienceText: {
    id: "bookingDetails.experience.text",
    defaultMessage: "How did you experience your visit?",
    description: "Text to invite user to register meeting experience",
  },
  bookingDetailsExperienceButton: {
    id: "bookingDetails.experience.button",
    defaultMessage: "Your review",
    description: "Button to visit Website",
  },
});
