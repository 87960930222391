import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { messages } from "./ChangeCustomerStatusButton.messages";
import { CustomerResponse } from "../../../generated/customer-api";
import { useStyles } from "./ChangeCustomerStatusButton.style";
import ChangeCustomerStatusDialog from "../../Dialogs/ChangeCustomerStatusDialog/ChangeCustomerStatusDialog";

interface Props {
  onClose: () => void;
  customer?: CustomerResponse;
}

const ChangeCustomerStatusButton = (props: Props) => {
  const intl = useIntl();
  const { onClose, customer } = props;
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    onClose();
  };
  return (
    <>
      <ChangeCustomerStatusDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        customer={customer}
      />
      <Button
        className={classes.button}
        size="small"
        variant="outlined"
        onClick={() => setIsDialogOpen(true)}
      >
        {intl.formatMessage(messages.buttonLabel)}
      </Button>
    </>
  );
};

export default ChangeCustomerStatusButton;
