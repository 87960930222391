import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "participants.title",
    defaultMessage: "Participants",
    description: "Heading for participants card in meeting session view",
  },
  generalError: {
    id: "participants.error.general",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to load chat message",
  },
});
