/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCustomerSettingsByHostnameRequest
 */
export interface GetCustomerSettingsByHostnameRequest {
    /**
     * 
     * @type {string}
     * @memberof GetCustomerSettingsByHostnameRequest
     */
    hostname: string;
}

export function GetCustomerSettingsByHostnameRequestFromJSON(json: any): GetCustomerSettingsByHostnameRequest {
    return GetCustomerSettingsByHostnameRequestFromJSONTyped(json, false);
}

export function GetCustomerSettingsByHostnameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCustomerSettingsByHostnameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hostname': json['hostname'],
    };
}

export function GetCustomerSettingsByHostnameRequestToJSON(value?: GetCustomerSettingsByHostnameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hostname': value.hostname,
    };
}


