import {
  Configuration as UserConfig,
  UserApi,
  UserRoleType,
} from "../../generated/user-api";
import { getSessionStorageItem, Key } from "../../Utils/SessionStorage";
import { User } from "../../Models/User";
import {
  getUserIdsFromMeetings,
  getUserIdsFromParticipants,
} from "../../Utils/UserId";
import {
  decorateMeetingSessionWithPersonNumber,
  decorateMeetingWithPersonNumber,
  decoratePaginatedMeetingListWithPersonNumber,
} from "../../Utils/Decorator";
import {
  GetMeetingByIdRequest,
  GetMeetingSessionRequest,
  GetMeetingsForUserRequest,
  MeetingApi,
  MeetingParticipantType,
} from "../../generated/meeting-api";
import {
  Meeting,
  MeetingSession,
  PaginatedMeetingList,
} from "../../Models/Meeting";

const BASE_PATH = process.env.REACT_APP_BACKEND_BASE_URL;
const TOKEN_PREFIX = "Bearer "; // needs to be a space in the end

const userService = (): UserApi => {
  const token = getSessionStorageItem(Key.JWT_TOKEN);
  const userConfig = new UserConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new UserApi(userConfig);
};

export class CustomMeetingApi extends MeetingApi {
  async getMeetingsForUserDecorated(
    authenticatedUser: User,
    requestParameters: GetMeetingsForUserRequest
  ): Promise<PaginatedMeetingList> {
    const originalResponse = await this.getMeetingsForUserRaw(
      requestParameters
    );
    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        const clientIds = getUserIdsFromMeetings(
          MeetingParticipantType.Client,
          res.data
        );
        return userService()
          .listPersonNumbers({
            listPersonNumberRequest: { userIds: Array.from(clientIds) },
          })
          .then((pIds) =>
            decoratePaginatedMeetingListWithPersonNumber(res, pIds)
          );
      }
      return res;
    });
  }

  async getMeetingSessionDecorated(
    authenticatedUser: User,
    requestParameters: GetMeetingSessionRequest
  ): Promise<MeetingSession> {
    const originalResponse = await this.getMeetingSessionRaw(requestParameters);
    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        const clientIds = getUserIdsFromParticipants(
          MeetingParticipantType.Client,
          res.meeting.participants
        );
        return userService()
          .listPersonNumbers({
            listPersonNumberRequest: { userIds: Array.from(clientIds) },
          })
          .then((pIds) => decorateMeetingSessionWithPersonNumber(res, pIds));
      }
      return res;
    });
  }

  async getMeetingByIdDecorated(
    authenticatedUser: User,
    requestParameters: GetMeetingByIdRequest
  ): Promise<Meeting> {
    const originalResponse = await this.getMeetingByIdRaw(requestParameters);
    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        const clientIds = getUserIdsFromParticipants(
          MeetingParticipantType.Client,
          res.participants
        );
        return userService()
          .listPersonNumbers({
            listPersonNumberRequest: { userIds: Array.from(clientIds) },
          })
          .then((pIds) => decorateMeetingWithPersonNumber(res, pIds));
      }
      return res;
    });
  }
}
