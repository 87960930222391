import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingLeft: theme.spacing(2),
    },
    margin: {
      marginTop: theme.spacing(0.2),
      padding: theme.spacing(2),
    },
  })
);
