import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dialog.takeCareSync.title",
    defaultMessage: "Select units to sync",
    description: "dialog title",
  },
  submitButtonLabel: {
    id: "dialog.takeCareSync.button.submit",
    defaultMessage: "Sync",
    description: "label for submit button",
  },
  cancelButtonLabel: {
    id: "dialog.takeCareSync.button.cancel",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  successOnlyCreated: {
    id: "dialog.takeCareSync.success.created",
    defaultMessage: "Synced {created} new bookings",
    description: "Text shown in snack bar when successfully synced",
  },
  successOnlyCanceled: {
    id: "dialog.takeCareSync.success.canceled",
    defaultMessage: "Synced {canceled} canceled bookings",
    description: "Text shown in snack bar when successfully synced",
  },
  successCreatedAndCanceled: {
    id: "dialog.takeCareSync.success.createdAndCanceled",
    defaultMessage: "Synced {created} new and {canceled} canceled bookings",
    description: "Text shown in snack bar when successfully synced",
  },
  info: {
    id: "dialog.takeCareSync.info",
    defaultMessage: "No bookings to sync",
    description: "Text shown in snack bar when no bookings to sync",
  },
  generalError: {
    id: "dialog.takeCareSync.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when general error appears",
  },
});
