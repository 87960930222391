import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
    informationText: {
      color: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    root: {
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);
