import React, { useContext, useState, Dispatch, SetStateAction } from "react";
import { UserResponse } from "../../generated/user-api/models/UserResponse";
import { MeetingResponse } from "../../generated/meeting-api";

interface Props {
  children: React.ReactNode;
}

interface CustomerCurrentUpdates {
  userResponse: UserResponse[];
}
export const initialState: CustomerCurrentUpdates = {
  userResponse: [],
};

interface CustomerCurrentBooking {
  MeetingResponse: MeetingResponse[];
}
export const initialStateBooking: CustomerCurrentBooking = {
  MeetingResponse: [],
};

const CustomerCurrentUpdatesContext = React.createContext<
  [CustomerCurrentUpdates, Dispatch<SetStateAction<CustomerCurrentUpdates>>]
>([initialState, () => {}]);

const CustomerCurrentBookingContext = React.createContext<
  [CustomerCurrentBooking, Dispatch<SetStateAction<CustomerCurrentBooking>>]
>([initialStateBooking, () => {}]);

export const useCustomerCurrentUpdate = () => {
  return useContext(CustomerCurrentUpdatesContext);
};
export const useCustomerCurrentBooking = () => {
  return useContext(CustomerCurrentBookingContext);
};
const RecentUpdatesProvider = (props: Props) => {
  const { children } = props;
  const [state, setState] = useState(initialState);
  const [stateBooking, setStateBooking] = useState(initialStateBooking);
  return (
    <CustomerCurrentUpdatesContext.Provider value={[state, setState]}>
      <CustomerCurrentBookingContext.Provider
        value={[stateBooking, setStateBooking]}
      >
        {children}
      </CustomerCurrentBookingContext.Provider>
    </CustomerCurrentUpdatesContext.Provider>
  );
};

export default RecentUpdatesProvider;
