import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Button from "@material-ui/core/Button";
import useStyles from "./PreviewAudioCard.style";
import { messages } from "./PreviewAudioCard.messages";
import { PreviewPublisherHook } from "../../../SessionVideo/hooks/usePreviewPublisher";
import { ErrorType } from "../../../SessionVideo/hooks/Error";
import AllowMediaGuideDialog from "../../../../Components/Dialogs/AllowMediaGuideDialog/AllowMediaGuideDialog";

interface Props {
  preview: PreviewPublisherHook;
}

const PreviewAudioCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [audioDevice, setAudioDevice] = useState("");
  const { preview } = props;
  const [showGuide, setShowGuide] = useState(false);

  const handleAudioSource = React.useCallback(
    (e) => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      preview.publisher.setAudioDevice(audioDeviceId);
    },
    [preview.publisher]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (preview.deviceInfo && preview.deviceInfo.audioInputDevices.length > 0) {
      if (preview.publisherCreated && preview.deviceInfo) {
        preview.publisher.getAudioDevice().then((currentAudioDevice) => {
          setAudioDevice(currentAudioDevice.deviceId);
        });
      }
    }
  }, [
    setAudioDevice,
    preview.publisherCreated,
    preview.deviceInfo,
    preview.publisher,
    preview.publisherErrors,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    preview.publisherErrors.includes(ErrorType.OT_USER_MEDIA_ACCESS_DENIED) ||
    preview.publisherErrors.includes(ErrorType.AUDIO_HARDWARE_NOT_FOUND)
  ) {
    return (
      <Paper className={classes.paper}>
        <AllowMediaGuideDialog
          isOpen={showGuide}
          onClose={() => setShowGuide(false)}
        />
        <Grid container justify="center" alignItems="center">
          <Grid item md={4} xs={12}>
            <div className={classes.iconContainer}>
              <ReportProblemIcon className={classes.errorIcon} />
            </div>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography variant="h5" color="error">
              {intl.formatMessage(messages.titleLabel)}
            </Typography>
            <Typography variant="body1">
              {preview.publisherErrors.includes(
                ErrorType.OT_USER_MEDIA_ACCESS_DENIED
              )
                ? intl.formatMessage(messages.errorContent)
                : intl.formatMessage(messages.errorNoDeviceFound)}
            </Typography>
            {preview.publisherErrors.includes(
              ErrorType.OT_USER_MEDIA_ACCESS_DENIED
            ) && (
              <Button variant="outlined" onClick={() => setShowGuide(true)}>
                {intl.formatMessage(messages.showHowButtonLabel)}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Grid container justify="center" alignItems="center">
        <Grid item md={4} xs={12}>
          <div className={classes.audioPreviewContainer}>
            <div className={classes.audioPreview}>
              <MicIcon className={classes.icon} />
              <CircularProgress
                size={100}
                thickness={3}
                variant="determinate"
                value={preview.audioLogLevel}
                color="primary"
                className={classes.progress}
              />
              <CircularProgress
                size={100}
                thickness={3}
                variant="determinate"
                value={100}
                className={classes.staticProgress}
              />
            </div>
          </div>
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography variant="h5">
            {intl.formatMessage(messages.titleLabel)}
          </Typography>
          <Typography variant="body1">
            {intl.formatMessage(messages.content)}
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="audioSource">
              {intl.formatMessage(messages.audioSourceLabel)}
            </InputLabel>
            {preview.deviceInfo.videoInputDevices && (
              <Select
                labelId="audioSource"
                value={audioDevice}
                onChange={handleAudioSource}
              >
                {preview.deviceInfo.audioInputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PreviewAudioCard;
