import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "dialog.connectToMeeting.title",
    defaultMessage: "Another visit have started",
    description: "Title for connect dialog",
  },
  cancelButtonLabel: {
    id: "dialog.connectToMeeting.cancelButton.label",
    defaultMessage: "Reject",
    description: "cancel button label",
  },
});
