import { makeStyles } from "@material-ui/core/styles";
import { PRIMARY_PANTONE_303 } from "../../../../Components/Theme/Components/DefaultTheme/DefaultTheme";

export default makeStyles((theme) => ({
  imageContainer: {
    alignContent: "center",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    borderRadius: "50%",
    width: 150,
    paddingBottom: theme.spacing(1),
    alignContent: "center",
  },
  title: {
    color: PRIMARY_PANTONE_303,
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      justifyContent: "flex-start",
    },
  },
  description: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    paddingLeft: theme.spacing(0),
  },
}));
