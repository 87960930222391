import { defineMessages } from "react-intl";

export const messages = defineMessages({
  error: {
    id: "button.file-upload.error",
    defaultMessage: "Something went wrong",
    description: "Text to be shown when fails to upload file",
  },
  fileTooLargeError: {
    id: "button.file-upload.error.fileTooLarge",
    defaultMessage: "File too large",
    description: "Text to be shown when file too large to be uploaded",
  },
  success: {
    id: "button.file-upload.success",
    defaultMessage: "Successfully uploaded file",
    description: "Text to be shown when successfully uploaded file",
  },
  label: {
    id: "button.file-upload.label",
    defaultMessage: "Upload file",
    description: "Button label for upload file",
  },
});
