import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import isBefore from "date-fns/isBefore";
import {
  AttendanceStatusType,
  MeetingResponse,
  MeetingStatusType,
} from "../../../generated/meeting-api";
import { messages } from "./AcceptMeetingButton.messages";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { Variant } from "../../BookingsList/Components/Variant/Variant";
import { RoutePath } from "../../Routes/RoutePath";
import { meetingService } from "../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  meeting: MeetingResponse;
  contextVariant: Variant;
  className?: string;
  onAccepted?: () => void;
}

const AcceptMeetingButton = (props: Props) => {
  const { meeting, className, contextVariant, onAccepted } = props;
  const intl = useIntl();
  const history = useHistory();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const isOngoing = meeting.status === MeetingStatusType.InProgress;

  const redirectToMeetingPage = () => {
    history.push({
      pathname: RoutePath.SESSION_VIDEO,
      search: `?meetingId=${meeting.id}`,
    });
  };

  const handleAcceptMeeting = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (customerId && meeting.id) {
      meetingService()
        .updateAttendanceStatus({
          customerId,
          meetingId: meeting.id,
          attendanceStatusRequest: {
            attendanceStatus: AttendanceStatusType.Confirmed,
          },
        })
        .then(() => {
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          });
        })
        .then(() => {
          if (onAccepted) {
            onAccepted();
          }
          if (isOngoing) {
            redirectToMeetingPage();
          }
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        });
    }
  };

  if (
    isBefore(new Date(), meeting.expectedEndDateTime) &&
    (contextVariant === Variant.DASHBOARD_INCOMING_BOOKING_REQUESTS ||
      contextVariant === Variant.INCOMING_BOOKING_REQUESTS) &&
    (meeting.status === MeetingStatusType.Created ||
      meeting.status === MeetingStatusType.InProgress)
  ) {
    return (
      <Button
        size="small"
        color="primary"
        className={className}
        variant="contained"
        onClick={handleAcceptMeeting}
      >
        {isOngoing
          ? intl.formatMessage(messages.acceptAndConnectButtonLabel)
          : intl.formatMessage(messages.acceptButtonLabel)}
      </Button>
    );
  }

  return null;
};

export default AcceptMeetingButton;
