/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingParticipantResponse,
    MeetingParticipantResponseFromJSON,
    MeetingParticipantResponseFromJSONTyped,
    MeetingParticipantResponseToJSON,
    MeetingStatusType,
    MeetingStatusTypeFromJSON,
    MeetingStatusTypeFromJSONTyped,
    MeetingStatusTypeToJSON,
    SourceType,
    SourceTypeFromJSON,
    SourceTypeFromJSONTyped,
    SourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingResponse
 */
export interface MeetingResponse {
    /**
     * UUID of meeting
     * @type {string}
     * @memberof MeetingResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingResponse
     */
    title?: string;
    /**
     * 
     * @type {Date}
     * @memberof MeetingResponse
     */
    startDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingResponse
     */
    expectedEndDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingResponse
     */
    actualEndDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MeetingResponse
     */
    cancelledOnDateTime?: Date;
    /**
     * Meeting lenght in minutes
     * @type {number}
     * @memberof MeetingResponse
     */
    duration: number;
    /**
     * 
     * @type {MeetingStatusType}
     * @memberof MeetingResponse
     */
    status: MeetingStatusType;
    /**
     * Session id obtained from video provider
     * @type {string}
     * @memberof MeetingResponse
     */
    sessionId?: string;
    /**
     * UUID of the user that created this meeting
     * @type {string}
     * @memberof MeetingResponse
     */
    createdByUserId?: string;
    /**
     * Date and time this meeting was created
     * @type {Date}
     * @memberof MeetingResponse
     */
    createdOn?: Date;
    /**
     * Date and time this meeting was last updated
     * @type {Date}
     * @memberof MeetingResponse
     */
    updatedOn?: Date;
    /**
     * 
     * @type {Array<MeetingParticipantResponse>}
     * @memberof MeetingResponse
     */
    participants?: Array<MeetingParticipantResponse>;
    /**
     * 
     * @type {SourceType}
     * @memberof MeetingResponse
     */
    source: SourceType;
}

export function MeetingResponseFromJSON(json: any): MeetingResponse {
    return MeetingResponseFromJSONTyped(json, false);
}

export function MeetingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'startDateTime': (new Date(json['startDateTime'])),
        'expectedEndDateTime': (new Date(json['expectedEndDateTime'])),
        'actualEndDateTime': !exists(json, 'actualEndDateTime') ? undefined : (new Date(json['actualEndDateTime'])),
        'cancelledOnDateTime': !exists(json, 'cancelledOnDateTime') ? undefined : (new Date(json['cancelledOnDateTime'])),
        'duration': json['duration'],
        'status': MeetingStatusTypeFromJSON(json['status']),
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'createdByUserId': !exists(json, 'createdByUserId') ? undefined : json['createdByUserId'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(MeetingParticipantResponseFromJSON)),
        'source': SourceTypeFromJSON(json['source']),
    };
}

export function MeetingResponseToJSON(value?: MeetingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'startDateTime': (value.startDateTime.toISOString()),
        'expectedEndDateTime': (value.expectedEndDateTime.toISOString()),
        'actualEndDateTime': value.actualEndDateTime === undefined ? undefined : (value.actualEndDateTime.toISOString()),
        'cancelledOnDateTime': value.cancelledOnDateTime === undefined ? undefined : (value.cancelledOnDateTime.toISOString()),
        'duration': value.duration,
        'status': MeetingStatusTypeToJSON(value.status),
        'sessionId': value.sessionId,
        'createdByUserId': value.createdByUserId,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(MeetingParticipantResponseToJSON)),
        'source': SourceTypeToJSON(value.source),
    };
}


