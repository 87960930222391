import React, { ChangeEvent, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { messages } from "./FileUploadButton.messages";
import { fileService } from "../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  meetingId?: string;
  messageThreadId?: string;
  accessGivenTo?: string[];
  onFileUploaded?: (file: File) => void;
  variant?: "text" | "outlined" | "contained";
  color?: "inherit" | "primary" | "secondary" | "default";
  disabled?: boolean;
  isLoading?: (isLoading: boolean) => void;
  onCanceled?: () => void;
}
const FileUploadButton = (props: Props) => {
  const {
    meetingId,
    messageThreadId,
    onFileUploaded,
    onCanceled,
    accessGivenTo,
    variant,
    color,
    disabled,
    isLoading,
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const intl = useIntl();

  const onCompleted = (file: File) => {
    if (onFileUploaded) {
      onFileUploaded(file);
    }
    if (isLoading) {
      isLoading(false);
    }
  };

  const uploadFile = (file: File): void => {
    if (customerId && meetingId && authenticatedUser.user) {
      fileService()
        .uploadMeetingFile({
          customerId,
          meetingId,
          file,
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          })
        )
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(() => onCompleted(file));
    } else if (customerId && messageThreadId && authenticatedUser.user) {
      fileService()
        .uploadMessageFile({
          customerId,
          threadId: messageThreadId,
          file,
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          })
        )
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(() => onCompleted(file));
    } else if (customerId && accessGivenTo && authenticatedUser.user) {
      fileService()
        .uploadFile({
          customerId,
          accessGivenTo,
          file,
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          })
        )
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(() => onCompleted(file));
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size / 1000000 > 10) {
        enqueueSnackbar(intl.formatMessage(messages.fileTooLargeError), {
          variant: "error",
        });
      } else {
        uploadFile(event.target.files[0]);
      }
    }
  };

  const handleButtonClick = (): void => {
    if (isLoading) {
      isLoading(true);
    }
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.addEventListener("cancel", () => {
        if (onCanceled) {
          onCanceled();
        }
      });
    }
  }, [fileInputRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <input
        ref={fileInputRef}
        onChange={handleFileUpload}
        type="file"
        style={{ display: "none" }}
      />
      <Button
        size="small"
        color={color || "primary"}
        variant={variant || "contained"}
        startIcon={<CloudUploadIcon />}
        disabled={disabled}
        onClick={handleButtonClick}
      >
        {intl.formatMessage(messages.label)}
      </Button>
    </>
  );
};

export default FileUploadButton;
