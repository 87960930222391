import { defineMessages } from "react-intl";

export const messages = defineMessages({
  warningMessage: {
    id: "modifyuserdialog.password.warning.noPhoneNumber.label",
    defaultMessage:
      "To be able to generate a password the user need to have a phone number registered. To register a phone number go to the General tab above.",
    description: "Warning Label when no phone number available",
  },
  description: {
    id: "modifyuserdialog.password.description.label",
    defaultMessage:
      "Current password will be replaced by a new generated one, this action cannot be undone! Password will be sent to the phone number below.",
    description: "description Label for generating password",
  },
  descriptionFirstTime: {
    id: "modifyuserdialog.password.description.firstTime.label",
    defaultMessage:
      "In order for the user to be able to log in with the username, a password must be created and sent to the user. The password is sent to the mobile phone number below.",
    description: "description Label for generating password for the first time",
  },
  infoMessage: {
    id: "modifyuserdialog.password.info.label",
    defaultMessage:
      "When activating username & password login the user will be able to login if a password is created, otherwise you will need to generate a new password for the user as well.",
    description: "Warning Label when status changed to  closed",
  },
  submitButtonLabel: {
    id: "modifyuserdialog.password.button.submit.label",
    defaultMessage: "Generate password",
    description: "label for submit button in Modify user dialog",
  },
  activateButtonLabel: {
    id: "modifyuserdialog.password.button.activate.label",
    defaultMessage: "Activate",
    description: "label for submit button in Modify user dialog",
  },
  deactivateSwitchLabel: {
    id: "modifyuserdialog.password.switch.deactivate.label",
    defaultMessage: "Activated",
    description: "label for submit button in Modify user dialog",
  },
  success: {
    id: "modifyuserdialog.password.success",
    defaultMessage: "Password is generated",
    description: "Success message when password successfully generated",
  },
});
