import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.getContrastText(theme.palette.grey.A700),
      backgroundColor: theme.palette.grey.A700,
    },
  })
);
