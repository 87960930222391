/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Invite API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateInviteRequest,
    CreateInviteRequestFromJSON,
    CreateInviteRequestToJSON,
    InviteResponse,
    InviteResponseFromJSON,
    InviteResponseToJSON,
    SendInviteCodeRequest,
    SendInviteCodeRequestFromJSON,
    SendInviteCodeRequestToJSON,
    VerifyInviteCodeRequest,
    VerifyInviteCodeRequestFromJSON,
    VerifyInviteCodeRequestToJSON,
    VerifyInviteCodeResponse,
    VerifyInviteCodeResponseFromJSON,
    VerifyInviteCodeResponseToJSON,
} from '../models';

export interface CreateInviteOperationRequest {
    customerId: string;
    body: CreateInviteRequest;
}

export interface SendInviteCodeOperationRequest {
    customerId: string;
    body: SendInviteCodeRequest;
}

export interface VerifyInviteCodeOperationRequest {
    customerId: string;
    body: VerifyInviteCodeRequest;
}

/**
 * 
 */
export class InviteApi extends runtime.BaseAPI {

    /**
     * Create user invite.
     * Create user invite
     */
    async createInviteRaw(requestParameters: CreateInviteOperationRequest): Promise<runtime.ApiResponse<InviteResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createInvite.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInviteRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InviteResponseFromJSON(jsonValue));
    }

    /**
     * Create user invite.
     * Create user invite
     */
    async createInvite(requestParameters: CreateInviteOperationRequest): Promise<InviteResponse> {
        const response = await this.createInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send invite code.
     * Creates and send invite code to invited user
     */
    async sendInviteCodeRaw(requestParameters: SendInviteCodeOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling sendInviteCode.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling sendInviteCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/code/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendInviteCodeRequestToJSON(requestParameters.body),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send invite code.
     * Creates and send invite code to invited user
     */
    async sendInviteCode(requestParameters: SendInviteCodeOperationRequest): Promise<void> {
        await this.sendInviteCodeRaw(requestParameters);
    }

    /**
     * Verify code sent by SMS or Email.
     * Verify code sent by SMS or Email
     */
    async verifyInviteCodeRaw(requestParameters: VerifyInviteCodeOperationRequest): Promise<runtime.ApiResponse<VerifyInviteCodeResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling verifyInviteCode.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling verifyInviteCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invite/code/verify/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyInviteCodeRequestToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyInviteCodeResponseFromJSON(jsonValue));
    }

    /**
     * Verify code sent by SMS or Email.
     * Verify code sent by SMS or Email
     */
    async verifyInviteCode(requestParameters: VerifyInviteCodeOperationRequest): Promise<VerifyInviteCodeResponse> {
        const response = await this.verifyInviteCodeRaw(requestParameters);
        return await response.value();
    }

}
