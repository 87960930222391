import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useSubscription } from "react-stomp-hooks";
import { useStyles } from "./SharedFilesCard.style";
import { messages } from "./SharedFilesCard.messages";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import FeatureController from "../FeatureController/FeatureController";
import FileUploadButton from "../Buttons/FileUploadButton/FileUploadButton";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import {
  PaginatedFileList,
  SortByType,
  SortOrderType,
} from "../../generated/file-api";
import FileList from "./Components/FileList/FileList";
import CustomPagination from "../CustomPagination/Pagination";
import { fileService } from "../../Providers/ServiceProvider/ServiceProvider";
import {
  parseSignalMessage,
  SignalType,
} from "../../WebSocket/model/SignalMessage";
import { SocketDestination } from "../../WebSocket/WebSocketSession";
import { Contact } from "../../Models/Contact";

interface Props {
  variant: "inMeeting" | "meetingOverview" | "personOverview";
  readOnly?: boolean;
  meetingId?: string;
  contact?: Contact;
  onLoaded?: () => void;
  onLoading?: () => void;
}

const SharedFilesCard = (props: Props) => {
  const { meetingId, onLoaded, onLoading, readOnly, contact, variant } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [files, setFiles] = useState<PaginatedFileList>();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);

  const loadFiles = () => {
    if (customerId && authenticatedUser.user) {
      if (onLoading) {
        onLoading();
      }
      fileService()
        .listFiles({
          page,
          pageSize,
          meetingId: contact?.userId ? undefined : meetingId,
          customerId,
          userId: contact?.userId,
          sortBy: SortByType.UploadedOn,
          sortOrder: SortOrderType.Descending,
        })
        .then(setFiles)
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(onLoaded);
    }
  };

  useSubscription(SocketDestination.SIGNAL_MEETING + meetingId, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.FILE &&
      signalMessage.triggeredBy !== authenticatedUser.user?.id
    ) {
      loadFiles();
    }
  });

  useEffect(() => {
    loadFiles();
  }, [meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadFiles();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!files) {
    return null;
  }

  return (
    <FeatureController requiredFeature={CustomerFeatureType.InMeetingFileShare}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="files-content"
          id="files-header"
        >
          <Typography
            className={classes.cardHeading}
            component="h2"
            variant="subtitle2"
          >
            {intl.formatMessage(messages.title).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container disableGutters>
            <Typography gutterBottom variant="subtitle2">
              <Box fontStyle="italic">
                {variant === "inMeeting" &&
                  intl.formatMessage(messages.inMeetingDescription)}
                {variant === "personOverview" &&
                  intl.formatMessage(messages.personOverviewDescription, {
                    name: `${contact?.firstName} ${contact?.lastName}`,
                  })}
                {variant === "meetingOverview" &&
                  intl.formatMessage(messages.meetingOverviewDescription)}
              </Box>
            </Typography>
            <div className={classes.sharedFileContainer}>
              <FileList files={files.data || []} onDeleted={loadFiles} />
              <CustomPagination
                size="small"
                onChange={setPage}
                pagination={files.pagination}
              />
            </div>
            {!readOnly && (
              <Box component="div" className={classes.buttonEnd}>
                <FileUploadButton
                  accessGivenTo={
                    contact?.userId ? [contact?.userId] : undefined
                  }
                  meetingId={meetingId}
                  onFileUploaded={loadFiles}
                />
              </Box>
            )}
          </Container>
        </AccordionDetails>
      </Accordion>
    </FeatureController>
  );
};

export default SharedFilesCard;
