import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noMatchEmail: {
    id: "dialog.addOrCreateUser.noMatch.email.text",
    defaultMessage: "There is no user in your organization with this email",
    description: "Text shown when search for user in meeting finds no match",
  },
  noMatchPersonNumber: {
    id: "dialog.addOrCreateUser.noMatch.personNumber.text",
    defaultMessage:
      "There is no user in your organization with this person number",
    description: "Text shown when search for user in meeting finds no match",
  },
  createUserButton: {
    id: "dialog.addOrCreateUser.button.createUser.label",
    defaultMessage: "Create new",
    description: "Text shown when search for user in meeting finds no match",
  },
  inviteUserButton: {
    id: "dialog.addOrCreateUser.button.inviteUser.label",
    defaultMessage: "Invite new",
    description: "Text shown when search for user in meeting finds no match",
  },
});
