import { defineMessages } from "react-intl";

export const messages = defineMessages({
  subjectLabel: {
    id: "createMessageFields.subject.label",
    defaultMessage: "Subject",
    description: "Label for text field subject in message",
  },
  clientRecipientsLabel: {
    id: "createMessageFields.clientRecipients.label",
    defaultMessage: "Patient recipients",
    description: "Label for text field client recipients in message",
  },
  staffRecipientsLabel: {
    id: "createMessageFields.staffRecipients.label",
    defaultMessage: "Caregiver recipients",
    description: "Label for text field staff recipients in message",
  },
  externalRecipientsLabel: {
    id: "createMessageFields.externalRecipients.label",
    defaultMessage: "External recipients",
    description: "Label for text field external recipients in message",
  },
  warningMessage: {
    id: "createMessageFields.warning.label",
    defaultMessage: "Separate messages will be sent to each recipient.",
    description: "Warning Label for multiple recipients",
  },
  warningOneWayMessage: {
    id: "createMessageFields.warning.oneWayMessage.label",
    defaultMessage:
      "Patienter och externa användare kommer inte att kunna svara på detta meddelande",
    description: "Warning Label for multiple recipients",
  },
});
