import React, { useEffect, useState } from "react";
import MuiAvatarGroup from "@material-ui/lab/AvatarGroup";
import { Grid } from "@material-ui/core";
import ProfilePicture, { Sizes } from "../ProfilePicture/ProfilePicture";
import { useStyles } from "./AvatarGroup.style";
import ParticipantList from "../Tooltip/ParticipantList/ParticipantList";
import Tooltip from "../Tooltip/Tooltip";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { createSortedList } from "../../Utils/Meeting";
import { MeetingParticipant } from "../../Models/Meeting";
import { FileUserResponse } from "../../generated/file-api";
import FileUserList from "../Tooltip/FileUserList/FileUserList";

interface Props {
  max?: number;
  center?: boolean;
  size?: Sizes;
  spacing?: "small" | "medium" | number;
  participants?: MeetingParticipant[];
  users?: FileUserResponse[];
}

const GroupAvatars = (props: Props) => {
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const [sortedListParticipants, setSortedListParticipants] = useState<
    MeetingParticipant[]
  >();
  const { max, size, center, participants, spacing, users } = props;

  useEffect(() => {
    if (participants) {
      setSortedListParticipants(
        createSortedList(participants, authenticatedUser.user)
      );
    }
  }, [participants]); // eslint-disable-line react-hooks/exhaustive-deps

  if (participants) {
    if (!participants || !sortedListParticipants) {
      return null;
    }
  } else if (!users) {
    return null;
  }

  return (
    <Tooltip
      title={
        participants ? (
          <ParticipantList participants={sortedListParticipants} />
        ) : (
          <FileUserList users={users} />
        )
      }
      placement="bottom-start"
    >
      <Grid container>
        <MuiAvatarGroup
          max={max || 5}
          spacing={spacing || "small"}
          className={center ? classes.center : undefined}
        >
          {sortedListParticipants &&
            sortedListParticipants.map((p) => (
              <ProfilePicture
                key={p.userId}
                size={size}
                firstName={p.firstName}
                lastName={p.lastName}
              />
            ))}

          {users &&
            users.map((p) => (
              <ProfilePicture
                size={size}
                firstName={p.firstName}
                lastName={p.lastName}
              />
            ))}
        </MuiAvatarGroup>
      </Grid>
    </Tooltip>
  );
};
export default GroupAvatars;
