export enum Key {
  MAC_OS_REACTIONS_INFO_CLOSED = "mac-os-reactions-info-closed",
}

export const getLocalStorageItem = (key: Key): string | undefined => {
  const value = localStorage.getItem(key);
  return value == null ? undefined : value;
};

export const setLocalStorageItem = (key: Key, value: string): Promise<void> => {
  return Promise.resolve().then(() => localStorage.setItem(key, value));
};

export const removeLocalStorageItem = (key: Key): void => {
  localStorage.removeItem(key);
};
