import React from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { messages } from "./GoToLoginButton.messages";
import { RoutePath } from "../../Routes/RoutePath";

interface Props {
  className?: string;
}

const GoToLoginButton = (props: Props) => {
  const { className } = props;
  const intl = useIntl();
  const history = useHistory();

  const redirectToLogin = (event: React.MouseEvent) => {
    event.stopPropagation();
    history.push({ pathname: RoutePath.LOGIN });
  };

  return (
    <Button
      size="small"
      color="primary"
      variant="contained"
      className={className}
      startIcon={<LoginIcon />}
      onClick={redirectToLogin}
    >
      {intl.formatMessage(messages.buttonLabel)}
    </Button>
  );
};

export default GoToLoginButton;
