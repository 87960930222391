/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerFeature,
    CustomerFeatureFromJSON,
    CustomerFeatureFromJSONTyped,
    CustomerFeatureToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerFeatureResponse
 */
export interface CustomerFeatureResponse {
    /**
     * UUID of customer
     * @type {string}
     * @memberof CustomerFeatureResponse
     */
    customerId: string;
    /**
     * List of customer features
     * @type {Array<CustomerFeature>}
     * @memberof CustomerFeatureResponse
     */
    features: Array<CustomerFeature>;
}

export function CustomerFeatureResponseFromJSON(json: any): CustomerFeatureResponse {
    return CustomerFeatureResponseFromJSONTyped(json, false);
}

export function CustomerFeatureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerFeatureResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'features': ((json['features'] as Array<any>).map(CustomerFeatureFromJSON)),
    };
}

export function CustomerFeatureResponseToJSON(value?: CustomerFeatureResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'features': ((value.features as Array<any>).map(CustomerFeatureToJSON)),
    };
}


