import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./CardHeading.style";

interface Props {
  children: React.ReactNode;
  noMarginBottom?: boolean;
}

const CardHeading = (props: Props) => {
  const { children, noMarginBottom } = props;
  const classes = useStyles();
  return (
    <Typography
      className={
        noMarginBottom ? classes.cardHeadingNoMargin : classes.cardHeading
      }
      component="h1"
      variant="subtitle2"
    >
      {children}
    </Typography>
  );
};

export default CardHeading;
