import { defineMessages } from "react-intl";

export const messages = defineMessages({
  close: {
    id: "todo.dialog.view.button.close",
    defaultMessage: "Close",
    description: "Close label for view todo dialog",
  },
  assignedByLabel: {
    id: "todo.dialog.view.assignedBy.label",
    defaultMessage: "Assigned by",
    description: "label  for Assigned by",
  },
  assignedToLabel: {
    id: "todo.dialog.view.assignedTo.label",
    defaultMessage: "Assigned to",
    description: "label  for Assigned to",
  },
  createdOnLabel: {
    id: "todo.dialog.view.createdOn.label",
    defaultMessage: "Created on",
    description: "label  for Created on",
  },
  updatedOnLabel: {
    id: "todo.dialog.view.updatedOn.label",
    defaultMessage: "Updated on",
    description: "label  for Updated on",
  },
  shouldBeCompletedOnLabel: {
    id: "todo.dialog.view.shouldBeCompletedOn.label",
    defaultMessage: "Should be completed on",
    description: "label  for Should be completed on",
  },
});
