import { defineMessages } from "react-intl";

export const messages = defineMessages({
  personOverviewLabel: {
    id: "participantCard.personOverview.label",
    defaultMessage: "Person overview",
    description: "label for person overview",
  },
  bookingHistoryLabel: {
    id: "participantCard.bookingHistory.label",
    defaultMessage: "Booking history",
    description: "label for booking history",
  },
});
