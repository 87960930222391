import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitleClient: {
    id: "userSelectField.dialog.createUser.client.title",
    defaultMessage: "Create new patient",
    description: "Client title",
  },
  dialogTitleExternal: {
    id: "userSelectField.dialog.createUser.external.title",
    defaultMessage: "Create new external user",
    description: "External title",
  },
  dialogTitleStaff: {
    id: "userSelectField.dialog.createUser.staff.title",
    defaultMessage: "Create new caregiver",
    description: "Staff title",
  },
});
