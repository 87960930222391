import { defineMessages } from "react-intl";

export const messages = defineMessages({
  today: {
    id: "calendarComponent.today",
    defaultMessage: "Today",
    description: "Header button text for today",
  },
  month: {
    id: "calendarComponent.month",
    defaultMessage: "Month",
    description: "Header button text for Month",
  },
  week: {
    id: "calendarComponent.week",
    defaultMessage: "Week",
    description: "Header button text for Week",
  },
  day: {
    id: "calendarComponent.day",
    defaultMessage: "Day",
    description: "Header button text for Day",
  },
  list: {
    id: "calendarComponent.list",
    defaultMessage: "List",
    description: "Header button text for List",
  },
  previous: {
    id: "calendarComponent.previous",
    defaultMessage: "Previous",
    description: "Go to previous range",
  },
  next: {
    id: "calendarComponent.next",
    defaultMessage: "Next",
    description: "Go to next range",
  },
});
