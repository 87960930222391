import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { preloadScript } from "opentok-react";
import { useIntl } from "react-intl";
import { messages } from "./TestVideo.messages";
import { useStyles } from "./TestVideo.style";
import PreviewVideoCard from "./Components/PreviewVideoCard/PreviewVideoCard";
import usePreviewPublisher from "../SessionVideo/hooks/usePreviewPublisher";
import PreviewAudioCard from "./Components/PreviewAudioCard/PreviewAudioCard";
import BrowserSupportCard from "./Components/BrowserSupportCard/BrowserSupportCard";
import CommonFixesCard from "./Components/CommonFixesCard/CommonFixesCard";
import AlertDialog from "../../Components/Dialogs/AlertDialog/AlertDialog";
import { ErrorType } from "../SessionVideo/hooks/Error";

const TestVideo = () => {
  const intl = useIntl();
  const classes = useStyles();
  const preview = usePreviewPublisher();
  const [forceCloseDialog, setForceCloseDialog] = useState<boolean>(false);

  return (
    <>
      <AlertDialog
        open={
          preview.publisherErrors.includes(ErrorType.OT_HARDWARE_UNAVAILABLE) &&
          !forceCloseDialog
        }
        title={intl.formatMessage(messages.alertDialogTitle)}
        content={intl.formatMessage(messages.alertDialogContent)}
        submitLabel={intl.formatMessage(messages.alertDialogSubmitLabel)}
        onSubmit={() => window.location.reload()}
        showCloseCross
        onClose={() => setForceCloseDialog(true)}
        cancelLabel={intl.formatMessage(messages.alertDialogCancelLabel)}
      />
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid item xs={12}>
          <PreviewVideoCard preview={preview} />
        </Grid>
        <Grid item xs={12}>
          <PreviewAudioCard preview={preview} />
        </Grid>
        <Grid item xs={12}>
          <BrowserSupportCard />
        </Grid>
        <Grid item xs={12}>
          <CommonFixesCard />
        </Grid>
      </Grid>
    </>
  );
};

export default preloadScript(TestVideo);
