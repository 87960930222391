/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform file management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileMetadata,
    FileMetadataFromJSON,
    FileMetadataToJSON,
    PaginatedFilterList,
    PaginatedFilterListFromJSON,
    PaginatedFilterListToJSON,
} from '../models';

export interface DownloadFilterRequest {
    customerId: string;
    fileId: string;
}

export interface ListfilterRequest {
    customerId: string;
    page?: number;
    pageSize?: number;
}

export interface UploadFilterRequest {
    customerId: string;
    file: Blob;
}

/**
 * 
 */
export class FilterApi extends runtime.BaseAPI {

    /**
     * Download a specific filter file.
     * Download a filter file
     */
    async downloadFilterRaw(requestParameters: DownloadFilterRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling downloadFilter.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling downloadFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/video/filter/{customerId}/{fileId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download a specific filter file.
     * Download a filter file
     */
    async downloadFilter(requestParameters: DownloadFilterRequest): Promise<Blob> {
        const response = await this.downloadFilterRaw(requestParameters);
        return await response.value();
    }

    /**
     * List video filter files
     * List video filter files
     */
    async listfilterRaw(requestParameters: ListfilterRequest): Promise<runtime.ApiResponse<PaginatedFilterList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listfilter.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/video/filter/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFilterListFromJSON(jsonValue));
    }

    /**
     * List video filter files
     * List video filter files
     */
    async listfilter(requestParameters: ListfilterRequest): Promise<PaginatedFilterList> {
        const response = await this.listfilterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Operation to be used user upload video filter.
     * Upload a filter and its metadata.
     */
    async uploadFilterRaw(requestParameters: UploadFilterRequest): Promise<runtime.ApiResponse<FileMetadata>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling uploadFilter.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/video/filter/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileMetadataFromJSON(jsonValue));
    }

    /**
     * Operation to be used user upload video filter.
     * Upload a filter and its metadata.
     */
    async uploadFilter(requestParameters: UploadFilterRequest): Promise<FileMetadata> {
        const response = await this.uploadFilterRaw(requestParameters);
        return await response.value();
    }

}
