import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl, IntlShape } from "react-intl";
import {
  useSnackbar,
  SnackbarMessage,
  OptionsObject,
  SnackbarKey,
} from "notistack";
import {
  MeetingtemplateApi,
  UpdateMeetingTemplateOperationRequest,
} from "../../../generated/meetingtemplate-api";
import { meetingTemplateService } from "../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { messages } from "./DeleteMeetingTemplateDialog.messages";

interface OnVerifyProps {
  customerId: string;
  meetingId: string;
  meetingTemplateApi: MeetingtemplateApi;
  intl: IntlShape;
  onDeleteTemplate?: () => void;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
}
const onVerify = (props: OnVerifyProps) => {
  const {
    customerId,
    meetingId,
    meetingTemplateApi,
    enqueueSnackbar,
    onDeleteTemplate,
    intl,
  } = props;
  const meetingTemplateRequest: UpdateMeetingTemplateOperationRequest = {
    customerId,
    templateId: meetingId,
    updateMeetingTemplateRequest: {},
  };
  meetingTemplateApi
    .deleteMeetingTemplate(meetingTemplateRequest)
    .then(() => {
      enqueueSnackbar(intl.formatMessage(messages.success), {
        variant: "success",
      });
    })
    .then(onDeleteTemplate)
    .catch(() =>
      enqueueSnackbar(intl.formatMessage(messages.generalError), {
        variant: "error",
      })
    );
};

interface Props {
  isOpen: boolean;
  onClose: (event: React.MouseEvent) => void;
  meetingId: string;
  meetingTitle: string;
  onDeleteTemplate?: () => void;
}

const DeleteMeetingTemplateDialog = (props: Props) => {
  const { isOpen, meetingId, meetingTitle, onClose, onDeleteTemplate } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const customerId = useCustomerId() || "";
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleSubmit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onVerify({
      meetingId,
      customerId,
      meetingTemplateApi: meetingTemplateService(),
      intl,
      enqueueSnackbar,
      onDeleteTemplate,
    });
    onClose(e);
  };

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onBackdropClick={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.title, { meetingTitle })}
      </DialogTitle>
      <DialogContent>{intl.formatMessage(messages.content)}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          {intl.formatMessage(messages.submitButtonLabel)}
        </Button>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(messages.cancelButtonLabel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMeetingTemplateDialog;
