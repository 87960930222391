import React, { useState } from "react";
import CallEndIcon from "@material-ui/icons/CallEnd";
import { useIntl } from "react-intl";
import { SpeedDialAction } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import RoomWrapper from "@vonage/video-express/dist/mp/room";
import { useHistory } from "react-router-dom";
import { useStyles } from "./EndCallSpeedDialAction.styles";
import { messages } from "./EndCallSpeedDialAction.messages";
import { UserRoleType } from "../../../../../../generated/user-api";
import PermissionController from "../../../../../../Components/PermissionController/PermissionController";
import { useCustomerId } from "../../../../../../Providers/CustomerProvider/CustomerProvider";
import { meetingService } from "../../../../../../Providers/ServiceProvider/ServiceProvider";
import { useAuthenticatedUser } from "../../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { RoutePath } from "../../../../../../Components/Routes/RoutePath";
import AlertDialog from "../../../../../../Components/Dialogs/AlertDialog/AlertDialog";

interface Props {
  open: boolean;
  delay: number;
  meetingId: string;
  room: RoomWrapper;
}
export default function EndCallSpeedDialAction(props: Props) {
  const { open, delay, meetingId, room } = props;
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const redirectToBookingDetails = () => {
    history.push({
      pathname: RoutePath.BOOKING_HISTORY,
      search: `?meetingId=${meetingId}`,
      state: { loadFromMeetingSession: true },
    });
  };

  const handleEndMeeting = () => {
    room.leave().then(() => {
      if (customerId && authenticatedUser.user) {
        meetingService()
          .endMeetingSession({
            customerId,
            meetingId,
            meetingEndRequest: {
              userId: authenticatedUser.user.id,
            },
          })
          .then(() => {
            redirectToBookingDetails();
          })
          .catch(() =>
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            })
          );
      }
    });
  };

  return (
    <PermissionController allowedRoles={[UserRoleType.Staff]}>
      <AlertDialog
        open={isDialogOpen}
        title={intl.formatMessage(messages.dialogTitle)}
        content={intl.formatMessage(messages.dialogContent)}
        submitLabel={intl.formatMessage(messages.dialogSubmitButton)}
        cancelLabel={intl.formatMessage(messages.dialogCancelButton)}
        onSubmit={handleEndMeeting}
        onClose={() => setIsDialogOpen(false)}
      />
      <SpeedDialAction
        key="end-call"
        icon={
          <div className={classes.container}>
            <CallEndIcon className={classes.icon} />
            {intl.formatMessage(messages.endCall)}
          </div>
        }
        onClick={() => setIsDialogOpen(true)}
        open={open}
        delay={delay}
        FabProps={{
          variant: "extended",
        }}
        classes={{ fab: classes.fab }}
        TooltipClasses={{ tooltip: classes.hidden }}
        className={classes.fabContainer}
      />
    </PermissionController>
  );
}
