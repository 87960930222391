import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createdStatus: {
    id: "bookingstatustype.created",
    defaultMessage: "Created",
    description: "Booking Meeting Created Status",
  },
  inProgressStatus: {
    id: "bookingstatustype.inprogress",
    defaultMessage: "In Progress",
    description: "Booking Meeting In Progress Status",
  },
  completedStatus: {
    id: "bookingstatustype.completed",
    defaultMessage: "Completed",
    description: "Booking Meeting Completed Status",
  },
  cancelledStatus: {
    id: "bookingstatustype.cancelled",
    defaultMessage: "Cancelled",
    description: "Booking Meeting Cancelled Status",
  },
  unknownStatus: {
    id: "bookingstatustype.unknown",
    defaultMessage: "Unknown",
    description: "Booking Meeting Unknown Status",
  },
});
