import {
  ListMessageThreadsRequest,
  MessageApi,
} from "../../generated/message-api";
import {
  Configuration as UserConfig,
  UserApi,
  UserRoleType,
} from "../../generated/user-api";
import { getSessionStorageItem, Key } from "../../Utils/SessionStorage";
import { PaginatedMessageThreadList } from "../../Models/Message";
import { User } from "../../Models/User";
import { getUserIdsFromMessages } from "../../Utils/UserId";
import { decoratePaginatedMessageThreadListWithPersonNumber } from "../../Utils/Decorator";

const BASE_PATH = process.env.REACT_APP_BACKEND_BASE_URL;
const TOKEN_PREFIX = "Bearer "; // needs to be a space in the end

const userService = (overrideToken?: string): UserApi => {
  const token = overrideToken || getSessionStorageItem(Key.JWT_TOKEN);
  const userConfig = new UserConfig({
    basePath: BASE_PATH,
    headers: { Authorization: `${TOKEN_PREFIX}${token}` },
  });

  return new UserApi(userConfig);
};

export class CustomMessageApi extends MessageApi {
  async listMessageThreadsDecorated(
    authenticatedUser: User,
    requestParameters: ListMessageThreadsRequest
  ): Promise<PaginatedMessageThreadList> {
    const originalResponse = await this.listMessageThreadsRaw(
      requestParameters
    );

    return originalResponse.value().then((res) => {
      if (authenticatedUser?.userRole === UserRoleType.Staff) {
        const clientIds = getUserIdsFromMessages(
          authenticatedUser?.id,
          res.data
        );

        return userService()
          .listPersonNumbers({
            listPersonNumberRequest: { userIds: Array.from(clientIds) },
          })
          .then((pIds) =>
            decoratePaginatedMessageThreadListWithPersonNumber(res, pIds)
          );
      }
      return res;
    });
  }
}
