import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      borderColor: theme.palette.grey.A700,
      borderWidth: theme.spacing(0.2),
      borderStyle: "solid",
      boxShadow: theme.shadows[5],
    },
    img: {
      maxHeight: theme.spacing(32),
      minHeight: theme.spacing(32),
      maxWidth: theme.spacing(32),
      minWidth: theme.spacing(32),
    },
    imgBar: {
      height: theme.spacing(4),
      width: "100%",
    },
    imgPlaceholder: {
      fontSize: 240,
      color: theme.palette.grey.A100,
      width: theme.spacing(32),
    },
  })
);
