import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2),
    },
    list: {
      marginBottom: theme.spacing(3),
    },
    Button: {
      textDecoration: "none",
      color: theme.palette.primary.dark,
    },
    hover: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "& $icon": {
          color: theme.palette.secondary.contrastText,
        },
      },
    },
  })
);
