import { defineMessages } from "react-intl";

export const messages = defineMessages({
  documentTitle: {
    id: "loginview.document.title",
    defaultMessage: "Sign in",
    description: "Browser tab title for login page",
  },
  dividerTitle: {
    id: "loginview.divider.caregiver.title",
    defaultMessage: "Caregiver",
    description: "divider title",
  },
  noneNotifiableUserInfoAlert: {
    id: "loginview.alert.info.noneNotifiableUser.text",
    defaultMessage: "Login to be able to update contact information",
    description: "Info alert",
  },
});
