import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  useAuthenticatedUser,
  useLogout,
} from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  children: React.ReactNode;
}

const IDLE_TIMER = 1000 * 60 * 60 * 2; // 2h

const IdleController = (props: Props) => {
  const { children } = props;
  const [authenticatedUser] = useAuthenticatedUser();
  const logout = useLogout();

  const handleOnIdle = () => {
    logout();
  };

  const { start, pause } = useIdleTimer({
    timeout: IDLE_TIMER,
    onIdle: handleOnIdle,
    startManually: true,
  });

  useEffect(() => {
    if (authenticatedUser.isLoggedIn) {
      start();
    } else {
      pause();
    }
  }, [authenticatedUser.isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

export default IdleController;
