import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { messages } from "./CreateMeetingTemplateDialog.messages";
import MeetingTemplateFields, {
  MeetingTemplateFormikValues,
} from "../../Fields/MeetingTemplateFields/MeetingTemplateFields";
import MeetingTemplateValidationSchema from "../../Fields/MeetingTemplateFields/Components/Validation/MeetingTemplateValidationSchema";
import ButtonLoadingWrapper from "../../Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";
import { MeetingTemplateKindType } from "../../../generated/meetingtemplate-api";
import { meetingTemplateService } from "../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  isDialogOpen?: boolean;
  onDialogClose?: () => void;
  onMeetingCreated?: () => void;
}

const CreateMeetingTemplateDialog = (props: Props) => {
  const { isDialogOpen, onDialogClose, onMeetingCreated } = props;
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [templateType] = useState<MeetingTemplateKindType | undefined>(
    MeetingTemplateKindType.Personal
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [
    isCreatingTemplateLoading,
    setIsCreatingTemplateLoading,
  ] = useState<boolean>(false);

  const initialValues = {
    title: "",
    duration: 30,
    templateType,
  } as MeetingTemplateFormikValues;
  const [kindTypeError, setKindTypeError] = useState<string>("");

  const formik = useFormik({
    initialValues,
    validationSchema: MeetingTemplateValidationSchema,
    onSubmit: (values) => {
      if (customerId && authenticatedUser?.user) {
        if (templateType === undefined) {
          setKindTypeError("Error");
          return;
        }
        setIsCreatingTemplateLoading(true);
        meetingTemplateService()
          .createMeetingTemplate({
            customerId,
            createMeetingTemplateRequest: {
              title: values.title,
              duration: values.duration,
              type: values.templateType,
            },
          })
          .then(() => {
            formik.resetForm();
            enqueueSnackbar(intl.formatMessage(messages.success), {
              variant: "success",
            });
          })
          .then(onMeetingCreated)
          .catch(() => {
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            });
          })
          .finally(() => setIsCreatingTemplateLoading(false));
      }
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isDialogOpen || false}
      fullScreen={fullScreen}
      onClose={onDialogClose}
    >
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.createButton)}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MeetingTemplateFields
            formik={formik}
            errorType={kindTypeError}
            typeDefaultValue={templateType}
            isNewTemplate
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isCreatingTemplateLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isCreatingTemplateLoading}
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button
            color="primary"
            onClick={onDialogClose}
            disabled={isCreatingTemplateLoading}
          >
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateMeetingTemplateDialog;
