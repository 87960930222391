import { defineMessages } from "react-intl";

export const messages = defineMessages({
  contentsPlaceholder: {
    id: "replyMessageFields.contents.placeholder",
    defaultMessage: "Reply...",
    description: "Placeholder for text field contents",
  },
  replyButton: {
    id: "replyMessageFields.button.submit",
    defaultMessage: "Reply",
    description: "Button label for reply",
  },
});
