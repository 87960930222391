import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { LinearProgress, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useIntl } from "react-intl";
import { useStyles } from "./SearchBar.style";
import { messages } from "./SearchBar.messages";

interface Props {
  placeholder: string;
  isLoading?: boolean;
  onSubmit: (query: string) => void;
  minQueryLength?: number;
}

const SearchBar = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { isLoading, onSubmit, placeholder, minQueryLength } = props;
  const [query, setQuery] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>): void => {
    event.preventDefault();
    onSubmit(query);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Paper className={classes.root} onSubmit={handleSubmit}>
        <form className={classes.form}>
          <InputBase
            color="primary"
            className={classes.input}
            onChange={handleChange}
            fullWidth
            placeholder={placeholder}
            inputProps={{ "aria-label": "search" }}
            defaultValue={query}
          />
          <Button
            disabled={minQueryLength ? query.length < minQueryLength : false}
            type="submit"
            color="primary"
            aria-label="search"
            startIcon={<SearchIcon />}
          >
            {intl.formatMessage(messages.searchLabel)}
          </Button>
        </form>
      </Paper>
      {isLoading && <LinearProgress color="secondary" />}
    </>
  );
};

export default SearchBar;
