/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Authentication API
 * An API to use to authenticate users
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateCodeRequest
 */
export interface GenerateCodeRequest {
    /**
     * SessionId created when authenticating user
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    sessionId: string;
    /**
     * UserId to send generated code to
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    userId: string;
}

export function GenerateCodeRequestFromJSON(json: any): GenerateCodeRequest {
    return GenerateCodeRequestFromJSONTyped(json, false);
}

export function GenerateCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'userId': json['userId'],
    };
}

export function GenerateCodeRequestToJSON(value?: GenerateCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'userId': value.userId,
    };
}


