import React from "react";
import { Chip } from "@material-ui/core";
import { useIntl } from "react-intl";
import { UserAuthorityType } from "../../generated/user-api";
import { messages } from "./RoleChip.messages";
import { useStyles } from "./RoleChip.style";

interface Props {
  authorities: UserAuthorityType[];
}

const RoleChip = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { authorities } = props;

  if (
    authorities.includes(UserAuthorityType.BookMeetingOther) &&
    authorities.includes(UserAuthorityType.AppointAdminSite)
  ) {
    return (
      <>
        <Chip
          size="small"
          className={classes.chip}
          label={intl.formatMessage(messages.roleSystemAdmin)}
        />
        <Chip
          size="small"
          className={classes.chip}
          label={intl.formatMessage(messages.roleAdmin)}
        />
      </>
    );
  }

  if (authorities.includes(UserAuthorityType.BookMeetingOther)) {
    return (
      <Chip
        size="small"
        className={classes.chip}
        label={intl.formatMessage(messages.roleAdmin)}
      />
    );
  }

  if (authorities.includes(UserAuthorityType.AppointAdminSite)) {
    return (
      <Chip
        size="small"
        className={classes.chip}
        label={intl.formatMessage(messages.roleSystemAdmin)}
      />
    );
  }

  return <></>;
};

export default RoleChip;
