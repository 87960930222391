import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "dialog.allowMediaGuide.safari.phone.title",
    defaultMessage: "Safari (Allow Camera and Microphone on phone)",
    description: "Title for dialog safari",
  },
  imgAltText: {
    id: "dialog.allowMediaGuide.safari.phone.image.alt.text",
    defaultMessage: "Chrome (Allow Camera and Microphone Guide on phone)",
    description: "Image text",
  },
  step1: {
    id: "dialog.allowMediaGuide.safari.phone.step.1.text",
    defaultMessage: "Start a meeting",
    description: "Step 1",
  },
  step2: {
    id: "dialog.allowMediaGuide.safari.phone.step.2.text",
    defaultMessage:
      "Click on the icon (mark 1, see image below) to display dialog.",
    description: "Step 2",
  },
  step3: {
    id: "dialog.allowMediaGuide.safari.phone.step.3.text",
    defaultMessage:
      "Click in the highlighted setting (mark 2, see image below).",
    description: "Step 3",
  },
  step4: {
    id: "dialog.allowMediaGuide.safari.phone.step.4.text",
    defaultMessage:
      "Allow camera and microphone as in the marking (mark 3, see image below).",
    description: "Step 4",
  },
  step5: {
    id: "dialog.allowMediaGuide.safari.phone.step.5.text",
    defaultMessage:
      "Click the button (mark 4, see image below) to save the settings.",
    description: "Step 5",
  },
  step6: {
    id: "dialog.allowMediaGuide.safari.phone.step.6.text",
    defaultMessage:
      "Click the button (mark 5, see image below) to refresh the page and apply the settings.",
    description: "Step 6",
  },
});
