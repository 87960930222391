import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  createButton: {
    id: "dialog.createMeetingTemplate.button.create",
    defaultMessage: "Create New Booking Template",
    description: "Create label for creating new template",
  },
  submitButtonLabel: {
    id: "dialog.createMeetingTemplate.button.submit",
    defaultMessage: "Create template",
    description: "Submit label for creating new template",
  },
  cancelButtonLabel: {
    id: "dialog.createMeetingTemplate.button.cancel",
    defaultMessage: "Cancel",
    description: "Cancel label for close dialog button",
  },
  success: {
    id: "dialog.createMeetingTemplate.success",
    defaultMessage: "Template was created",
    description: "Success message when meeting has been created",
  },
  generalError: {
    id: "dialog.createMeetingTemplate.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Text shown in snack bar when it fails to create a booking template",
  },
});
