import React from "react";
import { Chip } from "@material-ui/core";
import { useStyles } from "./MeetingSourceChip.style";
import { SourceType } from "../../generated/meeting-api";

interface Props {
  source: SourceType;
}

const MeetingSourceChip = (props: Props) => {
  const classes = useStyles();
  const { source } = props;

  if (source === SourceType.Careplatform) {
    return null;
  }

  return (
    <Chip
      style={{ paddingLeft: 0, height: 17 }}
      size="small"
      className={classes.chip}
      label={source}
    />
  );
};

export default MeetingSourceChip;
