import React from "react";
import {
  Button,
  Dialog,
  Divider,
  Grid,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ToDoResponse } from "../../../../generated/todo-api";
import { messages } from "./ViewTodoDialogDialog.messages";
import { getFormattedDate } from "../../../../Utils/Meeting";
import { useStyles } from "./ViewTodoDialog.style";
import { displayUser } from "../../../../Utils/User";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  todo: ToDoResponse;
}

const ViewTodoDialog = (props: Props) => {
  const { onClose, isOpen, todo } = props;
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{todo.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} justify="flex-start">
          {todo.description && (
            <Grid item xs={12}>
              <Typography className={classes.descriptionContainer}>
                {todo.description}
              </Typography>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <ListItemText
              primary={intl.formatMessage(messages.assignedByLabel)}
              secondary={displayUser(todo.assignedBy, true)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary={intl.formatMessage(messages.assignedToLabel)}
              secondary={displayUser(todo.assignedTo, true)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary={intl.formatMessage(messages.createdOnLabel)}
              secondary={getFormattedDate(todo.createdOn)}
            />
          </Grid>
          {todo.updatedOn && (
            <Grid item xs={12} md={4}>
              <ListItemText
                primary={intl.formatMessage(messages.updatedOnLabel)}
                secondary={getFormattedDate(todo.updatedOn)}
              />
            </Grid>
          )}
          {todo.shouldBeCompletedOn && (
            <Grid item xs={12} md={8}>
              <ListItemText
                primary={intl.formatMessage(messages.shouldBeCompletedOnLabel)}
                secondary={getFormattedDate(todo.shouldBeCompletedOn)}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {intl.formatMessage(messages.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTodoDialog;
