/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Sync API
 * An API to use to trigger sync
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncRequest
 */
export interface SyncRequest {
    /**
     * userId of the patient
     * @type {string}
     * @memberof SyncRequest
     */
    userId: string;
    /**
     * The units to sync
     * @type {Array<string>}
     * @memberof SyncRequest
     */
    units: Array<string>;
}

export function SyncRequestFromJSON(json: any): SyncRequest {
    return SyncRequestFromJSONTyped(json, false);
}

export function SyncRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'units': json['units'],
    };
}

export function SyncRequestToJSON(value?: SyncRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'units': value.units,
    };
}


