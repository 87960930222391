import { defineMessages } from "react-intl";

export const messages = defineMessages({
  // ===== Logo =====
  logoAltText: {
    id: "appbar.logo.alt.text",
    defaultMessage: "Logo",
    description: "logo alt text in app bar",
  },

  // ===== Admin tabs =====
  myPagesTabTitle: {
    id: "appbar.tab.mypages.title",
    defaultMessage: "My pages",
    description: "title for my pages in app bar",
  },
  adminTabTitle: {
    id: "appbar.tab.admin.title",
    defaultMessage: "Admin",
    description: "title for admin in app bar",
  },
  customerSettingsTabTitle: {
    id: "appbar.tab.settings.title",
    defaultMessage: "Settings",
    description: "title for customer settings in app bar",
  },

  // ===== Appbar menu =====
  dashboardTitle: {
    id: "appbar.dashboard.title",
    defaultMessage: "Dashboard",
    description: "title for dashboard in app bar",
  },
  accountTitle: {
    id: "appbar.account.title",
    defaultMessage: "Account",
    description: "title for account in app bar",
  },
  bookingsTitle: {
    id: "appbar.bookings.title",
    defaultMessage: "Bookings",
    description: "title for bookings in app bar",
  },
  clientsTitle: {
    id: "appbar.clients.title",
    defaultMessage: "Clients",
    description: "title for clients in app bar",
  },
  messagesTitle: {
    id: "appbar.messages.title",
    defaultMessage: "Messages",
    description: "title for messages in app bar",
  },
  filesTitle: {
    id: "appbar.files.title",
    defaultMessage: "Files",
    description: "title for files in app bar",
  },

  // ===== Appbar admin menu =====
  adminExternalsTitle: {
    id: "appbar.admin.externals.title",
    defaultMessage: "Externals",
    description: "title for externals under admin in app bar",
  },
  adminPatientsTitle: {
    id: "appbar.admin.patients.title",
    defaultMessage: "Patients",
    description: "title for patients under admin in app bar",
  },
  adminBookingsTitle: {
    id: "appbar.admin.bookings.title",
    defaultMessage: "Bookings",
    description: "title for bookings under admin in app bar",
  },
  adminCaregiversTitle: {
    id: "appbar.admin.caregivers.title",
    defaultMessage: "Caregivers",
    description: "title for caregivers under admin in app bar",
  },
  adminSettingsTitle: {
    id: "appbar.admin.settings.title",
    defaultMessage: "Settings",
    description: "title for settings under admin in app bar",
  },

  // ===== Appbar settings menu =====
  customerFeaturesTab: {
    id: "appbar.settings.features.title",
    defaultMessage: "Features Settings",
    description: "title for features under settings in app bar",
  },
  customerEmailSettingsTab: {
    id: "appbar.settings.email.title",
    defaultMessage: "Email Settings",
    description: "title for email under settings in app bar",
  },
  customerThemeSettingsTab: {
    id: "appbar.settings.theme.title",
    defaultMessage: "Theme Settings",
    description: "title for theme under settings in app bar",
  },
  customerAnonymizeSettingsTab: {
    id: "appbar.settings.anonymize.title",
    defaultMessage: "Anonymize Settings",
    description: "title for anonymize under settings in app bar",
  },
  customerContentSettingsTab: {
    id: "appbar.settings.content.title",
    defaultMessage: "Content Settings",
    description: "title for content under settings in app bar",
  },
  customerAgreementSettingsTab: {
    id: "appbar.settings.agreement.title",
    defaultMessage: "Agreement and policy",
    description: "title for agreement and policy under settings in app bar",
  },

  // ===== Appbar Avatar menu =====
  profileMenuLabel: {
    id: "appbar.avatar.menu.profile.label",
    defaultMessage: "Profile",
    description: "label for profile in appbar avatar menu",
  },
  meetingTemplateMenuLabel: {
    id: "appbar.avatar.menu.meetingtemplate.label",
    defaultMessage: "Booking templates",
    description: "label for booking templates in appbar avatar menu",
  },
  logoutMenuLabel: {
    id: "appbar.avatar.menu.logout.label",
    defaultMessage: "Logout",
    description: "label for logout in appbar avatar menu",
  },
});
