import { defineMessages } from "react-intl";

export const messages = defineMessages({
  userDoesNotExistError: {
    id: "authenticate.userDoesNotExistError",
    defaultMessage: "There is no matching user",
    description:
      "Text shown in snack bar when login failed due to no match in database",
  },
  userDoesNotExistSithsError: {
    id: "authenticate.userDoesNotExistSithsError",
    defaultMessage: "There is no matching user",
    description:
      "Text shown in snack bar when login failed due to no match in database",
  },
});
