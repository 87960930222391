import { defineMessages } from "react-intl";

export const messages = defineMessages({
  roleAdmin: {
    id: "roleChip.role.admin",
    defaultMessage: "Admin",
    description: "Label for Admin role",
  },
  roleSystemAdmin: {
    id: "roleChip.role.systemAdmin",
    defaultMessage: "System Admin",
    description: "Label for System Admin role",
  },
});
