import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { useStyles } from "./LatestVisit.style";
import { messages } from "./LatestVisit.messages";
import {
  MeetingHistoryType,
  MeetingStatusType,
  SortOrderType,
} from "../../../../generated/meeting-api";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  calculateTimeSinceDate,
  getParticipantsAfterRemoval,
} from "../../../../Utils/Meeting";
import BadgeAvatars from "../../../../Components/BadgeAvatar/BadgeAvatar";
import AvatarGroup from "../../../../Components/AvatarGroup/AvatarGroup";
import { meetingService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { Meeting } from "../../../../Models/Meeting";

const LatestVisit = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [latestVisit, setLatestVisit] = useState<Meeting>();
  const filteredParticipants = getParticipantsAfterRemoval(
    latestVisit?.participants || [],
    [authenticatedUser.user?.id || ""]
  );

  const loadLatestVisit = () => {
    if (customerId && authenticatedUser.user?.id) {
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: 1, // only shows one page
          pageSize: 1, // only shows 1 results
          customerId,
          sortOrder: SortOrderType.Descending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Past,
          statuses: [MeetingStatusType.Completed],
        })
        .then((res) =>
          setLatestVisit(
            res.data && res.data.length === 1 ? res.data[0] : undefined
          )
        );
    }
  };

  useEffect(() => {
    loadLatestVisit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!latestVisit) {
    return null;
  }

  const participants = getParticipantsAfterRemoval(
    latestVisit.participants || [],
    [authenticatedUser.user?.id || ""]
  );

  const CustomAvatar = () => {
    if (participants.length > 1) {
      return (
        <>
          <AvatarGroup participants={participants} max={6} center />
          <Typography variant="h6" className={classes.avatarText}>
            {latestVisit?.title}
          </Typography>
        </>
      );
    }

    return (
      <>
        <BadgeAvatars
          center
          size="medium"
          lastName={filteredParticipants[0]?.lastName}
          firstName={filteredParticipants[0]?.firstName}
          participantsForTooltip={participants}
        />
        <Typography variant="h6" className={classes.avatarText}>
          {`${filteredParticipants[0]?.firstName} ${filteredParticipants[0]?.lastName}`}
        </Typography>
      </>
    );
  };

  return (
    <div>
      <CardHeading>
        {intl.formatMessage(messages.title).toUpperCase()}
      </CardHeading>
      <div className={classes.profileContainer}>
        <CustomAvatar />
        <Typography variant="body2" className={classes.avatarText}>
          {latestVisit.expectedEndDateTime &&
            calculateTimeSinceDate(
              latestVisit.expectedEndDateTime,
              intl.locale
            )}
        </Typography>
        {participants.length < 2 && (
          <Typography variant="body2">{latestVisit?.title}</Typography>
        )}
        <Typography variant="h6">
          {`${latestVisit?.duration} ${intl.formatMessage(
            messages.durationUnit
          )}`}
        </Typography>
      </div>
    </div>
  );
};

export default LatestVisit;
