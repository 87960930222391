import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancelButtonLabel: {
    id: "dialog.createUser.button.cancel",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  submitButtonLabel: {
    id: "dialog.createUser.button.submit",
    defaultMessage: "Create",
    description: "label for submit button",
  },
  success: {
    id: "dialog.createUser.success",
    defaultMessage: "User was created",
    description: "Success message when user has been created",
  },
  duplicatePatientError: {
    id: "dialog.createUser.duplicatePatientError",
    defaultMessage: "There is already a patient with this person number",
    description:
      "Text shown in snack bar when it fails to create a patient due to user exists",
  },
  duplicateExternalError: {
    id: "dialog.createUser.duplicateExternalError",
    defaultMessage: "There is already a external user with this person number",
    description:
      "Text shown in snack bar when it fails to create a patient due to user exists",
  },
  duplicateStaffError: {
    id: "dialog.createUser.duplicateStaffError",
    defaultMessage:
      "There is already a caregiver with this person number or HSA_ID",
    description:
      "Text shown in snack bar when it fails to create a patient due to user exists",
  },
  generalError: {
    id: "dialog.createUser.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to create a user",
  },
});
