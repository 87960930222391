/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Invite API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleType,
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateInviteRequest
 */
export interface CreateInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    email: string;
    /**
     * 
     * @type {UserRoleType}
     * @memberof CreateInviteRequest
     */
    userRole: UserRoleType;
}

export function CreateInviteRequestFromJSON(json: any): CreateInviteRequest {
    return CreateInviteRequestFromJSONTyped(json, false);
}

export function CreateInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInviteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'userRole': UserRoleTypeFromJSON(json['userRole']),
    };
}

export function CreateInviteRequestToJSON(value?: CreateInviteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'userRole': UserRoleTypeToJSON(value.userRole),
    };
}


