import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "chat.title",
    defaultMessage: "Chat",
    description: "Heading for chat card in meeting session view",
  },
  generalError: {
    id: "chat.error.could.not.load.chat",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to load chat message",
  },
});
