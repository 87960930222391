import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    IN_PROGRESS: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
    },
    COMPLETED: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.grey.A700,
      backgroundColor: theme.palette.grey.A100,
    },
    CANCELLED: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    },
    CREATED: {
      marginLeft: theme.spacing(1),
      height: 17,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
    },
  })
);
