import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleText: {
    id: "settings.contentText.title",
    defaultMessage: "Title",
    description: "Label title text",
  },
  contentText: {
    id: "settings.contentText.content",
    defaultMessage: "Content",
    description: "Label content text",
  },
  applySettings: {
    id: "settings.contentText.applyChanges",
    defaultMessage: "Save",
    description: "Button for apply changes",
  },
});
