import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "dialog.allowMediaGuide.chrome.desktop.title",
    defaultMessage: "Chrome (Allow Camera and Microphone)",
    description: "Title for dialog chrome",
  },
  imgAltText: {
    id: "dialog.allowMediaGuide.chrome.desktop.image.alt.text",
    defaultMessage: "Chrome (Allow Camera and Microphone Guide)",
    description: "Image text",
  },
  step1: {
    id: "dialog.allowMediaGuide.chrome.desktop.step.1.text",
    defaultMessage: "Start a meeting",
    description: "Step 1",
  },
  step2: {
    id: "dialog.allowMediaGuide.chrome.desktop.step.2.text",
    defaultMessage: "Click on green mark to display dialog.",
    description: "Step 2",
  },
  step3: {
    id: "dialog.allowMediaGuide.chrome.desktop.step.3.text",
    defaultMessage:
      "Click in the red highlighted setting to allow camera/microphone on this page.",
    description: "Step 3",
  },
  step4: {
    id: "dialog.allowMediaGuide.chrome.desktop.step.4.text",
    defaultMessage: "Click the yellow marked button to save the settings.",
    description: "Step 4",
  },
  step5: {
    id: "dialog.allowMediaGuide.chrome.desktop.step.5.text",
    defaultMessage:
      "Click the blue highlighted icon to refresh the page and apply the settings.",
    description: "Step 5",
  },
});
