/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Notes API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotesRequest,
    NotesRequestFromJSON,
    NotesRequestToJSON,
    NotesResponse,
    NotesResponseFromJSON,
    NotesResponseToJSON,
    PaginatedNotesResponseList,
    PaginatedNotesResponseListFromJSON,
    PaginatedNotesResponseListToJSON,
} from '../models';

export interface CreateMeetingNotesRequest {
    customerId: string;
    meetingId: string;
    notesRequest: NotesRequest;
}

export interface ListNotesByMeetingForUserRequest {
    customerId: string;
    meetingId: string;
    page?: number;
    pageSize?: number;
}

export interface UpdateMeetingNotesRequest {
    customerId: string;
    meetingId: string;
    notesId: string;
    notesRequest: NotesRequest;
}

/**
 * 
 */
export class NotesApi extends runtime.BaseAPI {

    /**
     * Requires user to ba a participant of specified meeting (creates meeting notes for signed in userId).
     * Create new private notes for meeting belonging to a user
     */
    async createMeetingNotesRaw(requestParameters: CreateMeetingNotesRequest): Promise<runtime.ApiResponse<NotesResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling createMeetingNotes.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling createMeetingNotes.');
        }

        if (requestParameters.notesRequest === null || requestParameters.notesRequest === undefined) {
            throw new runtime.RequiredError('notesRequest','Required parameter requestParameters.notesRequest was null or undefined when calling createMeetingNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notes/{customerId}/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotesRequestToJSON(requestParameters.notesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotesResponseFromJSON(jsonValue));
    }

    /**
     * Requires user to ba a participant of specified meeting (creates meeting notes for signed in userId).
     * Create new private notes for meeting belonging to a user
     */
    async createMeetingNotes(requestParameters: CreateMeetingNotesRequest): Promise<NotesResponse> {
        const response = await this.createMeetingNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user to ba a participant of specified meeting (userId is used from the signed in one).
     * Get list of private notes for meeting belonging to a user
     */
    async listNotesByMeetingForUserRaw(requestParameters: ListNotesByMeetingForUserRequest): Promise<runtime.ApiResponse<PaginatedNotesResponseList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listNotesByMeetingForUser.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling listNotesByMeetingForUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notes/{customerId}/{meetingId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotesResponseListFromJSON(jsonValue));
    }

    /**
     * Requires user to ba a participant of specified meeting (userId is used from the signed in one).
     * Get list of private notes for meeting belonging to a user
     */
    async listNotesByMeetingForUser(requestParameters: ListNotesByMeetingForUserRequest): Promise<PaginatedNotesResponseList> {
        const response = await this.listNotesByMeetingForUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Requires user to ba a participant of specified meeting (userId is used from the signed in one).
     * Update private notes for meeting belonging to a user
     */
    async updateMeetingNotesRaw(requestParameters: UpdateMeetingNotesRequest): Promise<runtime.ApiResponse<NotesResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateMeetingNotes.');
        }

        if (requestParameters.meetingId === null || requestParameters.meetingId === undefined) {
            throw new runtime.RequiredError('meetingId','Required parameter requestParameters.meetingId was null or undefined when calling updateMeetingNotes.');
        }

        if (requestParameters.notesId === null || requestParameters.notesId === undefined) {
            throw new runtime.RequiredError('notesId','Required parameter requestParameters.notesId was null or undefined when calling updateMeetingNotes.');
        }

        if (requestParameters.notesRequest === null || requestParameters.notesRequest === undefined) {
            throw new runtime.RequiredError('notesRequest','Required parameter requestParameters.notesRequest was null or undefined when calling updateMeetingNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notes/{customerId}/{meetingId}/{notesId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meetingId"}}`, encodeURIComponent(String(requestParameters.meetingId))).replace(`{${"notesId"}}`, encodeURIComponent(String(requestParameters.notesId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotesRequestToJSON(requestParameters.notesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotesResponseFromJSON(jsonValue));
    }

    /**
     * Requires user to ba a participant of specified meeting (userId is used from the signed in one).
     * Update private notes for meeting belonging to a user
     */
    async updateMeetingNotes(requestParameters: UpdateMeetingNotesRequest): Promise<NotesResponse> {
        const response = await this.updateMeetingNotesRaw(requestParameters);
        return await response.value();
    }

}
