import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    currentAgreementChip: {
      justifyContent: "center",
      minWidth: theme.spacing(26),
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },
    gridContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row-reverse",
    },
    typography: {
      marginLeft: theme.spacing(1),
    },
  })
);
