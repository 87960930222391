import React from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useStyles } from "./UserListItemAvatar.style";
import ProfilePicture from "../../../ProfilePicture/ProfilePicture";
import { UserRoleType } from "../../../../generated/contact-api";
import LockedTypography from "../../../Fields/LockedTypography/LockedTypography";
import { userService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { displayUser, formatPersonNumber } from "../../../../Utils/User";
import { hasAnyOfRoles } from "../../../PermissionController/PermissionController";
import { User } from "../../../../Models/User";
import { messages } from "./UserListItemAvatar.messages";

interface Props {
  user: User;
}

const UserListItemAvatar = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { user } = props;
  const { id, firstName, lastName, userRole } = user;

  const loadPersonNumber = (): Promise<string> => {
    return userService()
      .getPersonNumber({ userId: id })
      .then((res) =>
        res.personNumber ? formatPersonNumber(res.personNumber) : ""
      );
  };

  return (
    <div className={classes.root}>
      <ListItemAvatar>
        <ProfilePicture firstName={firstName} lastName={lastName} />
      </ListItemAvatar>
      <div className={classes.container}>
        <Typography variant="h6" color="textPrimary">
          {displayUser(user, true)}
        </Typography>
        {!user.hasPersonNumber ? (
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage(messages.unverifiedLabel)}
          </Typography>
        ) : (
          <>
            {/* If the displayed user has role Staff or External, show this */}
            {hasAnyOfRoles(userRole, [
              UserRoleType.Staff,
              UserRoleType.External,
            ]) && (
              <LockedTypography
                lockedValue="************"
                loadLockedData={loadPersonNumber}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserListItemAvatar;
