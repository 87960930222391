/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Settings API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerFeature,
    CustomerFeatureFromJSON,
    CustomerFeatureToJSON,
    CustomerFeatureResponse,
    CustomerFeatureResponseFromJSON,
    CustomerFeatureResponseToJSON,
    CustomerSettingsResponse,
    CustomerSettingsResponseFromJSON,
    CustomerSettingsResponseToJSON,
    CustomerThemeRequest,
    CustomerThemeRequestFromJSON,
    CustomerThemeRequestToJSON,
    CustomerThemeResponse,
    CustomerThemeResponseFromJSON,
    CustomerThemeResponseToJSON,
    CustomerUnitResponse,
    CustomerUnitResponseFromJSON,
    CustomerUnitResponseToJSON,
    GetCustomerSettingsByHostnameRequest,
    GetCustomerSettingsByHostnameRequestFromJSON,
    GetCustomerSettingsByHostnameRequestToJSON,
    SourceType,
    SourceTypeFromJSON,
    SourceTypeToJSON,
} from '../models';

export interface GetCustomerSettingsByHostnameOperationRequest {
    customerSettingsByHostnameRequest: GetCustomerSettingsByHostnameRequest;
}

export interface GetCustomerThemeRequest {
    customerId: string;
}

export interface ListCustomerFeaturesRequest {
    customerId: string;
}

export interface ListUnitsRequest {
    sourceType: SourceType;
}

export interface UpdateCustomerFeaturesRequest {
    customerId: string;
    customerFeature: Array<CustomerFeature>;
}

export interface UpdateCustomerThemeRequest {
    customerId: string;
    customerTheme: CustomerThemeRequest;
}

/**
 * 
 */
export class CustomerSettingsApi extends runtime.BaseAPI {

    /**
     * Find customer first by matching the whole hostname against custom_domain
     * Find customer using hostname
     */
    async getCustomerSettingsByHostnameRaw(requestParameters: GetCustomerSettingsByHostnameOperationRequest): Promise<runtime.ApiResponse<CustomerSettingsResponse>> {
        if (requestParameters.customerSettingsByHostnameRequest === null || requestParameters.customerSettingsByHostnameRequest === undefined) {
            throw new runtime.RequiredError('customerSettingsByHostnameRequest','Required parameter requestParameters.customerSettingsByHostnameRequest was null or undefined when calling getCustomerSettingsByHostname.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/hostname`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetCustomerSettingsByHostnameRequestToJSON(requestParameters.customerSettingsByHostnameRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Find customer first by matching the whole hostname against custom_domain
     * Find customer using hostname
     */
    async getCustomerSettingsByHostname(requestParameters: GetCustomerSettingsByHostnameOperationRequest): Promise<CustomerSettingsResponse> {
        const response = await this.getCustomerSettingsByHostnameRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get customer theme
     * Get customer theme
     */
    async getCustomerThemeRaw(requestParameters: GetCustomerThemeRequest): Promise<runtime.ApiResponse<CustomerThemeResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerTheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/theme/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerThemeResponseFromJSON(jsonValue));
    }

    /**
     * Get customer theme
     * Get customer theme
     */
    async getCustomerTheme(requestParameters: GetCustomerThemeRequest): Promise<CustomerThemeResponse> {
        const response = await this.getCustomerThemeRaw(requestParameters);
        return await response.value();
    }

    /**
     * List customer features
     * List customer features
     */
    async listCustomerFeaturesRaw(requestParameters: ListCustomerFeaturesRequest): Promise<runtime.ApiResponse<CustomerFeatureResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling listCustomerFeatures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/features/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFeatureResponseFromJSON(jsonValue));
    }

    /**
     * List customer features
     * List customer features
     */
    async listCustomerFeatures(requestParameters: ListCustomerFeaturesRequest): Promise<CustomerFeatureResponse> {
        const response = await this.listCustomerFeaturesRaw(requestParameters);
        return await response.value();
    }

    /**
     * List customer units
     * List customer units
     */
    async listUnitsRaw(requestParameters: ListUnitsRequest): Promise<runtime.ApiResponse<CustomerUnitResponse>> {
        if (requestParameters.sourceType === null || requestParameters.sourceType === undefined) {
            throw new runtime.RequiredError('sourceType','Required parameter requestParameters.sourceType was null or undefined when calling listUnits.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceType !== undefined) {
            queryParameters['sourceType'] = requestParameters.sourceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerUnitResponseFromJSON(jsonValue));
    }

    /**
     * List customer units
     * List customer units
     */
    async listUnits(requestParameters: ListUnitsRequest): Promise<CustomerUnitResponse> {
        const response = await this.listUnitsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update customer features
     * Update customer features
     */
    async updateCustomerFeaturesRaw(requestParameters: UpdateCustomerFeaturesRequest): Promise<runtime.ApiResponse<CustomerFeatureResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomerFeatures.');
        }

        if (requestParameters.customerFeature === null || requestParameters.customerFeature === undefined) {
            throw new runtime.RequiredError('customerFeature','Required parameter requestParameters.customerFeature was null or undefined when calling updateCustomerFeatures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/features/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerFeature.map(CustomerFeatureToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFeatureResponseFromJSON(jsonValue));
    }

    /**
     * Update customer features
     * Update customer features
     */
    async updateCustomerFeatures(requestParameters: UpdateCustomerFeaturesRequest): Promise<CustomerFeatureResponse> {
        const response = await this.updateCustomerFeaturesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update customer theme
     * Update customer theme
     */
    async updateCustomerThemeRaw(requestParameters: UpdateCustomerThemeRequest): Promise<runtime.ApiResponse<CustomerThemeResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling updateCustomerTheme.');
        }

        if (requestParameters.customerTheme === null || requestParameters.customerTheme === undefined) {
            throw new runtime.RequiredError('customerTheme','Required parameter requestParameters.customerTheme was null or undefined when calling updateCustomerTheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/theme/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerThemeRequestToJSON(requestParameters.customerTheme),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerThemeResponseFromJSON(jsonValue));
    }

    /**
     * Update customer theme
     * Update customer theme
     */
    async updateCustomerTheme(requestParameters: UpdateCustomerThemeRequest): Promise<CustomerThemeResponse> {
        const response = await this.updateCustomerThemeRaw(requestParameters);
        return await response.value();
    }

}
