/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttendanceStatusType,
    AttendanceStatusTypeFromJSON,
    AttendanceStatusTypeFromJSONTyped,
    AttendanceStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttendanceStatusRequest
 */
export interface AttendanceStatusRequest {
    /**
     * 
     * @type {AttendanceStatusType}
     * @memberof AttendanceStatusRequest
     */
    attendanceStatus: AttendanceStatusType;
}

export function AttendanceStatusRequestFromJSON(json: any): AttendanceStatusRequest {
    return AttendanceStatusRequestFromJSONTyped(json, false);
}

export function AttendanceStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendanceStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attendanceStatus': AttendanceStatusTypeFromJSON(json['attendanceStatus']),
    };
}

export function AttendanceStatusRequestToJSON(value?: AttendanceStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attendanceStatus': AttendanceStatusTypeToJSON(value.attendanceStatus),
    };
}


