/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Messages API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * attachment details.
 * @export
 * @interface MessageAttachment
 */
export interface MessageAttachment {
    /**
     * The UUID of this attachment
     * @type {string}
     * @memberof MessageAttachment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttachment
     */
    contentType: string;
}

export function MessageAttachmentFromJSON(json: any): MessageAttachment {
    return MessageAttachmentFromJSONTyped(json, false);
}

export function MessageAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'contentType': json['contentType'],
    };
}

export function MessageAttachmentToJSON(value?: MessageAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'contentType': value.contentType,
    };
}


