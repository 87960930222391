/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of the participant (related to user role)
 * @export
 * @enum {string}
 */
export enum MeetingParticipantType {
    Client = 'CLIENT',
    Staff = 'STAFF',
    External = 'EXTERNAL'
}

export function MeetingParticipantTypeFromJSON(json: any): MeetingParticipantType {
    return MeetingParticipantTypeFromJSONTyped(json, false);
}

export function MeetingParticipantTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingParticipantType {
    return json as MeetingParticipantType;
}

export function MeetingParticipantTypeToJSON(value?: MeetingParticipantType | null): any {
    return value as any;
}

