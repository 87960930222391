import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuthenticatedUser } from "../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useStyles } from "./PortalCustomerProfile.style";
import {
  UserAuthorityType,
  UserRoleType,
} from "../../../../../generated/user-api";
import PermissionController, {
  hasPermission,
} from "../../../../../Components/PermissionController/PermissionController";
import { RoutePath } from "../../../../../Components/Routes/RoutePath";

import { useCustomerIsAdminPortal } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import CustomerListItem from "../../../../../Components/CustomerList/Components/CustomerListItem/CustomerListItem";
import { CustomerResponse } from "../../../../../generated/customer-api";
import CreateOrUpdateCustomerButton from "../../../../../Components/Buttons/CreateOrUpdateCustomerButton/CreateOrUpdateCustomerButton";
import { Variant } from "../../../../../Components/Dialogs/CreateOrUpdateCustomerDialog/CreateOrUpdateCustomerDialog";
import ChangeCustomerStatusButton from "../../../../../Components/Buttons/ChangeCustomerStatusButton/ChangeCustomerStatusButton";

export interface Props {
  customerResponse: CustomerResponse;
  loadCustomer: () => void;
}
const PortalCustomerProfile = (props: Props) => {
  const { customerResponse, loadCustomer } = props;
  const classes = useStyles();
  const isCustomerAdminPortal = useCustomerIsAdminPortal();
  const [authenticatedUser] = useAuthenticatedUser();

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    allowedRoles: [UserRoleType.PlatformAdmin],
  });

  useEffect(() => {
    loadCustomer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isCustomerAdminPortal || !hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }

  if (!customerResponse) {
    return null;
  }
  return (
    <div>
      <div className={classes.updateCustomerButton}>
        <CreateOrUpdateCustomerButton
          onClose={loadCustomer}
          initialCustomerValues={customerResponse}
          variant={Variant.UPDATE}
        />
      </div>
      <CustomerListItem customer={customerResponse} />
      <PermissionController
        requiredAuthoritiesPlatformAdmin={[
          UserAuthorityType.ChangeCustomerStatus,
        ]}
      >
        <div className={classes.statusButtonDiv}>
          <ChangeCustomerStatusButton
            onClose={loadCustomer}
            customer={customerResponse}
          />
        </div>
      </PermissionController>
    </div>
  );
};

export default PortalCustomerProfile;
