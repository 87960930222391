import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./DialogCloseButton.style";

interface Props {
  onClose?: () => void;
}

const DialogCloseButton = (props: Props) => {
  const classes = useStyles();
  const { onClose } = props;

  if (!onClose) {
    return null;
  }

  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      className={classes.closeButton}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default DialogCloseButton;
