import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        marginTop: theme.spacing(1.5),
      },
    },
    center: {
      "& > *": {
        margin: "auto",
      },
    },
    small: {
      width: 40,
      height: 40,
      border: `2px solid ${theme.palette.background.paper}`,
    },
    medium: {
      width: 50,
      height: 50,
      border: `2px solid ${theme.palette.background.paper}`,
    },
    large: {
      width: 50,
      height: 50,
      border: `2px solid ${theme.palette.background.paper}`,
    },
    default: {
      width: 30,
      height: 30,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  })
);
