import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeading: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.grey["600"],
    },
    nothingToShowText: {
      marginBottom: theme.spacing(3),
    },
    buttonEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    sharedFileContainer: {
      maxHeight: 350,
      overflowY: "auto",
      marginBottom: theme.spacing(1),
    },
  })
);
