import React from "react";

import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import CustomerFeatures from "../../../Components/CustomerFeatures/CustomerFeatures";

const CustomerFeaturesPage = () => {
  const customerId = useCustomerId();
  return <CustomerFeatures key="thisDomain" customerId={customerId} />;
};

export default CustomerFeaturesPage;
