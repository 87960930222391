import { defineMessages } from "react-intl";
import { Message } from "../../../../Translation/MessageModel";

export const messages: { [key: string]: Message } = defineMessages({
  heading: {
    id: "authenticate.userSelection.heading",
    defaultMessage:
      "You have multiple users, please select which one you want to login with",
    description:
      "Title on landing page when user is authenticated and has multiple roles",
  },
  CLIENT: {
    id: "authenticate.userSelection.button.client",
    defaultMessage: "Client",
    description: "Label to select user client to login with",
  },
  STAFF: {
    id: "authenticate.userSelection.button.staff",
    defaultMessage: "Staff",
    description: "Label to select user staff to login with",
  },
  EXTERNAL: {
    id: "authenticate.userSelection.button.external",
    defaultMessage: "External",
    description: "Label to select user external to login with",
  },
  PLATFORM_ADMIN: {
    id: "authenticate.userSelection.button.platformAdmin",
    defaultMessage: "Platform admin",
    description: "Label to select user platform admin to login with",
  },
});
