import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Button, Grid, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { authenticationService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { messages } from "./SithsLogin.messages";
import { useStyles } from "./SithsLogin.style";
import {
  AuthenticationStartResponse,
  AuthType,
} from "../../../../generated/authentication-api";
import FeatureController from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

interface FormikValues {
  hsaId?: string;
}

interface Props {
  onClick: () => void;
  onCancel: () => void;
  onError: (errorMsg?: string) => void;
}

const SithsLogin = (props: Props) => {
  const { onClick, onCancel, onError } = props;
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [isSelected, setIsSelected] = useState(false);
  const initialValues: FormikValues = {
    hsaId: undefined,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (customerId) {
        authenticationService()
          .startIdpAuthenticationSession({
            customerId,
            authType: AuthType.Siths,
            hsaId: values.hsaId,
          })
          .then((authResponse: AuthenticationStartResponse) => {
            window.location.href = authResponse.redirectUrl;
          })
          .catch(() =>
            enqueueSnackbar(intl.formatMessage(messages.generalError), {
              variant: "error",
            })
          );
      }
    },
  });

  const handleOnClick = () => {
    setIsSelected(true);
    onClick();
  };

  const handleOnCancel = () => {
    setIsSelected(false);
    onCancel();
    formik.setFieldValue("hsaId", undefined);
  };

  useEffect(() => {
    // Remove all current errors if selected
    if (isSelected) {
      onError(undefined);
    }
  }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  if (process.env.REACT_APP_TEST_AUTHENTICATION && !isSelected) {
    return (
      <FeatureController requiredFeature={CustomerFeatureType.SithsLogin}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Button
              onClick={handleOnClick}
              variant="contained"
              className={classes.loginSithsButton}
            >
              {intl.formatMessage(messages.sithsButton)}
            </Button>
          </Grid>
        </Grid>
      </FeatureController>
    );
  }

  if (process.env.REACT_APP_TEST_AUTHENTICATION && isSelected) {
    return (
      <Grid container justify="center">
        <Grid item md={8} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              className={classes.textField}
              id="hsaId"
              placeholder="SE123456789012-1234"
              name="hsaId"
              label={intl.formatMessage(messages.hsaId)}
              value={formik.values.hsaId}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
            />
            <Button variant="contained" type="submit" color="primary">
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
            <Button onClick={handleOnCancel} color="primary">
              {intl.formatMessage(messages.cancelButtonLabel)}
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <FeatureController requiredFeature={CustomerFeatureType.SithsLogin}>
        <Grid item xs={12} className={classes.container}>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            className={classes.loginSithsButton}
          >
            {intl.formatMessage(messages.sithsButton)}
          </Button>
        </Grid>
      </FeatureController>
    </Grid>
  );
};

export default SithsLogin;
