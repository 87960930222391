import React from "react";
import { Badge } from "@material-ui/core";
import PersonIcon from "@mui/icons-material/Person";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  ownerId: string;
  children: React.ReactNode;
}

const FileOwner = (props: Props) => {
  const { ownerId, children } = props;
  const [authenticatedUser] = useAuthenticatedUser();

  if (ownerId === authenticatedUser.user?.id) {
    return (
      <Badge
        color="default"
        badgeContent={<PersonIcon fontSize="inherit" />}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children}
      </Badge>
    );
  }

  return <>{children}</>;
};

export default FileOwner;
