import { List } from "@material-ui/core";
import React from "react";
import { UserAgreementResponse } from "../../generated/customercontent-api";
import ConsentAgreementListItem from "./Components/ConsentAgreementListItem/ConsentAgreementListItem";
import { useStyles } from "./ConsentAgreementList.styles";

export interface Props {
  list: UserAgreementResponse[];
  current: UserAgreementResponse;
}

const ConsentAgreementList = (props: Props) => {
  const { list, current } = props;
  const classes = useStyles();

  if (!list) {
    return null;
  }

  return (
    <List className={classes.list}>
      {list
        .filter((userAgreement) => userAgreement.id !== current.id)
        .map((userAgreement) => (
          <ConsentAgreementListItem userAgreement={userAgreement} />
        ))}
    </List>
  );
};

export default ConsentAgreementList;
