import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TextField as MuiTextField, useTheme } from "@material-ui/core";
import { FormikProps } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import sv from "date-fns/locale/sv";
import { useStyles } from "./DateFields.style";

export enum Type {
  TIME = "HH:mm",
  DATE = "yyyy-MM-dd",
  DATE_TIME = "yyyy-MM-dd HH:mm",
}

interface Props {
  id: string;
  label: string;
  timeLabel?: string;
  autoComplete?: "on" | "off";
  errorMsg?: string;
  disabledFields?: string[];
  formik: FormikProps<any>;
  type: Type;
}

const DateField = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const {
    id,
    label,
    timeLabel,
    formik,
    errorMsg,
    disabledFields,
    autoComplete,
    type,
  } = props;
  const { errors, touched } = formik;

  const handleChange = async (value: Date | [Date, Date] | null) => {
    await formik.setFieldValue(id, value);
    formik.setFieldTouched(id, true);
  };

  useEffect(() => {
    registerLocale("sv", sv);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.datePickerContainer}>
      <DatePicker
        selected={formik.values[id]}
        onChange={handleChange}
        isClearable={false}
        showTimeSelect={type === Type.TIME || type === Type.DATE_TIME}
        dateFormat={
          type === Type.DATE_TIME || type === Type.DATE ? type : undefined
        }
        timeFormat={
          type === Type.DATE_TIME || type === Type.TIME ? Type.TIME : undefined
        }
        locale={intl.locale}
        timeIntervals={5}
        timeCaption={timeLabel}
        popperClassName={classes.calendar}
        disabled={disabledFields?.includes(id)}
        customInput={
          <MuiTextField
            id={id}
            name={id}
            fullWidth
            label={label}
            InputLabelProps={{
              style: { color: theme.palette.text.secondary },
            }}
            autoComplete={autoComplete || "off"}
            variant="outlined"
            error={!!(errors[id] && touched[id])}
            helperText={touched[id] && errors[id] ? errorMsg : null}
            disabled={disabledFields?.includes(id)}
          />
        }
      />
    </div>
  );
};

export default DateField;
