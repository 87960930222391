import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { ChromePicker } from "react-color";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useStyles } from "./ColorPicker.style";

interface Props {
  label: string;
  defaultColor?: string;
  onChange: (color: string) => void;
}

const ColorPicker = (props: Props) => {
  const { label, defaultColor, onChange } = props;
  const [selectedColor, setSelectedColor] = useState(defaultColor);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleChange = (color: string) => {
    setSelectedColor(color);
    onChange(color);
  };

  useEffect(() => {
    setSelectedColor(defaultColor);
  }, [defaultColor]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedColor) {
    return null;
  }

  return (
    <>
      <Typography>{label}</Typography>
      <div className={classes.colorContainer}>
        <div
          style={{ backgroundColor: selectedColor }}
          className={classes.colorPreview}
        />
        <TextField
          disabled={isOpen}
          value={selectedColor}
          className={classes.textField}
          onClick={() => setIsOpen(!isOpen)}
          autoComplete="off"
        />
      </div>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <ChromePicker
            disableAlpha
            color={selectedColor}
            onChange={(color) => handleChange(color.hex)}
            className={classes.colorPicker}
          />
        </ClickAwayListener>
      )}
    </>
  );
};

export default ColorPicker;
