import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dialogTitle: {
    id: "dialog.allowMediaGuide.chrome.phone.title",
    defaultMessage: "Chrome (Allow Camera and Microphone on phone)",
    description: "Title for dialog chrome",
  },
  androidTitle: {
    id: "dialog.allowMediaGuide.chrome.phone.android.title",
    defaultMessage: "Android",
    description: "Title for android section",
  },
  iosTitle: {
    id: "dialog.allowMediaGuide.chrome.phone.ios.title",
    defaultMessage: "IOS",
    description: "Title for IOS section",
  },
  imgAltText: {
    id: "dialog.allowMediaGuide.chrome.phone.image.alt.text",
    defaultMessage:
      "Chrome desktop (Allow Camera and Microphone Guide on phone)",
    description: "Image text",
  },
  step1: {
    id: "dialog.allowMediaGuide.chrome.phone.step.1.text",
    defaultMessage: "Start a meeting",
    description: "Step 1",
  },
  step2: {
    id: "dialog.allowMediaGuide.chrome.phone.step.2.text",
    defaultMessage:
      "Click on the icon (mark 1, see image below) to display dialog.",
    description: "Step 2",
  },
  step3: {
    id: "dialog.allowMediaGuide.chrome.phone.step.3.text",
    defaultMessage:
      "Click in the red highlighted setting (mark 2, see image below).",
    description: "Step 3",
  },
  step4: {
    id: "dialog.allowMediaGuide.chrome.phone.step.4.text",
    defaultMessage:
      "Allow camera and microphone as in the marking (mark 3, see image below).",
    description: "Step 4",
  },
  step5: {
    id: "dialog.allowMediaGuide.chrome.phone.step.5.text",
    defaultMessage:
      "Click the button (mark 4, see image below) to refresh the page and apply the settings.",
    description: "Step 5",
  },
  step1Ios: {
    id: "dialog.allowMediaGuide.chrome.phone.ios.step.1.text",
    defaultMessage: "Go to settings on you phone (mark 1, see image below).",
    description: "Step 1",
  },
  step2Ios: {
    id: "dialog.allowMediaGuide.chrome.phone.ios.step.2.text",
    defaultMessage:
      "Scroll down to Chrome and click on it (mark 2, see image below).",
    description: "Step 2",
  },
  step3Ios: {
    id: "dialog.allowMediaGuide.chrome.phone.ios.step.3.text",
    defaultMessage:
      "Allow camera and microphone as in the marking (mark 3, see image below).",
    description: "Step 3",
  },
});
