import React from "react";
import { RouteProps } from "react-router-dom";
import Login from "../../Views/Login/Login";
import Authentication from "../../Views/Authentication/Authentication";
import ErrorPage from "../../Views/ErrorPage/ErrorPage";
import Dashboard from "../../Views/Dashboard/Dashboard";
import Clients from "../../Views/Clients/Clients";
import Bookings from "../../Views/Bookings/Bookings";
import BookingDetails from "../../Views/BookingDetails/BookingDetails";
import Messages from "../../Views/Messages/Messages";
import Account from "../../Views/Account/Account";
import TestVideo from "../../Views/TestVideo/TestVideo";
import AdminPatients from "../../Views/AdminPatients/AdminPatients";
import AdminBookings from "../../Views/AdminBookings/AdminBookings";
import AdminCaregivers from "../../Views/AdminCaregivers/AdminCaregivers";
import MeetingTemplates from "../../Views/MeetingTemplates/MeetingTemplates";
import CustomerFeaturesPage from "../../Views/CustomerSettings/CustomerFeatures/CustomerFeaturesPage";
import NotificationFeaturesPage from "../../Views/CustomerSettings/NotificationFeatures/NotificationFeaturesPage";
import ThemeSettingsPage from "../../Views/CustomerSettings/ThemeSettings/ThemeSettingsPage";
import AnonymizeFeaturesPage from "../../Views/CustomerSettings/AnonymizeFeatures/AnonymizeFeaturesPage";
import { RoutePath } from "./RoutePath";
import AccountCompletion from "../../Views/AccountCompletion/AccountCompletion";
import Sync from "../../Views/Sync/Sync";
import InviteVerify from "../../Views/InviteVerify/InviteVerify";
import Verification from "../../Views/Verification/Verification";
import AdminExternals from "../../Views/AdminExternals/AdminExternals";
import WebSocketSession from "../../WebSocket/WebSocketSession";
import Files from "../../Views/Files/Files";
import AdminSettings from "../../Views/AdminSettings/AdminSettings";
import SessionVideo from "../../Views/SessionVideo/SessionVideo";
import ContentSettingsPage from "../../Views/CustomerSettings/ContentSettings/ContentSettingsPage";
import ConsentAgreementSettingsPage from "../../Views/CustomerSettings/ConsentAgreementSettings/ConsentAgreementSettingsPage";
import PortalCustomers from "../../Views/AdminPortal/PortalCustomers/PortalCustomers";
import PortalCustomerSettings from "../../Views/AdminPortal/PortalCustomerSettings/PortalCustomerSettings";
import Sign from "../../Views/Sign/Sign";
import WaitingLobby from "../../Views/WaitingLobby/WaitingLobby";

export enum RouteType {
  PUBLIC, // No restricted routes
  PRIVATE, // Combined access for private admin and private customers
  PRIVATE_ADMIN, // Only access for admin portal
  PRIVATE_CUSTOMER, // Only access for customer pages
}

export interface CustomRouteProps extends RouteProps {
  routeType: RouteType;
  path?: string;
}

interface CustomRoutes {
  sync: CustomRouteProps;
  login: CustomRouteProps;
  inviteVerify: CustomRouteProps;
  authentication: CustomRouteProps;
  verification: CustomRouteProps;
  sign: CustomRouteProps;
  errorPage: CustomRouteProps;
  dashboard: CustomRouteProps;
  clients: CustomRouteProps;
  bookings: CustomRouteProps;
  bookingHistory: CustomRouteProps;
  messages: CustomRouteProps;
  files: CustomRouteProps;
  account: CustomRouteProps;
  accountCompletion: CustomRouteProps;
  testVideo: CustomRouteProps;
  adminPatients: CustomRouteProps;
  adminExternals: CustomRouteProps;
  adminBookings: CustomRouteProps;
  adminCaregivers: CustomRouteProps;
  adminSettings: CustomRouteProps;
  portalCustomers: CustomRouteProps;
  portalCustomerSettings: CustomRouteProps;
  waitingLobby: CustomRouteProps;
  sessionVideo: CustomRouteProps;
  meetingTemplates: CustomRouteProps;
  customerEmailSettings: CustomRouteProps;
  customerThemeSettings: CustomRouteProps;
  customerFeaturesSettings: CustomRouteProps;
  customerAnonymizeSettings: CustomRouteProps;
  customerContentSettings: CustomRouteProps;
  customerAgreementSettings: CustomRouteProps;
}

export const ROUTES: CustomRoutes = {
  // =========== PUBLIC ===========
  login: {
    routeType: RouteType.PUBLIC,
    path: RoutePath.LOGIN,
    component: Login,
    exact: true,
  },
  inviteVerify: {
    routeType: RouteType.PUBLIC,
    path: RoutePath.INVITE_VERIFY,
    component: InviteVerify,
    exact: true,
  },
  authentication: {
    routeType: RouteType.PUBLIC,
    path: RoutePath.AUTHENTICATION,
    component: Authentication,
    exact: true,
  },
  verification: {
    routeType: RouteType.PUBLIC,
    path: RoutePath.VERIFICATION,
    component: Verification,
    exact: true,
  },
  sync: {
    routeType: RouteType.PUBLIC,
    path: RoutePath.SYNC,
    component: Sync,
    exact: true,
  },
  errorPage: {
    routeType: RouteType.PUBLIC,
    component: ErrorPage,
    exact: true,
  },

  // =========== PRIVATE ===========
  account: {
    routeType: RouteType.PRIVATE,
    path: RoutePath.ACCOUNT,
    component: Account,
    exact: true,
  },
  customerThemeSettings: {
    routeType: RouteType.PRIVATE,
    path: RoutePath.CUSTOMERS_THEME_SETTINGS,
    component: ThemeSettingsPage,
    exact: true,
  },
  sign: {
    routeType: RouteType.PRIVATE,
    path: RoutePath.SIGN,
    component: Sign,
    exact: true,
  },

  // =========== PRIVATE  CUSTOMER ===========
  dashboard: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.DASHBOARD,
    children: (
      <WebSocketSession>
        <Dashboard />
      </WebSocketSession>
    ),
    exact: true,
  },
  customerFeaturesSettings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.CUSTOMER_FEATURE_SETTINGS,
    component: CustomerFeaturesPage,
    exact: true,
  },
  customerEmailSettings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.CUSTOMER_EMAIL_SETTINGS,
    component: NotificationFeaturesPage,
    exact: true,
  },
  customerAnonymizeSettings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.CUSTOMERS_ANONYMIZE_SETTINGS,
    component: AnonymizeFeaturesPage,
    exact: true,
  },
  customerContentSettings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.CUSTOMERS_CONTENT_SETTINGS,
    component: ContentSettingsPage,
    exact: true,
  },
  customerAgreementSettings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.CUSTOMERS_AGREEMENT_SETTINGS,
    component: ConsentAgreementSettingsPage,
    exact: true,
  },
  clients: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.CLIENTS,
    component: Clients,
    exact: true,
  },
  bookings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.BOOKINGS,
    component: Bookings,
    exact: true,
  },
  bookingHistory: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.BOOKING_HISTORY,
    component: BookingDetails,
    exact: true,
  },
  messages: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.MESSAGES,
    component: Messages,
    exact: true,
  },
  files: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.FILES,
    children: (
      <WebSocketSession>
        <Files />
      </WebSocketSession>
    ),
    exact: true,
  },
  accountCompletion: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.ACCOUNT_COMPLETION,
    component: AccountCompletion,
    exact: true,
  },
  testVideo: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.TEST_VIDEO,
    component: TestVideo,
    exact: true,
  },
  adminPatients: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.ADMIN_PATIENTS,
    component: AdminPatients,
    exact: true,
  },
  adminExternals: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.ADMIN_EXTERNALS,
    component: AdminExternals,
    exact: true,
  },
  adminBookings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.ADMIN_BOOKINGS,
    component: AdminBookings,
    exact: true,
  },
  adminCaregivers: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.ADMIN_CAREGIVERS,
    component: AdminCaregivers,
    exact: true,
  },
  adminSettings: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.ADMIN_SETTINGS,
    component: AdminSettings,
    exact: true,
  },
  sessionVideo: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.SESSION_VIDEO,
    component: SessionVideo,
    exact: true,
  },
  waitingLobby: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.WAITING_LOBBY,
    children: (
      <WebSocketSession>
        <WaitingLobby />
      </WebSocketSession>
    ),
    exact: true,
  },
  meetingTemplates: {
    routeType: RouteType.PRIVATE_CUSTOMER,
    path: RoutePath.MEETING_TEMPLATES,
    component: MeetingTemplates,
    exact: true,
  },

  // =========== PRIVATE ADMIN ===========
  portalCustomers: {
    routeType: RouteType.PRIVATE_ADMIN,
    path: RoutePath.PORTAL_CUSTOMERS,
    component: PortalCustomers,
    exact: true,
  },
  portalCustomerSettings: {
    routeType: RouteType.PRIVATE_ADMIN,
    path: RoutePath.PORTAL_CUSTOMER_SETTINGS,
    component: PortalCustomerSettings,
    exact: false,
  },
};
