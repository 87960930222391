import React from "react";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import ContentSettings from "../../../Components/ContentSettings/ContentSettings";

const ContentSettingsPage = () => {
  const customerId = useCustomerId();
  return <ContentSettings key="thisDomain" customerId={customerId} />;
};

export default ContentSettingsPage;
