/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Customer Content API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAgreementRequest
 */
export interface UserAgreementRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAgreementRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgreementRequest
     */
    contents?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserAgreementRequest
     */
    validFrom: Date;
}

export function UserAgreementRequestFromJSON(json: any): UserAgreementRequest {
    return UserAgreementRequestFromJSONTyped(json, false);
}

export function UserAgreementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAgreementRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'contents': !exists(json, 'contents') ? undefined : json['contents'],
        'validFrom': (new Date(json['validFrom'])),
    };
}

export function UserAgreementRequestToJSON(value?: UserAgreementRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'contents': value.contents,
        'validFrom': (value.validFrom.toISOString().substr(0,10)),
    };
}


