import React from "react";
import {
  CustomerUserAgreementType,
  PaginatedAgreementList,
  UserAgreementResponse,
} from "../../../../generated/customercontent-api";
import CustomPagination from "../../../CustomPagination/Pagination";
import ConsentAgreementList from "../../../ConsentAgreementList/ConsentAgreementList";
import CurrentConsentAgreement from "../CurrentConsentAgreement/CurrentConsentAgreement";
import { useStyles } from "./ConsentAgreementTabComponent.styles";

export interface Props {
  list: PaginatedAgreementList | undefined;
  current: UserAgreementResponse | undefined;
  page: number;
  onPaginationChange: (page: number) => void;
}

export default function ConsentAgreementTabComponent(props: Props) {
  const { list, current, page, onPaginationChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.middlePaper}>
      {list?.data && current && (
        <>
          {page === 1 && (
            <CurrentConsentAgreement current={current} key={current.id} />
          )}
          <ConsentAgreementList
            list={list?.data}
            current={current}
            key={CustomerUserAgreementType.ConsentAgreement}
          />
        </>
      )}
      <CustomPagination
        onChange={onPaginationChange}
        pagination={list?.pagination}
      />
    </div>
  );
}
