import React, { useEffect, useState } from "react";
import { useFormik, FormikProps } from "formik";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Prompt } from "react-router-dom";
import { useSnackbar } from "notistack";
import { messages } from "./AccountSettingsForm.messages";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import {
  UpdateUserRequest,
  UserResponse,
} from "../../../../generated/user-api";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import UserFields from "../../../../Components/UserForm/Components/UserFields/UserFields";
import { useStyles } from "./AccountSettingsForm.style";
import { userService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import UpdateUserValidationSchema from "../../../../Components/UserForm/Components/Validation/UpdateUserValidationSchema";
import { User } from "../../../../Models/User";
import { emptyStringToUndefined, formatHSAID } from "../../../../Utils/Format";

const PromptIfDirty = ({
  formik,
  message,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  message: string;
}) => {
  return <Prompt when={formik.dirty} message={message} />;
};

interface Props {
  user: UserResponse;
  onUpdated?: () => void;
}

const mapUserObject = (user: UpdateUserRequest) => {
  return {
    ...user,
    email: emptyStringToUndefined(user.email),
    workTitle: emptyStringToUndefined(user.workTitle),
    mobilePhoneNumber: emptyStringToUndefined(user.mobilePhoneNumber),
    additionalInformation: emptyStringToUndefined(user.additionalInformation),
  } as UpdateUserRequest;
};

const AccountSettingsForm = (props: Props) => {
  const { user, onUpdated } = props;
  const [authenticatedUser, setAuthenticatedUser] = useAuthenticatedUser();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const classes = useStyles();
  const [hsaId, setHsaId] = useState<string>();
  const [duplicateHsaIdError, setDuplicateHsaIdError] = useState(false);
  const [personNumber, setPersonNumber] = useState<string>();

  const loadUserHsaId = () => {
    if (authenticatedUser.user?.id) {
      userService()
        .getHsaId({ userId: authenticatedUser.user?.id })
        .then((res) => setHsaId(res.hsaId ? res.hsaId : ""));
    }
  };

  const loadUserPersonNumber = () => {
    if (authenticatedUser.user?.id) {
      userService()
        .getPersonNumber({ userId: authenticatedUser.user?.id })
        .then((res) =>
          setPersonNumber(res.personNumber ? res.personNumber : "")
        );
    }
  };

  const initialValues: User = {
    ...user,
    hsaId,
    personNumber,
  };

  const updateUser = (formik: FormikProps<User>, values: User) => {
    if (customerId && authenticatedUser.user?.id) {
      userService()
        .updateUser({
          customerId,
          userId: authenticatedUser.user?.id,
          body: mapUserObject({ ...values }),
        })
        .then((result) => {
          setAuthenticatedUser({
            ...authenticatedUser,
            user: result,
            isComplete: true,
          });
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          });
          if (onUpdated) {
            onUpdated();
          }
          // This line is crucial for Prompt to behave correctly: https://formik.org/docs/api/formik#resetform-nextstate-partialformikstatevalues--void
          formik.resetForm({ values: formik.values });
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UpdateUserValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (customerId && authenticatedUser.user?.id) {
        if (values.hsaId !== undefined && hsaId !== values.hsaId) {
          userService()
            .updateHsaId({
              userId: authenticatedUser.user?.id,
              updateUserHsaIdRequest: {
                hsaId: formatHSAID(emptyStringToUndefined(values.hsaId)),
              },
            })
            .then((res) => setHsaId(res.hsaId || ""))
            .then(() => updateUser(formik, values))
            .catch((error) => {
              if (error.status === 409) {
                setDuplicateHsaIdError(true);
              }
            });
        } else {
          updateUser(formik, values);
        }
      }
    },
  });

  useEffect(() => {
    if (!formik.errors.hsaId) {
      setDuplicateHsaIdError(false);
    }
  }, [formik.errors.hsaId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <PromptIfDirty
          formik={formik}
          message={intl.formatMessage(messages.beforeLeave)}
        />
        <UserFields
          formik={formik}
          userRole={user.userRole}
          useHsaIdLock
          loadHsaId={loadUserHsaId}
          duplicateHsaIdError={duplicateHsaIdError}
          usePersonNumberLock
          loadPersonNumber={loadUserPersonNumber}
          disabledFields={["personNumber"]}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          fullWidth
        >
          {intl.formatMessage(messages.submitButton)}
        </Button>
      </form>
    </>
  );
};

export default AccountSettingsForm;
