import { makeStyles, Theme } from "@material-ui/core/styles";
import { alpha } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  rootContainer: {
    display: "flex",
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  rowContainer: {
    flexGrow: 1,
    position: "relative",
  },
  columnContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
  },
  topBarContainer: {
    position: "absolute",
    display: "flex",
    flexGrow: 1,
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    color: theme.palette.getContrastText(
      alpha(theme.palette.common.black, 0.5)
    ),
    height: 30,
    width: "100%",
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.down("xs")]: {
      height: 50,
    },
  },
  roomContainer: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  publisherContainer: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: theme.spacing(2),
    overflow: "hidden",
    height: 198,
    width: 264,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      bottom: theme.spacing(9),
      height: 150,
      width: 100,
    },
  },
  bigPublisherContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    maxWidth: "150vh",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(1),
  },
  errorContainer: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  screenSharingContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 9,
  },
  screenSharingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    color: theme.palette.primary.contrastText,
  },
  alert: {
    borderRadius: 0,
    zIndex: theme.zIndex.tooltip,
  },
}));
