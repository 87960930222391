import { useEffect } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import {
  useAuthenticatedUser,
  useLogout,
} from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { getSessionStorageItem, Key } from "../../Utils/SessionStorage";
import { userService } from "../../Providers/ServiceProvider/ServiceProvider";
import { VerifyPersonNumberResponse } from "../../generated/user-api";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { messages } from "./Verification.messages";

const Verification = () => {
  const [grandidsession] = useQueryParam("grandidsession", StringParam);
  const intl = useIntl();
  const history = useHistory();
  const customerId = useCustomerId();
  const logout = useLogout(true);
  const [, setAuthenticatedUser] = useAuthenticatedUser();
  const { enqueueSnackbar } = useSnackbar();

  const initializeAuthenticatedUser = (user: VerifyPersonNumberResponse) => {
    if (customerId) {
      userService()
        .getUserById({ customerId, userId: user.userId })
        .then((userRs) => {
          setAuthenticatedUser({
            token: getSessionStorageItem(Key.JWT_TOKEN),
            isComplete: false,
            user: userRs,
          });
        })
        .then(() => history.push(RoutePath.DASHBOARD));
    }
  };

  const checkSession = (sessionId: string): void => {
    userService()
      .checkVerifyPersonNumberIdpSession({
        sessionId,
      })
      .then((res) => {
        initializeAuthenticatedUser(res);
      })
      .catch((error) => {
        if (error.status === 409) {
          enqueueSnackbar(intl.formatMessage(messages.duplicateUserError), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(intl.formatMessage(messages.generalError), {
            variant: "error",
          });
        }
        logout();
      });
  };

  useEffect(() => {
    if (grandidsession && getSessionStorageItem(Key.JWT_TOKEN)) {
      checkSession(grandidsession);
    } else {
      history.push(RoutePath.LOGIN);
    }
  }, [grandidsession]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Verification;
