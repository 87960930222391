import { useIntl } from "react-intl";
import { messages } from "../../Views/CustomerSettings/CustomerSettings.messages";
import { ContentItemType } from "../../generated/customercontent-api";

interface ContentItemsConfig {
  label: string;
  type: ContentItemType;
  hasTitle: boolean;
}

export const FeaturesTextAndButtonsConfig = (): ContentItemsConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.contentWelcomeText),
      type: ContentItemType.WelcomeText,
      hasTitle: true,
    },
    {
      label: intl.formatMessage(messages.contentNoticeText),
      type: ContentItemType.NoticeText,
      hasTitle: false,
    },
    {
      label: intl.formatMessage(messages.contentLinkButton),
      type: ContentItemType.LinkButton,
      hasTitle: true,
    },
    {
      label: intl.formatMessage(messages.contentFeedbackButton),
      type: ContentItemType.FeedbackButton,
      hasTitle: false,
    },
  ];
};

export const FeaturesContactDetailsConfig = (): ContentItemsConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.contentContactDetailsPhone),
      type: ContentItemType.ContactDetailsPhone,
      hasTitle: false,
    },
    {
      label: intl.formatMessage(messages.contentContactDetailsEmail),
      type: ContentItemType.ContactDetailsEmail,
      hasTitle: false,
    },
    {
      label: intl.formatMessage(messages.contentContactDetailsOpeningHours),
      type: ContentItemType.ContactDetailsOpeningHours,
      hasTitle: false,
    },
  ];
};

export const FeaturesTechSupportConfig = (): ContentItemsConfig[] => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage(messages.contentTechnicalSupportPhone),
      type: ContentItemType.TechnicalSupportPhone,
      hasTitle: false,
    },
    {
      label: intl.formatMessage(messages.contentTechnicalSupportEmail),
      type: ContentItemType.TechnicalSupportEmail,
      hasTitle: false,
    },
    {
      label: intl.formatMessage(messages.contentTechnicalSupportOpeningHours),
      type: ContentItemType.TechnicalSupportOpeningHours,
      hasTitle: false,
    },
  ];
};
