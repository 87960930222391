import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MeetingTemplates: {
      padding: theme.spacing(0.3),
      color: theme.palette.grey.A400,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(1),
    },
    actionContainer: {
      marginTop: theme.spacing(1),
      width: "100%",
      textAlign: "right",
    },
  })
);
