import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";

interface Props {
  open: boolean;
  showCloseCross?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  title: string;
  content?: string;
  submitLabel?: string;
  cancelLabel?: string;
}

const AlertDialog = (props: Props) => {
  const {
    open,
    showCloseCross,
    onClose,
    onSubmit,
    title,
    content,
    cancelLabel,
    submitLabel,
  } = props;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      {showCloseCross && onClose && <DialogCloseButton onClose={onClose} />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {onSubmit && (
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {submitLabel}
          </Button>
        )}
        {onClose && (
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
