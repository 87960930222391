import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import {
  UserAuthorityType,
  UserResponse,
  UserRoleType,
} from "../../../../generated/user-api";
import PermissionController, {
  hasPermission,
} from "../../../PermissionController/PermissionController";
import { messages } from "./AuthorityFields.messages";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import Tooltip from "../../../Tooltip/Tooltip";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  userRole: UserRoleType;
  user?: UserResponse;
}

const AuthorityFields = (props: Props) => {
  const { formik, userRole, user } = props;
  const [authenticatedUser] = useAuthenticatedUser();
  const intl = useIntl();

  const caregiverTooltip = [
    intl.formatMessage(messages.bookMeetingTooltip),
    intl.formatMessage(messages.addPatientWhenBookMeetingTooltip),
    intl.formatMessage(messages.sendMessageTooltip),
  ];
  const adminTooltip = [
    intl.formatMessage(messages.bookMeetingOtherTooltip),
    intl.formatMessage(messages.addPatientTooltip),
    intl.formatMessage(messages.addCaregiverTooltip),
    intl.formatMessage(messages.viewUpcomingBookingsTooltip),
    intl.formatMessage(messages.sendMessageTooltip),
  ];
  const systemAdminTooltip = [
    intl.formatMessage(messages.addCaregiverTooltip),
    intl.formatMessage(messages.addAdminTooltip),
    intl.formatMessage(messages.addSystemAdminTooltip),
    intl.formatMessage(messages.viewUpcomingBookingsTooltip),
    intl.formatMessage(messages.sendMessageTooltip),
  ];

  const mapTooltip = (array: string[]) => {
    return (
      <ul>
        {array.map((t) => (
          <li>{t}</li>
        ))}
      </ul>
    );
  };

  const hasCaregiverAuth = (): boolean => {
    if (
      user?.userAuthorities?.includes(UserAuthorityType.AttendMeeting) &&
      user?.userAuthorities?.includes(UserAuthorityType.BookMeeting) &&
      user?.userAuthorities?.includes(UserAuthorityType.CreateUserClient) &&
      user?.userAuthorities?.includes(UserAuthorityType.SendMessage)
    ) {
      return true;
    }
    return false;
  };

  const hasAdminAuth = (): boolean => {
    if (
      user?.userAuthorities?.includes(UserAuthorityType.BookMeetingOther) &&
      user?.userAuthorities?.includes(UserAuthorityType.CreateUserClient) &&
      user?.userAuthorities?.includes(UserAuthorityType.CreateUserStaff) &&
      user?.userAuthorities?.includes(UserAuthorityType.SendMessage) &&
      user?.userAuthorities?.includes(UserAuthorityType.AdminSite)
    ) {
      return true;
    }
    return false;
  };

  const hasSystemAdminAuth = (): boolean => {
    if (
      user?.userAuthorities?.includes(UserAuthorityType.CreateUserStaff) &&
      user?.userAuthorities?.includes(UserAuthorityType.AdminSite) &&
      user?.userAuthorities?.includes(UserAuthorityType.SendMessage) &&
      user?.userAuthorities?.includes(UserAuthorityType.AppointAdminSite)
    ) {
      return true;
    }
    return false;
  };

  const hasCustomerSettingsAuth = (): boolean => {
    if (user?.userAuthorities?.includes(UserAuthorityType.CustomerSettings)) {
      return true;
    }
    return false;
  };

  const formatInitialState = () => {
    // If user is defined (in modify view), get current settings
    if (user && userRole === UserRoleType.Staff) {
      return {
        external: false,
        patient: false,
        caregiver: hasCaregiverAuth(),
        administrator: hasAdminAuth(),
        systemAdministrator: hasSystemAdminAuth(),
        customerSettings: hasCustomerSettingsAuth(),
      };
    }
    return {
      external: userRole === UserRoleType.External,
      patient: userRole === UserRoleType.Client,
      caregiver: false,
      administrator: false,
      systemAdministrator: false,
      customerSettings: false,
    };
  };

  const [state, setState] = useState(formatInitialState());

  const formatInputToAuthorityList = () => {
    const list = [];
    if (state.patient || state.external) {
      list.push(UserAuthorityType.AttendMeeting);
      list.push(UserAuthorityType.SendMessage);
    }
    if (state.caregiver) {
      // "vanlig läkare"
      list.push(UserAuthorityType.AttendMeeting);
      list.push(UserAuthorityType.BookMeeting);
      list.push(UserAuthorityType.CreateUserClient);
      list.push(UserAuthorityType.SendMessage);
    }
    if (state.administrator) {
      // motsvarar medicinsk sekreterare / receptionist
      list.push(UserAuthorityType.BookMeetingOther);
      list.push(UserAuthorityType.CreateUserClient);
      list.push(UserAuthorityType.CreateUserStaff);
      list.push(UserAuthorityType.SendMessage);
      list.push(UserAuthorityType.AdminSite);
    }
    if (state.systemAdministrator) {
      // typiskt "IT-admin" på vårdcentralen
      list.push(UserAuthorityType.CreateUserStaff);
      list.push(UserAuthorityType.AdminSite);
      list.push(UserAuthorityType.SendMessage);
      list.push(UserAuthorityType.AppointAdminSite);
    }
    if (state.customerSettings) {
      list.push(UserAuthorityType.CustomerSettings);
    }

    if (
      user?.userAuthorities?.includes(UserAuthorityType.CreateUserPlatformAdmin)
    ) {
      // if admin has CreateUserPlatformAdmin auth, keep it.
      list.push(UserAuthorityType.CreateUserPlatformAdmin);
    }
    const noDuplicates = Array.from(new Set(list));
    formik.setFieldValue("userAuthorities", noDuplicates);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    // If a user creates a staff and does not have authority to create admin, always assign caregiver authorities
    if (
      !state.caregiver &&
      userRole === UserRoleType.Staff &&
      !hasPermission({
        currentUser: authenticatedUser.user,
        requiredAuthoritiesStaff: [UserAuthorityType.AppointAdminSite],
      })
    ) {
      setState({ ...state, caregiver: true });
    }
    formatInputToAuthorityList();
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PermissionController
        allowedRoles={[UserRoleType.Staff, UserRoleType.PlatformAdmin]}
        requiredAuthoritiesStaff={[UserAuthorityType.AppointAdminSite]}
      >
        {userRole === UserRoleType.Staff && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip title={mapTooltip(caregiverTooltip)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.caregiver}
                      onChange={handleChange}
                      name="caregiver"
                    />
                  }
                  label={intl.formatMessage(messages.caregiver)}
                />
              </Tooltip>
              <Tooltip title={mapTooltip(adminTooltip)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.administrator}
                      onChange={handleChange}
                      name="administrator"
                    />
                  }
                  label={intl.formatMessage(messages.admin)}
                />
              </Tooltip>
              <Tooltip title={mapTooltip(systemAdminTooltip)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.systemAdministrator}
                      onChange={handleChange}
                      name="systemAdministrator"
                    />
                  }
                  label={intl.formatMessage(messages.systemAdmin)}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </PermissionController>
      <PermissionController allowedRoles={[UserRoleType.PlatformAdmin]}>
        {userRole === UserRoleType.Staff && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip
                title={mapTooltip([
                  intl.formatMessage(messages.customerSettingsTooltip),
                ])}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.customerSettings}
                      onChange={handleChange}
                      name="customerSettings"
                    />
                  }
                  label={intl.formatMessage(messages.customerSettings)}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </PermissionController>
    </>
  );
};

export default AuthorityFields;
