import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dialog.createMessage.title",
    defaultMessage: "Create message",
    description: "dialog title",
  },
  submitButtonLabel: {
    id: "dialog.createMessage.button.submit",
    defaultMessage: "Send",
    description: "label for submit button",
  },
  cancelButtonLabel: {
    id: "dialog.createMessage.button.cancel",
    defaultMessage: "Cancel",
    description: "label for cancel button",
  },
  success: {
    id: "dialog.createMessage.success",
    defaultMessage: "Message was sent",
    description: "Success message when message has been sent",
  },
  error: {
    id: "dialog.createMessage.generalError",
    defaultMessage: "Something went wrong",
    description: "Text shown in snack bar when it fails to send message",
  },
});
