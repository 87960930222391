import React from "react";
import { Paper } from "@material-ui/core";
import { useIntl } from "react-intl";
import MessageList from "./Components/MessageList/MessageList";
import { PaginatedMessageThreadResponseList } from "../../../../generated/message-api";
import CustomPagination from "../../../../Components/CustomPagination/Pagination";
import SearchBar from "../../../../Components/SearchBar/SearchBar";
import { messages } from "../../Messages.messages";

interface Props {
  selectedId?: string;
  onSelected?: (messageId: string) => void;
  onSearch: (query: string) => void;
  onPaginationChange: (page: number) => void;
  paginatedMessageThreadResponseList?: PaginatedMessageThreadResponseList;
}

const MessageListCard = (props: Props) => {
  const intl = useIntl();
  const {
    onSearch,
    selectedId,
    paginatedMessageThreadResponseList,
    onSelected,
    onPaginationChange,
  } = props;

  return (
    <>
      <SearchBar
        placeholder={intl.formatMessage(messages.searchLabel)}
        onSubmit={onSearch}
      />
      <Paper>
        <MessageList
          selectedId={selectedId}
          onSelected={onSelected}
          messageThreads={paginatedMessageThreadResponseList?.data}
        />
        <CustomPagination
          onChange={onPaginationChange}
          pagination={paginatedMessageThreadResponseList?.pagination}
        />
      </Paper>
    </>
  );
};

export default MessageListCard;
