import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customerSettingsTab: {
    id: "settings.contentTab",
    defaultMessage: "Content Settings",
    description: "label for customer content settings",
  },
  customerContentTextAndButtonsCategory: {
    id: "settings.contentTab.textAndButtonsCategory",
    defaultMessage: "Text and buttons",
    description: "label for customer text and buttons content",
  },
  customerContentTechSupportCategory: {
    id: "settings.contentTab.techSupportCategory",
    defaultMessage: "Technical support",
    description: "label for customer content tech support contact details",
  },
  customerContentContactCategory: {
    id: "settings.contentTab.contactDetailsCategory",
    defaultMessage: "Contact details",
    description: "label for customer contact details",
  },
  successMessage: {
    id: "save.content.successSave",
    defaultMessage: "Content saved",
    description: "Snackbar displayed when saving features success",
  },
  getReadData: {
    id: "save.settings.generalError",
    defaultMessage: "Something went wrong",
    description:
      "Snackbar displayed when general error occurred for saving settings",
  },
});
