import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useStyles } from "./Image.styles";
import DefaultLogo from "../../../../Assets/Careplatform_logo.png";
import { messages } from "./Image.messages";

export enum Type {
  LOGO = "LOGO",
  FAVICON = "FAVICON",
}

interface Props {
  type: Type;
  currentSrc?: string;
  maxSizeInKb: number;
  onUploaded: (data: string) => void;
}

const Image = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const { currentSrc, type, maxSizeInKb, onUploaded } = props;
  const [src, setSrc] = useState(currentSrc);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const reloadSrc = (event: any) => {
    event.currentTarget.setAttribute("src", src);
  };

  const bytesToKilobytes = (bytes: number): number => {
    return bytes / 1024;
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // @ts-ignore
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length) {
      if (bytesToKilobytes(files[0].size) > maxSizeInKb) {
        enqueueSnackbar(
          intl.formatMessage(messages.exceedSize, { maxSize: maxSizeInKb }),
          {
            variant: "error",
          }
        );
        return;
      }
      getBase64(files[0]).then((data: string) => {
        const formattedData = data.replace(data.split(",")[0], "").substring(1);
        setSrc(formattedData);
        onUploaded(formattedData);
      });
    }
  };

  useEffect(() => {
    setSrc(currentSrc);
  }, [currentSrc]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <img
        className={type === Type.LOGO ? classes.logo : classes.icon}
        src={src ? `data:;base64,${src}` : DefaultLogo}
        alt={type === Type.LOGO ? "Logo" : "Icon"}
        onClick={handleClick}
        aria-hidden="true"
        onError={reloadSrc}
      />
      <input
        className={classes.hiddenInput}
        accept={type === Type.LOGO ? ".png" : ".png,.ico"}
        ref={inputRef}
        onChange={handleUpload}
        type="file"
      />
    </>
  );
};

export default Image;
