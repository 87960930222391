/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Sync API
 * An API to use to trigger sync
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jonas.malik@magello.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncResponse
 */
export interface SyncResponse {
    /**
     * Number of successfully canceled bookings
     * @type {number}
     * @memberof SyncResponse
     */
    canceled: number;
    /**
     * Number of successfully created bookings
     * @type {number}
     * @memberof SyncResponse
     */
    created: number;
}

export function SyncResponseFromJSON(json: any): SyncResponse {
    return SyncResponseFromJSONTyped(json, false);
}

export function SyncResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canceled': json['canceled'],
        'created': json['created'],
    };
}

export function SyncResponseToJSON(value?: SyncResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canceled': value.canceled,
        'created': value.created,
    };
}


