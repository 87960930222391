/* tslint:disable */
/* eslint-disable */
/**
 * Careplatform Meeting Template API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: bjorn.naeslund@r2m.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingTemplateKindType,
    MeetingTemplateKindTypeFromJSON,
    MeetingTemplateKindTypeFromJSONTyped,
    MeetingTemplateKindTypeToJSON,
} from './';

/**
 * A template for a meeting
 * @export
 * @interface MeetingTemplateResponse
 */
export interface MeetingTemplateResponse {
    /**
     * The UUID of this meeting template.
     * @type {string}
     * @memberof MeetingTemplateResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingTemplateResponse
     */
    title: string;
    /**
     * Meeting lenght in minutes
     * @type {number}
     * @memberof MeetingTemplateResponse
     */
    duration: number;
    /**
     * 
     * @type {MeetingTemplateKindType}
     * @memberof MeetingTemplateResponse
     */
    type: MeetingTemplateKindType;
    /**
     * Date and time this meeting template was created
     * @type {Date}
     * @memberof MeetingTemplateResponse
     */
    updatedOn?: Date;
    /**
     * Date and time this meeting template was created
     * @type {Date}
     * @memberof MeetingTemplateResponse
     */
    createdOn: Date;
}

export function MeetingTemplateResponseFromJSON(json: any): MeetingTemplateResponse {
    return MeetingTemplateResponseFromJSONTyped(json, false);
}

export function MeetingTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingTemplateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'duration': json['duration'],
        'type': MeetingTemplateKindTypeFromJSON(json['type']),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function MeetingTemplateResponseToJSON(value?: MeetingTemplateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'duration': value.duration,
        'type': MeetingTemplateKindTypeToJSON(value.type),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
        'createdOn': (value.createdOn.toISOString()),
    };
}


