import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./ContentText.styles";
import {
  ContentItemType,
  ContentItemTypeRequest,
  ContentItemTypeResponse,
} from "../../../generated/customercontent-api";
import { messages } from "./ContentText.messages";

export interface Props {
  label: string;
  itemType: ContentItemType;
  hasTitle: boolean;
  contentItemTypeResponse?: ContentItemTypeResponse;
  onChanged: (contentItemTypeRequest: ContentItemTypeRequest) => void;
}

const ContentText = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    label,
    contentItemTypeResponse,
    itemType,
    hasTitle,
    onChanged,
  } = props;
  const [itemTypeTitle, setItemTypeTitle] = useState(
    contentItemTypeResponse?.title
  );
  const [itemTypeContent, setItemTypeContent] = useState(
    contentItemTypeResponse?.contents
  );

  const handleSaveButton = () => {
    onChanged({
      title: itemTypeTitle || "",
      contents: itemTypeContent || "",
      type: itemType,
    } as ContentItemTypeRequest);
  };

  return (
    <Grid container alignItems="center">
      <Grid item sm={3} xs={12}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item sm={7} xs={12}>
        {hasTitle && (
          <TextField
            value={itemTypeTitle}
            type="string"
            fullWidth
            multiline
            inputProps={{ maxLength: 255 }}
            onChange={(e) => setItemTypeTitle(e.target.value)}
            variant="outlined"
            label={intl.formatMessage(messages.titleText)}
            autoComplete="off"
            className={classes.textContainer}
          />
        )}
        <TextField
          value={itemTypeContent}
          type="string"
          fullWidth
          multiline
          onChange={(e) => setItemTypeContent(e.target.value)}
          variant="outlined"
          label={intl.formatMessage(messages.contentText)}
          autoComplete="off"
          className={classes.textContainer}
        />
      </Grid>
      <Grid item sm={2} xs={12} className={classes.buttonGrid}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleSaveButton}
          className={classes.margin}
        >
          {intl.formatMessage(messages.applySettings)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContentText;
