import React from "react";
import ListItem from "@material-ui/core/ListItem";
import {
  Grid,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Avatar from "@material-ui/core/Avatar";
import { FileMetadata } from "../../../../generated/file-api";
import { calculateTimeSinceDate } from "../../../../Utils/Meeting";
import FileActionButton from "../../../Buttons/FileActionButton/FileActionButton";
import { fileService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useStyles } from "./FileListItem.style";
import { messages } from "./FileListItem.messages";
import { displayUser, getFileUsersAfterRemoval } from "../../../../Utils/User";
import FileOwner from "../FileOwner/FileOwner";
import AvatarGroup from "../../../AvatarGroup/AvatarGroup";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  file: FileMetadata;
  onDownloaded?: () => void;
  onDeleted?: () => void;
}

const FileListItem = (props: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const { file, onDownloaded, onDeleted } = props;
  const [authenticatedUser] = useAuthenticatedUser();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const openInNewTab = (fileMetadata: FileMetadata) => {
    // Trigger window.open() outside async method to be able to make it work for safari on iphone
    let windowRef: Window | null = null;
    windowRef = window.open();

    if (customerId) {
      fileService()
        .downloadFileRaw({
          customerId,
          fileId: fileMetadata.fileId,
        })
        .then((res) => Promise.resolve(res.raw.blob()))
        .then((blob) => {
          if (windowRef !== null) {
            // @ts-ignore
            windowRef.location = URL.createObjectURL(blob);
          }
        })
        .finally(onDownloaded)
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        );
    }
  };

  return (
    <ListItem
      className={classes.root}
      divider
      onClick={() => openInNewTab(file)}
    >
      <Grid container>
        <Grid item sm={4} xs={12}>
          <div className={classes.titleContainer}>
            <FileOwner ownerId={file.uploadedBy.id}>
              <ListItemAvatar>
                <Avatar>
                  <InsertDriveFileIcon />
                </Avatar>
              </ListItemAvatar>
            </FileOwner>

            <ListItemText
              primary={
                file.lastOpenedAt ? (
                  file.fileName
                ) : (
                  <strong>{file.fileName}</strong>
                )
              }
              secondary={
                isSmallScreen
                  ? displayUser(file.uploadedBy)
                  : calculateTimeSinceDate(
                      file.uploadedOn || new Date(),
                      intl.locale
                    )
              }
              primaryTypographyProps={{
                noWrap: true,
                variant: "body2",
                color: "textPrimary",
                style: {
                  marginRight: 10,
                },
              }}
              secondaryTypographyProps={{
                variant: "body2",
                color: "textSecondary",
              }}
            />
          </div>
        </Grid>

        {!isSmallScreen && (
          <>
            <Grid item sm={2}>
              <ListItemText
                primary={intl.formatMessage(messages.sharedWithLabel)}
                secondary={
                  <AvatarGroup
                    max={2}
                    users={getFileUsersAfterRemoval(file.userAccessList || [], [
                      authenticatedUser.user?.id || "",
                    ])}
                  />
                }
                primaryTypographyProps={{
                  variant: "body2",
                  color: "textPrimary",
                }}
              />
            </Grid>
            <Grid item sm={3}>
              {file.uploadedBy && (
                <ListItemText
                  primary={intl.formatMessage(messages.uploadedByLabel)}
                  secondary={displayUser(file.uploadedBy)}
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "textPrimary",
                  }}
                  secondaryTypographyProps={{
                    variant: "body2",
                    color: "textSecondary",
                  }}
                />
              )}
            </Grid>
            <Grid item sm={3} xs={6}>
              {file.lastOpenedAt && (
                <ListItemText
                  primary={intl.formatMessage(messages.lastOpenedLabel)}
                  secondary={calculateTimeSinceDate(
                    file.lastOpenedAt,
                    intl.locale
                  )}
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "textPrimary",
                  }}
                  secondaryTypographyProps={{
                    variant: "body2",
                    color: "textSecondary",
                  }}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <ListItemSecondaryAction>
        <FileActionButton
          fileId={file.fileId}
          fileName={file.fileName}
          uploadedBy={file.uploadedBy.id}
          onDownloaded={onDownloaded}
          onDeleted={onDeleted}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FileListItem;
