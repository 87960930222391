import { defineMessages } from "react-intl";

export const messages = defineMessages({
  privacyPolicy: {
    id: "footer.privacypolicy.title",
    defaultMessage: "Privacy Policy",
    description: "title in footer for link to privacy policy",
  },
  general: {
    id: "footer.general.title",
    defaultMessage: "General",
    description: "title in footer for link to privacy policy",
  },
  version: {
    id: "footer.version.title",
    defaultMessage: "Version:",
    description: "title in footer for link to privacy policy",
  },
  consent: {
    id: "footer.consent.title",
    defaultMessage: "Consent",
    description: "title in footer for link to consent to handle personal data",
  },
  languageTitle: {
    id: "footer.language.title",
    defaultMessage: "Languages",
    description: "title for languages in footer",
  },
  swedish: {
    id: "language.options.swedish",
    defaultMessage: "Swedish",
    description: "Text shown on button for switching language to swedish",
  },
  english: {
    id: "language.options.english",
    defaultMessage: "English",
    description: "Text shown on button for switching language to english",
  },
});
