import React from "react";
import MuiPagination from "@material-ui/lab/Pagination";
import { Pagination } from "../../generated/user-api";
import { useStyles } from "./Pagination.style";

interface Props {
  size?: "small" | "medium" | "large";
  onChange: (page: number) => void;
  pagination?: Pagination;
}
const CustomPagination = (props: Props) => {
  const classes = useStyles();
  const { pagination, onChange, size } = props;

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    onChange(page);
  };

  if (!pagination) {
    return null;
  }

  return (
    <>
      <MuiPagination
        size={size}
        className={classes.pagination}
        color="secondary"
        page={pagination.page}
        count={pagination.totalPages}
        onChange={handlePagination}
      />
    </>
  );
};

export default CustomPagination;
