import { defineMessages } from "react-intl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messages: { [key: string]: any } = defineMessages({
  titleRequired: {
    id: "validation.todo.titleRequired",
    defaultMessage: "Title is required",
    description:
      "Text to display when user attempts to enter empty title for todo",
  },
  titleTooLong: {
    id: "validation.todo.titleTooLong",
    defaultMessage: "Title can not be longer than 255 characters",
    description:
      "Text to display when user attempts to enter a todo title that is longer than 255 characters",
  },
  descriptionTooLong: {
    id: "validation.todo.descriptionTooLong",
    defaultMessage: "Description can not be longer than 32000 characters",
    description:
      "Text to display when user attempts to enter a todo description that is longer than 32000 characters",
  },
  participantsRequired: {
    id: "validation.todo.participantsRequired",
    defaultMessage: "You need to add at least one participants",
    description:
      "Text to display when user attempts to create todo without assigning anyone",
  },
  shouldBeCompletedOnDateInPast: {
    id: "validation.todo.shouldBeCompletedOnDateInPast",
    defaultMessage: "Date can not be in the past",
    description: "Text to display when user enters a date in past",
  },
});
