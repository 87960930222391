import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "commonFixesCard.title.label",
    defaultMessage: "Common fixes",
    description: "Title shown in card common fixes",
  },
  refreshTitle: {
    id: "commonFixesCard.refresh.title",
    defaultMessage: "Refresh the page",
    description: "Title for section about refresh page in Test Video page",
  },
  refreshDescription: {
    id: "commonFixesCard.refresh.description",
    defaultMessage:
      "Sometimes, the easiest way to fix any problem you might encounter is to refresh the page. To do so, press F5 or click on this button:",
    description: "Description section about refresh page in Test Video page",
  },
  requirementsTitle: {
    id: "commonFixesCard.requirements.title",
    defaultMessage: "To take part in a meeting you need:",
    description: "Title for section about requirements in Test Video page",
  },
  requirementsComputer: {
    id: "commonFixesCard.requirements.computer",
    defaultMessage:
      "A computer with camera and microphone as well as an updated version of Google Chrome or Apple Safari.",
    description: "Computer and browser requirements in Test Video page",
  },
  requirementsAppleDevices: {
    id: "commonFixesCard.requirements.appleDevice",
    defaultMessage:
      "Iphone or iPad with iOS 11 or later and an updated version of Apple Safari.",
    description: "Requirements for apple devices in Test Video page",
  },
  requirementsAndroidDevices: {
    id: "commonFixesCard.requirements.androidDevice",
    defaultMessage:
      "Android mobile phone or pad with an updated version of Google Chrome.",
    description: "Requirements for android devices in Test Video page",
  },
  furtherTroubleshootingTitle: {
    id: "commonFixesCard.furtherTroubleshooting.title",
    defaultMessage: "Further troubleshooting",
    description: "Title for section about further troubleshooting",
  },
  linkText: {
    id: "commonFixesCard.furtherTroubleshooting.link.text",
    defaultMessage: "For a more detailed test, press the button below",
    description: "Detailed test text",
  },
  buttonText: {
    id: "commonFixesCard.furtherTroubleshooting.button.text",
    defaultMessage: "Test",
    description: "Button text",
  },
  temporaryWarning: {
    id: "commonFixesCard.temporaryWarning.text",
    defaultMessage:
      "At the moment it is not possible to perform the test on an iPhone with chrome browser",
    description: "Button text",
  },
});
