import React, { useEffect, useRef, useState } from "react";
import {
  List,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import EmailIcon from "@material-ui/icons/Email";
import { Waypoint } from "react-waypoint";
import { Alert } from "@mui/material";
import { useStyles } from "./DisplayMessagesCard.style";
import { messages } from "./DisplayMessagesCard.messages";
import Message from "./Components/Message/Message";
import CreateMessageButton from "../../../../Components/Buttons/CreateMessageButton/CreateMessageButton";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { UserStatusType } from "../../../../generated/message-api";
import ReplyMessageFields from "../../../../Components/Fields/MessageFields/ReplyMessageFields/ReplyMessageFields";
import PermissionController from "../../../../Components/PermissionController/PermissionController";
import {
  UserAuthorityType,
  UserRoleType,
} from "../../../../generated/user-api";
import CardHeading from "../../../../Components/CardHeading/CardHeading";
import { getFormattedDate } from "../../../../Utils/Meeting";
import NewMessagesAvailableButton from "../../../../Components/Buttons/NewMessagesAvailableButton/NewMessagesAvailableButton";
import useLoadMessages from "./Components/Hooks/MessageHook";
import { messageService } from "../../../../Providers/ServiceProvider/ServiceProvider";
import FeatureController from "../../../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

interface Props {
  threadId?: string;
  noMessages?: boolean;
  onMessageCreated?: () => void;
  hideHeading?: boolean;
  onThreadLoaded?: (threadName: string) => void;
  numberOfUnreadMessages?: number;
}

const DisplayMessagesCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    threadId,
    noMessages,
    onMessageCreated,
    hideHeading,
    onThreadLoaded,
    numberOfUnreadMessages,
  } = props;
  const customerId = useCustomerId();
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [isScrollingToEnd, setIsScrollingToEnd] = useState<boolean>(true);
  const [displayNewMessage, setDisplayNewMessage] = useState<boolean>(false);
  const [newReply, setNewReply] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { hasMore, messageList, counterPart } = useLoadMessages(
    numberOfUnreadMessages || 0,
    newReply,
    threadId,
    page
  );

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const onBottomWaypointEnter = () => {
    setDisplayNewMessage(false);
    setIsScrollingToEnd(true);
  };

  const onBottomWaypointLeave = () => {
    setIsScrollingToEnd(false);
  };

  const checkIfDateChanged = (index: number): boolean => {
    const current = messageList?.[index];
    const previous = messageList?.[index + 1];
    return current && previous
      ? getFormattedDate(current.sentOn) !== getFormattedDate(previous.sentOn)
      : false;
  };

  const reply = (replyMessage: string) => {
    if (
      customerId &&
      threadId &&
      replyMessage &&
      replyMessage.replace(/<(.|\n)*?>/g, "").trim().length > 0
    ) {
      messageService()
        .replyToMessage({
          customerId,
          messageId: threadId,
          createMessageRequest: {
            contents: replyMessage,
          },
        })
        .then(() => {
          setNewReply((prevState) => prevState + 1);
        });
    }
  };

  const handleOnDeleted = () => {
    window.location.reload();
  };

  useEffect(() => {
    setIsScrollingToEnd(true);
    setDisplayNewMessage(false);
    setPage(1);
  }, [threadId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onThreadLoaded && messageList.length > 0) {
      onThreadLoaded(messageList[0].subject || "");
    }
  }, [messageList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      numberOfUnreadMessages &&
      numberOfUnreadMessages > 0 &&
      !isScrollingToEnd
    ) {
      setDisplayNewMessage(true);
    }
  }, [numberOfUnreadMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!threadId || noMessages)
    return (
      <Paper className={classes.paperEmpty}>
        <EmailIcon color="disabled" className={classes.icon} />
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.noMessagesLabel}
        >
          {noMessages
            ? intl.formatMessage(messages.noMessages)
            : intl.formatMessage(messages.noMessageSelected)}
        </Typography>
        <CreateMessageButton
          onCreated={onMessageCreated}
          label={intl.formatMessage(messages.createButton)}
          recipients={[]}
        />
      </Paper>
    );

  return (
    <Paper className={classes.paper}>
      {counterPart?.userRole && (
        <FeatureController requiredFeature={CustomerFeatureType.OneWayMessage}>
          <PermissionController
            allowedRoles={[UserRoleType.Client, UserRoleType.External]}
          >
            <Alert severity="info">
              {intl.formatMessage(messages.oneWayMessageWarning)}
            </Alert>
          </PermissionController>
          <PermissionController allowedRoles={[UserRoleType.Staff]}>
            {counterPart?.userRole !== UserRoleType.Staff && (
              <Alert severity="info">
                {intl.formatMessage(messages.oneWayMessageWarningStaff)}
              </Alert>
            )}
          </PermissionController>
        </FeatureController>
      )}
      {!hideHeading && (
        <CardHeading>{messageList?.[0]?.subject.toUpperCase()}</CardHeading>
      )}
      <NewMessagesAvailableButton
        show={displayNewMessage}
        onClick={scrollToBottom}
      />
      <List className={classes.ul}>
        <div ref={lastMessageRef} />
        <Waypoint
          onEnter={onBottomWaypointEnter}
          onLeave={onBottomWaypointLeave}
        />
        {messageList?.map((msg, index) => (
          <Message
            key={msg.id}
            onDeleted={handleOnDeleted}
            messageResponse={msg}
            distinctDate={
              messageList.length - 1 === index
                ? true
                : checkIfDateChanged(index)
            }
          />
        ))}
        <Waypoint onEnter={() => hasMore && setPage(page + 1)} />
      </List>
      {counterPart?.userStatus === UserStatusType.Active && (
        <PermissionController
          requiredAuthoritiesStaff={[UserAuthorityType.SendMessage]}
          requiredAuthoritiesClient={[UserAuthorityType.SendMessage]}
          requiredAuthoritiesExternal={[UserAuthorityType.SendMessage]}
        >
          {isSmallScreen && (
            <div className={classes.replyMessageContainer}>
              <ReplyMessageFields
                threadId={threadId}
                onSubmit={reply}
                onFileShared={() => setNewReply((prevState) => prevState + 1)}
                activateFileShareWith={counterPart.id}
              />
            </div>
          )}
          {!isSmallScreen && (
            <ReplyMessageFields
              threadId={threadId}
              onSubmit={reply}
              onFileShared={() => setNewReply((prevState) => prevState + 1)}
              activateFileShareWith={counterPart.id}
            />
          )}
        </PermissionController>
      )}
    </Paper>
  );
};

export default DisplayMessagesCard;
