import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(1),
    },
    paper: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.grey["100"],
      },
    },
    leftMargin: {
      marginLeft: theme.spacing(1),
    },
  })
);
