import React from "react";
import ListItemText from "@material-ui/core/ListItemText";

interface Props {
  primaryText: string;
  secondaryText?: string;
}

const ContactListItemText = (props: Props) => {
  const { primaryText, secondaryText } = props;

  if (!secondaryText) {
    return null;
  }

  return (
    <ListItemText
      primary={primaryText}
      secondary={secondaryText}
      primaryTypographyProps={{ variant: "body2", color: "textPrimary" }}
      secondaryTypographyProps={{
        variant: "body2",
        color: "textSecondary",
      }}
    />
  );
};

export default ContactListItemText;
