import React from "react";
import RoomWrapper from "@vonage/video-express/dist/mp/room";
import { SpeedDial, SpeedDialIcon } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isIOS } from "react-device-detect";
import { useStyles } from "./SpeedDialButton.style";
import MuteAllSpeedDialAction from "./Component/MuteAllSpeedDialAction/MuteAllSpeedDialAction";
import ScreenSharingSpeedDialAction from "./Component/ScreenSharingSpeedDialAction/ScreenSharingSpeedDialAction";
import BackgroundSpedDialAction from "./Component/BackgroundSpedDialAction/BackgroundSpedDialAction";

interface Props {
  room: RoomWrapper;
  onMuteAll: () => void;
  areAllMuted: boolean;
  isScreenSharing: boolean;
  startScreenSharing: () => void;
  stopScreenSharing: () => void;
}

export default function SpeedDialButton(props: Props) {
  const {
    room,
    onMuteAll,
    areAllMuted,
    startScreenSharing,
    stopScreenSharing,
    isScreenSharing,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.container}>
      <SpeedDial
        ariaLabel="More options"
        icon={
          <SpeedDialIcon openIcon={<CloseIcon />} icon={<MoreVertIcon />} />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        FabProps={{ className: classes.speedDialFab }}
      >
        {!isMobile && (
          <ScreenSharingSpeedDialAction
            open={open}
            delay={1}
            isScreenSharing={isScreenSharing}
            startScreenSharing={startScreenSharing}
            stopScreenSharing={stopScreenSharing}
          />
        )}
        {!isIOS && (
          <BackgroundSpedDialAction open={open} delay={2} room={room} />
        )}
        {isMobile && (
          <MuteAllSpeedDialAction
            open={open}
            delay={3}
            onMuteAll={onMuteAll}
            areAllMuted={areAllMuted}
          />
        )}
      </SpeedDial>
    </div>
  );
}
