import React from "react";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./ContactList.style";
import ContactListItem from "./Components/ContactListItem/ContactListItem";
import { messages } from "./ContactList.messages";
import { Contact } from "../../Models/Contact";

interface Props {
  contactList?: Contact[];
  reloadContacts?: () => void;
}

const ContactList = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { contactList, reloadContacts } = props;

  if (!contactList || contactList.length === 0) {
    return (
      <Typography
        className={classes.noResultText}
        variant="h4"
        color="textSecondary"
      >
        {intl.formatMessage(messages.noResultsText)}
      </Typography>
    );
  }

  return (
    <List className={classes.root}>
      {contactList.map(
        (contact) =>
          contact && (
            <ContactListItem
              key={contact.contactId}
              contact={contact}
              reloadContacts={reloadContacts}
            />
          )
      )}
    </List>
  );
};

export default ContactList;
