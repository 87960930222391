import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      paddingBottom: theme.spacing(0.5),
      backgroundColor: theme.palette.background.paper,
    },
  })
);
