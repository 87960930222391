import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      fontWeight: "bold",
      color: theme.palette.grey[700],
      textAlign: "center",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    customerMessage: {
      color: theme.palette.grey[700],
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
  })
);
